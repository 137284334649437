import React from "react";
import {startCase} from "lodash";

function FormErrorBox({errors}) {
	// STEP 1: Check to see if we have ANY errors returned from the API
	//         web-service and if no errors were returned then our stateless
	//         component will return nothing.
	if (errors === null || errors === undefined) {
		// console.log("FormErrorBox | null"); // For debugging purposes only.
		return null;
	}
	if (Object.keys(errors).length === 0) {
		// console.log("FormErrorBox | null"); // For debugging purposes only.
		return null;
	}

	// STEP 2: If the error result is not an object then return the following GUI.
	if (typeof errors !== "object") {
		return (
			<div className="rounded-sm bg-red-50 border border-red-200">
				<div className="px-4 py-2 bg-darkRedLink text-textLight font-semibold rounded-t-sm">
					Error(s):
				</div>
				<div className="p-4">
					<p className="mb-4 flex items-start">
						<span className="font-semibold">Error:</span>
						<span className="ml-2">{errors}</span>
					</p>
					<hr className="border-red-200 my-3" />
					<p className="text-sm italic text-darkShade">
						Please make sure the above error(s) have been fixed before
						submitting again
					</p>
				</div>
			</div>
		);
	}

	// STEP 3: If the result is an object then run the following GUI.
	return (
		<div className="rounded-sm bg-red-50 border border-red-200">
			<div className="px-4 py-2 bg-darkRedLink text-textLight font-semibold rounded-t-sm">
				Error(s):
			</div>
			<div className="p-4">
				{Object.keys(errors).map((key) => {
					// STEP 3: Process a single "field" or "non_field_errors" and
					//         then get our value.
					let startKey = startCase(key);

					// DEVELOPERS NOTE:
					// The following code will remove any "Id" related keys as it was added
					// due to "Golang" naming convention in the database. Ex: `how_hear_id`.
					startKey = startKey.replace(" Id", "");

					let value = errors[key];
					// console.log(key, value); // For debugging purposes only.

					// STEP 4: Generate the error row if the value accomponying it is not blank.
					if (value !== undefined && value !== null) {
						return (
							<p className="mb-2 last:mb-4 flex items-start">
								<span className="font-semibold">{startKey}:</span>
								<span className="ml-2">{value}</span>
							</p>
						);
					}
					return null;
				})}
				<hr className="border-red-200 my-3" />
				<p className="text-sm italic text-darkShade">
					Please make sure the above error(s) have been fixed before submitting
					again
				</p>
			</div>
		</div>
	);
}

export default FormErrorBox;
