import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faLightbulb,
	faPlus,
	faGauge,
	faEye,
	faPencilAlt,
	faTrashAlt,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import {getTipsListAPI, deleteTipAPI} from "../../../API/tips"; // Change API import to use tips
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	tipsFilterShowState,
	tipsFilterTemporarySearchTextState,
	tipsFilterActualSearchTextState,
	tipsFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
} from "../../Reusable/AdminList";
import Modal from "../../Reusable/Modal";
import FormSelectField from "../../Reusable/FormSelectField";
import {TIPS_STATUS_OPTIONS} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";

function AdminTipsList() {
	const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
	const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(tipsFilterShowState);
	const [sort] = useRecoilState(tipsFilterSortState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		tipsFilterTemporarySearchTextState
	);
	const [status, setStatus] = useState(1); //Status
	const [actualSearchText, setActualSearchText] = useRecoilState(
		tipsFilterActualSearchTextState
	);

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState([]);
	const [selectedTipForDeletion, setSelectedTipForDeletion] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const onTipsListSuccess = (response) => {
		if (response.results) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onTipsListError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onTipsListDone = () => {
		setFetching(false);
	};

	const onTipsDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Tip deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		fetchList(currentCursor, pageSize, actualSearchText, sort);
	};

	const onTipsDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		Scroll.animateScroll.scrollToTop();
	};

	const onTipsDeleteDone = () => {
		setFetching(false);
	};

	const fetchList = (cur, limit, keywords, sbv) => {
		setFetching(true);
		setErrors({});
		let params = new Map();
		params.set("page_size", limit);
		if (cur !== "") {
			params.set("cursor", cur);
		}
		if (keywords) {
			params.set("search", keywords);
		}
		if (sbv) {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}
		params.set("status", status);
		getTipsListAPI(params, onTipsListSuccess, onTipsListError, onTipsListDone);
	};

	const onNextClicked = () => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = () => {
		setActualSearchText(temporarySearchText);
	};

	const onSelectTipForDeletion = (e, datum) => {
		setSelectedTipForDeletion(datum);
		setIsModalOpen(true);
	};

	const onDeselectTipForDeletion = () => {
		setSelectedTipForDeletion("");
		setIsModalOpen(false);
	};

	const onDeleteConfirmButtonClick = () => {
		deleteTipAPI(
			selectedTipForDeletion.id,
			onTipsDeleteSuccess,
			onTipsDeleteError,
			onTipsDeleteDone
		);
		setIsModalOpen(false);
		setSelectedTipForDeletion("");
	};

	const onClearFilterClick = () => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
	};

	useEffect(() => {
		fetchList(currentCursor, pageSize, actualSearchText, sort, status);
	}, [currentCursor, pageSize, actualSearchText, sort, status]);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Tips", icon: faLightbulb},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
				<ListHeader
					title="Tips"
					icon={faLightbulb}
					onRefresh={() =>
						fetchList(currentCursor, pageSize, actualSearchText, sort)
					}
					onToggleFilter={() => setShowFilter(!showFilter)}
					addLink="/admin/tips/add"
					addLabel="New"
				/>

				{showFilter && (
					<ListFilter onClear={onClearFilterClick}>
						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
							<FormInputFieldWithButton
								label="Search"
								name="search"
								placeholder="Search by name or description"
								value={temporarySearchText}
								onChange={(e) => setTemporarySearchText(e.target.value)}
								onButtonClick={onSearchButtonClick}
								buttonLabel="Search"
							/>
							<FormSelectField
								label="Status"
								name="status"
								selectedValue={status}
								onChange={(e) => setStatus(parseInt(e.target.value))}
								options={TIPS_STATUS_OPTIONS}
							/>
						</div>
					</ListFilter>
				)}

				{errors && <FormErrorBox errors={errors} />}

				{isFetching ? (
					<PageLoadingContent />
				) : listData?.results?.length > 0 || previousCursors.length > 0 ? (
					<>
						{/* Desktop List */}
						<div className="hidden md:block">
							<DataListDesktop
								headers={[
									{label: "Name", className: "w-1/4"},
									{label: "Status", className: "w-1/4"},
									{label: "Created At", className: "w-1/4"},
									{label: "Actions", className: "w-1/4 text-right"},
								]}
								data={listData?.results || []}
								renderRow={(tip, index) => (
									<tr
										key={tip.id}
										className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
										<td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
											{tip.name}
										</td>
										<td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
											{
												TIPS_STATUS_OPTIONS.find(
													(option) => option.value === tip.status
												)?.label
											}
										</td>
										<td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
											{new Date(tip.createdAt).toLocaleDateString()}
										</td>
										<td className="px-4 py-4">
											<div className="flex items-center justify-end space-x-3">
												<Link
													to={`/admin/tips/${tip.id}`}
													className="inline-flex items-center px-3 py-2 border border-gray-300 
                           shadow-sm text-sm font-medium rounded-md text-gray-700 
                           bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 
                           focus:ring-offset-2 focus:ring-primary">
													<FontAwesomeIcon
														icon={faEye}
														className="mr-2 h-4 w-4"
													/>
													View
												</Link>
												<Link
													to={`/admin/tips/${tip.id}/update`}
													className="inline-flex items-center px-3 py-2 border border-gray-300 
                           shadow-sm text-sm font-medium rounded-md text-gray-700 
                           bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 
                           focus:ring-offset-2 focus:ring-primary">
													<FontAwesomeIcon
														icon={faPencilAlt}
														className="mr-2 h-4 w-4"
													/>
													Edit
												</Link>
												<button
													onClick={() => setIsModalOpen(true)}
													className="inline-flex items-center px-3 py-2 border border-transparent 
                           shadow-sm text-sm font-medium rounded-md text-white 
                           bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 
                           focus:ring-offset-2 focus:ring-red-500">
													<FontAwesomeIcon
														icon={faTrashAlt}
														className="mr-2 h-4 w-4"
													/>
													Delete
												</button>
											</div>
										</td>
									</tr>
								)}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData?.hasNextPage || false}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>

						{/* Mobile List */}
						<div className="md:hidden">
							<DataListMobile
								data={listData?.results || []}
								renderItem={(tip, index) => (
									<div
										key={tip.id}
										className="bg-white shadow rounded-lg overflow-hidden mb-4">
										<div className="px-4 py-5 sm:p-6">
											<h3 className="text-lg font-medium text-gray-900">
												{tip.name}
											</h3>
											<div className="mt-2 text-sm text-gray-500">
												<p>
													Status:{" "}
													{
														TIPS_STATUS_OPTIONS.find(
															(option) => option.value === tip.status
														)?.label
													}
												</p>
												<p>
													Created:{" "}
													{new Date(tip.createdAt).toLocaleDateString()}
												</p>
											</div>
											<div className="mt-4 flex space-x-3">
												<Link
													to={`/admin/tips/${tip.id}`}
													className="inline-flex items-center px-4 py-2 border border-transparent 
                           rounded-md shadow-sm text-sm font-medium text-white 
                           bg-primary hover:bg-primary-dark transition-colors duration-300">
													<FontAwesomeIcon
														icon={faEye}
														className="mr-2 h-4 w-4"
													/>
													View
												</Link>
												<button
													onClick={() => setSelectedTipForDeletion(tip)}
													className="inline-flex items-center px-4 py-2 border border-transparent 
                           rounded-md shadow-sm text-sm font-medium text-white 
                           bg-red-600 hover:bg-red-700 transition-colors duration-300">
													<FontAwesomeIcon
														icon={faTrashAlt}
														className="mr-2 h-4 w-4"
													/>
													Delete
												</button>
											</div>
										</div>
									</div>
								)}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData?.hasNextPage || false}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>
					</>
				) : (
					<div className="text-center py-12 bg-gray-50 rounded-lg">
						<FontAwesomeIcon
							icon={faTable}
							className="text-4xl text-gray-400 mb-4"
						/>
						<h3 className="text-lg font-medium text-gray-900 mb-2">
							No Tips Available
						</h3>
						<p className="text-gray-600">
							Get started by{" "}
							<Link
								to="/admin/tips/add"
								className="text-primary hover:text-primary-dark font-medium">
								creating your first tip
							</Link>
						</p>
					</div>
				)}

				<Modal
					isOpen={isModalOpen}
					onClose={onDeselectTipForDeletion}
					header="Confirm Deletion">
					<div className="space-y-4">
						<p className="text-gray-600">
							Are you sure you want to delete this tip? This action cannot be
							undone.
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={onDeselectTipForDeletion}
								className="px-4 py-2 text-gray-700 hover:bg-gray-100 
                       rounded-lg transition-colors duration-300">
								Cancel
							</button>
							<button
								onClick={onDeleteConfirmButtonClick}
								className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 
                       rounded-lg transition-colors duration-300">
								Delete
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminTipsList;
