import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faPencil, 
  faBuilding,
  faEnvelope,
  faPhone,
  faGlobe,
  faLocationDot,
  faGauge,
  faCalendarAlt,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import { getOrganizationDetailAPI } from "../../../API/organization";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import { currentUserState, topAlertMessageState, topAlertStatusState } from "../../../AppState";

function AdminOrganizationDetail() {
  ////
  ////
  ////

  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [organization, setOrganization] = useState({});

  function onOrganizationDetailSuccess(response) {
    setOrganization(response);
  }

  function onOrganizationDetailError(apiErr) {
    setErrors(apiErr);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onOrganizationDetailDone() {
    setFetching(false);
  }

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.

      setFetching(true);
      getOrganizationDetailAPI(
        currentUser.organizationId,
        onOrganizationDetailSuccess,
        onOrganizationDetailError,
        onOrganizationDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, []);
  ////
  //// Component rendering.
  ////

  const breadcrumbItems = [
    { label: 'Dashboard', link: '/dashboard', icon: faGauge },
    { label: 'Organization', icon: faBuilding }
  ]
  const InfoCard = ({ icon, label, value, subValue }) => (
    <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100 hover:border-primary/20 transition-all duration-300 hover:shadow-md">
      <div className="flex items-start space-x-4">
        <div className="p-3 bg-primary/10 rounded-lg">
          <FontAwesomeIcon icon={icon} className="text-primary text-xl" />
        </div>
        <div className="flex-1">
          <p className="text-sm font-medium text-gray-500">{label}</p>
          <p className="mt-1 text-lg font-semibold text-gray-900 break-all">{value}</p>
          {subValue && (
            <p className="mt-1 text-sm text-gray-500">{subValue}</p>
          )}
        </div>
      </div>
    </div>
  );

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <Layout currentUser={currentUser} breadcrumbItems={breadcrumbItems}>
      <div className="space-y-8">
        {/* Header Section */}
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-4 bg-primary/10 rounded-xl">
                <FontAwesomeIcon 
                  icon={faBuilding} 
                  className="text-3xl text-primary"
                />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {organization.name}
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  {organization.description || "Manage your organization's details and settings"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <FormErrorBox errors={errors} />

        {isFetching ? (
          <div className="bg-white rounded-xl shadow-sm p-8 border border-gray-100">
            <PageLoadingContent displayMessage={"Loading organization details..."} />
          </div>
        ) : (
          organization && (
            <div className="space-y-6">
              {/* Contact Information Cards */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                <InfoCard 
                  icon={faEnvelope}
                  label="Email Address"
                  value={organization.email}
                />
                <InfoCard 
                  icon={faPhone}
                  label="Phone Number"
                  value={organization.phone}
                />
                <InfoCard 
                  icon={faGlobe}
                  label="Website"
                  value={organization.websiteUrl || "Not provided"}
                />
              </div>

              {/* Location Details */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100">
                <div className="px-6 py-5 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-gray-900 flex items-center">
                    <FontAwesomeIcon icon={faLocationDot} className="mr-2 text-primary" />
                    Location Information
                  </h2>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-500">Address</label>
                      <p className="mt-1 text-sm text-gray-900">{organization.addressLine1}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">City, Region</label>
                      <p className="mt-1 text-sm text-gray-900">{organization.city}, {organization.region}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">Country, Postal Code</label>
                      <p className="mt-1 text-sm text-gray-900">{organization.country}, {organization.postalCode}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* System Information */}
              <div className="bg-white rounded-xl shadow-sm border border-gray-100">
                <div className="px-6 py-5 border-b border-gray-200">
                  <h2 className="text-lg font-semibold text-gray-900">
                    System Information
                  </h2>
                </div>
                <div className="p-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-500">Organization ID</label>
                      <p className="mt-1 text-sm font-mono text-gray-900">{organization.id}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">Created At</label>
                      <p className="mt-1 text-sm text-gray-900">{formatDate(organization.createdAt)}</p>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-500">Last Modified</label>
                      <p className="mt-1 text-sm text-gray-900">{formatDate(organization.modifiedAt)}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Opening Hours (if available) */}
              {organization.openingHours && (
                <div className="bg-white rounded-xl shadow-sm border border-gray-100">
                  <div className="px-6 py-5 border-b border-gray-200">
                    <h2 className="text-lg font-semibold text-gray-900">
                      Opening Hours
                    </h2>
                  </div>
                  <div className="p-6">
                    <pre className="text-sm text-gray-900">
                      {JSON.stringify(organization.openingHours, null, 2)}
                    </pre>
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <div className="mt-4 md:mt-0 flex justify-between space-x-3">
              <Link
                to="/admin/dashboard"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-200"
              >
                Back to Dashboard
              </Link>
              <Link
                to="/admin/organization/update"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary shadow-sm transition-all duration-200"
              >
                <FontAwesomeIcon icon={faPencil} className="mr-2" />
                Edit Organization
              </Link>
            </div>
    </Layout>
  );
}

export default AdminOrganizationDetail;