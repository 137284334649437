import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { MMA_FITNESS_ORGANIZATION_API_ENDPOINT } from "../Constants/API";

export function getOrganizationDetailAPI(
  id,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();
  axios
    .get(MMA_FITNESS_ORGANIZATION_API_ENDPOINT.replace("{id}", id))
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      // Return the callback data.
      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}

export function putOrganizationUpdateAPI(
  data,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();

  // To Snake-case for API from camel-case in React.
  let decamelizedData = decamelizeKeys(data);

  // Bugfix.
  decamelizedData.id = data.ID;
  delete decamelizedData.i_d;

  axios
    .put(
      MMA_FITNESS_ORGANIZATION_API_ENDPOINT.replace("{id}", decamelizedData.id),
      decamelizedData
    )
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      // Return the callback data.
      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}
