import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faVideoCamera,
	faEye,
	faPencil,
	faTrashCan,
	faPlus,
	faGauge,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
	faList,
	faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	getVideoCollectionListAPI,
	deleteVideoCollectionAPI,
} from "../../../API/VideoCollection";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	videoCollectionsFilterShowState,
	videoCollectionsFilterTemporarySearchTextState,
	videoCollectionsFilterActualSearchTextState,
	videoCollectionsFilterStatusState,
	videoCollectionsFilterVideoTypeState,
	videoCollectionsFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
	PAGE_SIZE_OPTIONS,
	VIDEO_COLLECTION_STATUS_MAP,
	VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	VIDEO_COLLECTION_TYPE_MAP,
	VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
} from "../../Reusable/AdminList";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";

function AdminVideoCollectionList() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		videoCollectionsFilterShowState
	);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		videoCollectionsFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		videoCollectionsFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(videoCollectionsFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		videoCollectionsFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(videoCollectionsFilterSortState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [
		selectedVideoCollectionForDeletion,
		setSelectedVideoCollectionForDeletion,
	] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

	////
	//// API.
	////

	function onVideoCollectionListSuccess(response) {
		console.log("onVideoCollectionListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onVideoCollectionListError(apiErr) {
		console.log("onVideoCollectionListError: Starting...");
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionListDone() {
		console.log("onVideoCollectionListDone: Starting...");
		setFetching(false);
	}

	function onVideoCollectionDeleteSuccess(response) {
		console.log("onVideoCollectionDeleteSuccess: Starting...");
		setTopAlertStatus("success");
		setTopAlertMessage("Video collection deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			status,
			videoType,
			sort
		);
	}

	function onVideoCollectionDeleteError(apiErr) {
		console.log("onVideoCollectionDeleteError: Starting...");
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDeleteDone() {
		console.log("onVideoCollectionDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, st, vt, sbv) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);

		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}

		if (cur !== "") {
			params.set("cursor", cur);
		}

		params.set("type", vt);

		if (keywords !== undefined && keywords !== null && keywords !== "") {
			params.set("search", keywords);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status", st);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}

		getVideoCollectionListAPI(
			params,
			onVideoCollectionListSuccess,
			onVideoCollectionListError,
			onVideoCollectionListDone
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	const onSelectVideoCollectionForDeletion = (item) => {
		setSelectedVideoCollectionForDeletion(item);
	};

	const onDeselectVideoCollectionForDeletion = () => {
		setSelectedVideoCollectionForDeletion("");
	};

	const onDeleteConfirm = () => {
		deleteVideoCollectionAPI(
			selectedVideoCollectionForDeletion.id,
			onVideoCollectionDeleteSuccess,
			onVideoCollectionDeleteError,
			onVideoCollectionDeleteDone
		);
		setSelectedVideoCollectionForDeletion("");
	};

	const onClearFilterClick = () => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				videoType,
				sort
			);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, videoType, sort]);

	////
	//// Component rendering.
	////

	const tableHeaders = [
		{label: "Name", sortKey: "name"},
		{label: "Type", sortKey: "type"},
		{label: "Status", sortKey: "status"},
		{label: "Actions", sortKey: null},
	];

	const renderDesktopRow = (item) => (
		<tr
			key={item.id}
			className="bg-white hover:bg-gray-50 transition-colors duration-200">
			<td className="px-4 py-4">
				<span className="text-gray-900 font-medium">{item.name}</span>
			</td>
			<td className="px-4 py-4">
				<span className="text-gray-600">{VIDEO_COLLECTION_TYPE_MAP[item.type]}</span>
			</td>
			<td className="px-4 py-4">
				<span
					className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium
                    ${
											item.status === 1
												? "bg-green-100 text-green-800"
												: "bg-gray-100 text-gray-800"
										}`}>
					{VIDEO_COLLECTION_STATUS_MAP[item.status]}
				</span>
			</td>
			<td className="px-4 py-4">
				<div className="flex items-center space-x-3">
                <Link
					to={`/admin/video-collection/${item.id}`}
					className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors">
					<FontAwesomeIcon icon={faEye} className="w-4 h-4 mr-1" />
						View
					</Link>
					<Link
						to={`/admin/video-collection/${item.id}/update`}
						className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors">
						<FontAwesomeIcon icon={faPencil} className="w-4 h-4 mr-1" />
						Edit
					</Link>
					<button
						onClick={() => onSelectVideoCollectionForDeletion(item)}
						className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors">
						<FontAwesomeIcon icon={faTrashCan} className="w-4 h-4 mr-1" />
						Delete
					</button>
				</div>
			</td>
		</tr>
	);

	const renderMobileItem = (item) => (
		<div className="bg-white rounded-lg shadow-sm p-4 space-y-4">
			<div className="flex justify-between items-start">
				<div>
					<h3 className="text-gray-900 font-medium">{item.name}</h3>
					<p className="text-gray-600 text-sm mt-1">{item.type}</p>
				</div>
				<span
					className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium
                    ${
											item.status === 1
												? "bg-green-100 text-green-800"
												: "bg-gray-100 text-gray-800"
										}`}>
					{item.status === 1 ? "Active" : "Inactive"}
				</span>
			</div>
			<div className="flex items-center justify-end space-x-4 pt-2 border-t border-gray-100">
				<Link
					to={`/admin/video-collection/${item.id}`}
					className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors">
					<FontAwesomeIcon icon={faEye} className="w-4 h-4 mr-1" />
					View
				</Link>
				<Link
					to={`/admin/video-collection/${item.id}/update`}
					className="inline-flex items-center text-sm text-gray-600 hover:text-primary transition-colors duration-200">
					<FontAwesomeIcon icon={faPencil} className="w-4 h-4 mr-1" />
					Edit
				</Link>
				<button
					onClick={() => onSelectVideoCollectionForDeletion(item)}
					className="inline-flex items-center text-sm text-gray-600 hover:text-red-600 transition-colors duration-200">
					<FontAwesomeIcon icon={faTrashCan} className="w-4 h-4 mr-1" />
					Delete
				</button>
			</div>
		</div>
	);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{
			label: "MMA Lessons",
			icon: faVideo,       
		},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-full bg-gray-50">
				<div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
					{/* Delete Confirmation Modal */}
					<Modal
						isOpen={selectedVideoCollectionForDeletion !== ""}
						onClose={onDeselectVideoCollectionForDeletion}
						header="Confirm Deletion">
						<div className="p-6">
							<p className="mb-4">
								Are you sure you want to delete this video collection?
							</p>
							<div className="flex justify-end space-x-3">
								<button
									onClick={onDeselectVideoCollectionForDeletion}
									className="px-4 py-2 text-gray-600 hover:text-gray-800">
									Cancel
								</button>
								<button
									onClick={onDeleteConfirm}
									className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700">
									Delete
								</button>
							</div>
						</div>
					</Modal>

					{/* Header */}
					<ListHeader
						title="MMA Lessons"
						icon={faVideo}
						onRefresh={() =>
							fetchList(
								currentCursor,
								pageSize,
								actualSearchText,
								status,
								videoType,
								sort
							)
						}
						onToggleFilter={() => setShowFilter(!showFilter)}
						addLink="/admin/video-collections/add"
						addLabel="Add"
					/>

					{/* Filter Section */}
					{showFilter && (
						<div className="bg-white rounded-lg shadow-sm p-6 mb-6">
							<div className="flex justify-between items-center mb-4">
								<h3 className="text-lg font-medium">
									<FontAwesomeIcon icon={faFilter} className="mr-2" />
									Filter
								</h3>
								<button
									onClick={onClearFilterClick}
									className="text-gray-600 hover:text-gray-900">
									<FontAwesomeIcon
										icon={faFilterCircleXmark}
										className="mr-1"
									/>
									Clear Filter
								</button>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
								<FormInputFieldWithButton
									label="Search"
									name="temporarySearchText"
									type="text"
									placeholder="Search by name"
									value={temporarySearchText}
									onChange={(e) => setTemporarySearchText(e.target.value)}
									buttonLabel={<FontAwesomeIcon icon={faSearch} />}
									onButtonClick={onSearchButtonClick}
								/>
								<FormSelectField
									label="Status"
									name="status"
									selectedValue={status}
									onChange={(e) => setStatus(parseInt(e.target.value))}
									options={VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION}
								/>
								<FormSelectField
									label="Video Type"
									name="videoType"
									selectedValue={videoType}
									onChange={(e) => setVideoType(e.target.value)}
									options={VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION}
								/>
							</div>
						</div>
					)}

					{/* Content */}
					<div className="mt-8">
						{isFetching ? (
							<PageLoadingContent displayMessage={"Please wait..."} />
						) : (
							<>
								<FormErrorBox errors={errors} />

								{/* List Content */}
								{listData?.results?.length > 0 || previousCursors.length > 0 ? (
									<>
										<div className="hidden md:block">
											<DataListDesktop
												headers={tableHeaders}
												data={listData.results || []}
												renderRow={renderDesktopRow}
												pageSize={pageSize}
												setPageSize={setPageSize}
												previousCursors={previousCursors}
												hasNextPage={listData.hasNextPage}
												onPreviousClicked={onPreviousClicked}
												onNextClicked={onNextClicked}
											/>
										</div>
										<div className="md:hidden">
											<DataListMobile
												data={listData.results || []}
												renderItem={renderMobileItem}
												pageSize={pageSize}
												setPageSize={setPageSize}
												previousCursors={previousCursors}
												hasNextPage={listData.hasNextPage}
												onPreviousClicked={onPreviousClicked}
												onNextClicked={onNextClicked}
											/>
										</div>
									</>
								) : (
									<div className="text-center py-12 bg-white rounded-lg shadow-sm">
										<FontAwesomeIcon
											icon={faTable}
											className="text-4xl text-gray-400 mb-4"
										/>
										<h3 className="text-lg font-medium text-gray-900 mb-2">
											No Video Collections
										</h3>
										<p className="text-gray-500">
											Get started by creating your first video collection.{" "}
											<Link
												to="/admin/video-collections/add"
												className="text-primary hover:text-primary-dark">
												Click here
											</Link>
										</p>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminVideoCollectionList;
