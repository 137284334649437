import React from "react";
import { RegionDropdown } from 'react-country-region-selector';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faChevronDown } from "@fortawesome/free-solid-svg-icons";

function FormRegionField({ 
    label, 
    name, 
    placeholder, 
    selectedCountry, 
    selectedRegion, 
    errorText, 
    validationText, 
    helpText, 
    onChange, 
    disabled, 
    maxWidth,
    icon = faMapMarkerAlt // Default icon, can be overridden
}) {
    return (
        <div className="mb-4" style={{ maxWidth }}>
            <label 
                htmlFor={name} 
                className="block text-sm font-medium text-gray-700 mb-1"
            >
                {label}
            </label>
            <div className="relative">
                {/* Left Icon */}
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon 
                        icon={icon} 
                        className="h-4 w-4 text-gray-400"
                    />
                </div>

                {/* Region Dropdown */}
                <RegionDropdown
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    country={selectedCountry}
                    value={selectedRegion}
                    onChange={onChange}
                    className={`
                        block w-full rounded-md 
                        pl-10 pr-10 py-2 
                        text-base focus:outline-none focus:ring-2
                        appearance-none
                        ${errorText 
                            ? 'border-red-500 focus:ring-red-200' 
                            : validationText 
                                ? 'border-green-500 focus:ring-green-200'
                                : 'border-gray-300 focus:ring-primary focus:border-primary'
                        }
                        ${disabled 
                            ? 'bg-gray-100 cursor-not-allowed' 
                            : 'bg-white'
                        }
                    `}
                    blankOptionLabel="Select a region"
                />

                {/* Right Dropdown Arrow */}
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <FontAwesomeIcon 
                        icon={faChevronDown} 
                        className="h-4 w-4 text-gray-400"
                    />
                </div>
            </div>

            {/* Help and Error Messages */}
            {helpText && (
                <p className="mt-1 text-sm text-gray-500">{helpText}</p>
            )}
            {errorText && (
                <p className="mt-1 text-sm text-red-600">{errorText}</p>
            )}
            {validationText && (
                <p className="mt-1 text-sm text-green-600">{validationText}</p>
            )}
        </div>
    );
}

export default FormRegionField;