import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faTachometer, faPlus, faTimesCircle, faCheckCircle, faUserCircle, faGauge, faPencil, faDumbbell, faIdCard, faAddressBook, faMessage, faChartPie, faCogs, faEye } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState } from 'recoil';

import { getVideoCategoryDetailAPI, putVideoCategoryUpdateAPI } from "../../../API/VideoCategory";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminVideoCategoryUpdate() {
    ////
    //// URL Parameters.
    ////

    const { id } = useParams()

    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [name, setName] = useState("");
    const [no, setNo] = useState("");
    const [status, setStatus] = useState(0);
    const [showCancelWarning, setShowCancelWarning] = useState(false);

    ////
    //// Event handling.
    ////

    const onSubmitClick = (e) => {
        console.log("onSubmitClick: Beginning...");
        setFetching(true);
        setErrors({});

        // To Snake-case for API from camel-case in React.
        const decamelizedData = {
            id: id,
            no: parseInt(no),
            name: name,
            status: status,
        };
        console.log("onSubmitClick, decamelizedData:", decamelizedData);
        putVideoCategoryUpdateAPI(decamelizedData, onAdminVideoCategoryUpdateSuccess, onAdminVideoCategoryUpdateError, onAdminVideoCategoryUpdateDone);
    }

    ////
    //// API.
    ////

    // --- Detail --- //

    function onVideoCategoryDetailSuccess(response){
        console.log("onVideoCategoryDetailSuccess: Starting...");
        setNo(response.no);
        setName(response.name);
        setStatus(response.status);
    }

    function onVideoCategoryDetailError(apiErr) {
        console.log("onVideoCategoryDetailError: Starting...");
        setErrors(apiErr);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onVideoCategoryDetailDone() {
        console.log("onVideoCategoryDetailDone: Starting...");
        setFetching(false);
    }

    // --- Update --- //

    function onAdminVideoCategoryUpdateSuccess(response){
        // For debugging purposes only.
        console.log("onAdminVideoCategoryUpdateSuccess: Starting...");
        console.log(response);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Video category update");
        setTopAlertStatus("success");
        setTimeout(() => {
            console.log("onAdminVideoCategoryUpdateSuccess: Delayed for 2 seconds.");
            console.log("onAdminVideoCategoryUpdateSuccess: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // Redirect the user to a new page.
        setForceURL("/admin/video-category/"+response.id);
    }

    function onAdminVideoCategoryUpdateError(apiErr) {
        console.log("onAdminVideoCategoryUpdateError: Starting...");
        setErrors(apiErr);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Failed submitting");
        setTopAlertStatus("danger");
        setTimeout(() => {
            console.log("onAdminVideoCategoryUpdateError: Delayed for 2 seconds.");
            console.log("onAdminVideoCategoryUpdateError: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onAdminVideoCategoryUpdateDone() {
        console.log("onAdminVideoCategoryUpdateDone: Starting...");
        setFetching(false);
    }

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);  // Start the page at the top of the page.
            setFetching(true);
            getVideoCategoryDetailAPI(
                id,
                onVideoCategoryDetailSuccess,
                onVideoCategoryDetailError,
                onVideoCategoryDetailDone
            );
        }

        return () => { mounted = false; }
    }, [id]);
    ////
    //// Component rendering.
    ////

    if (forceURL !== "") {
        return <Navigate to={forceURL}  />
    }

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/admin/dashboard', icon: faGauge },
        { label: 'Video Categories', link: '/admin/video-categories', icon: faDumbbell },
        { label: 'Detail', link: `/admin/video-category/${id}`, icon: faEye },
        { label: 'Edit', icon: faPencil },
    ]

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="mx-auto px-4 py-8">
            <div className="mx-auto">
                {/* Desktop Breadcrumbs */}

                {/* Cancel Warning Modal */}
                <Modal
                    isOpen={showCancelWarning}
                    onClose={() => setShowCancelWarning(false)}
                    header="Are you sure?"
                >
                    <div className="space-y-4">
                        <p className="text-gray-700">
                            Your record will be cancelled and your work will be lost. This cannot be undone. Do you want to continue?
                        </p>
                        <div className="flex justify-end space-x-3">
                            <Link 
                                to="/admin/video-categories"
                                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
                            >
                                Yes
                            </Link>
                            <button
                                onClick={() => setShowCancelWarning(false)}
                                className="px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Main Content */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                    <h1 className="text-2xl font-bold text-gray-900 flex items-center mb-6">
                        <FontAwesomeIcon className="mr-3" icon={faPencil} />
                        Update Video Category
                    </h1>

                    <FormErrorBox errors={errors} />

                    {isFetching ? (
                        <PageLoadingContent displayMessage={"Please wait..."} />
                    ) : (
                        <div className="space-y-6">
                            <div>

                                <div className="space-y-4">
                                    {/* Name Input */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Name <span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="w-full sm:w-96 px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                                            placeholder="Text input"
                                        />
                                        {errors?.name && (
                                            <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                                        )}
                                    </div>

                                    {/* No Input */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            No # <span className="text-red-600">*</span>
                                        </label>
                                        <input
                                            type="number"
                                            value={no}
                                            onChange={(e) => setNo(parseInt(e.target.value))}
                                            className="w-24 px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
                                            placeholder="#"
                                        />
                                        {errors?.no && (
                                            <p className="mt-1 text-sm text-red-600">{errors.no}</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col sm:flex-row justify-between pt-6 gap-3 sm:gap-0">
                                <button
                                    onClick={() => setShowCancelWarning(true)}
                                    className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors"
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faTimesCircle} />
                                    Cancel
                                </button>
                                <button
                                    onClick={onSubmitClick}
                                    className="inline-flex items-center justify-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faCheckCircle} />
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </div>
        </Layout>
    );
}

export default AdminVideoCategoryUpdate;
