import React, { useState } from "react";
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faFile, faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { postAttachmentCreateAPI, deleteAttachmentAPI } from "../../API/Attachment";
import { topAlertMessageState, topAlertStatusState } from "../../AppState";

function FormAttachmentField({
    label,
    name,
    placeholder="Choose a file...",
    attachmentFilename="",
    setAttachmentFilename=null,
    attachmentID="",
    setAttachmentID=null,
    errorText,
    validationText,
    helpText,
    maxWidth="100%",
    disabled=false
}) {
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);

    // Event handlers
    const onHandleFileChange = (event) => {
        setFetching(true);
        setErrors({});

        console.log("onHandleFileChange: Starting...");
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('ownership_type', "1");

        postAttachmentCreateAPI(
            formData,
            onCreateSuccess,
            onCreateError,
            onCreateDone
        );
    };

    const onDeleteClick = () => {
        setFetching(true);
        setErrors({});

        deleteAttachmentAPI(
            attachmentID,
            onDeleteSuccess,
            onDeleteError,
            onDeleteDone
        );
    }

    // API Callbacks
    const onCreateSuccess = (response) => {
        if (setAttachmentFilename) {
            setAttachmentFilename(response.filename);
        }
        if (setAttachmentID) {
            setAttachmentID(response.id);
        }
        
        setTopAlertStatus("success");
        setTopAlertMessage("File uploaded successfully!");
    }

    const onCreateError = (apiErr) => setErrors(apiErr);
    const onCreateDone = () => setFetching(false);
    
    const onDeleteSuccess = (response) => {
        if (setAttachmentFilename) {
            setAttachmentFilename("");
        }
        if (setAttachmentID) {
            setAttachmentID("");
        }
        
        setTopAlertStatus("success");
        setTopAlertMessage("File deleted successfully!");
    }

    const onDeleteError = (apiErr) => setErrors(apiErr);
    const onDeleteDone = () => setFetching(false);

    return (
        <div className="space-y-4">
            {/* Label Section */}
            <div className="flex justify-between items-center">
                <label className="block text-sm font-medium text-gray-700" htmlFor={name}>
                    {label}
                </label>
                {validationText && (
                    <span className="text-xs text-gray-500">{validationText}</span>
                )}
            </div>

            {/* Upload Section */}
            <div className={`relative ${errorText ? 'animate-shake' : ''}`}>
                {isFetching ? (
                    <div className="flex items-center justify-center p-4 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg">
                        <FontAwesomeIcon 
                            icon={faSpinner} 
                            className="text-primary text-xl animate-spin" 
                        />
                        <span className="ml-3 text-gray-600">Processing...</span>
                    </div>
                ) : (
                    <>
                        {attachmentID ? (
                            <div className="flex items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
                                <div className="flex items-center space-x-3">
                                    <FontAwesomeIcon icon={faFile} className="text-primary text-xl" />
                                    <span className="text-gray-700 truncate max-w-xs">
                                        {attachmentFilename}
                                    </span>
                                </div>
                                <button
                                    type="button"
                                    onClick={onDeleteClick}
                                    className="p-2 text-red-500 hover:text-red-600 hover:bg-red-50 rounded-full transition-colors duration-300"
                                    disabled={disabled}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </div>
                        ) : (
                            <div className="group relative">
                                <input
                                    id={name}
                                    name={name}
                                    type="file"
                                    onChange={onHandleFileChange}
                                    disabled={disabled}
                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                                />
                                <div className={`
                                    flex flex-col items-center justify-center p-6
                                    border-2 border-dashed rounded-lg
                                    ${errorText 
                                        ? 'border-red-300 bg-red-50' 
                                        : 'border-gray-300 bg-gray-50 group-hover:border-primary/60 group-hover:bg-primary/5'
                                    }
                                    transition-all duration-300
                                `}>
                                    <FontAwesomeIcon 
                                        icon={faCloudUpload} 
                                        className={`text-3xl mb-2 ${errorText ? 'text-red-400' : 'text-gray-400 group-hover:text-primary'}`}
                                    />
                                    <p className={`text-sm ${errorText ? 'text-red-600' : 'text-gray-500'}`}>
                                        {placeholder}
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>

            {/* Error & Help Text */}
            {errorText && (
                <p className="text-sm text-red-600 mt-1">{errorText}</p>
            )}
            {helpText && (
                <p className="text-sm text-gray-500 mt-1">{helpText}</p>
            )}
        </div>
    );
}

export default FormAttachmentField;