import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlay, 
  faVideo, 
  faEye, 
  faClock, 
  faExclamationTriangle,
  faLayerGroup,
  faFolder
} from '@fortawesome/free-solid-svg-icons';

const CardLayout = ({ item, viewMode = "grid", emptyStateMessage }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Handle empty state
  if (!item) {
    return (
      <div className="col-span-full flex flex-col items-center justify-center bg-white rounded-xl shadow p-8 text-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-4xl text-gray-400 mb-4" />
        <h3 className="text-lg font-semibold text-gray-900 mb-2">No Data Available</h3>
        <p className="text-sm text-gray-500">{emptyStateMessage || "Please try again later."}</p>
      </div>
    );
  }

  // Determine icon and route based on content type
  const getTypeConfig = () => {
    switch (item.type) {
      case 'category':
        return {
          icon: faFolder,
          route: `/videos/category/${item.id}`,
          count: `${item.videoCount || 0} collections`
        };
      case 'collection':
        return {
          icon: faLayerGroup,
          route: `/videos/collection/${item.id}`,
          count: `${item.videoCount || 0} videos`
        };
      case 'video':
        return {
          icon: faPlay,
          route: `/videos/watch/${item.id}`,
          count: item.duration || '0:00'
        };
      default:
        return {
          icon: faVideo,
          route: '#',
          count: 'Unknown type'
        };
    }
  };

  const { icon, route, count } = getTypeConfig();

  return (
    <Link
      to={route}
      className={`flex ${viewMode === "list" ? "flex-row" : "flex-col"} 
                 bg-white rounded-xl overflow-hidden hover:bg-gray-50 
                 transition-all duration-300 group border border-gray-100`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Thumbnail Section */}
      <div className={`relative ${viewMode === "list" ? "w-64 h-36" : "aspect-video"}`}>
        <img
          src={item.thumbnailObjectUrl || item.thumbnailUrl}
          alt={item.name || "Thumbnail"}
          className="w-full aspect-video object-cover transition-transform duration-500 
                   group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-black/40 flex items-center justify-center 
                      opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <FontAwesomeIcon
            icon={icon}
            className="text-white text-4xl transform scale-0 group-hover:scale-100 
                     transition-transform duration-300"
          />
        </div>
        {/* <div className="absolute bottom-2 right-2 bg-black/80 px-2 py-1 rounded text-white text-xs">
          {count}
        </div> */}
      </div>

      {/* Content Section */}
      <div className="p-4">
        <div className="flex items-start gap-3">
          <div className="w-10 h-10 rounded-full bg-gradient-to-br from-primary to-primary/80 
                        flex items-center justify-center flex-shrink-0">
            <FontAwesomeIcon icon={icon} className="text-white" />
          </div>
          <div>
            <h3 className="font-semibold text-gray-900 line-clamp-2 mb-1">
              {item.name || "Untitled"}
            </h3>
            <div className="flex items-center gap-3 text-xs text-gray-500">
              <span className="flex items-center gap-1">
                <FontAwesomeIcon icon={faEye} />
                {item.viewCount || 0}
              </span>
              <span>•</span>
              <span>{item.lastUpdated || "Recently updated"}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardLayout;