import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const DataDisplayRowURL = ({ 
    label, 
    urlKey, 
    urlValue, 
    value, 
    helpText, 
    type = "" 
}) => {
    const baseClasses = "text-primary hover:text-primary-dark transition-colors duration-200";
    const externalLinkClasses = "inline-flex items-center gap-2";

    const renderLink = () => {
        // Handle single value URL
        if (value) {
            const linkProps = {
                className: `${baseClasses} ${externalLinkClasses}`,
                to: value,
                target: "_blank",
                rel: "noreferrer"
            };

            return (
                <Link {...linkProps}>
                    <span>{value}</span>
                    <FontAwesomeIcon 
                        icon={faArrowUpRightFromSquare} 
                        className="w-3.5 h-3.5"
                    />
                </Link>
            );
        }

        // Handle key-value pair URL
        if (!urlKey || !urlValue) {
            return <span className="text-gray-500">-</span>;
        }

        const linkProps = {
            className: type === "external" ? `${baseClasses} ${externalLinkClasses}` : baseClasses,
            to: urlValue,
            ...(type === "external" && {
                target: "_blank",
                rel: "noreferrer"
            })
        };

        return (
            <Link {...linkProps}>
                <span>{urlKey}</span>
                {type === "external" && (
                    <FontAwesomeIcon 
                        icon={faArrowUpRightFromSquare} 
                        className="w-3.5 h-3.5"
                    />
                )}
            </Link>
        );
    };

    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <div className="text-gray-900">
                    {renderLink()}
                </div>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
};

export default DataDisplayRowURL;