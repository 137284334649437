import { faFileImport, faArrowDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDrop } from "react-dnd";

function DropZone({ onDrop, className = "", children, placeholder }) {
  const [{ isOver }, dropRef] = useDrop({
    accept: "item",
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={dropRef}
      className={`
        relative min-h-[20rem] rounded-xl transition-all duration-500 
        backdrop-blur-sm shadow-lg overflow-hidden
        ${isOver 
          ? 'border-2 border-dashed border-primary bg-gradient-to-b from-primary-light/25 to-primary-light/10' 
          : 'border border-gray-200 hover:border-primary/40 bg-white hover:shadow-xl'
        }
        ${className}
      `}
    >
      {/* Background Animation */}
      <div className={`
        absolute inset-0 bg-gradient-to-r from-primary-light/0 via-primary-light/10 to-primary-light/0
        transition-opacity duration-500
        ${isOver ? 'animate-gradient-x opacity-100' : 'opacity-0'}
      `} />

      {placeholder && (
        <div className="relative inset-0 flex items-center justify-center">
          <div className="flex flex-col items-center space-y-6 p-8 max-w-md">
            {/* Icon Container with Enhanced Gradient */}
            <div className={`
              relative p-6 rounded-full 
              bg-gradient-to-b from-gray-50 to-gray-100
              shadow-lg transform transition-all duration-500 ease-out
              group hover:shadow-xl
              ${isOver ? 'scale-110 rotate-3' : 'scale-100 rotate-0'}
            `}>
              {/* Ripple Effect */}
              <div className={`
                absolute inset-0 rounded-full
                transition-transform duration-1000
                ${isOver ? 'animate-pulseGlow' : ''}
              `} style={{ '--tw-shadow-color': 'rgba(193, 76, 0, 0.2)' }} />

              {/* Main Icon */}
              <div className="animate-float">
                <FontAwesomeIcon
                  icon={faFileImport}
                  className={`
                    h-12 w-12 sm:h-16 sm:w-16 transition-all duration-300
                    transform group-hover:scale-110
                    ${isOver ? 'text-primary' : 'text-gray-400'}
                  `}
                />
              </div>
              
              {/* Animated Plus Icons */}
              {isOver && (
                <>
                  <div className="absolute -top-2 -right-2 animate-ping-slow">
                    <FontAwesomeIcon icon={faPlus} className="h-4 w-4 text-primary-light" />
                  </div>
                  <div className="absolute -bottom-1 -left-3 animate-ping-slow delay-300">
                    <FontAwesomeIcon icon={faPlus} className="h-3 w-3 text-primary-light" />
                  </div>
                </>
              )}

              {/* Enhanced Arrow Animation */}
              <div className={`
                absolute -bottom-4 left-1/2 transform -translate-x-1/2
                transition-all duration-500
                ${isOver ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}
              `}>
                <FontAwesomeIcon
                  icon={faArrowDown}
                  className="h-6 w-6 text-primary animate-bounce-gentle"
                />
              </div>
            </div>

            {/* Text Content with Enhanced Animation */}
            <div className="text-center space-y-3 transform transition-all duration-500">
              <h3 className={`
                text-base sm:text-lg font-semibold
                transition-all duration-300 relative
                ${isOver ? 'text-primary translate-y-0' : 'text-gray-700 translate-y-0'}
              `}>
                <span className="relative inline-block">
                  Drop items here
                  {isOver && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-primary 
                                   transform origin-left animate-expand-width" />
                  )}
                </span>
              </h3>
              <p className="text-sm sm:text-base text-gray-500 max-w-[280px] leading-relaxed
                          transition-all duration-300">
                Drag and drop items or click to select from your list
              </p>
            </div>

            {/* Enhanced Status Indicator */}
            <div className={`
              transition-all duration-500 ease-out transform
              ${isOver ? 'opacity-100 translate-y-0 scale-100' : 'opacity-0 translate-y-4 scale-95'}
            `}>
              <span className="inline-flex items-center px-4 py-2 rounded-full 
                             bg-gradient-to-r from-primary-light/20 via-primary/20 to-primary-light/20
                             text-primary text-sm font-medium
                             animate-gradient-x">
                Release to drop
              </span>
            </div>
          </div>
        </div>
      )}
      
      {/* Content Container with Fade */}
      <div className={`
        h-full w-full transition-all duration-500 ease-out
        ${placeholder ? 'opacity-0 scale-95' : 'opacity-100 scale-100'}
      `}>
        {children}
      </div>

      {/* Enhanced Active State Overlay */}
      {isOver && (
        <>
          <div className="absolute inset-0 bg-primary/5 
                        rounded-xl pointer-events-none
                        animate-pulse duration-1000" />
          <div className="absolute inset-0 bg-gradient-to-b from-primary-light/5 to-transparent 
                        rounded-xl pointer-events-none animate-gradient-y" />
        </>
      )}
    </div>
  );
}

export default DropZone;