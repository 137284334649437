import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPencil,
  faArrowLeft,
  faEye,
  faGauge,
  faCheckCircle,
  faRibbon,
  faLevelUp,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import { deleteLevelAPI, getLevelDetailAPI } from "../../../API/level";
import Modal from "../../Reusable/Modal";

function AdminLevelDetail() {
  // URL Parameters.
  const { id } = useParams();

  // Global state.
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  // Component states.
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [level, setLevel] = useState({});
  const [selectedLevelForDeletion, setSelectedLevelForDeletion] =
    useState(null);

  // Breadcrumb items
  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Levels", link: "/admin/levels", icon: faLevelUp },
    { label: "Detail", icon: faEye },
  ];

  //// Event handling
  const onDeleteConfirmButtonClick = () => {
    deleteLevelAPI(
      selectedLevelForDeletion.id,
      onLevelDeleteSuccess,
      onLevelDeleteError,
      onLevelDeleteDone
    );
    setSelectedLevelForDeletion(null);
  };

  //// API
  const onLevelDetailSuccess = (response) => {
    setLevel(response);
  };

  const onLevelDetailError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  };

  const onLevelDetailDone = () => {
    setFetching(false);
  };

  const onLevelDeleteSuccess = () => {
    setTopAlertStatus("success");
    setTopAlertMessage("Badge deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/levels");
  };

  const onLevelDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to delete badge");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  };

  const onLevelDeleteDone = () => {
    setFetching(false);
  };

  //// Misc
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on mount
    setFetching(true);
    getLevelDetailAPI(
      id,
      onLevelDetailSuccess,
      onLevelDetailError,
      onLevelDetailDone
    );
  }, [id]);

  //// Component rendering
  if (forceURL) {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <div className="animate-fade-in">
        <div className="bg-white rounded-xl shadow-lg border border-gray-200 p-8">
          {isFetching ? (
            <PageLoadingContent displayMessage="Loading level details..." />
          ) : errors.notFound ? (
            <div className="p-4 bg-red-50 text-red-700 rounded-lg border border-red-100">
              {errors.notFound}
            </div>
          ) : (
            <>
              {/* Enhanced Header Section */}
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
                <div className="flex items-center">
                  <div className="bg-primary/10 p-3 rounded-lg mr-4">
                    <FontAwesomeIcon icon={faLevelUp} className="text-primary text-2xl" />
                  </div>
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">{level.name}</h1>
                    <p className="text-gray-500 text-sm mt-1">Level Details</p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <Link
                    to={`/admin/levels/${id}/edit`}
                    className="inline-flex items-center px-4 py-2 bg-primary hover:bg-amber-600 text-white rounded-lg transition-all duration-300 shadow-sm hover:shadow-md"
                  >
                    <FontAwesomeIcon icon={faPencil} className="mr-2" />
                    Edit
                  </Link>
                  <button
                    onClick={() => setSelectedLevelForDeletion(level)}
                    className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-all duration-300 shadow-sm hover:shadow-md"
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    Delete
                  </button>
                </div>
              </div>

              <FormErrorBox errors={errors} />

              {/* Enhanced Content Grid */}
              <div className="grid md:grid-cols-2 gap-8 mt-8">
                {/* Left Column - Details */}
                <div className="space-y-8">
                  <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
                      Basic Information
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Name</h4>
                        <p className="mt-2 text-lg font-medium text-gray-900">{level.name}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Description</h4>
                        <p className="mt-2 text-gray-700 whitespace-pre-wrap">{level.description}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Challenges Required</h4>
                        <p className="mt-2 text-lg font-medium text-gray-900">{level.numChallenges}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right Column - Thumbnail and Metadata */}
                <div className="space-y-8">
                  <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
                      Level Thumbnail
                    </h3>
                    {level.thumbnailUrl ? (
                      <div className="relative w-40 h-40 rounded-xl overflow-hidden border-2 border-gray-200 shadow-sm mx-auto">
                        <img 
                          src={level.thumbnailUrl} 
                          alt={`${level.name} thumbnail`}
                          className="w-full h-full object-cover transition-transform hover:scale-110 duration-300"
                        />
                      </div>
                    ) : (
                      <div className="text-gray-500 italic text-center">No thumbnail available</div>
                    )}
                  </div>

                  <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
                      Metadata
                    </h3>
                    <div className="space-y-4">
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Organization</h4>
                        <p className="mt-2 text-gray-700">{level.organizationName}</p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Created</h4>
                        <p className="mt-2 text-gray-700">
                          {new Date(level.createdAt).toLocaleDateString()} by {level.createdByUserName}
                        </p>
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-500">Last Modified</h4>
                        <p className="mt-2 text-gray-700">
                          {new Date(level.modifiedAt).toLocaleDateString()} by {level.modifiedByUserName}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Enhanced Footer */}
              <div className="mt-8 pt-6 border-t border-gray-200">
                <Link 
                  to="/admin/levels"
                  className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-all duration-300 shadow-sm hover:shadow-md group"
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2 group-hover:-translate-x-1 transition-transform duration-300" />
                  Back to Levels
                </Link>
              </div>
            </>
          )}
        </div>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={!!selectedLevelForDeletion}
          onClose={() => setSelectedLevelForDeletion(null)}
          header="Confirm Deletion"
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              Are you sure you want to delete the level "{selectedLevelForDeletion?.name}"?
              This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setSelectedLevelForDeletion(null)}
                className="px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={onDeleteConfirmButtonClick}
                disabled={isFetching}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-all duration-300 disabled:bg-gray-400"
              >
                {isFetching ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
}

export default AdminLevelDetail;