import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const FormInputPasswordField = ({
  label,
  name,
  placeholder,
  value,
  errorText,
  validationText,
  helpText,
  onChange,
  maxWidth,
  disabled = false,
  ref,
  icon
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-4" style={{ maxWidth }}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FontAwesomeIcon 
              icon={icon} 
              className="h-4 w-4 text-gray-400"
            />
          </div>
        )}
        <input
          id={name}
          ref={ref}
          type={showPassword ? "text" : "password"}
          className={`w-full ${icon ? 'pl-10' : 'pl-4'} pr-12 py-2 border rounded-md focus:outline-none focus:ring-2 ${
            errorText ? 'border-red-500 focus:ring-red-200' : 
            validationText ? 'border-green-500 focus:ring-green-200' :
            'border-gray-300 focus:ring-primary focus:border-primary'
          }`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <FontAwesomeIcon 
            icon={showPassword ? faEye : faEyeSlash}
            className="h-4 w-4"
          />
        </button>
      </div>
      {errorText && <p className="mt-1 text-sm text-red-600">{errorText}</p>}
      {validationText && <p className="mt-1 text-sm text-green-600">{validationText}</p>}
      {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}
    </div>
  );
};

export default FormInputPasswordField;