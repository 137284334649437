import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark, faFileInvoice, faIdBadge, faUserTag,
  faHourglassHalf, faListCheck, faPhone, faMapMarker,
  faCalendar, faCheckCircle, faTimesCircle, faSpinner,
  faTag, faClipboardList, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

// Profile Sidebar Component
export const ProfileSidebar = ({ datum }) => (
  <div className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 overflow-hidden">
    {/* Profile Header */}
    <div className="relative h-32 bg-gradient-to-r from-primaryColor to-burntOrange">
      <div className="absolute -bottom-12 left-6">
        {datum?.avatarObjectUrl ? (
          <img
            src={datum.avatarObjectUrl}
            alt=""
            className="w-24 h-24 rounded-2xl border-4 border-white shadow-lg object-cover"
          />
        ) : (
          <div className="w-24 h-24 rounded-2xl border-4 border-white shadow-lg
                        bg-gradient-to-br from-primaryColor to-burntOrange
                        flex items-center justify-center">
            <span className="text-3xl font-bold text-white">
              {datum?.firstName?.[0]}
            </span>
          </div>
        )}
      </div>
      <div className="absolute top-4 right-4">
        <span className={`
          px-3 py-1 rounded-full text-xs font-medium
          ${datum?.status === 1 
            ? 'bg-greenShade1 text-white' 
            : 'bg-gray-200 text-gray-600'}
        `}>
          {datum?.status === 1 ? 'Active' : 'Inactive'}
        </span>
      </div>
    </div>

    {/* Profile Info */}
    <div className="pt-14 p-6">
      <h3 className="text-xl font-bold text-textDark">{datum?.name}</h3>
      <p className="text-sm text-gray-500 mt-1">{datum?.email}</p>
      
      <div className="mt-6 space-y-4">
        {[
          { icon: faPhone, value: datum?.phone },
          { 
            icon: faMapMarker, 
            value: [datum?.city, datum?.region, datum?.country].filter(Boolean).join(', ') 
          },
          { 
            icon: faCalendar, 
            value: datum?.createdAt && `Joined ${new Date(datum.createdAt).toLocaleDateString()}` 
          }
        ].map((item, index) => (
          item.value && (
            <div key={index} className="flex items-center text-sm">
              <FontAwesomeIcon icon={item.icon} className="w-4 h-4 text-primaryColor" />
              <span className="ml-3 text-gray-600">{item.value}</span>
            </div>
          )
        ))}
      </div>

      {/* Quick Stats */}
      <div className="mt-6 pt-6 border-t border-gray-100">
        <div className="grid grid-cols-2 gap-4">
          <div className="text-center">
            <div className="text-2xl font-bold text-primaryColor">
              {datum?.totalChallengesCompleted || 0}
            </div>
            <div className="text-xs text-gray-500 mt-1">Challenges</div>
          </div>
          <div className="text-center">
            <div className="text-2xl font-bold text-primaryColor">
              {datum?.badgesEarned?.length || 0}
            </div>
            <div className="text-xs text-gray-500 mt-1">Badges</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

// Activity Card Component
export const ActivityCard = ({ title, icon, count, trend, color = "from-primary to-primaryDark" }) => (
  <div className="relative group">
    <div className={`absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100
                    transition-opacity duration-200 blur-xl bg-gradient-to-r ${color}`} />
    <div className="relative bg-white rounded-xl p-4 border border-primaryColor/5
                    hover:border-transparent transition-colors duration-200">
      <div className="flex justify-between items-start">
        <h4 className="text-sm font-medium text-gray-600">{title}</h4>
        <FontAwesomeIcon icon={icon} className="text-primaryColor" />
      </div>
      <p className="mt-2 text-2xl font-bold text-textDark">{count}</p>
      <p className="mt-1 text-xs text-gray-500">{trend}</p>
    </div>
  </div>
);

// Navigation Component
export const DetailNavigation = ({ activeSection, setActiveSection }) => {
  const sections = [
    { id: 'profile', label: 'Profile & Activity', icon: faIdBadge },
    { id: 'bookings', label: 'Bookings', icon: faBookmark },
    { id: 'invoices', label: 'Invoices', icon: faFileInvoice },
    { id: 'waitlist', label: 'Waitlist', icon: faHourglassHalf },
    { id: 'tags', label: 'Tags', icon: faUserTag },
    { id: 'profile-tasks', label: 'Profile Tasks', icon: faListCheck }
  ];

  return (
    <div className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 overflow-hidden">
      <div className="p-4 bg-gray-50 border-b border-gray-100">
        <h3 className="text-sm font-medium text-gray-700">Member Details</h3>
      </div>
      <nav className="p-2">
        {sections.map((section) => (
          <button
            key={section.id}
            onClick={() => setActiveSection(section.id)}
            className={`w-full flex items-center space-x-3 px-4 py-3 rounded-xl text-left transition-all duration-200
              ${activeSection === section.id 
                ? 'bg-primary text-white shadow-lg shadow-primary/30' 
                : 'text-gray-600 hover:bg-gray-50'}`}
          >
            <FontAwesomeIcon 
              icon={section.icon} 
              className={`w-4 h-4 ${activeSection === section.id ? 'text-white' : 'text-gray-400'}`} 
            />
            <span className="text-sm font-medium">{section.label}</span>
          </button>
        ))}
      </nav>
    </div>
  );
};

// Info Item Component
export const InfoItem = ({ icon, label, value }) => (
  <div className="flex items-start">
    {icon && <FontAwesomeIcon icon={icon} className="w-4 h-4 text-primaryColor mt-1 mr-3" />}
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="text-sm font-medium text-gray-900">{value || 'N/A'}</p>
    </div>
  </div>
);