import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faGauge,
	faPencil,
	faQuestionCircle,
	faEye,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
	deleteQuestionnaireAPI,
	getQuestionnaireDetailAPI,
} from "../../../API/questionnaire";
import Layout from "../../Menu/Layout";
import {
	QUESTIONNAIRE_STATUS_MAP,
	QUESTIONNAIRE_STATUS_OPTIONS,
} from "../../../Constants/FieldOptions";

function AdminQuestionnaireDetail() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedQuestionForDeletion, setSelectedQuestionForDeletion] =
		useState(null);

	const onDeleteConfirmButtonClick = () => {
		deleteQuestionnaireAPI(
			selectedQuestionForDeletion.id,
			OnQuestionDeleteSuccess,
			OnQuestionDeleteError,
			OnQuestionDeleteDone
		);
		setSelectedQuestionForDeletion(null);
	};

	// --- Detail --- //

	function OnQuestionSuccess(response) {
		setDatum(response);
	}

	function OnQuestionError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function OnQuestionDone() {
		setFetching(false);
	}

	// --- Delete --- //

	function OnQuestionDeleteSuccess(response) {
		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Question deleted successfully");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect back to the video categories page.
		setForceURL("/admin/questions");
	}

	function OnQuestionDeleteError(apiErr) {
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			console.log(topAlertMessage, topAlertStatus);
			setTopAlertMessage("");
		}, 2000);

		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function OnQuestionDeleteDone() {
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getQuestionnaireDetailAPI(
				id,
				OnQuestionSuccess,
				OnQuestionError,
				OnQuestionDone
			);
		}

		return () => {
			mounted = false;
		};
	}, []);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Questions", link: "/admin/questions", icon: faQuestionCircle},
		{label: "Detail", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50/50">
				<div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
					{/* Main Content */}
					<div className="bg-white rounded-xl shadow-sm border border-gray-200">
						{datum && (
							<div className="flex flex-col sm:flex-row justify-between items-start sm:items-center p-4 sm:p-6 border-b border-gray-200">
								<h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center mb-4 sm:mb-0">
									<FontAwesomeIcon icon={faQuestionCircle} className="mr-3" />
									Question
								</h1>
								<div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
									<Link
										to={`/admin/questions/${id}/update`}
										className="inline-flex items-center justify-center px-4 py-2 bg-yellow-500 hover:bg-yellow-600 
                           text-white rounded-lg transition-colors duration-300">
										<FontAwesomeIcon icon={faPencil} className="mr-2" />
										Edit
									</Link>
									<button
										onClick={() => setSelectedQuestionForDeletion(datum)}
										className="inline-flex items-center justify-center px-4 py-2 bg-red-600 hover:bg-red-700 
                           text-white rounded-lg transition-colors duration-300">
										<FontAwesomeIcon icon={faTrash} className="mr-2" />
										Delete
									</button>
								</div>
							</div>
						)}

						<FormErrorBox errors={errors} />

						<div className="p-4 sm:p-6">
							{isFetching ? (
								<PageLoadingContent displayMessage={"Please wait..."} />
							) : (
								datum && (
									<div className="space-y-6">
										<div className="border-b border-gray-200 pb-4">
											<h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
												<FontAwesomeIcon icon={faEye} className="mr-2" />
												Detail
											</h2>
											<div className="space-y-4">
												<DataDisplayRowText label="Title" value={datum.title} />
												{datum.subtitle && (
													<DataDisplayRowText
														label="Subtitle"
														value={datum.subtitle}
													/>
												)}
												<DataDisplayRowText
													label="Status"
													type="jsx"
													value={
														<span
															className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
            ${
							datum.status
								? "bg-green-100 text-green-800"
								: "bg-gray-100 text-gray-800"
						}`}>
															{QUESTIONNAIRE_STATUS_MAP[datum.status]}
														</span>
													}
												/>
												<DataDisplayRowText
													label="IsMultiSelect?"
													value={datum.isMultiselect ? "Yes" : "No"}
												/>
												{datum.options && (
													<div>
														<dt className="text-sm font-medium text-gray-500">
															Options
														</dt>
														<dd className="mt-1">
															<ul className="list-disc pl-5 space-y-1">
																{datum.options.map((op, i) => (
																	<li key={i} className="text-gray-900">
																		{op}
																	</li>
																))}
															</ul>
														</dd>
													</div>
												)}
											</div>
										</div>

										<div className="flex flex-col sm:flex-row justify-between gap-4">
											<Link
												to="/admin/questions"
												className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 
                               text-gray-700 bg-white rounded-lg hover:bg-gray-50 transition-colors duration-300">
												<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
												Back to Questions
											</Link>
											<Link
												to={`/admin/questions/${id}/update`}
												className="inline-flex items-center justify-center px-4 py-2 bg-yellow-500 
                               hover:bg-yellow-600 text-white rounded-lg transition-colors duration-300">
												<FontAwesomeIcon icon={faPencil} className="mr-2" />
												Edit
											</Link>
										</div>
									</div>
								)
							)}
						</div>
					</div>

					{/* Delete Modal */}
					<Modal
						isOpen={!!selectedQuestionForDeletion}
						onClose={() => setSelectedQuestionForDeletion(null)}
						header="Confirm Deletion">
						<div className="space-y-4">
							<p className="text-gray-600">
								You are about to delete this questionnaire and all associated
								data. This action cannot be undone. Are you sure you want to
								continue?
							</p>
							<div className="flex justify-end space-x-3">
								<button
									onClick={() => setSelectedQuestionForDeletion(null)}
									className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 
                         rounded-lg hover:bg-gray-50 transition-colors duration-300">
									Cancel
								</button>
								<button
									onClick={onDeleteConfirmButtonClick}
									className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg 
                         hover:bg-red-700 transition-colors duration-300">
									Delete
								</button>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		</Layout>
	);
}

export default AdminQuestionnaireDetail;
