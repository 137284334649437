import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faHeart, faClock, faEye } from '@fortawesome/free-solid-svg-icons';
import {
    EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
    EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL
} from "../../../Constants/App";

function MemberExerciseListMobile({
    description,
    name,
    thumbnailUrl,
    thumbnailObjectUrl,
    thumbnailType,
    gender,
    id
}) {
    return (
        <div>
            <article className="bg-white rounded-2xl overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl">
                {/* Image Section with Overlay */}
                <div className="relative aspect-[16/9]">
                    <img 
                        src={thumbnailObjectUrl} 
                        alt={name}
                        className="w-full h-full object-cover"
                    />
                    
                    {/* Gradient Overlay */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent" />
                    
                    {/* Play Button Overlay */}
                    <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-white/30 backdrop-blur-md rounded-full flex items-center justify-center border border-white/50 hover:bg-white/40 transition-all duration-300">
                        <FontAwesomeIcon icon={faPlay} className="text-white ml-1" />
                    </button>
                    
                    {/* Top Right Badge */}
                    <div className="absolute top-4 right-4">
                        <span className="px-3 py-1.5 bg-white/10 backdrop-blur-md rounded-full text-white text-sm font-medium">
                            {gender}
                        </span>
                    </div>
                    
                    {/* Bottom Left Time */}
                    <div className="absolute bottom-4 left-4 flex items-center">
                        <FontAwesomeIcon icon={faClock} className="text-white/90 w-4 h-4" />
                        <span className="ml-2 text-white/90 text-sm">15 min</span>
                    </div>
                    
                    {/* Bottom Right Like */}
                    <button className="absolute bottom-4 right-4 w-8 h-8 bg-white/10 backdrop-blur-md rounded-full flex items-center justify-center hover:bg-white/20 transition-all duration-300">
                        <FontAwesomeIcon icon={faHeart} className="text-white/90" />
                    </button>
                </div>

                {/* Content Section */}
                <div className="py-5">
                    <div className="flex items-start justify-between">
                        <div className="flex-1">
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">
                                {name}
                            </h3>
                            <p className="text-sm text-gray-600 line-clamp-2 mb-3">
                                {description}
                            </p>
                        </div>
                    </div>

                    {/* Tags */}
                    <div className="flex flex-wrap gap-2 mb-4">
                        <span className="px-3 py-1 bg-indigo-50 text-indigo-600 rounded-full text-xs font-medium">
                            Beginner
                        </span>
                        <span className="px-3 py-1 bg-orange-50 text-orange-600 rounded-full text-xs font-medium">
                            High Intensity
                        </span>
                        <span className="px-3 py-1 bg-green-50 text-green-600 rounded-full text-xs font-medium">
                            Full Body
                        </span>
                    </div>

                    {/* Action Button */}
                    <Link 
                        to={`/exercise/${id}`}
                        className="block w-full py-3 px-4 bg-gradient-to-r from-main to-darkOrangeShade text-white text-center rounded-xl font-medium  transition-all duration-300 shadow-lg shadow-primary-500/30"
                    >
                        <FontAwesomeIcon 
              icon={faEye} 
              className="mr-2 group-hover:scale-110 transition-transform duration-200" 
            />
            <span className="font-medium">View</span>
                    </Link>
                </div>
            </article>
        </div>
    );
}

export default MemberExerciseListMobile;