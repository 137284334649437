import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faTachometer,
  faPlus,
  faTimesCircle,
  faCheckCircle,
  faUserCircle,
  faGauge,
  faPencil,
  faTrophy,
  faIdCard,
  faAddressBook,
  faMessage,
  faChartPie,
  faCogs,
  faEye,
  faArrowLeft,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import {
  getFitnessPlanDetailAPI,
  postFitnessPlanCreateAPI,
} from "../../../API/FitnessPlan";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormRadioField from "../../Reusable/FormRadioField";
import FormInputField from "../../Reusable/FormInputField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormAlternateDateField from "../../Reusable/FormAlternateDateField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormDuelSelectField from "../../Reusable/FormDuelSelectField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";
import {
  HOME_GYM_EQUIPMENT_OPTIONS,
  FEET_WITH_EMPTY_OPTIONS,
  INCHES_WITH_EMPTY_OPTIONS,
  GENDER_WITH_EMPTY_OPTIONS,
  PHYSICAL_ACTIVITY_WITH_EMPTY_OPTIONS,
  WORKOUT_INTENSITY_WITH_EMPTY_OPTIONS,
  DAYS_PER_WEEK_WITH_EMPTY_OPTIONS,
  TIME_PER_DAY_WITH_EMPTY_OPTIONS,
  MAX_WEEK_WITH_EMPTY_OPTIONS,
  FITNESS_GOAL_OPTIONS,
  WORKOUT_PREFERENCE_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
  GENDER_OTHER,
  GENDER_MALE,
  GENDER_FEMALE,
  PHYSICAL_ACTIVITY_SEDENTARY,
  PHYSICAL_ACTIVITY_LIGHTLY_ACTIVE,
  PHYSICAL_ACTIVITY_MODERATELY_ACTIVE,
  PHYSICAL_ACTIVITY_VERY_ACTIVE,
  WORKOUT_INTENSITY_LOW,
  WORKOUT_INTENSITY_MEDIUM,
  WORKOUT_INTENSITY_HIGH,
} from "../../../Constants/App";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function MemberFitnessPlanAdd() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  // Page related states.
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Form data related states.
  const [name, setName] = useState("");
  const [equipmentAccess, setEquipmentAccess] = useState(0);
  const [homeGymEquipment, setHomeGymEquipment] = useState([]);
  const [hasWorkoutsAtHome, setHasWorkoutsAtHome] = useState(0);
  const [birthday, setBirthday] = useState(null);
  const [heightFeet, setHeightFeet] = useState(-1);
  const [heightInches, setHeightInches] = useState(-1);
  const [weight, setWeight] = useState(0);
  const [gender, setGender] = useState(0);
  const [genderOther, setGenderOther] = useState("");
  const [idealWeight, setIdealWeight] = useState(0);
  const [physicalActivity, setPhysicalActivity] = useState(0);
  const [workoutIntensity, setWorkoutIntensity] = useState(0);
  const [daysPerWeek, setDaysPerWeek] = useState(0);
  const [timePerDay, setTimePerDay] = useState(0);
  const [maxWeeks, setMaxWeeks] = useState(0);
  const [goals, setGoals] = useState([]);
  const [workoutPreferences, setWorkoutPreferences] = useState([]);

  ////
  //// Event handling.
  ////

  ////
  //// API.
  ////

  const onSubmitClick = (e) => {
    console.log("onSubmitClick: Beginning...");
    setFetching(true);
    setErrors({});

    // To Snake-case for API from camel-case in React.
    const decamelizedData = {
      name: name,
      equipment_access: equipmentAccess,
      home_gym_equipment: homeGymEquipment,
      has_workouts_at_home: hasWorkoutsAtHome,
      birthday: birthday,
      height_feet: heightFeet,
      height_inches: heightInches,
      weight: weight,
      gender: gender,
      gender_other: genderOther,
      ideal_weight: idealWeight,
      physical_activity: physicalActivity,
      workout_intensity: workoutIntensity,
      days_per_week: daysPerWeek,
      time_per_day: timePerDay,
      max_weeks: maxWeeks,
      goals: goals,
      workout_preferences: workoutPreferences,
    };
    console.log("onSubmitClick, decamelizedData:", decamelizedData);
    postFitnessPlanCreateAPI(
      decamelizedData,
      onMemberFitnessPlanAddSuccess,
      onMemberFitnessPlanAddError,
      onMemberFitnessPlanAddDone
    );
  };

  function onMemberFitnessPlanAddSuccess(response) {
    // For debugging purposes only.
    console.log("onMemberFitnessPlanAddSuccess: Starting...");
    console.log(response);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Fitness plan created");
    setTopAlertStatus("success");
    setTimeout(() => {
      console.log("onMemberFitnessPlanAddSuccess: Delayed for 2 seconds.");
      console.log(
        "onMemberFitnessPlanAddSuccess: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // Redirect the user to a new page.
    setForceURL("/fitness-plan/" + response.id);
  }

  function onMemberFitnessPlanAddError(apiErr) {
    console.log("onMemberFitnessPlanAddError: Starting...");
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      console.log("onMemberFitnessPlanAddError: Delayed for 2 seconds.");
      console.log(
        "onMemberFitnessPlanAddError: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onMemberFitnessPlanAddDone() {
    console.log("onMemberFitnessPlanAddDone: Starting...");
    setFetching(false);
  }

  ////
  //// BREADCRUMB
  ////
  const breadcrumbItems = [
    {
      label: "Dashboard",
      icon: faGauge,
      link: "/dashboard",
    },
    {
      label: "Fitness Plans",
      icon: faLeaf,
      link: "/fitness-plans",
    },
    {
      label: "New",
      icon: faPlus,
    }
  ]
  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      Scroll.animateScroll.scrollToTop();
    }

    return () => {
      mounted = false;
    };
  }, []);
  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg">
          {/* Header */}
          <div className="p-6 border-b border-divider">
            <h1 className="text-2xl font-semibold text-darkShade">
              <FontAwesomeIcon className="mr-3" icon={faTrophy} />
              Create New Fitness Plan
            </h1>
          </div>

          {/* Form Content */}
          <div className="p-6 space-y-8">
            {errors && <FormErrorBox errors={errors} />}

            {/* Basic Info Section */}
            <section className="space-y-6">
              <div className="flex items-center space-x-2 text-lg font-medium text-darkShade">
                <FontAwesomeIcon icon={faIdCard} />
                <h2>Basic Information</h2>
              </div>

              <FormInputField
                label="Plan Name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={errors?.name}
                helpText="Give this fitness plan a name you can use to keep track."
                maxWidth="380px"
              />

              <FormRadioField
                label="Equipment Access"
                name="equipmentAccess"
                value={equipmentAccess}
                opt1Value={1}
                opt1Label="No Equipment"
                opt2Value={2}
                opt2Label="Full Gym Access"
                opt3Value={3}
                opt3Label="Home Gym"
                errorText={errors?.equipmentAccess}
                onChange={(e) => setEquipmentAccess(parseInt(e.target.value))}
              />
            </section>

            {/* Physical Details Section */}
            <section className="space-y-6">
              <div className="flex items-center space-x-2 text-lg font-medium text-darkShade">
                <FontAwesomeIcon icon={faUserCircle} />
                <h2>Physical Details</h2>
              </div>

              <div className="grid gap-6 md:grid-cols-2">
                <FormAlternateDateField
                  label="Birthday"
                  name="birthday"
                  value={birthday}
                  errorText={errors?.birthday}
                  onChange={(date) => setBirthday(date)}
                  maxWidth="180px"
                />

                <div className="flex space-x-4">
                  <FormSelectField
                    label="Height (feet)"
                    name="heightFeet"
                    placeholder="Pick feet"
                    selectedValue={heightFeet}
                    options={FEET_WITH_EMPTY_OPTIONS}
                    errorText={errors?.heightFeet}
                    onChange={(e) => setHeightFeet(parseInt(e.target.value))}
                    maxWidth="180px"
                  />
                  <FormSelectField
                    label="Height (inches)"
                    name="heightInches"
                    placeholder="Pick inches"
                    selectedValue={heightInches}
                    options={INCHES_WITH_EMPTY_OPTIONS}
                    errorText={errors?.heightInches}
                    onChange={(e) => setHeightInches(parseInt(e.target.value))}
                    maxWidth="180px"
                  />
                </div>
              </div>
            </section>

            {/* Goals Section */}
            <section className="space-y-6">
              <div className="flex items-center space-x-2 text-lg font-medium text-darkShade">
                <FontAwesomeIcon icon={faTrophy} />
                <h2>Fitness Goals</h2>
              </div>

              <FormMultiSelectField
            label="Enter your fitness goals"
            name="goals"
            placeholder="Text input"
            options={FITNESS_GOAL_OPTIONS}
            selectedValues={goals}
            onChange={(e) => {
              let values = [];
              for (let option of e) {
                values.push(option.value);
              }
              setGoals(values);
            }}
            errorText={errors && errors.goals}
            helpText=""
            isRequired={true}
            maxWidth="640px"
          />

          <FormMultiSelectField
            label="Enter your workout preferences"
            name="workoutPreferences"
            placeholder="Text input"
            options={WORKOUT_PREFERENCE_OPTIONS}
            selectedValues={workoutPreferences}
            onChange={(e) => {
              let values = [];
              for (let option of e) {
                values.push(option.value);
              }
              setWorkoutPreferences(values);
            }}
            errorText={errors && errors.workoutPreferences}
            helpText=""
            isRequired={true}
            maxWidth="640px"
          />
            </section>

            {/* Action Buttons */}
            <div className="flex justify-between pt-8 border-t border-divider">
              <button
                onClick={() => setShowCancelModal(true)}
                className="px-6 py-2 text-darkShade bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                Cancel
              </button>
              
              <button
                onClick={onSubmitClick}
                disabled={isFetching}
                className="px-6 py-2 text-white bg-accent1 rounded-md hover:bg-accent1/80 transition-colors duration-200 disabled:opacity-50"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Save & Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Confirmation Modal */}
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        header="Cancel Plan Creation"
      >
        <div className="space-y-4">
          <p className="text-gray-600">
            Are you sure you want to cancel creating this fitness plan? All entered information will be lost.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowCancelModal(false)}
              className="px-4 py-2 text-darkShade bg-gray-100 rounded-md hover:bg-gray-200 transition-colors duration-200"
            >
              Continue Editing
            </button>
            <Link
              to="/fitness-plans"
              className="px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 transition-colors duration-200"
            >
              Cancel Creation
            </Link>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

export default MemberFitnessPlanAdd;