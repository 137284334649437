import React from "react";
import {useRecoilState} from "recoil";
import {currentUserState} from "../../../AppState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrophy, faLock} from "@fortawesome/free-solid-svg-icons";

const Badges = ({badgeDetails}) => {
	const [currentUser] = useRecoilState(currentUserState);

	const BadgeCard = ({badge}) => (
		<div className="relative group">
			{/* Card Container */}
			<div
				className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md 
                          transition-all duration-300 border border-gray-100">
				{/* Badge Status */}
				<div className="absolute top-2 right-2 z-10">
					<span
						className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${
													badge.status === "earned"
														? "bg-green-100 text-green-800"
														: badge.status === "in-progress"
														? "bg-blue-100 text-blue-800"
														: "bg-gray-100 text-gray-800"
												}`}>
						{badge.status === "earned"
							? "Earned"
							: badge.status === "in-progress"
							? "In Progress"
							: "Locked"}
					</span>
				</div>

				{/* Badge Image */}
				<div className="p-4">
					<div className="relative w-full aspect-square flex items-center justify-center">
						<img
							src={badge.thumbnailUrl || badge.thumbnailObjectUrl}
							alt={badge.name}
							className={`w-20 h-20 object-contain transition-transform duration-300 
                                     group-hover:scale-110 ${
																				badge.status === "locked"
																					? "opacity-40"
																					: ""
																			}`}
						/>
						{badge.status === "locked" && (
							<div className="absolute inset-0 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faLock}
									className="text-gray-400 text-xl"
								/>
							</div>
						)}
					</div>
				</div>

				{/* Badge Info */}
				<div className="p-4 bg-gray-50">
					<h3 className="text-sm font-medium text-gray-900 truncate">
						{badge.name}
					</h3>

					{/* Progress Bar */}
					{badge.status === "in-progress" && (
						<div className="mt-2">
							<div className="flex items-center justify-between text-xs text-gray-500">
								<span>{badge.progress}%</span>
								<span>Complete</span>
							</div>
							<div className="mt-1 h-1.5 bg-gray-200 rounded-full overflow-hidden">
								<div
									className="h-full bg-blue-500 rounded-full transition-all duration-500"
									style={{width: `${badge.progress}%`}}
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			{/* Hover Details */}
			<div
				className="absolute inset-0 bg-black bg-opacity-75 rounded-lg opacity-0 
                          group-hover:opacity-100 transition-opacity duration-300
                          flex items-center justify-center p-4">
				<div className="text-center text-white">
					<p className="text-sm mb-3">{badge.description}</p>
					<button
						className="inline-flex items-center px-3 py-1.5 bg-white/10 
                                     backdrop-blur-sm text-white text-sm rounded-full
                                     hover:bg-white/20 transition-colors duration-300">
						View Details
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<div className="space-y-6">
			{/* Badge Grid */}
			<div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
				{badgeDetails?.map((badge) => (
					<BadgeCard key={badge.id} badge={badge} />
				))}
			</div>

			{/* Empty State */}
			{(!badgeDetails || badgeDetails.length === 0) && (
				<div className="text-center py-12">
					<div
						className="inline-flex items-center justify-center w-12 h-12 
                                  rounded-full bg-gray-100 mb-4">
						<FontAwesomeIcon
							icon={faTrophy}
							className="text-gray-400 text-xl"
						/>
					</div>
					<h3 className="text-lg font-medium text-gray-900">No Badges Yet</h3>
					<p className="mt-2 text-sm text-gray-500 max-w-sm mx-auto">
						Start completing challenges to earn your first badge
					</p>
				</div>
			)}
		</div>
	);
};

export default Badges;
