import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {getVideoContentDetailAPI} from "../../../API/VideoContent";
import {LoadingState} from "./components/LoadingState";
import {ErrorState} from "./components/ErrorState";
import VideoPlayer, {VIDEO_LAYOUTS} from "../../Reusable/VideoPlayer";
import Layout from "../../Menu/Layout";
import {currentUserState} from "../../../AppState";
import {useRecoilState} from "recoil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faGauge,
	faExclamationTriangle,
	faClock,
	faVideo,
	faFolderOpen,
	faLayerGroup,
	faPlay,
	faUser,
	faCalendar,
	faPencil,
} from "@fortawesome/free-solid-svg-icons";

const VideoPlayerComponent = () => {
	const {videoId} = useParams();
	const [currentUser] = useRecoilState(currentUserState);
	const [videoContent, setVideoContent] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/dashboard", icon: faGauge},
		{label: "MMA Lessons", link: "/videos", icon: faVideo},
		{
			label: videoContent?.categoryName || "Collections",
			link: `/videos/category/${videoContent?.categoryId}`,
			icon: faFolderOpen,
		},
		{
			label: videoContent?.collectionName || "Content",
			link: `/videos/collection/${videoContent?.collectionId}`,
			icon: faLayerGroup,
		},
		{label: videoContent?.name, icon: faPlay},
	];

	useEffect(() => {
		setIsLoading(true);
		setError(null);

		getVideoContentDetailAPI(
			videoId,
			(response) => {
				setVideoContent(response);
				setIsLoading(false);
			},
			(error) => {
				setError(error);
				setIsLoading(false);
			}
		);
	}, []);

	if (isLoading) {
		return (
			<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
				<LoadingState />
			</Layout>
		);
	}

	if (error) {
		return (
			<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
				<ErrorState error={error} />
			</Layout>
		);
	}

	if (!videoContent) {
		return (
			<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
				<div className="text-center py-12">
					<FontAwesomeIcon
						icon={faExclamationTriangle}
						className="text-4xl text-gray-400 mb-4"
					/>
					<h3 className="text-xl font-semibold text-gray-900">
						Video Not Found
					</h3>
				</div>
			</Layout>
		);
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="p-4 mb-5">
				<VideoPlayer
					videoType={videoContent.videoType}
					videoUrl={videoContent.videoUrl}
					videoObjectUrl={videoContent.videoObjectUrl}
					thumbnailUrl={
						videoContent.thumbnailObjectUrl || videoContent.thumbnailUrl
					}
					name={videoContent.name}
					description={videoContent.description}
					duration={videoContent.duration}
					layout={VIDEO_LAYOUTS.COMPACT}
					metadata={[
						{
							label: "Duration",
							value: videoContent.duration,
							icon: faClock,
						},
						{
							label: "Author",
							value: videoContent.authorName,
							icon: faUser,
						},
						{
							label: "Created",
							value: new Date(videoContent.createdAt).toLocaleDateString(),
							icon: faCalendar,
						},
						{
							label: "Last Modified",
							value: new Date(videoContent.modifiedAt).toLocaleDateString(),
							icon: faPencil,
						},
					]}
				/>
			</div>
		</Layout>
	);
};

export default VideoPlayerComponent;
