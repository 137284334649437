import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faGauge,
  faPlus,
  faTable,
  faSave,
  faBook,
  faArrowUpRightFromSquare,
  faEdit,
  faCalendarPlus,
  faArrowRight,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import { getWorkoutListApi } from "../../../API/workout";
import {
  deleteTrainingProgAPI,
  getTrainingProgDetailAPI,
  patchTrainingProgAPI,
} from "../../../API/trainingProgram";
import PhasePanel from "./phasepanel";
import WorkoutDisplay from "../../Reusable/WorkoutsDisplay";
import Modal from "../../Reusable/Modal";
import FormInputField from "../../Reusable/FormInputField";
import Accordion from "../../Reusable/accordion";
import Layout from "../../Menu/Layout";

function AdminTPDetailUpdate() {
  // URL Parameters
  const { uid, id } = useParams();

  // Global state
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  // Component states
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [datum, setDatum] = useState({});
  const [listData, setListData] = useState([]);
  const [selectedWorkoutForDeletion, setSelectedWorkoutForDeletion] =
    useState(null);
  const [showAddWorkoutModal, setShowAddWorkoutModal] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [isModified, setIsModified] = useState(false);

  const [selectedWorkoutForRoutine, setselectedWorkoutForRoutine] = useState(
    {}
  );
  const [getSelectedWorkouts, setGetSelectedWorkouts] = useState({});
  // Event handling
  const handleAddWorkoutClick = (phase) => {
    setSelectedPhase(phase);
    setShowAddWorkoutModal(true);
  };

  const handleAddWorkoutModalClose = () => {
    setShowAddWorkoutModal(false);
  };

  const handleInputChange = (index, field, value) => {
    const updatedSelectedWorkouts = { ...selectedWorkoutForRoutine };
    const selectedPhaseId = selectedPhase.id;

    // Find the selected workout by index
    const selectedWorkout = updatedSelectedWorkouts[selectedPhaseId][index];

    // Update the corresponding property
    selectedWorkout[field] = value;

    // Update the state
    setselectedWorkoutForRoutine(updatedSelectedWorkouts);
    setIsModified(true);
  };

  // API

  // Detail
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      setFetching(true);

      getTrainingProgDetailAPI(
        id,
        onDetailSuccess,
        onDetailError,
        onDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  // Delete
  function handleDeleteConfirmButtonClick() {
    deleteTrainingProgAPI(id, ondeleteSuccess, ondeleteError, onDeleteDone);
    setSelectedWorkoutForDeletion(null);
  }
  // Misc.
  useEffect(() => {
    if (JSON.stringify(datum) !== JSON.stringify({}) && !listData.length) {
      setFetching(true);
      let query = new Map();
      query.set("sort_field", "created");
      query.set("sort_order", -1);
      if (datum.userId) {
        // query.set("user_id", datum.userId);
        query.set("visibility", 1); //admin+personal
      }

      getWorkoutListApi(query, onListSuccess, onListError, onListDone);
    }
    return () => {};
  }, [datum]);

  // Callbacks
  function onDetailSuccess(response) {
    setDatum(response);
    if (response.trainingPhases) {
      const updatedWorkoutForRoutine = {};
      response.trainingPhases.forEach((tp) => {
        if (tp.trainingRoutines && tp.trainingRoutines.length) {
          const phaseWorkout = tp.trainingRoutines.map((routine) => ({
            ...routine.workout, // Embed all fields of workout
            day:
              (routine.trainingDays &&
                routine.trainingDays.length > 0 &&
                routine.trainingDays[0].day) ||
              0,
            week:
              (routine.trainingDays &&
                routine.trainingDays.length > 0 &&
                routine.trainingDays[0].week) ||
              0,
          }));
          updatedWorkoutForRoutine[tp.id] = phaseWorkout;
        } else {
          updatedWorkoutForRoutine[tp.id] = [];
        }
      });
      setselectedWorkoutForRoutine(updatedWorkoutForRoutine);
    }
  }

  function onDetailError(apiErr) {
    setErrors(apiErr);
    scrollToTop();
  }

  function onDetailDone() {
    setFetching(false);
  }

  function ondeleteSuccess(response) {
    setTopAlertStatus("success");
    setTopAlertMessage("training program deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/training-program");
  }

  function ondeleteError(apiErr) {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    scrollToTop();
  }

  function onDeleteDone() {
    setFetching(false);
  }

  function onListSuccess(resp) {
    setListData(resp.results);
  }

  function onListError(er) {
    setErrors(er);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  }

  function onListDone() {
    setFetching(false);
  }

  const handleSaveButtonClick = () => {
    let phase = [];
    // Iterate over each entry in the frontend object
    for (const phaseId in selectedWorkoutForRoutine) {
      if (selectedWorkoutForRoutine.hasOwnProperty(phaseId)) {
        const routines = selectedWorkoutForRoutine[phaseId];
        const phaseRoutines = routines.map((routine) => ({
          workout_id: routine.id,
          routine_day: parseInt(routine.day),
          routine_week: parseInt(routine.week),
        }));
        let phaseVal =
          datum.trainingPhases &&
          datum.trainingPhases.find((phase) => phase.id === phaseId);
        // Create PhaseRequestIDO object
        phase.push({
          phase_id: phaseId,
          phase: phaseVal && phaseVal.phase,
          routines: phaseRoutines,
        });
      }
    }
    setFetching(true);
    let payload = {
      phases: phase,
    };
    patchTrainingProgAPI(id, payload, onPatchOK, onPatchError, onDone);
    setIsModified(false);
  };
  function onPatchOK(response) {
    setTopAlertStatus("success");
    setTopAlertMessage("Program updated");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/training-program");
  }

  function onPatchError(apiErr) {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed updating");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    scrollToTop();
  }

  function onDone() {
    setFetching(false);
  }

  const handleSaveSelectedWorkouts = () => {
    const currentWorkouts = selectedWorkoutForRoutine[selectedPhase.id] || [];
    const initialWorkouts = getSelectedWorkouts[selectedPhase.id] || [];

    // Check if the current and initial workouts are different
    const hasChanges =
      JSON.stringify(currentWorkouts) !== JSON.stringify(initialWorkouts);

    setIsModified(hasChanges);
    setselectedWorkoutForRoutine((prevState) => ({
      ...prevState,
      [selectedPhase.id]: getSelectedWorkouts[selectedPhase.id],
    }));
    setShowAddWorkoutModal(false);
  };

  // Helper function to scroll to the top of the page
  const scrollToTop = () => {
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  };

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    {label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
    {label: "Training Programs", link: "/admin/training-program", icon: faBook},
    {label: "Edit", icon: faEdit},
  ];

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen bg-gray-50/50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link
                to="/admin/training-program"
                className="text-gray-500 hover:text-primary transition-colors"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5" />
              </Link>
              <h1 className="text-2xl font-bold text-gray-900">
                Edit Training Program
              </h1>
            </div>

            <div className="flex items-center space-x-3">
              {isModified && (
                <button
                  onClick={handleSaveButtonClick}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium
                           text-white bg-primary rounded-lg hover:bg-primary-dark
                           transition-colors duration-300"
                >
                  <FontAwesomeIcon icon={faSave} className="w-4 h-4 mr-2" />
                  Save Changes
                </button>
              )}
              <button
                onClick={() => setSelectedWorkoutForDeletion(datum)}
                className="inline-flex items-center px-4 py-2 text-sm font-medium
                         text-red-600 bg-white border border-red-200 rounded-lg
                         hover:bg-red-50 hover:border-red-300 transition-all
                         duration-300 shadow-sm"
              >
                <FontAwesomeIcon icon={faTrash} className="w-4 h-4 mr-2" />
                Delete Program
              </button>
            </div>
          </div>
        </div>

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <div className="">
            {/* Phase Panel */}
            <div className="md:col-span-1">
              <PhasePanel
                phases={datum.trainingPhases}
                onAddWorkout={handleAddWorkoutClick}
                setSelectedPhase={setSelectedPhase}
              />
            </div>

            {/* Workout Details */}
            <div className="md:col-span-3 mt-8">
              {selectedPhase && (
                <div className="bg-white rounded-xl border border-gray-200 shadow-sm">
                  <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-900">
                      Phase: {selectedPhase.name}
                    </h2>
                    <button
                      onClick={() => setShowAddWorkoutModal(true)}
                      className="inline-flex items-center px-4 py-2 text-sm font-medium
                               text-primary bg-white border border-primary/20 rounded-lg
                               hover:bg-primary/5 hover:border-primary transition-all
                               duration-300 shadow-sm"
                    >
                      <FontAwesomeIcon icon={faEdit} className="w-4 h-4 mr-2" />
                      Add/Edit Workouts
                    </button>
                  </div>

                  <div className="p-6">
                    {selectedWorkoutForRoutine[selectedPhase.id]?.length > 0 ? (
                      <div className="space-y-4">
                        {selectedWorkoutForRoutine[selectedPhase.id].map((routine, index) => (
                          <div key={index} className="bg-white rounded-lg border border-gray-200">
                            <Accordion
                              head={
                                <div className="flex items-center justify-between px-4 py-3">
                                  <div className="flex items-center space-x-2">
                                    <span className="font-medium">{routine.name}</span>
                                    <Link
                                      to={`/admin/workouts/${routine.id}`}
                                      target="_blank"
                                      className="text-primary hover:text-primary-dark"
                                    >
                                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="w-4 h-4" />
                                    </Link>
                                  </div>
                                </div>
                              }
                              content={
                                <div className="px-4 py-3 border-t border-gray-100">
                                  <div className="text-gray-600 mb-4">{routine.description}</div>
                                  
                                  <div className="grid grid-cols-2 gap-4 mb-4">
                                    <FormInputField
                                      label="Week"
                                      type="number"
                                      value={routine.week}
                                      onChange={(e) => handleInputChange(index, "week", e.target.value)}
                                      className="w-full"
                                    />
                                    <FormInputField
                                      label="Day"
                                      type="number"
                                      value={routine.day}
                                      onChange={(e) => handleInputChange(index, "day", e.target.value)}
                                      className="w-full"
                                    />
                                  </div>

                                  {routine.workoutExercises?.length > 0 && (
                                    <div className="space-y-2">
                                      {routine.workoutExercises.map((exercise, idx) => (
                                        <div key={idx} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                          <span className="text-gray-700">
                                            {idx + 1}. {exercise.exerciseName}
                                          </span>
                                          <span className="text-sm text-gray-500">
                                            {exercise.sets || 0} sets - {exercise.restPeriodInSecs}s rest
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-center py-8">
                        <div className="inline-flex items-center justify-center w-12 h-12 
                                     bg-gray-100 rounded-full mb-4">
                          <FontAwesomeIcon icon={faTable} className="w-6 h-6 text-gray-400" />
                        </div>
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                          No Workouts Added
                        </h3>
                        <p className="text-gray-500 mb-4">
                          Start by adding workouts to this phase.
                        </p>
                        <button
                          onClick={() => setShowAddWorkoutModal(true)}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium
                                   text-primary bg-white border border-primary/20 rounded-lg
                                   hover:bg-primary/5 hover:border-primary transition-all
                                   duration-300 shadow-sm"
                        >
                          <FontAwesomeIcon icon={faPlus} className="w-4 h-4 mr-2" />
                          Add First Workout
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Add Workout Modal */}
        <Modal
          isOpen={showAddWorkoutModal}
          onClose={handleAddWorkoutModalClose}
          header="Add Workouts"
          maxWidth="full"
        >
          {selectedPhase && (
            <div className="space-y-4">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  Adding workouts to: {selectedPhase.name}
                </h3>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={handleSaveSelectedWorkouts}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium
                             text-white bg-primary rounded-lg hover:bg-primary-dark
                             transition-colors duration-300"
                  >
                    <FontAwesomeIcon icon={faSave} className="w-4 h-4 mr-2" />
                    Save Selected
                  </button>
                </div>
              </div>
              
              <WorkoutDisplay
                workouts={listData}
                initialState={selectedWorkoutForRoutine[selectedPhase.id] || []}
                getSelectedWorkouts={(wr) => {
                  setGetSelectedWorkouts({
                    ...getSelectedWorkouts,
                    [selectedPhase.id]: wr,
                  });
                }}
              />
            </div>
          )}
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={selectedWorkoutForDeletion !== null}
          onClose={() => setSelectedWorkoutForDeletion(null)}
          header="Confirm Deletion"
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              Are you sure you want to delete this training program? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setSelectedWorkoutForDeletion(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white 
                         border border-gray-300 rounded-lg hover:bg-gray-50 
                         transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirmButtonClick}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                         rounded-lg hover:bg-red-700 transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>

        <FormErrorBox errors={errors} />
      </div>
    </div>
    </Layout>
  );
}

export default AdminTPDetailUpdate;
