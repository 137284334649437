import {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faArrowLeft,
	faGauge,
	faEye,
	faTable,
	faPlus,
	faDumbbell,
	faCalendar,
	faLayerGroup,
	faEdit,
	faChartLine,
	faArrowRight,
	faUsers,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {ListHeader} from "../../Reusable/AdminList";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {getWorkoutListApi} from "../../../API/workout";
import {
	deleteTrainingProgAPI,
	getTrainingProgDetailAPI,
	patchTrainingProgAPI,
} from "../../../API/trainingProgram";
import Layout from "../../Menu/Layout";
import PhasePanel from "./phasepanel";
import FitnessPlanDisplay from "../../Reusable/FitnessPlanDisplay";
import {
	TRAINING_PROGRAM_STATUS_OPTIONS,
	TRAINING_PROGRAM_VISBILITY_OPTIONS,
} from "../../../Constants/FieldOptions";

const EmptyState = ({icon, title, description, action}) => (
	<div className="text-center max-w-md mx-auto">
		<div className="inline-flex items-center justify-center w-12 h-12 rounded-xl bg-gray-100 mb-4">
			<FontAwesomeIcon icon={icon} className="w-6 h-6 text-gray-400" />
		</div>
		<h3 className="text-lg font-medium text-gray-900 mb-2">{title}</h3>
		<p className="text-sm text-gray-500 mb-6">{description}</p>
		{action}
	</div>
);

const StatusCard = ({label, value, icon, statusClass}) => (
	<div className="bg-white p-4 sm:p-6 rounded-xl border border-gray-200 shadow-sm">
		<div className="flex items-start justify-between">
			<div className="flex-1 min-w-0">
				<p className="text-sm font-medium text-gray-500 mb-1">{label}</p>
				<h3 className="text-base sm:text-lg font-semibold text-gray-900 truncate">
					{value}
				</h3>
			</div>
			{icon ? (
				<FontAwesomeIcon icon={icon} className="w-5 h-5 text-gray-400 ml-2" />
			) : (
				<span
					className={`ml-2 px-2.5 sm:px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap ${statusClass}`}>
					{value}
				</span>
			)}
		</div>
	</div>
);

function AdminTPDetail() {
	// URL Parameters

	const {uid, id} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedWorkoutForDeletion, setSelectedWorkoutForDeletion] =
		useState(null);
	const [selectedPhase, setSelectedPhase] = useState(null);

	// Add this function
	const onAddWorkout = (phase) => {
		// Navigate to add workout page or open modal
		window.location.href = `/admin/training-program/${id}/phase/${phase.id}/workout/add`;
	};

	// API

	// Detail
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);

			getTrainingProgDetailAPI(
				id,
				onDetailSuccess,
				onDetailError,
				onDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	// Delete
	function handleDeleteConfirmButtonClick() {
		deleteTrainingProgAPI(id, ondeleteSuccess, ondeleteError, onDeleteDone);
		setSelectedWorkoutForDeletion(null);
	}

	// Callbacks
	function onDetailSuccess(response) {
		setDatum(response);
		if (response.trainingPhases) {
			const updatedWorkoutForRoutine = {};
			response.trainingPhases.forEach((tp) => {
				if (tp.trainingRoutines && tp.trainingRoutines.length) {
					const phaseWorkout = tp.trainingRoutines.map((routine) => ({
						...routine.workout, // Embed all fields of workout
						day:
							(routine.trainingDays &&
								routine.trainingDays.length > 0 &&
								routine.trainingDays[0].day) ||
							0,
						week:
							(routine.trainingDays &&
								routine.trainingDays.length > 0 &&
								routine.trainingDays[0].week) ||
							0,
					}));
					updatedWorkoutForRoutine[tp.id] = phaseWorkout;
				} else {
					updatedWorkoutForRoutine[tp.id] = [];
				}
			});
		}
	}

	function onDetailError(apiErr) {
		setErrors(apiErr);
		scrollToTop();
	}

	function onDetailDone() {
		setFetching(false);
	}

	function ondeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("training program deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setForceURL("/admin/training-program");
	}

	function ondeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		scrollToTop();
	}

	function onDeleteDone() {
		setFetching(false);
	}

	function onDone() {
		setFetching(false);
	}
	const handleAddWorkoutClick = (phase) => {
		setSelectedPhase(phase);
	};
	// Helper function to scroll to the top of the page
	const scrollToTop = () => {
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	function transformResponseForFitnessPlan(phase) {
		if (!phase || !phase.trainingRoutines) {
			return [];
		}

		let plan = [
			{
				title: phase.name,
				dailyPlans: [],
			},
		];
		const setInstruction = (exc) => {
			let inst = exc.excercise && exc.excercise.description;
			if (exc.sets) {
				inst =
					inst +
					"\n " +
					"This has to be done for a set of " +
					exc.sets +
					"times.";
			}
			if (exc.restPeriodInSecs) {
				inst =
					inst +
					"\n " +
					"Give a rest period of around  " +
					exc.restPeriodInSecs +
					"seconds.";
			}
			if (exc.targetTimeInSecs) {
				inst =
					inst +
					"\n " +
					"Try to complete in  " +
					exc.targetTimeInSecs +
					"seconds.";
			}

			return inst;
		};
		const getTitle = (trainingDays) => {
			if (!trainingDays) {
				return "";
			}
			if (trainingDays.length) {
				return (
					"Week - " + trainingDays[0].week + ": Day - " + trainingDays[0].day
				);
			} else {
				return "";
			}
		};
		phase.trainingRoutines.map((tr) =>
			plan[0].dailyPlans.push({
				title: getTitle(tr.trainingDays),
				instructions: tr.description,
				planDetails:
					tr.workout.workoutExercises && tr.workout.workoutExercises.length > 0
						? tr.workout.workoutExercises.map((exc) => ({
								id: exc.id,
								name: exc.exerciseName,
								videoUrl: exc.excercise.videoUrl,
								thumbnailUrl: exc.excercise.thumbnailUrl,
								description: setInstruction(exc),
								videoType: exc.excercise.videoType,
								videoObjectUrl: exc.excercise.videoObjectUrl,
						  }))
						: [],
			})
		);
		return plan;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{
			label: "Training Programs",
			link: "/admin/training-program",
			icon: faBook,
		},
		{label: "Details", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50/50">
				<div className="w-full max-w-7xl mx-auto px-3 sm:px-4 md:px-6 lg:px-8 py-3 sm:py-4 md:py-6">
					{/* Header Section */}
					<div className="mb-4 md:mb-6">
						{/* Title and Actions */}
						<div className="flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between mb-4 md:mb-6">
							{/* Back Button and Title */}
							<div className="flex items-center gap-2 sm:gap-3">
								<Link
									to="/admin/training-program"
									className="p-2 text-gray-500 hover:text-primary transition-colors rounded-lg hover:bg-gray-100">
									<FontAwesomeIcon icon={faBook} className="w-4 h-4" />
								</Link>
								<h1 className="text-lg sm:text-xl md:text-2xl font-bold text-gray-900 truncate">
									Training Program Details
								</h1>
							</div>

							{/* Action Buttons */}
							<div className="flex items-center gap-2 sm:gap-3 mt-2 sm:mt-0">
								<button
									onClick={() => setSelectedWorkoutForDeletion(datum)}
									className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4 
                           text-sm font-medium text-red-600 bg-white border border-red-200 
                           rounded-lg hover:bg-red-50 hover:border-red-300 transition-all
                           duration-300 shadow-sm focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
									<FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
									<span className="ml-2 hidden sm:inline">Delete Program</span>
								</button>
								<Link
									to={`/admin/training-program/${id}/edit`}
									className="flex-1 sm:flex-none inline-flex items-center justify-center h-10 px-3 sm:px-4
                           text-sm font-medium text-primary bg-white border border-primary/20 
                           rounded-lg hover:bg-primary/5 hover:border-primary transition-all
                           duration-300 shadow-sm focus:ring-2 focus:ring-primary focus:ring-offset-2">
									<FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
									<span className="ml-2 hidden sm:inline">Edit Program</span>
								</Link>
							</div>
						</div>

						{/* Status Cards */}
						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4 md:gap-6">
							{/* Status Card */}
							<div className="bg-white p-4 rounded-xl border border-gray-200 shadow-sm">
								<div className="flex items-center justify-between">
									<div>
										<p className="text-xs sm:text-sm text-gray-500 mb-1">
											Status
										</p>
										<div className="flex items-center gap-2">
											<h3 className="text-sm sm:text-base font-semibold text-gray-900">
												{TRAINING_PROGRAM_STATUS_OPTIONS.find(
													(option) => option.value === datum.status
												)?.label || "N/A"}
											</h3>
											<span
												className={`px-2 py-1 rounded-full text-xs font-medium
                                    ${
																			datum.status === 1
																				? "bg-green-100 text-green-700"
																				: "bg-yellow-100 text-yellow-700"
																		}`}>
												{datum.status === 1 ? "Active" : "Draft"}
											</span>
										</div>
									</div>
								</div>
							</div>

							{/* Visibility Card */}
							<div className="bg-white p-4 rounded-xl border border-gray-200 shadow-sm">
								<div className="flex items-center justify-between">
									<div>
										<p className="text-xs sm:text-sm text-gray-500 mb-1">
											Visibility
										</p>
										<h3 className="text-sm sm:text-base font-semibold text-gray-900">
											{TRAINING_PROGRAM_VISBILITY_OPTIONS.find(
												(option) => option.value === datum.visibility
											)?.label || "N/A"}
										</h3>
									</div>
									<FontAwesomeIcon
										icon={faEye}
										className="w-5 h-5 text-gray-400"
									/>
								</div>
							</div>

							{/* Phases Card */}
							<div className="bg-white p-4 rounded-xl border border-gray-200 shadow-sm">
								<div className="flex items-center justify-between">
									<div>
										<p className="text-xs sm:text-sm text-gray-500 mb-1">
											Total Phases
										</p>
										<h3 className="text-sm sm:text-base font-semibold text-gray-900">
											{datum.trainingPhases?.length || 0} Phases
										</h3>
									</div>
									<FontAwesomeIcon
										icon={faLayerGroup}
										className="w-5 h-5 text-gray-400"
									/>
								</div>
							</div>
						</div>

						<FormErrorBox errors={errors} />
					</div>

					{/* Main Content */}
					<div className="space-y-6">
						{/* Training Phases Card */}
						<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm overflow-hidden">
							{/* Header */}
							<div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200/80 px-6 py-5">
								<div className="flex flex-wrap items-center justify-between gap-4">
									<div className="flex items-center space-x-3">
										<div className="flex items-center justify-center w-10 h-10 rounded-lg bg-primary/10">
											<FontAwesomeIcon
												icon={faLayerGroup}
												className="w-5 h-5 text-primary"
											/>
										</div>
										<div>
											<h2 className="text-lg font-semibold text-gray-900">
												Training Phases
											</h2>
											<p className="text-sm text-gray-500 mt-0.5">
												Manage your program's training phases
											</p>
										</div>
									</div>
									<div className="flex items-center gap-3">
										<span className="inline-flex items-center px-3 py-1 rounded-full bg-primary/5 text-primary text-sm font-medium">
											{datum.trainingPhases?.length || 0} Phases
										</span>
										{/* <button
											onClick={() => {}}
											className="inline-flex items-center px-4 py-2 rounded-lg text-sm font-medium
                     text-white bg-primary hover:bg-primary-dark transition-colors
                     duration-200 shadow-sm">
											<FontAwesomeIcon icon={faPlus} className="w-4 h-4 mr-2" />
											Add Phase
										</button> */}
									</div>
								</div>
							</div>

							{/* Phases Grid */}
							<div className="p-6">
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
									{(datum.trainingPhases || []).map((phase, index) => (
										<div
											key={phase.id}
											className={`group relative p-5 rounded-xl border border-gray-200/80 bg-white
                       hover:border-primary/20 hover:shadow-lg transition-all duration-300
                       ${
													selectedPhase?.id === phase.id
														? "ring-2 ring-primary ring-offset-2"
														: ""
												}`}>
											<div className="flex flex-col h-full">
												{/* Phase Header */}
												<div className="flex items-start justify-between mb-3">
													<div className="flex-1">
														<h3 className="text-base font-medium text-gray-900 group-hover:text-primary transition-colors">
															{phase.name}
														</h3>
														<p className="mt-1 text-sm text-gray-500 line-clamp-2">
															{phase.description}
														</p>
													</div>
													<span
														className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                               bg-gray-100 text-gray-800">
														Week {index + 1}
													</span>
												</div>

												{/* Stats Row */}
												<div className="flex items-center gap-4 py-3 border-t border-gray-100">
													<div className="flex items-center text-sm text-gray-500">
														<FontAwesomeIcon
															icon={faDumbbell}
															className="w-4 h-4 mr-1.5"
														/>
														{phase.trainingRoutines?.length || 0} Workouts
													</div>
													<div className="flex items-center text-sm text-gray-500">
														<FontAwesomeIcon
															icon={faCalendar}
															className="w-4 h-4 mr-1.5"
														/>
														{phase.duration || 0} Days
													</div>
												</div>

												{/* Actions */}
												<div className="flex items-center gap-2 mt-auto pt-4 border-t border-gray-100">
													<button
														onClick={() => setSelectedPhase(phase)}
														className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium
                           text-primary bg-primary/5 rounded-lg hover:bg-primary/10
                           transition-colors duration-200">
														<FontAwesomeIcon
															icon={faEye}
															className="w-4 h-4 mr-2"
														/>
														View Details
													</button>
													{/* <button
														onClick={() => onAddWorkout(phase)}
														className="flex-1 inline-flex items-center justify-center px-4 py-2 text-sm font-medium
                           text-white bg-primary rounded-lg hover:bg-primary-dark
                           transition-colors duration-200">
														<FontAwesomeIcon
															icon={faPlus}
															className="w-4 h-4 mr-2"
														/>
														Add Workout
													</button> */}
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</section>

						{/* Phase Details Card */}
						{selectedPhase ? (
							<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm overflow-hidden">
								{/* Header */}
								<div className="bg-gradient-to-r from-gray-50 to-white border-b border-gray-200/80 px-6 py-5">
									<div className="flex flex-wrap items-center justify-between gap-4">
										<div className="flex items-center space-x-3">
											<div className="flex items-center justify-center w-10 h-10 rounded-lg bg-primary/10">
												<FontAwesomeIcon
													icon={faChartLine}
													className="w-5 h-5 text-primary"
												/>
											</div>
											<div>
												<h2 className="text-lg font-semibold text-gray-900 flex items-center">
													{selectedPhase.name}
												</h2>
												<p className="text-sm text-gray-500 mt-0.5 line-clamp-1">
													{selectedPhase.description}
												</p>
											</div>
										</div>
										<div className="flex flex-wrap items-center gap-2">
											<span className="inline-flex items-center px-3 py-1 rounded-full bg-primary/5 text-primary text-sm font-medium">
												Week {selectedPhase.trainingRoutines?.length || 0}
											</span>
											<span className="inline-flex items-center px-3 py-1 rounded-full bg-gray-100 text-gray-700 text-sm font-medium">
												{selectedPhase.status === 1 ? "Active" : "Draft"}
											</span>
										</div>
									</div>
								</div>

								{/* Content */}
								<div className="relative">
									{selectedPhase.trainingRoutines?.length > 0 ? (
										<div className="overflow-x-auto">
											<FitnessPlanDisplay
												weeklyFitnessPlans={transformResponseForFitnessPlan(
													selectedPhase
												)}
												label={selectedPhase.name}
												helpText={selectedPhase.description}
											/>
										</div>
									) : (
										<div className="px-6 py-12">
											<EmptyState
												icon={faTable}
												title="No Workouts Added"
												description="This phase doesn't have any workouts yet. Add your first workout to get started."
												action={
													<button
														onClick={() => onAddWorkout(selectedPhase)}
														className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium
                           text-white bg-primary rounded-lg hover:bg-primary-dark
                           transition-colors duration-200 shadow-sm">
														<FontAwesomeIcon
															icon={faPlus}
															className="w-4 h-4 mr-2"
														/>
														Add First Workout
													</button>
												}
											/>
										</div>
									)}
								</div>
							</section>
						) : (
							<section className="bg-white rounded-2xl border border-gray-200/80 shadow-sm p-12">
								<EmptyState
									icon={faLayerGroup}
									title="Select a Phase"
									description="Choose a phase from above to view its workout details."
								/>
							</section>
						)}
					</div>
				</div>

				{/* Modal */}
				<Modal
					isOpen={selectedWorkoutForDeletion !== null}
					onClose={() => setSelectedWorkoutForDeletion(null)}
					header="Confirm Deletion">
					<div className="p-4">
						<p className="text-sm text-gray-600 mb-4">
							Are you sure you want to delete this training program? This action
							cannot be undone.
						</p>
						<div className="flex flex-col sm:flex-row justify-end gap-2">
							<button
								onClick={() => setSelectedWorkoutForDeletion(null)}
								className="w-full sm:w-auto h-10 px-4 text-sm font-medium text-gray-700 
                         bg-white border border-gray-300 rounded-lg hover:bg-gray-50 
                         transition-colors duration-300 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
								Cancel
							</button>
							<button
								onClick={handleDeleteConfirmButtonClick}
								className="w-full sm:w-auto h-10 px-4 text-sm font-medium text-white 
                         bg-red-600 rounded-lg hover:bg-red-700 transition-colors 
                         duration-300 focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
								Delete
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminTPDetail;
