import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { aTasks, faTachometer, faPlus, faArrowLeft, faCheckCircle, faGauge, faPencil, faUsers, faEye, faIdCard, faAddressBook, faContactCard, faChartPie, faCogs, faEdit } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import useLocalStorage from "../../../Hooks/useLocalStorage";
import { getMemberDetailAPI, putMemberUpdateAPI } from "../../../API/member";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import Layout from "../../Menu/Layout";


function AdminMemberUpdate() {
    ////
    //// URL Parameters.
    ////

    const { bid, id } = useParams()

    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [organizationID, setOrganizationID] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [status, setStatus] = useState(0);
    const [agreePromotionsEmail, setHasPromotionalEmail] = useState(true);
    const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(0);
    const [howDidYouHearAboutUsOther, setHowDidYouHearAboutUsOther] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeated, setPasswordRepeated] = useState("");

    // Breadcrumb items
   const breadcrumbItems = [
    {
        label: "Members",
        link: "/admin/members",
        icon: faUsers,
    },
    {
        label: "Detail",
        link: `/admin/member/${id}`,
        icon: faEye,
    },
    {
        label: "Update",
        icon: faEdit,
    }
   ]

    ////
    //// Event handling.
    ////

    function onAgreePromotionsEmailChange(e) {
        setHasPromotionalEmail(!agreePromotionsEmail);
    }

    ////
    //// API.
    ////

    const onSubmitClick = (e) => {
        console.log("onSubmitClick: Beginning...");
        setFetching(true);
        setErrors({});
        const decamelizedData = {
            id: id,
            organization_id: currentUser.organizationID,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            postal_code: postalCode,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            city: city,
            region: region,
            country: country,
            status: status,
            password: password,
            password_repeated: passwordRepeated,
            how_did_you_hear_about_us: howDidYouHearAboutUs,
            how_did_you_hear_about_us_other: howDidYouHearAboutUsOther,
            agree_promotions_email: agreePromotionsEmail,
        };
        console.log("onSubmitClick, decamelizedData:", decamelizedData);
        putMemberUpdateAPI(decamelizedData, onAdminMemberUpdateSuccess, onAdminMemberUpdateError, onAdminMemberUpdateDone);
    }

    function onMemberDetailSuccess(response){
        console.log("onMemberDetailSuccess: Starting...");
        setOrganizationID(response.organizationId);
        setFirstName(response.firstName);
        setLastName(response.lastName);
        setEmail(response.email);
        setPhone(response.phone);
        setPostalCode(response.postalCode);
        setAddressLine1(response.addressLine1);
        setAddressLine2(response.addressLine2);
        setCity(response.city);
        setRegion(response.region);
        setCountry(response.country);
        setStatus(response.status);
        setHowDidYouHearAboutUs(response.howDidYouHearAboutUs);
        setHowDidYouHearAboutUsOther(response.howDidYouHearAboutUsOther);
        setHasPromotionalEmail(response.agreePromotionsEmail);
    }

    function onMemberDetailError(apiErr) {
        console.log("onMemberDetailError: Starting...");
        setErrors(apiErr);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onMemberDetailDone() {
        console.log("onMemberDetailDone: Starting...");
        setFetching(false);
    }

    function onAdminMemberUpdateSuccess(response){
        // For debugging purposes only.
        console.log("onAdminMemberUpdateSuccess: Starting...");
        console.log(response);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Member updated successfully");
        setTopAlertStatus("success");
        setTimeout(() => {
            console.log("onAdminMemberUpdateSuccess: Delayed for 2 seconds.");
            console.log("onAdminMemberUpdateSuccess: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // Redirect the user to a new page.
        setForceURL("/admin/member/"+response.id);
    }

    function onAdminMemberUpdateError(apiErr) {
        console.log("onAdminMemberUpdateError: Starting...");
        setErrors(apiErr);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Failed submitting");
        setTopAlertStatus("danger");
        setTimeout(() => {
            console.log("onAdminMemberUpdateError: Delayed for 2 seconds.");
            console.log("onAdminMemberUpdateError: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onAdminMemberUpdateDone() {
        console.log("onAdminMemberUpdateDone: Starting...");
        setFetching(false);
    }

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);  // Start the page at the top of the page.

            setFetching(true);
            getMemberDetailAPI(
                id,
                onMemberDetailSuccess,
                onMemberDetailError,
                onMemberDetailDone
            );
        }

        return () => { mounted = false; }
    }, []);
    ////
    //// Component rendering.
    ////

    if (forceURL !== "") {
        return <Navigate to={forceURL}  />
    }

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="mb-6">
                    <h1 className="flex items-center text-2xl font-semibold text-gray-800">
                        <FontAwesomeIcon className="mr-3 text-primaryColor" icon={faUsers} />
                        Members
                    </h1>
                    <FormErrorBox errors={errors} />
                </div>

                {isFetching ? (
                    <PageLoadingContent displayMessage={"Please wait..."} />
                ) : (
                    <div className="space-y-8" key={id}>
                        {/* Office Information Section */}
                        <section>
                            <h2 className="flex items-center text-lg font-medium text-gray-700 mb-4">
                                <FontAwesomeIcon className="mr-2 text-primaryColor" icon={faIdCard} />
                                Office Information
                            </h2>
                            <div className="border-b border-gray-200 mb-6" />
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <FormInputField
                                    label="First Name"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    value={firstName}
                                    errorText={errors?.firstName}
                                    onChange={(e)=>setFirstName(e.target.value)}
                                    isRequired={true}
                                    className="max-w-[380px]"
                                />

                                <FormInputField
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Enter last name"
                                    value={lastName}
                                    errorText={errors?.lastName}
                                    onChange={(e)=>setLastName(e.target.value)}
                                    isRequired={true}
                                    className="max-w-[380px]"
                                />
                            </div>
                        </section>

                        {/* Contact Information Section */}
                        <section>
                            <h2 className="flex items-center text-lg font-medium text-gray-700 mb-6">
                                <FontAwesomeIcon className="mr-2 text-primaryColor" icon={faContactCard} />
                                Contact Information
                            </h2>
                            <div className="border-b border-gray-200 mb-6" />
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <FormInputField
                                    label="Email"
                                    name="email"
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    errorText={errors?.email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    isRequired={true}
                                    className="max-w-[380px]"
                                />

                                <FormInputField
                                    label="Phone"
                                    name="phone"
                                    placeholder="Enter phone"
                                    value={phone}
                                    errorText={errors?.phone}
                                    onChange={(e)=>setPhone(e.target.value)}
                                    isRequired={true}
                                    className="max-w-[150px]"
                                />
                            </div>
                        </section>

                        {/* Address Section */}
                        <section>
                            <h2 className="flex items-center text-lg font-medium text-gray-700 mb-6">
                                <FontAwesomeIcon className="mr-2 text-primaryColor" icon={faAddressBook} />
                                Address
                            </h2>
                            <div className="border-b border-gray-200 mb-6" />
                            
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="space-y-6">
                                    <FormCountryField
                                        priorityOptions={["CA","US","MX"]}
                                        label="Country"
                                        name="country"
                                        selectedCountry={country}
                                        errorText={errors?.country}
                                        onChange={(value)=>setCountry(value)}
                                        isRequired={true}
                                        className="max-w-[160px]"
                                    />

                                    <FormRegionField
                                        label="Province/Territory"
                                        name="region"
                                        selectedCountry={country}
                                        selectedRegion={region}
                                        errorText={errors?.region}
                                        onChange={(value)=>setRegion(value)}
                                        isRequired={true}
                                        className="max-w-[280px]"
                                    />
                                </div>

                                <div className="space-y-6">
                                    <FormInputField
                                        label="City"
                                        name="city"
                                        value={city}
                                        errorText={errors?.city}
                                        onChange={(e)=>setCity(e.target.value)}
                                        isRequired={true}
                                        className="max-w-[380px]"
                                    />

                                    <FormInputField
                                        label="Postal Code"
                                        name="postalCode"
                                        value={postalCode}
                                        errorText={errors?.postalCode}
                                        onChange={(e)=>setPostalCode(e.target.value)}
                                        isRequired={true}
                                        className="max-w-[180px]"
                                    />
                                </div>
                            </div>
                        </section>

                        {/* Action Buttons */}
                        <div className="flex justify-between items-center pt-8">
                            <Link 
                                to={`/admin/member/${id}`}
                                className="inline-flex items-center px-4 py-2 border border-gray-300 
                                         text-sm font-medium rounded-md text-gray-700 bg-white 
                                         hover:bg-gray-50 focus:outline-none focus:ring-2 
                                         focus:ring-offset-2 focus:ring-primaryColor"
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                Back
                            </Link>
                            
                            <button
                                onClick={onSubmitClick}
                                className="inline-flex items-center px-4 py-2 border border-transparent 
                                         text-sm font-medium rounded-md text-white bg-primaryColor 
                                         hover:bg-primary-dark focus:outline-none focus:ring-2 
                                         focus:ring-offset-2 focus:ring-primaryColor"
                            >
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                Save Changes
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default AdminMemberUpdate;
