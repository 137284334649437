import React from "react";

function DataDisplayRowRadio({
    label,
    value,
    opt1Value,
    opt1Label,
    opt2Value,
    opt2Label,
    opt3Value,
    opt3Label,
    opt4Value,
    opt4Label,
    opt5Value,
    opt5Label,
    opt6Value,
    opt6Label,
    opt7Value,
    opt7Label,
    helpText,
}) {
    const getValue = () => {
        const options = [
            { value: opt1Value, label: opt1Label },
            { value: opt2Value, label: opt2Label },
            { value: opt3Value, label: opt3Label },
            { value: opt4Value, label: opt4Label },
            { value: opt5Value, label: opt5Label },
            { value: opt6Value, label: opt6Label },
            { value: opt7Value, label: opt7Label },
        ].filter(opt => opt.value !== undefined);

        return options.find(opt => opt.value === value)?.label || '-';
    };

    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <div className="text-gray-900">
                    {getValue()}
                </div>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowRadio;