import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faImage,
	faVideo,
	faSave,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faVideoCamera,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faCogs,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Vimeo from "@u-wave/react-vimeo";

import {
	putVideoCollectionUpdateAPI,
	getVideoCollectionDetailAPI,
} from "../../../API/VideoCollection";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormSelectFieldForVideoCategory from "../../Reusable/FormSelectFieldForVideoCategory";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import {VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION} from "../../../Constants/FieldOptions";
import {
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	VIDEO_COLLECTION_TYPE_MANY_VIDEOS,
    VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";

function AdminVideoCollectionUpdate() {
	////
	//// URL Parameters.
	////

	const {vcid} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [thumbnailType, setThumbnailType] = useState(0);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
    const [existingThumbnailURL, setExistingThumbnailURL] = useState("");
	const [existingThumbnailAttachmentID, setExistingThumbnailAttachmentID] =
		useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [name, setName] = useState("");
	const [summary, setSummary] = useState("");
	const [description, setDescription] = useState("");
	const [type, setType] = useState(0);
	const [videoCategoryID, setVideoCategoryID] = useState("");
	const [isVideoCategoryOther, setIsVideoCategoryOther] = useState("");

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});

		const payload = {
			id: vcid,
			type: parseInt(type),
            thumbnail_type: parseInt(thumbnailType),
			name: name,
			summary: summary,
			description: description,
			category_id: videoCategoryID,
			status: 1, //1=Active
		};

		if (thumbnailType === VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (thumbnailAttachmentID !== existingThumbnailAttachmentID) {
				payload.thumbnail_upload = thumbnailAttachmentID;
			}
		} else {
			// For external URL
			payload.thumbnail_url = thumbnailURL;
		}

		// If we're not changing the thumbnail, keep the existing one
		if (
			!payload.thumbnail_upload &&
			!payload.thumbnail_url &&
			existingThumbnailURL
		) {
			if (thumbnailType === VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
				payload.thumbnail_upload = existingThumbnailAttachmentID;
			} else {
				payload.thumbnail_url = existingThumbnailURL;
			}
		}

		putVideoCollectionUpdateAPI(
			payload,
			onUpdateSuccess,
			onUpdateError,
			onUpdateDone
		);
		console.log("onSubmitClick: Finished.");
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onVideoCollectionDetailSuccess(response) {
		console.log("onVideoCollectionDetailSuccess: Starting...");
		setThumbnailType(response.thumbnailType);
		setThumbnailAttachmentID(
			response.thumbnailAttachmentId === "000000000000000000000000"
				? ""
				: response.thumbnailAttachmentId
		);
		setExistingThumbnailAttachmentID(
			response.thumbnailAttachmentId === "000000000000000000000000"
				? ""
				: response.thumbnailAttachmentId
		);
		setThumbnailAttachmentName(response.thumbnailAttachmentFilename);
		setExistingThumbnailURL(response.thumbnailObjectUrl);
		setThumbnailURL(response.thumbnailUrl || "");

		setSummary(response.summary);
		setDescription(response.description);
		setName(response.name);
		setType(response.type);
		setVideoCategoryID(response.categoryId);
		setIsVideoCategoryOther(response.isVideoCategoryOther);
	}

	function onVideoCollectionDetailError(apiErr) {
		console.log("onVideoCollectionDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		console.log("onVideoCollectionDetailDone: Starting...");
		setFetching(false);
	}

	// --- Update --- //

	function onUpdateSuccess(response) {
		// For debugging purposes only.
		console.log("onUpdateSuccess: Starting...");
		console.log(response);

		// Update a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Video collection update");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onUpdateSuccess: Delayed for 2 seconds.");
			console.log(
				"onUpdateSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL("/admin/video-collection/" + response.id + "");
	}

	function onUpdateError(apiErr) {
		console.log("onUpdateError: Starting...");
		setErrors(apiErr);

		// Update a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onUpdateError: Delayed for 2 seconds.");
			console.log(
				"onUpdateError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onUpdateDone() {
		console.log("onUpdateDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getVideoCollectionDetailAPI(
				vcid,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, []);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const isThumbnailUploaded =
		thumbnailAttachmentID !== "" || thumbnailURL !== "";
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{
			label: "MMA Lessons",
			icon: faVideo,
			link: "/admin/video-collections",
		},
		{label: "Detail", link: `/admin/video-collection/${vcid}`, icon: faEye},
		{label: "Edit", icon: faPencil},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50">
				<div className="mx-auto sm:px-3 lg:px-6 py-8">
					{/* Main Content */}
					<div className="bg-white rounded-lg shadow-sm">
						{/* Header */}
						<div className="px-5 py-4 border-b border-gray-200">
							<h1 className="text-xl font-semibold text-gray-900 flex items-center">
								<FontAwesomeIcon icon={faPencil} className="mr-2" />
								Edit Video Collection
							</h1>
						</div>

						{/* Content */}
						<div className="px-5 py-4">
							<FormErrorBox errors={errors} />

							{isFetching ? (
								<div className="flex justify-center items-center py-12">
									<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
								</div>
							) : (
								<div className="space-y-6">
									{/* Thumbnail Section */}
									<section>
										<h2 className="text-lg font-medium text-gray-900 mb-4">
											<FontAwesomeIcon icon={faImage} className="mr-2" />
											Thumbnail
										</h2>
										<div className="space-y-4">
											<FormRadioField
												label="Thumbnail Type"
												name="thumbnailType"
												value={thumbnailType}
												opt1Value={
													EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
												}
												opt1Label="File Upload"
												opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
												opt2Label="External URL"
												onChange={(e) => {
                                                    const newType = parseInt(e.target.value);
                                                    setThumbnailType(newType);
                                                    // Clear relevant fields when switching types
                                                    if (
                                                        newType ===
                                                        VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
                                                    ) {
                                                        setThumbnailURL("");
                                                    } else {
                                                        setThumbnailAttachmentID("");
                                                        setThumbnailAttachmentName("");
                                                    }
                                                }}
												errorText={errors?.thumbnailType}
											/>

											{thumbnailType ===
											EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
												<FormAttachmentField
													label="Thumbnail Image"
													name="thumbnailUpload"
													placeholder="Upload thumbnail image"
													errorText={errors && errors.thumbnailUpload}
													attachmentID={thumbnailAttachmentID}
													setAttachmentID={setThumbnailAttachmentID}
													attachmentFilename={thumbnailAttachmentName}
													setAttachmentFilename={setThumbnailAttachmentName}
													helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
													validationText="Maximum size: 10MB"
													disabled={false}
												/>
											) : (
												<FormInputField
													label="Thumbnail URL"
													name="thumbnailURL"
													type="url"
													placeholder="Enter thumbnail URL"
													value={thumbnailURL}
													errorText={errors?.thumbnailUrl}
													onChange={(e) => setThumbnailURL(e.target.value)}
													helpText="Enter the URL of the thumbnail image"
												/>
											)}
										</div>
									</section>

									{/* Information Section */}
									<section>
										<h2 className="text-lg font-medium text-gray-900 mb-4">
											<FontAwesomeIcon icon={faEye} className="mr-2" />
											Information
										</h2>
										<div className="grid gap-6">
											<FormInputField
												label="Name"
												name="name"
												value={name}
												errorText={errors?.name}
												onChange={(e) => setName(e.target.value)}
												maxWidth="max-w-lg"
												required
											/>
											<FormInputField
												label="Summary"
												name="summary"
												value={summary}
												errorText={errors?.summary}
												onChange={(e) => setSummary(e.target.value)}
												maxWidth="max-w-lg"
												required
											/>
											<FormTextareaField
												label="Description"
												name="description"
												value={description}
												errorText={errors?.description}
												onChange={(e) => setDescription(e.target.value)}
												maxWidth="max-w-lg"
												required
											/>
											<FormSelectField
												label="Type"
												name="type"
												value={type}
												options={
													VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION
												}
												errorText={errors?.type}
												onChange={(e) => setType(parseInt(e.target.value))}
												maxWidth="max-w-xs"
											/>
											<FormSelectFieldForVideoCategory
												videoCategoryID={videoCategoryID}
												setVideoCategoryID={setVideoCategoryID}
												isVideoCategoryOther={isVideoCategoryOther}
												setIsVideoCategoryOther={setIsVideoCategoryOther}
												errorText={errors?.videoCategoryID}
												required
												maxWidth="max-w-lg"
											/>
										</div>
									</section>
								</div>
							)}
						</div>

						{/* Footer */}
						<div className="px-4 py-4 border-t border-gray-200">
							<div className="flex justify-between items-center">
								<button
									onClick={onSubmitClick}
									disabled={isFetching}
									className="inline-flex justify-center items-center px-4 py-2 border w-full mx-auto sm:w-auto border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark disabled:opacity-50">
									<FontAwesomeIcon icon={faSave} className="mr-2" />
									Save Changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminVideoCollectionUpdate;
