import React, { useState, useEffect } from "react";
import Scroll from "react-scroll";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlay,
  faEye,
  faClock,
  faVideo,
  faList,
  faTable,
  faFilter,
  faChartLine,
  faThumbsUp,
  faLayerGroup,
  faShare,
  faExclamationTriangle,
  faGauge
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../../AppState";
import { getVideoCategoryListAPI } from "../../../API/VideoCategory";
import Layout from "../../Menu/Layout";
import debounce from "lodash/debounce";

// Loading Skeleton Component
const CategorySkeleton = ({ viewMode = "grid" }) => {
  if (viewMode === "list") {
    return (
      <div className="animate-pulse flex bg-white rounded-xl overflow-hidden border border-gray-100">
        <div className="w-64 h-36 bg-gray-200 flex-shrink-0" />
        <div className="flex-1 p-4">
          <div className="flex items-start gap-4">
            <div className="w-12 h-12 rounded-full bg-gray-200 flex-shrink-0" />
            <div className="flex-1">
              <div className="h-6 bg-gray-200 rounded w-3/4 mb-4" />
              <div className="h-4 bg-gray-200 rounded w-full mb-2" />
              <div className="h-4 bg-gray-200 rounded w-2/3" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="animate-pulse bg-white rounded-xl overflow-hidden border border-gray-100">
      <div className="aspect-video bg-gray-200" />
      <div className="p-4">
        <div className="flex items-start gap-3">
          <div className="w-10 h-10 rounded-full bg-gray-200 flex-shrink-0" />
          <div className="flex-1">
            <div className="h-5 bg-gray-200 rounded w-3/4 mb-2" />
            <div className="h-4 bg-gray-200 rounded w-1/2" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Error State Component
const ErrorState = ({ error }) => (
  <div className="text-center py-12">
    <FontAwesomeIcon
      icon={faExclamationTriangle}
      className="text-4xl text-gray-400 mb-4"
    />
    <h3 className="text-xl font-semibold text-gray-900 mb-2">
      Something went wrong
    </h3>
    <p className="text-gray-500">{error}</p>
  </div>
);

// Category Card Component
const CategoryCard = ({ category, viewMode = "grid" }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    if (viewMode === "list") {
      return (
        <Link
          to={`/videos/category/${category.id}`}
          className="flex bg-white rounded-xl overflow-hidden hover:bg-gray-50 
                    transition-all duration-300 group border border-gray-100"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* Thumbnail Section */}
          <div className="relative w-64 h-36">
            <img
              src="/static/mma/mma_lesson.png"
              alt={category.name}
              className="w-full h-full object-cover transition-transform duration-500 
                       group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-black/40 flex items-center justify-center 
                          opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <FontAwesomeIcon
                icon={faLayerGroup}
                className="text-white text-4xl transform scale-0 group-hover:scale-100 
                         transition-transform duration-300"
              />
            </div>
          </div>
  
          {/* Content Section */}
          <div className="flex-1 p-4">
            <div className="flex items-start gap-4">
              <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary to-primary/80 
                            flex items-center justify-center flex-shrink-0">
                <FontAwesomeIcon icon={faVideo} className="text-white text-lg" />
              </div>
              <div className="flex-1 min-w-0">
                <h3 className="font-semibold text-gray-900 text-lg mb-2 line-clamp-1">
                  {category.name}
                </h3>
                <p className="text-sm text-gray-500 line-clamp-2 mb-3">
                  {category.description || "Explore our collection of videos in this category"}
                </p>
                <div className="flex items-center gap-4 text-xs text-gray-500">
                  <span className="flex items-center gap-1">
                    <FontAwesomeIcon icon={faEye} />
                    {category.viewCount || 0} views
                  </span>
                  <span className="flex items-center gap-1">
                    <FontAwesomeIcon icon={faClock} />
                    {category.lastUpdated || "Recently updated"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }
  
    return (
      <Link
        to={`/videos/category/${category.id}`}
        className="flex flex-col bg-white rounded-xl overflow-hidden hover:bg-gray-50 
                  transition-all duration-300 group border border-gray-100"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {/* Thumbnail */}
        <div className="relative aspect-video">
          <img
            src="/static/mma/mma_lesson.png"
            alt={category.name}
            className="w-full aspect-video object-cover transition-transform duration-500 
             group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-black/40 flex items-center justify-center 
                        opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <FontAwesomeIcon
              icon={faLayerGroup}
              className="text-white text-4xl transform scale-0 group-hover:scale-100 
                       transition-transform duration-300"
            />
          </div>
        </div>
  
        {/* Content */}
        <div className="p-4">
          <div className="flex items-start gap-3">
            <div className="w-10 h-10 rounded-full bg-gradient-to-br from-primary to-primary/80 
                          flex items-center justify-center flex-shrink-0">
              <FontAwesomeIcon icon={faVideo} className="text-white" />
            </div>
            <div>
              <h3 className="font-semibold text-gray-900 line-clamp-2 mb-1">
                {category.name}
              </h3>
              <div className="flex items-center gap-3 text-xs text-gray-500">
                <span className="flex items-center gap-1">
                  <FontAwesomeIcon icon={faEye} />
                  {category.viewCount || 0}
                </span>
                <span>•</span>
                <span>{category.lastUpdated || "Recently updated"}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  
  // Filter Bar Component
  const FilterBar = ({ activeFilter, setActiveFilter }) => {
    const filters = [
      { id: "all", label: "All Categories", icon: faList },
      // { id: "popular", label: "Most Popular", icon: faThumbsUp },
      { id: "recent", label: "Recently Added", icon: faClock },
      // { id: "viewed", label: "Most Viewed", icon: faEye },
      // { id: "trending", label: "Trending Now", icon: faChartLine },
    ];
  
    return (
      <div className="w-full overflow-x-auto scrollbar-hide -mx-4 px-4 sm:mx-0 sm:px-0">
        <div className="inline-flex md:flex flex-nowrap md:flex-wrap gap-2 pb-2 sm:pb-0">
          {filters.map((filter) => (
            <button
              key={filter.id}
              onClick={() => setActiveFilter(filter.id)}
              className={`
                whitespace-nowrap px-4 py-2.5 rounded-full text-sm font-medium
                transition-all duration-200 flex items-center gap-2 flex-shrink-0
                ${activeFilter === filter.id
                  ? "bg-primary text-white shadow-sm hover:bg-primary-dark"
                  : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200"
                }
              `}
            >
              <FontAwesomeIcon icon={filter.icon} className="w-4 h-4" />
              {filter.label}
            </button>
          ))}
        </div>
      </div>
    );
  };

  // Main Categories Component
const VideoHomepage = () => {
    const [currentUser] = useRecoilState(currentUserState);
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [viewMode, setViewMode] = useState("grid");
    const [activeFilter, setActiveFilter] = useState("all");
    const [searchText, setSearchText] = useState("");
  
    // Breadcrumb configuration
    const breadcrumbItems = [
      { label: "Dashboard", link: "/dashboard", icon: faGauge },
      { label: "MMA Lessons", icon: faVideo },
    ];
  
    // Fetch categories
    useEffect(() => {
      const fetchCategories = async () => {
        Scroll.animateScroll.scrollToTop();
        try {
          setIsLoading(true);
          const response = await getVideoCategoryListAPI(
            new Map(),
            (response) => {
              setCategories(response.results || []);
              setFilteredCategories(response.results || []);
            },
            (error) => {
              setError(error.message || "Failed to load categories");
            }
          );
        } catch (err) {
          setError(err.message || "Failed to load categories");
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchCategories();
    }, []);
  
    // Handle search with debounce
    const handleSearch = debounce((value) => {
      setSearchText(value);
      if (!categories) return;
  
      const filtered = categories.filter((category) =>
        category.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCategories(filtered);
    }, 300);
  
    // Handle filter changes
    useEffect(() => {
      if (!categories) return;
  
      let filtered = [...categories];
  
      switch (activeFilter) {
        case "popular":
          filtered = filtered.sort((a, b) => b.viewsCount - a.viewsCount);
          break;
        case "recent":
          filtered = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          break;
        case "viewed":
          filtered = filtered.sort((a, b) => b.viewsCount - a.viewsCount);
          break;
        case "trending":
          filtered = filtered.sort((a, b) => b.trendingScore - a.trendingScore);
          break;
        default:
          break;
      }
  
      if (searchText) {
        filtered = filtered.filter((category) =>
          category.name.toLowerCase().includes(searchText.toLowerCase())
        );
      }
  
      setFilteredCategories(filtered);
    }, [activeFilter, categories, searchText]);
  
    return (
      <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
        <div className="min-h-screen bg-[#F9FAFB]">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 py-6">
            {/* Header Section */}
            <div className="flex flex-col gap-6 mb-8">
              {/* Title and View Toggle */}
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900">
                  MMA Lessons
                </h1>
                <button
                  onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium 
                           text-gray-700 bg-white rounded-full border border-gray-200 
                           hover:bg-gray-50 focus:ring-2 focus:ring-primary focus:ring-offset-2 
                           transition-all duration-200"
                >
                  <FontAwesomeIcon
                    icon={viewMode === "grid" ? faList : faTable}
                    className="mr-2 h-4 w-4"
                  />
                  {viewMode === "grid" ? "List view" : "Grid view"}
                </button>
              </div>
  
              {/* Search and Filters */}
              <div className="grid sm:grid-cols-[1fr,auto] gap-4">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search categories..."
                    className="w-full pl-10 pr-4 py-2.5 rounded-full border border-gray-200 
                             focus:ring-2 focus:ring-primary focus:border-primary 
                             transition-colors duration-200"
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>
                <FilterBar
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                />
              </div>
            </div>
  
            {/* Content Grid/List */}
            <div className="min-h-[300px]">
              {isLoading ? (
                <div className={`
                  ${viewMode === "grid" 
                    ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6" 
                    : "space-y-6"}
                `}>
                  {[...Array(8)].map((_, i) => (
                    <CategorySkeleton key={i} viewMode={viewMode} />
                  ))}
                </div>
              ) : error ? (
                <ErrorState error={error} />
              ) : (
                <div className={`
                  ${viewMode === "grid"
                    ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6"
                    : "space-y-6"}
                `}>
                  {filteredCategories.map((category) => (
                    <CategoryCard
                      key={category.id}
                      category={category}
                      viewMode={viewMode}
                    />
                  ))}
  
                  {/* Empty State */}
                  {filteredCategories.length === 0 && (
                    <div className="col-span-full text-center py-12">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="text-4xl text-gray-400 mb-4"
                      />
                      <h3 className="text-lg font-semibold text-gray-900 mb-2">
                        No Categories Found
                      </h3>
                      <p className="text-gray-500">
                        {searchText
                          ? "Try adjusting your search terms"
                          : "Categories will appear here once added"}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  };
  
  export default VideoHomepage;