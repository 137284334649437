import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeftLong,
	faArrowRightLong,
	faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {
	quizAnswersState,
	currentUserState,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import {getQuestionnaireListApi} from "../../../API/questionnaire";
import Scroll from "react-scroll";
import PageLoadingContent from "../PageLoadingContent";
import {putMemberUpdateAPI} from "../../../API/member";
import {Navigate} from "react-router-dom";

export const Title = ({text, subtitle}) => (
	<div className="space-y-4 md:space-y-6 mb-6 md:mb-8 animate-fade-in">
		<h2 className="text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight">
			<span
				className="bg-gradient-to-r from-primary via-primary-light to-primary 
        bg-[length:200%_auto] animate-gradient-flow bg-clip-text text-transparent">
				{text}
			</span>
		</h2>
		{subtitle && (
			<p
				className="text-base sm:text-lg lg:text-xl text-gray-300 leading-relaxed font-light 
        animate-slide-up max-w-2xl">
				{subtitle}
			</p>
		)}
	</div>
);

export const SelectableOption = ({option, isSelected, onSelect}) => (
	<button
		onClick={onSelect}
		className={`
      group w-full p-4 sm:p-5 lg:p-6 rounded-xl sm:rounded-2xl transition-all duration-300
      backdrop-blur-sm backdrop-filter
      relative overflow-hidden
      transform hover:scale-[1.02] hover:-translate-y-1 active:scale-[0.98]
      ${
				isSelected
					? "bg-gradient-to-r from-primary/20 to-primary-light/20 border-2 border-primary shadow-lg shadow-primary/20"
					: "bg-gray-800/40 hover:bg-gray-800/60 border-2 border-gray-700/50"
			}
    `}>
		{/* Animated background effect */}
		<div
			className={`
      absolute inset-0 bg-gradient-to-r from-primary/20 to-primary-light/20
      transition-opacity duration-300 opacity-0 group-hover:opacity-100
    `}
		/>

		{/* Content */}
		<div className="relative flex items-center justify-between">
			<span
				className={`
        text-base sm:text-lg lg:text-xl font-medium transition-colors duration-300
        ${
					isSelected
						? "text-primary-light"
						: "text-gray-300 group-hover:text-white"
				}
      `}>
				{option}
			</span>

			<div
				className={`
        transition-all duration-300 transform
        ${isSelected ? "scale-100 opacity-100" : "scale-0 opacity-0"}
      `}>
				<div className="h-5 w-5 sm:h-6 sm:w-6 rounded-full bg-primary/20 flex items-center justify-center">
					<FontAwesomeIcon
						icon={faCheck}
						className="h-3 w-3 sm:h-4 sm:w-4 text-primary-light animate-bounce"
					/>
				</div>
			</div>
		</div>

		{/* Shine effect */}
		<div
			className={`
      absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent
      -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-out
    `}
		/>
	</button>
);

export const Onboarding = () => {
	const [currentUser] = useRecoilState(currentUserState);
	const [isFetching, setFetching] = useState(false);
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState(null);
	const [forceURL, setForceURL] = useState("");

	function OnListSuccess(response) {
		if (response && response.results && response.results.length > 0) {
			setListData(response);
		} else {
			setListData(null);
			setForceURL("/dashboard");
		}
	}

	function OnListErr(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onListDone() {
		setFetching(false);
	}

	const fetchList = () => {
		setFetching(true);
		setErrors({});
		let params = new Map();
		// params.set("status", true);
		getQuestionnaireListApi(params, OnListSuccess, OnListErr, onListDone);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			if (!currentUser.onboardingCompleted && currentUser.role === 4) {
				fetchList();
			} else {
				setForceURL("/dashboard");
			}
		}

		return () => {
			mounted = false;
		};
	}, [currentUser]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<>
			{isFetching ? (
				<PageLoadingContent displayMessage={"Please wait..."} />
			) : (
				<>
					{listData && listData.results.length > 0 && (
						<OnBoardingQuestionWizard questions={listData.results} />
					)}
				</>
			)}
		</>
	);
};

export const OnBoardingQuestionWizard = ({questions}) => {
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [forceURL, setForceURL] = useState("");
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [answers, setAnswers] = useRecoilState(quizAnswersState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	const isLastQuestion = currentQuestionIndex === questions.length - 1;
	const isFirstQuestion = currentQuestionIndex === 0;
	const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

	const handleNext = () => {
		if (!isLastQuestion) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		}
	};

	const handlePrevious = () => {
		if (!isFirstQuestion) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		}
	};

	function onAdminMemberUpdateSuccess(response) {
		setTopAlertMessage("Member updated successfully");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setCurrentUser(response);
		setForceURL("/dashboard");
	}

	function onAdminMemberUpdateError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAdminMemberUpdateDone() {
		setFetching(false);
	}

	const handleSubmit = () => {
		setFetching(true);
		setErrors({});

		const onboardingAnswers = questions.map((question) => ({
			question_id: question.id,
			answers: answers[question.id] || [],
		}));

		const decamelizedData = {
			id: currentUser.id,
			organization_id: currentUser.organizationId,
			first_name: currentUser.firstName,
			last_name: currentUser.lastName,
			email: currentUser.email,
			phone: currentUser.phone,
			postal_code: currentUser.postalCode,
			address_line_1: currentUser.addressLine1,
			address_line_2: currentUser.addressLine2,
			city: currentUser.city,
			region: currentUser.region,
			country: currentUser.country,
			status: currentUser.status,
			password: currentUser.password,
			password_repeated: currentUser.passwordRepeated,
			how_did_you_hear_about_us: currentUser.howDidYouHearAboutUs,
			how_did_you_hear_about_us_other: currentUser.howDidYouHearAboutUsOther,
			agree_promotions_email: currentUser.agreePromotionsEmail,
			onboarding_answers: onboardingAnswers,
			onboarding_completed: true,
		};

		putMemberUpdateAPI(
			decamelizedData,
			onAdminMemberUpdateSuccess,
			onAdminMemberUpdateError,
			onAdminMemberUpdateDone
		);
	};

	const handleSelect = (questionId, selectedId, isMultiSelect) => {
		if (isMultiSelect) {
			const updatedSelections = answers[questionId]?.includes(selectedId)
				? answers[questionId].filter((id) => id !== selectedId)
				: [...(answers[questionId] || []), selectedId];
			setAnswers({...answers, [questionId]: updatedSelections});
		} else {
			setAnswers({...answers, [questionId]: [selectedId]});
		}
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<div className="fixed inset-0 z-50 overflow-hidden">
			{/* Animated background gradient */}
			<div
				className="absolute inset-0 bg-gradient-to-br from-gray-900 via-backgroundDark to-gray-900 
        bg-[length:200%_200%] animate-gradient-flow"
			/>

			{/* Content wrapper */}
			<div className="relative min-h-screen min-w-screen flex items-stretch">
				<div className="w-full animate-fade-in">
					{/* Main container */}
					<div className="h-screen flex flex-col">
						{/* Header */}
						<header className="border-b border-gray-700/30 bg-gray-900/40 backdrop-blur-xl">
							<nav className="px-4 sm:px-6 lg:px-8 py-4 sm:py-5 lg:py-6 max-w-[1920px] mx-auto">
								<div className="flex items-center justify-between">
									<div className="flex items-center space-x-4 sm:space-x-6">
										<img
											src="/static/logo.png"
											className="h-8 sm:h-10 lg:h-12 w-auto object-contain hover:opacity-80 transition-opacity"
											alt="MMA_Fitness_Logo"
										/>
										<div className="h-6 sm:h-8 w-px bg-gray-700/30" />
										<div className="flex flex-col">
											<span className="text-xs sm:text-sm text-gray-400">
												Onboarding
											</span>
											<span className="text-base sm:text-lg lg:text-xl font-semibold text-white">
												Fitness Quiz
											</span>
										</div>
									</div>

									{/* Progress indicator */}
									<div className="flex items-center space-x-3 sm:space-x-4">
										<div className="text-right">
											<p className="text-xs sm:text-sm text-gray-400">
												Question
											</p>
											<p className="text-base sm:text-lg lg:text-xl font-semibold text-white">
												{currentQuestionIndex + 1}/{questions.length}
											</p>
										</div>
										<div
											className="h-10 w-10 sm:h-12 sm:w-12 rounded-full bg-primary/20 
                      flex items-center justify-center">
											<span className="text-primary-light font-semibold text-sm sm:text-base">
												{Math.round(
													((currentQuestionIndex + 1) / questions.length) * 100
												)}
												%
											</span>
										</div>
									</div>
								</div>
							</nav>

							{/* Progress bar */}
							<div className="w-full h-0.5 sm:h-1 bg-gray-800/50">
								<div
									className="h-full bg-gradient-to-r from-primary via-primary-light to-primary
                    transition-all duration-500 ease-out"
									style={{width: `${progress}%`}}
								/>
							</div>
						</header>

						{/* Body - Using flex-grow to take remaining height */}
						<section className="flex-grow overflow-y-auto">
							<div className="mx-auto p-4 sm:p-6 lg:p-8 xl:p-10 h-full">
								<div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-12 h-full">
									{/* Question Content */}
									<div className="space-y-6 sm:space-y-8">
										<Title
											text={questions[currentQuestionIndex]?.title}
											subtitle={questions[currentQuestionIndex]?.subtitle}
										/>
										<div className="space-y-3 sm:space-y-4">
											{questions[currentQuestionIndex]?.options.map(
												(option, index) => (
													<div
														key={option}
														className="animate-slide-up"
														style={{animationDelay: `${index * 100}ms`}}>
														<SelectableOption
															option={option}
															isSelected={answers[
																questions[currentQuestionIndex]?.id
															]?.includes(option)}
															onSelect={() =>
																handleSelect(
																	questions[currentQuestionIndex]?.id,
																	option,
																	questions[currentQuestionIndex]?.isMultiselect
																)
															}
														/>
													</div>
												)
											)}
										</div>
									</div>

									{/* Image Column - Full height */}
									<div className="hidden lg:block relative group">
										<div className="absolute inset-0">
											<div className="h-full w-full">
												<div
													className="absolute inset-0 bg-gradient-to-t from-black via-black/70 to-transparent 
              rounded-2xl z-10 transition-transform duration-500 group-hover:scale-[1.02]"
												/>
												<img
													src={
														questions[currentQuestionIndex]?.image ||
														"/static/mma/img1.png"
													}
													alt="Fitness"
													className="w-full h-full object-cover rounded-2xl shadow-xl 
                transition-transform duration-500 group-hover:scale-[1.02]"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>

						{/* Footer */}
						<footer className="border-t border-gray-700/30 bg-gray-900/40 backdrop-blur-xl pb-safe">
							<div
								className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-5 lg:py-6
                flex justify-between items-center">
								<button
									className={`
                    px-4 sm:px-6 py-2.5 sm:py-3 rounded-xl text-sm sm:text-base font-medium 
                    transition-all duration-300
                    flex items-center space-x-2 transform hover:-translate-y-0.5
                    ${
											isFirstQuestion
												? "bg-gray-800/50 text-gray-500 cursor-not-allowed opacity-50"
												: "bg-gray-800 hover:bg-gray-700 text-white hover:shadow-lg"
										}
                  `}
									onClick={handlePrevious}
									disabled={isFirstQuestion}>
									<FontAwesomeIcon
										icon={faArrowLeftLong}
										className="h-3.5 w-3.5 sm:h-4 sm:w-4"
									/>
									<span>Previous</span>
								</button>

								{isLastQuestion ? (
									<button
										className="px-4 sm:px-6 py-2.5 sm:py-3 rounded-xl text-sm sm:text-base font-medium
                      bg-gradient-to-r from-green-600 to-green-500
                      hover:from-green-500 hover:to-green-400
                      text-white transition-all duration-300
                      hover:shadow-lg hover:shadow-green-600/20
                      transform hover:-translate-y-0.5
                      flex items-center space-x-2"
										onClick={handleSubmit}>
										<span>Complete</span>
										<FontAwesomeIcon
											icon={faCheck}
											className="h-3.5 w-3.5 sm:h-4 sm:w-4"
										/>
									</button>
								) : (
									<button
										className="px-4 sm:px-6 py-2.5 sm:py-3 rounded-xl text-sm sm:text-base font-medium
                      bg-gradient-to-r from-primary to-primary-light
                      hover:from-primary-light hover:to-primary
                      text-white transition-all duration-300
                      hover:shadow-lg hover:shadow-primary/20
                      transform hover:-translate-y-0.5
                      flex items-center space-x-2"
										onClick={handleNext}>
										<span>Next</span>
										<FontAwesomeIcon
											icon={faArrowRightLong}
											className="h-3.5 w-3.5 sm:h-4 sm:w-4"
										/>
									</button>
								)}
							</div>
						</footer>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Onboarding;
