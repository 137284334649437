import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faFilterCircleXmark,
	faLeaf,
	faArrowLeft,
	faPlus,
	faGauge,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faCalendarAlt,
	faClock,
	faUser,
	faLocationDot,
	faListCheck,
	faEye,
	faExclamationCircle,
	faEyeSlash,
	faCheckCircle,
	faUtensils,
	faChartLine,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import Modal from "../../Reusable/Modal";
import {
	getNutritionPlanListAPI,
	deleteNutritionPlanAPI,
} from "../../../API/NutritionPlan";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	nutritionPlanVisibility,
	nutritionPlanFilterStatusState,
	nutrtionPlanFilterSortState,
	nutritionPlanFilterShowState,
	nutritionPlanFilterTemporarySearchTextState,
	nutritionPlanFilterActualSearchTextState,
} from "../../../AppState";
import {
	NUTRITION_PLAN_STATUS_CONFIG,
	NUTRITION_PLAN_VISIBILITY_OPTIONS,
	NUTRITION_PLAN_VISIBILITY_WITH_EMPTY_VISBILITY_OPTIONS,
	NUTRITIONAL_GOAL_MAP,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";
import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import CardView from "../../Reusable/List/CardView";

function MemberNutritionPlanList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		nutritionPlanFilterShowState
	);
	const [sort, setSort] = useRecoilState(nutrtionPlanFilterSortState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		nutritionPlanFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		nutritionPlanFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(nutritionPlanFilterStatusState);
	const [visibility, setVisibility] = useRecoilState(nutritionPlanVisibility);

	// Component states
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState(null);
	const [
		selectedNutritionPlanForDeletion,
		setSelectedNutritionPlanForDeletion,
	] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

	// API
	function onNutritionPlanListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onNutritionPlanListError(apiErr) {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	}

	function onNutritionPlanListDone() {
		setFetching(false);
	}

	function onNutritionPlanDeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("Nutrition Plan deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			status,
			sort,
			visibility
		);
	}

	function onNutritionPlanDeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		Scroll.animateScroll.scrollToTop();
	}

	function onNutritionPlanDeleteDone() {
		setFetching(false);
	}

	// Event handling
	const fetchList = (cur, limit, keywords, stat, sbv, visibility) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);

		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}

		if (cur !== "") {
			params.set("cursor", cur);
		}

		if (stat !== "") {
			params.set("status", stat);
		}

		if (keywords !== undefined && keywords !== null && keywords !== "") {
			params.set("search", keywords);
		}

		params.set("visibility", visibility);

		console.log(params);

		getNutritionPlanListAPI(
			params,
			onNutritionPlanListSuccess,
			onNutritionPlanListError,
			onNutritionPlanListDone
		);
	};

	const onNextClicked = () => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = () => {
		setActualSearchText(temporarySearchText);
	};

	const onClearFilterClick = () => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setSort("no,1");
		setStatus(0);
	};

	// Misc
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				sort,
				visibility
			);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, sort, visibility]);

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Nutrition Plans",
			icon: faLeaf,
		},
	];

	const onDeleteConfirmButtonClick = () => {
		if (!selectedNutritionPlanForDeletion?.id) {
			console.error("No nutrition plan selected for deletion");
			return;
		}

		deleteNutritionPlanAPI(
			selectedNutritionPlanForDeletion.id,
			onNutritionPlanDeleteSuccess,
			onNutritionPlanDeleteError,
			onNutritionPlanDeleteDone
		);
		setSelectedNutritionPlanForDeletion("");
	};

	const DeleteConfirmationModal = () => (
		<Modal
			isOpen={selectedNutritionPlanForDeletion !== ""}
			onClose={() => setSelectedNutritionPlanForDeletion("")}
			header="Delete Nutrition Plan">
			<div className="space-y-4">
				<p className="text-gray-600">
					Are you sure you want to delete this nutrition plan? This action
					cannot be undone.
				</p>
				<div className="flex justify-end space-x-3">
					<button
						onClick={() => setSelectedNutritionPlanForDeletion("")}
						className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
						Cancel
					</button>
					<button
						onClick={onDeleteConfirmButtonClick}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300">
						Delete
					</button>
				</div>
			</div>
		</Modal>
	);

	const filterContent = (
		<FilterSidebar>
			<FilterSection title="Search">
				<div className="relative">
					<input
						type="text"
						placeholder="Search nutrition plans..."
						value={temporarySearchText}
						onChange={(e) => setTemporarySearchText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSearchButtonClick();
							}
						}}
						className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/10 transition-all duration-300"
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
					/>
				</div>
			</FilterSection>

			<FilterSection title="Status">
				<select
					value={status}
					onChange={(e) => setStatus(parseInt(e.target.value))}
					className="w-full rounded-lg border border-gray-200 py-2 px-3 focus:border-primary focus:ring-2 focus:ring-primary/10">
					<option value={0}>All Status</option>
					{Object.entries(NUTRITION_PLAN_STATUS_CONFIG).map(
						([value, config]) => (
							<option key={value} value={value}>
								{config.label}
							</option>
						)
					)}
				</select>
			</FilterSection>

			<FilterSection title="Visibility">
				<select
					value={visibility}
					onChange={(e) => setVisibility(parseInt(e.target.value))}
					className="w-full rounded-lg border border-gray-200 py-2 px-3 focus:border-primary focus:ring-2 focus:ring-primary/10">
					{NUTRITION_PLAN_VISIBILITY_WITH_EMPTY_VISBILITY_OPTIONS.map(
						(option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						)
					)}
				</select>
			</FilterSection>

			<div className="mt-6">
				<button
					onClick={onClearFilterClick}
					className="w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
					Clear Filters
				</button>
			</div>
		</FilterSidebar>
	);

	const ActionButtons = () => (
		<div className="flex items-center space-x-3">
			<button
				onClick={() =>
					fetchList(
						currentCursor,
						pageSize,
						actualSearchText,
						status,
						sort,
						visibility
					)
				}
				className="inline-flex items-center px-4 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
				<FontAwesomeIcon icon={faRefresh} className="mr-2" />
				<span className="hidden sm:inline">Refresh</span>
			</button>

			<Link
				to="/nutrition-plans/add"
				className="inline-flex items-center px-4 py-2 text-sm bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				Request Plan
			</Link>
		</div>
	);

	const renderNutritionPlanCard = (plan) => {
		const statusConfig = NUTRITION_PLAN_STATUS_CONFIG[plan.status] || {
			label: "Unknown",
			color: "text-gray-500",
			icon: faExclamationCircle,
		};

		const visibilityOption = NUTRITION_PLAN_VISIBILITY_OPTIONS.find(
			(option) => option.value === plan.visibility
		);
		const visibilityText = visibilityOption?.label || "Unknown";
		const visibilityIcon = {
			Public: faEye,
			Private: faEyeSlash,
			Unknown: faExclamationCircle,
		}[visibilityText];

		const goalLabels = plan.goals.map(
			(goalId) => NUTRITIONAL_GOAL_MAP[goalId] || "Unknown Goal"
		);

		return (
			<div className="h-full flex flex-col border rounded-lg shadow-lg overflow-hidden bg-white">
				<div className="p-6 bg-gradient-to-r from-primary to-primary-light text-white">
					<h2 className="text-2xl font-bold">{plan.name}</h2>
					<p className="text-sm mt-1 text-white">
						Created by: {plan.createdByUserName}
					</p>
				</div>

				<div className="p-6 space-y-4 flex-grow">
					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={statusConfig.icon}
							className={`w-5 ${statusConfig.color}`}
						/>
						<span className={`text-sm ${statusConfig.color}`}>
							Status: {statusConfig.label}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={visibilityIcon}
							className="text-gray-400 w-5"
						/>
						<span className="text-sm text-gray-600">
							Visibility: {visibilityText}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={faCalendarAlt}
							className="text-gray-400 w-5"
						/>
						<span className="text-sm text-gray-600">
							Created on:{" "}
							{new Date(plan.createdAt).toLocaleDateString("en-US", {
								weekday: "short",
								month: "long",
								day: "numeric",
							})}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon icon={faListCheck} className="text-gray-400 w-5" />
						<span className="text-sm text-gray-600">
							Meals per day: {plan.mealsPerDay}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon icon={faUser} className="text-gray-400 w-5" />
						<span className="text-sm text-gray-600">
							Height: {plan.heightFeet}'{plan.heightInches}" | Weight:{" "}
							{plan.weight} lbs
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={faLocationDot}
							className="text-gray-400 w-5"
						/>
						<span className="text-sm text-gray-600">
							Organization: {plan.organizationName}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon icon={faListCheck} className="text-gray-400 w-5" />
						<span className="text-sm text-gray-600">
							Goals: {goalLabels.join(", ")}
						</span>
					</div>
				</div>

				<div className="p-4 bg-gray-50">
					<Link
						to={`/nutrition-plan/${plan.id}`}
						className={`w-full py-2.5 sm:py-3 px-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-all duration-300 inline-flex items-center justify-center text-sm`}>
						View Plan
					</Link>
				</div>
			</div>
		);
	};
	const EmptyState = () => (
		<div className="text-center py-12">
			<FontAwesomeIcon icon={faLeaf} className="text-gray-400 text-4xl mb-4" />
			<h3 className="text-lg font-medium text-gray-900 mb-2">
				No Nutrition Plans Found
			</h3>
			<p className="text-gray-500">
				{actualSearchText
					? `No nutrition plans matching "${actualSearchText}"`
					: "Start by requesting a new nutrition plan"}
			</p>
			<Link
				to="/nutrition-plans/add"
				className="inline-flex items-center px-4 py-2 mt-4 text-sm bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				Request Plan
			</Link>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<DeleteConfirmationModal />

			<div className="relative bg-gray-900">
				{/* Decorative Elements */}
				<div className="absolute inset-0">
					<div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-10" />
					<div className="absolute inset-0 bg-gradient-to-br from-primary/10 via-accent1/10 to-primary-light/10" />
					<div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-lightShade/25 to-transparent" />
				</div>

				{/* Hero Content */}
				<div className="relative container mx-auto px-4 sm:px-6 py-12 sm:py-20">
					<div className="mx-auto text-center">
						{/* Professional Badge */}
						<div
							className="inline-flex items-center justify-center p-2 mb-6 
                    rounded-2xl bg-primary/10 backdrop-blur-xs 
                    border border-primary/25">
							<span className="px-3 py-1 text-sm font-medium text-lightShade font-secondary">
								Professional Nutrition Plans
							</span>
						</div>

						{/* Main Heading */}
						<h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-lightShade mb-4 sm:mb-6 font-primary">
							Your Personalized Nutrition Plan
						</h1>

						{/* Primary Description */}
						<p className="text-lg sm:text-xl text-gray-100/90 mx-auto mb-12 font-secondary">
							Expert-designed nutrition plans tailored to support your MMA
							training and performance goals
						</p>

						{/* Info Boxes */}
						<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto mb-12">
							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faUtensils}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Custom Macros
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Nutrition plans aligned with your body composition and
									training intensity
								</p>
							</div>

							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faChartLine}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Performance Focus
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Meal plans optimized for athletic performance and recovery
								</p>
							</div>

							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faUserTie}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Expert Design
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Created by certified nutritionists specializing in combat
									sports
								</p>
							</div>
						</div>

						{/* Additional Info Grid */}
						<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto">
							<div className="p-6 rounded-xl bg-gray-800/50 text-left">
								<h4 className="text-base font-semibold text-lightShade mb-2 font-primary">
									Getting Started
								</h4>
								<p className="text-sm text-gray-300 font-secondary">
									Complete your onboarding questionnaire to set your initial
									preferences and generate your first nutrition plan
								</p>
							</div>

							<div className="p-6 rounded-xl bg-gray-800/50 text-left">
								<h4 className="text-base font-semibold text-lightShade mb-2 font-primary">
									Plan Updates
								</h4>
								<p className="text-sm text-gray-300 font-secondary">
									Visit your profile settings anytime to update your preferences
									and generate new customized plans
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ListWithFilters
				title={
					<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-6">
						<div className="flex items-center space-x-3">
							<FontAwesomeIcon icon={faLeaf} className="text-primary text-xl" />
							<h1 className="text-2xl font-bold">Nutrition Plans</h1>
						</div>
						<div className="flex items-center space-x-3 sm:w-auto">
							<ActionButtons />
						</div>
					</div>
				}
				filterContent={filterContent}
				loading={isFetching}
				error={typeof errors === "string" ? errors : null}
				EmptyStateComponent={<EmptyState />}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={listData?.hasNextPage}>
				<CardView
					items={listData?.results}
					renderItem={renderNutritionPlanCard}
					linkPrefix="/nutrition-plans"
					emptyState={<EmptyState />}
				/>
			</ListWithFilters>
		</Layout>
	);
}

export default MemberNutritionPlanList;
