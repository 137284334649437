import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGauge,
  faPencil,
  faQuestionCircle,
  faEye,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";
import {
  getQuestionnaireDetailAPI,
  putQuestionnaireUpdateAPI,
} from "../../../API/questionnaire";

function AdminQuestionnaireUpdate() {
  const { id } = useParams();
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  // Component states
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [isMultiselect, setIsMultiselect] = useState(false);
  const [status, setStatus] = useState(true);
  const [options, setOptions] = useState([]);

  // Event handlers
  const onSubmitClick = (e) => {
    setFetching(true);
    setErrors({});
    putQuestionnaireUpdateAPI(
      id,
      {
        id,
        title,
        subtitle,
        is_multiselect: isMultiselect,
        status,
        options,
      },
      onQuestionnaireUpdateSuccess,
      onQuestionnaireUpdateError,
      onQuestionnaireUpdateDone
    );
  };

  // API callbacks
  function onQuestionnaireDetailSuccess(response) {
    setTitle(response.title);
    setSubTitle(response.subtitle);
    setStatus(response.status);
    setIsMultiselect(response.isMultiselect);
    setOptions(response.options);
  }

  function onQuestionnaireDetailError(apiErr) {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  }

  function onQuestionnaireUpdateDone() {
    setFetching(false);
  }

  function onQuestionnaireUpdateSuccess(response) {
    setTopAlertMessage("Question updated successfully");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL(`/admin/questions/${response.id}`);
  }

  function onQuestionnaireUpdateError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  }

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      window.scrollTo(0, 0);
      setFetching(true);
      getQuestionnaireDetailAPI(
        id,
        onQuestionnaireDetailSuccess,
        onQuestionnaireDetailError,
        () => setFetching(false)
      );
    }
    return () => {
      mounted = false;
    };
  }, [id]);

  if (forceURL) {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Questions", link: "/admin/questions", icon: faQuestionCircle },
    { label: "Detail", link: `/admin/questions/${id}`, icon: faEye },
    { label: "Edit", icon: faPencil },
  ];

  const OptionsComponent = ({
    options,
    setOptions,
    label,
    helpText,
    errorText,
  }) => {
    const handleAddOption = () => {
      setOptions([...options, ""]);
    };
  
    const handleOptionChange = (index, value) => {
      const updatedOptions = [...options];
      updatedOptions[index] = value;
      setOptions(updatedOptions);
    };
  
    const handleRemoveOption = (indexToRemove) => {
      setOptions(options.filter((_, index) => index !== indexToRemove));
    };
  
    return (
      <div className="space-y-4">
        <div className="flex items-center gap-3">
          <label className="text-sm font-medium text-gray-700">{label}</label>
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 bg-primary hover:bg-primary-dark 
                     text-white rounded-lg transition-colors duration-300 text-sm"
            onClick={handleAddOption}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add
          </button>
        </div>
        
        {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
        
        <div className="space-y-3">
          {options.map((option, index) => (
            <div key={index} className="flex items-center gap-2">
              <div className="flex-grow">
                <FormInputField
                  type="text"
                  placeholder="Enter option"
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  maxWidth="300px"
                />
              </div>
              <button
                type="button"
                onClick={() => handleRemoveOption(index)}
                className="inline-flex items-center p-2 text-gray-500 hover:text-red-600 
                         hover:bg-red-50 rounded-lg transition-colors duration-300"
                title="Remove option"
              >
                <FontAwesomeIcon 
                  icon={faTrash} 
                  className="w-4 h-4"
                />
              </button>
            </div>
          ))}
        </div>
        
        {errorText && <p className="text-sm text-red-600">{errorText}</p>}
        
        {options.length === 0 && (
          <p className="text-sm text-gray-500 italic">
            No options added yet. Click the Add button to create options.
          </p>
        )}
      </div>
    );
  };

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen bg-gray-50/50">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
          {/* Main Content */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200">
            <div className="p-4 sm:p-6">
              <FormErrorBox errors={errors} />
              
              {isFetching ? (
                <PageLoadingContent displayMessage="Loading..." />
              ) : (
                <div className="space-y-6">
                  <FormInputField
                    label="Title"
                    name="title"
                    placeholder="Please enter the title"
                    value={title}
                    errorText={errors && errors.title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxWidth="380px"
                  />

                  <FormTextareaField
                    label="Subtitle"
                    name="subtitle"
                    placeholder="Please enter the subtitle"
                    value={subtitle}
                    errorText={errors && errors.subtitle}
                    onChange={(e) => setSubTitle(e.target.value)}
                    rows={4}
                    maxWidth="380px"
                  />

                  <FormRadioField
                    label="Is Multiselect"
                    name="isMultiselect"
                    value={isMultiselect}
                    opt1Value={true}
                    opt1Label="Yes"
                    opt2Value={false}
                    opt2Label="No"
                    errorText={errors && errors.isMultiselect}
                    onChange={(e) => setIsMultiselect(e.target.value === "true")}
                  />

                  <FormRadioField
                    label="Status"
                    name="status"
                    value={status}
                    opt1Value={true}
                    opt1Label="Active"
                    opt2Value={false}
                    opt2Label="Archived"
                    errorText={errors && errors.status}
                    onChange={(e) => setStatus(e.target.value === "true")}
                  />

                  <OptionsComponent
                    options={options}
                    setOptions={setOptions}
                    label="Option(s)"
                    helpText="Add options for the questionnaire"
                    errorText={errors && errors.options}
                  />

                  <div className="flex flex-col sm:flex-row justify-between gap-4">
                    <Link
                      to="/admin/questions"
                      className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 
                               text-gray-700 bg-white rounded-lg hover:bg-gray-50 transition-colors duration-300"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                      Back to Questions
                    </Link>
                    <button
                      onClick={onSubmitClick}
                      className="inline-flex items-center justify-center px-4 py-2 bg-primary 
                               hover:bg-primary-dark text-white rounded-lg transition-colors duration-300"
                      disabled={isFetching}
                    >
                      <FontAwesomeIcon icon={faPencil} className="mr-2" />
                      Update
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Cancel Warning Modal */}
      <Modal
        isOpen={showCancelWarning}
        onClose={() => setShowCancelWarning(false)}
        header="Are you sure?"
      >
        <div className="space-y-4">
          <p className="text-gray-600">
            Your changes will be lost if you cancel. This action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setShowCancelWarning(false)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 
                       rounded-lg hover:bg-gray-50 transition-colors duration-300"
            >
              No, Continue Editing
            </button>
            <Link
              to={`/admin/questions/${id}`}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg 
                       hover:bg-red-700 transition-colors duration-300"
            >
              Yes, Cancel
            </Link>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}

export default AdminQuestionnaireUpdate;