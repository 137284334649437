import { useState, useEffect } from "react";
import Scroll from "react-scroll";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faGauge,
  faPencil,
  faEye,
  faBolt,
  faUsers,
  faPencilRuler
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";

import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
  topAlertMessageState,
  topAlertStatusState,
  fitnessChallengeDatumState,
  currentUserState
} from "../../../AppState";
import {
  deletefitnessChallengeAPI,
  getfitnessChallengeDetailAPI,
} from "../../../API/FitnessChallenge";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import Layout from "../../Menu/Layout";
import { BADGE_STATUS_MAP } from "../../../Constants/FieldOptions";

// Reusable Components
const Card = ({ children, className = "" }) => (
  <div className={`bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ${className}`}>
    {children}
  </div>
);

const DetailHeader = ({ datum, onDeleteClick }) => (
  <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
    <h1 className="text-2xl sm:text-3xl font-bold text-textDark flex items-center animate-fade-in">
      <FontAwesomeIcon className="mr-2" icon={faBolt} />
      Challenge Details
    </h1>
    <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
      <Link
        to={`/admin/fitness-challenge/${datum.id}/update`}
        className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2.5 bg-primary hover:bg-primary-dark text-white rounded-lg transition-all duration-200 animate-fade-in"
      >
        <FontAwesomeIcon className="mr-2" icon={faPencil} />
        Edit Challenge
      </Link>
      <button
        onClick={onDeleteClick}
        className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2.5 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-200 animate-fade-in"
      >
        <FontAwesomeIcon className="mr-2" icon={faTrash} />
        Delete
      </button>
    </div>
  </div>
);

function AdminFitnessChallengeDetail() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [fitnessChallengeDatum, setFitnessChallengedatum] = useRecoilState(
		fitnessChallengeDatumState
	);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [selectedChallengeForDeletion, setselectedChallengeForDeletion] =
		useState(null);

	// Breadcrumb items
	const breadcrumbItems = [
		{
			label: "Dashboard",
			link: "/admin/dashboard",
			icon: faGauge
		},
		{
			label: "Fitness Challenges",
			link: "/admin/fitness-challenges",
			icon: faBolt
		},
		{
			label: "Challenge Details",
			icon: faEye
		}
	]

	////
	//// API.
	////

	////
	//// Event handling.
	////

	// --- Detail --- //

	function onVideoCollectionDetailSuccess(response) {
		setDatum(response);
		setFitnessChallengedatum(response);
	}

	function onVideoCollectionDetailError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		setFetching(false);
	}

	// --- Delete --- //

	const onDeleteConfirm = () => {
		if (!selectedChallengeForDeletion) return;
	
		setFetching(true);
		setErrors({});
	
		deletefitnessChallengeAPI(
		  selectedChallengeForDeletion.id,
		  onDeleteSuccess,
		  onDeleteError,
		  onDeleteDone
		);
	  };
	
	  const onDeleteSuccess = () => {
		setTopAlertMessage("Challenge deleted successfully");
		setTopAlertStatus("success");
		setForceURL("/admin/fitness-challenge");
	  };
	
	  const onDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to delete challenge");
		setTopAlertStatus("danger");
	  };
	
	  const onDeleteDone = () => {
		setFetching(false);
		setselectedChallengeForDeletion(null);
	  };

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getfitnessChallengeDetailAPI(
				id,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}


  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <Card className="overflow-hidden">
            <div className="p-6">
              <FormErrorBox errors={errors} />

              {isFetching ? (
                <div className="p-8">
                  <PageLoadingContent message="Loading challenge details..." />
                </div>
              ) : (
                datum && (
                  <div className="space-y-8 animate-fade-in">
                    <DetailHeader datum={datum} onDeleteClick={() => setselectedChallengeForDeletion(datum)} />

                    {/* Tab Navigation */}
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        <Link
                          className="border-primary text-primary border-b-2 py-4 px-1 text-md font-medium"
                        >
                          Detail
                        </Link>
                        <Link
                          to={`/admin/fitness-challenge/${id}/leaderboard`}
                          className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium"
                        >
                          Leaderboard
                        </Link>
                        {datum.criteria === 2 && (
                          <Link
                            to={`/admin/fitness-challenge/${id}/assign-badge`}
                            className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium"
                          >
                            Assign Badges
                          </Link>
                        )}
                      </nav>
                    </div>

                    {/* Challenge Details */}
                    <section className="space-y-6">
                      <div className="bg-gradient-to-r from-primary/5 to-primary/10 rounded-xl p-5">
                        <h2 className="text-xl font-semibold text-gray-900 mb-4">
                          {datum.name}
                        </h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                          <DataDisplayRowText label="Challenge Overview" value={datum.description} />
                          <DataDisplayRowText label="Duration" value={`${datum.durationInWeeks} weeks`} />
                          <DataDisplayRowText label="Start Date" value={datum.startTime} type="datetime" />
                          <DataDisplayRowText label="Maximum Points Required" value={datum.maxPoints} />
                          <DataDisplayRowText 
                            label="Challenge Type" 
                            value={datum.category === 2 ? "Self Update Based" : "Biometric Based"} 
                          />
                          <DataDisplayRowText label="Criteria" value={BADGE_STATUS_MAP[datum.criteria]} />
                          {datum.earnsBadge && (
                            <DataDisplayRowText label="Badge Awarded" value={datum.badgeName} />
                          )}
                        </div>
                      </div>

                      {/* Rules Section */}
                      <div className="bg-white rounded-xl shadow-sm">
                        <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                          <FontAwesomeIcon icon={faPencilRuler} className="mr-2 text-primary" />
                          Challenge Rules
                        </h2>
                        <div className="space-y-4">
                          {datum?.rules?.map((rule, index) => (
                            <div key={index} className="bg-gray-50 rounded-lg p-4">
                              <h3 className="font-medium text-gray-900 mb-2">
                                Rule {index + 1}: {rule.name}
                              </h3>
                              <DataDisplayRowText label="Details" value={rule.description} />
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Participants Section */}
                      <div className="bg-white rounded-xl shadow-sm">
                        <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                          <FontAwesomeIcon icon={faUsers} className="mr-2 text-primary" />
                          Participants
                        </h2>
                        {datum?.users && datum.users.length > 0 ? (
                          <div className="grid gap-3">
                            {datum.users.map((user, index) => (
                              <div key={index} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                <span className="font-medium text-gray-900">{user.name}</span>
                                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                                  user.completed 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {user.completed ? 'Completed' : 'In Progress'}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <p className="text-gray-500 text-center py-4">
                            No participants have joined yet.
                          </p>
                        )}
                      </div>
                    </section>

                    {/* Back Button */}
                    <div className="pt-6">
                      <Link
                        to="/admin/fitness-challenges"
                        className="inline-flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-all duration-200"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                        Back to Challenges
                      </Link>
                    </div>
                  </div>
                )
              )}
            </div>
          </Card>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={selectedChallengeForDeletion !== null}
          onClose={() => setselectedChallengeForDeletion(null)}
          header="Delete Challenge"
        >
          <div className="space-y-4">
            <div className="flex items-center space-x-4">
              <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
                <FontAwesomeIcon icon={faTrash} className="h-6 w-6 text-red-600" />
              </div>
              <div>
                <h4 className="text-lg font-medium text-gray-900">Confirm Deletion</h4>
                <p className="text-sm text-gray-500">This action cannot be undone.</p>
              </div>
            </div>
            
            <p className="text-gray-600">
              Are you sure you want to delete "<span className="font-medium">{selectedChallengeForDeletion?.name}</span>"?
            </p>
            
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setselectedChallengeForDeletion(null)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all duration-200"
                disabled={isFetching}
              >
                Cancel
              </button>
              <button
                onClick={onDeleteConfirm}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-all duration-200"
                disabled={isFetching}
              >
                {isFetching ? (
                  <span className="inline-flex items-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    Deleting...
                  </span>
                ) : (
                  "Delete Challenge"
                )}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
}

export default AdminFitnessChallengeDetail;