import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faClock,
	faTrophy,
	faSpinner,
	faGauge,
	faArchive,
	faTrash,
	faPencil,
	faChevronDown,
	faExclamationTriangle,
	faBed,
	faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Scroll from "react-scroll";

import {
	getFitnessPlanDetailAPI,
	deleteFitnessPlanAPI,
} from "../../../API/FitnessPlan";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import VideoPlayer, {VIDEO_LAYOUTS} from "../../Reusable/VideoPlayer";
import {
	FITNESS_GOAL_STATUS_QUEUED,
	FITNESS_GOAL_STATUS_ACTIVE,
	FITNESS_GOAL_STATUS_ARCHIVED,
	FITNESS_GOAL_STATUS_ERROR,
	FITNESS_GOAL_STATUS_IN_PROGRESS,
	FITNESS_GOAL_STATUS_PENDING,
} from "../../../Constants/App";

// Utility function to convert snake_case to camelCase
const toCamelCase = (str) =>
	str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const transformResponse = (data) => {
	if (!data) return data;
	return Object.keys(data).reduce((acc, key) => {
		const camelKey = toCamelCase(key);
		let value = data[key];

		if (Array.isArray(value)) {
			value = value.map((item) =>
				typeof item === "object" && item !== null
					? transformResponse(item)
					: item
			);
		} else if (value && typeof value === "object") {
			value = transformResponse(value);
		}

		acc[camelKey] = value;
		return acc;
	}, {});
};

// Exercise Component
const ExerciseItem = ({exercise}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const parseInstructions = (text) => {
		// Regex pattern: matches a number followed by period, space, and text up to the next number or end
		const pattern = /(\d+)\.\s([^1-9]+)/g;
		const instructions = [];
		let match;

		while ((match = pattern.exec(text)) !== null) {
			instructions.push({
				number: parseInt(match[1]),
				text: match[2].trim(),
			});
		}

		return instructions;
	};

	return (
		<div className="border-b border-gray-200 last:border-b-0">
			<div
				className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
				onClick={() => setIsExpanded(!isExpanded)}>
				<div className="flex items-center space-x-4">
					<div className="relative w-16 h-16 rounded-lg overflow-hidden bg-gray-100">
						<img
							src={exercise.thumbnailUrl}
							alt={exercise.name}
							className="w-full h-full object-cover"
						/>
					</div>
					<div>
						<h3 className="font-semibold text-gray-900">{exercise.name}</h3>
					</div>
				</div>
				<FontAwesomeIcon
					icon={faChevronDown}
					className={`text-gray-400 transition-transform duration-200 ${
						isExpanded ? "transform rotate-180" : ""
					}`}
				/>
			</div>

			{isExpanded && (
				<div className="p-4 bg-gray-50">
					<div className="flex flex-col w-full space-y-4">
						<div className="w-full">
							<VideoPlayer
								videoType={exercise.videoType}
								videoUrl={exercise.videoUrl}
								thumbnailUrl={exercise.thumbnailUrl}
								name={exercise.name}
								layout={VIDEO_LAYOUTS.FULL}
								showActions={false}
								showVideo={true}
								showDescription={false}
							/>
						</div>

						{/* Instructions */}
						<div className="text-sm text-gray-600 space-y-2">
							<h4 className="font-medium text-gray-900">Instructions:</h4>
							<ol className="list-decimal list-inside space-y-2">
								{parseInstructions(exercise.description).map(
									({number, text}) => (
										<li key={number} className="ml-4">
											{text}
										</li>
									)
								)}
							</ol>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

// Daily Plan Component
const DailyPlanSection = ({plan}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const exerciseCount = plan?.planDetails?.length || 0;
	const isRestDay = !plan?.planDetails;

	const renderIcon = () => {
		if (isRestDay) {
			return (
				<div className="relative">
					<div className="absolute -inset-1 bg-blue-500/20 rounded-full blur-sm" />
					<div className="relative flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-br from-blue-100 to-blue-200 shadow-inner">
						<FontAwesomeIcon icon={faClock} className="text-blue-600 text-lg" />
					</div>
				</div>
			);
		}
		return (
			<div className="relative">
				<div className="absolute -inset-1 bg-primary/20 rounded-full blur-sm" />
				<div className="relative flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-br from-primary/10 to-primary/20 shadow-inner">
					<FontAwesomeIcon icon={faDumbbell} className="text-primary text-lg" />
				</div>
			</div>
		);
	};

	return (
		<div
			className={`border rounded-lg bg-white mb-4 ${
				isRestDay
					? "bg-gradient-to-br from-blue-50 via-white to-indigo-50/30"
					: ""
			}`}>
			<div
				className={`flex items-center justify-between p-4 ${
					!isRestDay ? "cursor-pointer" : ""
				}`}
				onClick={() => !isRestDay && setIsExpanded(!isExpanded)}>
				<div>
					<div className="flex items-center gap-2">
						{renderIcon()}
						<h2 className="font-semibold text-gray-900">{plan.title}</h2>
						{isRestDay && (
							<span className="inline-flex items-center px-2 py-0.5 text-xs font-medium text-blue-700 bg-blue-100/50 rounded-full">
								<FontAwesomeIcon icon={faBed} className="mr-1 text-xs" />
								Rest Day
							</span>
						)}
					</div>
					<p className="text-sm text-gray-600 mt-1">{plan.instructions}</p>
				</div>
				{!isRestDay && (
					<div className="flex items-center space-x-4">
						<span className="font-semibold text-sm text-main">
							{`x${exerciseCount}`}
						</span>
						<FontAwesomeIcon
							icon={faChevronDown}
							className={`text-gray-400 transition-transform duration-200 ${
								isExpanded ? "transform rotate-180" : ""
							}`}
						/>
					</div>
				)}
			</div>

			{isExpanded && !isRestDay && (
				<div className="border-t">
					{plan.planDetails?.map((exercise) => (
						<ExerciseItem key={exercise.id} exercise={exercise} />
					))}
				</div>
			)}
		</div>
	);
};

// Weekly Plan Component
const WeeklyPlanSection = ({plan}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className="mb-6">
			<div
				className="flex items-center justify-between p-4 bg-gray-100 rounded-lg cursor-pointer mb-4"
				onClick={() => setIsExpanded(!isExpanded)}>
				<h2 className="text-lg font-bold text-gray-900">{plan.title}</h2>
				<FontAwesomeIcon
					icon={faChevronDown}
					className={`text-gray-400 transition-transform duration-200 ${
						isExpanded ? "transform rotate-180" : ""
					}`}
				/>
			</div>

			{isExpanded && (
				<div className="space-y-4">
					{plan.dailyPlans.map((dailyPlan, index) => (
						<DailyPlanSection key={index} plan={dailyPlan} />
					))}
				</div>
			)}
		</div>
	);
};

const StatusCard = ({
	status,
	icon,
	title,
	message,
	bgColor = "from-yellow-50 to-orange-50",
}) => (
	<div
		className={`w-full p-4 sm:p-6 bg-gradient-to-br ${bgColor} rounded-xl shadow-sm hover:shadow-md transition-all duration-300`}>
		<div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
			<div className="w-14 h-14 sm:w-12 sm:h-12 flex items-center justify-center rounded-full bg-white/90 shadow-inner">
				<FontAwesomeIcon
					className="text-2xl sm:text-xl text-primary"
					icon={icon}
				/>
			</div>
			<div className="text-center sm:text-left flex-1">
				<h3 className="text-xl sm:text-lg font-semibold text-gray-800 mb-2">
					{title}
				</h3>
				<p className="text-gray-600 text-sm sm:text-base leading-relaxed">
					{message}
				</p>
			</div>
		</div>
	</div>
);

// Main Component
function MemberFitnessPlanDetail() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState(null);
	const [selectedFitnessPlanForDeletion, setSelectedFitnessPlanForDeletion] =
		useState(null);

	// API Handlers
	const onFitnessPlanDetailSuccess = (response) => {
		const transformedData = transformResponse(response);
		setDatum(transformedData);
	};

	const onFitnessPlanDetailError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onFitnessPlanDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Fitness plan deleted");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/fitness-plans");
	};

	const onDeleteConfirmButtonClick = () => {
		setFetching(true);
		deleteFitnessPlanAPI(
			selectedFitnessPlanForDeletion.id,
			onFitnessPlanDeleteSuccess,
			(apiErr) => {
				setErrors(apiErr);
				setTopAlertStatus("danger");
				setTopAlertMessage("Failed deleting");
				setTimeout(() => setTopAlertMessage(""), 2000);
			},
			() => {
				setFetching(false);
				setSelectedFitnessPlanForDeletion(null);
			}
		);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			setFetching(true);
			getFitnessPlanDetailAPI(
				id,
				onFitnessPlanDetailSuccess,
				onFitnessPlanDetailError,
				() => setFetching(false)
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/dashboard"},
		{label: "Fitness Plans", icon: faTrophy, link: "/fitness-plans"},
		{label: datum?.name, icon: faTrophy},
	];

	if (forceURL) return <Navigate to={forceURL} />;

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			{/* Header */}
			<div className="bg-gradient-to-br from-primary-dark via-primary to-primary-light px-6 py-8 flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
				<div>
					<div className="text-white">
						<h3 className="text-sm font-medium uppercase">Fitness Plan</h3>
						<h2 className="text-3xl font-bold mt-2">{datum?.name}</h2>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
					{datum?.status === FITNESS_GOAL_STATUS_ACTIVE && (
						<Link
							to={`/fitness-plan/${datum.id}/update`}
							className="w-full sm:w-auto inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
							<FontAwesomeIcon className="mr-2" icon={faPencil} />
							Edit & Re-request
						</Link>
					)}
					<button
						onClick={() => setSelectedFitnessPlanForDeletion(datum)}
						className="w-full sm:w-auto inline-flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
						<FontAwesomeIcon className="mr-2" icon={faTrash} />
						Delete
					</button>
				</div>
			</div>

			{/* Content */}
			<div className="mx-auto py-6 -mt-6 relative z-10">
				<div className="bg-white rounded-t-xl shadow-lg">
					{isFetching ? (
						<div className="p-6">
							<PageLoadingContent displayMessage="Loading workout plan..." />
						</div>
					) : (
						<>
							<FormErrorBox errors={errors} />

							{datum && (
								<div className="space-y-6">
									{/* Status Cards */}
									{datum.status === FITNESS_GOAL_STATUS_PENDING && (
										<StatusCard
											icon={faClock}
											title="Fitness Plan Pending"
											message="You have successfully submitted this fitness plan to our team. The estimated time until our team completes your fitness plan will take about 1-2 days. Please check back later."
											bgColor="from-yellow-50 to-orange-50"
										/>
									)}

									{datum.status === FITNESS_GOAL_STATUS_QUEUED && (
										<StatusCard
											icon={faClock}
											title="Fitness Plan Not Ready"
											message="Your fitness plan has been queued for processing. Our team will start working on it soon."
											bgColor="from-blue-50 to-indigo-50"
										/>
									)}

									{datum.status === FITNESS_GOAL_STATUS_IN_PROGRESS && (
										<StatusCard
											icon={faSpinner}
											title="Fitness Plan In Progress"
											message="Your fitness plan is currently being prepared. Please check back soon for updates."
											bgColor="from-blue-50 to-cyan-50"
										/>
									)}

									{datum.status === FITNESS_GOAL_STATUS_ERROR && (
										<StatusCard
											icon={faExclamationTriangle}
											title="Fitness Plan Problem"
											message="There appears to be a problem with your fitness plan submission. We are investigating and working through the issue. Please check back in another day."
											bgColor="from-red-50 to-pink-50"
										/>
									)}

									{datum.status === FITNESS_GOAL_STATUS_ARCHIVED && (
										<StatusCard
											icon={faArchive}
											title="Fitness Plan Archived"
											message="This fitness plan has been archived. It is no longer active but can still be viewed."
											bgColor="from-gray-50 to-gray-100"
										/>
									)}

									{/* Active Content */}
									{datum.status === FITNESS_GOAL_STATUS_ACTIVE && (
										<>
											{/* Instructions */}
											{datum?.instructions && (
												<div className="p-4 border-b">
													<h3 className="font-semibold text-gray-900 mb-2">
														Overview
													</h3>
													<p className="text-gray-600">{datum.instructions}</p>
												</div>
											)}

											{/* Weekly Plans */}
											<div className="p-4">
												{datum?.weeklyFitnessPlans?.map((weeklyPlan, index) => (
													<WeeklyPlanSection key={index} plan={weeklyPlan} />
												))}
											</div>
										</>
									)}
								</div>
							)}
						</>
					)}
				</div>
			</div>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={selectedFitnessPlanForDeletion !== null}
				onClose={() => setSelectedFitnessPlanForDeletion(null)}
				header="Are you sure?">
				<div>
					<p className="text-gray-700 mb-6">
						You are about to delete this fitness plan and all associated data.
						This action cannot be undone.
					</p>
					<div className="flex justify-end space-x-4">
						<button
							onClick={() => setSelectedFitnessPlanForDeletion(null)}
							disabled={isFetching}
							className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                       transition-colors duration-200">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							disabled={isFetching}
							className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 
                       transition-colors duration-200 flex items-center">
							{isFetching ? (
								<>
									<FontAwesomeIcon
										icon={faSpinner}
										className="animate-spin mr-2"
									/>
									Deleting...
								</>
							) : (
								"Delete"
							)}
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default MemberFitnessPlanDetail;
