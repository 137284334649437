import "bulma/css/bulma.min.css";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {RecoilRoot} from "recoil";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

import AdminNutritionPlanUpdate from "./Components/Admin/Member/NutritionPlan/Update";
import AdminNutritionPlanSubmissionForm from "./Components/Admin/Member/NutritionPlan/DetailSubmissionForm";
import AdminNutritionPlanDetail from "./Components/Admin/Member/NutritionPlan/Detail";
import AdminNutritionPlanList from "./Components/Admin/Member/NutritionPlan/List";
import AdminNutritionPlanAdd from "./Components/Admin/Member/NutritionPlan/Add";
import AdminFitnessPlanUpdate from "./Components/Admin/Member/FitnessPlan/Update";
import AdminFitnessPlanSubmissionForm from "./Components/Admin/Member/FitnessPlan/DetailSubmissionForm";
import AdminFitnessPlanDetail from "./Components/Admin/Member/FitnessPlan/Detail";
import AdminFitnessPlanList from "./Components/Admin/Member/FitnessPlan/List";
import AdminFitnessPlanAdd from "./Components/Admin/Member/FitnessPlan/Add";
import AdminOfferUpdate from "./Components/Admin/Offer/Update";
import AdminOfferDetail from "./Components/Admin/Offer/Detail";
import AdminOfferList from "./Components/Admin/Offer/List";
import AdminOfferAdd from "./Components/Admin/Offer/Add";
import AdminVideoContentUpdate from "./Components/Admin/VideoCollection/VideoContent/Update";
import AdminVideoContentDetail from "./Components/Admin/VideoCollection/VideoContent/Detail";
import AdminVideoContentAdd from "./Components/Admin/VideoCollection/VideoContent/Add";
import AdminVideoContentList from "./Components/Admin/VideoCollection/VideoContent/List";
import AdminVideoCollectionUpdate from "./Components/Admin/VideoCollection/Update";
import AdminVideoCollectionDetail from "./Components/Admin/VideoCollection/Detail";
import AdminVideoCollectionAdd from "./Components/Admin/VideoCollection/Add";
import AdminVideoCollectionList from "./Components/Admin/VideoCollection/List";
import AdminVideoCategoryUpdate from "./Components/Admin/VideoCategory/Update";
import AdminVideoCategoryDetail from "./Components/Admin/VideoCategory/Detail";
import AdminVideoCategoryList from "./Components/Admin/VideoCategory/List";
import AdminVideoCategoryAdd from "./Components/Admin/VideoCategory/Add";
import AdminExerciseUpdate from "./Components/Admin/Exercise/Update";
import AdminExerciseAdd from "./Components/Admin/Exercise/Add";
import AdminExerciseDetail from "./Components/Admin/Exercise/Detail";
import AdminExerciseList from "./Components/Admin/Exercise/List";
import AdminMemberTagList from "./Components/Admin/Member/DetailForTags";
import AdminMemberUpdate from "./Components/Admin/Member/Update";
import AdminMemberDetail from "./Components/Admin/Member/Detail";
import AdminMemberList from "./Components/Admin/Member/List";
import AdminMemberAdd from "./Components/Admin/Member/Add";
import AdminDashboard from "./Components/Admin/Dashboard";
import MemberLeaderboardGlobalTabularList from "./Components/Member/Biometric/Leaderboard/Global/TabularList";
import MemberSummary from "./Components/Member/Biometric/Summary/View";
import MemberDataPointHistoricalGraphicalList from "./Components/Member/Biometric/Historical/GraphicalList";
import MemberDataPointHistoricalTabularList from "./Components/Member/Biometric/Historical/TabularList";
import MemberBiometricLaunchpad from "./Components/Member/Biometric/Launchpad";
import MemberNutritionPlanUpdate from "./Components/Member/NutritionPlan/Update";
import MemberNutritionPlanSubmissionForm from "./Components/Member/NutritionPlan/DetailSubmissionForm";
import MemberNutritionPlanDetail from "./Components/Member/NutritionPlan/Detail";
import MemberNutritionPlanList from "./Components/Member/NutritionPlan/List";
import MemberNutritionPlanAdd from "./Components/Member/NutritionPlan/Add";
import MemberFitnessPlanUpdate from "./Components/Member/FitnessPlan/Update";
import MemberFitnessPlanSubmissionForm from "./Components/Member/FitnessPlan/DetailSubmissionForm";
import MemberFitnessPlanDetail from "./Components/Member/FitnessPlan/Detail";
import MemberFitnessPlanList from "./Components/Member/FitnessPlan/List";
import MemberFitnessPlanAdd from "./Components/Member/FitnessPlan/Add";
// import MemberVideoCollectionContentList from "./Components/Member/Videos/VideoCategories/Collections/VideoContent/List";
// import MemberVideoCollectionDetail from "./Components/Member/Videos/VideoCategories/Collections/Detail";
// import MemberVideoCollectionList from "./Components/Member/Videos/VideoCategories/Collections/List";
// import MemberCategoriesList from "./Components/Member/Videos/VideoCategories/List";
import MemberExerciseDetail from "./Components/Member/Exercise/Detail";
import MemberExerciseList from "./Components/Member/Exercise/List";
import MemberDashboard from "./Components/Member/Dashboard";

import TwoFactorAuthenticationWizardStep1 from "./Components/Gateway/2FA/Step1";
import TwoFactorAuthenticationWizardStep2 from "./Components/Gateway/2FA/Step2";
import TwoFactorAuthenticationWizardStep3 from "./Components/Gateway/2FA/Step3";
import TwoFactorAuthenticationValidateOnLogin from "./Components/Gateway/2FA/ValidateOnLogin";
import LogoutRedirector from "./Components/Gateway/LogoutRedirector";
import Login from "./Components/Gateway/Login";
import Register from "./Components/Gateway/Register";
import RegisterSuccessful from "./Components/Gateway/RegisterSuccessful";
import IndexStaticPage from "./Components/Gateway/Index/Index";
import PrivacyStaticPage from "./Components/Misc/Privacy";
import TermsOfServiceStaticPage from "./Components/Misc/TOS";
import AnonymousCurrentUserRedirector from "./Components/Misc/AnonymousCurrentUserRedirector";
import TwoFactorAuthenticationRedirector from "./Components/Misc/TwoFactorAuthenticationRedirector";
import GoogleFitRequiresLoginAgainTopBanner from "./Components/Misc/GoogleFitRequiresLoginAgainTopBanner";
import TopAlertBanner from "./Components/Misc/TopAlertBanner";
import Sidebar from "./Components/Menu/Sidebar";
import Topbar from "./Components/Menu/Top";
import NotFoundError from "./Components/Misc/NotFoundError";
import AccountTwoFactorAuthenticationDetail from "./Components/Account/2FA/View";
import AccountEnableTwoFactorAuthentication from "./Components/Account/2FA/EnableView";
import EmailVerification from "./Components/Gateway/EmailVerification";
import AccountFriendList from "./Components/Account/Friend/Friend";
import AccountMoreOperationAvatar from "./Components/Account/More/Operation/Avatar/Avatar";
import AccountMoreOperationSubscribe from "./Components/Account/More/Operation/Subscribe/Subscribe";
import AccountMoreLaunchpad from "./Components/Account/More/Launchpad";
import AccountWearableTechLaunchpad from "./Components/Account/WearableTech/Launchpad";
import AccountSubscriptionDetailAndCancel from "./Components/Account/Subscription/Subscription";
import AccountInvoiceList from "./Components/Account/Subscription/Invoice/List";
import AccountTagList from "./Components/Account/DetailForTags";
import AccountUpdate from "./Components/Account/Update";
import AccountChangePassword from "./Components/Account/ChangePassword";
import ForgotPassword from "./Components/Gateway/ForgotPassword";
import PasswordReset from "./Components/Gateway/PasswordReset";
// import MemberVideoCollectionContentList from "./Components/Member/Videos/VideoCategories/Collections/VideoContent/List";
import MemberLeaderboardPersonal from "./Components/Member/Biometric/Leaderboard/Personal/Personal";
// import MemberHistoricalDashboard from "./Components/Member/Biometric/Historical/Dashboard";
import PaymentProcessoePurchaseCanceled from "./Components/Member/PaymentProcessor/PurchaseCanceled";
import PaymentProcessorPurchaseSuccess from "./Components/Member/PaymentProcessor/PurchaseSuccess";
import UserProfile from "./Components/Account/User/UserProfile";
import AdminTrainingProgramList from "./Components/Admin/TrainingProgram/list";
import AdminTrainingProgramAdd from "./Components/Admin/TrainingProgram/add";
import AdminWorkoutList from "./Components/Admin/Workouts/list";
import AdminWorkoutAdd from "./Components/Admin/Workouts/add";
import AdminWorkoutDetail from "./Components/Admin/Workouts/Detail";
import AdminWorkoutUpdate from "./Components/Admin/Workouts/update";
import MemberWorkoutList from "./Components/Member/Workouts/list";
import MemberWorkoutAdd from "./Components/Member/Workouts/add";
import MemberWorkoutDetail from "./Components/Member/Workouts/Detail";
import MemberWorkoutEdit from "./Components/Member/Workouts/update";
import MemberTrainingProgramList from "./Components/Member/TrainingProgram/list";
import MemberTrainingProgramAdd from "./Components/Member/TrainingProgram/add";
import AdminQuestionnaireList from "./Components/Admin/questionnaire/List";
import AdminQuestionnaireAdd from "./Components/Admin/questionnaire/Add";
import AdminQuestionnaireDetail from "./Components/Admin/questionnaire/Detail";
import AdminQuestionnaireUpdate from "./Components/Admin/questionnaire/Update";
import Onboarding from "./Components/Reusable/Wizard/Wizard";
import AdminTPDetail from "./Components/Admin/TrainingProgram/Detail";
import AdminTPDetailUpdate from "./Components/Admin/TrainingProgram/Update";
import MemberTPUpdate from "./Components/Member/TrainingProgram/Update";
import AdminFitnessChallengeAdd from "./Components/Admin/FitnessChallenge/add";
import FitnessChallengeList from "./Components/Admin/FitnessChallenge/list";
import AdminFitnessChallengeDetail from "./Components/Admin/FitnessChallenge/Detail";
import LeaderboardGlobalTabularList from "./Components/Admin/FitnessChallenge/leaderboard";
import AdminFitnessChallengeUpdate from "./Components/Admin/FitnessChallenge/update";
import FitenessChallengeMemberList from "./Components/Member/FitnessChallenge/list";
import MemberFitnessChallengeDetail from "./Components/Member/FitnessChallenge/Detail";
import MemberLeaderboardGlobalTabularListForChallenge from "./Components/Member/FitnessChallenge/leaderboard";
import AdminOrganizationDetail from "./Components/Admin/organization/Detail";
import AdminOrganizationUpdate from "./Components/Admin/organization/update";
import AdminMemberProfileDetail from "./Components/Admin/Member/DetailForProfile";
import Footer from "./Components/Menu/Footer";
import UserPointsHistory from "./Components/Member/FitnessChallenge/points";
import AdminBadgeAdd from "./Components/Admin/Badges/Add";
// import AdminTipsAdd from "./Components/Admin/Tips/add";
import AdminBadgeList from "./Components/Admin/Badges/List";
import AdminBadgeDetail from "./Components/Admin/Badges/Detail";
import AdminBadgeUpdate from "./Components/Admin/Badges/Update";
import AdminLevelAdd from "./Components/Admin/Levels/Add";
import AdminLevelList from "./Components/Admin/Levels/List";
import AdminLevelDetail from "./Components/Admin/Levels/Detail";
import AdminLevelUpdate from "./Components/Admin/Levels/Update";
import AdminTipAdd from "./Components/Admin/Tips/Add";
import AdminTipsList from "./Components/Admin/Tips/List";
import AdminTipDetail from "./Components/Admin/Tips/Detail";
import AdminTipUpdate from "./Components/Admin/Tips/Update";
import MemberPreviousLessonsList from "./Components/Member/PreviousLessonsOfTheWeek/List";
import MemberPreviousLessonsDetail from "./Components/Member/PreviousLessonsOfTheWeek/Detail";
import MemberPreviousTechniquesList from "./Components/Member/PreviousTechniquesOfTheWeek/List";
import MemberPreviousTechniquesDetail from "./Components/Member/PreviousTechniquesOfTheWeek/Detail";
import AdminFitnessChallengeAssignBadges from "./Components/Admin/FitnessChallenge/AssignBadges";
import AdminWrapper from "./Helpers/adminWrapper";
import VideoPlayer from "./Components/Member/Video/VideoPlayer";
import CollectionDetail from "./Components/Member/Video/CollectionDetail";
import CategoryDetail from "./Components/Member/Video/CategoryDetail";
import VideoHomepage from "./Components/Member/Video/Categories";
import VideoPlayerComponent from "./Components/Member/Video/VideoPlayer";
import Splash from "./Components/Misc/Splash";

// Create a query client instance
const queryClient = new QueryClient();

function AppRoute() {
	return (
		<div class="w-full">
			<RecoilRoot>
				<QueryClientProvider client={queryClient}>
					<Router>
						<AnonymousCurrentUserRedirector />
						<TwoFactorAuthenticationRedirector />
						<TopAlertBanner />
						<GoogleFitRequiresLoginAgainTopBanner />
						<Routes>
							{/*
                    -----------------------------------------------
                    EVERYTHING BELOW BELONGS TO THE ADMINISTRATION.
                    -----------------------------------------------
                  */}

							<Route path="/admin/*">
								{/* Offers */}
								<Route path="offer/:id/update" element={<AdminOfferUpdate />} />
								<Route path="offer/:id" element={<AdminOfferDetail />} />
								<Route path="offers/add" element={<AdminOfferAdd />} />
								<Route path="offers" element={<AdminOfferList />} />

								{/* Video Collections */}
								<Route
									path="video-collection/:vcid/video-content/:vconid/update"
									element={<AdminVideoContentUpdate />}
								/>
								<Route
									path="video-collection/:vcid/video-content/:vconid"
									element={<AdminVideoContentDetail />}
								/>
								<Route
									path="video-collection/:vcid/video-contents/add"
									element={<AdminVideoContentAdd />}
								/>
								<Route
									path="video-collection/:vcid/video-contents"
									element={<AdminVideoContentList />}
								/>
								<Route
									path="video-collection/:vcid/update"
									element={<AdminVideoCollectionUpdate />}
								/>
								<Route
									path="video-collection/:vcid"
									element={<AdminVideoCollectionDetail />}
								/>
								<Route
									path="video-collections/add"
									element={<AdminVideoCollectionAdd />}
								/>
								<Route
									path="video-collections"
									element={<AdminVideoCollectionList />}
								/>

								{/* Video Categories */}
								<Route
									path="video-category/:id/update"
									element={<AdminVideoCategoryUpdate />}
								/>
								<Route
									path="video-category/:id"
									element={<AdminVideoCategoryDetail />}
								/>
								<Route
									path="video-categories/add"
									element={<AdminVideoCategoryAdd />}
								/>
								<Route
									path="video-categories"
									element={<AdminVideoCategoryList />}
								/>

								{/* Exercises */}
								<Route
									path="exercise/:id/update"
									element={<AdminExerciseUpdate />}
								/>
								<Route path="exercise/:id" element={<AdminExerciseDetail />} />
								<Route path="exercises/add" element={<AdminExerciseAdd />} />
								<Route path="exercises" element={<AdminExerciseList />} />

								{/* Member Management */}
								<Route
									path="member/nutrition-plan/:id/update"
									element={<AdminNutritionPlanUpdate />}
								/>
								<Route
									path="member/nutrition-plan/:id/submission-form"
									element={<AdminNutritionPlanSubmissionForm />}
								/>
								<Route
									path="member/nutrition-plan/:id"
									element={<AdminNutritionPlanDetail />}
								/>
								<Route
									path="member/nutrition-plans/add"
									element={<AdminNutritionPlanAdd />}
								/>
								<Route
									path="member/nutrition-plans"
									element={<AdminNutritionPlanList />}
								/>
								<Route
									path="member/:uid/nutrition-plans"
									element={<AdminNutritionPlanList />}
								/>
								<Route
									path="member/:uid/fitness-plan/:id/update"
									element={<AdminFitnessPlanUpdate />}
								/>
								<Route
									path="member/:uid/fitness-plan/:id/submission-form"
									element={<AdminFitnessPlanSubmissionForm />}
								/>
								<Route
									path="member/:uid/fitness-plan/:id"
									element={<AdminFitnessPlanDetail />}
								/>
								<Route
									path="member/:uid/fitness-plans/add"
									element={<AdminFitnessPlanAdd />}
								/>
								<Route
									path="member/:uid/fitness-plans"
									element={<AdminFitnessPlanList />}
								/>
								<Route
									path="member/:id/update"
									element={<AdminMemberUpdate />}
								/>
								<Route
									path="member/:id/tags"
									element={<AdminMemberTagList />}
								/>
								<Route
									path="member/:id/profile"
									element={<AdminMemberProfileDetail />}
								/>
								<Route path="member/:id" element={<AdminMemberDetail />} />
								<Route path="members" element={<AdminMemberList />} />
								<Route path="members/add" element={<AdminMemberAdd />} />

								{/* Training Programs */}
								<Route
									path="training-program"
									element={<AdminTrainingProgramList />}
								/>
								<Route
									path="training-program/add"
									element={<AdminTrainingProgramAdd />}
								/>
								<Route
									path="training-program/:id"
									element={<AdminTPDetail />}
								/>
								<Route
									path="training-program/:id/edit"
									element={<AdminTPDetailUpdate />}
								/>

								{/* Workouts */}
								<Route path="workouts" element={<AdminWorkoutList />} />
								<Route path="workouts/add" element={<AdminWorkoutAdd />} />
								<Route path="workouts/:id" element={<AdminWorkoutDetail />} />
								<Route
									path="workouts/:id/update"
									element={<AdminWorkoutUpdate />}
								/>

								{/* Questionnaires */}
								<Route path="questions" element={<AdminQuestionnaireList />} />
								<Route
									path="questions/add"
									element={<AdminQuestionnaireAdd />}
								/>
								<Route
									path="questions/:id"
									element={<AdminQuestionnaireDetail />}
								/>
								<Route
									path="questions/:id/update"
									element={<AdminQuestionnaireUpdate />}
								/>

								{/* Fitness Challenges */}
								<Route
									path="fitness-challenges"
									element={<FitnessChallengeList />}
								/>
								<Route
									path="fitness-challenge/add"
									element={<AdminFitnessChallengeAdd />}
								/>
								<Route
									path="fitness-challenge/:id"
									element={<AdminFitnessChallengeDetail />}
								/>
								<Route
									path="fitness-challenge/:id/leaderboard"
									element={<LeaderboardGlobalTabularList />}
								/>
								<Route
									path="fitness-challenge/:id/assign-badge"
									element={<AdminFitnessChallengeAssignBadges />}
								/>
								<Route
									path="fitness-challenge/:id/update"
									element={<AdminFitnessChallengeUpdate />}
								/>

								{/* Organization */}
								<Route
									path="organization"
									element={<AdminOrganizationDetail />}
								/>
								<Route
									path="organization/update"
									element={<AdminOrganizationUpdate />}
								/>

								{/* Badges */}
								<Route path="badges/add" element={<AdminBadgeAdd />} />
								<Route path="badges" element={<AdminBadgeList />} />
								<Route path="badges/:id" element={<AdminBadgeDetail />} />
								<Route path="badges/:id/edit" element={<AdminBadgeUpdate />} />

								{/* Levels */}
								<Route path="levels/add" element={<AdminLevelAdd />} />
								<Route path="levels" element={<AdminLevelList />} />
								<Route path="levels/:id" element={<AdminLevelDetail />} />
								<Route path="levels/:id/edit" element={<AdminLevelUpdate />} />

								{/* Tips */}
								<Route path="tips/add" element={<AdminTipAdd />} />
								<Route path="tips" element={<AdminTipsList />} />
								<Route path="tips/:id" element={<AdminTipDetail />} />
								<Route path="tips/:id/update" element={<AdminTipUpdate />} />

								{/* Dashboard */}
								<Route path="dashboard" element={<AdminDashboard />} />
							</Route>

							{/*
                    ---------------------------------------
                    EVERYTHING BELOW BELONGS TO THE MEMBER.
                    ---------------------------------------
                  */}
							<Route
								exact
								path="/biometrics/leaderboard/global"
								element={<MemberLeaderboardGlobalTabularList />}
							/>
							<Route
								exact
								path="/biometrics/leaderboard/personal"
								element={<MemberLeaderboardPersonal />}
							/>
							<Route
								exact
								path="/biometrics/summary"
								element={<MemberSummary />}
							/>
							<Route
								exact
								path="/biometrics/history/graphview"
								element={<MemberDataPointHistoricalGraphicalList />}
							/>
							<Route
								exact
								path="/biometrics/history/tableview"
								element={<MemberDataPointHistoricalTabularList />}
							/>
							<Route
								exact
								path="/biometrics"
								element={<MemberBiometricLaunchpad />}
							/>

							<Route
								exact
								path="/nutrition-plan/:id/update"
								element={<MemberNutritionPlanUpdate />}
							/>
							<Route
								exact
								path="/nutrition-plan/:id/submission-form"
								element={<MemberNutritionPlanSubmissionForm />}
							/>
							<Route
								exact
								path="/nutrition-plan/:id"
								element={<MemberNutritionPlanDetail />}
							/>
							<Route
								exact
								path="/nutrition-plans/add"
								element={<MemberNutritionPlanAdd />}
							/>
							<Route
								exact
								path="/nutrition-plans"
								element={<MemberNutritionPlanList />}
							/>

							<Route
								exact
								path="/fitness-plan/:id/update"
								element={<MemberFitnessPlanUpdate />}
							/>
							<Route
								exact
								path="/fitness-plan/:id/submission-form"
								element={<MemberFitnessPlanSubmissionForm />}
							/>
							<Route
								exact
								path="/fitness-plan/:id"
								element={<MemberFitnessPlanDetail />}
							/>
							<Route
								exact
								path="/fitness-plans/add"
								element={<MemberFitnessPlanAdd />}
							/>
							<Route
								exact
								path="/fitness-plans"
								element={<MemberFitnessPlanList />}
							/>
							{/* <Route exact path="/video-collection/:vcid/video-content/:vconid" element={<MemberVideoContentDetail />} /> */}
							{/* <Route
								exact
								path="/video-category/:vcatid/video-collection/:vcollid/video-content/:vconid"
								element={<MemberVideoCollectionContentList />}
							/>
							<Route
								exact
								path="/video-collection/:vcid/video-contents"
								element={<MemberVideoContentList />}
							/>
							<Route
								exact
								path="/video-collection/:vcid"
								element={<MemberVideoCollectionDetail />}
							/>
							<Route
								exact
								path="/video-categories"
								element={<MemberCategoriesList />}
							/>
							<Route
								exact
								path="/video-category/:vcatid/video-collections"
								element={<MemberVideoCollectionList />}
							/> */}

							<Route path="/videos">
								{/* Main video categories page (like YouTube homepage) */}
								<Route index element={<VideoHomepage />} />

								{/* Category page showing all collections*/}
								<Route
									path="category/:categoryId"
									element={<CategoryDetail />}
								/>

								{/* Collection page showing all videos*/}
								<Route
									path="collection/:collectionId"
									element={<CollectionDetail />}
								/>

								{/* Individual video page with player*/}
								<Route
									path="watch/:videoId"
									element={<VideoPlayerComponent />}
								/>
							</Route>

							<Route
								exact
								path="/exercise/:id"
								element={<MemberExerciseDetail />}
							/>
							<Route exact path="/exercises" element={<MemberExerciseList />} />

							<Route exact path="/dashboard" element={<MemberDashboard />} />
							<Route exact path="/workouts" element={<MemberWorkoutList />} />
							<Route
								exact
								path="/workouts/add"
								element={<MemberWorkoutAdd />}
							/>
							<Route
								exact
								path="/workouts/:id"
								element={<MemberWorkoutDetail />}
							/>
							<Route
								exact
								path="/workouts/:id/update"
								element={<MemberWorkoutEdit />}
							/>

							<Route
								exact
								path="/training-program"
								element={<MemberTrainingProgramList />}
							/>
							<Route
								exact
								path="/training-program/add"
								element={<MemberTrainingProgramAdd />}
							/>
							<Route
								exact
								path="/training-program/:id"
								element={<AdminTPDetail />}
							/>
							<Route exact path="/onboarding" element={<Onboarding />} />
							<Route
								exact
								path="/training-program/:id/edit"
								element={<MemberTPUpdate />}
							/>
							<Route
								exact
								path="/fitness-challenge"
								element={<FitenessChallengeMemberList />}
							/>
							<Route
								exact
								path="/fitness-challenge/:id"
								element={<MemberFitnessChallengeDetail />}
							/>
							<Route
								exact
								path="/fitness-challenge/:id/leaderboard"
								element={<MemberLeaderboardGlobalTabularListForChallenge />}
							/>
							<Route
								exact
								path="/fitness-challenge/:id/points"
								element={<UserPointsHistory />}
							/>

							{/* Previous Week Lessons */}
							<Route
								exact
								path="/previous-week-lessons"
								element={<MemberPreviousLessonsList />}
							/>
							<Route
								exact
								path="/previous-week-lesson/:id"
								element={<MemberPreviousLessonsDetail />}
							/>

							{/* Previous Week Techniques */}
							<Route
								exact
								path="/previous-week-techniques"
								element={<MemberPreviousTechniquesList />}
							/>
							<Route
								exact
								path="/previous-week-technique/:id"
								element={<MemberPreviousTechniquesDetail />}
							/>

							{/*
                                        -----------------------------------------------
                                        EVERYTHING BELOW BLONGS TO USER PROFILE.
                                        -----------------------------------------------
                                    */}

							<Route
								exact
								path="/purchase/success"
								element={<PaymentProcessorPurchaseSuccess />}
							/>
							<Route
								exact
								path="/purchase/canceled"
								element={<PaymentProcessoePurchaseCanceled />}
							/>
							<Route
								exact
								path="/account/more/subscribe"
								element={<AccountMoreOperationSubscribe />}
							/>
							<Route
								exact
								path="/account/more/avatar"
								element={<AccountMoreOperationAvatar />}
							/>
							<Route
								exact
								path="/account/more"
								element={<AccountMoreLaunchpad />}
							/>
							<Route
								exact
								path="/account/wearable-tech"
								element={<AccountWearableTechLaunchpad />}
							/>
							<Route
								exact
								path="/account/friends"
								element={<AccountFriendList />}
							/>
							<Route exact path="/account/tags" element={<AccountTagList />} />
							<Route exact path="/account" element={<UserProfile />} />
							<Route exact path="/account/update" element={<AccountUpdate />} />
							<Route
								exact
								path="/account/change-password"
								element={<AccountChangePassword />}
							/>
							<Route
								exact
								path="/account/subscription"
								element={<AccountSubscriptionDetailAndCancel />}
							/>
							<Route
								exact
								path="/account/subscription/invoices"
								element={<AccountInvoiceList />}
							/>
							<Route
								exact
								path="/account/2fa"
								element={<AccountTwoFactorAuthenticationDetail />}
							/>
							<Route
								exact
								path="/account/2fa/enable"
								element={<AccountEnableTwoFactorAuthentication />}
							/>

							{/*
                                        -----------------------------------------------
                                        EVERYTHING BELOW BELONGS TO THE GATEWAY OR INDEX.
                                        -----------------------------------------------
                                     */}
							<Route exact path="/register" element={<Register />} />
							<Route
								exact
								path="/register-successful"
								element={<RegisterSuccessful />}
							/>
							<Route exact path="/login" element={<Login />} />
							<Route
								exact
								path="/login/2fa/step-1"
								element={<TwoFactorAuthenticationWizardStep1 />}
							/>
							<Route
								exact
								path="/login/2fa/step-2"
								element={<TwoFactorAuthenticationWizardStep2 />}
							/>
							<Route
								exact
								path="/login/2fa/step-3"
								element={<TwoFactorAuthenticationWizardStep3 />}
							/>
							<Route
								exact
								path="/login/2fa"
								element={<TwoFactorAuthenticationValidateOnLogin />}
							/>
							<Route exact path="/logout" element={<LogoutRedirector />} />
							<Route exact path="/verify" element={<EmailVerification />} />
							<Route
								exact
								path="/forgot-password"
								element={<ForgotPassword />}
							/>
							<Route exact path="/password-reset" element={<PasswordReset />} />
							<Route
								exact
								path="/terms-of-service"
								element={<TermsOfServiceStaticPage />}
							/>
							<Route
								exact
								path="/privacy-policy"
								element={<PrivacyStaticPage />}
							/>
							<Route path="/splash-page" element={<Splash />} />
							<Route exact path="/" element={<IndexStaticPage />} />
							<Route path="*" element={<NotFoundError />} />
						</Routes>
					</Router>
				</QueryClientProvider>
			</RecoilRoot>
		</div>
	);
}

export default AppRoute;
