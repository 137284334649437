import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTasks,
	faTachometer,
	faPlus,
	faTimesCircle,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faDumbbell,
	faIdCard,
	faAddressBook,
	faMessage,
	faChartPie,
	faCogs,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {
	getVideoCategoryDetailAPI,
	postVideoCategoryCreateAPI,
} from "../../../API/VideoCategory";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminVideoCategoryAdd() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");
	const [no, setNo] = useState("");
	const [showCancelWarning, setShowCancelWarning] = useState(false);

	////
	//// Event handling.
	////

	////
	//// API.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Beginning...");
		setFetching(true);
		setErrors({});

		// To Snake-case for API from camel-case in React.
		const decamelizedData = {
			no: parseInt(no),
			name: name,
		};
		console.log("onSubmitClick, decamelizedData:", decamelizedData);
		postVideoCategoryCreateAPI(
			decamelizedData,
			onAdminVideoCategoryAddSuccess,
			onAdminVideoCategoryAddError,
			onAdminVideoCategoryAddDone
		);
	};

	function onAdminVideoCategoryAddSuccess(response) {
		// For debugging purposes only.
		console.log("onAdminVideoCategoryAddSuccess: Starting...");
		console.log(response);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Video category created");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onAdminVideoCategoryAddSuccess: Delayed for 2 seconds.");
			console.log(
				"onAdminVideoCategoryAddSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the user to a new page.
		setForceURL("/admin/video-categories");
	}

	function onAdminVideoCategoryAddError(apiErr) {
		console.log("onAdminVideoCategoryAddError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onAdminVideoCategoryAddError: Delayed for 2 seconds.");
			console.log(
				"onAdminVideoCategoryAddError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAdminVideoCategoryAddDone() {
		console.log("onAdminVideoCategoryAddDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
		}

		return () => {
			mounted = false;
		};
	}, []);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{
			label: "Video Categories",
			link: "/admin/video-categories",
			icon: faDumbbell,
		},
		{label: "New", icon: faPlus},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-8">
				<div className="mx-auto">
					{/* Main Content */}
					<div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
						{/* Cancel Warning Modal */}
						<Modal
							isOpen={showCancelWarning}
							onClose={() => setShowCancelWarning(false)}
							header="Are you sure?">
							<div className="space-y-4">
								<p className="text-gray-700">
									Your record will be cancelled and your work will be lost. This
									cannot be undone. Do you want to continue?
								</p>
								<div className="flex justify-end space-x-3">
									<button
										onClick={() => setShowCancelWarning(false)}
										className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors">
										No
									</button>
									<Link
										to="/admin/video-categories"
										className="px-4 py-2 text-white bg-primary hover:bg-primary-dark rounded-lg transition-colors">
										Yes
									</Link>
								</div>
							</div>
						</Modal>

						<h1 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
							<FontAwesomeIcon className="mr-3" icon={faPlus} />
							New Video Category
						</h1>

						<FormErrorBox errors={errors} />

						{isFetching && (
							<PageLoadingContent displayMessage={"Please wait..."} />
						)}

						<div className="space-y-6">
							<div>
								<h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
									<FontAwesomeIcon className="mr-2" icon={faEye} />
									Detail
								</h2>
								<hr className="mb-6" />

								<div className="space-y-4 max-w-lg">
									<div>
										<label className="block text-sm font-medium text-gray-700 mb-2">
											Name <span className="text-red-600">*</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
											placeholder="Enter name"
										/>
										{errors?.name && (
											<p className="mt-1 text-sm text-red-600">{errors.name}</p>
										)}
									</div>

									<div>
										<label className="block text-sm font-medium text-gray-700 mb-2">
											No # <span className="text-red-600">*</span>
										</label>
										<input
											type="number"
											value={no}
											onChange={(e) => setNo(parseInt(e.target.value))}
											className="w-24 px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary focus:border-primary"
											placeholder="#"
										/>
										{errors?.no && (
											<p className="mt-1 text-sm text-red-600">{errors.no}</p>
										)}
									</div>
								</div>
							</div>

							<div className="flex flex-col sm:flex-row justify-between pt-6 space-y-3 sm:space-y-0">
								<button
									onClick={() => setShowCancelWarning(true)}
									className="w-full sm:w-auto px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center">
									<FontAwesomeIcon className="mr-2" icon={faTimesCircle} />
									Cancel
								</button>
								<button
									onClick={onSubmitClick}
									className="w-full sm:w-auto px-4 py-2 text-white bg-primary hover:bg-primary-dark rounded-lg transition-colors flex items-center justify-center">
									<FontAwesomeIcon className="mr-2" icon={faCheckCircle} />
									Submit
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminVideoCategoryAdd;
