import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faTachometer, faPlus, faTimesCircle, faCheckCircle, faUserCircle, faGauge, faPencil, faUsers, faIdCard, faAddressBook, faMessage, faChartPie, faCogs, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState } from 'recoil';

import { getMemberDetailAPI, postMemberCreateAPI } from "../../../API/member";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import { HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminMemberAdd() {
    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeated, setPasswordRepeated] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [status, setStatus] = useState(0);
    const [agreePromotionsEmail, setHasPromotionalEmail] = useState(true);
    const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(0);
    const [howDidYouHearAboutUsOther, setHowDidYouHearAboutUsOther] = useState("");
    const [showCancelWarning, setShowCancelWarning] = useState(false);

        // Breadcrumb items
  const breadcrumbItems = [
    {
        label: "Dashboard",
        link: "/admin/dashboard",
        icon: faGauge
    },
    {
        label: "Members",
        link: "/admin/members",
        icon: faUsers
    },
    {
        label: "Add Member",
        icon: faPlus
    }
  ]

    ////
    //// Event handling.
    ////

    function onAgreePromotionsEmailChange(e) {
        setHasPromotionalEmail(!agreePromotionsEmail);
    }

    ////
    //// API.
    ////

    const onSubmitClick = (e) => {
        console.log("onSubmitClick: Beginning...");
        setFetching(true);
        setErrors({});

        // To Snake-case for API from camel-case in React.
        const decamelizedData = {
            organization_id: currentUser.organizationID,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            postal_code: postalCode,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            city: city,
            region: region,
            country: country,
            status: status,
            password: password,
            password_repeated: passwordRepeated,
            how_did_you_hear_about_us: howDidYouHearAboutUs,
            how_did_you_hear_about_us_other: howDidYouHearAboutUsOther,
            agree_promotions_email: agreePromotionsEmail,
        };
        console.log("onSubmitClick, decamelizedData:", decamelizedData);
        postMemberCreateAPI(decamelizedData, onAdminMemberAddSuccess, onAdminMemberAddError, onAdminMemberAddDone);
    }

    function onAdminMemberAddSuccess(response){
        // For debugging purposes only.
        console.log("onAdminMemberAddSuccess: Starting...");
        console.log(response);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Member created");
        setTopAlertStatus("success");
        setTimeout(() => {
            console.log("onAdminMemberAddSuccess: Delayed for 2 seconds.");
            console.log("onAdminMemberAddSuccess: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // Redirect the user to a new page.
        setForceURL("/admin/member/"+response.id);
    }

    function onAdminMemberAddError(apiErr) {
        console.log("onAdminMemberAddError: Starting...");
        setErrors(apiErr);

        // Add a temporary banner message in the app and then clear itself after 2 seconds.
        setTopAlertMessage("Failed submitting");
        setTopAlertStatus("danger");
        setTimeout(() => {
            console.log("onAdminMemberAddError: Delayed for 2 seconds.");
            console.log("onAdminMemberAddError: topAlertMessage, topAlertStatus:", topAlertMessage, topAlertStatus);
            setTopAlertMessage("");
        }, 2000);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onAdminMemberAddDone() {
        console.log("onAdminMemberAddDone: Starting...");
        setFetching(false);
    }

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);  // Start the page at the top of the page.
        }

        return () => { mounted = false; }
    }, []);
    ////
    //// Component rendering.
    ////

    if (forceURL !== "") {
        return <Navigate to={forceURL}  />
    }



    return (
<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="bg-white rounded-lg shadow-md p-6">
                {/* Modal */}
                <Modal
                    isOpen={showCancelWarning}
                    onClose={() => setShowCancelWarning(false)}
                    header="Are you sure?"
                >
                    <div className="text-center">
                        <p className="text-sm text-gray-500 mb-6">
                            Your record will be cancelled and your work will be lost. This cannot be undone. Do you want to continue?
                        </p>
                        <div className="flex justify-center gap-4">
                            <Link 
                                className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition-colors duration-200" 
                                to="/admin/members"
                            >
                                Yes
                            </Link>
                            <button 
                                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded transition-colors duration-200"
                                onClick={() => setShowCancelWarning(false)}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Header */}
                <div className="mb-6">
                    <h1 className="text-2xl font-semibold text-gray-800 flex items-center">
                        <FontAwesomeIcon className="mr-2" icon={faPlus} />
                        New Member
                    </h1>
                </div>

                <FormErrorBox errors={errors} />

                {isFetching && <PageLoadingContent displayMessage={"Please wait..."} />}

                <div className="space-y-8">
                    {/* Personal Information Section */}
                    <section>
                        <h2 className="text-lg font-medium text-gray-700 flex items-center mb-4">
                            <FontAwesomeIcon className="mr-2" icon={faIdCard} />
                            Full Name
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <FormInputField
                                label="First Name"
                                name="firstName"
                                placeholder="Enter first name"
                                value={firstName}
                                errorText={errors && errors.firstName}
                                onChange={(e)=>setFirstName(e.target.value)}
                                isRequired={true}
                                className="max-w-md"
                            />
                            <FormInputField
                                label="Last Name"
                                name="lastName"
                                placeholder="Enter last name"
                                value={lastName}
                                errorText={errors && errors.lastName}
                                onChange={(e)=>setLastName(e.target.value)}
                                isRequired={true}
                                className="max-w-md"
                            />
                        </div>
                    </section>

                    {/* Contact Information Section */}
                    <section>
                        <h2 className="text-lg font-medium text-gray-700 flex items-center mb-4">
                            <FontAwesomeIcon className="mr-2" icon={faMessage} />
                            Contact Information
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <FormInputField
                                label="Email"
                                name="email"
                                placeholder="Enter email address"
                                value={email}
                                errorText={errors && errors.email}
                                onChange={(e)=>setEmail(e.target.value)}
                                isRequired={true}
                                className="max-w-md"
                            />
                            <FormInputField
                                label="Phone"
                                name="phone"
                                placeholder="Enter phone number"
                                value={phone}
                                errorText={errors && errors.phone}
                                onChange={(e)=>setPhone(e.target.value)}
                                isRequired={true}
                                className="max-w-[150px]"
                            />
                        </div>
                    </section>

                    {/* Address Section */}
                    <section>
                        <h2 className="text-lg font-medium text-gray-700 flex items-center mb-4">
                            <FontAwesomeIcon className="mr-2" icon={faAddressBook} />
                            Address
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <FormCountryField
                                priorityOptions={["CA","US","MX"]}
                                label="Country"
                                name="country"
                                placeholder="Text input"
                                selectedCountry={country}
                                errorText={errors && errors.country}
                                helpText=""
                                onChange={(value)=>setCountry(value)}
                                isRequired={true}
                                maxWidth="160px"
                            />

                            <FormRegionField
                                label="Province/Territory"
                                name="region"
                                placeholder="Text input"
                                selectedCountry={country}
                                selectedRegion={region}
                                errorText={errors && errors.region}
                                helpText=""
                                onChange={(value)=>setRegion(value)}
                                isRequired={true}
                                maxWidth="280px"
                            />

                            <FormInputField
                                label="City"
                                name="city"
                                placeholder="Text input"
                                value={city}
                                errorText={errors && errors.city}
                                helpText=""
                                onChange={(e)=>setCity(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />

                            <FormInputField
                                label="Address Line 1"
                                name="addressLine1"
                                placeholder="Text input"
                                value={addressLine1}
                                errorText={errors && errors.addressLine1}
                                helpText=""
                                onChange={(e)=>setAddressLine1(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />

                            <FormInputField
                                label="Address Line 2"
                                name="addressLine2"
                                placeholder="Text input"
                                value={addressLine2}
                                errorText={errors && errors.addressLine2}
                                helpText=""
                                onChange={(e)=>setAddressLine2(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />

                            <FormInputField
                                label="Postal Code"
                                name="postalCode"
                                placeholder="Text input"
                                value={postalCode}
                                errorText={errors && errors.postalCode}
                                helpText=""
                                onChange={(e)=>setPostalCode(e.target.value)}
                                isRequired={true}
                                maxWidth="80px"
                            />
</div>
</section>
<section>
                        <h2 className="text-lg font-medium text-gray-700 flex items-center mb-4">
                            <FontAwesomeIcon className="mr-2" icon={faChartPie} />
                            Metrics
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <FormSelectField
                                label="How did you hear about us?"
                                name="howDidYouHearAboutUs"
                                placeholder="Pick"
                                selectedValue={howDidYouHearAboutUs}
                                errorText={errors && errors.howDidYouHearAboutUs}
                                helpText=""
                                onChange={(e)=>setHowDidYouHearAboutUs(parseInt(e.target.value))}
                                options={HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS}
                            />

                            {howDidYouHearAboutUs === 1 && <FormInputField
                                label="Other (Please specify):"
                                name="howDidYouHearAboutUsOther"
                                placeholder="Text input"
                                value={howDidYouHearAboutUsOther}
                                errorText={(e)=>setHowDidYouHearAboutUsOther(e.target.value)}
                                helpText=""
                                onChange={(e)=>setHowDidYouHearAboutUsOther(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />}

                            <FormCheckboxField
                                label="I agree to receive electronic updates from my local gym"
                                name="agreePromotionsEmail"
                                checked={agreePromotionsEmail}
                                errorText={errors && errors.agreePromotionsEmail}
                                onChange={onAgreePromotionsEmailChange}
                                maxWidth="180px"
                            />

                        </div>
                    </section>

                    <section>
                        <h2 className="text-lg font-medium text-gray-700 flex items-center mb-4">
                            <FontAwesomeIcon className="mr-2" icon={faCogs} />
                            Settings
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <FormRadioField
                                label="Status"
                                name="status"
                                placeholder="Pick"
                                value={status}
                                opt2Value={1}
                                opt2Label="Active"
                                opt4Value={100}
                                opt4Label="Archived"
                                errorText={errors && errors.status}
                                onChange={(e)=>setStatus(parseInt(e.target.value))}
                                maxWidth="180px"
                                disabled={false}
                            />

                            <FormInputField
                                label="Password"
                                name="password"
                                type="password"
                                placeholder="Text input"
                                value={password}
                                errorText={errors && errors.password}
                                helpText=""
                                onChange={(e)=>setPassword(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />

                            <FormInputField
                                label="Password Repeated"
                                name="passwordRepeated"
                                type="password"
                                placeholder="Text input"
                                value={passwordRepeated}
                                errorText={errors && errors.passwordRepeated}
                                helpText=""
                                onChange={(e)=>setPasswordRepeated(e.target.value)}
                                isRequired={true}
                                maxWidth="380px"
                            />

                        </div>
                    </section>

                    <div className="flex flex-col sm:flex-row justify-between gap-4 mt-8">
                        <button 
                            onClick={(e)=>setShowCancelWarning(true)}
                            className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center">
                            <FontAwesomeIcon className="mr-2" icon={faTimesCircle} />
                            Cancel
                        </button>
                        <button 
                            onClick={onSubmitClick}
                            className="bg-primary hover:bg-primary-hover text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center">
                            <FontAwesomeIcon className="mr-2" icon={faCheckCircle} />
                            Create Member
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AdminMemberAdd;
