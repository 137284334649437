import { 
    faArrowLeft, 
    faUser, 
    faEnvelope, 
    faPhone, 
    faHome, 
    faGlobe,
    faPencilAlt,
    faMapMarkerAlt,
    faBell,
    faShield,
    faCheckCircle,
    faExclamationCircle,
    faBars,
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect } from 'react';
import FormTextRow from "../../Reusable/FormTextRow";
import FormTextYesNoRow from '../../Reusable/FormTextYesNoRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const StatusBadge = ({ isActive }) => (
    <span className={`
      px-3 py-1.5 rounded-full text-xs font-medium inline-flex items-center
      ${isActive 
        ? 'bg-activeBg/20 text-activeBg' 
        : 'bg-lightGrayShade text-textDark'
      }
    `}>
      <FontAwesomeIcon 
        icon={isActive ? faCheckCircle : faExclamationCircle} 
        className="mr-1.5" 
      />
      {isActive ? 'Active' : 'Inactive'}
    </span>
  );

  const SectionCard = ({ icon, title, subtitle, children }) => (
    <div className="bg-lightShade rounded-xl shadow-sm border border-lightGrayShade">
      <div className="p-2">
        <div className="flex items-center mb-6">
          <div className="w-10 h-10 bg-main/10 rounded-lg flex items-center justify-center mr-4">
            <FontAwesomeIcon icon={icon} className="text-main" />
          </div>
          <div>
            <h2 className="text-lg font-primary font-semibold text-textDark">{title}</h2>
            <p className="text-sm text-textDark/60">{subtitle}</p>
          </div>
        </div>
        {children}
      </div>
    </div>
  );

  const InfoField = ({ label, value, icon, isEmpty }) => (
    <div className="bg-lightGrayShade p-4 rounded-lg break-words">
      <FormTextRow 
        label={label}
        value={value || 'Not provided'}
        icon={icon}
        isEmpty={isEmpty}
        className={`${isEmpty ? 'text-textDark/40 italic' : 'text-textDark'}`}
      />
    </div>
  );

const UserInfo = ({
    firstName,
    lastName,
    email,
    phone,
    country,
    region,
    city,
    addressLine1,
    addressLine2,
    postalCode,
    agreePromotionsEmail
}) => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 20);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen">

            {/* Main Content */}
            <main className="px-4">
                <div className="mx-auto">

                    {/* Content Grid */}
                    <div className="space-y-6">
                        {/* Main Column */}
                        <div>
                            {/* Personal Information */}
                            <SectionCard
                                icon={faUser}
                                title="Personal Information"
                                subtitle="Your personal details"
                            >
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <InfoField 
                                        label="First Name"
                                        value={firstName}
                                        icon={faUser}
                                        isEmpty={!firstName}
                                    />
                                    <InfoField 
                                        label="Last Name"
                                        value={lastName}
                                        icon={faUser}
                                        isEmpty={!lastName}
                                    />
                                    <InfoField 
                                        label="Email"
                                        value={email}
                                        icon={faEnvelope}
                                        isEmpty={!email}
                                    />
                                    <InfoField 
                                        label="Phone"
                                        value={phone}
                                        icon={faPhone}
                                        isEmpty={!phone}
                                    />
                                </div>
                            </SectionCard>

                            {/* Address Information */}
                            <SectionCard
                                icon={faMapMarkerAlt}
                                title="Address Information"
                                subtitle="Your shipping and billing address"
                            >
                                <div className="space-y-4">
                                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                        <InfoField 
                                            label="Country"
                                            value={country}
                                            icon={faGlobe}
                                            isEmpty={!country}
                                        />
                                        <InfoField 
                                            label="Region"
                                            value={region}
                                            icon={faGlobe}
                                            isEmpty={!region}
                                        />
                                        <InfoField 
                                            label="City"
                                            value={city}
                                            icon={faGlobe}
                                            isEmpty={!city}
                                        />
                                    </div>
                                    <InfoField 
                                        label="Address Line 1"
                                        value={addressLine1}
                                        icon={faHome}
                                        isEmpty={!addressLine1}
                                    />
                                    <InfoField 
                                        label="Address Line 2"
                                        value={addressLine2}
                                        icon={faHome}
                                        isEmpty={!addressLine2}
                                    />
                                    <InfoField 
                                        label="Postal Code"
                                        value={postalCode}
                                        icon={faHome}
                                        isEmpty={!postalCode}
                                    />
                                </div>
                            </SectionCard>
                        </div>

                        {/* Sidebar */}
                        <div className="space-y-6">
                            {/* Notifications */}
                            <SectionCard
                                icon={faBell}
                                title="Notifications"
                                subtitle="Manage your preferences"
                            >
                                <div className="bg-gray-50 p-4 rounded-lg">
                                    <div className="flex items-center justify-between mb-4">
                                        <span className="text-sm font-medium text-gray-900">Email Updates</span>
                                        <StatusBadge isActive={agreePromotionsEmail} />
                                    </div>
                                    <FormTextYesNoRow
                                        label="Marketing Communications"
                                        value={agreePromotionsEmail}
                                        description="Receive updates about new features"
                                    />
                                </div>
                            </SectionCard>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default UserInfo;