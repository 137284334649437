import { camelizeKeys } from "humps";
import { MMA_FITNESS_FITNESS_CHALLENGE_ASSIGN_BADGE_API_ENDPOINT } from "../Constants/API";
import getCustomAxios from "../Helpers/customAxios";

export function putfitnessChallengeAssignBadgeAPI(
  decamelizedData,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();

  axios
    .put(
      MMA_FITNESS_FITNESS_CHALLENGE_ASSIGN_BADGE_API_ENDPOINT.replace(
        "{cid}",
        decamelizedData.id
      ),
      decamelizedData
    )
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      // Return the callback data.
      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);

      console.log(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}
