import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faTrash,
	faPencil,
	faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {getAccountDetailAPI, putAccountUpdateAPI} from "../../API/Account";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../AppState";
import Modal from "../Reusable/Modal";
import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import FormTextareaField from "../Reusable/FormTextareaField";

function AccountTagList({modalStates, updateModalState}) {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	// Local state
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [selectedTag, setSelectedTag] = useState(null);
	const [text, setText] = useState("");
	const [description, setDescription] = useState("");

	// Event Handlers
	const onAddButtonClick = () => {
		const modifiedCurrentUser = {...currentUser};
		if (!modifiedCurrentUser.tags) {
			modifiedCurrentUser.tags = [];
		}

		const tag = {
			text,
			description,
			id: Date.now(), // Simple ID generation
		};
		modifiedCurrentUser.tags.push(tag);

		setFetching(true);
		setErrors({});
		putAccountUpdateAPI(
			modifiedCurrentUser,
			onAccountUpdateSuccess,
			onAccountUpdateError,
			onAccountUpdateDone
		);
	};

	const onEditButtonClick = () => {
		let modifiedCurrentUser = {...currentUser};
		const updatedTags = modifiedCurrentUser.tags.map((tag) =>
			selectedTag.id === tag.id ? {...tag, text, description} : tag
		);
		modifiedCurrentUser.tags = updatedTags;
		putAccountUpdateAPI(
			modifiedCurrentUser,
			onAccountUpdateSuccess,
			onAccountUpdateError,
			onAccountUpdateDone
		);
	};

	const onDeleteConfirmClick = () => {
		let modifiedCurrentUser = {...currentUser};
		modifiedCurrentUser.tags = modifiedCurrentUser.tags.filter(
			(tag) => tag.id !== selectedTag.id
		);
		putAccountUpdateAPI(
			modifiedCurrentUser,
			onAccountUpdateSuccess,
			onAccountUpdateError,
			onAccountUpdateDone
		);
	};

	const resetModals = () => {
		updateModalState("tags", "showAdd", false);
		updateModalState("tags", "showEdit", false);
		updateModalState("tags", "showDelete", false);
		setSelectedTag(null);
	};

	// Add API callback functions
	const onAccountUpdateSuccess = (response) => {
		setCurrentUser(response);
		setTopAlertMessage("Tag updated successfully");
		setTopAlertStatus("success");
		resetModals();
		setTimeout(() => setTopAlertMessage(""), 2000);
	};

	const onAccountUpdateError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to update tag");
		setTopAlertStatus("error");
	};

	const onAccountUpdateDone = () => {
		setFetching(false);
	};

	// Effects
	useEffect(() => {
		getAccountDetailAPI(
			(response) => setCurrentUser(response),
			(error) => setErrors(error),
			() => setFetching(false)
		);
	}, []);

	const handleSubmit = (formData) => {
		if (!formData) {
			resetModals();
			return;
		}

		// Create a copy of current user
		const modifiedCurrentUser = {...currentUser};

		// Ensure tags array exists and create a new array
		if (
			!modifiedCurrentUser.hasOwnProperty("tags") ||
			modifiedCurrentUser.tags === undefined ||
			modifiedCurrentUser.tags === null ||
			modifiedCurrentUser.tags === ""
		) {
			modifiedCurrentUser.tags = [];
		} else {
			// Create a new array from existing tags
			modifiedCurrentUser.tags = [...modifiedCurrentUser.tags];
		}

		if (selectedTag) {
			// Edit existing tag
			modifiedCurrentUser.tags = modifiedCurrentUser.tags.map((tag) =>
				tag.id === selectedTag.id
					? {
							...tag,
							text: formData.text,
							description: formData.description,
					  }
					: tag
			);
		} else {
			// Add new tag
			const tag = {
				text: formData.text,
				description: formData.description,
			};
			modifiedCurrentUser.tags.push(tag);
		}

		setFetching(true);
		setErrors({});
		putAccountUpdateAPI(
			modifiedCurrentUser,
			onAccountUpdateSuccess,
			onAccountUpdateError,
			onAccountUpdateDone
		);
	};

	return (
		<>
			{/* Tags Grid */}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
				{(!currentUser?.tags || currentUser.tags.length === 0) ? (
					<div className="col-span-full flex flex-col items-center justify-center bg-gray-50 rounded-2xl p-8 text-center">
						<div className="w-12 h-12 rounded-xl bg-blue-100 flex items-center justify-center mb-4">
							<svg
								className="w-6 h-6 text-blue-600"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A2 2 0 013 12V7a4 4 0 014-4z"
								/>
							</svg>
						</div>
						<h3 className="text-lg font-semibold text-gray-900 mb-2">No Tags Added Yet</h3>
						<p className="text-sm text-gray-500 mb-4">Get started by adding your first tag to organize your content.</p>
					</div>
				) : (
					currentUser.tags.map((tag) => (
						<TagCard
							key={tag.id}
							tag={tag}
							onEdit={() => {
								setSelectedTag(tag);
								updateModalState("tags", "showEdit", true);
							}}
							onDelete={() => {
								setSelectedTag(tag);
								updateModalState("tags", "showDelete", true);
							}}
						/>
					))
				)}
			</div>

			{/* Add Modal */}
			<Modal
				isOpen={modalStates?.showAdd}
				onClose={() => resetModals()}
				header="Add New Tag">
				<TagForm
					errors={errors}
					onSubmit={handleSubmit}
					isSubmitting={isFetching}
				/>
			</Modal>

			{/* Edit Modal */}
			<Modal
				isOpen={modalStates?.showEdit}
				onClose={() => resetModals()}
				header="Edit Tag">
				<TagForm
					tag={selectedTag}
					errors={errors}
					onSubmit={handleSubmit}
					isSubmitting={isFetching}
				/>
			</Modal>

			{/* Delete Modal */}
			<Modal
				isOpen={modalStates?.showDelete}
				onClose={() => resetModals()}
				header="Delete Tag">
				<div className="space-y-4">
					<p className="text-sm text-gray-500">
						Are you sure you want to delete this tag? This action cannot be
						undone.
					</p>
					<div className="flex justify-end gap-3">
						<button
							onClick={resetModals}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
                            border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmClick}
							disabled={isFetching}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                            rounded-md hover:bg-red-700 disabled:opacity-50">
							{isFetching ? "Deleting..." : "Delete Tag"}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}

function TagCard({tag, onEdit, onDelete}) {
	return (
		<div className="bg-darkShade/7 rounded-2xl shadow-sm border border-gray-100 p-3">
			{/* Top Section with Icon and Text */}
			<div className="flex items-start gap-3 mb-4">
				{/* Icon Container */}
				<div className="w-10 h-10 rounded-xl bg-blue-100 flex items-center justify-center flex-shrink-0">
					<svg
						className="w-5 h-5 text-blue-600"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A2 2 0 013 12V7a4 4 0 014-4z"
						/>
					</svg>
				</div>

				{/* Text Content */}
				<div className="flex-1">
					<h3 className="text-base font-semibold text-gray-900 mb-1">
						{tag.text}
					</h3>
					{tag.description && (
						<p className="text-sm text-gray-500 line-clamp-2">
							{tag.description}
						</p>
					)}
				</div>
			</div>

			{/* Action Buttons */}
			<div className="flex gap-2 justify-end mt-2">
				<button
					onClick={onEdit}
					className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 
                    bg-gray-50 rounded-full hover:bg-gray-100 transition-colors duration-200">
					<FontAwesomeIcon icon={faPencil} className="w-3.5 h-3.5" />
					<span>Edit</span>
				</button>
				<button
					onClick={onDelete}
					className="flex items-center gap-2 px-3 py-1.5 text-sm text-red-600 
                    bg-white rounded-full hover:bg-red-50 transition-colors duration-200
                    border border-red-100">
					<FontAwesomeIcon icon={faTrash} className="w-3.5 h-3.5" />
					<span>Delete</span>
				</button>
			</div>
		</div>
	);
}

function TagForm({tag, errors: formErrors, onSubmit, isSubmitting}) {
	// Local state for form
	const [text, setText] = useState(tag?.text || "");
	const [description, setDescription] = useState(tag?.description || "");
	const [errors, setErrors] = useState(formErrors || {});

	// Update errors when props change
	useEffect(() => {
		setErrors(formErrors);
	}, [formErrors]);

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit({text, description});
	};

	return (
		<form onSubmit={handleSubmit} className="space-y-4">
			<FormErrorBox errors={errors} />
			<p className="pb-4">
				Please fill out all the required fields before submitting this form.
			</p>

			<FormInputField
				label="Text"
				name="text"
				type="text"
				placeholder="Input text"
				value={text}
				errorText={errors?.text}
				helpText=""
				onChange={(e) => setText(e.target.value)}
				isRequired={true}
				maxWidth="275px"
			/>

			<FormTextareaField
				label="Description (Optional)"
				name="description"
				placeholder="Description input"
				value={description}
				errorText={errors?.description}
				helpText=""
				onChange={(e) => setDescription(e.target.value)}
				isRequired={false}
				maxWidth="100%"
				rows={2}
			/>

			<div className="flex justify-end gap-3 pt-4">
				<button
					type="button"
					onClick={() => onSubmit(null)}
					className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
                    border-gray-300 rounded-md hover:bg-gray-50">
					Cancel
				</button>
				<button
					type="submit"
					disabled={isSubmitting}
					className="px-4 py-2 text-sm font-medium text-white bg-blue-600 
                    rounded-md hover:bg-blue-700 disabled:opacity-50">
					{isSubmitting ? "Saving..." : tag ? "Save Changes" : "Add Tag"}
				</button>
			</div>
		</form>
	);
}

export default AccountTagList;
