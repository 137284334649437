import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faFacebook,
	faTwitter,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
	faSignInAlt,
	faUserPlus,
	faDumbbell,
	faHeartbeat,
	faChartLine,
} from "@fortawesome/free-solid-svg-icons";

function IndexStaticPage() {
	return (
		<div className="w-full h-full bg-gradient-to-b from-gray-900 to-gray-800">
			{/* Navigation Bar */}
			<nav className="fixed top-0 w-full bg-backgroundDark/95 backdrop-blur-sm z-50 py-4">
				<div className="container mx-auto px-4 flex justify-between items-center">
					<div className="flex items-center">
						<img
							src="logo.svg"
							alt="Logo"
							className="h-14 w-auto mr-2"
						/>
					</div>
					<div className="flex items-center space-x-4">
						<Link
							to="/login"
							className="text-textLight hover:text-lightShade transition-colors duration-200 flex items-center">
							<FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
							Login
						</Link>
						<Link
							to="/register"
							className="bg-primaryColor hover:bg-lightShade text-lightShade px-4 py-2 rounded-sm transition-colors duration-200 flex items-center">
							<FontAwesomeIcon icon={faUserPlus} className="mr-2" />
							Sign Up
						</Link>
					</div>
				</div>
			</nav>

			{/* Hero Section */}
			<section className="relative min-h-screen flex items-center pt-16">
				<div className="absolute inset-0 bg-[url('/static/mma/index.jpg')] bg-cover bg-top-center opacity-20" />
				<div className="container mx-auto px-4 relative z-10">
					<div className="max-w-4xl mx-auto text-center">
						<h1 className="text-5xl md:text-7xl font-bold text-lightShade mb-6 leading-tight font-primary">
							Transform Your Life Through Fitness
						</h1>
						<p className="text-xl md:text-2xl text-textLight mb-8 font-secondary">
							Join our community and achieve your fitness goals with
							personalized workouts, nutrition plans, and expert guidance.
						</p>
						<div className="flex flex-col sm:flex-row justify-center gap-4">
							<Link
								to="/register"
								className="bg-primaryColor hover:bg-lightShade text-lightShade px-8 py-4 rounded-sm text-lg font-semibold transition-all duration-200 transform hover:scale-105 flex items-center justify-center">
								<FontAwesomeIcon icon={faUserPlus} className="mr-2" />
								Start Your Journey
							</Link>
							<Link
								to="/about"
								className="bg-transparent border-control border-lightShade text-lightShade px-8 py-4 rounded-sm text-lg font-semibold hover:bg-lightShade/90 transition-all duration-200 flex items-center justify-center">
								<FontAwesomeIcon icon={faChartLine} className="mr-2" />
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* Features Section */}
			<section className="py-20 bg-darkShade/50">
				<div className="container mx-auto px-4">
					<h2 className="text-3xl md:text-4xl font-bold text-lightShade text-center mb-12 font-primary">
						Why Choose Our Platform?
					</h2>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8">
						{/* Feature 1 */}
						<div className="bg-gray-700/50 rounded-xl p-6 transform hover:scale-105 transition-all duration-200">
							<div className="text-primaryColor text-4xl mb-4">
								<FontAwesomeIcon icon={faDumbbell} />
							</div>
							<h3 className="text-xl font-semibold text-lightShade mb-2 font-primary">
								Personalized Workouts
							</h3>
							<p className="text-textLight font-secondary">
								Custom training plans tailored to your goals and fitness level.
							</p>
						</div>

						{/* Feature 2 */}
						<div className="bg-gray-700/50 rounded-xl p-6 transform hover:scale-105 transition-all duration-200">
							<div className="text-primaryColor text-4xl mb-4">
								<FontAwesomeIcon icon={faHeartbeat} />
							</div>
							<h3 className="text-xl font-semibold text-lightShade mb-2 font-primary">
								Expert Guidance
							</h3>
							<p className="text-textLight font-secondary">
								Access to professional trainers and nutrition experts.
							</p>
						</div>

						{/* Feature 3 */}
						<div className="bg-gray-700/50 rounded-xl p-6 transform hover:scale-105 transition-all duration-200">
							<div className="text-primaryColor text-4xl mb-4">
								<FontAwesomeIcon icon={faChartLine} />
							</div>
							<h3 className="text-xl font-semibold text-lightShade mb-2 font-primary">
								Progress Tracking
							</h3>
							<p className="text-textLight font-secondary">
								Monitor your improvements with detailed analytics and insights.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer className="bg-backgroundDark py-8">
				<div className="container mx-auto px-4">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="text-textLight mb-4 md:mb-0 font-secondary">
							&copy; 2025 Adrenaline MMA. All rights reserved.
						</div>
						<div className="flex space-x-6">
							<Link
								to="https://www.facebook.com/adrenalinemma"
								target="_blank"
								rel="noopener noreferrer"
								className="text-textLight hover:text-lightShade transition-colors duration-200">
								<FontAwesomeIcon icon={faFacebook} size="lg" />
							</Link>
							<Link
								to="https://www.instagram.com/adrenalinemmahome/"
								target="_blank"
								rel="noopener noreferrer"
								className="text-textLight hover:text-lightShade transition-colors duration-200">
								<FontAwesomeIcon icon={faInstagram} size="lg" />
							</Link>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
}

export default IndexStaticPage;
