import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function FormSelectField({
  label,
  name,
  placeholder,
  value,
  errorText,
  helpText,
  onChange,
  options,
  disabled,
  icon // Add icon prop
}) {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative">
        {/* Icon container */}
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <FontAwesomeIcon 
              icon={icon} 
              className="h-4 w-4 text-gray-400"
            />
          </div>
        )}
        
        {/* Select input */}
        <select
          id={name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          className={`
            block w-full rounded-md border-gray-300 
            ${icon ? 'pl-10' : 'pl-3'} pr-10 py-2 
            text-base focus:outline-none 
            appearance-none
            ${errorText 
              ? 'border-red-500 focus:border-red-500 focus:ring-red-500' 
              : 'focus:border-primary focus:ring-primary'
            } 
            ${disabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}
          `}
        >
          {options.map((option, i) => (
            <option 
              key={i} 
              value={option.value} 
              selected={value === option.value}
            >
              {option.label}
            </option>
          ))}
        </select>

        {/* Custom dropdown arrow */}
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <FontAwesomeIcon 
            icon={faChevronDown} 
            className="h-4 w-4 text-gray-400"
          />
        </div>
      </div>
      
      {/* Help and error text */}
      {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}
      {errorText && <p className="mt-1 text-sm text-red-600">{errorText}</p>}
    </div>
  );
}

export default FormSelectField;