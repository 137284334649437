import React from "react";
import Select from 'react-select';
import { getSelectedOptions } from "../../Helpers/selectHelper";

function FormMultiSelectField({
  label,
  name,
  placeholder,
  options,
  selectedValues,
  onChange,
  errorText,
  helpText,
  maxWidth,
  disabled = false
}) {
  return (
    <div className="mb-4" style={{ maxWidth }}>
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <Select
        isMulti
        name={name}
        options={options}
        value={getSelectedOptions(options, selectedValues)}
        isClearable={false}
        onChange={onChange}
        isDisabled={disabled}
        classNamePrefix="react-select"
        styles={{
          control: (base) => ({
            ...base,
            borderColor: errorText ? 'red' : base.borderColor,
            '&:hover': { borderColor: errorText ? 'red' : base.borderColor }
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
      />
      {errorText && <p className="text-sm text-red-600">{errorText}</p>}
      {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
    </div>
  );
}

export default FormMultiSelectField;