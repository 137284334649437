import getCustomAxios from "../Helpers/customAxios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { DateTime } from "luxon";

import {
  MMA_FITNESS_LEVELS_API_ENDPOINT,
  MMA_FITNESS_LEVEL_API_ENDPOINT,
} from "../Constants/API";

// Function to fetch the list of levels
export function getLevelsListAPI(
  filtersMap = new Map(),
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();

  let aURL = MMA_FITNESS_LEVELS_API_ENDPOINT;
  filtersMap.forEach((value, key) => {
    let decamelizedkey = decamelizeKeys(key);
    if (aURL.indexOf("?") > -1) {
      aURL += "&" + decamelizedkey + "=" + value;
    } else {
      aURL += "?" + decamelizedkey + "=" + value;
    }
  });

  axios
    .get(aURL)
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Convert snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      // Handle date formatting
      if (data.results && data.results.length > 0) {
        data.results.forEach((item) => {
          if (item.createdAt) {
            item.createdAt = DateTime.fromISO(item.createdAt).toLocaleString(
              DateTime.DATETIME_MED
            );
          }
        });
      }

      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}

// Function to create a new level
export function postLevelCreateAPI(
  decamelizedData,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();

  axios
    .post(MMA_FITNESS_LEVELS_API_ENDPOINT, decamelizedData)
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Convert snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}

// Function to fetch a single level's details
export function getLevelDetailAPI(
  id,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();
  axios
    .get(MMA_FITNESS_LEVEL_API_ENDPOINT.replace("{id}", id))
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Convert snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}

// Function to update an existing level
export function putLevelUpdateAPI(
  decamelizedData,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();

  axios
    .put(
      MMA_FITNESS_LEVEL_API_ENDPOINT.replace("{id}", decamelizedData.id),
      decamelizedData
    )
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Convert snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}

// Function to delete a level
export function deleteLevelAPI(
  id,
  onSuccessCallback,
  onErrorCallback,
  onDoneCallback
) {
  const axios = getCustomAxios();
  axios
    .delete(MMA_FITNESS_LEVEL_API_ENDPOINT.replace("{id}", id))
    .then((successResponse) => {
      const responseData = successResponse.data;

      // Convert snake-case from API to camel-case for React.
      const data = camelizeKeys(responseData);

      onSuccessCallback(data);
    })
    .catch((exception) => {
      let errors = camelizeKeys(exception);
      onErrorCallback(errors);
    })
    .then(onDoneCallback);
}
