import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";

const FileUpload = ({
	label, // Default label text
	fileType, // File type for label customization
	fileName, // Prefilled file name, if any
	onFileChange, // Callback for handling file selection
	multiple = false, // Keep it remain False
}) => {
	const [selectedFileName, setSelectedFileName] = useState(
		fileName || "No file selected"
	);

	useEffect(() => {
		// Update selected file name if the `fileName` prop changes
		if (fileName) {
			setSelectedFileName(fileName);
		}
	}, [fileName]);

	// Determine label text based on whether a file is selected
	const labelText =
		selectedFileName !== "No file selected"
			? `Replace ${fileType || "File"}`
			: label || `Choose a ${fileType || "file"}…`;

	return (
		<div className="file is-info has-name">
			<label className="file-label">
				<input
					className="file-input"
					type="file"
					onChange={onFileChange}
					name="file"
					multiple={multiple} // Enable multiple file selection if needed
				/>
				<span className="file-cta">
					<span className="file-icon">
						<FontAwesomeIcon className="fas" icon={faUpload} />
					</span>
					<span className="file-label">{labelText}</span>
				</span>
				<span className="file-name">{selectedFileName}</span>
			</label>
		</div>
	);
};

export default FileUpload;
