import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSearch } from "@fortawesome/free-solid-svg-icons";

const Topbar = ({ setSidebarOpen, currentUser }) => (
  <header className="bg-darkShade border-b border-gray-700">
    <div className="px-4 sm:px-6 lg:px-8">
	<div className="flex items-center justify-between lg:justify-end md:justify-end h-16">
  {/* Mobile menu button */}
  <button 
    onClick={() => setSidebarOpen(true)} 
    className="lg:hidden md:hidden text-gray-400 hover:text-white"
  >
    <FontAwesomeIcon icon={faBars} className="h-6 w-6" />
  </button>

  {/* Logo - will be pushed to end when faBars is hidden */}
  <div className="flex">
    <img src="/logo.svg" alt="Logo" className="h-10 w-auto" />
  </div>

  {/* Search Bar */}
  {/* <div className="flex-1 max-w-xl">
    <div className="relative">
      <input
        type="text"
        placeholder="Search..."
        className="w-full bg-gray-600 text-white rounded-lg pl-4 pr-10 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        <FontAwesomeIcon icon={faSearch} className="h-5 w-5 text-gray-400" />
      </div>
    </div>
  </div> */}
</div>
    </div>
  </header>
);

export default Topbar;
