import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserClock} from "@fortawesome/free-solid-svg-icons";

import {getMemberDetailAPI} from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";

function AdminMemberDetailForWaitlisterList() {
	const {bid, id} = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [waitlist, setWaitlist] = useState([]);
	const [error, setError] = useState(null);

	////
	//// API Callbacks
	////

	function onMemberDetailSuccess(response) {
		setWaitlist(response.waitlist || []);
		setError(null);
	}

	function onMemberDetailError(apiErr) {
		setError(apiErr.message || "An error occurred while loading waitlist");
		console.error("Member Detail API Error:", apiErr);
	}

	function onMemberDetailDone() {
		setIsLoading(false);
	}

	////
	//// Lifecycle
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setIsLoading(true);
			setError(null);
			getMemberDetailAPI(
				id,
				onMemberDetailSuccess,
				onMemberDetailError,
				onMemberDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Render
	////

	if (isLoading) {
		return <PageLoadingContent displayMessage="Loading waitlist..." />;
	}

	if (error) {
		return (
			<div className="p-4 text-red-600 bg-red-50 rounded-lg">
				Error loading waitlist: {error}
			</div>
		);
	}

	return (
		<div className="space-y-6">
			{/*Member Waitlist Section */}
			<section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
				<h3 className="text-lg font-bold text-textDark flex items-center">
					<FontAwesomeIcon
						icon={faUserClock}
						className="mr-3 text-primaryColor"
					/>
					Member Waitlist
				</h3>

				<div className="mt-6">
					{waitlist.length === 0 ? (
						<div className="text-center py-12 bg-gray-50 rounded-lg">
							<div className="w-12 h-12 mx-auto rounded-xl bg-main flex items-center justify-center mb-4">
								<FontAwesomeIcon
									icon={faUserClock}
									className="w-6 h-6 text-white"
								/>
							</div>
							<h3 className="text-lg font-semibold text-gray-900 mb-2">
								No Waitlist Entries
							</h3>
							<p className="text-sm text-gray-500">
								This member is not currently on any waitlists.
							</p>
						</div>
					) : (
						<div className="bg-white shadow rounded-lg">
							<ul className="divide-y divide-gray-200">
								{waitlist.map((entry) => (
									<li key={entry.id} className="p-4 hover:bg-gray-50">
										<div className="flex justify-between items-center">
											<div>
												<h3 className="font-medium text-gray-900">
													{entry.sessionName}
												</h3>
												<p className="text-sm text-gray-500">
													Added:{" "}
													{new Date(entry.dateAdded).toLocaleDateString()}
												</p>
											</div>
											<div className="flex items-center gap-4">
												<span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
													Position: {entry.position}
												</span>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default AdminMemberDetailForWaitlisterList;
