import React from "react";

function DataDisplayRowMultiSelectStatic({
    label = "",
    selectedValues = [],
    map = {},
    helpText = ""
}) {
    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <div className="flex flex-wrap gap-2">
                    {selectedValues?.map((value, i) => (
                        <span 
                            key={i}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary text-lightShade"
                        >
                            {map[value]}
                        </span>
                    ))}
                </div>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowMultiSelectStatic;