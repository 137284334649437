function FormRadioField({
  label, // The text to display the user.
  name, // The element HTML name.
  value, // The selected value.
  opt1Value, // The value to use for option #1.
  opt1Label, // The label to display for option #1.
  opt2Value, // ...
  opt2Label, // ...
  opt3Value, // ...
  opt3Label, // ...
  opt4Value, // ...
  opt4Label, // ...
  opt5Value, // ...
  opt5Label, // ...
  opt6Value, // ...
  opt6Label, // ...
  opt7Value, // ...
  opt7Label, // ...
  errorText, // The error message to display
  wasValidated, // Boolean indicates if this element was successfully validated or not.
  helpText, // The special help task to include.
  onChange, // The function to call when a selection occurs.
}) {
  return (
    <div className="field pb-4">
      <label className="label">{label}</label>
      <div className="control">
        {opt1Label && (
          <>
            <label className="radio">
              <input
                type="radio"
                data-label={opt1Value}
                checked={opt1Value === value}
                name={name}
                value={opt1Value}
                onChange={onChange}
              />
              &nbsp;
              {errorText ? (
                <span style={{ color: "#f14668" }}>{opt1Label}</span>
              ) : (
                <span
                  style={
                    wasValidated ? { color: "#48c78e" } : { color: "#363636" }
                  }
                >
                  {opt1Label}
                </span>
              )}
            </label>
            &nbsp;&nbsp;
          </>
        )}
        {opt2Label && (
          <>
            <label className="radio">
              <input
                type="radio"
                data-label={opt2Value}
                checked={opt2Value === value}
                name={name}
                value={opt2Value}
                onChange={onChange}
              />
              &nbsp;
              {errorText ? (
                <span style={{ color: "#f14668" }}>{opt2Label}</span>
              ) : (
                <span
                  style={
                    wasValidated ? { color: "#48c78e" } : { color: "#363636" }
                  }
                >
                  {opt2Label}
                </span>
              )}
            </label>
            &nbsp;&nbsp;
          </>
        )}
        {opt3Label && (
          <>
            <label className="radio">
              <input
                type="radio"
                data-label={opt3Value}
                checked={opt3Value === value}
                name={name}
                value={opt3Value}
                onChange={onChange}
              />
              &nbsp;
              {errorText ? (
                <span style={{ color: "#f14668" }}>{opt3Label}</span>
              ) : (
                <span
                  style={
                    wasValidated ? { color: "#48c78e" } : { color: "#363636" }
                  }
                >
                  {opt3Label}
                </span>
              )}
            </label>
            &nbsp;&nbsp;
          </>
        )}
        {/* Repeat the same pattern for opt4Label through opt7Label */}
      </div>
      {errorText && <p className="help is-danger">{errorText}</p>}
      <p className="help">{helpText}</p>
    </div>
  );
}

export default FormRadioField;
