import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faTrophy, 
  faFire, 
  faBolt, 
  faStar,
  faChartLine 
} from "@fortawesome/free-solid-svg-icons";

const ProgressBar = ({ currentLevel, currentLevelDetails }) => {
  const progress = currentLevel?.challengesCompleted || 0;
  const totalChallenges = currentLevelDetails?.numChallenges || 1;
  const progressPercentage = (progress / totalChallenges) * 100;

  const getLevelIcon = () => {
    if (progressPercentage === 100) return faTrophy;
    if (progressPercentage >= 75) return faFire;
    if (progressPercentage >= 50) return faBolt;
    if (progressPercentage >= 25) return faStar;
    return faChartLine;
  };

  const getMotivationalContent = () => {
    const messages = [
      {
        threshold: 0,
        text: "Begin Your Journey",
        subtext: "Every champion started here",
        color: "text-blue-400"
      },
      {
        threshold: 25,
        text: "Building Momentum",
        subtext: "You're developing warrior strength",
        color: "text-green-400"
      },
      {
        threshold: 50,
        text: "Halfway to Glory",
        subtext: "Your dedication is showing results",
        color: "text-yellow-400"
      },
      {
        threshold: 75,
        text: "Peak Performance",
        subtext: "Excellence becomes habit",
        color: "text-orange-400"
      },
      {
        threshold: 100,
        text: "Master Level",
        subtext: "You've achieved greatness",
        color: "text-red-400"
      }
    ];

    return messages.find(m => progressPercentage <= m.threshold) || messages[messages.length - 1];
  };

  const motivationalContent = getMotivationalContent();

  return (
    <div className="relative transform transition-all duration-300 hover:scale-[1.02]">
      <div className="p-6 bg-gradient-to-r from-gray-900 to-gray-800 rounded-2xl border border-gray-700/30 shadow-xl">
        <div className="flex flex-col md:flex-row gap-6 items-center">
          {/* Level Badge */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div className="p-4 bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl border border-gray-700">
                <FontAwesomeIcon 
                  icon={getLevelIcon()} 
                  className={`text-2xl ${motivationalContent.color} animate-pulse`} 
                />
              </div>
              <div className="absolute -top-2 -right-2">
                <div className="px-2 py-1 bg-main/90 rounded-full text-xs font-bold">
                  {`${progress}/${totalChallenges}`}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-lg font-bold text-white">
                {currentLevelDetails?.name || "Level"}
              </span>
              <span className={`text-sm ${motivationalContent.color}`}>
                {motivationalContent.text}
              </span>
            </div>
          </div>

          {/* Progress Bar */}
          <div className="flex-1 space-y-2">
            <div className="relative h-4">
              {/* Background */}
              <div className="absolute inset-0 bg-gray-700/30 rounded-full overflow-hidden">
                {/* Progress Gradient */}
                <div
                  className="h-full rounded-full transition-all duration-700 ease-out relative overflow-hidden"
                  style={{
                    width: `${progressPercentage}%`,
                    background: "linear-gradient(90deg, #FF4D00, #FF8A00)",
                  }}
                >
                  {/* Animated Shine Effect */}
                  <div className="absolute inset-0 animate-shine">
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent skew-x-12" />
                  </div>
                </div>
              </div>

              {/* Milestone Markers */}
              <div className="absolute inset-0 flex justify-between items-center px-1">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={`
                      h-2 w-2 rounded-full transition-all duration-500
                      ${progressPercentage >= index * 25 
                        ? 'bg-white shadow-glow' 
                        : 'bg-gray-600'
                      }
                    `}
                  />
                ))}
              </div>
            </div>

            {/* Motivational Message */}
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-400">
                {motivationalContent.subtext}
              </span>
              <span className="text-main font-medium">
                {`${Math.round(progressPercentage)}% Complete`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;