import {
    faTachometer,
    faUsers,
    faDumbbell,
    faVideoCamera,
    faLeaf,
    faCalendarPlus,
    faBolt,
    faRibbon,
    faLevelUp,
    faTicketSimple,
    faBuilding,
    faQuestionCircle,
    faHandHolding,
    faUserCircle,
    faSignOut,
    faTrophy,
    faMessage,
    faHeartbeat,
    faRankingStar,
    faStar,
    faChartLine,
    faIdBadge,
    faCreditCard,
    faLayerGroup,
    faPersonRunning,
  } from '@fortawesome/free-solid-svg-icons';
  import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
  
  // Admin Navigation Items
  export const adminNavigationItems = [
    {
      name: 'Menu',
      type: 'section',
      items: [
        {
          name: 'Dashboard',
          icon: faTachometer,
          link: '/admin/dashboard',
        },
        {
          name: 'Members',
          icon: faUsers,
          link: '/admin/members',
        },
        {
          name: 'Exercises',
          icon: faDumbbell,
          link: '/admin/exercises',
        },
        {
          name: 'Global Workouts',
          icon: faPersonRunning,
          link: '/admin/workouts',
        },
        {
          name: 'Training Program',
          icon: faCalendarPlus,
          link: '/admin/training-program',
        },
        {
          name: 'Lesson of the Week',
          icon: faTicketSimple,
          link: '/admin/tips',
        },
        {
          name: 'Challenges',
          icon: faBolt,
          link: '/admin/fitness-challenges',
          subItems: [
            {
              name: 'Badges',
              icon: faRibbon,
              link: '/admin/badges',
            },
            {
              name: 'Levels',
              icon: faLevelUp,
              link: '/admin/levels',
            },
          ],
        }
      ],
    },
    {
      name: 'System',
      type: 'section',
      items: [
        {
          name: 'Organization',
          icon: faBuilding,
          link: '/admin/organization',
        },
        {
          name: 'MMA Lesson Categories',
          icon: faLayerGroup,
          link: '/admin/video-categories',
        },
        {
          name: 'MMA Lessons',
          icon: faVideoCamera,
          link: '/admin/video-collections',
        },
        {
          name: 'Offers',
          icon: faHandHolding,
          link: '/admin/offers',
        },
        {
          name: 'Onboarding Questions',
          icon: faQuestionCircle,
          link: '/admin/questions',
        }
      ],
    },
  ];
  
  // Member Navigation Items
  export const memberNavigationItems = [
    {
      name: 'Menu',
      type: 'section',
      items: [
        {
          name: 'Dashboard',
          icon: faTachometer,
          link: '/dashboard',
        },
        {
          name: 'MMA Lessons',
          icon: faVideoCamera,
          link: '/videos',
          badge: 'New',
        },
        {
          name: 'My Fitness Plan',
          icon: faTrophy,
          link: '/fitness-plans',
          subItems: [
            {
              name: 'Exercises',
              icon: faDumbbell,
              link: '/exercises',
            },
          ],
        },
        {
          name: 'Nutrition Plan',
          icon: faLeaf,
          link: '/nutrition-plans',
        },
        {
          name: 'Global Workouts',
          icon: faDumbbell,
          link: '/workouts',
        },
        {
          name: 'Challenges',
          icon: faBolt,
          link: '/fitness-challenge',
        },
      ],
    },
  ];
  
  // Common Navigation Items (for all users)
  export const commonNavigationItems = [
    {
      name: 'Account',
      type: 'section',
      items: [
        {
          name: 'Account',
          icon: faUserCircle,
          link: '/account',
        },
        {
          name: 'Subscription Plans',
          icon: faCreditCard,
          link: '/account/more/subscribe',
        },
      ],
    },
    {
      name: 'Connect with us on',
      type: 'section',
      items: [
        {
          name: 'Adrenaline Community',
          icon: faWhatsapp,
          link: 'https://chat.whatsapp.com/FdVtOFCoUN48PUy5E4AcKe',
          external: true,
          color: 'text-main',
        },
      ],
    },
  ];
  
  // Helper function to get navigation items based on user role
  export const getNavigationItems = (role) => {
    switch (role) {
      case 1: // Root Admin
        return [...adminNavigationItems, ...commonNavigationItems];
      case 2: // Staff Admin
      case 3: // Staff
        return [...adminNavigationItems, ...commonNavigationItems];
      case 4: // Member
        return [...memberNavigationItems, ...commonNavigationItems];
      default:
        return commonNavigationItems;
    }
  };