import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function DataDisplayRowCheckbox({ label, checked, helpText }) {
    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm ${
                    checked 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                }`}>
                    <FontAwesomeIcon 
                        icon={checked ? faCheckCircle : faTimesCircle} 
                        className="mr-1.5 h-4 w-4"
                    />
                    {checked ? 'Yes' : 'No'}
                </span>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowCheckbox;