import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faEnvelope,
	faKey,
	faLock,
	faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import Button from "../Reusable/Button";
import FormCheckboxField from "../Reusable/FormCheckboxField";

const LoginMobile = ({
	errors,
	isUnauthorized,
	validation,
	email,
	onEmailChange,
	password,
	onPasswordChange,
	onButtonClick,
	rememberMe,
	setRememberMe,
	isLoading = false,
}) => {
	return (
		<div className="rounded bg-white flex flex-col">
			{/* Header Section */}
			<div className="bg-darkShade rounded pt-16 pb-12">
				<div className="flex flex-col items-center px-8">
					{/* Icon */}
					<div className="w-20 h-20 bg-white/10 rounded-full flex items-center justify-center mb-4">
						<img
							src="/static/logo.png"
							style={{width: "120px", borderRadius: "50%"}}
							alt="Logo"
						/>
					</div>
					{/* Heading */}
					<h1 className="text-white text-2xl font-semibold text-center">
						Hello again, welcome back!
					</h1>
				</div>
			</div>

			{/* Form Section */}
			<div className="flex flex-col items-center px-4 sm:px-6 mt-10">
				<form className="w-full space-y-6">
					{isUnauthorized === "true" && (
						<div className="p-4 bg-error/10 border border-error/20 rounded-lg flex items-center text-error">
							<FontAwesomeIcon icon={faTriangleExclamation} className="mr-3" />
							<p>
								Your session has ended.
								<br />
								Please login again
							</p>
						</div>
					)}

					<FormErrorBox errors={errors} />

					<FormInputField
						label="Email"
						name="email"
						type="email"
						placeholder="Enter your email"
						value={email}
						onChange={onEmailChange}
						error={errors?.email}
						validation={!errors?.email && email.includes("@")}
						icon={faEnvelope}
					/>

					<FormInputField
						label="Password"
						name="password"
						type="password"
						icon={faLock}
						placeholder="Enter your password"
						value={password}
						onChange={onPasswordChange}
						error={errors?.password}
						validation={password?.length >= 8}
					/>

					<FormCheckboxField
						label="Remember Me"
						name="rememberMe"
						checked={rememberMe}
						errorText={errors.rememberMe}
						wasValidated={validation.rememberMe}
						helpText="By selecting YES, important communication will occur through email"
						onChange={() => setRememberMe(!rememberMe)}
					/>

					<Link
						to="/forgot-password"
						className="text-sm text-main hover:underline">
						Forgot Password?
					</Link>

					<Button
						text="Login"
						icon={faArrowRight}
						onClick={onButtonClick}
						variant="primary"
						size="large"
						fullWidth={true}
						isLoading={isLoading}
						disabled={isLoading}
						className="mt-6"
					/>
				</form>
			</div>

			{/* Footer Section */}
			<div className="my-8 text-center">
				<p className="text-gray-600">
					Don't have an account yet?{" "}
					<Link
						to="/register"
						className="text-main font-semibold hover:text-main/80 transition-colors duration-200">
						Sign up
					</Link>
				</p>
			</div>
		</div>
	);
};

export default LoginMobile;
