  import React, { useState, useEffect } from "react";
  import Scroll from "react-scroll";
  import { Link, Navigate } from "react-router-dom";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faPlus, faTimesCircle, faCheckCircle, faGauge, faLeaf, faIdCard, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
  import { useRecoilState } from "recoil";
  import Modal from "../../Reusable/Modal";
  import FormErrorBox from "../../Reusable/FormErrorBox";
  import FormRadioField from "../../Reusable/FormRadioField";
  import FormInputField from "../../Reusable/FormInputField";
  import FormTextareaField from "../../Reusable/FormTextareaField";
  import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
  import FormAlternateDateField from "../../Reusable/FormAlternateDateField";
  import FormSelectField from "../../Reusable/FormSelectField";
  import FormDuelSelectField from "../../Reusable/FormDuelSelectField";
  import PageLoadingContent from "../../Reusable/PageLoadingContent";
  import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
  import { FEET_WITH_EMPTY_OPTIONS, INCHES_WITH_EMPTY_OPTIONS, MAX_WEEK_WITH_EMPTY_OPTIONS, MEALS_PER_DAY_WITH_EMPTY_OPTIONS, CONSUME_FREQUENCY_WITH_EMPTY_OPTIONS, NUTRITIONAL_GOAL_WITH_EMPTY_OPTIONS } from "../../../Constants/FieldOptions";
  import { GENDER_OTHER, GENDER_MALE, GENDER_FEMALE, PHYSICAL_ACTIVITY_SEDENTARY, PHYSICAL_ACTIVITY_LIGHTLY_ACTIVE, PHYSICAL_ACTIVITY_MODERATELY_ACTIVE, PHYSICAL_ACTIVITY_VERY_ACTIVE, WORKOUT_INTENSITY_LOW, WORKOUT_INTENSITY_MEDIUM, WORKOUT_INTENSITY_HIGH } from "../../../Constants/App";
  import Layout from "../../Menu/Layout";
  import { postNutritionPlanCreateAPI } from "../../../API/NutritionPlan";

  function MemberNutritionPlanAdd() {
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [showCancelWarning, setShowCancelWarning] = useState(false);

    const [name, setName] = useState("");
    const [hasAllergies, setHasAllergies] = useState(0);
    const [allergies, setAllergies] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [heightFeet, setHeightFeet] = useState(-1);
    const [heightInches, setHeightInches] = useState(-1);
    const [weight, setWeight] = useState(0);
    const [gender, setGender] = useState(0);
    const [genderOther, setGenderOther] = useState("");
    const [idealWeight, setIdealWeight] = useState(0);
    const [physicalActivity, setPhysicalActivity] = useState(0);
    const [workoutIntensity, setWorkoutIntensity] = useState(0);
    const [mealsPerDay, setMealsPerDay] = useState(0);
    const [consumeJunkFood, setConsumeJunkFood] = useState(0);
    const [consumeFruitsAndVegetables, setConsumeFruitsAndVegetables] = useState(0);
    const [hasIntermittentFasting, setHasIntermittentFasting] = useState(0);
    const [maxWeeks, setMaxWeeks] = useState(0);
    const [goals, setGoals] = useState([]);

    const onSubmitClick = (e) => {
      e.preventDefault();
      setFetching(true);
      setErrors({});

      const decamelizedData = {
        name: name,
        has_allergies: hasAllergies,
        allergies: allergies,
        meals_per_day: mealsPerDay,
        consume_junk_food: consumeJunkFood,
        consume_fruits_and_vegetables: consumeFruitsAndVegetables,
        birthday: birthday,
        height_feet: heightFeet,
        height_inches: heightInches,
        weight: weight,
        gender: gender,
        gender_other: genderOther,
        ideal_weight: idealWeight,
        physical_activity: physicalActivity,
        workout_intensity: workoutIntensity,
        time_per_day: hasIntermittentFasting,
        max_weeks: maxWeeks,
        goals: goals,
        has_intermittent_fasting: hasIntermittentFasting,
        user_id: currentUser.id,
        visibility: 2,
      };

      postNutritionPlanCreateAPI(
        decamelizedData,
        onMemberNutritionPlanAddSuccess,
        onMemberNutritionPlanAddError,
        onMemberNutritionPlanAddDone
      );
    };

    const onMemberNutritionPlanAddSuccess = (response) => {
      setTopAlertMessage("Nutrition plan has been submitted");
      setTopAlertStatus("success");
      setTimeout(() => {
        setTopAlertMessage("");
      }, 2000);
      setForceURL("/nutrition-plan/" + response.id);
    };

    const onMemberNutritionPlanAddError = (apiErr) => {
      setErrors(apiErr);
      setTopAlertMessage("Failed submitting nutrition plan");
      setTopAlertStatus("danger");
      setTimeout(() => {
        setTopAlertMessage("");
      }, 2000);
      Scroll.animateScroll.scrollToTop();
    };

    const onMemberNutritionPlanAddDone = () => {
      setFetching(false);
    };

    const breadcrumbItems = [
      {
        label: "Dashboard",
        icon: faGauge,
        link: "/dashboard",
      },
      {
        label: "Nutrition Plans",
        icon: faLeaf,
        link: "/nutrition-plans",
      },
      {
        label: "New",
        icon: faPlus,
      },
    ]

    useEffect(() => {
      Scroll.animateScroll.scrollToTop();
    }, []);

    if (forceURL !== "") {
      return <Navigate to={forceURL} />;
    }

    return (
      <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
        <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-8 mb-8 shadow-lg">
          <div className="mx-auto px-4 text-center">
            <h1 className="text-4xl font-bold animate-bounce">Create Your Nutrition Plan</h1>
            <p className="mt-2 text-lg">Personalize your nutrition goals and track your progress</p>
          </div>
        </header>

        <Modal
          isOpen={showCancelWarning}
          onClose={() => setShowCancelWarning(false)}
          header="Are you sure?"
        >
          <p>Your record will be cancelled and your work will be lost. This cannot be undone. Do you want to continue?</p>
          <div className="flex justify-end mt-4">
            <Link className="bg-green-500 text-white px-4 py-2 rounded-lg mr-2" to={`/nutrition-plans`}>
              Yes
            </Link>
            <button className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg" onClick={() => setShowCancelWarning(false)}>
              No
            </button>
          </div>
        </Modal>

        <div className="container mx-auto px-4">
          <div className="bg-white shadow-md rounded-lg p-8 mb-8">
            <FormErrorBox errors={errors} />

            {isFetching && <PageLoadingContent displayMessage={"Please wait..."} />}

            <form onSubmit={onSubmitClick} className="space-y-6">
              <FormInputField
                label="Name (Optional)"
                name="name"
                placeholder="Text input"
                value={name}
                errorText={errors && errors.name}
                helpText="Give this nutrition plan a name you can use to keep track for your own purposes. Ex: `My Cardio-Plan`."
                onChange={(e) => setName(e.target.value)}
                isRequired={true}
                maxWidth="full"
              />

              <h2 className="text-2xl font-semibold mt-6 mb-2">Personal Details</h2>
              <hr />

              <FormAlternateDateField
                label="Birthday"
                name="birthday"
                placeholder="Text input"
                value={birthday}
                helpText=""
                onChange={(date) => setBirthday(date)}
                errorText={errors && errors.birthday}
                isRequired={true}
                maxWidth="full"
                maxDate={new Date()}
              />

              <FormDuelSelectField
                label="Please enter your height?"
                oneName="heightFeet"
                onePlaceholder="Pick"
                oneSelectedValue={heightFeet}
                oneErrorText={errors && errors.heightFeet}
                oneOnChange={(e) => setHeightFeet(parseInt(e.target.value))}
                oneOptions={FEET_WITH_EMPTY_OPTIONS}
                oneDisabled={false}
                oneMaxWidth={{ maxWidth: "100px" }}
                twoLabel="Height"
                twoName="heightInches"
                twoPlaceholder="Pick"
                twoSelectedValue={heightInches}
                twoErrorText={errors && errors.heightInches}
                twoOnChange={(e) => setHeightInches(parseInt(e.target.value))}
                twoOptions={INCHES_WITH_EMPTY_OPTIONS}
                twoDisabled={false}
                twoMaxWidth={{ maxWidth: "100px" }}
                helpText={
                  heightFeet > -1 &&
                  heightInches > -1 && (
                    <>
                      (Your height is {heightFeet} ft and {heightInches} inches)
                    </>
                  )
                }
              />

              <FormInputField
                label="What is your current weight (lbs)?"
                type="number"
                name="weight"
                placeholder="Text input"
                value={weight}
                errorText={errors && errors.weight}
                helpText="lbs"
                onChange={(e) => setWeight(parseFloat(e.target.value))}
                isRequired={true}
                maxWidth="full"
              />

              <FormRadioField
                label="What is your gender?"
                name="gender"
                placeholder="Pick"
                value={gender}
                opt1Value={GENDER_MALE}
                opt1Label="Male"
                opt2Value={GENDER_FEMALE}
                opt2Label="Female"
                opt3Value={GENDER_OTHER}
                opt3Label="Other"
                errorText={errors && errors.gender}
                onChange={(e) => setGender(parseInt(e.target.value))}
                maxWidth="full"
                disabled={false}
              />
              {gender === GENDER_OTHER && (
                <FormInputField
                  label="Gender (Other)"
                  name="genderOther"
                  placeholder="Text input"
                  value={genderOther}
                  errorText={errors && errors.genderOther}
                  helpText=""
                  onChange={(e) => setGenderOther(e.target.value)}
                  isRequired={true}
                  maxWidth="full"
                />
              )}

              <FormInputField
                label="What is your ideal weight for your nutrition goal?"
                type="number"
                name="idealWeight"
                placeholder="lbs"
                value={idealWeight}
                errorText={errors && errors.idealWeight}
                helpText="lbs"
                onChange={(e) => setIdealWeight(parseFloat(e.target.value))}
                isRequired={true}
                maxWidth="full"
              />

              <FormRadioField
                label="My current level of physical activity is"
                name="physicalActivity"
                placeholder="Pick"
                value={physicalActivity}
                opt1Value={PHYSICAL_ACTIVITY_SEDENTARY}
                opt1Label="Sedentary"
                opt2Value={PHYSICAL_ACTIVITY_LIGHTLY_ACTIVE}
                opt2Label="Lightly Active"
                opt3Value={PHYSICAL_ACTIVITY_MODERATELY_ACTIVE}
                opt3Label="Moderately Active"
                opt4Value={PHYSICAL_ACTIVITY_VERY_ACTIVE}
                opt4Label="Very Active"
                errorText={errors && errors.physicalActivity}
                onChange={(e) => setPhysicalActivity(parseInt(e.target.value))}
                maxWidth="full"
                disabled={false}
              />

              <FormRadioField
                label="My current intensity in my exercise routine is"
                name="workoutIntensity"
                placeholder="Pick"
                value={workoutIntensity}
                opt1Value={WORKOUT_INTENSITY_LOW}
                opt1Label="Low"
                opt2Value={WORKOUT_INTENSITY_MEDIUM}
                opt2Label="Medium"
                opt3Value={WORKOUT_INTENSITY_HIGH}
                opt3Label="High"
                errorText={errors && errors.workoutIntensity}
                onChange={(e) => setWorkoutIntensity(parseInt(e.target.value))}
                maxWidth="full"
                disabled={false}
              />

              <h2 className="text-2xl font-semibold mt-6 mb-2">Allergies</h2>
              <hr />

              <FormRadioField
                label="Do you have any allergies?"
                name="hasAllergies"
                placeholder="Pick"
                value={hasAllergies}
                opt1Value={1}
                opt1Label="Yes"
                opt2Value={2}
                opt2Label="No"
                errorText={errors && errors.hasAllergies}
                onChange={(e) => setHasAllergies(parseInt(e.target.value))}
                maxWidth="full"
                disabled={false}
              />

              {hasAllergies === 1 && (
                <FormTextareaField
                  label="If yes, what are your allergies?"
                  type="allergies"
                  name="allergies"
                  placeholder=""
                  value={allergies}
                  errorText={errors && errors.allergies}
                  helpText=""
                  onChange={(e) => setAllergies(e.target.value)}
                  isRequired={true}
                  maxWidth="full"
                />
              )}

              <h2 className="text-2xl font-semibold mt-6 mb-2">Goal(s) for Nutrition Plan</h2>
              <hr />

              <FormSelectField
                label="How many meals do you typically eat in a day?"
                name="mealsPerDay"
                placeholder="Pick"
                selectedValue={mealsPerDay}
                errorText={errors && errors.mealsPerDay}
                helpText=""
                onChange={(e) => setMealsPerDay(parseInt(e.target.value))}
                options={MEALS_PER_DAY_WITH_EMPTY_OPTIONS}
                disabled={false}
              />

              <FormSelectField
                label="How often do you consume fast food or junk food?"
                name="consumeJunkFood"
                placeholder="Pick"
                selectedValue={consumeJunkFood}
                errorText={errors && errors.consumeJunkFood}
                helpText=""
                onChange={(e) => setConsumeJunkFood(parseInt(e.target.value))}
                options={CONSUME_FREQUENCY_WITH_EMPTY_OPTIONS}
                disabled={false}
              />

              <FormSelectField
                label="How often do you consume fruits and/or vegetables?"
                name="consumeFruitsAndVegetables"
                placeholder="Pick"
                selectedValue={consumeFruitsAndVegetables}
                errorText={errors && errors.consumeFruitsAndVegetables}
                helpText=""
                onChange={(e) =>
                  setConsumeFruitsAndVegetables(parseInt(e.target.value))
                }
                options={CONSUME_FREQUENCY_WITH_EMPTY_OPTIONS}
                disabled={false}
              />

              <FormMultiSelectField
                label="Enter your nutritional goal(s)"
                name="goals"
                placeholder="Text input"
                options={NUTRITIONAL_GOAL_WITH_EMPTY_OPTIONS}
                selectedValues={goals}
                onChange={(e) => {
                  let values = [];
                  for (let option of e) {
                    values.push(option.value);
                  }
                  setGoals(values);
                }}
                errorText={errors && errors.goals}
                helpText=""
                isRequired={true}
                maxWidth="full"
              />

              <FormSelectField
                label="How long would you like to commit to this nutritional plan?"
                name="maxWeeks"
                placeholder="Pick"
                selectedValue={maxWeeks}
                errorText={errors && errors.maxWeeks}
                helpText=""
                onChange={(e) => setMaxWeeks(parseInt(e.target.value))}
                options={MAX_WEEK_WITH_EMPTY_OPTIONS}
                disabled={false}
              />

              <FormRadioField
                label="Has Intermittent Fasting"
                name="hasIntermittentFasting"
                placeholder="Pick"
                value={hasIntermittentFasting}
                opt1Value={1}
                opt1Label="Yes"
                opt2Value={2}
                opt2Label="No"
                errorText={errors && errors.hasIntermittentFasting}
                onChange={(e) =>
                  setHasIntermittentFasting(parseInt(e.target.value))
                }
                maxWidth="full"
                disabled={false}
              />

              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  className="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded-lg"
                  onClick={() => setShowCancelWarning(true)}
                >
                  <FontAwesomeIcon className="fas mr-2" icon={faTimesCircle} />
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-main hover:bg-darkOrange text-white px-4 py-2 rounded-lg shadow-lg transform transition-transform hover:scale-105"
                >
                  <FontAwesomeIcon className="fas mr-2" icon={faCheckCircle} />
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        
      </Layout>
    );
  }

  export default MemberNutritionPlanAdd;