import React, { useState, useEffect } from "react";
import Scroll from "react-scroll";
import { useParams } from "react-router-dom";
import { getVideoCollectionListAPI } from "../../../API/VideoCollection";
import { LoadingState } from "./components/LoadingState";
import { ErrorState } from "./components/ErrorState";
import Layout from "../../Menu/Layout";
import { currentUserState } from "../../../AppState";
import { useRecoilState } from "recoil";
import CardLayout from "./components/CardLayout";
import { faGauge, faChevronRight, faVideo, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

const CategoryDetail = () => {
	const {categoryId} = useParams();
	const [currentUser] = useRecoilState(currentUserState);
	const [category, setCategory] = useState(null);
	const [collections, setCollections] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState("");
	const [searchText, setSearchText] = useState("");
	const [actualSearchText, setActualSearchText] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");
	const [nextCursor, setNextCursor] = useState("");
	const [pageSize] = useState(24);
	const [sortBy, setSortBy] = useState("newest");
	const [viewMode, setViewMode] = useState("grid");

	// Handle successful API response with filtering
	const onVideoCollectionListSuccess = (response) => {
		if (response.results !== null) {
			// Filter collections by categoryId
			const categorizedResponse = response.results.filter(
				(collection) => collection.categoryId === categoryId
			);

			// Get category name from the first matching collection
			if (categorizedResponse.length > 0 && !category) {
				setCategory({
					name: categorizedResponse[0].categoryName,
					description: categorizedResponse[0].categoryDescription,
				});
			}

			// Update collections based on cursor
			setCollections((prev) =>
				currentCursor ? [...prev, ...categorizedResponse] : categorizedResponse
			);

			// Update next cursor only if we have filtered results
			setNextCursor(
				categorizedResponse.length > 0 && response.hasNextPage
					? response.nextCursor
					: ""
			);
		} else {
			setCollections([]);
			setNextCursor("");
		}
		setIsLoading(false);
	};

	// Handle API errors
	const onError = (error) => {
		setError(typeof error === "object" ? JSON.stringify(error) : error);
		setIsLoading(false);
	};

	// Fetch collections list
	const fetchList = (cursor, limit, keywords) => {
		setIsLoading(true);
		setError("");

		const params = new Map();
		params.set("page_size", limit);
		params.set("sort_field", sortBy === "newest" ? "created" : "popularity");
		params.set("sort_order", sortBy === "oldest" ? 1 : -1);
		if (cursor) params.set("cursor", cursor);
		if (keywords) params.set("search", keywords);

		getVideoCollectionListAPI(
			params,
			onVideoCollectionListSuccess,
			onError,
			() => setIsLoading(false)
		);
	};

	// Handle search with debouncing
	const debouncedSearch = debounce((value) => {
		setActualSearchText(value);
		setCurrentCursor(""); // Reset cursor when searching
	}, 500);

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchText(value);
		debouncedSearch(value);
	};

	// Handle load more
	const handleLoadMore = () => {
		if (nextCursor) {
			setCurrentCursor(nextCursor);
		}
	};

	// Handle sort change
	const handleSortChange = (e) => {
		setSortBy(e.target.value);
		setCurrentCursor(""); // Reset cursor when sorting changes
		setCollections([]); // Clear existing collections
	};

	// Initial fetch and subsequent fetches based on dependencies
	useEffect(() => {
		Scroll.animateScroll.scrollToTop();
		fetchList(currentCursor, pageSize, actualSearchText);
	}, [currentCursor, pageSize, actualSearchText, sortBy, categoryId]);

	if (isLoading) return <LoadingState />;
	if (error) return <ErrorState error={error} />;

	const breadcrumbItems = [
		{label: "Dashboard", link: "/dashboard", icon: faGauge},
		{label: "MMA Lessons", link: "/videos", icon: faVideo},
		{label: category?.name || "Collections", icon: faFolderOpen},
	];


  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-4">
        {collections?.length > 0 ? (
          collections?.map((collection) => (
            <CardLayout key={collection.id} item={{ ...collection, type: 'collection' }} />
          ))
        ) : (
          <CardLayout emptyStateMessage="Collections will appear here once added." />
        )}
      </div>
    </Layout>
  );
};

export default CategoryDetail;