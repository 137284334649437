import { useQuery } from "@tanstack/react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { accountState, currentUserState } from "../AppState";
import { getAccountDetailAPIUsingPromise } from "../API/Account";

export const ACCOUNT_QUERY_KEY = ['accountDetail'];

export const useAccountQuery = () => {
  const [account, setAccount] = useRecoilState(accountState);
  const currentUser = useRecoilValue(currentUserState);

  return useQuery({
    queryKey: ACCOUNT_QUERY_KEY,
    queryFn: getAccountDetailAPIUsingPromise,
    enabled: !!currentUser,
    onSuccess: (data) => {
      setAccount(data);
    },
    staleTime: 2 * 60 * 1000, // 2 minutes
    gcTime: 5 * 60 * 1000,    // 5 minutes
    refetchOnWindowFocus: true, // Enable this to keep data fresh
  });
};