import React from "react";

function FormCheckboxField({
    label,
    name,
    checked,
    errorText,
    wasValidated,
    helpText,
    onChange,
    disabled,
    className = "mb-4" // Default margin bottom
}) {
    return (
        <div className={className}>
            <div className="relative flex items-start">
                <div className="flex items-center h-5">
                    <input
                        type="checkbox"
                        id={name}
                        name={name}
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange}
                        className={`
                            h-4 w-4 rounded
                            focus:ring-2 focus:ring-offset-2
                            transition duration-150 ease-in-out
                            ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'}
                            ${errorText 
                                ? 'border-red-500 text-red-600 focus:ring-red-200' 
                                : wasValidated
                                    ? 'border-green-500 text-green-600 focus:ring-green-200'
                                    : 'border-gray-300 text-primary focus:ring-primary'
                            }
                        `}
                    />
                </div>
                <div className="ml-3 text-sm">
                    <label 
                        htmlFor={name}
                        className={`
                            font-medium
                            ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'}
                            ${errorText 
                                ? 'text-red-600' 
                                : wasValidated
                                    ? 'text-green-700'
                                    : 'text-gray-700'
                            }
                        `}
                    >
                        {label}
                    </label>
                    
                    {/* Help Text */}
                    {helpText && (
                        <p className="text-gray-500 mt-1">
                            {helpText}
                        </p>
                    )}
                    
                    {/* Error Message */}
                    {errorText && (
                        <p className="text-red-600 mt-1">
                            {errorText}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default FormCheckboxField;