import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faDumbbell,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faCogs,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {postExerciseCreateAPI} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormSelectFieldForOffer from "../../Reusable/FormSelectFieldForOffer";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_TYPE_WITH_EMPTY_OPTIONS,
	EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_TYPE_CUSTOM,
	VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";
import Layout from "../../Menu/Layout";

function AdminExerciseAdd() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [videoType, setVideoType] = useState(
		EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentName, setVideoAttachmentName] = useState("");
	const [videoAttachment, setVideoAttachment] = useState(null);
	const [type, setType] = useState(EXERCISE_TYPE_CUSTOM);
	const [thumbnailType, setThumbnailType] = useState(
		EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [description, setDescription] = useState("");
	const [alternateName, setAlternateName] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [movementType, setMovementType] = useState(0);
	const [category, setCategory] = useState(0);
	const [hasMonetization, setHasMonetization] = useState(false);
	const [offerID, setOfferID] = useState("");
	const [isOfferOther, setIsOfferOther] = useState(false);
	const [hasTimedLock, setHasTimedLock] = useState(false);
	const [timedLock, setTimedLock] = useState("");

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});
		postExerciseCreateAPI(
			{
				type: type,
				video_type: parseInt(videoType),
				video_upload: videoAttachmentID,
				video_url: videoURL,
				thumbnail_type: parseInt(thumbnailType),
				thumbnail_upload: thumbnailAttachmentID,
				thumbnail_url: thumbnailURL,
				name: name,
				alternate_name: alternateName,
				category: category,
				description: description,
				gender: gender,
				movement_type: movementType,
				has_monetization: hasMonetization,
				offer_id: offerID,
				has_timed_lock: hasTimedLock,
				timed_lock: timedLock,
			},
			onAddSuccess,
			onAddError,
			onAddDone
		);
		console.log("onSubmitClick: Finished.");
	};

	////
	//// API.
	////

	// --- Exercise Create --- //

	function onAddSuccess(response) {
		// For debugging purposes only.
		console.log("onAddSuccess: Starting...");
		console.log(response);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Exercise created");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onAddSuccess: Delayed for 2 seconds.");
			console.log(
				"onAddSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL("/admin/exercise/" + response.id + "");
	}

	function onAddError(apiErr) {
		console.log("onAddError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onAddError: Delayed for 2 seconds.");
			console.log(
				"onAddError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		console.log("onAddDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
		}

		return () => {
			mounted = false;
		};
	}, []);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Exercises", link: "/admin/exercises", icon: faDumbbell},
		{label: "Add", icon: faPlus},
	];

	const isVideoUploaded = videoAttachmentID !== "" || videoURL !== "";
	const isThumbnailUploaded =
		thumbnailAttachmentID !== "" || thumbnailURL !== "";

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-6 sm:px-6 md:px-8">
				{/* Main Card */}
				<div className="bg-white rounded-xl shadow-sm border border-gray-200 animate-fade-in">
					{/* Header */}
					<div className="p-4 sm:p-6 border-b border-gray-200">
						<div className="flex items-center justify-between">
							<h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center">
								<FontAwesomeIcon icon={faDumbbell} className="mr-3" />
								New Exercise
							</h1>
							<Link
								to="/admin/exercises"
								className="text-gray-500 hover:text-primary transition-colors duration-300">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to List
							</Link>
						</div>
					</div>

					{/* Content */}
					<div className="p-4 sm:p-6">
						<FormErrorBox errors={errors} />

						{isFetching ? (
							<PageLoadingContent displayMessage={"Please wait..."} />
						) : (
							<div className="space-y-8 mx-auto">
								{/* Monetization Section */}
								<section className="space-y-4">
									<h2 className="text-lg font-medium text-gray-900 flex items-center">
										<FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
										Monetization
									</h2>
									<div className="bg-gray-50 rounded-lg p-4 sm:p-6 space-y-4">
										<FormCheckboxField
											label="Enable Monetization"
											name="hasMonetization"
											checked={hasMonetization}
											onChange={(e) => setHasMonetization(!hasMonetization)}
											errorText={errors?.hasMonetization}
											helpText="Enable Monetization to restrict access to this exercise based on user purchases."
										/>

										{hasMonetization && (
											<div className="pl-4 sm:pl-6 border-l-2 border-gray-200 space-y-4">
												<FormSelectFieldForOffer
													label="Offer"
													offerID={offerID}
													setOfferID={setOfferID}
													isOfferOther={isOfferOther}
													setIsOfferOther={setIsOfferOther}
													errorText={errors?.offerId}
													helpText="Pick any offer that will grant access to this exercise"
													isRequired={true}
													maxWidth="100%"
												/>

												<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
													<FormCheckboxField
														label="Has Timed Lock"
														name="hasTimedLock"
														checked={hasTimedLock}
														errorText={errors?.hasTimedLock}
														onChange={(e) => setHasTimedLock(!hasTimedLock)}
														helpText="Enable artificial time lock on this video for the user."
													/>

													{hasTimedLock && (
														<FormSelectField
															label="Timed Lock Duration"
															name="timedLockDuration"
															placeholder="Pick duration"
															selectedValue={timedLock}
															errorText={errors?.timedLock}
															helpText="Duration to lock this video"
															onChange={(e) => setTimedLock(e.target.value)}
															options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
														/>
													)}
												</div>
											</div>
										)}
									</div>
								</section>

								{/* Media Section */}
								<section className="space-y-4">
									<h2 className="text-lg font-medium text-gray-900 flex items-center">
										<FontAwesomeIcon icon={faVideo} className="mr-2" />
										Media
									</h2>
									<div className="bg-gray-50 rounded-lg p-4 sm:p-6 space-y-6">
										<FormRadioField
											label="Video Type"
											name="videoType"
											placeholder="Pick"
											value={videoType}
											opt1Value={EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
											opt1Label="File Upload"
											opt2Value={EXERCISE_VIDEO_TYPE_YOUTUBE}
											opt2Label="YouTube"
											opt3Value={EXERCISE_VIDEO_TYPE_VIMEO}
											opt3Label="Vimeo"
											errorText={errors && errors.videoType}
											onChange={(e) => {
												const newType = parseInt(e.target.value);
												setVideoType(newType);
												if (
													newType ===
													EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
												) {
													setVideoURL("");
												} else {
													setVideoAttachmentID("");
													setVideoAttachmentName("");
													setVideoAttachment(null);
												}
											}}
											maxWidth="180px"
											disabled={false}
										/>

										{(() => {
											switch (videoType) {
												case EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE:
													return (
														<>
															<FormAttachmentField
																label="Video Upload"
																name="videoUpload"
																placeholder="Upload file"
																errorText={errors && errors.videoUpload}
																attachmentID={videoAttachmentID}
																setAttachmentID={setVideoAttachmentID}
																attachmentFilename={videoAttachmentName}
																setAttachmentFilename={setVideoAttachmentName}
																helpText="Upload a video file (MP4, MOV, etc.)"
																validationText="Maximum size: 500MB"
																disabled={false}
																					/>
														</>
													);
												case EXERCISE_VIDEO_TYPE_YOUTUBE:
													return (
														<FormInputField
															label="YouTube URL"
															name="videoExternalURL"
															placeholder="URL input"
															value={videoURL}
															errorText={errors && errors.videoUrl}
															helpText={`Enter the YouTube video URL`}
															onChange={(e) => {
																setVideoURL(e.target.value);
																setVideoAttachmentID("");
																setVideoAttachmentName("");
																setVideoAttachment(null);
															}}
															isRequired={true}
															maxWidth="380px"
														/>
													);
												case EXERCISE_VIDEO_TYPE_VIMEO:
													return (
														<FormInputField
															label="Vimeo URL"
															name="videoExternalURL"
															placeholder="URL input"
															value={videoURL}
															errorText={errors && errors.videoUrl}
															helpText={`Enter the Vimeo video URL`}
															onChange={(e) => {
																setVideoURL(e.target.value);
																setVideoAttachmentID("");
																setVideoAttachmentName("");
																setVideoAttachment(null);
															}}
															isRequired={true}
															maxWidth="380px"
														/>
													);
												default:
													return null;
											}
										})()}

										<FormRadioField
											label="Thumbnail Type"
											name="thumbnailType"
											placeholder="Pick"
											value={thumbnailType}
											opt1Value={EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE}
											opt1Label="File Upload"
											opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
											opt2Label="External URL"
											errorText={errors && errors.thumbnailType}
											onChange={(e) => {
												const newType = parseInt(e.target.value);
												setThumbnailType(newType);
												// Clear relevant fields when switching types
												if (
													newType ===
													EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
												) {
													setThumbnailURL("");
												} else {
													setThumbnailAttachmentID("");
													setThumbnailAttachmentName("");
												}
											}}
											maxWidth="180px"
											disabled={false}
										/>

										{(() => {
											switch (thumbnailType) {
												case EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE:
													return (
														<>
															<FormAttachmentField
																label="File Upload"
																name="thumbnaiUpload"
																placeholder="Upload file"
																errorText={errors && errors.thumbnailUpload}
																attachmentID={thumbnailAttachmentID}
																setAttachmentID={setThumbnailAttachmentID}
																attachmentFilename={thumbnailAttachmentName}
																setAttachmentFilename={setThumbnailAttachmentName}
																helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
																validationText="Maximum size: 10MB"
																disabled={false}
															/>
														</>
													);
												case EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL:
													return (
														<FormInputField
															label="Thumbnail External URL"
															name="thumbnailUrl"
															placeholder="URL input"
															value={thumbnailURL}
															errorText={errors && errors.thumbnailUrl}
															helpText="Enter the URL of the thumbnail image"
															onChange={(e) => {
																setThumbnailURL(e.target.value);
																setThumbnailAttachmentID("");
																setThumbnailAttachmentName("");
															}}
															isRequired={true}
															maxWidth="380px"
														/>
													);
												default:
													return null;
											}
										})()}

										{isVideoUploaded && isThumbnailUploaded && (
											<>
												<p class="subtitle is-6 mt-5">
													<FontAwesomeIcon className="fas" icon={faEye} />
													&nbsp;Information
												</p>
												<hr />

												<FormInputField
													label="Name"
													name="name"
													placeholder="Name input"
													value={name}
													errorText={errors && errors.name}
													helpText=""
													onChange={(e) => setName(e.target.value)}
													isRequired={true}
													maxWidth="380px"
												/>

												<FormInputField
													label="Alternate Name"
													name="alternateName"
													placeholder="Name input"
													value={alternateName}
													errorText={errors && errors.alternateName}
													helpText=""
													onChange={(e) => setAlternateName(e.target.value)}
													// isRequired={true}
													maxWidth="380px"
												/>

												<FormTextareaField
													label="Description"
													name="description"
													placeholder="Description input"
													value={description}
													errorText={errors && errors.description}
													helpText=""
													onChange={(e) => setDescription(e.target.value)}
													isRequired={true}
													maxWidth="380px"
												/>

												<FormSelectField
													label="Gender"
													name="gender"
													placeholder="Pick"
													selectedValue={gender}
													errorText={errors && errors.gender}
													helpText=""
													onChange={(e) => setGender(e.target.value)}
													options={EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION}
												/>

												<FormSelectField
													label="Movement Type"
													name="movementType"
													placeholder="Pick"
													selectedValue={movementType}
													errorText={errors && errors.movementType}
													helpText=""
													onChange={(e) =>
														setMovementType(parseInt(e.target.value))
													}
													options={
														EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION
													}
												/>

												<FormSelectField
													label="Category"
													name="category"
													placeholder="Pick"
													selectedValue={category}
													errorText={errors && errors.category}
													helpText=""
													onChange={(e) =>
														setCategory(parseInt(e.target.value))
													}
													options={EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION}
												/>

												<FormSelectField
													label="Type"
													name="type"
													placeholder="Pick"
													selectedValue={type}
													errorText={errors && errors.type}
													helpText=""
													onChange={(e) => setType(parseInt(e.target.value))}
													options={EXERCISE_TYPE_WITH_EMPTY_OPTIONS}
													disabled={true}
												/>

												<FormSelectField
													label="Movement Type"
													name="movementType"
													selectedValue={movementType}
													onChange={(e) =>
														setMovementType(parseInt(e.target.value))
													}
													options={
														EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION
													}
													errorText={errors?.movementType}
												/>
											</>
										)}
									</div>
								</section>

								{/* Submit Button */}
								<div className="flex justify-end pt-6 border-t border-gray-200">
									<button
										onClick={onSubmitClick}
										disabled={isFetching}
										className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-dark 
                                 text-white rounded-lg transition-colors duration-300 disabled:opacity-50
                                 disabled:cursor-not-allowed shadow-sm hover:shadow-md">
										<FontAwesomeIcon icon={faPlus} className="mr-2" />
										Create Exercise
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminExerciseAdd;
