import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCalendarMinus,
  faCalendarPlus,
  faTrophy,
  faCalendar,
  faGauge,
  faSearch,
  faEye,
  faPencil,
  faTrashCan,
  faPlus,
  faArrowRight,
  faTable,
  faArrowUpRightFromSquare,
  faFilter,
  faRefresh,
  faCalendarCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { PAGE_SIZE_OPTIONS } from "../../../../../Constants/FieldOptions";
import {
  RANK_POINT_METRIC_TYPE_HEART_RATE,
  RANK_POINT_METRIC_TYPE_STEP_COUNTER,
  RANK_POINT_METRIC_TYPE_CALORIES_BURNED,
  RANK_POINT_METRIC_TYPE_DISTANCE_DELTA,
} from "../../../../../Constants/App";

function MemberLeaderboardGlobalTabularListMobile(props) {
  const {
    listRank,
    setPageSize,
    pageSize,
    previousCursors,
    onPreviousClicked,
    onNextClicked,
    currentUser,
  } = props;

  return (
    <main className="main_page">
      <div className="leaderboard">
        <div className="leader_wrap">
          <Leader listRank={listRank} />
        </div>
      </div>

      <div className="players">
        <table>
          <tbody>
            {listRank &&
              listRank.results &&
              listRank.results.map(function (datum, i) {
                return (
                  <tr key={`mobile_tablet_${datum.id}`}>
                    <td>
                      <div className="player_left">
                        <div className="player_img">
                          {datum.userAvatarObjectUrl ? (
                            <figure className="figure-img is-128x128">
                              <img src={datum.userAvatarObjectUrl} alt="Player Avatar" />
                            </figure>
                          ) : (
                            <figure className="figure-img is-128x128">
                              <img src="/static/default_user.jpg" alt="Default Avatar" />
                            </figure>
                          )}
                        </div>
                        <div className="player_left_text">
                          <h2 className="text_md f_500 pb_5">
                            {datum.userFirstName}
                          </h2>
                          <p className="text_sm f_300">{`@${datum.userFirstName.toLowerCase()}`}</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="player_right">
                        <h2 className="text_md f_500 pb_5">
                          {datum.value.toFixed()}&nbsp;
                          {datum.metricType === RANK_POINT_METRIC_TYPE_HEART_RATE && <>bpm</>}
                          {datum.metricType === RANK_POINT_METRIC_TYPE_STEP_COUNTER && <>steps</>}
                          {datum.metricType === RANK_POINT_METRIC_TYPE_CALORIES_BURNED && <>kcal</>}
                          {datum.metricType === RANK_POINT_METRIC_TYPE_DISTANCE_DELTA && <>m</>}
                        </h2>
                        <img
                          src="/static/leaderboard/arrow_up.svg"
                          className="rank_arrow mt-1"
                          alt="Rank Arrow"
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="columns pt-4 is-mobile">
        <div className="column is-half">
          <span className="select">
            <select
              className="input has-text-grey-light"
              name="pageSize"
              onChange={(e) => setPageSize(parseInt(e.target.value))}
            >
              {PAGE_SIZE_OPTIONS.map(function (option, index) {
                return (
                  <option
                    key={index}
                    selected={pageSize === option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className="column is-half has-text-right">
          {previousCursors.length > 0 && (
            <button className="button" onClick={onPreviousClicked}>
              Previous
            </button>
          )}
          {listRank.hasNextPage && (
            <>
              <button className="button" onClick={onNextClicked}>
                Next
              </button>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default MemberLeaderboardGlobalTabularListMobile;

const Leader = ({ listRank }) => {
  const leaders = [];

  if (listRank && listRank.results) {
    listRank.results.forEach((datum, index) => {
      const leaderElement = (
        <div
          key={index}
          className={`column is-one-third leader ${
            // datum.place === 1 ? "first" : datum.place === 2 ? "second" : "third"
            index === 0 ? "first" : index === 1 ? "second" : index === 2 ? "third" : "none"
          }`}
        >
          <div className="leader_img_wrap">
            {datum.userAvatarObjectUrl ? (
              <figure className="figure-img is-128x128">
                <img src={datum.userAvatarObjectUrl} alt="Leader Avatar" />
              </figure>
            ) : (
              <figure className="figure-img is-128x128">
                <img src="/static/default_user.jpg" alt="Default Avatar" />
              </figure>
            )}
            {/* {datum.place === 1 && ( */
            index === 0 && (
              <img
                src="/static/leaderboard/crown.png"
                className="crown"
                alt="Crown"
              />
            )}
            <div className="badge_rotate">
              <h5 className="badge">{datum.place}</h5>
            </div>
          </div>
          <div className="leader_texts">
            <h2 className="text_md">{datum.userFirstName}</h2>
            <h4 className="text_lg">{datum.value.toFixed()}</h4>
            <p className="text_sm">@{datum.userFirstName.toLowerCase()}</p>
          </div>
        </div>
      );

      // if (datum.place === 1) {
      //   leaders[1] = leaderElement;
      // } else if (datum.place === 2) {
      //   leaders[0] = leaderElement;
      // } else if (datum.place === 3) {
      //   leaders[2] = leaderElement;
      // }

      if (index === 0) {
        leaders[1] = leaderElement;
      } else if (index === 1) {
        leaders[0] = leaderElement;
      } else if (index === 2) {
        leaders[2] = leaderElement;
      }
    });
  }

  return <div className="leader_wrap">{leaders}</div>;
};
