import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowUpRightFromSquare,
	faLegal,
	faTasks,
	faArrowLeft,
	faCheckCircle,
	faArrowRight,
	faIdCard,
	faContactCard,
	faAddressBook,
	faChartPie,
	faLock,
	faEnvelope,
	faUser,
	faPhone,
	faMapMarkerAlt,
	faGlobe,
	faCity,
	faBuilding,
	faHome,
	faMailBulk,
	faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import { useQueryClient } from '@tanstack/react-query';

import {postRegisterAPI} from "../../API/gateway";
import { ACCOUNT_QUERY_KEY } from "../../Hooks/useAccountQuery";
import { getAccountDetailAPIUsingPromise } from "../../API/Account";
import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import FormSelectField from "../Reusable/FormSelectField";
import FormCheckboxField from "../Reusable/FormCheckboxField";
import FormCountryField from "../Reusable/FormCountryField";
import FormRegionField from "../Reusable/FormRegionField";
import {HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS} from "../../Constants/FieldOptions";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import {topAlertMessageState, topAlertStatusState, currentUserState} from "../../AppState";
import FormInputPasswordField from "../Reusable/FormInputPasswordField";

function Register() {
	const queryClient = useQueryClient();
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	// Form state
	const [formData, setFormData] = useState({
		email: "",
		emailRepeated: "",
		phone: "",
		firstName: "",
		lastName: "",
		password: "",
		passwordRepeated: "",
		postalCode: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		region: "",
		country: "",
		agreePromotionsEmail: true,
		agreeTOS: false,
		howDidYouHearAboutUs: 0,
		howDidYouHearAboutUsOther: "",
		hasShippingAddress: false,
		shippingName: "",
		shippingPhone: "",
		shippingCountry: "",
		shippingRegion: "",
		shippingCity: "",
		shippingAddressLine1: "",
		shippingAddressLine2: "",
		shippingPostalCode: "",
	});

	// Component state
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [branches, setBranches] = useState([]);

	const handleInputChange = (name, value) => {
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const onSubmitClick = () => {
		setFetching(true);
		setErrors({});

		const decamelizedData = {
			organization_id: process.env.REACT_APP_API_ORGANIZATION_ID,
			first_name: formData.firstName,
			last_name: formData.lastName,
			email: formData.email,
			email_repeated: formData.emailRepeated,
			phone: formData.phone,
			postal_code: formData.postalCode,
			address_line_1: formData.addressLine1,
			address_line_2: formData.addressLine2,
			city: formData.city,
			region: formData.region,
			country: formData.country,
			status: 1,
			password: formData.password,
			password_repeated: formData.passwordRepeated,
			how_did_you_hear_about_us: formData.howDidYouHearAboutUs,
			how_did_you_hear_about_us_other: formData.howDidYouHearAboutUsOther,
			agree_promotions_email: formData.agreePromotionsEmail,
			agree_tos: formData.agreeTOS,
			has_shipping_address: formData.hasShippingAddress,
			shipping_name: formData.shippingName,
			shipping_phone: formData.shippingPhone,
			shipping_country: formData.shippingCountry,
			shipping_region: formData.shippingRegion,
			shipping_city: formData.shippingCity,
			shipping_address_line1: formData.shippingAddressLine1,
			shipping_address_line2: formData.shippingAddressLine2,
			shipping_postal_code: formData.shippingPostalCode,
		};

		postRegisterAPI(
			decamelizedData,
			onRegisterSuccess,
			onRegisterError,
			onRegisterDone
		);
	};

	const onRegisterSuccess = async (response) => {
		try {
			// Set the current user first
			if (response.user) {
			  setCurrentUser(response.user);
			  
			  // Prefetch account details
			  await queryClient.prefetchQuery({
				queryKey: ACCOUNT_QUERY_KEY,
				queryFn: getAccountDetailAPIUsingPromise,
			  });
			}
	  
			// Show success message
			setTopAlertMessage("Registration successful!");
			setTopAlertStatus("success");
			setTimeout(() => setTopAlertMessage(""), 2000);
	  
			// Redirect based on user role
			if (response.user?.role === 4) {
				if (!response.user.onboardingCompleted) {
					setForceURL("/onboarding");
				} else if (response.user.isVisitorPassHolder) {
					setForceURL("/dashboard");
				} else if (response.user.isSubscriber) {
					setForceURL("/dashboard");
				} else if (response.user.waiverVersion === undefined) {
					setForceURL("/dashboard");
			  } else {
				setForceURL("/subscriptions");
			  }
			} else {
			  setForceURL("/register-successful");
			}
		  } catch (error) {
			console.error("Error prefetching account details:", error);
			// Continue with registration flow even if prefetch fails
			setForceURL("/register-successful");
		  }
		};

	const onRegisterError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Registration failed");
		setTopAlertStatus("error");
		setTimeout(() => setTopAlertMessage(""), 2000);
	};

	const onRegisterDone = () => {
		setFetching(false);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
		}
		return () => {
			mounted = false;
		};
	}, []);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<div
			className="min-h-screen bg-cover bg-center bg-no-repeat py-12 px-4 sm:px-6 lg:px-8"
			style={{
				backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`,
			}}>
			<div className="max-w-4xl mx-auto">
				<div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-xl p-8">
					{/* Logo Section */}
					<div className="flex justify-center mb-8">
						<img
							src="/static/logo.png"
							alt="Logo"
							className="w-32 h-32 rounded-full border-4 border-primaryColor/20 
                hover:border-primaryColor/40 transition-colors duration-300"
						/>
					</div>

					{/* Title Section */}
					<h1 className="text-2xl font-bold text-gray-900 mb-8 flex items-center">
						<FontAwesomeIcon
							icon={faTasks}
							className="mr-3 text-primaryColor"
						/>
						Register
					</h1>

					<FormErrorBox errors={errors} />

					{isFetching ? (
						<PageLoadingContent displayMessage={"Please wait..."} />
					) : (
						<div className="space-y-8">
							{/* Details Section */}
							<div>
								<div className="flex items-center space-x-2 mb-6">
									<FontAwesomeIcon
										icon={faIdCard}
										className="text-primaryColor"
									/>
									<h2 className="text-lg font-semibold text-gray-700">
										Personal Details
									</h2>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<FormInputField
										label="First Name"
										name="firstName"
										placeholder="Enter your first name"
										value={formData.firstName}
										errorText={errors?.firstName}
										onChange={(e) =>
											handleInputChange("firstName", e.target.value)
										}
										isRequired={true}
										icon={faUser}
									/>

									<FormInputField
										label="Last Name"
										name="lastName"
										placeholder="Enter your last name"
										value={formData.lastName}
										errorText={errors?.lastName}
										onChange={(e) =>
											handleInputChange("lastName", e.target.value)
										}
										isRequired={true}
										icon={faUser}
									/>

									<FormInputField
										label="Email"
										name="email"
										type="email"
										placeholder="Enter your email"
										value={formData.email}
										errorText={errors?.email}
										onChange={(e) => handleInputChange("email", e.target.value)}
										isRequired={true}
										icon={faEnvelope}
									/>

									<FormInputField
										label="Email Repeated"
										name="emailRepeated"
										type="emailRepeated"
										placeholder="Confirm your email"
										value={formData.emailRepeated}
										errorText={errors && errors.emailRepeated}
										helpText="Please re-enter the above email again to confirm the email you entered is correct"
										onChange={(e) =>
											handleInputChange("emailRepeated", e.target.value)
										}
										isRequired={true}
										icon={faEnvelope}
									/>

									<FormInputPasswordField
										label="Password"
										name="password"
										type="password"
										placeholder="Enter your password"
										value={formData.password}
										errorText={errors?.password}
										onChange={(e) =>
											handleInputChange("password", e.target.value)
										}
										isRequired={true}
										icon={faLock}
									/>

									<FormInputPasswordField
										label="Confirm Password"
										name="passwordRepeated"
										type="password"
										placeholder="Confirm your password"
										value={formData.passwordRepeated}
										errorText={errors?.passwordRepeated}
										helpText="Please re-enter the your password again to confirm the password you entered is correct"
										onChange={(e) =>
											handleInputChange("passwordRepeated", e.target.value)
										}
										isRequired={true}
										icon={faLock}
									/>

									<FormInputField
										label="Phone"
										name="phone"
										placeholder="Enter your phone number"
										value={formData.phone}
										errorText={errors?.phone}
										onChange={(e) => handleInputChange("phone", e.target.value)}
										isRequired={true}
										icon={faPhone}
									/>
								</div>
							</div>

							{/* Address Section */}
							<div>
								<div className="flex items-center space-x-2 mb-6">
									<FontAwesomeIcon
										icon={faAddressBook}
										className="text-primaryColor"
									/>
									<h2 className="text-lg font-semibold text-gray-700">
										{formData.hasShippingAddress
											? "Billing Address"
											: "Address"}
									</h2>
								</div>

								<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
									{/* Billing Address */}
									<div className="space-y-4">
										<FormCountryField
											priorityOptions={["CA", "US", "MX"]}
											label="Country"
											name="country"
											selectedCountry={formData.country}
											errorText={errors?.country}
											onChange={(value) => handleInputChange("country", value)}
											isRequired={true}
											icon={faGlobe}
										/>

										<FormRegionField
											label="Province/Territory"
											name="region"
											selectedCountry={formData.country}
											selectedRegion={formData.region}
											errorText={errors?.region}
											onChange={(value) => handleInputChange("region", value)}
											isRequired={true}
											icon={faMapMarkerAlt}
										/>

										<FormInputField
											label="City"
											name="city"
											placeholder="Enter your city"
											value={formData.city}
											errorText={errors?.city}
											onChange={(e) =>
												handleInputChange("city", e.target.value)
											}
											icon={faCity}
											isRequired={true}
										/>

										<FormInputField
											label="Address Line 1"
											name="addressLine1"
											placeholder="Enter your street address"
											value={formData.addressLine1}
											errorText={errors?.addressLine1}
											onChange={(e) =>
												handleInputChange("addressLine1", e.target.value)
											}
											isRequired={true}
											icon={faHome}
										/>

										<FormInputField
											label="Address Line 2 (Optional)"
											name="addressLine2"
											placeholder="Apartment, suite, etc."
											value={formData.addressLine2}
											errorText={errors?.addressLine2}
											onChange={(e) =>
												handleInputChange("addressLine2", e.target.value)
											}
											icon={faBuilding}
										/>

										<FormInputField
											label="Postal Code"
											name="postalCode"
											placeholder="Enter postal code"
											value={formData.postalCode}
											errorText={errors?.postalCode}
											onChange={(e) =>
												handleInputChange("postalCode", e.target.value)
											}
											icon={faMailBulk}
											isRequired={true}
										/>

										<FormCheckboxField
											label="Use different shipping address"
											name="hasShippingAddress"
											checked={formData.hasShippingAddress}
											onChange={(e) =>
												handleInputChange(
													"hasShippingAddress",
													e.target.checked
												)
											}
										/>
									</div>

									{/* Shipping Address */}
									{formData.hasShippingAddress && (
										<div className="space-y-4">
											<h3 className="text-md font-medium text-gray-700 mb-4">
												Shipping Address
											</h3>

											<FormInputField
												label="Full Name"
												name="shippingName"
												placeholder="Enter recipient's full name"
												value={formData.shippingName}
												errorText={errors?.shippingName}
												onChange={(e) =>
													handleInputChange("shippingName", e.target.value)
												}
												icon={faUser}
												isRequired={true}
											/>

											<FormInputField
												label="Phone"
												name="shippingPhone"
												placeholder="Enter contact phone number"
												value={formData.shippingPhone}
												errorText={errors?.shippingPhone}
												onChange={(e) =>
													handleInputChange("shippingPhone", e.target.value)
												}
												icon={faPhone}
												isRequired={true}
											/>

											<FormCountryField
												priorityOptions={["CA", "US", "MX"]}
												label="Country"
												name="shippingCountry"
												selectedCountry={formData.shippingCountry}
												errorText={errors?.shippingCountry}
												onChange={(value) =>
													handleInputChange("shippingCountry", value)
												}
												icon={faGlobe}
												isRequired={true}
											/>

											<FormRegionField
												label="Province/Territory"
												name="shippingRegion"
												selectedCountry={formData.shippingCountry}
												selectedRegion={formData.shippingRegion}
												errorText={errors?.shippingRegion}
												onChange={(value) =>
													handleInputChange("shippingRegion", value)
												}
												isRequired={true}
												icon={faMapMarkerAlt}
											/>

											<FormInputField
												label="City"
												name="shippingCity"
												placeholder="Enter city"
												value={formData.shippingCity}
												errorText={errors?.shippingCity}
												onChange={(e) =>
													handleInputChange("shippingCity", e.target.value)
												}
												icon={faCity}
												isRequired={true}
											/>

											<FormInputField
												label="Address Line 1"
												name="shippingAddressLine1"
												placeholder="Enter street address"
												value={formData.shippingAddressLine1}
												errorText={errors?.shippingAddressLine1}
												onChange={(e) =>
													handleInputChange(
														"shippingAddressLine1",
														e.target.value
													)
												}
												icon={faHome}
												isRequired={true}
											/>

											<FormInputField
												label="Address Line 2 (Optional)"
												name="shippingAddressLine2"
												placeholder="Apartment, suite, etc."
												value={formData.shippingAddressLine2}
												errorText={errors?.shippingAddressLine2}
												onChange={(e) =>
													handleInputChange(
														"shippingAddressLine2",
														e.target.value
													)
												}
												icon={faBuilding}
											/>

											<FormInputField
												label="Postal Code"
												name="shippingPostalCode"
												placeholder="Enter postal code"
												value={formData.shippingPostalCode}
												errorText={errors?.shippingPostalCode}
												onChange={(e) =>
													handleInputChange(
														"shippingPostalCode",
														e.target.value
													)
												}
												icon={faMailBulk}
												isRequired={true}
											/>
										</div>
									)}
								</div>
							</div>

							{/* Additional Information Section */}
							<div>
								<div className="flex items-center space-x-2 mb-6">
									<FontAwesomeIcon
										icon={faChartPie}
										className="text-primaryColor"
									/>
									<h2 className="text-lg font-semibold text-gray-700">
										Additional Information
									</h2>
								</div>

								<div className="space-y-6">
									<FormSelectField
										label="How did you hear about us?"
										name="howDidYouHearAboutUs"
										placeholder="Select an option"
										selectedValue={formData.howDidYouHearAboutUs}
										errorText={errors?.howDidYouHearAboutUs}
										onChange={(e) =>
											handleInputChange(
												"howDidYouHearAboutUs",
												parseInt(e.target.value)
											)
										}
										options={HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS}
										icon={faQuestionCircle}
									/>

									{formData.howDidYouHearAboutUs === 1 && (
										<FormInputField
											label="Please specify:"
											name="howDidYouHearAboutUsOther"
											placeholder="Tell us more..."
											value={formData.howDidYouHearAboutUsOther}
											errorText={errors?.howDidYouHearAboutUsOther}
											onChange={(e) =>
												handleInputChange(
													"howDidYouHearAboutUsOther",
													e.target.value
												)
											}
											icon={faQuestionCircle}
											isRequired={true}
										/>
									)}
								</div>
							</div>

							{/* Legal Section */}
							<div>
								<div className="flex items-center space-x-2 mb-6">
									<FontAwesomeIcon
										icon={faLegal}
										className="text-primaryColor"
									/>
									<h2 className="text-lg font-semibold text-gray-700">
										Legal Agreements
									</h2>
								</div>

								<div className="space-y-4">
									<FormCheckboxField
										label="I agree to receive electronic updates"
										name="agreePromotionsEmail"
										checked={formData.agreePromotionsEmail}
										onChange={(e) =>
											handleInputChange(
												"agreePromotionsEmail",
												e.target.checked
											)
										}
										error={errors?.agreePromotionsEmail}
									/>

									<FormCheckboxField
										label={
											<span className="flex items-center space-x-1">
												<span>I agree to</span>
												<Link
													to="https://MMA_.ca/terms-and-conditions/"
													target="_blank"
													rel="noreferrer"
													className="text-primaryColor hover:text-darkOrangeShade">
													terms of service
													<FontAwesomeIcon
														icon={faArrowUpRightFromSquare}
														className="ml-1"
													/>
												</Link>
												<span>and</span>
												<Link
													to="https://MMA_.ca/privacy-policy/"
													target="_blank"
													rel="noreferrer"
													className="text-primaryColor hover:text-darkOrangeShade">
													privacy policy
													<FontAwesomeIcon
														icon={faArrowUpRightFromSquare}
														className="ml-1"
													/>
												</Link>
											</span>
										}
										name="agreeTOS"
										checked={formData.agreeTOS}
										onChange={(e) =>
											handleInputChange("agreeTOS", e.target.checked)
										}
										error={errors?.agreeTos}
									/>
								</div>
							</div>

							{/* Action Buttons */}
							<div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 pt-6">
								<Link
									to="/login"
									className="inline-flex justify-center items-center px-6 py-3 border border-gray-300 
                    shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor">
									<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
									Back
								</Link>

								<button
									onClick={onSubmitClick}
									className="inline-flex justify-center items-center px-6 py-3 border border-transparent 
                    text-base font-medium rounded-md text-white bg-primaryColor hover:bg-darkOrangeShade 
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor">
									<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
									Register
								</button>
							</div>
						</div>
					)}
				</div>

				{/* Sign In Link */}
				<div className="mt-6 text-right">
					<span className="text-lightShade">
						Already have an account?{" "}
						<Link
							to="/login"
							className="text-primaryColor hover:text-darkOrangeShade transition-colors duration-200">
							Sign in
							<FontAwesomeIcon icon={faArrowRight} className="ml-2" />
						</Link>
					</span>
				</div>

				{/* Help Section */}
				<div className="mt-8 text-center">
					<h3 className="text-lightShade font-medium mb-4">Need help?</h3>
					<div className="space-y-2">
						<div>
							<span className="text-lightShade">London: </span>
							<a
								href="mailto:info@MMA_fitness.com"
								className="text-main hover:text-main/50">
								info@MMA_fitness.com
							</a>
						</div>
						<div>
							<span className="text-lightShade">Worldwide: </span>
							<a
								href="mailto:info.tt@MMA_fitness.com"
								className="text-main hover:text-main/50">
								info.tt@MMA_fitness.com
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Register;
