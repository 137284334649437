import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faHandHolding,
	faTasks,
	faTachometer,
	faPlus,
	faTimesCircle,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faIdCard,
	faAddressBook,
	faMessage,
	faChartPie,
	faCogs,
	faEye,
	faArrowLeft,
	faSave,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {getOfferDetailAPI, putOfferUpdateAPI} from "../../../API/Offer";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputField from "../../Reusable/FormInputField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowCheckbox from "../../Reusable/DataDisplayRowCheckbox";
import DataDisplayRowSelect from "../../Reusable/DataDisplayRowSelect";
import {
	OFFER_PAY_FREQUENCY_WITH_EMPTY_OPTIONS,
	BUSINESS_FUNCTION_WITH_EMPTY_OPTIONS,
	OFFER_MEMBERSHIP_RANK_WITH_EMPTY_OPTIONS,
	OFFER_STATUS_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminOfferUpdate() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [datum, setDatum] = useState({});
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [businessFunction, setBusinessFunction] = useState(0);
	const [status, setStatus] = useState(0);
	const [showCancelWarning, setShowCancelWarning] = useState(false);
	const [membershipRank, setMembershipRank] = useState(0);

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		e.preventDefault();
		setFetching(true);
		setErrors({});

		const data = {
			id: id,
			business_function: parseInt(businessFunction),
			membership_rank: parseInt(membershipRank),
			status: status,
		};

		putOfferUpdateAPI(
			data,
			onAdminOfferUpdateSuccess,
			onAdminOfferUpdateError,
			onAdminOfferUpdateDone
		);
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onOfferDetailSuccess(response) {
		console.log("onOfferDetailSuccess: Starting...");
		setBusinessFunction(response.businessFunction);
		setMembershipRank(response.membershipRank);
		setStatus(response.status);
		setDatum(response);
	}

	function onOfferDetailError(apiErr) {
		console.log("onOfferDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onOfferDetailDone() {
		console.log("onOfferDetailDone: Starting...");
		setFetching(false);
	}

	// --- Update --- //

	function onAdminOfferUpdateSuccess(response) {
		// For debugging purposes only.
		console.log("onAdminOfferUpdateSuccess: Starting...");
		console.log(response);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Offer updated successfully");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onAdminOfferUpdateSuccess: Delayed for 2 seconds.");
			console.log(
				"onAdminOfferUpdateSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the user to a new page.
		setForceURL("/admin/offer/" + response.id);
	}

	function onAdminOfferUpdateError(apiErr) {
		console.log("onAdminOfferUpdateError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onAdminOfferUpdateError: Delayed for 2 seconds.");
			console.log(
				"onAdminOfferUpdateError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAdminOfferUpdateDone() {
		console.log("onAdminOfferUpdateDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			getOfferDetailAPI(
				id,
				onOfferDetailSuccess,
				onOfferDetailError,
				onOfferDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Offers", link: "/admin/offers", icon: faHandHolding},
		{label: "Update", icon: faPencil},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 sm:px-6 lg:px-8">
				<div className="bg-white rounded-xl shadow-lg overflow-hidden">
					{/* Header */}
					<div className="bg-gradient-to-r from-primary to-primary-dark p-6 text-white">
						<h1 className="text-2xl font-semibold flex items-center">
							<FontAwesomeIcon icon={faSave} className="mr-3" />
							Update Offer
						</h1>
					</div>

					<div className="p-6 space-y-6">
						<FormErrorBox errors={errors} />

						<form onSubmit={onSubmitClick} className="space-y-8">
							{/* Read-only Information Section */}
							<div className="bg-gray-50 rounded-lg p-6 space-y-6">
								<h3 className="text-lg font-medium text-gray-900 mb-4">
									Offer Details
								</h3>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<DataDisplayRowText
										label="Name"
										value={datum.name}
										className="bg-white rounded-lg shadow-sm p-4"
									/>

									<DataDisplayRowText
										label="Description"
										value={datum.description}
										className="bg-white rounded-lg shadow-sm p-4"
									/>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<DataDisplayRowText
										label="Price"
										value={datum.price}
										className="bg-white rounded-lg shadow-sm p-4"
									/>

									<DataDisplayRowText
										label="Price Currency"
										value={datum.priceCurrency}
										className="bg-white rounded-lg shadow-sm p-4"
									/>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<DataDisplayRowCheckbox
										label="Is Subscription?"
										checked={datum.isSubscription}
										className="bg-white rounded-lg shadow-sm p-4"
									/>

									<DataDisplayRowSelect
										label="Payment Frequency"
										selectedValue={datum.payFrequency}
										options={OFFER_PAY_FREQUENCY_WITH_EMPTY_OPTIONS}
										className="bg-white rounded-lg shadow-sm p-4"
									/>
								</div>
							</div>

							{/* Editable Fields Section */}
							<div className="bg-white rounded-lg p-6 shadow-sm space-y-6">
								<h3 className="text-lg font-medium text-gray-900 mb-4">
									Update Settings
								</h3>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
									<FormSelectField
										label="Business Function"
										name="businessFunction"
										placeholder="Pick the business function"
										selectedValue={businessFunction}
										options={BUSINESS_FUNCTION_WITH_EMPTY_OPTIONS}
										onChange={(e) => setBusinessFunction(e.target.value)}
										errorText={errors && errors.businessFunction}
										disabled={isFetching}
										className="bg-gray-50 focus-within:bg-white transition-colors duration-200"
									/>

									<FormSelectField
										label="Membership Rank"
										name="membershipRank"
										placeholder="Pick the membership rank"
										selectedValue={membershipRank}
										options={OFFER_MEMBERSHIP_RANK_WITH_EMPTY_OPTIONS}
										onChange={(e) => setMembershipRank(e.target.value)}
										errorText={errors && errors.membershipRank}
										disabled={isFetching}
										className="bg-gray-50 focus-within:bg-white transition-colors duration-200"
									/>
								</div>

								<FormSelectField
									label="Status"
									name="status"
									placeholder="Pick the status"
									selectedValue={status}
									options={OFFER_STATUS_WITH_EMPTY_OPTIONS}
									onChange={(e) => setStatus(e.target.value)}
									errorText={errors && errors.status}
									disabled={isFetching}
									className="bg-gray-50 focus-within:bg-white transition-colors duration-200"
								/>

								{/* Form Actions */}
								<div className="flex justify-between items-center pt-6 mt-8 border-t border-gray-200">
									<Link
										to="/admin/offers"
										className="inline-flex items-center px-4 py-2 border border-gray-300 
                 text-sm font-medium rounded-lg bg-white text-gray-700 
                 hover:bg-gray-50 hover:border-primary hover:text-primary
                 focus:outline-none focus:ring-2 focus:ring-offset-2 
                 focus:ring-primary transition-all duration-300
                 shadow-sm hover:shadow">
										<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
										Back to Offers
									</Link>

									<div className="flex space-x-3">
										<button
											type="button"
											onClick={() => setShowCancelWarning(true)}
											className="inline-flex items-center px-4 py-2 border border-gray-300 
                   text-sm font-medium rounded-lg bg-white text-gray-700 
                   hover:bg-red-50 hover:border-red-500 hover:text-red-600
                   focus:outline-none focus:ring-2 focus:ring-offset-2 
                   focus:ring-red-500 transition-all duration-300">
											Cancel
										</button>

										<button
											type="submit"
											disabled={isFetching}
											className="inline-flex items-center px-6 py-2 border border-transparent 
                   text-sm font-medium rounded-lg text-white bg-primary 
                   hover:bg-primary-dark focus:outline-none focus:ring-2 
                   focus:ring-offset-2 focus:ring-primary transition-all duration-300
                   disabled:opacity-50 disabled:cursor-not-allowed
                   shadow-sm hover:shadow">
											<FontAwesomeIcon icon={faSave} className="mr-2" />
											{isFetching ? "Updating..." : "Update Offer"}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>

			{/* Cancel Warning Modal */}
			<Modal
				isOpen={showCancelWarning}
				onClose={() => setShowCancelWarning(false)}
				header={
					<div className="flex items-center text-red-600">
						<FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
						<span>Confirm Cancel</span>
					</div>
				}>
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to cancel? All changes will be lost.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowCancelWarning(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white 
                       border border-gray-300 rounded-lg hover:bg-gray-50 
                       transition-colors duration-300">
							No, Stay
						</button>
						<Link
							to="/admin/offers"
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                       rounded-lg hover:bg-red-700 transition-colors duration-300">
							Yes, Cancel
						</Link>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default AdminOfferUpdate;
