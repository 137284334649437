import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCreditCard, 
    faImage, 
    faArrowLeft,
    faChevronRight 
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';

import { getAccountDetailAPI } from "../../../API/Account";
import { 
    topAlertMessageState, 
    topAlertStatusState, 
    currentUserState 
} from "../../../AppState";

const LaunchpadCard = ({ title, subtitle, icon, url, bgColor = "bg-white" }) => (
    <Link 
        to={url} 
        className={`group flex items-center justify-between ${bgColor} 
                    rounded-xl border border-gray-200 p-6 transition-all 
                    duration-200 hover:border-blue-500 hover:shadow-md`}
    >
        <div className="flex items-center space-x-4">
            <div className="flex h-12 w-12 items-center justify-center rounded-full 
                          bg-blue-50 text-blue-600 transition-colors duration-200 
                          group-hover:bg-blue-600 group-hover:text-white">
                <FontAwesomeIcon 
                    icon={icon} 
                    className="text-xl"
                    aria-hidden="true"
                />
            </div>
            <div>
                <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                <p className="text-sm text-gray-600">{subtitle}</p>
            </div>
        </div>
        <FontAwesomeIcon 
            icon={faChevronRight} 
            className="text-gray-400 transition-transform duration-200 
                      group-hover:translate-x-1 group-hover:text-blue-600"
        />
    </Link>
);

function AccountMoreLaunchpad() {
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);
            setFetching(true);
            setErrors({});
            getAccountDetailAPI(
                response => setCurrentUser(response),
                error => {
                    setErrors(error);
                    Scroll.animateScroll.scrollToTop();
                },
                () => setFetching(false)
            );
        }

        return () => { mounted = false; }
    }, []);

    if (forceURL) return <Navigate to={forceURL} />;

    return (
        <div className="min-h-screen">
            <div className="mx-auto max-w-3xl px-4 py-12 sm:px-6 lg:px-8">

                <div className="space-y-4">
                    {currentUser.status === 1 && (
                        <LaunchpadCard
                            title="Profile Photo"
                            subtitle="Upload or update your profile picture"
                            icon={faImage}
                            url="/account/more/avatar"
                        />
                    )}
                    
                    {currentUser.status === 1 && !currentUser.isSubscriber && (
                        <LaunchpadCard
                            title="Subscription"
                            subtitle="View all membership options"
                            icon={faCreditCard}
                            url="/account/more/subscribe"
                        />
                    )}
                </div>

                {isFetching && (
                    <div className="mt-8 flex justify-center">
                        <div className="h-8 w-8 animate-spin rounded-full border-2 
                                    border-blue-600 border-t-transparent" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default AccountMoreLaunchpad;