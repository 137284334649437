import React, {useState, useEffect} from "react";
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faRankingStar,
	faArrowLeft,
	faGauge,
	faRefresh,
	faEye,
	faBolt,
	faChartLine,
	faTrophy,
	faMedal,
	faSearch,
	faUser,
	faPlus,
	faEdit,
	faTrash,
	faUndo,
	faFilter,
	faRunning,
	faWalking,
	faSwimmer,
	faBiking,
	faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Scroll from "react-scroll";

import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";
import FormDateTimeField from "../../Reusable/FormDateTimeField";
import FormInputField from "../../Reusable/FormInputField";
import {getfitnessChallengeDetailAPI} from "../../../API/FitnessChallenge";
import {
	getfitnessChallengePointListApi,
	postfitnessChallengePointCreateAPI,
	putfitnessChallengePointUpdateAPI,
} from "../../../API/FitnessChallengePoints";

import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	dataPointFilterShowState,
	dataPointFilterTemporarySearchTextState,
	dataPointFilterActualSearchTextState,
	dataPointFilterSortState,
	dataPointFilterStatusState,
} from "../../../AppState";
import FormRadioField from "../../Reusable/FormRadioField";
import {SORT_OPTIONS} from "../../../Constants/FieldOptions";

function UserPointsHistory() {
	const {id} = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	// Global State
	const [currentUser] = useRecoilState(currentUserState);
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [showFilter, setShowFilter] = useRecoilState(dataPointFilterShowState);
	const [sort, setSort] = useRecoilState(dataPointFilterSortState);
	const [status, setStatus] = useRecoilState(dataPointFilterStatusState);

	// Local State
	const [pointsHistory, setPointsHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [errors, setErrors] = useState({});
	const [showAddModal, setShowAddModal] = useState(false);
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [day, setDay] = useState(null);
	const [newPoint, setNewPoint] = useState("");
	const [activity, setActivity] = useState("");
	const [notes, setNotes] = useState("");
	const [datum, setDatum] = useState({});
	const [challenge, setChallenge] = useState({});
	const [selectedPoint, setSelectedPoint] = useState({});
	const [updateData, setUpdateData] = useState({});

	// Filter State
	const [filterState, setFilterState] = useState({
		startDate: null,
		endDate: null,
		activity: "all",
		sortBy: "date,desc",
	});

	// Pagination State
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

	// Helper Functions
	const getActivityStyle = (activity) => {
		const styles = {
			running: "bg-green-100 text-green-800 border border-green-200",
			walking: "bg-yellow-100 text-yellow-800 border border-yellow-200",
			swimming: "bg-blue-100 text-blue-800 border border-blue-200",
			cycling: "bg-purple-100 text-purple-800 border border-purple-200",
			default: "bg-gray-100 text-gray-800 border border-gray-200",
		};
		return styles[activity?.toLowerCase()] || styles.default;
	};

	const getActivityIcon = (activity) => {
		const icons = {
			running: faRunning,
			walking: faWalking,
			swimming: faSwimmer,
			cycling: faBiking,
			default: faDumbbell,
		};
		return icons[activity?.toLowerCase()] || icons.default;
	};

	// Date Utility Functions
	const addWeeksToDate = (date, weeks) => {
		const result = new Date(date);
		result.setDate(result.getDate() + weeks * 7);
		return result;
	};

	const isWithinDateRange = (date, minDate, maxDate) => {
		const checkDate = new Date(date);
		return checkDate >= minDate && checkDate <= maxDate;
	};

	// Tab Items Configuration
	const tabItems = [
		{
			id: "details",
			label: "Detail",
			icon: faEye,
			to: `/fitness-challenge/${id}`,
			show: true,
		},
		{
			id: "points",
			label: "Points",
			icon: faChartLine,
			to: `/fitness-challenge/${id}/points`,
			show: true,
		},
		{
			id: "leaderboard",
			label: "Leaderboard",
			icon: faTrophy,
			to: `/fitness-challenge/${id}/leaderboard`,
			show: true,
		},
	];

	// API Handlers
	const fetchList = (cur, limit, sbv) => {
		setIsLoading(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		params.set("user_id", currentUser.id);
		params.set("challenge_id", id);

		// Handle sorting
		if (sbv && sbv !== "") {
			const [field, order] = sbv.split(",");
			params.set("sort_field", field);
			params.set("sort_order", order === "desc" ? -1 : 1);
		} else {
			// Default to highest points
			params.set("sort_field", "value");
			params.set("sort_order", -1);
		}

		if (cur && cur !== "") {
			params.set("cursor", cur);
		}

		getfitnessChallengePointListApi(
			params,
			onListSuccess,
			onListError,
			onListDone
		);
	};

	function onListSuccess(response) {
		if (response.results !== null && response.results.length > 0) {
			setDatum(response);
			setPointsHistory(response.results);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setDatum({});
			setPointsHistory([]);
			setNextCursor("");
		}
	}

	function onListError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onListDone() {
		setIsLoading(false);
	}

	// Challenge Detail Handlers
	function onDetailSuccess(response) {
		setChallenge(response);
		setFilterState((prev) => ({
			...prev,
			startDate: new Date(response.startTime),
			endDate: addWeeksToDate(response.startTime, response.durationInWeeks),
		}));
	}

	function onDetailError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		setIsLoading(false);
	}

	// Add Point Handlers
	const onSubmitClick = (e) => {
		e.preventDefault();
		setIsLoading(true);
		let payload = {
			user_id: currentUser.id,
			challenge_id: id,
			day: day,
			value: parseInt(newPoint),
			activity: activity,
			notes: notes,
			organization_id: currentUser.organizationId,
		};

		postfitnessChallengePointCreateAPI(
			payload,
			onAddSuccess,
			onAddError,
			onAddDone
		);
	};

	function onAddSuccess(response) {
		setTopAlertMessage("Challenge point added successfully");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	function onAddError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed to add point");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		setIsLoading(false);
		setShowAddModal(false);
		fetchList(currentCursor, pageSize, sort);
		setDay(null);
		setNewPoint("");
		setActivity("");
		setNotes("");
	}
	
	// Update Point Handlers
	const onUpdate = (isDelete = false) => {
		setIsLoading(true);
		setShowUpdateModal(false);
		setShowDeleteModal(false);
	
		// Always include all fields regardless of operation type
		const payload = {
			id: id,                            // Challenge ID
			point_id: selectedPoint.id,        // Point ID
			user_id: currentUser.id,           // User ID
			challenge_id: id,                  // Challenge ID again (if needed by API)
			day: isDelete ? selectedPoint.date : (updateData.day || selectedPoint.date),
			value: isDelete 
				? parseInt(selectedPoint.value || 0)
				: parseInt(updateData.points || selectedPoint.value || 0),
			activity: isDelete 
				? (selectedPoint.activity || '')
				: (updateData.activity || selectedPoint.activity || ''),
			notes: isDelete 
				? (selectedPoint.notes || '')
				: (updateData.notes || selectedPoint.notes || ''),
			delete: isDelete,
			organization_id: currentUser.organizationId
		};

		putfitnessChallengePointUpdateAPI(
			id,
			payload,
			onUpdateSuccess,
			onUpdateError,
			onUpdateDone
		);
	};

	function onUpdateSuccess() {
		setTopAlertMessage(
			selectedPoint.delete
				? "Point deleted successfully"
				: "Point updated successfully"
		);
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	function onUpdateError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed to update point");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onUpdateDone() {
		setSelectedPoint({});
		setUpdateData({});
		setErrors({});
		setIsLoading(false);
		fetchList(currentCursor, pageSize, sort);
	}

	// Pagination Handlers
	const onNextClicked = () => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
		fetchList(nextCursor, pageSize, sort);
	};

	const onPreviousClicked = () => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
		fetchList(previousCursor, pageSize, sort);
	};

	// Effects
	useEffect(() => {
		let mounted = true;

		const initializeData = async () => {
			if (!datum.id) {
				await getfitnessChallengeDetailAPI(
					id,
					onDetailSuccess,
					onDetailError,
					onDetailDone
				);
				Scroll.animateScroll.scrollToTop();
			}
			if (mounted) {
				fetchList(currentCursor, pageSize, sort);
			}
		};

		initializeData();

		return () => {
			mounted = false;
		};
	}, [id, currentCursor, pageSize, sort]);

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Challenge",
			icon: faBolt,
			link: "/fitness-challenge",
		},
		{
			label: "Points",
			icon: faChartLine,
		},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<ListWithFilters
				title={
					<div className="bg-gradient-to-r from-primary/95 to-primary-dark/95 rounded-xl shadow-lg px-6 py-5 mb-6">
						<div className="flex items-center justify-between">
							<div className="flex items-center space-x-3">
								<div className="p-2 bg-white/10 rounded-lg">
									<FontAwesomeIcon
										icon={faChartLine}
										className="text-white h-5 w-5"
									/>
								</div>
								<h1 className="text-2xl font-bold text-white">
									Points History
								</h1>
							</div>
							<div className="flex space-x-3">
								<button
									onClick={() => setShowAddModal(true)}
									className="px-4 py-2 text-sm font-medium text-white bg-white/10 
						hover:bg-white/20 rounded-lg transition-colors duration-150 
						flex items-center space-x-2">
									<FontAwesomeIcon icon={faPlus} />
									<span className="hidden sm:inline">Add Points</span>
								</button>
								<button
									onClick={() => fetchList(currentCursor, pageSize, sort)}
									className="px-4 py-2 text-sm font-medium text-white bg-white/10 
						hover:bg-white/20 rounded-lg transition-colors duration-150 
						flex items-center space-x-2">
									<FontAwesomeIcon icon={faRefresh} />
									<span className="hidden sm:inline">Refresh</span>
								</button>
							</div>
						</div>
					</div>
				}
				loading={isLoading}
				error={typeof errors === "string" ? errors : null}
				filterContent={
					<FilterSidebar>
						{/* Sort Options */}
						<FilterSection title="Sort By">
							<div className="space-y-2">
								{SORT_OPTIONS.map((sortOption) => (
									<label key={sortOption.value} className="flex items-center">
										<input
											type="radio"
											name="sortBy"
											value={sortOption.value}
											checked={sort === sortOption.value}
											onChange={(e) => {
												const newSort = e.target.value;
												setSort(newSort);
												// Immediately apply the sort
												fetchList(currentCursor, pageSize, newSort);
											}}
											className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
										/>
										<span className="ml-2 text-sm text-gray-700">
											{sortOption.label}
										</span>
									</label>
								))}
							</div>
						</FilterSection>

						{/* Filter Actions */}
						<div className="mt-6 flex flex-col gap-3">
							<button
								onClick={() => {
									// Reset to default values - highest first
									const defaultSort = "value,desc";
									setSort(defaultSort);
									fetchList("", pageSize, defaultSort);
								}}
								className="w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
			border-gray-300 rounded-md hover:bg-gray-50 transition-colors duration-150 
			flex items-center justify-center space-x-2">
								<FontAwesomeIcon icon={faUndo} className="h-4 w-4" />
								<span>Reset Filters</span>
							</button>
							<button
								onClick={() => {
									setShowFilter(false);
									fetchList(currentCursor, pageSize, sort);
								}}
								className="w-full px-4 py-2 text-sm font-medium text-white bg-primary 
			hover:bg-primary-dark rounded-md transition-colors duration-150 
			flex items-center justify-center space-x-2">
								<FontAwesomeIcon icon={faFilter} className="h-4 w-4" />
								<span>Apply Filters</span>
							</button>
						</div>
					</FilterSidebar>
				}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={!!nextCursor}>
				{/* Enhanced Table Component */}
				{/* Tab Navigation */}
				<div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden mb-6">
					<div className="border-b border-gray-200">
						<nav className="flex space-x-8 px-6" aria-label="Tabs">
							{tabItems.map((tab) => (
								<Link
									key={tab.id}
									to={tab.to}
									className={`
						group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
						${
							location.pathname === tab.to
								? "border-primary text-primary"
								: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
						}
					  `}>
									<FontAwesomeIcon
										icon={tab.icon}
										className={`mr-2 h-4 w-4 ${
											location.pathname === tab.to
												? "text-primary"
												: "text-gray-400 group-hover:text-gray-500"
										}`}
									/>
									{tab.label}
								</Link>
							))}
						</nav>
					</div>

					{/* Points Table */}
					<div className="bg-white rounded-lg shadow-md overflow-hidden">
						{/* Table Container */}
						<div className="overflow-x-auto">
							<table className="min-w-full divide-y divide-gray-200">
								{/* Table Header */}
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
											Date
										</th>
										<th
											scope="col"
											className="px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
											Points
										</th>
										<th
											scope="col"
											className="hidden md:table-cell px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
											Activity
										</th>
										<th
											scope="col"
											className="hidden lg:table-cell px-6 py-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
											Notes
										</th>
										<th
											scope="col"
											className="px-6 py-4 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
											Actions
										</th>
									</tr>
								</thead>

								{/* Table Body */}
								<tbody className="bg-white divide-y divide-gray-200">
									{!pointsHistory ||
									!pointsHistory.length ||
									!pointsHistory.some((history) => history.points?.length) ? (
										<tr>
											<td colSpan="5" className="px-6 py-8 text-center">
												<div className="flex flex-col items-center justify-center space-y-2">
													<FontAwesomeIcon
														icon={faChartLine}
														className="h-8 w-8 text-gray-400"
													/>
													<p className="text-sm font-medium text-gray-500">
														No points history found
													</p>
													<p className="text-xs text-gray-400">
														Add points to see them here
													</p>
												</div>
											</td>
										</tr>
									) : (
										pointsHistory.map(
											(history) =>
												history.points &&
												history.points.map((point) => (
													<tr
														key={point.id}
														className="hover:bg-gray-50 transition-all duration-150">
														{/* Date Column */}
														<td className="px-6 py-4 whitespace-nowrap">
															<div className="flex flex-col">
																<span className="text-sm font-medium text-gray-900">
																	{point.date
																		? new Date(point.date).toLocaleDateString(
																				"en-US",
																				{
																					month: "short",
																					day: "numeric",
																					year: "numeric",
																				}
																		  )
																		: ""}
																</span>
																<span className="text-xs text-gray-500">
																	{point.date
																		? new Date(point.date).toLocaleTimeString(
																				"en-US",
																				{
																					hour: "numeric",
																					minute: "numeric",
																				}
																		  )
																		: ""}
																</span>
															</div>
														</td>

														{/* Points Column */}
														<td className="px-6 py-4 whitespace-nowrap">
															<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
																{point.value || "0"} pts
															</span>
														</td>

														{/* Activity Column - Hidden on mobile */}
														<td className="hidden md:table-cell px-6 py-4 whitespace-nowrap">
															<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
																{point.activity || "N/A"}
															</span>
														</td>

														{/* Notes Column - Hidden on mobile and tablet */}
														<td className="hidden lg:table-cell px-6 py-4">
															<p className="text-sm text-gray-500 truncate max-w-xs">
																{point.notes || "No notes"}
															</p>
														</td>

														{/* Actions Column */}
														<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
															<div className="flex space-x-3">
																<button
																	onClick={() => {
																		setSelectedPoint(point);
																	setUpdateData({
																		day: new Date(point.date),
																		points: point.value,
																		activity: point.activity,
																		notes: point.notes,
																	});
																		setShowUpdateModal(true);
																	}}
																	className="text-blue-600 hover:text-blue-900 transition-colors duration-150"
																	title="Edit">
																	<FontAwesomeIcon
																		icon={faEdit}
																		className="h-4 w-4"
																	/>
																</button>
																<button
																	onClick={() => {
																		setSelectedPoint({
																			...point,
																			id: point.id,
																			date: point.date,
																			value: point.value || 0,
																			notes: point.notes || '',
																			activity: point.activity || '',
																			delete: true,
																		});
																		setShowDeleteModal(true);
																	}}
																	className="text-red-600 hover:text-red-900 transition-colors duration-150"
																	title="Delete">
																	<FontAwesomeIcon
																		icon={faTrash}
																		className="h-4 w-4"
																	/>
																</button>
															</div>
														</td>
													</tr>
												))
										)
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</ListWithFilters>

			{/* Add Modal */}
			<Modal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				header="Add Points">
				<div className="space-y-6">
					<FormDateTimeField
						label="Date"
						helpText="Select the date for your points entry"
						onChange={setDay}
						value={day}
						placeholder="Select date"
						maxWidth="full"
						name="day"
						errorText={errors?.day}
					/>
					<FormInputField
						label="Points"
						helpText="Enter the points you achieved"
						value={newPoint}
						onChange={(e) => setNewPoint(e.target.value)}
						placeholder="Enter points"
						type="number"
						min="0"
					/>
					<FormInputField
						label="Activity"
						helpText="What activity did you do?"
						value={activity}
						onChange={(e) => setActivity(e.target.value)}
						placeholder="e.g., Running, Walking"
						type="text"
					/>
					<FormInputField
						label="Notes"
						helpText="Any additional details (optional)"
						value={notes}
						onChange={(e) => setNotes(e.target.value)}
						placeholder="Add notes"
						type="text"
					/>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowAddModal(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={onSubmitClick}
							disabled={!day || !newPoint}
							className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md disabled:opacity-50">
							Add Points
						</button>
					</div>
				</div>
			</Modal>

			{/* Update Modal */}
			<Modal
				isOpen={showUpdateModal}
				onClose={() => setShowUpdateModal(false)}
				header="Edit Points">
				<div className="space-y-6">
					<FormDateTimeField
						label="Date"
						helpText="Select the date for your points entry"
						onChange={(date) => setUpdateData({...updateData, day: date})}
						value={updateData.day}
						placeholder="Select date"
						maxWidth="full"
						name="day"
						errorText={errors?.day}
					/>
					<FormInputField
						label="Points"
						helpText="Enter the points you achieved"
						value={updateData.points}
						onChange={(e) =>
							setUpdateData({...updateData, points: e.target.value})
						}
						placeholder="Enter points"
						type="number"
						min="0"
					/>
					<FormInputField
						label="Activity"
						helpText="What activity did you do?"
						value={updateData.activity}
						onChange={(e) =>
							setUpdateData({...updateData, activity: e.target.value})
						}
						placeholder="e.g., Running, Walking"
						type="text"
					/>
					<FormInputField
						label="Notes"
						helpText="Any additional details (optional)"
						value={updateData.notes}
						onChange={(e) =>
							setUpdateData({...updateData, notes: e.target.value})
						}
						placeholder="Add notes"
						type="text"
					/>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowUpdateModal(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={() => onUpdate(false)}
							disabled={!updateData.day || !updateData.points}
							className="px-4 py-2 text-sm font-medium text-white bg-primary hover:bg-primary-dark rounded-md disabled:opacity-50">
							Update Points
						</button>
					</div>
				</div>
			</Modal>

			{/* Delete Modal */}
			<Modal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				header="Confirm Deletion">
				<div className="space-y-6">
					<p className="text-gray-600">
						Are you sure you want to delete this point entry? This action cannot
						be undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowDeleteModal(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={() => onUpdate(true)}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md">
							Delete
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default UserPointsHistory;
