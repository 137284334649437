import React from "react";
import ExerciseDisplay from "./ExerciseDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCalendarWeek, 
  faDumbbell, 
  faCircleInfo 
} from "@fortawesome/free-solid-svg-icons";

function FitnessPlanDisplay({ weeklyFitnessPlans, label, helpText }) {
  return (
    <div className="space-y-4">
      {/* Label Section */}
      {label && (
        <div className="flex items-center text-gray-700 font-medium mb-2">
          <FontAwesomeIcon icon={faDumbbell} className="mr-2 text-primary" />
          <span>{label}</span>
        </div>
      )}

      {/* Weekly Plans */}
      <div className="space-y-6">
        {weeklyFitnessPlans.map((weeklyPlan, index) => (
          <div 
            key={index} 
            className="bg-white rounded-xl border border-gray-200 shadow-sm 
                     overflow-hidden transition-all duration-300 
                     hover:shadow-md hover:border-primary/20"
          >
            {/* Weekly Plan Header */}
            <div className="px-6 py-4 border-b border-gray-100 bg-gray-50">
              <div className="flex items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-900 flex items-center">
                  <FontAwesomeIcon 
                    icon={faCalendarWeek} 
                    className="mr-2 text-primary" 
                  />
                  {weeklyPlan.title}
                </h2>
              </div>
            </div>

            {/* Daily Plans */}
            <div className="divide-y divide-gray-100">
              {weeklyPlan.dailyPlans?.map((dailyPlan, dailyIndex) => (
                <div 
                  key={dailyIndex} 
                  className="px-6 py-4 transition-colors duration-300 
                           hover:bg-gray-50"
                >
                  {/* Daily Plan Header */}
                  <h3 className="text-base font-medium text-gray-900 mb-2 
                               flex items-center">
                    <FontAwesomeIcon 
                      icon={faDumbbell} 
                      className="mr-2 text-primary/70 w-4 h-4" 
                    />
                    {dailyPlan.title}
                  </h3>

                  {/* Instructions */}
                  {dailyPlan.instructions && (
                    <p className="text-sm text-gray-600 mb-3 pl-6">
                      {dailyPlan.instructions}
                    </p>
                  )}

                  {/* Exercise Details */}
                  {dailyPlan.planDetails && dailyPlan.planDetails.length > 0 && (
                    <div className="pl-6 animate-fadeIn">
                      <ExerciseDisplay exercises={dailyPlan.planDetails} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Help Text */}
      {helpText && (
        <div className="flex items-start mt-4 text-sm text-gray-500">
          <FontAwesomeIcon 
            icon={faCircleInfo} 
            className="mr-2 mt-0.5 text-primary/70" 
          />
          <p className="help">{helpText}</p>
        </div>
      )}
    </div>
  );
}

export default FitnessPlanDisplay;