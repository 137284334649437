import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faGauge,
  faEye,
  faQuestionCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { ListHeader } from "../../Reusable/AdminList";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
} from "../../../AppState";
import { postQuestionnaireCreateAPI } from "../../../API/questionnaire";
import Layout from "../../Menu/Layout";

function AdminQuestionnaireAdd() {
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");

  const [title, setTitle] = useState("");
  const [subtitle, setSubTitle] = useState("");
  const [isMultiselect, setIsMultiselect] = useState(false);
  const [status, setStatus] = useState(true);
  const [options, setOptions] = useState([]);

  const onSubmitClick = (e) => {
    setFetching(true);
    setErrors({});
    postQuestionnaireCreateAPI(
      {
        title: title,
        subtitle: subtitle,
        is_multiselect: isMultiselect,
        status: status,
        options: options,
      },
      onAddSuccess,
      onAddError,
      onAddDone
    );
  };

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("question  created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/admin/questions");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Questions", link: "/admin/questions", icon: faQuestionCircle },
    { label: "Add", icon: faPlus },
  ];

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen bg-gray-50/50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200">
          <div className="p-4 sm:p-6 border-b border-gray-200">
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon icon={faPlus} className="mr-3" />
              Add Question
            </h1>
          </div>

          <FormErrorBox errors={errors} />

          <div className="p-4 sm:p-6">
            <p className="text-gray-600 mb-6">
              Please fill out all the required fields before submitting this form.
            </p>

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <div className="space-y-6">
                <div className="border-b border-gray-200 pb-6">
                  <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                    Information
                  </h2>

                  <div className="space-y-4">
                    <FormTextareaField
                      label="Title"
                      name="title"
                      placeholder="Enter title"
                      value={title}
                      errorText={errors?.title}
                      onChange={(e) => setTitle(e.target.value)}
                      isRequired={true}
                    />

                    <FormTextareaField
                      label="Subtitle (Optional)"
                      name="subtitle"
                      placeholder="Enter subtitle"
                      value={subtitle}
                      errorText={errors?.subtitle}
                      onChange={(e) => setSubTitle(e.target.value)}
                      isRequired={false}
                    />

                    <FormRadioField
                      label="Is Multiselect?"
                      name="is_multiselect"
                      value={isMultiselect}
                      opt1Value={true}
                      opt1Label="Yes"
                      opt2Value={false}
                      opt2Label="No"
                      onChange={() => setIsMultiselect(!isMultiselect)}
                    />

                    <OptionsComponent
                      options={options}
                      setOptions={setOptions}
                      label="Option(s)"
                      errorText={errors?.options}
                      helpText="Click on the button to start adding options"
                    />

                    <FormRadioField
                      label="Status"
                      name="status"
                      value={status}
                      opt1Value={true}
                      opt1Label="Active"
                      opt2Value={false}
                      opt2Label="Archived"
                      onChange={() => setStatus(!status)}
                    />
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row justify-between gap-4">
                  <Link
                    to="/admin/questions"
                    className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 
                             text-gray-700 bg-white rounded-lg hover:bg-gray-50 transition-colors duration-300"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Back to Questions
                  </Link>
                  <button
                    onClick={onSubmitClick}
                    className="inline-flex items-center justify-center px-4 py-2 bg-primary 
                             hover:bg-primary-dark text-white rounded-lg transition-colors duration-300"
                    type="button"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}

const OptionsComponent = ({
  options,
  setOptions,
  label,
  helpText,
  errorText,
}) => {
  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleRemoveOption = (indexToRemove) => {
    setOptions(options.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-3">
        <label className="text-sm font-medium text-gray-700">{label}</label>
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 bg-primary hover:bg-primary-dark 
                   text-white rounded-lg transition-colors duration-300 text-sm"
          onClick={handleAddOption}
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add
        </button>
      </div>
      
      {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
      
      <div className="space-y-3">
        {options.map((option, index) => (
          <div key={index} className="flex items-center gap-2">
            <div className="flex-grow">
              <FormInputField
                type="text"
                placeholder="Enter option"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                maxWidth="300px"
              />
            </div>
            <button
              type="button"
              onClick={() => handleRemoveOption(index)}
              className="inline-flex items-center p-2 text-gray-500 hover:text-red-600 
                       hover:bg-red-50 rounded-lg transition-colors duration-300"
              title="Remove option"
            >
              <FontAwesomeIcon 
                icon={faTrash} 
                className="w-4 h-4"
              />
            </button>
          </div>
        ))}
      </div>
      
      {errorText && <p className="text-sm text-red-600">{errorText}</p>}
      
      {options.length === 0 && (
        <p className="text-sm text-gray-500 italic">
          No options added yet. Click the Add button to create options.
        </p>
      )}
    </div>
  );
};

export default AdminQuestionnaireAdd;