import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const Title = ({ text, subtitle, quizTime }) => (
  <div className="space-y-2 text-center">
    <h1 className="text-4xl font-bold text-textLight">{text}</h1>
    {subtitle && (
      <h2 className="text-2xl text-textLight/90">
        {subtitle}
      </h2>
    )}
    {quizTime && (
      <p className="text-xs text-textLight/70 pb-3">
        {quizTime}
      </p>
    )}
  </div>
);

const Card = ({ imgUrl, id, selected, buttonText, card, onSelect }) => (
  <div className="w-full px-4 mb-4">
    <div
      className={`
        transform transition-all duration-250 cursor-pointer
        max-w-card mx-auto overflow-hidden rounded-2xl
        ${selected ? 'shadow-lg ring-2 ring-primary' : 'shadow-md hover:shadow-lg'}
        ${selected ? 'bg-primary bg-opacity-10' : 'bg-gray-800'}
      `}
      onClick={() => onSelect(id)}
    >
      <div className="relative aspect-[4/3]">
        <img
          src={imgUrl}
          alt="Card"
          className="w-full h-full object-cover"
        />
      </div>
      <button
        className={`
          w-full px-4 py-3 flex items-center justify-between
          ${selected ? 'bg-primary text-white' : 'bg-accent1 text-white'}
          transition-colors duration-250
        `}
      >
        <span className="flex items-center">
          {buttonText}
          {card.type === "age" && `: ${card.ageRange}`}
        </span>
        <FontAwesomeIcon 
          icon={faArrowRight}
          className="h-4 w-4 ml-2"
        />
      </button>
    </div>
  </div>
);

const SelectableOption = ({
  option,
  isSelected,
  onSelect,
  isFullScreen = true,
}) => (
  <button
    type="button"
    onClick={() => onSelect(option)}
    aria-pressed={isSelected}
    className={`
      w-full px-6 py-4 rounded-lg transition-all duration-250
      flex items-center justify-between
      ${isFullScreen ? 'text-lg' : 'text-base'}
      ${
        isSelected
          ? 'bg-primary text-white shadow-md'
          : isFullScreen
          ? 'bg-gray-800 text-gray-100 hover:bg-gray-700'
          : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
      }
      ${isSelected ? 'ring-2 ring-primary ring-opacity-50' : ''}
      animate-fade-in
    `}
  >
    <span>{option}</span>
    {isSelected && (
      <span className="ml-2">
        <FontAwesomeIcon 
          icon={faCheck}
          className={`
            h-5 w-5
            ${isFullScreen ? 'text-white' : 'text-primary'}
          `}
        />
      </span>
    )}
  </button>
);

export { Title, Card, SelectableOption };