import React, { useState, useEffect } from "react";
import { getOfferSelectOptionListAPI } from "../../API/Offer";
import { getSelectedOptions } from "../../Helpers/selectHelper";
import PageLoadingContent from "./PageLoadingContent";

function DataDisplayRowOffer({
    label = "Offer",
    offerID,
    helpText
}) {
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [offerOption, setOfferOption] = useState(null);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFetching(true);
            getOfferSelectOptionListAPI(
                (response) => {
                    const options = [...response];
                    const offers = getSelectedOptions(options, [offerID]);
                    if (offers?.length > 0) {
                        setOfferOption(offers[0]);
                    }
                },
                setErrors,
                () => setFetching(false)
            );
        }

        return () => { mounted = false; }
    }, [offerID]);

    if (isFetching) {
        return <PageLoadingContent displayMessage="Loading offer..." />;
    }

    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                {offerOption ? (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm bg-primary/10 text-primary">
                        {offerOption.label}
                    </span>
                ) : (
                    <span className="text-gray-500">-</span>
                )}
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
                {errors && Object.keys(errors).length > 0 && (
                    <p className="mt-2 text-sm text-red-600">Error loading offer</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowOffer;