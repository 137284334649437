import React, { useState, useEffect } from "react";

function FormAlternateDateField({
  label,
  name,
  placeholder,
  value,
  errorText,
  helpText,
  onChange,
  maxWidth,
  disabled = false,
  maxDate = null
}) {
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setDay(date.getDate());
      setMonth(date.getMonth() + 1);
      setYear(date.getFullYear());
    }
  }, [value]);

  const onDateChange = (d, m, y) => {
    if (d && m && y) {
      const date = new Date(y, m - 1, d);
      onChange(date);
    }
  };

  return (
    <div className="mb-4" style={{ maxWidth }}>
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <div className="flex space-x-2">
        <input
          type="text"
          className={`w-1/3 px-2 py-1 border rounded-md focus:outline-none ${
            errorText ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="DD"
          value={day || ''}
          onChange={(e) => setDay(e.target.value)}
          onBlur={() => onDateChange(day, month, year)}
          disabled={disabled}
        />
        <input
          type="text"
          className={`w-1/3 px-2 py-1 border rounded-md focus:outline-none ${
            errorText ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="MM"
          value={month || ''}
          onChange={(e) => setMonth(e.target.value)}
          onBlur={() => onDateChange(day, month, year)}
          disabled={disabled}
        />
        <input
          type="text"
          className={`w-1/3 px-2 py-1 border rounded-md focus:outline-none ${
            errorText ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="YYYY"
          value={year || ''}
          onChange={(e) => setYear(e.target.value)}
          onBlur={() => onDateChange(day, month, year)}
          disabled={disabled}
        />
      </div>
      {errorText && <p className="text-sm text-red-600">{errorText}</p>}
      {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
    </div>
  );
}

export default FormAlternateDateField;