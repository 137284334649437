import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faFilter,
  faSearch,
  faSave,
  faFilterCircleXmark,
  faDumbbell,
  faGauge,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DropZone from "../../Reusable/dropzone";
import { getExerciseListAPI } from "../../../API/Exercise";
import WorkoutDisplay from "../../Reusable/Workouts/WorkoutDisplay";
import { postWorkoutCreateAPI } from "../../../API/workout";
import DragSortListForSelectedWorkouts from "../../Reusable/draglistforSelectWorkouts";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
  EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
  EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import Modal from "../../Reusable/Modal";

function AdminWorkoutAdd() {
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  const [currentUser] = useRecoilState(currentUserState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const [listdata, setlistdata] = useState([]);
  const [selectableExcercises, setselectableExcercises] = useState(listdata);
  const [forceURL, setForceURL] = useState("");
  const [exersizeLoading, setexersizeLoading] = useState(true);
  const [showExerciseFilter, setshowExerciseFilter] = useState(false);
  const [temporarySearchText, setTemporarySearchText] = useState("");
  const [actualSearchText, setActualSearchText] = useState("");
  const [category, setCategory] = useState("");
  const [movementType, setMovementType] = useState("");
  const [status, setStatus] = useState("");
  const [gender, setGender] = useState("");
  const [videoType, setVideoType] = useState("");
  const [tags, setTags] = useState([]);
  const onSubmitClick = () => {
    // Logic to submit data
    let payload = {
      name: name,
      description: description,
      visibility: 1, //1. visible to all 2. personal
      user_id: currentUser.id,
      user_name: currentUser.name,
    };
    let workoutExcercises = [];
    selectedWorkouts?.map((w, index) =>
      workoutExcercises.push({
        exercise_id: w.isRest ? null : w.id,
        exercise_name: w.isRest ? "REST" : w.name,
        is_rest: w.isRest === true,
        order_number: index + 1,
        sets: w.reps ? parseInt(w.reps) : 0,
        type: w.type ? parseInt(w.type) : 0,
        rest_period_in_secs: parseInt(w.restPeriod),
        target_time_in_secs: w.targetTime ? parseInt(w.targetTime) : 0,
        target_text: w?.targetText,
      })
    );
    payload.workout_exercises = workoutExcercises;
    postWorkoutCreateAPI(payload, onAddSuccess, onAddError, onAddDone);
  };

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Exercise created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/workouts/" + response.id + "");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  const getAllExcericses = (clear = false, search = "") => {
    setexersizeLoading(true);
    let params = new Map();
    params.set("page_size", 1000000);
    params.set("sort_field", "created");
    params.set("sort_order", "-1");
    if ((!clear && actualSearchText !== "") || search != "") {
      if (search) {
        params.set("search", search);
      } else {
        params.set("search", actualSearchText);
      }
    }
    if (!clear && category !== "") {
      params.set("category", category);
    }
    if (!clear && movementType !== "") {
      params.set("movement_type", movementType);
    }
    if (!clear && status !== "") {
      params.set("status", status);
    }
    if (!clear && gender !== "") {
      params.set("gender", gender);
    }
    if (!clear && videoType !== "") {
      params.set("video_type", videoType);
    }
    if (tags.length > 0) {
      params.set("tags", tags);
    }
    getExerciseListAPI(
      params,
      onExerciseListSuccess,
      onExerciseListError,
      onExerciseListDone
    );
  };

  function onExerciseListSuccess(response) {
    if (response.results !== null) {
      setlistdata(response.results);
      setselectableExcercises(response.results);
      if (response.hasNextPage) {
        // setNextCursor(response.nextCursor);
      }
    } else {
      setlistdata([]);
      // setNextCursor("");
    }
  }

  function onExerciseListError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onExerciseListDone() {
    setFetching(false);
    setexersizeLoading(false);
  }

  const onSearchButtonClick = (e) => {
    setActualSearchText(temporarySearchText);
    getAllExcericses(false, temporarySearchText);
    setshowExerciseFilter(false);
  };

  function ApplyFilter() {
    getAllExcericses();
    setshowExerciseFilter(false);
  }
  const onClearFilterClick = (e) => {
    setshowExerciseFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
    setStatus("");
    setCategory("");
    setMovementType("");
    setVideoType("");
    setGender("");
    getAllExcericses(true);
  };

  useEffect(() => {
    getAllExcericses();
    window.scrollTo(0, 0);
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const onDrop = (item) => {
    const exercise = listdata.find((ex) => ex.id === item.id);
    const newWorkout = {
      ...exercise,
      reps: "",
      restPeriod: "",
      targetText: "",
      targetType: "",
    };

    setselectableExcercises((prevExercises) =>
      prevExercises.filter((e) => e.id !== exercise.id)
    );

    setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, newWorkout]);
  };

  const handleInputChange = (e, exerciseId, field) => {
    const { value } = e.target;
    setSelectedWorkouts((prevWorkouts) =>
      prevWorkouts?.map((workout) => {
        if (workout.id === exerciseId) {
          return { ...workout, [field]: value };
        }
        return workout;
      })
    );
  };

  const onRemove = (cancelledItem) => {
    // Move the cancelled item back to the exercises column
    setSelectedWorkouts((prevWorkouts) =>
      prevWorkouts.filter((workout) => workout.id !== cancelledItem.id)
    );
    if (!cancelledItem.isRest) {
      const exercise = listdata.find((ex) => ex.id === cancelledItem.id);
      setselectableExcercises((e) => [...e, exercise]);
    }
  };

  const handleAddRest = () => {
    const restId = `rest-${Date.now()}`;
    let restWorkout = { id: restId, restPeriod: 60, isRest: true };

    setSelectedWorkouts((prevWorkouts) => [...prevWorkouts, restWorkout]);
  };

  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Workouts", link: "/admin/workouts", icon: faDumbbell },
    { label: "New Workout", icon: faPlus },
  ];

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
          <DndProvider backend={HTML5Backend}>
      <div className="mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm border border-gray-200">
          {/* Header */}
          <div className="border-b border-gray-200 px-6 py-4">
            <h1 className="text-xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Workouts
            </h1>
            <p className="mt-2 text-sm text-gray-600">
              Please fill out all the required fields before submitting this form.
            </p>
          </div>

          {/* Content */}
          <div className="p-6">
            <FormErrorBox errors={errors} />

            {isFetching ? (
              <PageLoadingContent displayMessage={"Please wait..."} />
            ) : (
              <div className="space-y-6">
                {/* Basic Info */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <FormTextareaField
                    rows={1}
                    name="Name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isRequired={true}
                    className="max-w-[150px]"
                  />
                  <FormTextareaField
                    rows={1}
                    name="Description"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    isRequired={true}
                    className="max-w-[380px]"
                  />
                </div>

                {/* Workout Builder */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                  {/* Selected Workouts Column */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <h2 className="text-lg font-medium text-gray-900">Selected Workouts</h2>
                      <button
                        onClick={handleAddRest}
                        className="px-3 py-1.5 bg-primary-light text-primary hover:bg-primary hover:text-gray-500  
                                 rounded-lg text-sm font-medium transition-colors"
                      >
                        Add Rest
                      </button>
                    </div>

                    <DropZone
                      className="p-4 bg-gray-50 border-2 border-dashed border-gray-300 rounded-lg"
                      onDrop={onDrop}
                      placeholder={!selectedWorkouts.length}
                    >
                      <DragSortListForSelectedWorkouts
                        onRemove={onRemove}
                        onSortChange={setSelectedWorkouts}
                        selectedWorkouts={selectedWorkouts}
                        handleInputChange={handleInputChange}
                      />
                    </DropZone>
                  </div>

                  {/* Available Exercises Column */}
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <div className="space-y-1">
                        <h2 className="text-lg font-medium text-gray-900">Exercises</h2>
                        <p className="text-sm text-gray-500">Click or drag item to add</p>
                      </div>
                      <button
                        onClick={() => setshowExerciseFilter(true)}
                        className="px-3 py-1.5 bg-primary-light text-primary hover:bg-primary hover:text-gray-500  
                                 rounded-lg text-sm font-medium transition-colors flex items-center"
                      >
                        <FontAwesomeIcon icon={faFilter} className="mr-2" />
                        Filter
                      </button>
                    </div>

                    {/* Filter Modal */}
                    <Modal
                      isOpen={showExerciseFilter}
                      onClose={() => setshowExerciseFilter(false)}
                      header="Filter Exercises"
                    >
                      <div className="space-y-6">
                        {/* Search and Filters */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                          <FormInputFieldWithButton
                            label="Search"
                            name="temporarySearchText"
                            type="text"
                            placeholder="Search by name"
                            value={temporarySearchText}
                            onChange={(e) => setTemporarySearchText(e.target.value)}
                            buttonIcon={faSearch}
                            onButtonClick={onSearchButtonClick}
                          />
                          <FormSelectField
                            label="Category"
                            name="category"
                            placeholder="Pick"
                            selectedValue={category}
                            onChange={(e) => setCategory(parseInt(e.target.value))}
                            options={EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION}
                          />
                          <FormSelectField
                            label="Movement Type"
                            name="movementType"
                            placeholder="Pick"
                            selectedValue={movementType}
                            onChange={(e) => setMovementType(parseInt(e.target.value))}
                            options={EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION}
                          />
                          <FormSelectField
                            label="Gender"
                            name="gender"
                            placeholder="Pick"
                            selectedValue={gender}
                            onChange={(e) => setGender(e.target.value)}
                            options={EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION}
                          />
                          <FormSelectField
                            label="Video Type"
                            name="videoType"
                            placeholder="Pick"
                            selectedValue={videoType}
                            onChange={(e) => setVideoType(e.target.value)}
                            options={EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION}
                          />
                          <FormMultiSelectFieldForTags
                            label="Tags"
                            name="tags"
                            placeholder="Pick tags"
                            tags={tags}
                            setTags={setTags}
                            isRequired={true}
                            className="max-w-[320px]"
                          />
                        </div>

                        {/* Filter Actions */}
                        <div className="flex justify-end space-x-2">
                          <button
                            onClick={ApplyFilter}
                            className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark 
                                     transition-colors text-sm font-medium flex items-center"
                          >
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            Apply
                          </button>
                          <button
                            onClick={() => setshowExerciseFilter(false)}
                            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg 
                                     hover:border-primary hover:text-primary transition-colors 
                                     text-sm font-medium flex items-center"
                          >
                            <FontAwesomeIcon icon={faClose} className="mr-2" />
                            Close
                          </button>
                          <button
                            onClick={onClearFilterClick}
                            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg 
                                     hover:bg-gray-200 transition-colors text-sm font-medium flex items-center"
                          >
                            <FontAwesomeIcon icon={faFilterCircleXmark} className="mr-2" />
                            Clear Filter
                          </button>
                        </div>
                      </div>
                    </Modal>

                    {/* Exercise List */}
                    {exersizeLoading ? (
                      <PageLoadingContent displayMessage={"Please wait..."} />
                    ) : (
                      <WorkoutDisplay
                        className="bg-gray-50 rounded-lg border border-gray-200 p-4"
                        workouts={selectableExcercises}
                        onAdd={onDrop}
                        showindex={false}
                        showDescription={false}
                      />
                    )}
                  </div>
                </div>

                {/* Footer Actions */}
                <div className="flex flex-col sm:flex-row justify-between items-center pt-6 gap-4 border-t border-gray-200">
                  <Link
                    to="/workouts"
                    className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg 
                             text-gray-700 hover:text-primary hover:border-primary 
                             transition-colors flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Back to Workouts
                  </Link>
                  <button
                    onClick={onSubmitClick}
                    disabled={!(name && description && selectedWorkouts.length)}
                    className="w-full sm:w-auto px-4 py-2 bg-primary text-white rounded-lg 
                             hover:bg-primary-dark transition-colors flex items-center justify-center
                             disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DndProvider>
    </Layout>
  );
}

export default AdminWorkoutAdd;
