import React from "react";

/**
 * Display a static select value with its mapped label
 * 
 * @example
 * const PHYSICAL_ACTIVITY_MAP = {
 *     1: "Sedentary",
 *     2: "Lightly Active",
 *     3: "Moderately Active",
 *     4: "Very Active",
 * };
 * 
 * <DataDisplayRowSelectStatic
 *    label="My current level of physical activity is"
 *    selectedValue={2}
 *    map={PHYSICAL_ACTIVITY_MAP}
 * />
 */
function DataDisplayRowSelectStatic({
    label = "",
    selectedValue = 0,
    map = {},
    helpText = ""
}) {
    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <div className="text-gray-900">
                    {selectedValue && map[selectedValue] ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm bg-gray-100">
                            {map[selectedValue]}
                        </span>
                    ) : (
                        <span className="text-gray-500">-</span>
                    )}
                </div>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowSelectStatic;