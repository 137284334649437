import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {useRecoilState} from "recoil";
import {useParams, Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faDumbbell,
	faUser,
	faHeart,
	faGauge,
	faCog,
	faPlusCircle,
	faSearch,
	faPlay,
	faBookmark,
	faShare,
} from "@fortawesome/free-solid-svg-icons";
import Vimeo from "@u-wave/react-vimeo";

import Layout from "../../Menu/Layout";
import {getExerciseDetailAPI} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_CATEGORY,
	EXERCISE_CATEGORY_OPTIONS,
	EXERCISE_MOMENT_TYPE_OPTIONS,
	EXERCISE_TYPE_SYSTEM,
} from "../../../Constants/FieldOptions";
import {
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_VIMEO,
} from "../../../Constants/App";
import {VideoPlayer, VIDEO_LAYOUTS} from "../../Reusable/VideoPlayer";
import {ListHeader} from "../../Reusable/AdminList";

const MemberExerciseDetail = () => {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});

	const breadcrumbItems = [
		{label: "Dashboard", link: "/dashboard", icon: faGauge},
		{label: "Exercises", icon: faDumbbell},
	];

	function onExerciseDetailSuccess(response) {
		console.log("onExerciseDetailSuccess: Starting...");
		setDatum(response);
	}

	function onExerciseDetailError(apiErr) {
		console.log("onExerciseDetailError: Starting...");
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onExerciseDetailDone() {
		console.log("onExerciseDetailDone: Starting...");
		setFetching(false);
	}

	// Format metadata for the video player
	// Format metadata for the video player
	const formatMetadata = (data) => {
		return [
			{
				label: "Category",
				value:
					EXERCISE_CATEGORY_OPTIONS.find((item) => item.value === data.category)
						?.label || data.category,
				icon: faDumbbell,
			},
			{
				label: "Movement Type",
				value:
					EXERCISE_MOMENT_TYPE_OPTIONS.find(
						(item) => item.value === data.movementType
					)?.label || data.movementType,
				icon: faPlay,
			},
			{
				label: "Suitable For",
				value: data.gender,
				icon: faUser,
			},
		];
	};

	// Handle like, save, and share actions
	const handleLike = (isLiked) => {
		console.log("Exercise liked:", isLiked);
		// Implement like functionality
	};

	const handleSave = (isSaved) => {
		console.log("Exercise saved:", isSaved);
		// Implement save functionality
	};

	const handleShare = () => {
		console.log("Share clicked");
		// Implement share functionality
	};

	const fetchData = (id) => {
		setFetching(true);
		getExerciseDetailAPI(
			id,
			onExerciseDetailSuccess,
			onExerciseDetailError,
			onExerciseDetailDone
		);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			setFetching(true);
			fetchData(id);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="px-4 py-6">
				<ListHeader
					title={datum.name || "Exercise Details"}
					icon={faDumbbell}
					onRefresh={() => fetchData(id)}
				/>
				{isFetching ? (
					<div className="p-6">
						<div className="animate-pulse space-y-4">
							<div className="h-96 bg-lightGrayShade rounded-lg"></div>
							<div className="h-8 w-1/3 bg-lightGrayShade rounded"></div>
							<div className="h-24 bg-lightGrayShade rounded"></div>
						</div>
					</div>
				) : (
					<VideoPlayer
						name={datum.name}
						videoType={datum.videoType}
						videoUrl={datum.videoUrl}
						videoObjectUrl={datum.videoObjectUrl}
						thumbnailUrl={datum.thumbnailUrl || datum.thumbnailObjectUrl}
						description={datum.description}
						metadata={formatMetadata(datum)}
						layout={VIDEO_LAYOUTS.FULL}
						showRelated={false}
						showActions={true}
						showDescription={true}
						onLike={handleLike}
						onSave={handleSave}
						onShare={handleShare}
					/>
				)}
			</div>
		</Layout>
	);
};

export default MemberExerciseDetail;
