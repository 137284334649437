import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCreditCard,
    faUserCircle,
    faGauge,
    faArrowLeft,
    faLock,
    faCheck,
    faCartPlus,
    faStar,
    faShield,
    faCrown,
    faVideo,
    faMobile,
    faHeadset,
    faDownload,
    faInfinity,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import {
    getAccountDetailAPI,
} from "../../../../../API/Account";
import { getOfferListAPI } from "../../../../../API/Offer";
import { postCreateStripeSubscriptionCheckoutSessionAPI } from "../../../../../API/PaymentProcessor";
import FormErrorBox from "../../../../Reusable/FormErrorBox";
import PageLoadingContent from "../../../../Reusable/PageLoadingContent";
import {
    topAlertMessageState,
    topAlertStatusState,
} from "../../../../../AppState";
import Layout from "../../../../Menu/Layout";

// Constants
const PREMIUM_FEATURES = [
    {
        icon: faVideo,
        title: "HD Video Content",
        description: "Access crystal clear training videos and tutorials"
    },
    {
        icon: faMobile,
        title: "Mobile Access",
        description: "Train anywhere with our mobile-optimized platform"
    },
    {
        icon: faHeadset,
        title: "24/7 Support",
        description: "Get help whenever you need it from our expert team"
    },
    {
        icon: faDownload,
        title: "Offline Access",
        description: "Download content for offline viewing"
    },
    {
        icon: faInfinity,
        title: "Unlimited Access",
        description: "No restrictions on viewing or learning"
    },
    {
        icon: faCrown,
        title: "Exclusive Content",
        description: "Access premium content not available anywhere else"
    }
];

const FAQ_ITEMS = [
    {
        question: "How does the subscription work?",
        answer: "Our subscription gives you immediate access to all premium content. You'll be billed monthly or annually depending on your chosen plan, and you can cancel anytime."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept all major credit cards (Visa, MasterCard, American Express) and PayPal for your convenience and security."
    },
    {
        question: "Can I cancel my subscription?",
        answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your billing period."
    },
    {
        question: "Is there a free trial?",
        answer: "Yes, we offer a 7-day free trial on all our subscription plans. You can explore all features risk-free."
    }
];

const TRUST_BADGES = [
    {
        icon: faShield,
        text: "Secure Payment",
        subtext: "256-bit SSL encryption"
    },
    {
        icon: faCrown,
        text: "Premium Support",
        subtext: "24/7 dedicated help"
    },
    {
        icon: faStar,
        text: "Cancel Anytime",
        subtext: "No commitments"
    }
];

function AccountMoreOperationSubscribe() {
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [currentUser, setCurrentUser] = useState({});
    const [listData, setListData] = useState("");

    const onOfferClick = (priceID) => {
        setFetching(true);
        postCreateStripeSubscriptionCheckoutSessionAPI(
            priceID,
            onCreateStripeSubscriptionCheckoutSessionSuccess,
            onCreateStripeSubscriptionCheckoutSessionError,
            onCreateStripeSubscriptionCheckoutSessionDone
        );
    };

    const fetchList = async () => {
        setFetching(true);
        setErrors({});
        getOfferListAPI(new Map(), onListSuccess, onListError, onListDone);
    };

    function onListSuccess(response) {
        if (response.results !== null) {
            setListData(response);
        } else {
            setListData([]);
        }
    }

    function onListError(apiErr) {
        setErrors(apiErr);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onListDone() {
        setFetching(false);
    }

    function onCreateStripeSubscriptionCheckoutSessionSuccess(response) {
        document.location.href = response.checkoutUrl;
    }

    function onCreateStripeSubscriptionCheckoutSessionError(apiErr) {
        setErrors(apiErr);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onCreateStripeSubscriptionCheckoutSessionDone() {
        setFetching(false);
    }

    function onAccountSuccess(response) {
        setCurrentUser(response);
    }

    function onAccountError(apiErr) {
        setErrors(apiErr);
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onAccountDone() {
        setFetching(false);
    }

    ////
    //// BREADCRUMB
    ////

    const generateBreadcrumbItemLink = (currentUser) => {
        let dashboardLink;
        switch (currentUser.role) {
            case 1:
                dashboardLink = "/root/dashboard";
                break;
            case 2:
                dashboardLink = "/admin/dashboard";
                break;
            case 3:
                dashboardLink = "/trainer/dashboard";
                break;
            case 4:
                dashboardLink = "/dashboard";
                break;
            default:
                dashboardLink = "/"; // Default or error handling
                break;
        }
        return dashboardLink;
    };


    const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: generateBreadcrumbItemLink(currentUser),
		},
		{
			label: "Account",
			icon: faUserCircle,
			link: "/account",
		},
		{
			label: "Subscribe",
			icon: faCreditCard,
		},
	];

    const onUnauthorized = () => {
        setForceURL("/login?unauthorized=true");
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);
            setFetching(true);
            setErrors({});
            getAccountDetailAPI(
                onAccountSuccess,
                onAccountError,
                onAccountDone,
                onUnauthorized
            );
            fetchList();
        }

        return () => {
            mounted = false;
        };
    }, []);

    if (forceURL !== "") {
        return <Navigate to={forceURL} />;
    }

	return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    {/* Hero Section */}
                    <div className="text-center mb-16 animate-fade-in infinite">
                        <div className="inline-block p-2 px-4 bg-primary/10 rounded-full text-primary text-sm font-semibold mb-4 animate-bounce">
                            🎉 Special Launch Offer - Save up to 40%
                        </div>
                        <h1 className="text-5xl font-extrabold text-gray-900 mb-6 leading-tight">
                            Level Up Your MMA Journey
                        </h1>
                        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
                            Join thousands of fighters and enthusiasts who have transformed their training with our premium content
                        </p>
                        
                        {/* Trust Badges */}
                        <div className="flex flex-wrap justify-center gap-6 mb-8">
                            {TRUST_BADGES.map((badge, index) => (
                                <div key={index} 
                                    className="flex items-center px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
                                    <FontAwesomeIcon icon={badge.icon} className="text-primary mr-2" />
                                    <div className="text-left">
                                        <div className="font-semibold">{badge.text}</div>
                                        <div className="text-xs text-gray-500">{badge.subtext}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <FormErrorBox errors={errors} />

                    {/* Pricing Cards Section */}
                    <div id="pricing-section">
                        {isFetching ? (
                            <div className="flex justify-center">
                                <PageLoadingContent displayMessage={"Loading amazing offers..."} />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
                                {listData.results && listData.results.length !== 0 ? (
                                    listData.results.map((content, index) => (
                                        <PricingOption
                                            key={index}
                                            title={content.name}
                                            highlightedText={content.recommended ? "Recommended" : null}
                                            customClasses={`${content.recommended ? "border-2 border-primary" : ""}`}
                                            price={content.price}
                                            period={null}
                                            description={content.description}
                                            url={content.thumbnailUrl || "https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg"}
                                            isLocked={false}
                                            customComponent={
                                                <CustomComponent
                                                    onClick={onOfferClick}
                                                    id={content.id}
                                                    isLocked={false}
                                                    isRecommended={content.recommended}
                                                />
                                            }
                                        />
                                    ))
                                ) : (
                                    <div className="col-span-full">
                                        <EmptyState />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    {/* Premium Features Section */}
                    <div className="py-16">
                        <h2 className="text-3xl font-bold text-center mb-12">Premium Features</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {PREMIUM_FEATURES.map((feature, index) => (
                                <FeatureCard
                                    key={index}
                                    icon={feature.icon}
                                    title={feature.title}
                                    description={feature.description}
                                />
                            ))}
                        </div>
                    </div>

                    {/* FAQ Section */}
                    <div className="py-16">
                        <h2 className="text-3xl font-bold text-center mb-12">Frequently Asked Questions</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
                            {FAQ_ITEMS.map((item, index) => (
                                <FAQItem
                                    key={index}
                                    question={item.question}
                                    answer={item.answer}
                                />
                            ))}
                        </div>
                    </div>

                    {/* Call to Action */}
                    <div className="text-center py-16">
                        <h2 className="text-3xl font-bold mb-6">Ready to Start Your Journey?</h2>
                        <p className="text-xl text-gray-600 mb-8">Join our community of fighters today and transform your training.</p>
                        <button 
                            onClick={() => document.querySelector('#pricing-section').scrollIntoView({ behavior: 'smooth' })}
                            className="inline-flex items-center px-8 py-4 bg-primary text-white rounded-xl font-semibold text-lg hover:bg-primary-dark transition-colors duration-300"
                        >
                            View Plans
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

const PricingOption = ({
    title,
    url,
    subtitle,
    description,
    price,
    period,
    features,
    highlightedText,
    isLocked,
    customComponent,
    customClasses = "",
}) => {
    const defaultImage = "https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg";
    
    return (
        <div className={`
            flex flex-col bg-white rounded-2xl shadow-xl
            transition-all duration-300 hover:shadow-2xl hover:-translate-y-1
            relative overflow-hidden h-full
            ${isLocked ? 'opacity-75' : ''} 
            ${customClasses}
        `}>
            {isLocked && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-600/50 rounded-2xl z-10">
                    <FontAwesomeIcon icon={faLock} className="text-4xl text-white mb-2" />
                    <span className="text-white font-semibold">Purchased</span>
                </div>
            )}
            
            {highlightedText && (
                <div className="absolute top-5 right-0 py-2 px-6 bg-gradient-to-r from-primary to-primary-dark text-white text-sm font-bold uppercase tracking-wider transform rotate-45 translate-x-8 shadow-lg">
                    {highlightedText}
                </div>
            )}

            <CardImage url={url || defaultImage} title={title} />

            <div className="flex flex-col flex-grow p-8">
                {title && (
                    <h3 className="text-2xl font-bold text-center text-gray-900 mb-4">{title}</h3>
                )}
                {subtitle && (
                    <p className="text-gray-600 text-center text-sm mb-5">{subtitle}</p>
                )}
                
                <Pricing price={price} period={period} />

                <div className="flex-grow">
                    {description && (
                        <p className="text-gray-600 text-center text-base my-6">{description}</p>
                    )}
                    {features && <FeaturesList features={features} />}
                </div>
                <div className="mt-8">{customComponent}</div>
            </div>
        </div>
    );
};

const CardImage = ({url, title}) => (
    <div className="relative pt-[56.25%]">
        <img 
            src={url} 
            alt={title}
            className="absolute top-0 left-0 w-full h-full object-cover rounded-t-2xl"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-t-2xl" />
    </div>
);

const Pricing = ({price, period}) => (
    <div className="text-center my-6">
        <div className="flex items-center justify-center">
            <span className="text-3xl font-normal text-gray-600">$</span>
            <span className="text-6xl font-bold text-primary mx-1">{price}</span>
            {period && <span className="text-xl text-gray-600">/{period}</span>}
        </div>
        {period && <p className="text-gray-500 mt-2 text-sm">Billed {period}ly</p>}
    </div>
);

const FeaturesList = ({features}) => (
    <ul className="space-y-4">
        {features?.map((feature, index) => (
            <li key={index} className="flex items-center text-gray-700">
                <div className="w-6 h-6 rounded-full bg-primary/10 flex items-center justify-center mr-3">
                    <FontAwesomeIcon icon={faCheck} className="text-primary text-sm" />
                </div>
                <span className="text-gray-600">{feature}</span>
            </li>
        ))}
    </ul>
);

const CustomComponent = ({onClick, id, isLocked, isRecommended}) => {
    return (
        <div className="flex flex-col items-center">
            <button
                onClick={() => onClick(id)}
                disabled={isLocked}
                className={`
                    group relative w-full flex items-center justify-center px-8 py-4 rounded-xl font-semibold text-lg
                    transform transition-all duration-300
                    ${isLocked 
                        ? 'bg-gray-300 cursor-not-allowed' 
                        : isRecommended
                            ? 'bg-gradient-to-r from-primary to-primary-dark text-white hover:shadow-xl hover:-translate-y-1'
                            : 'bg-gray-900 text-white hover:bg-gray-800 hover:shadow-xl hover:-translate-y-1'
                    }
                `}
            >
                <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
                <span>Get Started</span>
                <div className="absolute inset-0 rounded-xl border-2 border-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            </button>
            <p className="text-sm text-gray-500 mt-4">30-day money-back guarantee</p>
        </div>
    );
};

const EmptyState = () => (
    <div className="bg-white p-12 rounded-2xl text-center shadow-lg">
        <div className="w-20 h-20 mx-auto mb-6 bg-gray-100 rounded-full flex items-center justify-center">
            <FontAwesomeIcon icon={faCartPlus} className="text-3xl text-gray-400" />
        </div>
        <h3 className="text-2xl font-bold text-gray-900 mb-4">No Offers Available</h3>
        <p className="text-gray-600 mb-6">We're preparing something special for you. Check back soon!</p>
        <Link to="/dashboard" className="inline-flex items-center px-6 py-3 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-all duration-300">
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
            Return to Dashboard
        </Link>
    </div>
);

const FeatureCard = ({ icon, title, description }) => (
    <div className="text-center p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors duration-300">
        <div className="w-16 h-16 mx-auto mb-4 bg-primary/10 rounded-full flex items-center justify-center">
            <FontAwesomeIcon icon={icon} className="text-2xl text-primary" />
        </div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600">{description}</p>
    </div>
);

const FAQItem = ({ question, answer }) => (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300">
        <h4 className="text-lg font-semibold mb-2">{question}</h4>
        <p className="text-gray-600">{answer}</p>
    </div>
);

export default AccountMoreOperationSubscribe;
