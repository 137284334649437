import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faBolt,
	faCalendar,
	faClock,
	faTrophy,
	faUsers,
	faSearch,
	faGauge,
	faCrown,
	faListCheck,
	faCheck,
	faArrowRight,
	faCheckCircle,
	faPlus,
	faDumbbell,
	faHeartPulse,
	faRefresh,
	faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import Layout from "../../Menu/Layout";
import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import CardView from "../../Reusable/List/CardView";
import {
	getFitnessChalengeLeaderboard,
	getfitnessChallengeListApi,
	patchFitnessChaleengeParticipation,
} from "../../../API/FitnessChallenge";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	fitnessChallengeFilterShowState,
	fitnessChallengeFilterTemporarySearchTextState,
	fitnessChallengeFilterActualSearchTextState,
	fitnessChallengeFilterStatusState,
	videoCollectionsFilterVideoTypeState,
	fitnessChallengeSortState,
} from "../../../AppState";
import {
	CHALLENGE_CATEGORY_MAP,
	CHALLENGE_CATEGORY_WITH_EMPTY_OPTIONS,
	CHALLENGE_CRITERIA_MAP,
	CHALLENGE_CRITERIA_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";

const formatDate = (dateString) => {
	if (!dateString) return "";
	return new Date(dateString).toLocaleDateString("en-US", {
		year: "numeric",
		month: "short",
		day: "numeric",
	});
};

// const ChallengeCard = ({challenge, onParticipate, currentUserId}) => {
// 	if (!challenge) return null;

// 	const usersList = challenge.users || [];
// 	const userCount = usersList.length;
// 	const currentUserData = usersList.find((user) => user.id === currentUserId);
// 	const isParticipating = !!currentUserData;

// 	return (
// 		<div
// 			className="group h-full flex flex-col rounded-xl shadow-sm hover:shadow-md
// 					  transition-all duration-300 bg-white overflow-hidden border border-gray-100">
// 			{/* Header Section - More Compact */}
// 			<div className="relative p-5 bg-gradient-to-r from-primary to-primary-dark">
// 				{/* Subtle Decorative Element */}
// 				<div className="absolute inset-0 bg-pattern opacity-10"></div>

// 				{/* Content */}
// 				<div className="relative z-10">
// 					<div className="flex justify-between items-start gap-3 mb-4">
// 						<h3 className="text-lg font-semibold text-white leading-tight">
// 							{challenge.name || "Unnamed Challenge"}
// 						</h3>
// 						<span
// 							className="px-2.5 py-1 text-xs font-medium rounded-full
// 							 bg-white/20 text-white whitespace-nowrap">
// 							{CHALLENGE_CRITERIA_MAP[challenge.criteria]}
// 						</span>
// 					</div>

// 					{isParticipating && (
// 						<div className="w-full mt-2">
// 							<div className="flex items-center gap-2 bg-white/10 rounded-lg p-2.5">
// 								{/* Time Remaining */}
// 								<div className="flex-1">
// 									<div className="text-xs text-white/80 mb-1">
// 										Time Remaining
// 									</div>
// 									<div className="text-sm font-medium text-white flex items-center gap-1.5">
// 										<FontAwesomeIcon icon={faClock} className="text-xs" />
// 										{challenge.endTime
// 											? new Date(challenge.endTime) > new Date()
// 												? `${Math.ceil(
// 														(new Date(challenge.endTime) - new Date()) /
// 															(1000 * 60 * 60 * 24)
// 												  )} days`
// 												: "Ended"
// 											: "No end date"}
// 									</div>
// 								</div>

// 								{/* Badge Info - if available */}
// 								{challenge.badgeName && (
// 									<div className="flex-1">
// 										<div className="text-xs text-white/80 mb-1">
// 											Badge Available
// 										</div>
// 										<div className="text-sm font-medium text-white flex items-center gap-1.5">
// 											<FontAwesomeIcon icon={faMedal} className="text-xs" />
// 											{challenge.badgeName}
// 										</div>
// 									</div>
// 								)}
// 							</div>
// 						</div>
// 					)}
// 				</div>
// 			</div>

// 			{/* Main Content - Optimized Grid */}
// 			<div className="p-5">
// 				<div className="grid grid-cols-2 gap-4 mb-4">
// 					{/* Key Stats - Compact Design */}
// 					<div className="flex items-center gap-2.5">
// 						<div className="w-8 h-8 rounded-lg bg-primary/10 flex items-center justify-center">
// 							<FontAwesomeIcon
// 								icon={faCalendar}
// 								className="text-primary text-sm"
// 							/>
// 						</div>
// 						<div className="min-w-0">
// 							<p className="text-xs text-gray-500">Start</p>
// 							<p className="text-sm text-gray-700 font-medium truncate">
// 								{formatDate(challenge.startTime)}
// 							</p>
// 						</div>
// 					</div>

// 					<div className="flex items-center gap-2.5">
// 						<div className="w-8 h-8 rounded-lg bg-primary/10 flex items-center justify-center">
// 							<FontAwesomeIcon
// 								icon={faClock}
// 								className="text-primary text-sm"
// 							/>
// 						</div>
// 						<div>
// 							<p className="text-xs text-gray-500">Duration</p>
// 							<p className="text-sm text-gray-700 font-medium">
// 								{challenge.durationInWeeks} week(s)
// 							</p>
// 						</div>
// 					</div>

// 					<div className="flex items-center gap-2.5">
// 						<div className="w-8 h-8 rounded-lg bg-primary/10 flex items-center justify-center">
// 							<FontAwesomeIcon
// 								icon={faTrophy}
// 								className="text-primary text-sm"
// 							/>
// 						</div>
// 						<div>
// 							<p className="text-xs text-gray-500">Points</p>
// 							<p className="text-sm text-gray-700 font-medium">
// 								{challenge.maxPoints || 0}
// 							</p>
// 						</div>
// 					</div>

// 					<div className="flex items-center gap-2.5">
// 						<div className="w-8 h-8 rounded-lg bg-primary/10 flex items-center justify-center">
// 							<FontAwesomeIcon
// 								icon={faUsers}
// 								className="text-primary text-sm"
// 							/>
// 						</div>
// 						<div className="flex items-center gap-2">
// 							<div>
// 								<p className="text-xs text-gray-500">Participant(s)</p>
// 								<p className="text-sm text-gray-700 font-medium">{userCount}</p>
// 							</div>
// 						</div>
// 					</div>
// 				</div>

// 				{/* Rules Section - Compact */}
// 				{challenge.rules && challenge.rules.length > 0 && (
// 					<div className="border-t border-gray-100 pt-4">
// 						<h4 className="text-xs font-semibold text-gray-900 mb-2 flex items-center gap-1.5">
// 							<FontAwesomeIcon icon={faList} className="text-primary text-xs" />
// 							Challenge Rules
// 						</h4>
// 						<ul className="space-y-1.5">
// 							{challenge.rules.map((rule, index) => (
// 								<li
// 									key={index}
// 									className="flex items-start gap-2 text-sm text-gray-600">
// 									<span className="w-1 h-1 rounded-full bg-primary mt-1.5"></span>
// 									<span className="flex-1">{rule.name}</span>
// 								</li>
// 							))}
// 						</ul>
// 					</div>
// 				)}
// 			</div>

// 			{/* Footer - Compact Actions */}
// 			<div className="mt-auto p-4 pt-3 border-t border-gray-100">
// 				<div className="flex gap-2">
// 					<Link
// 						to={`/fitness-challenge/${challenge.id}`}
// 						className="flex-1 py-2 px-3 rounded-lg text-sm font-medium
// 						bg-gray-100 text-gray-700 hover:bg-gray-200
// 						flex items-center justify-center gap-2">
// 						<FontAwesomeIcon icon={faEye} className="text-xs" />
// 						<span>Details</span>
// 					</Link>

// 					{currentUserData?.completed ? (
// 						<button
// 							disabled
// 							className="flex-1 py-2 px-3 rounded-lg text-sm font-medium
// 						  bg-green-50 text-green-600 border border-green-200
// 						  flex items-center justify-center gap-2">
// 							<FontAwesomeIcon icon={faCheck} className="text-xs" />
// 							<span>Completed</span>
// 						</button>
// 					) : (
// 						<button
// 							onClick={() => onParticipate(challenge.id)}
// 							className={`flex-1 py-2 px-3 rounded-lg text-sm font-medium
// 				  flex items-center justify-center gap-2
// 				  ${
// 						isParticipating
// 							? "bg-red-50 text-red-600 border border-red-200"
// 							: "bg-primary text-white hover:bg-primary-dark"
// 					}`}>
// 							<FontAwesomeIcon
// 								icon={isParticipating ? faCalendarMinus : faCalendarPlus}
// 								className="text-xs"
// 							/>
// 							<span>{isParticipating ? "Leave" : "Join"}</span>
// 						</button>
// 					)}
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

const ChallengeCard = ({challenge, currentUserId, onParticipate}) => {
	const [leaderboardData, setLeaderboardData] = useState(null);
	const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(false);
	const [leaderboardError, setLeaderboardError] = useState(null);

	// Calculate user participation and time remaining
	const usersList = challenge.users || [];
	const userCount = usersList.length;
	const currentUserData = usersList.find((user) => user.id === currentUserId);
	const isParticipating = !!currentUserData;
	const timeRemaining = challenge.endTime
		? new Date(challenge.endTime) > new Date()
			? Math.ceil(
					(new Date(challenge.endTime) - new Date()) / (1000 * 60 * 60 * 24)
			  )
			: -1 // Use -1 to indicate ended
		: null;

	// Single API call implementation
	useEffect(() => {
		let isSubscribed = true;

		const fetchLeaderboard = () => {
			setIsLoadingLeaderboard(true);

			getFitnessChalengeLeaderboard(
				challenge.id,
				(data) => {
					if (isSubscribed) {
						setLeaderboardData(data);
					}
				},
				(error) => {
					if (isSubscribed) {
						setLeaderboardError(error);
					}
				},
				() => {
					if (isSubscribed) {
						setIsLoadingLeaderboard(false);
					}
				}
			);
		};

		fetchLeaderboard();

		return () => {
			isSubscribed = false;
		};
	}, [challenge.id]);

	return (
		<div className="flex flex-col h-full bg-white rounded-2xl">
			{/* Header - Fixed height */}
			<div className="bg-gradient-to-r from-primary via-primary-dark to-orange-600 p-6 rounded-t-2xl min-h-[120px] flex flex-col justify-center">
				<div className="flex justify-between items-start gap-4">
					<div>
						<h3 className="text-md font-bold text-white tracking-tight mb-1 line-clamp-2">
							{challenge.name}
						</h3>
						<div className="flex items-center text-white/80 text-sm">
							<FontAwesomeIcon icon={faClock} className="mr-2" />
							<span>
								{timeRemaining === null
									? "Ongoing Challenge"
									: timeRemaining === -1
									? "Challenge Ended"
									: `${timeRemaining} days remaining`}
							</span>
						</div>
					</div>

					{!currentUserData?.completed &&
						(timeRemaining === -1 ? (
							<span className="px-4 py-2 rounded-full text-sm font-medium flex-shrink-0 bg-gray-100 text-gray-500">
								<FontAwesomeIcon icon={faClock} className="text-xs mr-1" />
								Expired
							</span>
						) : (
							<button
								onClick={() => onParticipate(challenge.id)}
								className={`
        px-4 py-2 rounded-full text-sm font-medium flex-shrink-0
        backdrop-blur-sm transition-all whitespace-nowrap
        ${
					isParticipating
						? "bg-white/10 text-white hover:bg-white/20"
						: "bg-white text-primary hover:bg-primary-50"
				}
      `}>
								{isParticipating ? "Leave Challenge" : "Join Challenge"}
							</button>
						))}
				</div>
			</div>

			{/* Content Area */}
			<div className="flex flex-col flex-grow">
				{/* Stats Grid */}
				<div className="grid grid-cols-2 gap-4 p-4 min-h-[180px]">
					<div className="space-y-6">
						<div className="flex items-center gap-4">
							<div className="w-12 h-12 rounded-xl bg-gradient-to-br from-primary/5 to-primary/10 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faCalendar}
									className="text-primary text-lg"
								/>
							</div>
							<div>
								<p className="text-xs font-medium text-gray-500 uppercase tracking-wide">
									Start Date
								</p>
								<p className="text-sm font-semibold text-gray-900">
									{formatDate(challenge.startTime)}
								</p>
							</div>
						</div>

						<div className="flex items-center gap-4">
							<div className="w-12 h-12 rounded-xl bg-gradient-to-br from-amber-50 to-amber-100/50 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faTrophy}
									className="text-amber-600 text-lg"
								/>
							</div>
							<div>
								<p className="text-xs font-medium text-gray-500 uppercase tracking-wide">
									Max Points
								</p>
								<p className="text-sm font-semibold text-gray-900">
									{challenge.maxPoints}
								</p>
							</div>
						</div>
					</div>

					<div className="space-y-6">
						<div className="flex items-center gap-4">
							<div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-50 to-blue-100/50 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faClock}
									className="text-blue-600 text-lg"
								/>
							</div>
							<div>
								<p className="text-xs font-medium text-gray-500 uppercase tracking-wide">
									Duration
								</p>
								<p className="text-sm font-semibold text-gray-900">
									{challenge.durationInWeeks} weeks
								</p>
							</div>
						</div>

						<div className="flex items-center gap-4">
							<div className="w-12 h-12 rounded-xl bg-gradient-to-br from-green-50 to-green-100/50 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faUsers}
									className="text-green-600 text-lg"
								/>
							</div>
							<div>
								<p className="text-xs font-medium text-gray-500 uppercase tracking-wide">
									Participants
								</p>
								<p className="text-sm font-semibold text-gray-900">
									{userCount}
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* Leaderboard */}
				<div className="border-t border-gray-100 px-4 py-4 min-h-[200px]">
					<h4 className="font-semibold text-gray-900 mb-4 flex items-center gap-2">
						<FontAwesomeIcon icon={faCrown} className="text-amber-400" />
						Leaderboard
					</h4>

					{isLoadingLeaderboard ? (
						<div className="flex items-center justify-center h-32">
							<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
						</div>
					) : leaderboardError ? (
						<div className="text-center text-red-500 py-4">
							Failed to load leaderboard data
						</div>
					) : leaderboardData?.results ? (
						<div className="space-y-3">
							{leaderboardData?.results
								?.slice(0, 3)
								.map((participant, index) => (
									<div
										key={participant.id}
										className="flex items-center justify-between p-3 rounded-xl bg-gray-50 hover:bg-gray-100 transition-colors">
										<div className="flex items-center gap-3">
											<div
												className={`
                    w-8 h-8 rounded-lg flex items-center justify-center font-semibold
                    ${
											participant.place === 1
												? "bg-amber-100 text-amber-700"
												: participant.place === 2
												? "bg-gray-200 text-gray-700"
												: "bg-orange-100 text-orange-700"
										}
                  `}>
												#{participant.place}
											</div>
											<span className="font-medium text-gray-900">
												{participant?.userFirstName}
											</span>
										</div>
										<div className="flex items-center gap-2">
											<span className="text-sm font-semibold text-primary">
												{participant?.value || 0}
											</span>
											<span className="text-xs text-gray-500">pts</span>
										</div>
									</div>
								))}
						</div>
					) : (
						<div className="text-center text-gray-500 py-4">
							No leaderboard data available
						</div>
					)}
				</div>

				{/* Rules Section */}
				{challenge.rules && challenge.rules.length > 0 && (
					<div className="border-t border-gray-100 bg-gray-50/50 px-4 py-4 min-h-[160px]">
						<h4 className="font-semibold text-gray-900 mb-3 flex items-center gap-2">
							<FontAwesomeIcon icon={faListCheck} className="text-primary" />
							Challenge Rules
						</h4>
						<ul className="space-y-2">
							{challenge.rules.slice(0, 2).map((rule, index) => (
								<li
									key={index}
									className="flex items-start gap-3 text-sm text-gray-600">
									<div className="w-6 h-6 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0 mt-0.5">
										<FontAwesomeIcon
											icon={faCheck}
											className="text-primary text-xs"
										/>
									</div>
									<span>{rule.name}</span>
								</li>
							))}
						</ul>
					</div>
				)}

				{/* Footer */}
				<div className="border-t border-gray-200/80 p-4 bg-gray-50/50 rounded-b-xl">
					<div className="flex justify-between items-center">
						<Link
							to={`/fitness-challenge/${challenge.id}`}
							className="text-sm text-gray-600 hover:text-gray-900 font-medium">
							<FontAwesomeIcon icon={faArrowRight} className="text-xs" />
							&nbsp;View Details
						</Link>
						<div className="flex items-center space-x-2">
							{currentUserData?.completed ? (
								<span className="px-4 py-2 bg-green-50 text-green-600 rounded-lg text-sm font-medium">
									<FontAwesomeIcon icon={faCircleCheck} className="text-xs" />
									&nbsp;Completed
								</span>
							) : (
								isParticipating &&
								(timeRemaining === -1 ? (
									<span className="px-4 py-2 bg-gray-50 text-gray-500 rounded-lg text-sm font-medium">
										<FontAwesomeIcon icon={faClock} className="text-xs" />
										&nbsp;Expired
									</span>
								) : (
									<Link
										to={`/fitness-challenge/${challenge.id}/points`}
										className="px-4 py-2 bg-primary/10 text-primary hover:bg-primary/20 rounded-lg text-sm font-medium transition-all">
										<FontAwesomeIcon icon={faPlus} className="text-xs" />
										&nbsp;Add Points
									</Link>
								))
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const EmptyState = () => (
	<div className="text-center py-12">
		<div className="w-16 h-16 mx-auto mb-4 rounded-full bg-primary/10 flex items-center justify-center">
			<FontAwesomeIcon icon={faBolt} className="text-2xl text-primary" />
		</div>
		<h3 className="text-lg font-medium text-gray-900 mb-2">
			No Challenges Available
		</h3>
		<p className="text-gray-500">
			Check back later for new fitness challenges.
		</p>
	</div>
);

function FitnessChallengeMemberList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		fitnessChallengeFilterShowState
	);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		fitnessChallengeFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		fitnessChallengeFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(fitnessChallengeFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		videoCollectionsFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(fitnessChallengeSortState);
	const [category, setCategory] = useState("");
	const [criteria, setCriteria] = useState("");

	// Component states
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState([]);
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Challenge",
			icon: faBolt,
		},
	];

	// API success handler for fetching the challenge list
	function onFitnessChallengeListSuccess(response) {
		if (response && response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			} else {
				setNextCursor("");
			}
		} else {
			setListData([]);
		}
	}

	// API error handler
	function onFitnessChallengelistError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessChallengeListDone() {
		setFetching(false);
	}

	// Fetch list of challenges
	const fetchList = (cur, limit, keywords, st, vt, sbv, category, criteria) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);

		if (sbv) {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}

		if (cur) {
			params.set("cursor", cur);
		}

		params.set("type", vt);

		if (keywords) {
			params.set("search", keywords);
		}
		if (st) {
			params.set("status_list", [st]);
		}
		if (category !== 0) {
			params.set("category", category);
		}

		if (criteria !== 0) {
			params.set("criteria", criteria);
		}

		getfitnessChallengeListApi(
			params,
			onFitnessChallengeListSuccess,
			onFitnessChallengelistError,
			onFitnessChallengeListDone
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = () => {
		setActualSearchText(temporarySearchText);
	};

	const onClearFilterClick = () => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
		setCategory("");
	};

	function onChangeError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDone() {
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			status,
			videoType,
			sort,
			category,
			criteria
		);
	}

	function onChangeSuccess(response, challengeId) {
		// Find the relevant challenge from the listData and update its users
		const updatedListData = listData.results.map((datum) => {
			if (datum.id === challengeId) {
				// Check if the current user is in the list
				const isUserParticipating = datum.users.some(
					(user) => user.id === currentUser.id
				);

				if (isUserParticipating) {
					// Remove the user from the users array (leaving the challenge)
					datum.users = datum.users.filter(
						(user) => user.id !== currentUser.id
					);
				} else {
					// Add the user to the users array (joining the challenge)
					datum.users.push({id: currentUser.id, completed: false});
				}
			}
			return datum;
		});

		// Update the local state with the updated challenge data
		setListData({...listData, results: updatedListData});

		// Provide feedback to the user based on whether they joined or left
		const isUserInChallenge = updatedListData
			.find((datum) => datum.id === challengeId)
			.users.some((user) => user.id === currentUser.id);

		if (isUserInChallenge) {
			setTopAlertStatus("success");
			setTopAlertMessage("You have joined the challenge");
		} else {
			setTopAlertStatus("success");
			setTopAlertMessage("You have left the challenge");
		}

		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	}

	const ChangeParticipationStatus = (id) => {
		// Call the API to update server-side state
		patchFitnessChaleengeParticipation(
			id,
			(response) => onChangeSuccess(response, id), // Pass challenge ID to success handler
			onChangeError,
			onDone
		);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			window.scrollTo(0, 0);
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				videoType,
				sort,
				category,
				criteria
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentCursor,
		pageSize,
		actualSearchText,
		status,
		videoType,
		sort,
		category,
		criteria,
	]);

	const ActionButtons = () => (
		<div className="flex items-center space-x-3">
			<button
				onClick={() =>
					fetchList(
						currentCursor,
						pageSize,
						actualSearchText,
						status,
						videoType,
						sort,
						category,
						criteria
					)
				}
				className="inline-flex items-center px-4 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
				<FontAwesomeIcon icon={faRefresh} className="mr-2" />
				<span className="hidden sm:inline">Refresh</span>
			</button>
		</div>
	);

	const filterContent = (
		<FilterSidebar>
			<FilterSection title="Search">
				<div className="relative">
					<input
						type="text"
						value={temporarySearchText}
						onChange={(e) => setTemporarySearchText(e.target.value)}
						placeholder="Search"
						className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary/20 focus:border-primary"
					/>
					<button className="absolute right-2 top-1/2 -translate-y-1/2 p-2 text-gray-400 hover:text-primary">
						<FontAwesomeIcon icon={faSearch} />
					</button>
				</div>
			</FilterSection>

			<FilterSection title="Category">
				<select
					value={category}
					onChange={(e) => setCategory(e.target.value)}
					className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary/20 focus:border-primary">
					{CHALLENGE_CATEGORY_WITH_EMPTY_OPTIONS.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</FilterSection>
			{/* <FilterSection title="Criteria">
				<select
					value={criteria}
					onChange={(e) => setCriteria(e.target.value)}
					className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary/20 focus:border-primary">
					{CHALLENGE_CRITERIA_WITH_EMPTY_OPTIONS.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</FilterSection> */}
		</FilterSidebar>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<ListWithFilters
				title={
					<div className="flex items-center gap-3 py-6">
						<FontAwesomeIcon icon={faBolt} className="text-primary text-xl" />
						<h1 className="text-2xl font-bold text-gray-900">
							Fitness Challenges
						</h1>
						<div className="flex-shrink-0 ml-auto">
							<ActionButtons />
						</div>
					</div>
				}
				filterContent={filterContent}
				loading={isFetching}
				error={typeof errors === "string" ? errors : null}
				EmptyStateComponent={<EmptyState />}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={!!nextCursor}>
				<CardView
					items={listData.results || []}
					renderItem={(challenge) => (
						<ChallengeCard
							challenge={challenge}
							onParticipate={ChangeParticipationStatus}
							currentUserId={currentUser?.id}
						/>
					)}
					emptyState={<EmptyState />}
				/>
			</ListWithFilters>
		</Layout>
	);
}

export default FitnessChallengeMemberList;
