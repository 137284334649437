import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faRefresh,
	faEye,
	faSearch,
	faFilterCircleXmark,
	faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	videoCollectionsFilterShowState,
	videoCollectionsFilterTemporarySearchTextState,
	videoCollectionsFilterActualSearchTextState,
	videoCollectionsFilterStatusState,
	videoCollectionsFilterSortState,
	workoutVisibilityForAdmin,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
	TRAINING_PROGRAM_WITH_EMPTY_VISBILITY_OPTIONS,
	VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import {getWorkoutListApi} from "../../../API/workout";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
} from "../../Reusable/AdminList";
import {WORKOUT_STATUS_MAP} from "../../../Constants/FieldOptions";

function AdminWorkoutList() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		videoCollectionsFilterShowState
	); // Filtering + Searching
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		videoCollectionsFilterTemporarySearchTextState
	); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useRecoilState(
		videoCollectionsFilterActualSearchTextState
	); // Searching - The actual search query value to submit to the API.
	const [status, setStatus] = useRecoilState(videoCollectionsFilterStatusState);
	const [sort, setSort] = useRecoilState(videoCollectionsFilterSortState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [visibility, setVisibility] = useRecoilState(
		workoutVisibilityForAdmin //visibility
	);
	////
	//// API.
	////

	function onWorkoutListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onWorkoutlistError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onWorkoutListDOne() {
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, st, sbv, visibility) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// params.set("type", vt);

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (st !== undefined && st !== null && st !== "") {
			let stArray = [];
			stArray.push(st);
			params.set("status_list", stArray);
			if (st === 2) {
				params.set("exclude_archived", false);
			}
		}
		params.set("visibility", visibility);

		getWorkoutListApi(
			params,
			onWorkoutListSuccess,
			onWorkoutlistError,
			onWorkoutListDOne
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		setActualSearchText(temporarySearchText);
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		// setVideoType(0);
		setStatus(0);
		setSort("created,-1");
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				// videoType,
				sort,
				visibility
			);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, sort, visibility]);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Workouts", icon: faDumbbell},
	];

	const renderWorkoutRow = (datum, index) => (
		<tr key={`desktop_${datum.id}`}>
			<td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap">
				<div className="text-sm font-medium text-gray-900">{datum.name}</div>
			</td>
			<td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap">
				<span
					className={`inline-flex rounded-full px-2 py-1 text-xs font-medium
          ${
						datum.visibility === 1
							? "bg-green-100 text-green-800"
							: "bg-gray-100 text-gray-800"
					}`}>
					{datum.visibility === 1 ? "Visible to All" : "Personal"}
				</span>
			</td>
			<td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap">
				<div className="text-sm text-gray-500">
					{WORKOUT_STATUS_MAP[datum.status]}
				</div>
			</td>
			<td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-right">
				<div className="flex justify-end space-x-2">
					<Link
						to={`/admin/workouts/${datum.id}`}
						className="inline-flex items-center px-3 py-1.5 border border-gray-300 
                     text-sm font-medium rounded-md text-gray-700 bg-white 
                     hover:bg-gray-50 hover:text-primary hover:border-primary 
                     transition-colors duration-300">
						<FontAwesomeIcon icon={faEye} className="mr-2" />
						View
					</Link>
				</div>
			</td>
		</tr>
	);

	const renderMobileItem = (datum, index) => (
		<div
			key={`mobile_${datum.id}`}
			className="bg-white rounded-lg border border-gray-200 shadow-sm p-4 mb-4">
			{/* Info Grid */}
			<div className="grid grid-cols-1 gap-3 mb-4">
				<div>
					<div className="text-sm text-gray-500 font-medium mb-1">Name</div>
					<div className="text-sm text-gray-900">{datum.name}</div>
				</div>

				<div className="flex justify-between items-center">
					<div>
						<div className="text-sm text-gray-500 font-medium mb-1">
							Visibility
						</div>
						<span
							className={`inline-flex rounded-full px-2 py-1 text-xs font-medium
              ${
								datum.visibility === 1
									? "bg-green-100 text-green-800"
									: "bg-gray-100 text-gray-800"
							}`}>
							{datum.visibility === 1 ? "Visible to All" : "Personal"}
						</span>
					</div>

					<div className="text-right">
						<div className="text-sm text-gray-500 font-medium mb-1">Status</div>
						<div className="text-sm text-gray-900">
							{WORKOUT_STATUS_MAP[datum.status]}
						</div>
					</div>
				</div>

				<div>
					<div className="text-sm text-gray-500 font-medium mb-1">
						Created At
					</div>
					<div className="text-sm text-gray-900">{datum.createdAt || "—"}</div>
				</div>
			</div>

			{/* Actions */}
			<div className="border-t border-gray-200 pt-4 mt-4">
				{/* Tablet View */}
				<div className="hidden sm:block md:hidden">
					<div className="flex justify-end">
						<Link
							to={`/admin/workouts/${datum.id}`}
							className="inline-flex items-center px-4 py-2 border border-gray-300 
                       text-sm font-medium rounded-md text-gray-700 bg-white 
                       hover:bg-gray-50 hover:text-primary hover:border-primary 
                       transition-colors duration-300">
							<FontAwesomeIcon icon={faEye} className="mr-2" />
							View
						</Link>
					</div>
				</div>

				{/* Mobile View */}
				<div className="sm:hidden">
					<Link
						to={`/admin/workouts/${datum.id}`}
						className="flex items-center justify-center w-full px-4 py-2 
                     border border-gray-300 text-sm font-medium rounded-md 
                     text-gray-700 bg-white hover:bg-gray-50 hover:text-primary 
                     hover:border-primary transition-colors duration-300">
						<FontAwesomeIcon icon={faEye} className="mr-2" />
						View Details
					</Link>
				</div>
			</div>
		</div>
	);

	////
	//// Component rendering.
	////

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-8">
				<div className="shadow-sm border border-gray-200 p-6">
					<ListHeader
						title="Workouts"
						icon={faDumbbell}
						onRefresh={() =>
							fetchList(
								currentCursor,
								pageSize,
								actualSearchText,
								status,
								sort,
								visibility
							)
						}
						onToggleFilter={() => setShowFilter(!showFilter)}
						addLink="/admin/workouts/add"
						addLabel="New"
					/>

					{/* Filter Section */}
					{showFilter && (
						<ListFilter onClear={onClearFilterClick}>
							<div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
								<FormInputFieldWithButton
									label="Search"
									name="temporarySearchText"
									type="text"
									placeholder="Search by name"
									value={temporarySearchText}
									onChange={(e) => setTemporarySearchText(e.target.value)}
									buttonLabel={<FontAwesomeIcon icon={faSearch} />}
									onButtonClick={onSearchButtonClick}
								/>

								<FormSelectField
									label="Status"
									name="status"
									placeholder="Pick status"
									selectedValue={status}
									errorText={errors?.status}
									onChange={(e) => setStatus(parseInt(e.target.value))}
									options={VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION}
								/>

								<FormSelectField
									label="Visibility"
									name="visibility"
									placeholder="Pick visibility"
									selectedValue={visibility}
									errorText={errors?.visibility}
									onChange={(e) => setVisibility(parseInt(e.target.value))}
									options={TRAINING_PROGRAM_WITH_EMPTY_VISBILITY_OPTIONS}
								/>
							</div>
						</ListFilter>
					)}

					{isFetching ? (
						<PageLoadingContent displayMessage="Loading Workouts...Please wait..." />
					) : (
						<>
							<FormErrorBox errors={errors} />

							{listData?.results?.length > 0 || previousCursors.length > 0 ? (
								<>
									{/* Desktop Table */}
									<div className="hidden md:block">
										<DataListDesktop
											headers={[
												{label: "Name", className: "w-1/3"},
												{label: "Visibility", className: "w-1/4"},
												{label: "Status", className: "w-1/4"},
												{label: "Actions", className: "w-1/6 text-right"},
											]}
											data={listData.results}
											renderRow={renderWorkoutRow}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>

									{/* Mobile List */}
									<div className="md:hidden">
										<DataListMobile
											data={listData.results}
											renderItem={renderMobileItem}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>
								</>
							) : (
								<div className="text-center py-12 bg-gray-50 rounded-lg">
									<FontAwesomeIcon
										icon={faTable}
										className="text-4xl text-gray-400 mb-4"
									/>
									<h3 className="text-lg font-medium text-gray-900 mb-2">
										No Workouts
									</h3>
									<p className="text-gray-600">
										Get started by{" "}
										<Link
											to="/admin/workouts/add"
											className="text-primary hover:text-primary-dark font-medium">
											creating your first workout
										</Link>
									</p>
								</div>
							)}
						</>
					)}

					<div className="flex flex-col sm:flex-row justify-between items-center mt-6 gap-4">
						<Link
							to="/admin/dashboard"
							className="w-full sm:w-auto px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:text-primary hover:border-primary transition-colors flex items-center justify-center">
							<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
							Back to Dashboard
						</Link>
						<Link
							to="/admin/workouts/add"
							className="w-full sm:w-auto px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors flex items-center justify-center">
							<FontAwesomeIcon icon={faPlus} className="mr-2" />
							New Workout
						</Link>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminWorkoutList;
