import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRepeat, faTasks, faTachometer, faTrash, faArrowLeft, faCheckCircle, faUserCircle, faGauge, faPencil, faDumbbell, faEye, faIdCard, faAddressBook, faContactCard, faChartPie, faCogs } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';

import { getVideoCategoryDetailAPI, deleteVideoCategoryAPI } from "../../../API/VideoCategory";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import FormTextTagRow from "../../Reusable/FormTextTagRow";
import FormTextYesNoRow from "../../Reusable/FormTextYesNoRow";
import FormTextOptionRow from "../../Reusable/FormTextOptionRow";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminVideoCategoryDetail() {
    ////
    //// URL Parameters.
    ////

    const { id } = useParams()

    ////
    //// Global state.
    ////

    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////

    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [datum, setDatum] = useState({});
    const [tabIndex, setTabIndex] = useState(1);
    const [selectedVideoCategoryForDeletion, setSelectedVideoCategoryForDeletion] = useState(null);

    ////
    //// Event handling.
    ////

    const onDeleteConfirmButtonClick = () => {
        console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

        deleteVideoCategoryAPI(
            selectedVideoCategoryForDeletion.id,
            onVideoCategoryDeleteSuccess,
            onVideoCategoryDeleteError,
            onVideoCategoryDeleteDone
        );
        setSelectedVideoCategoryForDeletion(null);
    }

    ////
    //// API.
    ////

    // --- Detail --- //

    function onVideoCategoryDetailSuccess(response){
        console.log("onVideoCategoryDetailSuccess: Starting...");
        setDatum(response);
    }

    function onVideoCategoryDetailError(apiErr) {
        console.log("onVideoCategoryDetailError: Starting...");
        setErrors(apiErr);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onVideoCategoryDetailDone() {
        console.log("onVideoCategoryDetailDone: Starting...");
        setFetching(false);
    }

    // --- Delete --- //

    function onVideoCategoryDeleteSuccess(response) {
        console.log("onVideoCategoryDeleteSuccess: Starting..."); // For debugging purposes only.

        // Update notification.
        setTopAlertStatus("success");
        setTopAlertMessage("Video category deleted");
        setTimeout(() => {
        console.log(
            "onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
            topAlertMessage,
            topAlertStatus
        );
        setTopAlertMessage("");
        }, 2000);

        // Redirect back to the video categories page.
        setForceURL("/admin/video-categories");
    }

    function onVideoCategoryDeleteError(apiErr) {
        console.log("onVideoCategoryDeleteError: Starting..."); // For debugging purposes only.
        setErrors(apiErr);

        // Update notification.
        setTopAlertStatus("danger");
        setTopAlertMessage("Failed deleting");
        setTimeout(() => {
        console.log(
            "onVideoCategoryDeleteError: topAlertMessage, topAlertStatus:",
            topAlertMessage,
            topAlertStatus
        );
        setTopAlertMessage("");
        }, 2000);

        // The following code will cause the screen to scroll to the top of
        // the page. Please see ``react-scroll`` for more information:
        // https://github.com/fisshy/react-scroll
        var scroll = Scroll.animateScroll;
        scroll.scrollToTop();
    }

    function onVideoCategoryDeleteDone() {
        console.log("onVideoCategoryDeleteDone: Starting...");
        setFetching(false);
    }

    ////
    //// Misc.
    ////

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);  // Start the page at the top of the page.

            setFetching(true);
            getVideoCategoryDetailAPI(
                id,
                onVideoCategoryDetailSuccess,
                onVideoCategoryDetailError,
                onVideoCategoryDetailDone
            );
        }

        return () => { mounted = false; }
    }, []);
    ////
    //// Component rendering.
    ////

    if (forceURL !== "") {
        return <Navigate to={forceURL}  />
    }

    const breadcrumbItems = [
        { label: 'Dashboard', link: '/admin/dashboard', icon: faGauge },
        { label: 'Video Categories', link: '/admin/video-categories', icon: faDumbbell },
        { label: 'Detail', icon: faEye },
    ]

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
<div className="mx-auto px-4 py-8">
            <div className="mx-auto">

                {/* Delete Confirmation Modal */}
                <Modal
                    isOpen={selectedVideoCategoryForDeletion !== null}
                    onClose={() => setSelectedVideoCategoryForDeletion(null)}
                    header="Are you sure?"
                >
                    <div className="space-y-4">
                        <p className="text-gray-700">
                            You are about to delete this video category and all the data associated with it. 
                            This action cannot be undone. Are you sure you would like to continue?
                        </p>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setSelectedVideoCategoryForDeletion(null)}
                                className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={onDeleteConfirmButtonClick}
                                className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </Modal>

                {/* Main Content */}
                <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                    {datum && (
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4 sm:gap-0">
                            <h1 className="text-2xl font-bold text-gray-900 flex items-center">
                                <FontAwesomeIcon className="mr-3" icon={faDumbbell} />
                                Video Category
                            </h1>
                            <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                                <Link 
                                    to={`/admin/video-category/${id}/update`}
                                    className="inline-flex items-center justify-center px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg transition-colors"
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faPencil} />
                                    Edit
                                </Link>
                                <button
                                    onClick={() => setSelectedVideoCategoryForDeletion(datum)}
                                    className="inline-flex items-center justify-center px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors"
                                >
                                    <FontAwesomeIcon className="mr-2" icon={faTrash} />
                                    Delete
                                </button>
                            </div>
                        </div>
                    )}

                    <FormErrorBox errors={errors} />

                    {isFetching ? (
                        <PageLoadingContent displayMessage={"Please wait..."} />
                    ) : (
                        datum && (
                            <div className="space-y-6">
                                <div>

                                    <div className="space-y-4">
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <span className="block text-sm font-medium text-gray-700">Name</span>
                                                <span className="block mt-1 text-gray-900">{datum.name}</span>
                                            </div>
                                            <div>
                                                <span className="block text-sm font-medium text-gray-700">No #</span>
                                                <span className="block mt-1 text-gray-900">{datum.no}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col sm:flex-row justify-between pt-6 gap-3 sm:gap-0">
                                    <Link 
                                        to="/admin/video-categories"
                                        className="inline-flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                                        Back
                                    </Link>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
        </Layout>
    );
}

export default AdminVideoCategoryDetail;
