import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function PageLoadingContent(props) {
    const { 
        displayMessage = "Loading...",
        icon = null,
        imageClassName = "w-8 h-8" 
    } = props;
    return (
        <div className="flex flex-col items-center justify-center py-12">
            <div className="relative">
                <div className="w-16 h-16 border-4 border-primary/30 rounded-full animate-spin border-t-primary"></div>
                {icon ? (
                    // If a FontAwesome icon is provided
                    <FontAwesomeIcon
                        icon={icon}
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-primary text-xl"
                    />
                ) : (
                    // Default to using the image
                    <img
                        src={"/logo.svg"}
                        alt="Loading"
                        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${imageClassName}`}
                    />
                )}
            </div>
            <p className="mt-4 text-gray-600 font-medium">
                {displayMessage}
            </p>
        </div>
    );
}

export default PageLoadingContent;
