import React, {useState, useEffect} from "react";
import {Link, useParams, useLocation, useNavigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faRankingStar,
	faArrowLeft,
	faGauge,
	faRefresh,
	faEye,
	faBolt,
	faChartLine,
	faTrophy,
	faMedal,
	faSearch,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	dataPointFilterShowState,
	dataPointFilterTemporarySearchTextState,
	dataPointFilterActualSearchTextState,
	dataPointFilterSortState,
	dataPointFilterStatusState,
	challengeDetailState,
} from "../../../AppState";
import {getFitnessChalengeLeaderboard} from "../../../API/FitnessChallenge";

function MemberLeaderboardGlobalTabularListForChallenge() {
	const {id} = useParams();
	const location = useLocation();
	const navigate = useNavigate();  // Add this hook

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(dataPointFilterShowState);
	const [sort, setSort] = useRecoilState(dataPointFilterSortState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		dataPointFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		dataPointFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(dataPointFilterStatusState);
	const [challengeDetail] = useRecoilState(challengeDetailState);

	// Component states
	const [errors, setErrors] = useState({});
	const [listRank, setListRank] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(100);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");
	const [activeTab, setActiveTab] = useState("leaderboard");

	// Filter states
	const [filters, setFilters] = useState({
		dateRange: "all",
		sortBy: "points",
		sortOrder: "desc",
		status: "all",
	});

	

    const timeRemaining = challengeDetail?.endTime
        ? new Date(challengeDetail.endTime) > new Date()
            ? Math.ceil(
                (new Date(challengeDetail.endTime) - new Date()) / (1000 * 60 * 60 * 24)
            )
            : -1
        : null;

		const isUserCompleted = () => {
			const currentUserInChallenge = challengeDetail?.users?.find(
				user => user.id === currentUser.id
			);
			return currentUserInChallenge?.completed || false;
		};

	// Tab navigation items
	const tabItems = [
		{
			id: "details",
			label: "Detail",
			icon: faEye,
			to: `/fitness-challenge/${id}`,
			show: true,
		},
		{
			id: "points",
			label: "Points",
			icon: faChartLine,
			to: `/fitness-challenge/${id}/points`,
			show: challengeDetail?.category === 2 && (timeRemaining === null || timeRemaining >= 0) && !isUserCompleted()
		},
		{
			id: "leaderboard",
			label: "Leaderboard",
			icon: faTrophy,
			to: `/fitness-challenge/${id}/leaderboard`,
			show: true,
		},
	];

	// Set active tab based on URL
	useEffect(() => {
		const path = location.pathname;
		if (path.includes("/points")) {
			setActiveTab("points");
		} else if (path.includes("/leaderboard")) {
			setActiveTab("leaderboard");
		} else {
			setActiveTab("details");
		}
	}, [location.pathname]);

	// API handlers
	const onRankPointistSuccess = (response) => {
		if (response.results !== null) {
			setListRank(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListRank([]);
			setNextCursor("");
		}
	};

	const onRankPointistError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onRankPointistDone = () => {
		setFetching(false);
	};

	// Event handlers
	const fetchList = () => {
		setFetching(true);
		setErrors({});
		getFitnessChalengeLeaderboard(
			id,
			onRankPointistSuccess,
			onRankPointistError,
			onRankPointistDone
		);
	};

	const onNextClicked = () => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	// Initial fetch
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			fetchList();
		}
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
        if (!challengeDetail) {
            navigate(`/fitness-challenge/${id}`);
        }
    }, [challengeDetail, id]);

	// Filter sidebar content
	const renderFilterContent = () => (
		<FilterSidebar>
			<FilterSection title="Time Period">
				<div className="space-y-2">
					{[
						{label: "All Time", value: "all"},
						{label: "This Week", value: "week"},
						{label: "This Month", value: "month"},
					].map((option) => (
						<label key={option.value} className="flex items-center space-x-2">
							<input
								type="radio"
								name="dateRange"
								value={option.value}
								checked={filters.dateRange === option.value}
								onChange={(e) =>
									setFilters({...filters, dateRange: e.target.value})
								}
								className="form-radio text-primary focus:ring-primary"
							/>
							<span className="text-sm text-gray-700">{option.label}</span>
						</label>
					))}
				</div>
			</FilterSection>

			<FilterSection title="Sort By">
				<select
					value={filters.sortBy}
					onChange={(e) => setFilters({...filters, sortBy: e.target.value})}
					className="form-select w-full rounded-md border-gray-300 text-sm
                   focus:border-primary focus:ring focus:ring-primary/20">
					<option value="points">Points</option>
					<option value="name">Name</option>
					<option value="status">Status</option>
				</select>
			</FilterSection>

			<FilterSection title="Status">
				<div className="space-y-2">
					{[
						{label: "All", value: "all"},
						{label: "Completed", value: "completed"},
						{label: "In Progress", value: "in_progress"},
					].map((option) => (
						<label key={option.value} className="flex items-center space-x-2">
							<input
								type="radio"
								name="status"
								value={option.value}
								checked={filters.status === option.value}
								onChange={(e) =>
									setFilters({...filters, status: e.target.value})
								}
								className="form-radio text-primary focus:ring-primary"
							/>
							<span className="text-sm text-gray-700">{option.label}</span>
						</label>
					))}
				</div>
			</FilterSection>
		</FilterSidebar>
	);

	// Empty state component
	const EmptyState = () => (
		<div className="text-center py-12">
			<div className="rounded-full bg-primary/10 p-3 w-12 h-12 mx-auto mb-4 flex items-center justify-center">
				<FontAwesomeIcon icon={faTrophy} className="text-primary h-6 w-6" />
			</div>
			<h3 className="text-lg font-medium text-gray-900 mb-2">
				No Results Found
			</h3>
			<p className="text-gray-500 max-w-sm mx-auto">
				Try adjusting your search or filter criteria to find what you're looking
				for.
			</p>
		</div>
	);


	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Challenge",
			icon: faBolt,
			link: "/fitness-challenge",
		},
		{
			label: "Leaderboard",
			icon: faRankingStar,
		},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<ListWithFilters
				title={
					<div className="bg-gradient-to-r from-primary/95 to-primary-dark/95 rounded-xl shadow-lg px-6 py-5 mb-6">
						<div className="flex items-center justify-between">
							<div className="flex items-center space-x-3">
								<div className="p-2 bg-white/10 rounded-lg">
									<FontAwesomeIcon
										icon={faRankingStar}
										className="text-white h-5 w-5 animate-scale"
									/>
								</div>
								<h1 className="text-2xl font-bold text-white">
									Challenge Leaderboard
								</h1>
							</div>
							<button
								onClick={fetchList}
								className="px-4 py-2 text-sm font-medium text-white bg-white/10 
                         hover:bg-white/20 rounded-lg transition-colors duration-250 
                         flex items-center space-x-2">
								<FontAwesomeIcon icon={faRefresh} className="h-4 w-4" />
								<span className="hidden sm:inline">Refresh</span>
							</button>
						</div>
					</div>
				}
				filterContent={renderFilterContent()}
				loading={isFetching}
				error={typeof errors === "string" ? errors : null}
				EmptyStateComponent={<EmptyState />}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={nextCursor !== ""}>
				{/* Tab Navigation */}
				<div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden mb-6">
					<div className="border-b border-gray-200">
						<nav className="flex space-x-8 px-6" aria-label="Tabs">
							{tabItems.map(
								(tab) =>
									tab.show && (
										<Link
											key={tab.id}
											to={tab.to}
											className={`
        group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
        ${
					location.pathname === tab.to
						? "border-primary text-primary"
						: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
				}
      `}>
											<FontAwesomeIcon
												icon={tab.icon}
												className={`mr-2 h-4 w-4 ${
													location.pathname === tab.to
														? "text-primary"
														: "text-gray-400"
												}`}
											/>
											{tab.label}
										</Link>
									)
							)}
						</nav>
					</div>

					{/* Search Bar */}
					<div className="p-4 border-b border-gray-200 bg-gray-50">
						<div className="max-w-md">
							<div className="relative">
								<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
									<FontAwesomeIcon
										icon={faSearch}
										className="h-4 w-4 text-gray-400"
									/>
								</div>
								<input
									type="text"
									value={temporarySearchText}
									onChange={(e) => setTemporarySearchText(e.target.value)}
									placeholder="Search participants..."
									className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md
                           leading-5 bg-white placeholder-gray-500 focus:outline-none 
                           focus:placeholder-gray-400 focus:ring-1 focus:ring-primary 
                           focus:border-primary sm:text-sm"
								/>
							</div>
						</div>
					</div>

					{/* Leaderboard Table */}
					<div className="overflow-x-auto">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Rank
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Participant
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										Points
									</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{listRank?.results?.map((item) => (
									<tr
										key={item.user_id}
										className="hover:bg-gray-50 transition-colors duration-150">
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="flex items-center">
												{item.place <= 3 ? (
													<div
														className={`
                  w-8 h-8 rounded-full flex items-center justify-center
                  ${
										item.place === 1
											? "bg-yellow-100 text-yellow-800"
											: item.place === 2
											? "bg-gray-100 text-gray-800"
											: "bg-orange-100 text-orange-800"
									}
                `}>
														<FontAwesomeIcon icon={faMedal} />
													</div>
												) : (
													<span className="text-gray-900 font-medium">
														{item.place}
													</span>
												)}
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="flex items-center">
												{item.userAvatarObjectUrl ? (
													<img
														src={item.userAvatarObjectUrl}
														alt=""
														className="h-8 w-8 rounded-full mr-3"
													/>
												) : (
													<div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center mr-3">
														<FontAwesomeIcon
															icon={faUser}
															className="text-primary/60"
														/>
													</div>
												)}
												<div className="text-sm font-medium text-gray-900">
													{item.userFirstName}
												</div>
											</div>
										</td>
										<td className="px-6 py-4 whitespace-nowrap">
											<div className="text-sm text-gray-900">{item.value}</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</ListWithFilters>
		</Layout>
	);
}

export default MemberLeaderboardGlobalTabularListForChallenge;
