import React from "react";
import { Link } from "react-router-dom";
import PhoneTextFormatter from "./PhoneTextFormatter";
import DateTextFormatter from "./DateTextFormatter";
import DateTimeTextFormatter from "./DateTimeTextFormatter";
import TextWithLineBreaksFormatter from "./TextWithLineBreaksFormatter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle, faCircle, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

function DataDisplayRowText({ label, value, helpText, type = "text" }) {
    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1">
                <div className="text-gray-900">
                    {value ? (
                        <>
                            {type === "text" && value}
                            {type === "text_with_linebreaks" && (
                                <TextWithLineBreaksFormatter text={value} />
                            )}
                            {type === "email" && (
                                <Link to={`mailto:${value}`} className="text-primary-600 hover:text-primary-700">
                                    {value}
                                </Link>
                            )}
                            {type === "url" && (
                                <a 
                                    href={value}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-primary-600 hover:text-primary-700 inline-flex items-center gap-1"
                                >
                                    {value}
                                    <FontAwesomeIcon 
                                        icon={faExternalLinkAlt} 
                                        className="w-3 h-3"
                                    />
                                </a>
                            )}
                            {type === "phone" && <PhoneTextFormatter value={value} />}
                            {type === "datetime" && <DateTimeTextFormatter value={value} />}
                            {type === "date" && <DateTextFormatter value={value} />}
                            {type === "currency" && <span>${value}</span>}
                            {type === "textlist" && (
                                <div className="space-y-2">
                                    {value.map((datum, i) => (
                                        <div key={i} className="flex items-center space-x-2">
                                            <FontAwesomeIcon 
                                                icon={faCircle} 
                                                className="text-primary w-2 h-2"
                                            />
                                            <span>{datum}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {type === "textarea" && (
                                <div className="whitespace-pre-wrap break-words">
                                    {value}
                                </div>
                            )}
                            {type === "jsx" && value}
                        </>
                    ) : (
                        <span className="text-gray-500">-</span>
                    )}
                </div>
                {helpText && (
                    <p className="mt-2 text-sm text-gray-500">{helpText}</p>
                )}
            </div>
        </div>
    );
}

export default DataDisplayRowText;