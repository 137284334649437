import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faLevelUp,
  faGauge,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputField from "../../Reusable/FormInputField";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import { topAlertMessageState, topAlertStatusState } from "../../../AppState";
import Layout from "../../Menu/Layout";
import { postLevelCreateAPI } from "../../../API/level";
import { FILE_UPLOAD_OPTIONS } from "../../../Constants/FieldOptions";
import { BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE } from "../../../Constants/App";

function AdminLevelAdd() {
  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnailType, setThumbnailType] = useState(
		BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
  const [criteria, setCriteria] = useState("");
  const [challengesRequired, setChallengesRequired] = useState(0);

  // Breadcrumb items
  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Levels", link: "/admin/levels", icon: faLevelUp },
    { label: "Add Level", icon: faPlus },
  ];

  // Callbacks
  const onSubmitSuccess = (newLevel) => {
    setTopAlertStatus("success");
    setTopAlertMessage("Level successfully added!");

    // Clear form fields after submission
    setName("");
    setDescription("");
    setThumbnailType(1);
    setThumbnailAttachmentID("");
    setThumbnailAttachmentName("");
    setThumbnailURL("");
    setCriteria("");
    setChallengesRequired(0);

    // Redirect to levels list
    setForceURL("/admin/levels");

    // Clear the alert message after 2 seconds
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onSubmitError = (error) => {
    console.error("onSubmitError: Failed to save level", error);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to add level.");
    setErrors(error);

    // Clear the alert message after 2 seconds
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  };

  const onSubmitDone = () => {
    setFetching(false);
  };

  const onSubmitClick = () => {
    setFetching(true);
    setErrors({});

    let newLevel = {
      name,
      description,
      thumbnail_type: parseInt(thumbnailType),
      thumbnail_upload: thumbnailAttachmentID,
      thumbnail_url: thumbnailURL,
      num_challenges: parseInt(challengesRequired),
    };
    postLevelCreateAPI(newLevel, onSubmitSuccess, onSubmitError, onSubmitDone);
  };

  // Redirect if needed
  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems}>
      <div className="animate-fade-in">
        <div className="bg-white rounded-xl shadow-lg border border-gray-200 p-8">
          {/* Enhanced Header */}
          <div className="flex items-center mb-8">
            <div className="bg-primary/10 p-3 rounded-lg mr-4">
              <FontAwesomeIcon icon={faPlus} className="text-primary text-2xl" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Add Level</h1>
              <p className="text-gray-500 text-sm mt-1">Create a new level for your organization</p>
            </div>
          </div>

          <FormErrorBox errors={errors} />

          {isFetching ? (
            <PageLoadingContent displayMessage={"Please wait..."} />
          ) : (
            <>
              {/* Form Content */}
              <div className="grid md:grid-cols-2 gap-8 mt-6">
                {/* Left Column - Basic Information */}
                <div className="space-y-6">
                  <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
                      Basic Information
                    </h3>
                    
                    <div className="space-y-6">
                      <FormInputField
                        name="name"
                        label="Level Name"
                        placeholder="Enter the level name (e.g., Level 1)"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        isRequired={true}
                        errorText={errors?.name}
                        className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
                      />

                      <FormTextareaField
                        name="description"
                        label="Description"
                        placeholder="Describe the level"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        isRequired={true}
                        errorText={errors?.description}
                        className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
                        rows={4}
                      />

                      <FormInputField
                        name="challengesRequired"
                        label="Challenges Required"
                        type="number"
                        value={challengesRequired}
                        onChange={(e) => setChallengesRequired(e.target.value)}
                        isRequired={true}
                        errorText={errors?.num_challenges}
                        className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
                      />
                    </div>
                  </div>
                </div>

                {/* Right Column - Thumbnail Settings */}
                <div className="space-y-6">
                  <div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
                    <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
                      Thumbnail Settings
                    </h3>

                    <div className="space-y-6">
                      <FormSelectField
                        name="thumbnailType"
                        label="Thumbnail Type"
                        value={thumbnailType}
                        onChange={(e) => {
                          const newType = parseInt(e.target.value);
                          setThumbnailType(newType);
                          // Clear relevant fields when switching types
                          if (
                            newType ===
                            BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
                          ) {
                            setThumbnailURL("");
                          } else {
                            setThumbnailAttachmentID("");
                            setThumbnailAttachmentName("");
                          }
                        }}
                        options={FILE_UPLOAD_OPTIONS}
                        errorText={errors?.thumbnail_type}
                        className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
                      />

                      {thumbnailType === 1 ? (
                        <FormAttachmentField
                          name="thumbnail"
                          placeholder="Choose an image"
                          errorText={errors && errors.thumbnail}
                          attachmentID={thumbnailAttachmentID}
                          setAttachmentID={setThumbnailAttachmentID}
                          attachmentFilename={thumbnailAttachmentName}
                          setAttachmentFilename={setThumbnailAttachmentName}
                          helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
                          validationText="Maximum size: 10MB"
                          disabled={false}
                          acceptedFileTypes="image/*"
                        />
                      ) : (
                        <FormInputField
                          label="Thumbnail URL"
                          name="thumbnailURL"
                          type="url"
                          placeholder="Enter thumbnail URL"
                          value={thumbnailURL}
                          errorText={errors?.thumbnailUrl}
                          onChange={(e) => {
                            setThumbnailURL(e.target.value);
                            setThumbnailAttachmentID("");
                            setThumbnailAttachmentName("");
                          }}
                          helpText="Enter the URL of the thumbnail image"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer Actions */}
              <div className="flex justify-end space-x-4 mt-8 pt-6 border-t border-gray-200">
                <Link
                  to="/admin/levels"
                  className="px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-all duration-300 shadow-sm hover:shadow-md"
                >
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                  Cancel
                </Link>
                <button
                  onClick={onSubmitClick}
                  disabled={isFetching}
                  className={`
                    inline-flex items-center px-6 py-2 rounded-lg text-white
                    ${isFetching 
                      ? 'bg-gray-400 cursor-not-allowed' 
                      : 'bg-primary hover:bg-primary-dark'}
                    transition-all duration-300 shadow-sm hover:shadow-md
                  `}
                >
                  <FontAwesomeIcon 
                    icon={faCheckCircle} 
                    className={`mr-2 ${isFetching ? 'animate-spin' : ''}`} 
                  />
                  {isFetching ? "Saving..." : "Save Level"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default AdminLevelAdd;
