import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTags} from "@fortawesome/free-solid-svg-icons";

import {getMemberDetailAPI} from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";

// Read-only TagCard Component
function TagCard({tag}) {
	return (
		<div className="bg-darkShade/7 rounded-2xl shadow-sm border border-gray-100 p-3">
			<div className="flex items-start gap-3 mb-4">
				<div className="w-10 h-10 rounded-xl bg-blue-100 flex items-center justify-center flex-shrink-0">
					<svg
						className="w-5 h-5 text-blue-600"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A2 2 0 013 12V7a4 4 0 014-4z"
						/>
					</svg>
				</div>

				<div className="flex-1">
					<h3 className="text-base font-semibold text-gray-900 mb-1">
						{tag.text}
					</h3>
					{tag.description && (
						<p className="text-sm text-gray-500 line-clamp-2">
							{tag.description}
						</p>
					)}
				</div>
			</div>
		</div>
	);
}

function AdminMemberDetailForTags() {
	const {bid, id} = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [tags, setTags] = useState([]);
	const [error, setError] = useState(null);

	////
	//// API Callbacks
	////

	function onMemberDetailSuccess(response) {
		setTags(response.tags || []);
		setError(null);
	}

	function onMemberDetailError(apiErr) {
		setError(apiErr.message || "An error occurred while loading tags");
		console.error("Member Detail API Error:", apiErr);
	}

	function onMemberDetailDone() {
		setIsLoading(false);
	}

	////
	//// Lifecycle
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setIsLoading(true);
			setError(null);
			getMemberDetailAPI(
				id,
				onMemberDetailSuccess,
				onMemberDetailError,
				onMemberDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Render
	////

	if (isLoading) {
		return <PageLoadingContent displayMessage="Loading tags..." />;
	}

	if (error) {
		return (
			<div className="p-4 text-red-600 bg-red-50 rounded-lg">
				Error loading tags: {error}
			</div>
		);
	}

	return (
		<div className="space-y-6">
			{/*Tags Section */}
			<section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
				<h3 className="text-lg font-bold text-textDark flex items-center">
					<FontAwesomeIcon icon={faTags} className="mr-3 text-primaryColor" />
					Tags
				</h3>

				<div className="mt-6">
					{tags.length === 0 ? (
						<div className="text-center py-12 bg-gray-50 rounded-lg">
							<div className="w-12 h-12 mx-auto rounded-xl bg-main flex items-center justify-center mb-4">
								<FontAwesomeIcon icon={faTags} className="w-6 h-6 text-white" />
							</div>
							<h3 className="text-lg font-semibold text-gray-900 mb-2">
								No Tags
							</h3>
							<p className="text-sm text-gray-500">
								This member doesn't have any tags assigned.
							</p>
						</div>
					) : (
						<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
							{tags.map((tag) => (
								<TagCard key={tag.id} tag={tag} />
							))}
						</div>
					)}
				</div>
			</section>
		</div>
	);
}

export default AdminMemberDetailForTags;
