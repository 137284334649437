import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faDumbbell,
	faEye,
	faPencil,
	faTrashCan,
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faArrowUpRightFromSquare,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
	faSave,
	faClose,
	faEdit,
	faTrash,
	faPencilAlt,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {getExerciseListAPI, deleteExerciseAPI} from "../../../API/Exercise";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	exercisesFilterShowState,
	exercisesFilterTemporarySearchTextState,
	exercisesFilterActualSearchTextState,
	exercisesFilterCategoryState,
	exercisesFilterMovementTypeState,
	exercisesFilterStatusState,
	exercisesFilterGenderState,
	exercisesFilterVideoTypeState,
	exercisesFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import FormMultiSelectFieldForTags from "../../Reusable/FormMultiSelectFieldForTags";
import {
	PAGE_SIZE_OPTIONS,
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_MOMENT_TYPE_MAP,
	EXERCISE_CATEGORY_MAP,
	EXERCISE_TYPE_MAP,
	EXERCISE_VIDEO_FILE_TYPE_MAP,
	EXERCISE_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";
import {
	ListHeader,
	TableCell,
	DataListDesktop,
	DataListMobile,
	ListFilter,
} from "../../Reusable/AdminList";
import Modal from "../../Reusable/Modal";

function AdminExerciseList() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(exercisesFilterShowState);
	const [showExerciseFilter, setShowExerciseFilter] = useState(false);
	const [sort, setSort] = useRecoilState(exercisesFilterSortState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		exercisesFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		exercisesFilterActualSearchTextState
	);
	const [category, setCategory] = useRecoilState(exercisesFilterCategoryState);
	const [movementType, setMovementType] = useRecoilState(
		exercisesFilterMovementTypeState
	);
	const [status, setStatus] = useRecoilState(exercisesFilterStatusState);
	const [gender, setGender] = useRecoilState(exercisesFilterGenderState);
	const [videoType, setVideoType] = useRecoilState(
		exercisesFilterVideoTypeState
	);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [selectedExerciseForDeletion, setSelectedExerciseForDeletion] =
		useState(null);
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");
	const [tags, setTags] = useState([]);

	////
	//// API.
	////

	function onExerciseListSuccess(response) {
		console.log("onExerciseListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onExerciseListError(apiErr) {
		console.log("onExerciseListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onExerciseListDone() {
		console.log("onExerciseListDone: Starting...");
		setFetching(false);
	}

	function onExerciseDeleteSuccess(response) {
		console.log("onExerciseDeleteSuccess: Starting...");
		setTopAlertMessage("Exercise deleted successfully");
		setTopAlertStatus("success");
		setSelectedExerciseForDeletion(null);

		// Refresh the list after successful deletion
		fetchList(
			currentCursor,
			pageSize,
			actualSearchText,
			category,
			movementType,
			status,
			gender,
			videoType,
			sort,
			tags
		);
	}

	function onExerciseDeleteError(apiErr) {
		console.log("onExerciseDeleteError: Starting...");
		setErrors(apiErr);
		setTopAlertMessage("Failed to delete exercise");
		setTopAlertStatus("danger");
	}

	function onExerciseDeleteDone() {
		console.log("onExerciseDeleteDone: Starting...");
		setSelectedExerciseForDeletion(null);
	}

	const handleDeleteClick = () => {
		console.log("handleDeleteClick - Exercise:", selectedExerciseForDeletion);

		if (!selectedExerciseForDeletion?.id) {
			console.error("No exercise selected for deletion");
			return;
		}

		deleteExerciseAPI(
			selectedExerciseForDeletion.id,
			onExerciseDeleteSuccess,
			onExerciseDeleteError,
			onExerciseDeleteDone
		);
	};

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, cat, mt, st, g, vt, sbv, t) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (cat !== undefined && cat !== null && cat !== "") {
			params.set("category", cat);
		}
		if (mt !== undefined && mt !== null && mt !== "") {
			params.set("movement_type", mt);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status", st);
		}
		if (g !== undefined && g !== null && g !== "") {
			params.set("gender", g);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}
		if (t !== undefined && t !== null && t !== "") {
			params.set("tags", t);
		}

		getExerciseListAPI(
			params,
			onExerciseListSuccess,
			onExerciseListError,
			onExerciseListDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	const onSelectExerciseForDeletion = (e, datum) => {
		console.log("onSelectExerciseForDeletion", datum);
		setSelectedExerciseForDeletion(datum);
	};

	const onClearFilterClick = () => {
		setTemporarySearchText("");
		setActualSearchText("");
		setCategory("");
		setMovementType("");
		setStatus("");
		setGender("");
		setVideoType("");
		setShowExerciseFilter(false);
		// Trigger search with cleared filters
		fetchList("", pageSize, "", "", "", "", "", "", sort, []);
	};

	const onToggleFilterClick = () => {
		setShowExerciseFilter(!showExerciseFilter);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				category,
				movementType,
				status,
				gender,
				videoType,
				sort,
				tags
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentCursor,
		pageSize,
		actualSearchText,
		category,
		movementType,
		status,
		gender,
		videoType,
		sort,
		tags,
	]);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Exercises", icon: faDumbbell},
	];

	////
	//// Component rendering.
	////

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 py-8">
				{/* Main Content */}
				<div className="space-y-6">
					{/* Header Section */}
					<ListHeader
						title="Exercises"
						icon={faDumbbell}
						onRefresh={() =>
							fetchList(
								currentCursor,
								pageSize,
								actualSearchText,
								category,
								movementType,
								status,
								gender,
								videoType,
								sort,
								tags
							)
						}
						onToggleFilter={onToggleFilterClick}
						addLink="/admin/exercises/add"
						addLabel="New"
					/>

					{/* Filter Section */}
					{showExerciseFilter && (
						<ListFilter onClear={onClearFilterClick}>
							<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
								<div>
									<FormInputFieldWithButton
										label={"Search"}
										name="temporarySearchText"
										type="text"
										placeholder="Search by name"
										value={temporarySearchText}
										helpText=""
										onChange={(e) => setTemporarySearchText(e.target.value)}
										isRequired={true}
										maxWidth="100%"
										buttonLabel={
											<>
												<FontAwesomeIcon className="fas" icon={faSearch} />
											</>
										}
										onButtonClick={onSearchButtonClick}
									/>
								</div>
								<div>
									<FormSelectField
										label="Category"
										name="category"
										placeholder="Pick"
										selectedValue={category}
										errorText={errors && errors.category}
										helpText=""
										onChange={(e) => setCategory(parseInt(e.target.value))}
										options={EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION}
									/>
								</div>
								<div>
									<FormSelectField
										label="Movement Type"
										name="movementType"
										placeholder="Pick"
										selectedValue={movementType}
										errorText={errors && errors.movementType}
										helpText=""
										onChange={(e) => setMovementType(parseInt(e.target.value))}
										options={EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION}
									/>
								</div>
								<div>
									<FormSelectField
										label="Status"
										name="status"
										placeholder="Pick"
										selectedValue={status}
										errorText={errors && errors.status}
										helpText=""
										onChange={(e) => setStatus(parseInt(e.target.value))}
										options={EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION}
									/>
								</div>
								<div>
									<FormSelectField
										label="Gender"
										name="gender"
										placeholder="Pick"
										selectedValue={gender}
										errorText={errors && errors.gender}
										helpText=""
										onChange={(e) => setGender(e.target.value)}
										options={EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION}
									/>
								</div>
								<div>
									<FormSelectField
										label="Video Type"
										name="videoType"
										placeholder="Pick"
										selectedValue={videoType}
										errorText={errors && errors.videoType}
										helpText=""
										onChange={(e) => setVideoType(e.target.value)}
										options={EXERCISE_VIDEO_FILE_TYPE_OPTIONS_WITH_EMPTY_OPTION}
									/>
								</div>
							</div>
						</ListFilter>
					)}

					{/* Content Section */}
					{isFetching ? (
						<PageLoadingContent displayMessage="Please wait..." />
					) : (
						<>
							<FormErrorBox errors={errors} />
							{listData &&
							listData.results &&
							(listData.results.length > 0 || previousCursors.length > 0) ? (
								<div className="animate-fade-in">
									{/* Desktop View */}
									<div className="hidden md:block">
										<DataListDesktop
											headers={[
												{label: "Name", className: "w-1/2"},
												{label: "Gender", className: "w-1/4"},
												{label: "Movement Type", className: "w-1/4"},
												{label: "Category", className: "w-1/4"},
												{label: "Actions", className: "w-1/4 text-right"},
											]}
											data={listData.results}
											renderRow={(exercise) => (
												<tr key={exercise.id}>
													<TableCell>{exercise.name}</TableCell>
													<TableCell>{exercise.gender}</TableCell>
													<TableCell>
														{EXERCISE_MOMENT_TYPE_MAP[exercise.movementType]}
													</TableCell>
													<TableCell>
														{EXERCISE_CATEGORY_MAP[exercise.category]}
													</TableCell>
													<TableCell>
														{/* Action buttons */}
														<div className="flex justify-end space-x-2">
															<Link
																to={`/admin/exercise/${exercise.id}`}
																className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
																<FontAwesomeIcon
																	icon={faEye}
																	className="mr-2 h-4 w-4"
																/>
																View
															</Link>
															<Link
																to={`/admin/exercise/${exercise.id}/update`}
																className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
																<FontAwesomeIcon
																	icon={faPencilAlt}
																	className="mr-2 h-4 w-4"
																/>
																Edit
															</Link>
															<button
																onClick={() =>
																	onSelectExerciseForDeletion(exercise)
																}
																className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
																<FontAwesomeIcon
																	icon={faTrashAlt}
																	className="mr-2 h-4 w-4"
																/>
																Delete
															</button>
														</div>
													</TableCell>
												</tr>
											)}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>

									{/* Mobile View */}
									<div className="md:hidden">
										<DataListMobile
											data={listData.results}
											renderItem={(exercise) => (
												<div
													key={exercise.id}
													className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
													<div className="space-y-3">
														{/* Name */}
														<div>
															<h3 className="font-medium text-gray-900">
																{exercise.name}
															</h3>
														</div>

														{/* Details Grid */}
														<div className="grid grid-cols-2 gap-3 text-sm">
															<div>
																<span className="text-gray-500">Gender:</span>
																<span className="ml-2 text-gray-900">
																	{exercise.gender}
																</span>
															</div>
															<div>
																<span className="text-gray-500">
																	Movement Type:
																</span>
																<span className="ml-2 text-gray-900">
																	{
																		EXERCISE_MOMENT_TYPE_MAP[
																			exercise.movementType
																		]
																	}
																</span>
															</div>
															<div>
																<span className="text-gray-500">Category:</span>
																<span className="ml-2 text-gray-900">
																	{EXERCISE_CATEGORY_MAP[exercise.category]}
																</span>
															</div>
															<div>
																<span className="text-gray-500">Status:</span>
																<span className="ml-2 text-gray-900">
																	{EXERCISE_STATUS_MAP[exercise.status]}
																</span>
															</div>
														</div>

														{/* Actions */}
														<div className="flex justify-end space-x-3 pt-3 border-t border-gray-100">
															<Link
																to={`/admin/exercises/${exercise.id}`}
																className="text-primary hover:text-primary-dark transition-colors">
																<FontAwesomeIcon
																	icon={faEdit}
																	className="mr-1"
																/>
																Edit
															</Link>
															<button
																onClick={() =>
																	onSelectExerciseForDeletion(exercise)
																}
																className="text-red-600 hover:text-red-700 transition-colors">
																<FontAwesomeIcon
																	icon={faTrash}
																	className="mr-1"
																/>
																Delete
															</button>
														</div>
													</div>
												</div>
											)}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>
								</div>
							) : (
								<div
									className="animate-fade-in min-h-[300px] bg-gray-50 rounded-lg 
                          flex items-center justify-center">
									<div className="text-center p-8">
										<p className="text-3xl font-bold text-gray-400 mb-4">
											<FontAwesomeIcon icon={faTable} className="mr-2" />
											No Exercises
										</p>
										<p className="text-gray-600">
											No exercises found.{" "}
											<Link
												to="/admin/exercises/add"
												className="text-primary hover:text-primary-dark transition-colors">
												Click here
												<FontAwesomeIcon icon={faArrowRight} className="ml-1" />
											</Link>{" "}
											to create your first exercise.
										</p>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>

			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={!!selectedExerciseForDeletion}
				onClose={() => setSelectedExerciseForDeletion(null)}
				header="Confirm Deletion">
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to delete this exercise? This action cannot be
						undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setSelectedExerciseForDeletion(null)}
							className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors">
							Cancel
						</button>
						<button
							onClick={handleDeleteClick}
							className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-lg transition-colors">
							Delete
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default AdminExerciseList;
