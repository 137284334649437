import React, { useState, useEffect, useRef } from "react";
import Scroll from "react-scroll";
import moment from "moment";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import { getMySummaryAPI } from "../../../API/Biometric";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Slideshow from "./Slider";

const ActivityStream = () => {
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  const [errors, setErrors] = useState({});
  const [datum, setDatum] = useState(null);
  const [isFetching, setFetching] = useState(false);

  const getDatum = (user) => {
    if (!user) {
      console.log("user does not have a device, prevented pulling data.");
      return;
    }

    const connectedApps = user.healthAppConnections
      ? user.healthAppConnections.filter((app) => app.connected === "CONNECTED")
      : [];

    if (connectedApps.length > 0) {
      setFetching(true);
      setErrors({});
      let params = new Map();
      params.set("user_id", user.id);

      getMySummaryAPI(user.id, onSummarySuccess, onSummaryError, onSummaryDone);
    }
  };

  const onSummarySuccess = (response) => {
    setDatum(response);
    console.log("onSummarySuccess:", response);
  };

  const onSummaryError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
    console.error("onSummaryError:", apiErr);
  };

  const onSummaryDone = () => {
    setFetching(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDatum(currentUser);

    return () => {};
  }, [currentUser]);

  const formatToHours = (end) => {
    return moment(end).format("ha"); // Formats the end to hour with am/pm
  };

  const transformData = (barData, label, text, timeframe, mode, dataType) => {
    let formatFunction;

    switch (timeframe) {
      case "hours":
        formatFunction = (item) =>
          formatToHours(getDateForFormatting(item, timeframe));
        break;
      case "week":
        formatFunction = (item) =>
          moment(getDateForFormatting(item, timeframe)).format("ddd");
        break;
      case "month":
        formatFunction = (item) =>
          moment(getDateForFormatting(item, timeframe)).format("MMM D");
        break;
      case "year":
        formatFunction = (item) =>
          moment(getDateForFormatting(item, timeframe)).format("MMM YYYY");
        break;
      default:
        formatFunction = (item) =>
          formatToHours(getDateForFormatting(item, timeframe));
        break;
    }

    const dataset = {
      label: ` ${label}`,
      data: barData.map((item) => item.data[dataType] || 0),
      backgroundColor:
        dataType === "steps"
          ? "#5374DF"
          : dataType === "distance"
          ? "#E1BD67"
          : "#DF5353",
      borderColor:
        dataType === "steps"
          ? "rgba(54, 162, 235, 1)"
          : dataType === "distance"
          ? "#E1BD67"
          : "#DF5353",
      borderWidth: 0,
      borderRadius: 5,
      borderSkipped: false,
    };

    return {
      text: text,
      labels: barData.map(formatFunction),
      datasets: [dataset],
    };
  };

  const getDateForFormatting = (item, timeframe) => {
    if (item.data.date) {
      return item.data.date;
    }

    if (timeframe === "week" || timeframe === "month" || timeframe === "year") {
      return item.startDate; // Use startDate when date is empty for these timeframes
    }

    return ""; // Default empty string if no valid date found
  };

  return (
    <div className="card has-background-primary">
      <div className="card-content">
        <p className="title is-6 has-text-centered">Recent Trends</p>
        {isFetching ? (
          <PageLoadingContent displayMessage="Loading..." />
        ) : (
          <>
            {datum ? (
              <Slideshow datum={datum} transformData={transformData} />
            ) : (
              <FormErrorBox errors={errors} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityStream;

// Datum not working
