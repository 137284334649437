import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus, faRefresh, faFilter, faFilterCircleXmark,
  faChevronLeft, faChevronRight
} from "@fortawesome/free-solid-svg-icons";

const ListHeader = ({ 
  title, 
  icon, 
  onRefresh, 
  onToggleFilter, 
  addLink, 
  addLabel = "Add New" 
}) => (
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 sm:mb-6 gap-4 sm:gap-0">
    <div>
      <h1 className="text-xl sm:text-2xl font-bold text-gray-900 flex items-center">
        {icon && <FontAwesomeIcon className="mr-2 sm:mr-3" icon={icon} />}
        {title}
      </h1>
    </div>
    <div className="flex flex-wrap items-center gap-2 sm:gap-3 w-full sm:w-auto">
      {onRefresh && <button
        onClick={onRefresh}
        className="inline-flex items-center px-3 sm:px-4 py-2 bg-white text-primary 
                 hover:text-primary-dark rounded-lg border border-gray-200 
                 hover:border-primary transition-all duration-300 shadow-sm
                 text-sm sm:text-base flex-1 sm:flex-none justify-center sm:justify-start"
      >
        <FontAwesomeIcon className="mr-2" icon={faRefresh} />
        <span className="sm:inline">Refresh</span>
      </button>}
      
      {onToggleFilter && <button
        onClick={onToggleFilter}
        className="inline-flex items-center px-3 sm:px-4 py-2 bg-white text-gray-700 
                 hover:text-primary rounded-lg border border-gray-200 
                 hover:border-primary transition-all duration-300 shadow-sm
                 text-sm sm:text-base flex-1 sm:flex-none justify-center sm:justify-start"
      >
        <FontAwesomeIcon className="mr-2" icon={faFilter} />
        <span className="sm:inline">Filter</span>
      </button>}
      
      {addLink && (
        <Link 
          to={addLink}
          className="inline-flex items-center px-3 sm:px-4 py-2 bg-primary 
                   hover:bg-primary-dark text-white rounded-lg 
                   transition-all duration-300 shadow-sm
                   text-sm sm:text-base flex-1 sm:flex-none justify-center sm:justify-start"
        >
          <FontAwesomeIcon className="mr-2" icon={faPlus} />
          <span className="sm:inline">{addLabel}</span>
        </Link>
      )}
    </div>
  </div>
);

const ListFilter = ({ children, onClear }) => (
  <div className="bg-white rounded-xl p-4 sm:p-6 shadow-sm mb-4 sm:mb-6 border border-gray-100">
    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 sm:gap-0 mb-4">
      <div className="font-semibold text-gray-900 flex items-center text-sm sm:text-base">
        <FontAwesomeIcon className="mr-2" icon={faFilter} />
        Filter Options
      </div>
      <button
        onClick={onClear}
        className="text-primary hover:text-primary-dark transition-colors duration-300 
                 flex items-center text-sm sm:text-base w-full sm:w-auto justify-center sm:justify-start"
      >
        <FontAwesomeIcon className="mr-2" icon={faFilterCircleXmark} />
        Clear Filters
      </button>
    </div>
    <div className="space-y-4">
      {children}
    </div>
  </div>
);

const TableHeader = ({ children, className = "" }) => (
  <th className={`px-4 sm:px-6 py-2 sm:py-3 text-left text-xs sm:text-sm font-medium text-gray-500 uppercase tracking-wider ${className}`}>
    {children}
  </th>
);

const TableCell = ({ children, className = "" }) => (
  <td className={`px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-sm ${className}`}>
    {children}
  </td>
);

const DataListDesktop = ({ 
  headers,
  data,
  renderRow,
  pageSize,
  setPageSize,
  previousCursors,
  hasNextPage,
  onPreviousClicked,
  onNextClicked,
  pageSizeOptions = [10, 25, 50, 100]
}) => (
  <div className="bg-white overflow-hidden rounded-xl border border-gray-200 shadow-sm">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <TableHeader key={index} className={header.className}>
                {header.label}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item, index) => renderRow(item, index))}
        </tbody>
      </table>
    </div>

    <div className="bg-white px-4 sm:px-6 py-3 sm:py-4 flex flex-col sm:flex-row items-center justify-between border-t border-gray-200 gap-3 sm:gap-0">
      <div className="flex items-center space-x-2 text-sm text-gray-700 w-full sm:w-auto justify-center sm:justify-start">
        <span>Show</span>
        <select
          value={pageSize}
          onChange={(e) => setPageSize(parseInt(e.target.value))}
          className="border-gray-300 rounded-md focus:ring-primary focus:border-primary text-sm"
        >
          {pageSizeOptions.map(size => (
            <option key={size} value={size}>{size}</option>
          ))}
        </select>
        <span>entries</span>
      </div>
      
      <div className="flex items-center space-x-2 w-full sm:w-auto justify-center sm:justify-start">
        <button
          onClick={onPreviousClicked}
          disabled={previousCursors.length === 0}
          className={`relative inline-flex items-center px-2 sm:px-3 py-2 rounded-lg border
            ${previousCursors.length === 0 
              ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary transition-colors duration-300'
            }`}
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
        </button>
        <button
          onClick={onNextClicked}
          disabled={!hasNextPage}
          className={`relative inline-flex items-center px-2 sm:px-3 py-2 rounded-lg border
            ${!hasNextPage
              ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
              : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary transition-colors duration-300'
            }`}
        >
          <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
        </button>
      </div>
    </div>
  </div>
);

const DataListMobile = ({
  data,
  renderItem,
  pageSize,
  setPageSize,
  previousCursors,
  hasNextPage,
  onPreviousClicked,
  onNextClicked,
  pageSizeOptions = [10, 25, 50, 100]
}) => (
  <div className="space-y-4">
    <div className="grid gap-4">
      {data.map((item, index) => renderItem(item, index))}
    </div>

    <div className="bg-white rounded-lg shadow-sm p-4 border border-gray-200">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-3 sm:gap-0">
        <div className="flex items-center space-x-2 text-sm text-gray-700 w-full sm:w-auto justify-center sm:justify-start">
          <span>Show</span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            className="border-gray-300 rounded-md focus:ring-primary focus:border-primary text-sm"
          >
            {pageSizeOptions.map(size => (
              <option key={size} value={size}>{size}</option>
            ))}
          </select>
          <span>entries</span>
        </div>
        
        <div className="flex space-x-2 w-full sm:w-auto justify-center sm:justify-start">
          <button
            onClick={onPreviousClicked}
            disabled={previousCursors.length === 0}
            className={`p-2 rounded-lg border flex-1 sm:flex-none
              ${previousCursors.length === 0 
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary transition-colors duration-300'
              }`}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
          </button>
          <button
            onClick={onNextClicked}
            disabled={!hasNextPage}
            className={`p-2 rounded-lg border flex-1 sm:flex-none
              ${!hasNextPage
                ? 'bg-gray-50 text-gray-400 cursor-not-allowed'
                : 'bg-white text-gray-700 hover:bg-gray-50 hover:text-primary transition-colors duration-300'
              }`}
          >
            <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

export {
  ListHeader,
  ListFilter,
  DataListDesktop,
  DataListMobile,
  TableHeader,
  TableCell
};