import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCheckCircle, 
  faBuilding,
  faGauge,
  faPencil
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import {
  getOrganizationDetailAPI,
  putOrganizationUpdateAPI,
} from "../../../API/organization";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../../AppState";

function AdminOrganizationUpdate() {
  ////
  //// Global state.
  ////
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    email: "",
    phone: "",
    websiteUrl: "",
    addressLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });

  ////
  //// Event handling.
  ////

  const handleInputChange = (name, e) => {
    setFormData(prev => ({
      ...prev,
      [name]: e.target.value
    }));
  };

  ////
  //// API.
  ////

  const onSubmitClick = (e) => {
    e.preventDefault();
    setFetching(true);
    setErrors({});

    const org = {
      ID: currentUser?.organizationId,
      name: formData.name,
      description: formData.description,
      email: formData.email,
      phone: formData.phone,
      website_url: formData.websiteUrl,
      address_line_1: formData.addressLine1,
      city: formData.city,
      region: formData.region,
      country: formData.country,
      postal_code: formData.postalCode,
    };
    
    putOrganizationUpdateAPI(
      org,
      onOrganizationUpdateSuccess,
      onOrganizationUpdateError,
      onOrganizationUpdateDone
    );
  };

  function onOrganizationDetailSuccess(response) {
    setFormData({
      name: response.name,
      description: response.description,
      email: response.email,
      phone: response.phone,
      websiteUrl: response.website_url,
      addressLine1: response.address_line_1,
      city: response.city,
      region: response.region,
      country: response.country,
      postalCode: response.postal_code,
    });
  }

  function onOrganizationDetailError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onOrganizationDetailDone() {
    setFetching(false);
  }

  function onOrganizationUpdateSuccess(response) {
    setTopAlertMessage("Organization updated successfully");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/organization");
  }

  function onOrganizationUpdateError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed updating organization");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onOrganizationUpdateDone() {
    setFetching(false);
  }

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
      setFetching(true);
      getOrganizationDetailAPI(
        currentUser.organizationId,
        onOrganizationDetailSuccess,
        onOrganizationDetailError,
        onOrganizationDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, []);

  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    { label: 'Dashboard', link: '/dashboard', icon: faGauge },
    { label: 'Organization', link: '/admin/organization', icon: faBuilding },
    { label: 'Edit', icon: faPencil }
  ];

  return (
    <Layout currentUser={currentUser} breadcrumbItems={breadcrumbItems}>
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon 
              icon={faBuilding} 
              className="text-xl text-primary-dark"
            />
            <h1 className="text-2xl font-semibold text-gray-900">
              Edit Organization
            </h1>
          </div>
        </div>

        <FormErrorBox errors={errors} />

        {isFetching ? (
          <PageLoadingContent displayMessage={"Loading..."} />
        ) : (
          <form onSubmit={onSubmitClick} className="space-y-8">
            {/* Basic Information */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-6">
                Basic Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInputField
                  label="Organization Name"
                  name="name"
                  placeholder="Enter organization name"
                  value={formData.name}
                  errorText={errors?.name}
                  onChange={(e) => handleInputChange("name", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Description"
                  name="description"
                  placeholder="Enter description"
                  value={formData.description}
                  errorText={errors?.description}
                  onChange={(e) => handleInputChange("description", e)}
                  disabled={isFetching}
                />
              </div>
            </div>

            {/* Contact Information */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-6">
                Contact Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInputField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                  value={formData.email}
                  errorText={errors?.email}
                  onChange={(e) => handleInputChange("email", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Phone"
                  name="phone"
                  placeholder="Enter phone number"
                  value={formData.phone}
                  errorText={errors?.phone}
                  onChange={(e) => handleInputChange("phone", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Website URL"
                  name="website_url"
                  type="url"
                  placeholder="Enter website URL"
                  value={formData.website_url}
                  errorText={errors?.website_url}
                  onChange={(e) => handleInputChange("website_url", e)}
                  disabled={isFetching}
                />
              </div>
            </div>

            {/* Location Information */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-6">
                Location Information
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <FormInputField
                  label="Address"
                  name="address_line_1"
                  placeholder="Enter street address"
                  value={formData.address_line_1}
                  errorText={errors?.address_line_1}
                  onChange={(e) => handleInputChange("address_line_1", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="City"
                  name="city"
                  placeholder="Enter city"
                  value={formData.city}
                  errorText={errors?.city}
                  onChange={(e) => handleInputChange("city", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Region/State/Province"
                  name="region"
                  placeholder="Enter region"
                  value={formData.region}
                  errorText={errors?.region}
                  onChange={(e) => handleInputChange("region", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Country"
                  name="country"
                  placeholder="Enter country"
                  value={formData.country}
                  errorText={errors?.country}
                  onChange={(e) => handleInputChange("country", e)}
                  disabled={isFetching}
                />
                <FormInputField
                  label="Postal Code"
                  name="postal_code"
                  placeholder="Enter postal code"
                  value={formData.postal_code}
                  errorText={errors?.postal_code}
                  onChange={(e) => handleInputChange("postal_code", e)}
                  disabled={isFetching}
                />
              </div>
            </div>

            <div className="flex justify-between pt-5">
              <Link
                to="/admin/organization"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                Back
              </Link>
              <button
                type="submit"
                disabled={isFetching}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Save Changes
              </button>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
}

export default AdminOrganizationUpdate;