import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import getCustomAxios from "../../Helpers/customAxios";

function DataDisplayRowImage({ label, src, alt }) {
    const handleDownload = async () => {
        try {
            const axios = getCustomAxios(null);
            const response = await axios.get(src, { responseType: 'blob' });
            const blobURL = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = blobURL;
            link.download = src.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    return (
        <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                {label}
            </label>
            <div className="mt-1 space-y-3">
                <div className="relative rounded-lg overflow-hidden border border-gray-200">
                    <img 
                        src={src} 
                        alt={alt} 
                        className="w-full h-auto object-cover"
                    />
                </div>
                <button
                    onClick={handleDownload}
                    type="button"
                    className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                    <FontAwesomeIcon icon={faCloudDownload} className="mr-2 h-4 w-4" />
                    Download Image
                </button>
            </div>
        </div>
    );
}

export default DataDisplayRowImage;