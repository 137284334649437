import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export const FilterSection = ({ title, children }) => (
    <div className="mb-3">
      <h4 className="font-medium text-gray-700 mb-3">{title}</h4>
      {children}
    </div>
  );

const FilterSidebar = ({ children, isCollapsible = false }) => {
    const [isCollapsed, setIsCollapsed] = React.useState(false);
  
    return (
      <div className="w-full">
        <div className="bg-white rounded-lg p-4 shadow-md sticky top-4">
          {isCollapsible && (
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="md:hidden mb-4 p-2 rounded-lg hover:bg-gray-100"
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          )}
          
          <div className={isCollapsed ? 'hidden md:block' : 'block'}>
            <h3 className="font-bold text-lg mb-3 text-gray-900">Filters</h3>
            {children}
          </div>
        </div>
      </div>
    );
  };

  export default FilterSidebar;