import {useState} from "react";
import {Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faCheckCircle,
	faGauge,
	faRibbon,
	faLink,
	faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputField from "../../Reusable/FormInputField";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";

import {postBadgeCreateAPI} from "../../../API/badge";
import {
	BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
	FILE_UPLOAD_OPTIONS,
	FILE_UPLOAD_OPTIONS_MAP,
} from "../../../Constants/FieldOptions";
import {BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE} from "../../../Constants/App";

function AdminBadgeAdd() {
	const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
	const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [thumbnailType, setThumbnailType] = useState(
		BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [criteria, setCriteria] = useState("");

	// Breadcrumb items
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Badges", link: "/admin/badges", icon: faRibbon},
		{label: "Add Badge", icon: faPlus},
	];

	// Callbacks
	const onSubmitSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Badge successfully added!");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Clear form fields after submission
		setName("");
		setDescription("");
		setThumbnailType(1);
		setThumbnailAttachmentID("");
		setThumbnailAttachmentName("");
		setThumbnailURL("");
		setCriteria("");

		// Redirect to badges list
		setForceURL("/admin/badges");
	};

	const onSubmitError = (error) => {
		console.error("onSubmitError: Failed to save badge", error);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed to add badge.");
		setErrors(error);
	};

	const onSubmitDone = () => {
		setFetching(false);
	};

	const onSubmitClick = () => {
		setFetching(true);
		setErrors({});
		postBadgeCreateAPI(
			{
				name,
				description,
				thumbnail_type: parseInt(thumbnailType),
				thumbnail_upload: thumbnailAttachmentID,
				thumbnail_url: thumbnailURL,
				criteria: parseInt(criteria),
			},
			onSubmitSuccess,
			onSubmitError,
			onSubmitDone
		);
	};

	// Redirect if needed
	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 py-8 max-w-container">
				{/* Header Section - Using ListHeader style */}
				<div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 animate-fade-in">
					<div className="mb-4 md:mb-0">
						<h1 className="text-2xl font-bold text-textDark flex items-center">
							<div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center mr-4">
								<FontAwesomeIcon
									icon={faPlus}
									className="text-primary text-xl"
								/>
							</div>
							Add New Badge
						</h1>
					</div>
				</div>

				<FormErrorBox errors={errors} />

				{/* Main Form Card */}
				<div className="bg-white rounded-2xl shadow-lg border border-gray-200 overflow-hidden hover:shadow-xl transition-shadow duration-300">
					<div className="relative">
						{/* Header Accent */}
						<div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-primary via-primary-light to-primary-dark"></div>

						<div className="p-8 space-y-6">
							{/* Badge Information */}
							<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
								<FormInputField
									label="Badge Name"
									name="name"
									placeholder="Enter badge name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									errorMessage={errors?.name}
									maxLength={100}
									required={true}
								/>

								<FormSelectField
									label="Badge Criteria"
									name="criteria"
									placeholder="Select criteria"
									value={criteria}
									options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
									onChange={(e) => setCriteria(e.target.value)}
									errorMessage={errors?.criteria}
								/>
							</div>

							{/* Thumbnail Section */}
							<div className="space-y-6">
								<div className="border-b border-gray-100 pb-4">
									<label className="block text-sm font-medium text-gray-700 mb-1">
										Badge Thumbnail
									</label>
									<p className="text-sm text-gray-500">
										Add your badge image by uploading a file or providing a URL
									</p>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
									{/* Thumbnail Type Selection */}
									<div className="relative">
										<div className="absolute -left-4 top-4 w-1 h-12 bg-primary rounded-r-full opacity-75"></div>
										<FormSelectField
											name="thumbnailType"
											label="Choose Upload Method"
											placeholder="Select thumbnail type"
											value={thumbnailType}
											options={FILE_UPLOAD_OPTIONS}
											onChange={(e) => {
												const newType = parseInt(e.target.value);
												setThumbnailType(newType);
												// Clear relevant fields when switching types
												if (
													newType ===
													BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
												) {
													setThumbnailURL("");
												} else {
													setThumbnailAttachmentID("");
													setThumbnailAttachmentName("");
												}
											}}
											errorMessage={errors?.thumbnailType}
											className="shadow-sm hover:shadow transition-shadow duration-300"
										/>
									</div>

									{/* Upload Section */}
									<div className="relative">
										{thumbnailType ===
										BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
											<div className="group">
												<div
													className="absolute inset-0 bg-gradient-to-r from-primary/5 to-primary/10 rounded-lg -z-10 
                        transform transition-transform duration-300 group-hover:scale-105"></div>
												<FormAttachmentField
													name="thumbnail"
													placeholder="Choose an image"
													errorText={errors && errors.thumbnail}
													attachmentID={thumbnailAttachmentID}
													setAttachmentID={setThumbnailAttachmentID}
													attachmentFilename={thumbnailAttachmentName}
													setAttachmentFilename={setThumbnailAttachmentName}
													helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
													validationText="Maximum size: 10MB"
													disabled={false}
													acceptedFileTypes="image/*"
												/>
											</div>
										) : (
											<FormInputField
												label="Thumbnail URL"
												name="thumbnailURL"
												type="url"
												placeholder="Enter thumbnail URL"
												value={thumbnailURL}
												errorText={errors?.thumbnailUrl}
												onChange={(e) => {
													setThumbnailURL(e.target.value);
													setThumbnailAttachmentID("");
													setThumbnailAttachmentName("");
												}}
												helpText="Enter the URL of the thumbnail image"
											/>
										)}
									</div>
								</div>

								{/* Preview Section - Shows when either upload or URL is provided */}
								{(thumbnailAttachmentID || thumbnailURL) && (
									<div className="mt-6 p-6 bg-gray-50 rounded-xl border border-gray-100">
										<h4 className="text-sm font-medium text-gray-700 mb-4">
											Preview
										</h4>
										<div className="relative w-32 h-32 mx-auto">
											<div className="absolute inset-0 bg-white rounded-lg shadow-md overflow-hidden">
												<img
													src={
														thumbnailURL ||
														`/api/attachments/${thumbnailAttachmentID}`
													}
													alt="Badge Preview"
													className="w-full h-full object-cover object-center"
												/>
											</div>
											<div className="absolute -inset-0.5 bg-gradient-to-r from-primary to-primary-dark rounded-lg blur opacity-20"></div>
										</div>
									</div>
								)}
							</div>

							{/* Description */}
							<FormTextareaField
								label="Description"
								name="description"
								placeholder="Enter badge description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								errorMessage={errors?.description}
								rows={5}
							/>

							{/* Submit Button */}
							<div className="flex justify-end pt-6 border-t border-gray-100">
								<button
									onClick={onSubmitClick}
									disabled={isFetching}
									className={`
                    inline-flex items-center px-6 py-3 rounded-lg text-white
                    ${
											isFetching
												? "bg-gray-400 cursor-not-allowed"
												: "bg-primary hover:bg-primary-dark transform hover:scale-105"
										}
                    transition-all duration-300 shadow-md hover:shadow-lg
                  `}>
									<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
									{isFetching ? "Creating..." : "Create Badge"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminBadgeAdd;
