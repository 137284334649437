import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faCalendar,
  faPenRuler,
  faSave,
  faUser,
  faBolt,
  faGauge,
  faMedal,
  faClock,
  faTrophy,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
  cursorStateForBadges,
} from "../../../AppState";
import FormSelectField from "../../Reusable/FormSelectField";
import {
  BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
  FITNESS_CHALLENGE,
  MAX_WEEK_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";
import FormInputField from "../../Reusable/FormInputField";
import FormDateTimeField from "../../Reusable/FormDateTimeField";
import Modal from "../../Reusable/Modal";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import { getMemberListOptionsAPI } from "../../../API/member";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import { postfitnessChallengeCreateAPI } from "../../../API/FitnessChallenge";
import { AsyncPaginate } from "react-select-async-paginate";
import { getBadgesListAPI } from "../../../API/badge";
import Layout from "../../Menu/Layout";
import { icon } from "@fortawesome/fontawesome-svg-core";

function AdminFitnessChallengeAdd() {
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  const [currentUser] = useRecoilState(currentUserState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [forceURL, setForceURL] = useState("");
  const [Starton, setStarton] = useState("");
  const [duration, setduration] = useState("");
  const [showRuleModal, setshowRuleModal] = useState(false);
  const [selectedRules, setselectedRules] = useState([]);
  const [memoptions, setmemoptions] = useState([]);
  const [users, setusers] = useState([]);
  const [category, setcategory] = useState("");
  const [maxPoints, setMaxPoints] = useState("");
  const [criteria, setCriteria] = useState("");

  // for badges
  const [selectedBadge, setselectedBadge] = useState(null);
  const [earnBadge, setEarnBadge] = useState(false);
  const [cursor, setCursor] = useRecoilState(cursorStateForBadges); // Recoil state for cursor
  const [, setBadges] = useState([]);

  // Breadcrumb items
	const breadcrumbItems = [
    {label:"Dashboard", link:"/admin/dashboard", icon:faGauge},
    {label:"Fitness Challenges", link:"/admin/fitness-challenge", icon:faBolt},
    {label:"Add Challenge", icon:faPlus}
  ]

  const IsRuleSelected = (ruleId) => {
    const index = selectedRules.indexOf(ruleId);
    if (index !== -1) {
      return true;
    }
    return false;
  };
  const ToggleRuleCheckBox = (ruleId) => {
    console.log("ruleid", ruleId);
    if (IsRuleSelected(ruleId)) {
      // If ruleId is present, remove it from the selectedRules array
      setselectedRules((rules) => rules.filter((id) => id !== ruleId));
    } else {
      // If ruleId is not present, add it to the selectedRules array
      setselectedRules((rules) => [...rules, ruleId]);
    }
  };

  const onSubmitClick = () => {
    // Logic to submit data
    setFetching(true);
    let payload = {
      name: name,
      description: description,
      category: parseInt(category),
      duration: parseInt(duration),
      organization_id: currentUser.organizationId,
      rules: selectedRules,
      users: users,
      start_on: Starton,
      max_points: parseInt(maxPoints),
      earns_badge: earnBadge,
      criteria: parseInt(criteria),
      badge_id: selectedBadge?.value,
      badge_name: selectedBadge?.label,
    };

    postfitnessChallengeCreateAPI(payload, onAddSuccess, onAddError, onAddDone);
  };

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("challenge created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/admin/fitness-challenge/" + response.id + "");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  function onListOK(resp) {
    setFetching(false);
    if (resp?.length) {
      setmemoptions(resp);
    }
  }

  function onListNotOK(resp) {
    setErrors(resp);
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed gettiing list");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  }
  function onDone() {
    setFetching(false);
  }

  const loadBadges = async (search, loadedOptions, { cursor, criteria }) => {
    return new Promise((resolve, reject) => {
      let params = new Map();

      params.set("page_size", 10); // Set page size

      if (search) {
        params.set("search", search);
      }

      if (cursor && cursor !== "" && cursor !== "000000000000000000000000") {
        params.set("cursor", cursor); // Use cursor for pagination
      }

      if (criteria) {
        params.set("criteria", parseInt(criteria));
      }

      getBadgesListAPI(
        params,
        (response) => {
          const newBadges = response?.results?.map((badge) => ({
            value: badge.id,
            label: badge.name,
          }));

          setBadges(newBadges);
          setCursor(response.nextCursor);

          // Continue loading if there are more results
          const hasMore =
            response.nextCursor !== "" &&
            response.nextCursor !== "000000000000000000000000";

          if (!response?.results || response.results.length === 0) {
            resolve({
              options: [],
              hasMore: false,
              additional: { cursor: "" },
            });
            return;
          }

          resolve({
            options: [...newBadges],
            hasMore: hasMore, // Continue loading if more results are available
            additional: {
              cursor: response.nextCursor, // Store the new cursor for the next request
            },
          });
        },
        (error) => {
          setErrors(error);
          reject(error);
        }
      );
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMemberListOptionsAPI(
      currentUser.organizationId,
      onListOK,
      onListNotOK,
      onDone
    );
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="min-h-screen bg-gradient-y from-gray-50 to-white py-8">
        <div className="max-w-container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Main Card */}
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100 animate-fade-in">
            {/* Header Section */}
            <div className="bg-gradient-x from-primary/5 via-primary/10 to-primary/5 p-8 border-b border-gray-100">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-white/80 backdrop-blur-sm rounded-xl shadow-sm">
                  <FontAwesomeIcon icon={faPlus} className="text-primary text-xl" />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900 mb-1">
                    Add Fitness Challenge
                  </h1>
                  <p className="text-gray-600">
                    Create a new challenge to motivate and engage your members
                  </p>
                </div>
              </div>
            </div>

            <div className="p-8">
              <FormErrorBox errors={errors} />

              {isFetching ? (
                <PageLoadingContent displayMessage={"Please wait..."} />
              ) : (
                <div className="space-y-8 animate-fade-in">
                  {/* Challenge Basic Info Card */}
                  <div className="bg-gray-50/50 rounded-xl p-6 border border-gray-100 shadow-sm">
                    <h2 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
                      <FontAwesomeIcon icon={faBolt} className="text-primary mr-2" />
                      Basic Information
                    </h2>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <FormInputField
                        name="Name"
                        placeholder="Enter challenge name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        isRequired={true}
                        maxWidth="380px"
                        label="Challenge Name"
                        errorText={errors && errors.name}
                        className="transform transition-all duration-300 focus:scale-105"
                      />

                      <FormSelectField
                        name="category"
                        label="Category"
                        helpText="Choose the type of fitness challenge"
                        errorText={errors && errors.category}
                        options={[
                          { label: "Please choose", value: 0 },
                          { label: "Self Entry Based", value: 2 },
                        ]}
                        maxWidth="380px"
                        selectedValue={category}
                        onChange={(e) => setcategory(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Users Selection Card */}
                  <div className="bg-white rounded-xl p-6 border border-gray-100 shadow-sm hover:shadow-md transition-shadow duration-300">
                    <h2 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
                      <FontAwesomeIcon icon={faUser} className="text-primary mr-2" />
                      Participants
                    </h2>
                    
                    <FormMultiSelectField
                      label="Add users to the challenge"
                      name="users"
                      placeholder="Search and select users"
                      options={memoptions}
                      selectedValues={users}
                      onChange={(e) => {
                        setusers(e.map(option => option.value));
                      }}
                      errorText={errors && errors.users}
                      helpText="Select the users who will participate in this challenge"
                      isRequired={false}
                    />
                  </div>

                  {/* Challenge Details Card */}
                  <div className="bg-gray-50/50 rounded-xl p-6 border border-gray-100 shadow-sm">
                    <h2 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
                      <FontAwesomeIcon icon={faPenRuler} className="text-primary mr-2" />
                      Challenge Details
                    </h2>

                    <div className="space-y-6">
                      <FormTextareaField
                        rows={3}
                        name="Description"
                        placeholder="Describe the challenge objectives and rules"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        isRequired={true}
                        label="Challenge Description"
                        errorText={errors && errors.description}
                        className="w-full"
                      />

                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="flex items-center space-x-3 mb-2">
                            <FontAwesomeIcon icon={faCalendar} className="text-primary" />
                            <span className="text-sm font-medium text-gray-700">Start Date</span>
                          </div>
                          <FormDateTimeField
                            onChange={(date) => setStarton(date)}
                            value={Starton}
                            placeholder="Select start date"
                            name="Starton"
                            errorText={errors && errors.startOn}
                          />
                        </div>

                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="flex items-center space-x-3 mb-2">
                            <FontAwesomeIcon icon={faClock} className="text-primary" />
                            <span className="text-sm font-medium text-gray-700">Duration</span>
                          </div>
                          <FormSelectField
                            options={MAX_WEEK_WITH_EMPTY_OPTIONS}
                            placeholder="Select duration"
                            selectedValue={duration}
                            onChange={(e) => setduration(e.target.value)}
                            errorText={errors && errors.duration}
                          />
                        </div>

                        <div className="bg-white p-4 rounded-lg border border-gray-100">
                          <div className="flex items-center space-x-3 mb-2">
                            <FontAwesomeIcon icon={faTrophy} className="text-primary" />
                            <span className="text-sm font-medium text-gray-700">Max Points</span>
                          </div>
                          <FormInputField
                            name="maxPoints"
                            placeholder="Enter max points"
                            errorText={errors && errors.maxPoints}
                            value={maxPoints}
                            onChange={(e) => setMaxPoints(e.target.value)}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Badge Settings Card */}
                  <div className="bg-white rounded-xl p-6 border border-gray-100 shadow-sm">
                    <h2 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
                      <FontAwesomeIcon icon={faMedal} className="text-primary mr-2" />
                      Badge Settings
                    </h2>

                    <div className="space-y-6">
                      <div className="flex items-center space-x-4 p-4 bg-gray-50 rounded-lg">
                        <FormCheckboxField
                          label="Enable badge rewards for this challenge"
                          checked={earnBadge}
                          onChange={() => setEarnBadge(!earnBadge)}
                        />
                      </div>

                      {earnBadge && (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 animate-slide-up">
                          <FormSelectField
                            name="criteria"
                            label="Award Criteria"
                            helpText="Choose how participants can earn this badge"
                            errorText={errors && errors.criteria}
                            options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
                            selectedValue={criteria}
                            onChange={(e) => setCriteria(e.target.value)}
                          />

                          {criteria && (
                            <div className="space-y-2">
                              <label className="text-sm font-medium text-gray-700">
                                Select Badge
                              </label>
                              <AsyncPaginate
                                value={selectedBadge}
                                loadOptions={loadBadges}
                                onChange={setselectedBadge}
                                isClearable
                                placeholder="Search available badges..."
                                additional={{
                                  cursor: cursor,
                                  criteria: criteria,
                                }}
                                className="w-full"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Rules Section */}
                  <div className="bg-gray-50/50 rounded-xl p-6 border border-gray-100 shadow-sm">
                    <h2 className="text-lg font-semibold text-gray-900 mb-6 flex items-center">
                      <FontAwesomeIcon icon={faPenRuler} className="text-primary mr-2" />
                      Challenge Rules
                    </h2>

                    <div className="space-y-6">
                      <button
                        className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-dark text-white rounded-xl 
                          transition-all duration-300 transform hover:scale-105 hover:shadow-md"
                        onClick={() => setshowRuleModal(true)}
                      >
                        <FontAwesomeIcon icon={faPenRuler} className="mr-2" />
                        Configure Rules
                      </button>

                      {selectedRules.length > 0 && (
                        <div className="bg-white rounded-xl p-6 border border-gray-100">
                          <div className="space-y-3">
                            {selectedRules.map((ruleId) => (
                              <div key={ruleId} 
                                className="flex items-center space-x-3 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                                <FontAwesomeIcon icon={faCheck} className="text-green-500" />
                                <span className="text-gray-700">
                                  {FITNESS_CHALLENGE.find(rule => rule.value === ruleId)?.label}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Action Buttons */}
                  <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 pt-6 border-t border-gray-100">
                    <Link
                      className="inline-flex items-center justify-center px-6 py-3 border border-gray-200 rounded-xl text-gray-700 
                        bg-white hover:bg-gray-50 transition-all duration-300 hover:shadow-md"
                      to="/admin/fitness-challenge"
                    >
                      <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                      Back to Challenges
                    </Link>
                    
                    <button
                      onClick={onSubmitClick}
                      className="inline-flex items-center justify-center px-6 py-3 bg-green-600 hover:bg-green-700 text-white 
                        rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-md
                        disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none disabled:hover:shadow-none"
                      disabled={!(name && description && Starton && duration && selectedRules.length > 0)}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Create Challenge
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Rules Modal */}
          <Modal
            isOpen={showRuleModal}
            onClose={() => setshowRuleModal(false)}
            header="Configure Challenge Rules"
          >
            <div className="p-6 space-y-4">
              {FITNESS_CHALLENGE.map((rule) => (
                <div key={rule.value} 
                  className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200">
                  <FormCheckboxField
                    label={rule.label}
                    checked={IsRuleSelected(rule.value)}
                    onChange={() => ToggleRuleCheckBox(rule.value)}
                  />
                </div>
              ))}

              <div className="flex justify-end pt-4 border-t border-gray-100">
                <button
                  className="inline-flex items-center px-6 py-3 bg-primary hover:bg-primary-dark text-white 
                    rounded-xl transition-all duration-300 transform hover:scale-105 hover:shadow-md"
                  onClick={() => setshowRuleModal(false)}
                >
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  Apply Rules
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
}

export default AdminFitnessChallengeAdd;
