import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faKey, 
    faArrowLeft, 
    faCheckCircle, 
    faUserCircle, 
    faGauge, 
    faUser
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';

import { putAccountChangePasswordAPI } from "../../API/Account";
import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import { 
    topAlertMessageState, 
    topAlertStatusState, 
    currentUserState 
} from "../../AppState";
import Layout from "../Menu/Layout";

function AccountChangePassword() {
    ////
    //// Global state.
    ////
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser] = useRecoilState(currentUserState);

    ////
    //// Component states.
    ////
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeated, setPasswordRepeated] = useState("");

    ////
    //// API.
    ////
    const onSubmitClick = () => {
        setFetching(true);
        setErrors({});

        const submission = {
            old_password: oldPassword,
            password: password,
            password_repeated: passwordRepeated,
        };
        
        putAccountChangePasswordAPI(
            submission,
            onAccountChangePasswordSuccess,
            onAccountChangePasswordError,
            onAccountChangePasswordDone
        );
    }

    function onAccountChangePasswordSuccess(response) {
        setTopAlertMessage("Password updated");
        setTopAlertStatus("success");
        setTimeout(() => {
            setTopAlertMessage("");
        }, 2000);
        setForceURL("/account");
    }

    function onAccountChangePasswordError(apiErr) {
        setErrors(apiErr);
        setTopAlertMessage("Failed submitting");
        setTopAlertStatus("danger");
        setTimeout(() => {
            setTopAlertMessage("");
        }, 2000);
        Scroll.animateScroll.scrollToTop();
    }

    function onAccountChangePasswordDone() {
        setFetching(false);
    }

    ////
    //// Misc.
    ////
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            window.scrollTo(0, 0);
        }
        return () => { mounted = false; }
    }, []);

    ////
    //// Component rendering.
    ////
    if (forceURL !== "") {
        return <Navigate to={forceURL} />
    }

    const breadcrumbItems = [
        {
            label: "Dashboard",
            icon: faGauge,
            link: "/dashboard",
        },
        {
            label: "Account",
            icon: faUser,
            link: "/account",
        },
        {
            label: "Change Password",
            icon: faKey,
        },
    ]

    return (
        <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="bg-white rounded-default shadow-default">
                <div className="p-4 sm:p-6 md:p-8 mx-auto">
                    <FormErrorBox errors={errors} />

                    {isFetching ? (
                        <PageLoadingContent displayMessage={"Submitting..."} />
                    ) : (
                        <div className="space-y-6">
                            {/* Header */}
                            <div className="border-b border-divider pb-4">
                                <h1 className="text-lg sm:text-xl font-primary font-semibold text-darkShade flex items-center">
                                    <FontAwesomeIcon 
                                        className="mr-2 text-burntOrange" 
                                        icon={faKey} 
                                    />
                                    Reset Your Password
                                </h1>
                            </div>

                            {/* Form Fields */}
                            <div className="space-y-4">
                                <FormInputField
                                    label="Old Password"
                                    name="oldPassword"
                                    placeholder="Input your original password"
                                    value={oldPassword}
                                    type="password"
                                    errorText={errors?.oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    required
                                    className="w-full sm:max-w-md"
                                />

                                <FormInputField
                                    label="New Password"
                                    name="password"
                                    placeholder="Input your new password"
                                    value={password}
                                    type="password"
                                    errorText={errors?.password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    className="w-full sm:max-w-md"
                                />

                                <FormInputField
                                    label="Confirm New Password"
                                    name="passwordRepeated"
                                    type="password"
                                    placeholder="Repeat your new password for security"
                                    value={passwordRepeated}
                                    errorText={errors?.passwordRepeated}
                                    onChange={(e) => setPasswordRepeated(e.target.value)}
                                    required
                                    className="w-full sm:max-w-md"
                                />
                            </div>

                            {/* Action Buttons */}
                            <div className="flex flex-col sm:flex-row justify-between gap-3 pt-6 border-t border-divider">
                                <Link
                                    to="/account"
                                    className="btn-secondary order-2 sm:order-1"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                    Back
                                </Link>
                                <button
                                    onClick={onSubmitClick}
                                    className="btn-primary order-1 sm:order-2"
                                    disabled={isFetching}
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AccountChangePassword;