import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faTachometer,
  faPlus,
  faArrowLeft,
  faCheckCircle,
  faUserCircle,
  faGauge,
  faPencil,
  faIdCard,
  faAddressBook,
  faContactCard,
  faChartPie,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import useLocalStorage from "../../Hooks/useLocalStorage";
import { getAccountDetailAPI, putAccountUpdateAPI } from "../../API/Account";
import FormErrorBox from "../Reusable/FormErrorBox";
import FormInputField from "../Reusable/FormInputField";
import FormTextareaField from "../Reusable/FormTextareaField";
import FormRadioField from "../Reusable/FormRadioField";
import FormMultiSelectField from "../Reusable/FormMultiSelectField";
import FormSelectField from "../Reusable/FormSelectField";
import FormCheckboxField from "../Reusable/FormCheckboxField";
import FormCountryField from "../Reusable/FormCountryField";
import FormRegionField from "../Reusable/FormRegionField";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
} from "../../AppState";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import Layout from "../Menu/Layout";
import { HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS } from "../../Constants/FieldOptions";

function AccountUpdate() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeated, setPasswordRepeated] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [agreePromotionsEmail, setHasPromotionalEmail] = useState(true);
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState(0);
  const [howDidYouHearAboutUsOther, setHowDidYouHearAboutUsOther] =
    useState("");

  ////
  //// Event handling.
  ////

  function onAgreePromotionsEmailChange(e) {
    setHasPromotionalEmail(!agreePromotionsEmail);
  }

  ////
  //// API.
  ////

  const onSubmitClick = (e) => {
    console.log("onSubmitClick: Beginning...");
    setFetching(true);

    const submission = {
      Email: email,
      Phone: phone,
      FirstName: firstName,
      LastName: lastName,
      Password: password,
      PasswordRepeated: passwordRepeated,
      CompanyName: companyName,
      PostalCode: postalCode,
      AddressLine1: addressLine1,
      AddressLine2: addressLine2,
      City: city,
      Region: region,
      Country: country,
      AgreePromotionsEmail: agreePromotionsEmail,
      howDidYouHearAboutUs: howDidYouHearAboutUs,
      howDidYouHearAboutUsOther: howDidYouHearAboutUsOther,
    };
    console.log("onSubmitClick, submission:", submission);
    putAccountUpdateAPI(
      submission,
      onAccountUpdateSuccess,
      onAccountUpdateError,
      onAccountUpdateDone
    );
  };

  function onAccountDetailSuccess(response) {
    console.log("onAccountDetailSuccess: Starting...");
    setEmail(response.email);
    setPhone(response.phone);
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setCompanyName(response.companyName);
    setPostalCode(response.postalCode);
    setAddressLine1(response.addressLine1);
    setAddressLine2(response.addressLine2);
    setCity(response.city);
    setRegion(response.region);
    setCountry(response.country);
    setHasPromotionalEmail(response.agreePromotionsEmail);
    setHowDidYouHearAboutUs(response.howDidYouHearAboutUs);
    setHowDidYouHearAboutUsOther(response.howDidYouHearAboutUsOther);
    setCurrentUser(response);
  }

  function onAccountDetailError(apiErr) {
    console.log("onAccountDetailError: Starting...");
    setErrors(apiErr);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAccountDetailDone() {
    console.log("onAccountDetailDone: Starting...");
    setFetching(false);
  }

  function onAccountUpdateSuccess(response) {
    // For debugging purposes only.
    console.log("onAccountUpdateSuccess: Starting...");
    console.log(response);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Account updated");
    setTopAlertStatus("success");
    setTimeout(() => {
      console.log("onAccountUpdateSuccess: Delayed for 2 seconds.");
      console.log(
        "onAccountUpdateSuccess: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // Redirect the user to a new page.
    setForceURL("/account");
  }

  function onAccountUpdateError(apiErr) {
    console.log("onAccountUpdateError: Starting...");
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      console.log("onAccountUpdateError: Delayed for 2 seconds.");
      console.log(
        "onAccountUpdateError: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus
      );
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAccountUpdateDone() {
    console.log("onAccountUpdateDone: Starting...");
    setFetching(false);
  }

  ////
  //// BREADCRUMB
  ////

  const generateBreadcrumbItemLink = (currentUser) => {
    let dashboardLink;
    switch (currentUser.role) {
      case 1:
        dashboardLink = "/root/dashboard";
        break;
      case 2:
        dashboardLink = "/admin/dashboard";
        break;
      case 3:
        dashboardLink = "/trainer/dashboard";
        break;
      case 4:
        dashboardLink = "/dashboard";
        break;
      default:
        dashboardLink = "/"; // Default or error handling
        break;
    }
    return dashboardLink;
  };

  const breadcrumbItems = [
    {
      label: "Dashboard",
      icon: faGauge,
      link: generateBreadcrumbItemLink(currentUser),
    },
    {
      label: "Account",
      icon: faUser,
      link: "/account",
    },
    {
      label: "Edit",
      icon: faPencil,
    },
  ]

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.

      setFetching(true);
      getAccountDetailAPI(
        onAccountDetailSuccess,
        onAccountDetailError,
        onAccountDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, []);
  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }


    return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="bg-white rounded-lg shadow-md p-3 sm:p-4 md:p-6">
        {/* Header */}
        <div className="mb-4 md:mb-6">
          <h1 className="text-lg sm:text-xl font-primary font-semibold text-darkShade flex items-center">
            <FontAwesomeIcon className="mr-2 text-burntOrange" icon={faUserCircle} />
            Account
          </h1>
        </div>

        <FormErrorBox errors={errors} />

        {isFetching ? (
          <PageLoadingContent displayMessage={"Please wait..."} />
        ) : (
          <div className="space-y-6 md:space-y-8">
            {/* Personal Information Section */}
            <section className="bg-lightShade p-3 sm:p-4 rounded-lg">
              <div className="mb-3 md:mb-4">
                <h2 className="text-sm sm:text-base font-primary font-medium text-darkShade flex items-center">
                  <FontAwesomeIcon className="mr-2 text-accent1" icon={faIdCard} />
                  Full Name
                </h2>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4">
                <FormInputField
                  label="First Name"
                  name="firstName"
                  placeholder="Enter first name"
                  value={firstName}
                  errorText={errors?.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="w-full"
                />

                <FormInputField
                  label="Last Name"
                  name="lastName"
                  placeholder="Enter last name"
                  value={lastName}
                  errorText={errors?.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="w-full"
                />
              </div>
            </section>

            {/* Contact Information Section */}
            <section className="bg-lightShade p-3 sm:p-4 rounded-lg">
              <div className="mb-3 md:mb-4">
                <h2 className="text-sm sm:text-base font-primary font-medium text-darkShade flex items-center">
                  <FontAwesomeIcon className="mr-2 text-accent1" icon={faContactCard} />
                  Contact Information
                </h2>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4">
                <FormInputField
                  label="Email"
                  name="email"
                  placeholder="Enter email"
                  value={email}
                  errorText={errors?.email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full"
                />

                <FormInputField
                  label="Phone"
                  name="phone"
                  placeholder="Enter phone number"
                  value={phone}
                  errorText={errors?.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  className="w-full"
                />
              </div>
            </section>

            {/* Address Section */}
            <section className="bg-lightShade p-3 sm:p-4 rounded-lg">
              <div className="mb-3 md:mb-4">
                <h2 className="text-sm sm:text-base font-primary font-medium text-darkShade flex items-center">
                  <FontAwesomeIcon className="mr-2 text-accent1" icon={faAddressBook} />
                  Address
                </h2>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="grid grid-cols-1 gap-3 md:gap-4">
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 md:gap-4">
                  <FormCountryField
                    priorityOptions={["CA", "US", "MX"]}
                    label="Country"
                    name="country"
                    selectedCountry={country}
                    errorText={errors?.country}
                    onChange={(value) => setCountry(value)}
                    required
                    className="w-full"
                  />

                  <FormRegionField
                    label="Province/Territory"
                    name="region"
                    selectedCountry={country}
                    selectedRegion={region}
                    errorText={errors?.region}
                    onChange={(value) => setRegion(value)}
                    required
                    className="w-full"
                  />

                  <FormInputField
                    label="City"
                    name="city"
                    value={city}
                    errorText={errors?.city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                    className="w-full"
                  />

                  <FormInputField
                    label="Postal Code"
                    name="postalCode"
                    value={postalCode}
                    errorText={errors?.postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                    className="w-full"
                  />
                </div>

                <FormInputField
                  label="Address Line 1"
                  name="addressLine1"
                  value={addressLine1}
                  errorText={errors?.addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  required
                  className="w-full"
                />

                <FormInputField
                  label="Address Line 2"
                  name="addressLine2"
                  value={addressLine2}
                  errorText={errors?.addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  className="w-full"
                />
              </div>
            </section>

            {/* Metrics Section */}
            <section className="bg-lightShade p-3 sm:p-4 rounded-lg">
              <div className="mb-3 md:mb-4">
                <h2 className="text-sm sm:text-base font-primary font-medium text-darkShade flex items-center">
                  <FontAwesomeIcon className="mr-2 text-accent1" icon={faChartPie} />
                  Metrics
                </h2>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="space-y-3 md:space-y-4">
                <FormSelectField
                  label="How did you hear about us?"
                  name="howDidYouHearAboutUs"
                  placeholder="Select an option"
                  selectedValue={howDidYouHearAboutUs}
                  errorText={errors?.howDidYouHearAboutUs}
                  onChange={(e) => setHowDidYouHearAboutUs(parseInt(e.target.value))}
                  options={HOW_DID_YOU_HEAR_ABOUT_US_WITH_EMPTY_OPTIONS}
                  className="w-full sm:max-w-sm"
                />

                {howDidYouHearAboutUs === 1 && (
                  <FormInputField
                    label="Other (Please specify):"
                    name="howDidYouHearAboutUsOther"
                    value={howDidYouHearAboutUsOther}
                    errorText={errors?.howDidYouHearAboutUsOther}
                    onChange={(e) => setHowDidYouHearAboutUsOther(e.target.value)}
                    required
                    className="w-full sm:max-w-sm"
                  />
                )}

                <FormCheckboxField
                  label="I agree to receive electronic updates from my local branch and/or The Adrenaline MMA Training & Fitness Center Team."
                  name="agreePromotionsEmail"
                  checked={agreePromotionsEmail}
                  errorText={errors?.agreePromotionsEmail}
                  onChange={onAgreePromotionsEmailChange}
                  className="w-full"
                />
              </div>
            </section>

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row justify-end gap-3 pt-4 sm:pt-6 border-t border-gray-200">
              <button
                onClick={onSubmitClick}
                className="btn-primary order-1 sm:order-2"
                disabled={isFetching}
              >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Save Changes
              </button>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default AccountUpdate;
