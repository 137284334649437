import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faHome, 
    faHeadset, 
    faExclamationTriangle,
    faArrowRight,
    faSearch,
    faCompass,
    faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";

function NotFoundError() {
    return (
        <div className="min-h-screen bg-gradient-to-br from-primary-dark via-primary to-primary-light flex items-center justify-center p-4 sm:p-8 relative overflow-hidden">
            {/* Dynamic Background Elements */}
            <div className="absolute inset-0">
                {/* Animated Patterns */}
                <div className="absolute inset-0 opacity-20">
                    <div className="absolute top-0 left-0 w-[500px] h-[500px] bg-gradient-to-r from-white/20 to-transparent rounded-full mix-blend-overlay filter blur-xl animate-float" />
                    <div className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-gradient-to-l from-white/20 to-transparent rounded-full mix-blend-overlay filter blur-xl animate-float" 
                         style={{ animationDelay: '2s' }} />
                </div>
                
                {/* Grid Pattern Overlay */}
                <div className="absolute inset-0 bg-[url('/path/to/grid-pattern.png')] opacity-5" />
                
                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
            </div>

            <div className="max-w-4xl w-full relative z-10">
                {/* Main Error Card */}
                <div className="bg-white/95 backdrop-blur-2xl rounded-3xl shadow-2xl overflow-hidden border border-white/20">
                    {/* Top Accent Bar */}
                    <div className="h-1.5 bg-gradient-to-r from-primary/40 via-primary to-primary/40 animate-gradient-x" />

                    <div className="p-8 sm:p-12">
                        {/* Status Icon */}
                        <div className="mb-10 flex justify-center">
                            <div className="relative">
                                {/* Ping Animation Ring */}
                                <div className="absolute -inset-4 bg-primary/10 rounded-full animate-ping-slow opacity-75" />
                                
                                {/* Icon Container */}
                                <div className="relative bg-gradient-to-br from-primary/10 to-primary/5 rounded-full p-6 shadow-lg">
                                    <div className="relative animate-float">
                                        <FontAwesomeIcon 
                                            icon={faCompass} 
                                            className="w-12 h-12 text-primary transform rotate-12" 
                                        />
                                        <FontAwesomeIcon 
                                            icon={faMapMarkerAlt}
                                            className="absolute -top-1 -right-1 w-6 h-6 text-primary-dark animate-bounce-gentle" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 404 Display */}
                        <div className="text-center mb-10">
                            <div className="relative inline-block">
                                {/* Glow Effect */}
                                <div className="absolute -inset-4 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-full blur-2xl opacity-75" />
                                
                                {/* Numbers */}
                                <h1 className="relative text-8xl sm:text-9xl font-bold bg-gradient-to-r from-primary-dark via-primary to-primary-light bg-clip-text text-transparent">
                                    <span className="inline-block hover:animate-bounce-gentle">4</span>
                                    <span className="inline-block hover:animate-bounce-gentle mx-2">0</span>
                                    <span className="inline-block hover:animate-bounce-gentle">4</span>
                                </h1>
                            </div>
                        </div>

                        {/* Content Section */}
                        <div className="max-w-2xl mx-auto text-center space-y-6">
                            <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 animate-fade-in">
                                Page Not Found
                            </h2>
                            
                            <p className="text-lg text-gray-600 leading-relaxed animate-fade-in" 
                               style={{ animationDelay: '0.2s' }}>
                                We couldn't find the page you're looking for. 
                                It might have been moved or doesn't exist.
                            </p>

                            {/* Action Buttons */}
                            <div className="flex flex-col sm:flex-row gap-4 justify-center pt-8 animate-fade-in" 
                                 style={{ animationDelay: '0.3s' }}>
                                <Link 
                                    to="/"
                                    className="group relative inline-flex items-center justify-center gap-3 px-8 py-4 
                                        bg-gradient-to-r from-primary to-primary-dark text-white font-semibold rounded-xl
                                        transform transition-all duration-250
                                        hover:from-primary-dark hover:to-primary hover:scale-[1.02] hover:shadow-xl
                                        focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2
                                        active:scale-98"
                                >
                                    <FontAwesomeIcon icon={faHome} 
                                        className="w-5 h-5 transition-transform group-hover:scale-110" 
                                    />
                                    <span>Return Home</span>
                                    <FontAwesomeIcon icon={faArrowRight} 
                                        className="w-5 h-5 transition-transform group-hover:translate-x-1" 
                                    />
                                </Link>
                                
                                <Link 
                                    to="/contact"
                                    className="group inline-flex items-center justify-center gap-3 px-8 py-4
                                        bg-white text-primary font-semibold rounded-xl
                                        border-2 border-primary/20
                                        transform transition-all duration-250
                                        hover:border-primary hover:bg-primary/5 hover:scale-[1.02] hover:shadow-xl
                                        focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2
                                        active:scale-98"
                                >
                                    <FontAwesomeIcon icon={faHeadset} 
                                        className="w-5 h-5 transition-transform group-hover:scale-110" 
                                    />
                                    <span>Contact Support</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Bottom Accent */}
                    <div className="h-px bg-gradient-to-r from-transparent via-primary/20 to-transparent" />
                </div>

                {/* Footer Section */}
                <div className="mt-8 text-center animate-fade-in" style={{ animationDelay: '0.4s' }}>
                    <p className="text-white/90 font-medium mb-3">
                        Need assistance? Our support team is here to help 24/7
                    </p>
                    <div className="w-32 h-0.5 mx-auto bg-gradient-to-r from-white/0 via-white/50 to-white/0 animate-pulse-glow" />
                </div>
            </div>
        </div>
    );
}

export default NotFoundError;