import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faCaretUp, 
  faCaretDown, 
  faPlus,
  faEdit,
  faDumbbell,
  faCalendarWeek,
  faLayerGroup,
  faChevronRight,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function PhasePanel({ phases, onAddWorkout, setSelectedPhase }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    if (phases?.length > 0 && setSelectedPhase) {
      setSelectedPhase(phases[0]);
    }
  }, [phases, setSelectedPhase]);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
    if (setSelectedPhase) {
      setSelectedPhase(phases[index]);
    }
  };

  if (!phases?.length) {
    return (
      <div className="p-8 text-center bg-white rounded-xl border border-gray-200 shadow-sm">
        <div className="mb-6">
          <div className="inline-flex items-center justify-center w-16 h-16 
                         bg-gradient-to-br from-primary/10 to-primary/5 
                         rounded-xl mb-4 transform transition-transform 
                         duration-500 hover:scale-110">
            <FontAwesomeIcon icon={faLayerGroup} className="text-primary text-2xl" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-3">
            No Phases Yet
          </h3>
          <p className="text-gray-500 max-w-sm mx-auto mb-6">
            Get started by creating your first training phase to organize your workouts effectively
          </p>
        </div>
        <Link 
          to="/admin/training-program/new"
          className="inline-flex items-center px-6 py-3 bg-primary 
                   hover:bg-primary-dark text-white rounded-xl
                   transition-all duration-300 shadow-sm text-sm font-medium
                   hover:shadow-md hover:translate-y-[-2px] 
                   active:translate-y-[1px]"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Create First Phase
        </Link>
      </div>
    );
  }

  const renderWorkoutDetails = (routine) => (
    <div className="flex flex-col space-y-2 pl-4">
      {routine.workoutExercises?.map((exercise, idx) => (
        <div 
          key={idx}
          className="flex items-center text-sm text-gray-600 py-1.5"
        >
          <span className="w-6 text-gray-400">{exercise.orderNumber}.</span>
          <span className="flex-1">{exercise.exerciseName}</span>
          {exercise.sets && (
            <span className="text-primary/70 font-medium">
              {exercise.sets} sets
            </span>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="divide-y divide-gray-200 bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden">
      {phases.map((phase, index) => (
        <div 
          key={index} 
          className={`group relative transition-all duration-300
                     ${hoveredIndex === index ? "bg-gray-50/50" : ""}`}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <button
            onClick={() => toggleAccordion(index)}
            className="w-full px-8 py-6 flex items-center justify-between 
                     transition-all duration-300 relative"
          >
        <div className="flex items-center space-x-5">
          <div className="text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <FontAwesomeIcon icon={faGripVertical} className="w-4 h-4" />
          </div>
          <div className="flex flex-col items-start space-y-2">
            <span className="font-medium text-base flex items-center text-gray-900">
              <FontAwesomeIcon 
                icon={faLayerGroup} 
                className={`mr-3 w-4 h-4 ${activeIndex === index ? "text-primary" : "text-gray-400"}`}
              />
              {phase.name}
            </span>
            <span className="text-sm text-gray-500 flex items-center pl-7">
              <FontAwesomeIcon icon={faCalendarWeek} className="mr-2 w-3.5 h-3.5" />
              Week {phase.startWeek} - {phase.endWeek}
            </span>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          {/* Workout Count Badge */}
          <span className={`text-sm px-3 py-1.5 rounded-full flex items-center
                          transition-colors duration-300
                          ${activeIndex === index 
                            ? "bg-primary/10 text-primary" 
                            : "bg-gray-100 text-gray-600"}`}>
            <FontAwesomeIcon icon={faDumbbell} className="mr-2 w-3.5 h-3.5" />
            {phase.trainingRoutines?.length || 0} Workouts
          </span>

          {/* Edit Button */}
          <Link
            to={`/admin/phase/${phase.id}/edit`}
            className={`p-2 rounded-lg transition-all duration-300
                       ${activeIndex === index 
                         ? "text-primary hover:bg-primary/10" 
                         : "text-gray-400 hover:text-primary hover:bg-gray-100"}
                       opacity-0 group-hover:opacity-100
                       hover:scale-110 active:scale-95`}
          >
            <FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
          </Link>
          
          {/* Expand Icon */}
          <div className={`transform transition-transform duration-300
                         ${activeIndex === index ? "rotate-90" : ""}`}>
            <FontAwesomeIcon 
              icon={faChevronRight}
              className={`w-4 h-4 ${activeIndex === index ? "text-primary" : "text-gray-400"}`}
            />
          </div>
        </div>

        {/* Active Indicator */}
        <div className={`absolute left-0 top-0 bottom-0 w-1 transition-all duration-300
                       ${activeIndex === index ? "bg-primary scale-y-100" : "bg-transparent scale-y-0"}`} 
        />
      </button>

      {/* Accordion Content */}
      {activeIndex === index && (
            <div className="animate-slideDown">
              <div className="px-8 py-6 bg-gray-50 space-y-4">
                {phase.trainingRoutines?.length > 0 ? (
                  <div className="space-y-4">
                    {phase.trainingRoutines.map((routine, idx) => (
                      <div 
                        key={idx}
                        className="bg-white rounded-lg border border-gray-200 
                                 shadow-sm overflow-hidden transition-all duration-300
                                 hover:border-primary/20 hover:shadow-md"
                      >
                        {/* Workout Header */}
                        <div className="px-5 py-4 border-b border-gray-100 
                                    flex justify-between items-center">
                          <div className="flex items-center space-x-3">
                            <span className="text-gray-900 font-medium">
                              {routine.workout?.name}
                            </span>
                            <span className="text-xs px-2 py-1 rounded-full 
                                         bg-primary/10 text-primary">
                              {routine.workout?.workoutExercises?.length || 0} exercises
                            </span>
                          </div>
                          <button
                            onClick={() => onAddWorkout(phase)}
                            className="text-primary hover:text-primary-dark p-2 
                                     rounded-lg hover:bg-primary/5 transition-all 
                                     duration-300 hover:scale-110 active:scale-95"
                          >
                            <FontAwesomeIcon icon={faEdit} className="w-3.5 h-3.5" />
                          </button>
                        </div>

                        {/* Workout Details */}
                        <div className="px-5 py-4">
                          {renderWorkoutDetails(routine.workout)}
                        </div>

                        {/* Training Days */}
                        {routine.trainingDays?.length > 0 && (
                          <div className="px-5 py-3 bg-gray-50 border-t 
                                      border-gray-100 text-sm text-gray-500">
                            <FontAwesomeIcon icon={faCalendarWeek} 
                                           className="mr-2 text-gray-400" />
                            Week {routine.trainingDays[0].week}, 
                            Day {routine.trainingDays[0].day}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <button
                    onClick={() => onAddWorkout(phase)}
                    className="w-full text-sm px-5 py-4 text-primary bg-white 
                             rounded-lg border border-primary/20 hover:bg-primary/5 
                             transition-all duration-300 flex items-center justify-center
                             hover:border-primary hover:shadow-md font-medium
                             hover:translate-y-[-1px] active:translate-y-[1px]"
                  >
                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                    Add First Workout
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PhasePanel;