import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft, faUsers, faTrash, faGauge, faArrowRight,
  faTable, faEye, faSearch, faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import {
  ListHeader,
  ListFilter,
  DataListDesktop,
  DataListMobile,
  TableCell
} from "../../Reusable/AdminList";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import { getMemberListAPI, deleteMemberAPI } from "../../../API/member";
import FormSelectFieldForOffer from "../../Reusable/FormSelectFieldForOffer";
import FormRadioField from "../../Reusable/FormRadioField";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import Modal from "../../Reusable/Modal";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
  membersFilterShowState,
  membersFilterTemporarySearchTextState,
  membersFilterActualSearchTextState,
  membersFilterOfferIDState,
  membersFilterStatusState,
  membersFilterSortState,
} from "../../../AppState";

function AdminMemberList() {
  ////
  //// Global state.
  ////
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);
  const [showFilter, setShowFilter] = useRecoilState(membersFilterShowState);
  const [actualSearchText, setActualSearchText] = useRecoilState(membersFilterActualSearchTextState);
  const [temporarySearchText, setTemporarySearchText] = useRecoilState(membersFilterTemporarySearchTextState);
  const [offerID, setOfferID] = useRecoilState(membersFilterOfferIDState);
  const [status, setStatus] = useRecoilState(membersFilterStatusState);
  const [sort, setSort] = useRecoilState(membersFilterSortState);

  ////
  //// Component states.
  ////
  const [isOfferOther, setIsOfferOther] = useState("");
  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [currentCursor, setCurrentCursor] = useState("");

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);

  ////
  //// API.
  ////
  function onMemberListSuccess(response) {
    if (response.results !== null) {
      setListData(response);
      if (response.hasNextPage) {
        setNextCursor(response.nextCursor);
      }
    } else {
      setListData([]);
      setNextCursor("");
    }
  }

  function onMemberListError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onMemberListDone() {
    setFetching(false);
  }

  const fetchList = (cur, limit, keywords, o, s, sbv) => {
    setFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit);

    if (sbv !== undefined && sbv !== null && sbv !== "") {
      const sortArray = sbv.split(",");
      params.set("sort_field", sortArray[0]);
      params.set("sort_order", sortArray[1]);
    }

    if (cur !== "") {
      params.set("cursor", cur);
    }

    if (s !== undefined && s !== null && s !== "" && s !== 0) {
      params.set("status", status);
    }

    if (keywords !== undefined && keywords !== null && keywords !== "") {
      params.set("search", keywords);
    }
    if (o !== undefined && o !== null && o !== "") {
      params.set("subscription_offer_id", o);
    }

    getMemberListAPI(
      params,
      onMemberListSuccess,
      onMemberListError,
      onMemberListDone
    );
  };

  ////
  //// Event handling.
  ////
  const onClearFilterClick = (e) => {
    setShowFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
    setOfferID(null);
    setStatus(0);
    setSort("name,-1");
  };

  const onNextClicked = (e) => {
    let arr = [...previousCursors];
    arr.push(currentCursor);
    setPreviousCursors(arr);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = (e) => {
    let arr = [...previousCursors];
    const previousCursor = arr.pop();
    setPreviousCursors(arr);
    setCurrentCursor(previousCursor);
  };

  const onSearchButtonClick = (e) => {
    setActualSearchText(temporarySearchText);
  };

  const handleSelectMember = (memberId) => {
    setSelectedMembers(prev => 
        prev.includes(memberId) 
            ? prev.filter(id => id !== memberId)
            : [...prev, memberId]
    );
};

const handleSelectAll = (e) => {
    if (e.target.checked) {
        setSelectedMembers(listData.results.map(member => member.id));
    } else {
        setSelectedMembers([]);
    }
};

const handleDeleteClick = (member) => {
  setMemberToDelete(member);
  setShowDeleteModal(true);
};

const handleConfirmDelete = () => {
  // Show loading state if needed
  setFetching(true);

  const onDeleteSuccess = (response) => {

    setShowDeleteModal(false);
        setMemberToDelete(null);

      setTopAlertMessage("Member deleted successfully");
      setTopAlertStatus("success");

      // Refresh the list
      fetchList(currentCursor, pageSize, actualSearchText, offerID, status, sort);
  };

  const onDeleteError = (apiErr) => {

    setShowDeleteModal(false);
        setMemberToDelete(null);

      setTopAlertMessage("Failed to delete member");
      setTopAlertStatus("error");
      setErrors(apiErr);
      // Optionally close modal on error
      // setShowDeleteModal(false);
  };

  const onDeleteDone = () => {
      setFetching(false);
  };

  // Call the API with callbacks
  deleteMemberAPI(
      memberToDelete.id,
      onDeleteSuccess,
      onDeleteError,
      onDeleteDone
  );
};

const handleBulkDeleteClick = () => {
  setShowBulkDeleteModal(true);
};

const handleConfirmBulkDelete = async () => {
  try {
      // await bulkDeleteMembersAPI(selectedMembers);
      // setTopAlertMessage("Selected members deleted successfully");
      // setTopAlertStatus("success");
      // setShowBulkDeleteModal(false);
      // setSelectedMembers([]);
      // fetchList(currentCursor, pageSize, actualSearchText, offerID, status, sort);
  } catch (error) {
      setTopAlertMessage("Failed to delete selected members");
      setTopAlertStatus("error");
      setErrors(error);
  }
};

  ////
  //// Misc.
  ////
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
      fetchList(currentCursor, pageSize, actualSearchText, offerID, status, sort);
    }

    return () => { mounted = false; }
  }, [currentCursor, pageSize, actualSearchText, offerID, status, sort]);

  ////
  //// Component rendering.
  ////
  
  const breadcrumbItems = [
    { label: "Dashboard", icon: faGauge, link: "/admin/dashboard" },
    { label: "Members", icon: faUsers },
  ];

  const headers = [
    { 
        label: (
            <div className="flex items-center">
                <input
                    type="checkbox"
                    className="h-4 w-4 text-primary border-gray-300 rounded"
                    checked={selectedMembers.length === listData?.results?.length}
                    onChange={handleSelectAll}
                />
            </div>
        ),
        className: "w-8"
    },
    { label: "Name" },
    { label: "Email" },
    { label: "Phone" },
    { label: "Status" },
    { label: "Joined" },
    { label: "Actions", className: "text-right" }
];

  const renderDesktopRow = (member, index) => (
    <tr key={member.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
    <TableCell>
        <input
            type="checkbox"
            className="h-4 w-4 text-primary border-gray-300 rounded"
            checked={selectedMembers.includes(member.id)}
            onChange={() => handleSelectMember(member.id)}
        />
    </TableCell>
      <TableCell>
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {member.avatarUrl ? (
              <img className="h-10 w-10 rounded-full" src={member.avatarUrl} alt="" />
            ) : (
              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500 font-medium">
                  {member.name.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{member.name}</div>
            <div className="text-sm text-gray-500">ID: {member.id}</div>
          </div>
        </div>
      </TableCell>
      <TableCell className="text-sm text-gray-500">{member.email}</TableCell>
      <TableCell className="text-sm text-gray-500">{member.phone}</TableCell>
      <TableCell>
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
          ${member.status === 1 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
          {member.status === 1 ? 'Active' : 'Archived'}
        </span>
      </TableCell>
      <TableCell className="text-sm text-gray-500">
        {new Date(member.createdAt).toLocaleDateString()}
      </TableCell>
      <TableCell className="text-right">
                <div className="flex justify-end space-x-3">
                    <Link
                        to={`/admin/member/${member.id}`}
                        className="text-primary hover:text-primary-dark transition-colors duration-300"
                    >
                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                        View
                    </Link>
                    <button
                        onClick={() => handleDeleteClick(member)}
                        className="text-red-600 hover:text-red-800 transition-colors duration-300"
                    >
                        <FontAwesomeIcon icon={faTrash} className="mr-1" />
                        Delete
                    </button>
                </div>
            </TableCell>
    </tr>
  );

  const renderMobileItem = (member) => (
    <div key={member.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-200 hover:shadow-md transition-all duration-300">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            {member.avatarUrl ? (
              <img className="h-10 w-10 rounded-full" src={member.avatarUrl} alt="" />
            ) : (
              <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-500 font-medium">
                  {member.name.charAt(0).toUpperCase()}
                </span>
              </div>
            )}
          </div>
          <div className="ml-3">
            <div className="text-sm font-medium text-gray-900">{member.name}</div>
            <div className="text-xs text-gray-500">ID: {member.id}</div>
          </div>
        </div>
        <span className={`px-2 py-1 text-xs font-semibold rounded-full
          ${member.status === 1 ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
          {member.status === 1 ? 'Active' : 'Archived'}
        </span>
      </div>

      <div className="space-y-2 text-sm text-gray-500">
        <div className="flex justify-between">
          <span>Email:</span>
          <span>{member.email}</span>
        </div>
        <div className="flex justify-between">
          <span>Phone:</span>
          <span>{member.phone}</span>
        </div>
        <div className="flex justify-between">
          <span>Joined:</span>
          <span>{new Date(member.createdAt).toLocaleDateString()}</span>
        </div>
      </div>

      <div className="mt-4 flex justify-end space-x-3">
                <Link
                    to={`/admin/member/${member.id}`}
                    className="text-primary hover:text-primary-dark transition-colors duration-300"
                >
                    <FontAwesomeIcon icon={faEye} className="mr-1" />
                    View Details
                </Link>
                <button
                    onClick={() => handleDeleteClick(member)}
                    className="text-red-600 hover:text-red-800 transition-colors duration-300"
                >
                    <FontAwesomeIcon icon={faTrash} className="mr-1" />
                    Delete
                </button>
            </div>
    </div>
  );

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
            <div className="p-8 bg-gray-50 min-h-screen">
                <div className="mx-auto">

                    <div className="mb-4">
                        <ListHeader
                            title="Members"
                            icon={faUsers}
                            onRefresh={() => fetchList(currentCursor, pageSize, actualSearchText, offerID, status, sort)}
                            onToggleFilter={() => setShowFilter(!showFilter)}
                            addLink="/admin/members/add"
                            addLabel="New Member"
                        />
                        {selectedMembers.length > 0 && (
                            <button
                                onClick={handleBulkDeleteClick}
                                className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-lg flex items-center"
                            >
                                <FontAwesomeIcon icon={faTrash} className="mr-2" />
                                Delete Selected ({selectedMembers.length})
                            </button>
                        )}
                    </div>

          {showFilter && (
            <ListFilter onClear={onClearFilterClick}>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="form-group">
                  <FormInputFieldWithButton
                    label="Search"
                    name="temporarySearchText"
                    type="text"
                    placeholder="Search by name"
                    value={temporarySearchText}
                    onChange={(e) => setTemporarySearchText(e.target.value)}
                    buttonLabel={<FontAwesomeIcon icon={faSearch} />}
                    onButtonClick={onSearchButtonClick}
                  />
                </div>
                <div className="form-group">
                  <FormSelectFieldForOffer
                    label="Enrollment"
                    isSubscription={true}
                    offerID={offerID}
                    setOfferID={setOfferID}
                    isOfferOther={isOfferOther}
                    setIsOfferOther={setIsOfferOther}
                    errorText={errors?.offerId}
                  />
                </div>
                <div className="form-group">
                  <FormRadioField
                    label="Status"
                    name="status"
                    value={status}
                    opt2Value={1}
                    opt2Label="Active"
                    opt4Value={2}
                    opt4Label="Archived"
                    errorText={errors?.status}
                    onChange={(e) => setStatus(parseInt(e.target.value))}
                  />
                </div>
              </div>
            </ListFilter>
          )}

          {isFetching ? (
            <PageLoadingContent displayMessage={"Please wait..."} />
          ) : (
            <>
              <FormErrorBox errors={errors} />
              {listData && listData.results && (listData.results.length > 0 || previousCursors.length > 0) ? (
                <>
                  <div className="hidden md:block">
            <DataListDesktop
              headers={headers}
              data={listData.results}
              renderRow={renderDesktopRow}
              pageSize={pageSize}
              setPageSize={setPageSize}
              previousCursors={previousCursors}
              hasNextPage={listData.hasNextPage}
              onPreviousClicked={onPreviousClicked}
              onNextClicked={onNextClicked}
            />
          </div>

          <div className="md:hidden">
            <DataListMobile
              data={listData.results}
              renderItem={renderMobileItem}
              pageSize={pageSize}
              setPageSize={setPageSize}
              previousCursors={previousCursors}
              hasNextPage={listData.hasNextPage}
              onPreviousClicked={onPreviousClicked}
                      onNextClicked={onNextClicked}
                    />
                  </div>
                </>
              ) : (
                <div className="text-center py-12 bg-white rounded-xl shadow-sm border border-gray-100">
                  <FontAwesomeIcon icon={faTable} className="text-4xl text-gray-400 mb-4" />
                  <h3 className="text-xl font-semibold text-textDark mb-2">No Members</h3>
                  <p className="text-gray-600">
                    No members found.{" "}
                    <Link to="/admin/members/add" className="text-primary hover:text-primary-dark">
                      Click here <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
                    </Link>
                    {" "}to add your first member.
                  </p>
                </div>
              )}
            </>
          )}

          <Modal
                        isOpen={showDeleteModal}
                        onClose={() => {
                            setShowDeleteModal(false);
                            setMemberToDelete(null);
                        }}
                        header="Confirm Delete"
                        maxWidth="md"
                    >
                        <div className="text-center py-4">
                            <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                className="text-red-600 text-5xl mb-4" 
                            />
                            <p className="text-gray-900 font-medium text-lg mb-2">
                                Are you sure you want to delete this member?
                            </p>
                            {memberToDelete && (
                                <p className="text-gray-600">
                                    You are about to delete <span className="font-medium">{memberToDelete.name}</span>. 
                                    This action cannot be undone.
                                </p>
                            )}
                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowDeleteModal(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmDelete}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
                                >
                                    Delete Member
                                </button>
                            </div>
                        </div>
                    </Modal>

                    {/* Bulk Delete Modal */}
                    <Modal
                        isOpen={showBulkDeleteModal}
                        onClose={() => setShowBulkDeleteModal(false)}
                        header="Confirm Bulk Delete"
                        maxWidth="md"
                    >
                        <div className="text-center py-4">
                            <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                className="text-red-600 text-5xl mb-4" 
                            />
                            <p className="text-gray-900 font-medium text-lg mb-2">
                                Are you sure you want to delete {selectedMembers.length} members?
                            </p>
                            <p className="text-gray-600">
                                This action cannot be undone and will permanently delete the selected members.
                            </p>
                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => setShowBulkDeleteModal(false)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleConfirmBulkDelete}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
                                >
                                    Delete Selected Members
                                </button>
                            </div>
                        </div>
                    </Modal>

          <div className="flex flex-col md:flex-row justify-between items-center mt-8 gap-4">
            <Link to="/admin/dashboard" className="w-full md:w-auto inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors duration-300">
              <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
              Back to Dashboard
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AdminMemberList;