import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlay, 
  faHeart, 
  faBookmark, 
  faShare,
  faThumbsUp,
  faClock,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import Vimeo from '@u-wave/react-vimeo';
import { EXERCISE_VIDEO_FILE_TYPE_OPTIONS } from '../../Constants/FieldOptions';

export const VIDEO_LAYOUTS = {
  MINIMAL: 'minimal',
  COMPACT: 'compact',
  FULL: 'full'
};

// Helper function to extract YouTube video ID
const getYouTubeVideoId = (url) => {
  if (!url) return null;
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

// Video Content Component
const VideoContent = ({ 
  showVideo, 
  setShowVideo, 
  videoType, 
  videoObjectUrl, 
  videoUrl, 
  thumbnailUrl, 
  thumbnailObjectUrl,
  name,
  onPlay
}) => {
  const videoWrapperRef = React.useRef(null);
  const [aspectRatio] = useState('16/9');
  const [isLoading, setIsLoading] = useState(false);

  const handlePlayClick = React.useCallback(() => {
    setShowVideo(true);
    if (onPlay) onPlay();
  }, [setShowVideo, onPlay]);

  const renderVideo = React.useCallback(() => {
    
    switch (parseInt(videoType)) {
      case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[0].value: // S3
      if (!videoObjectUrl) return null;
        return (
          <video
            className="absolute inset-0 w-full h-full object-cover"
            controls
            playsInline
            autoPlay
            muted={!showVideo}
            controlsList="nodownload"
            onLoadedData={() => setIsLoading(false)}
          >
            <source src={videoObjectUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[1].value: // YouTube
        if (!videoUrl) return null;
        const youtubeId = getYouTubeVideoId(videoUrl);
        if (!youtubeId) return null;
        
        return (
          <iframe
            className="absolute inset-0 w-full h-full"
            src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&rel=0&modestbranding=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ border: "none" }}
            onLoad={() => setIsLoading(false)}
          />
        );

      case EXERCISE_VIDEO_FILE_TYPE_OPTIONS[2].value: // Vimeo
      
      console.log(videoUrl);
        if (!videoUrl) return null;

        const vimeoId = videoUrl.split('/').pop();
        console.log('Vimeo URL:', videoUrl);
        console.log('Extracted Vimeo ID:', vimeoId);

        return (
          <div className="absolute inset-0 w-full h-full">
            <Vimeo
              video={vimeoId}
              autoplay={true}
              responsive={true}
              autopause={true}
              controls={true}
              onLoaded={() => setIsLoading(false)}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );

      default:
        return null;
    }
  }, [videoType, videoUrl, videoObjectUrl, showVideo]);

  return (
    <div 
      ref={videoWrapperRef}
      className="relative w-full group bg-black overflow-hidden rounded-2xl"
      style={{ aspectRatio }}
    >
      {showVideo ? (
        <div className="absolute inset-0 w-full h-full">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-transparent" />
          {renderVideo()}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/50">
              <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent" />
            </div>
          )}
        </div>
      ) : (
        <div
          className="absolute inset-0 cursor-pointer group"
          onClick={handlePlayClick}
        >
          <img
            className="w-full h-full object-cover transition-all duration-700 group-hover:scale-105"
            src={thumbnailUrl || thumbnailObjectUrl}
            alt={name}
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/30 to-transparent 
                        flex items-center justify-center transition-all duration-500">
            <div className="w-16 h-16 sm:w-20 sm:h-20 bg-white/90 backdrop-blur-xl rounded-full 
                         flex items-center justify-center transform transition-all duration-500 
                         group-hover:scale-110 shadow-lg">
              <FontAwesomeIcon
                icon={faPlay}
                className="text-2xl sm:text-3xl text-primary ml-1 sm:ml-2 
                         transition-transform duration-500 group-hover:scale-110"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Action Buttons Component
const ActionButtons = ({ 
  isLiked, 
  setIsLiked, 
  isSaved, 
  setIsSaved, 
  onLike, 
  onSave, 
  onShare,
  stats 
}) => (
  <div className="flex items-center gap-4 mt-4 text-sm">
    <button
      onClick={() => {
        setIsLiked(!isLiked);
        if (onLike) onLike();
      }}
      className={`flex items-center gap-2 transition-colors duration-200
                ${isLiked ? 'text-primary' : 'text-gray-600 hover:text-primary'}`}
    >
      <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
      <span>{stats?.likes || 0}</span>
    </button>
    <button
      onClick={() => {
        setIsSaved(!isSaved);
        if (onSave) onSave();
      }}
      className={`flex items-center gap-2 transition-colors duration-200
                ${isSaved ? 'text-primary' : 'text-gray-600 hover:text-primary'}`}
    >
      <FontAwesomeIcon icon={faBookmark} className="text-lg" />
      <span>{stats?.saves || 0}</span>
    </button>
    <button
      onClick={onShare}
      className="flex items-center gap-2 text-gray-600 hover:text-primary 
               transition-colors duration-200"
    >
      <FontAwesomeIcon icon={faShare} className="text-lg" />
      <span>{stats?.shares || 0}</span>
    </button>
  </div>
);

// Description Component
const Description = ({ description }) => (
  <div className="mt-6 text-gray-600">
    <p className="whitespace-pre-wrap">{description}</p>
  </div>
);

// Metadata Sidebar Component
const MetadataSidebar = ({ metadata }) => (
  <div className="bg-gray-50 rounded-xl p-6">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">Video Details</h3>
    <div className="space-y-4">
      {metadata?.map((item, index) => (
        <div key={index} className="flex items-start gap-3">
          <FontAwesomeIcon icon={item.icon} className="text-gray-400 mt-1" />
          <div>
            <h4 className="text-sm font-medium text-gray-900">{item.label}</h4>
            <p className="text-sm text-gray-600">{item.value}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

// Main VideoPlayer Component
const VideoPlayer = ({
  videoType = EXERCISE_VIDEO_FILE_TYPE_OPTIONS[0].value,
  videoObjectUrl,
  videoUrl,
  thumbnailUrl,
  name,
  description,
  metadata,
  layout = VIDEO_LAYOUTS.FULL,
  showActions = true,
  showDescription = true,
  showRelated = true,
  onPlay,
  onLike,
  onSave,
  onShare,
  stats
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  // Layout styles
  const wrapperStyles = {
    [VIDEO_LAYOUTS.MINIMAL]: "max-w-4xl mx-auto",
    [VIDEO_LAYOUTS.COMPACT]: "max-w-4xl mx-auto",
    [VIDEO_LAYOUTS.FULL]: "max-w-7xl mx-auto"
  };

  // Layout configurations
  const layouts = {
    [VIDEO_LAYOUTS.MINIMAL]: (
      <div className={wrapperStyles[VIDEO_LAYOUTS.MINIMAL]}>
        <VideoContent
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          videoType={videoType}
          videoObjectUrl={videoObjectUrl}
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
          name={name}
          onPlay={onPlay}
        />
        {showDescription && <Description description={description} />}
      </div>
    ),

    [VIDEO_LAYOUTS.COMPACT]: (
      <div className={wrapperStyles[VIDEO_LAYOUTS.COMPACT]}>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div className="lg:col-span-2">
        <VideoContent
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          videoType={videoType}
          videoObjectUrl={videoObjectUrl}
          videoUrl={videoUrl}
          thumbnailUrl={thumbnailUrl}
          name={name}
          onPlay={onPlay}
        />
        <div className="px-4 sm:px-0">
          <h1 className="mt-6 text-2xl font-bold text-gray-900">{name}</h1>
          {showActions && (
            <ActionButtons
              isLiked={isLiked}
              setIsLiked={setIsLiked}
              isSaved={isSaved}
              setIsSaved={setIsSaved}
              onLike={onLike}
              onSave={onSave}
              onShare={onShare}
              stats={stats}
            />
          )}
          {showDescription && <Description description={description} />}
        </div>
      </div>
      {metadata && <div className="lg:col-span-1">
        <MetadataSidebar metadata={metadata} />
      </div>}
    </div>
  </div>
),

    [VIDEO_LAYOUTS.FULL]: (
      <div className={wrapperStyles[VIDEO_LAYOUTS.FULL]}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <VideoContent
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              videoType={videoType}
              videoObjectUrl={videoObjectUrl}
              videoUrl={videoUrl}
              thumbnailUrl={thumbnailUrl}
              name={name}
              onPlay={onPlay}
            />
            <div className="px-4 sm:px-0">
              <h1 className="mt-6 text-2xl font-bold text-gray-900">{name}</h1>
              {showActions && (
                <ActionButtons
                  isLiked={isLiked}
                  setIsLiked={setIsLiked}
                  isSaved={isSaved}
                  setIsSaved={setIsSaved}
                  onLike={onLike}
                  onSave={onSave}
                  onShare={onShare}
                  stats={stats}
                />
              )}
              {showDescription && <Description description={description} />}
            </div>
          </div>
          {metadata && <div className="lg:col-span-1">
            <MetadataSidebar metadata={metadata} />
          </div>}
        </div>
      </div>
    )
  };

  return layouts[layout] || layouts[VIDEO_LAYOUTS.FULL];
};

export { VideoPlayer };  // Named export
export default VideoPlayer; // Default export