import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faSearch,
	faTable,
	faGauge,
	faTicketSimple,
	faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import Layout from "../../Menu/Layout";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {getTipsListAPI} from "../../../API/tips";
import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import CardView from "../../Reusable/List/CardView";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import {Link} from "react-router-dom";

const MemberPreviousLessonsList = () => {
	// Global State
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component States
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [showFilter, setShowFilter] = useState(false); // Filtering + Searching
	const [sortField, setSortField] = useState("created"); // Sorting
	const [temporarySearchText, setTemporarySearchText] = useState(""); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useState("");
	const [sort, setSort] = useState("");
	const [status, setStatus] = useState("");
	const [videoType, setVideoType] = useState("");

	// Breadcrumb items
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Previous Tips",
			icon: faTicketSimple,
		},
	]

	////
	//// API.
	////

	const onPreviousTipsListSuccess = (response) => {
		console.log("onPreviousTipsListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	};

	const onPreviousTipsListError = (apiErr) => {
		console.log("onPreviousTipsListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onPreviousTipsListDone = () => {
		console.log("onPreviousTipsListDone: Starting...");
		setFetching(false);
	};

	////
	//// Event handling.
	////

	// Note: currentCursor, pageSize, actualSearchText, category, movementType, status, gender, videoType, tags
	const fetchList = (cur, limit, keywords, cat, st, vt) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination
		params.set("sort_field", "created"); // Sorting
		params.set("sort_order", -1); // Sorting - descending, meaning most recent start date to oldest start date.

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (cat !== undefined && cat !== null && cat !== "") {
			params.set("category", cat);
		}
		if (st !== undefined && st !== null && st !== "") {
			params.set("status", st);
		}
		if (vt !== undefined && vt !== null && vt !== "") {
			params.set("video_type", vt);
		}

		getTipsListAPI(
			params,
			onPreviousTipsListSuccess,
			onPreviousTipsListError,
			onPreviousTipsListDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	// Fetch Data on Component Mount or Change in Filters
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			fetchList(currentCursor, pageSize, actualSearchText, status, videoType);
		}
		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, videoType]);

	const EmptyState = (
		<section className="flex flex-col items-center justify-center py-12 px-4 bg-gray-50 rounded-xl">
			<FontAwesomeIcon icon={faTable} className="text-4xl text-gray-400 mb-4" />
			<h3 className="text-xl font-semibold text-gray-900 mb-2">
				No Previous Tips
			</h3>
			<p className="text-gray-600 text-center">
				No previous tips found at the moment. Please check back later!
			</p>
		</section>
	);

	const renderTipCard = (tip) => (
		<div className="flex flex-col h-full">
			<div className="relative pb-[56.25%]">
				<img
					src={tip.thumbnailUrl || tip.thumbnailObjectUrl}
					alt={tip.name}
					className="absolute inset-0 w-full h-full object-cover rounded-t-xl"
				/>
			</div>
			<div className="px-4 py-1 flex flex-col flex-grow">
				<h3 className="text-lg font-semibold text-gray-900 mb-2">{tip.name}</h3>
				<p className="text-gray-600 text-sm line-clamp-2">{tip.description}</p>
			</div>
			<div className="p-4 flex flex-col flex-grow">
				<Link
					to={`/previous-week-lesson/${tip.id}`}
					className="w-full py-2.5 sm:py-3 px-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-all duration-300 inline-flex items-center justify-center text-sm">
					View
				</Link>
			</div>
		</div>
	);

	const FilterContent = (
		<FilterSidebar>
			<FilterSection>
				<FormInputFieldWithButton
					label={"Search"}
					name="temporarySearchText"
					type="text"
					placeholder="Search by name"
					value={temporarySearchText}
					onChange={(e) => setTemporarySearchText(e.target.value)}
					isRequired={true}
					maxWidth="100%"
					buttonLabel={<FontAwesomeIcon className="fas" icon={faSearch} />}
					onButtonClick={() => setActualSearchText(temporarySearchText)}
				/>
			</FilterSection>
		</FilterSidebar>
	);

	const ActionButtons = () => (
		<div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 sm:gap-3 w-full sm:w-auto">
			<button
				onClick={() =>
					fetchList(currentCursor, pageSize, actualSearchText, status, sort)
				}
				className="inline-flex items-center justify-center px-4 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
				<FontAwesomeIcon icon={faRefresh} className="mr-2" />
				<span>Refresh</span>
			</button>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<ListWithFilters
				title={
					<div className="flex items-center justify-between py-4 px-4 sm:px-6 border-b border-gray-200">
						<div className="flex items-center space-x-3">
							<FontAwesomeIcon
								icon={faTicketSimple}
								className="text-primary text-xl"
							/>
							<h2 className="text-xl font-semibold">Previous Tips</h2>
						</div>
						<div className="flex items-center space-x-2">
							<ActionButtons />
						</div>
					</div>
				}
				filterContent={FilterContent}
				loading={isFetching}
				error={errors && Object.keys(errors).length > 0 ? errors : null}
				EmptyStateComponent={EmptyState}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={listData?.hasNextPage}>
				<CardView
					items={listData?.results || []}
					renderItem={renderTipCard}
					emptyState={EmptyState}
				/>
			</ListWithFilters>
		</Layout>
	);
};

export default MemberPreviousLessonsList;
