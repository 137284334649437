import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faRankingStar,
	faArrowLeft,
	faGauge,
	faTable,
	faRefresh,
	faEye,
	faBolt,
	faTrophy,
	faUser,
	faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue} from "recoil";

import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	dataPointFilterShowState,
	dataPointFilterTemporarySearchTextState,
	dataPointFilterActualSearchTextState,
	dataPointFilterSortState,
	dataPointFilterStatusState,
	fitnessChallengeDatumState,
} from "../../../AppState";
import {
	RANK_POINT_PERIOD_DAY,
	RANK_POINT_FUNCTION_AVERAGE,
} from "../../../Constants/App";
import MemberLeaderboardGlobalTabularListDesktop from "./TabularListDesktop";
import MemberLeaderboardGlobalTabularListMobile from "./TabularListMobile";
import {
	RANK_POINT_METRIC_TYPE_HEART_RATE,
	RANK_POINT_METRIC_TYPE_STEP_COUNTER,
} from "../../../Constants/App";
import FormErrorBox from "../../Reusable/FormErrorBox";
import {getRankPointListAPI} from "../../../API/RankPoint";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {getFitnessChalengeLeaderboard} from "../../../API/FitnessChallenge";
import Layout from "../../Menu/Layout";
import { ListHeader, ListFilter, DataListDesktop, DataListMobile } from "../../Reusable/AdminList";

// Reusable Card Component
const Card = ({ children, className = "" }) => (
	<div className={`bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ${className}`}>
	  {children}
	</div>
  );
  
  function LeaderboardGlobalTabularList() {
  
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(dataPointFilterShowState); // Filtering + Searching
	const [sort, setSort] = useRecoilState(dataPointFilterSortState); // Sorting
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		dataPointFilterTemporarySearchTextState
	); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useRecoilState(
		dataPointFilterActualSearchTextState
	); // Searching - The actual search query value to submit to the API.
	const [status, setStatus] = useRecoilState(dataPointFilterStatusState);
	const fitnessChallengeDatum = useRecoilValue(fitnessChallengeDatumState); // Access datum from Recoil

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listRank, setListRank] = useState("");
	const [selectedFitnessPlanForDeletion, setSelectedFitnessPlanForDeletion] =
		useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [isHeartRate, setIsHeartRate] = useState(true);
	const [isStepsCounter, setIsStepsCounter] = useState(false);
	const [period, setPeriod] = useState(RANK_POINT_PERIOD_DAY);
	const [calcFunction, setCalcFunction] = useState(RANK_POINT_FUNCTION_AVERAGE);

	const {id} = useParams();


	// Breadcrumb items
	const breadcrumbItems = [
		{
			label: "Dashboard",
			link: "/admin/dashboard",
			icon: faGauge
		},
		{
			label: "Challenges",
			link: "/admin/fitness-challenges",
			icon: faBolt
		},
		{
			label: "Detail",
			icon: faEye
		}
	]

	////
	//// API.
	////

	function onRankPointistSuccess(response) {
		console.log("onRankPointistSuccess: Starting...");
		if (response.results !== null) {
			setListRank(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListRank([]);
			setNextCursor("");
		}
	}

	function onRankPointistError(apiErr) {
		console.log("onRankPointistError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onRankPointistDone() {
		console.log("onRankPointistDone: Starting...");
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (
		user,
		cur,
		limit,
		keywords,
		stat,
		sbv,
		isHeartRate,
		isStepsCounter,
		p,
		cf
	) => {
		setFetching(true);
		setErrors({});

		getFitnessChalengeLeaderboard(
			id,
			onRankPointistSuccess,
			onRankPointistError,
			onRankPointistDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(
				currentUser,
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				sort,
				isHeartRate,
				isStepsCounter,
				period,
				calcFunction
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentUser,
		currentCursor,
		pageSize,
		actualSearchText,
		status,
		sort,
		isHeartRate,
		isStepsCounter,
		period,
		calcFunction,
	]);


	// Table headers configuration
	const leaderboardHeaders = [
	  { label: "Rank", className: "w-16" },
	  { label: "Member", className: "min-w-[200px]" },
	  { label: "Points", className: "w-24" },
	//   { label: "Status", className: "w-24" },
	];

	// Helper function to get user initials
	const getInitials = (name) => {
		return name
		  .split(' ')
		  .map(word => word[0])
		  .join('')
		  .toUpperCase();
	  };


	  	  // Calculate max value for progress bars
			const maxValue = Math.max(...(listRank?.results?.map(item => item.value) || [0]));
  
	// Render row function for desktop view
	const renderLeaderboardRow = (item, index) => (
		<tr key={item.id} className="hover:bg-gray-50 transition-colors duration-200">
		  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
			<div className="flex items-center">
			  {item.place <= 3 ? (
				<div className={`w-8 h-8 rounded-full flex items-center justify-center ${
				  item.place === 1 ? 'bg-yellowShade1 text-gray-900' :
				  item.place === 2 ? 'bg-gray-200 text-gray-900' :
				  item.place === 3 ? 'bg-orange-100 text-orange-800' :
				  'bg-br text-white'
				}`}>
				  <FontAwesomeIcon icon={faTrophy} className="h-4 w-4" />
				</div>
			  ) : (
				<span className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center">
				  {item.place}
				</span>
			  )}
			</div>
		  </td>
		  <td className="px-6 py-4 whitespace-nowrap">
			<div className="flex items-center">
			  {item.userAvatarObjectUrl ? (
				<img 
				  src={item.userAvatarObjectUrl} 
				  alt={item.userFirstName}
				  className="h-10 w-10 rounded-full object-cover"
				/>
			  ) : (
				<div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
				  <span className="text-sm font-medium text-primary">
					{getInitials(item.userFirstName)}
				  </span>
				</div>
			  )}
			  <div className="ml-4">
				<div className="text-sm font-medium text-gray-900">{item.userFirstName}</div>
				{item.organizationName && (
				  <div className="text-sm text-gray-500">{item.organizationName}</div>
				)}
			  </div>
			</div>
		  </td>
		  <td className="px-6 py-4 whitespace-nowrap">
			<div className="text-sm text-gray-900">{item.value.toLocaleString()}</div>
		  </td>
		  
		{/* <td className="px-6 py-4 whitespace-nowrap">
		  <span className={`px-3 py-1 rounded-full text-xs font-medium ${
			item.completed 
			  ? 'bg-green-100 text-green-800' 
			  : 'bg-yellow-100 text-yellow-800'
		  }`}>
			{item.completed ? 'Completed' : 'In Progress'}
		  </span>
		</td> */}
		</tr>
	  );
	
	  // Render item function for mobile view
	  const renderLeaderboardItem = (item, index) => (
		<div key={item.id} className="bg-white rounded-lg shadow-sm p-4 border border-gray-200">
		  <div className="flex items-center justify-between mb-4">
			<div className="flex items-center space-x-3">
			  {item.place <= 3 ? (
				<div className={`w-8 h-8 rounded-full flex items-center justify-center ${
				  item.place === 1 ? 'bg-yellowShade1 text-gray-900' :
				  item.place === 2 ? 'bg-gray-200 text-gray-900' :
				  'bg-yellowShade3 text-white'
				}`}>
				  <FontAwesomeIcon icon={faTrophy} className="h-4 w-4" />
				</div>
			  ) : (
				<span className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center text-sm font-medium">
				  {item.place}
				</span>
			  )}
			  <div className="flex items-center">
				{item.user_avatar_object_url ? (
				  <img 
					src={item.userAvatarObjectUrl} 
					alt={item.userFirstName}
					className="h-10 w-10 rounded-full object-cover mr-3"
				  />
				) : (
				  <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center mr-3">
					<span className="text-sm font-medium text-primary">
					  {getInitials(item.userFirstName)}
					</span>
				  </div>
				)}
				<div>
				  <div className="text-sm font-medium text-gray-900">{item.userFirstName}</div>
				  {item.organizationName && (
					<div className="text-xs text-gray-500">{item.organizationName}</div>
				  )}
				</div>
			  </div>
			</div>
			<div className="text-sm font-medium text-gray-900">
			  {item.value.toLocaleString()} pts
			</div>
		  </div>
		  <div className="w-full bg-gray-200 rounded-full h-2">
			<div 
			  className="bg-primary h-2 rounded-full transition-all duration-500"
			  style={{ width: `${Math.min((item.value / maxValue) * 100, 100)}%` }}
			></div>
		  </div>
		</div>
	  );
	

  
	return (
	  <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
		<div className="min-h-screen bg-gray-50 py-8">
		  <div className="mx-auto px-4 sm:px-6 lg:px-8">
			<Card>
			  <div className="p-6">
				<ListHeader
				  title="Challenge Leaderboard"
				  icon={faRankingStar}
				  onRefresh={() => fetchList(
					currentCursor,
					pageSize,
					actualSearchText,
					status,
					sort,
					isHeartRate,
					isStepsCounter,
					period,
					calcFunction
				  )}
				/>
  
				{/* Tab Navigation */}
				<div className="border-b border-gray-200 mb-6">
				  <nav className="-mb-px flex space-x-8">
					<Link
					  to={`/admin/fitness-challenge/${id}`}
					  className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium"
					>
					  Detail
					</Link>
					<Link
					  to={`/admin/fitness-challenge/${id}/leaderboard`}
					  className="border-primary text-primary border-b-2 py-4 px-1 text-md font-medium"
					>
					  Leaderboard
					</Link>
					{fitnessChallengeDatum?.criteria === 2 && (
					  <Link
						to={`/admin/fitness-challenge/${id}/assign-badge`}
						className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-b-2 py-4 px-1 text-md font-medium"
					  >
						Assign Badges
					  </Link>
					)}
				  </nav>
				</div>
  
				{isFetching ? (
				  <PageLoadingContent displayMessage={"Loading leaderboard..."} />
				) : (
				  <>
					<FormErrorBox errors={errors} />
					
					{listRank?.results?.length > 0 ? (
					  <>
						{/* Desktop View */}
						<div className="hidden md:block">
						  <DataListDesktop
							headers={leaderboardHeaders}
							data={listRank.results}
							renderRow={renderLeaderboardRow}
							pageSize={pageSize}
							setPageSize={setPageSize}
							previousCursors={previousCursors}
							hasNextPage={listRank.hasNextPage}
							onPreviousClicked={onPreviousClicked}
							onNextClicked={onNextClicked}
						  />
						</div>
  
						{/* Mobile View */}
						<div className="md:hidden">
						  <DataListMobile
							data={listRank.results}
							renderItem={renderLeaderboardItem}
							pageSize={pageSize}
							setPageSize={setPageSize}
							previousCursors={previousCursors}
							hasNextPage={listRank.hasNextPage}
							onPreviousClicked={onPreviousClicked}
							onNextClicked={onNextClicked}
						  />
						</div>
					  </>
					) : (
					  <div className="text-center py-12">
						<div className="w-16 h-16 mx-auto bg-gray-100 rounded-full flex items-center justify-center mb-4">
						  <FontAwesomeIcon icon={faChartLine} className="h-8 w-8 text-gray-400" />
						</div>
						<h3 className="text-lg font-medium text-gray-900 mb-2">No Leaderboard Data</h3>
						<p className="text-gray-500">There are no participants in this challenge yet.</p>
					  </div>
					)}
				  </>
				)}
  
				{/* Back Button */}
				<div className="mt-6">
				  <Link
					to="/admin/fitness-challenge"
					className="inline-flex items-center px-4 py-2 bg-white hover:bg-gray-50 text-gray-700 rounded-lg border border-gray-200 transition-all duration-200"
				  >
					<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
					Back to Challenges
				  </Link>
				</div>
			  </div>
			</Card>
		  </div>
		</div>
	  </Layout>
	);
  }
  
  export default LeaderboardGlobalTabularList;
