import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faSpinner,
  faCheckCircle,
  faClock,
  faBan
} from "@fortawesome/free-solid-svg-icons";

import { getMemberDetailAPI } from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";

function AdminMemberDetailForBookingList() {
  const { bid, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [workoutSessions, setWorkoutSessions] = useState([]);
  const [error, setError] = useState(null);
  const [isFetching, setFetching] = useState(false);

  ////
  //// API Callbacks
  ////

  function onMemberDetailSuccess(response) {
    console.log("onMemberDetailSuccess: Starting...");
    setWorkoutSessions(response.workoutSessions || []);
    setError(null);
  }

  function onMemberDetailError(apiErr) {
    console.log("onMemberDetailError: Starting...");
    setError(apiErr);
    setWorkoutSessions([]);
    console.error("Member Detail API Error:", apiErr);
  }

  function onMemberDetailDone() {
    console.log("onMemberDetailDone: Starting...");
    setIsLoading(false);
    setFetching(false);
  }

  ////
  //// Event Handlers
  ////

  // Add any event handlers here if needed

  ////
  //// Lifecycle
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setFetching(true);
      getMemberDetailAPI(
        id,
        onMemberDetailSuccess,
        onMemberDetailError,
        onMemberDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, [bid, id]);

  ////
  //// Component Rendering
  ////

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />;
      case 'upcoming':
        return <FontAwesomeIcon icon={faClock} className="text-blue-500" />;
      case 'cancelled':
        return <FontAwesomeIcon icon={faBan} className="text-red-500" />;
      default:
        return <FontAwesomeIcon icon={faCalendar} className="text-gray-500" />;
    }
  };

  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'bg-green-100 text-green-800';
      case 'upcoming':
        return 'bg-blue-100 text-blue-800';
      case 'cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  ////
  //// Component Rendering
  ////

  if (isLoading || isFetching) {
    return <PageLoadingContent displayMessage={"Loading workout sessions..."} />;
  }

  if (error) {
    return (
      <div className="p-4 text-red-600 bg-red-50 rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Error</h3>
        <p>{error.message || "An error occurred while loading workout sessions"}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
        <h3 className="text-lg font-bold text-textDark flex items-center">
          <FontAwesomeIcon icon={faCalendar} className="mr-3 text-primaryColor" />
          Workout Sessions
        </h3>

        {workoutSessions.length === 0 ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg mt-6">
            <div className="w-12 h-12 mx-auto rounded-xl bg-main flex items-center justify-center mb-4">
              <FontAwesomeIcon icon={faCalendar} className="w-6 h-6 text-white" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">No Sessions Found</h3>
            <p className="text-sm text-gray-500">
              This member hasn't attended any workout sessions yet.
            </p>
          </div>
        ) : (
          <div className="mt-6">
            <ul className="divide-y divide-gray-200">
              {workoutSessions.map((session) => (
                <li key={session.id} className="py-4 hover:bg-gray-50 transition-colors duration-150">
                  <div className="flex justify-between items-center">
                    <div className="flex items-start space-x-4">
                      <div className="p-2 bg-gray-50 rounded-lg">
                        {getStatusIcon(session.status)}
                      </div>
                      <div>
                        <h4 className="text-sm font-medium text-gray-900">{session.name}</h4>
                        <p className="text-sm text-gray-500">
                          {new Date(session.datetime).toLocaleString()}
                        </p>
                      </div>
                    </div>
                    <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium
                      ${getStatusClass(session.status)}`}>
                      {session.status}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
    </div>
  );
}

export default AdminMemberDetailForBookingList;