import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faImage,
	faArrowLeft,
	faGauge,
	faLightbulb,
	faEye,
	faPencil,
	faInfoCircle,
	faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormSelectField from "../../Reusable/FormSelectField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_VIDEO_TYPE_VIMEO,
	EXERCISE_VIDEO_TYPE_YOUTUBE,
	EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";
import {getTipDetailAPI, putTipUpdateAPI} from "../../../API/tips";
import {TIPS_STATUS_WITH_EMPTY_OPTIONS} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";

function AdminTipUpdate() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
	const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [status, setStatus] = useState(0);
	const [thumbnailType, setThumbnailType] = useState(
		EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentFilename, setThumbnailAttachmentFilename] =
		useState("");
	const [existingThumbnailURL, setExistingThumbnailURL] = useState("");
	const [existingThumbnailAttachmentID, setExistingThumbnailAttachmentID] =
		useState("");
	const [existingVideoAttachmentID, setExistingVideoAttachmentID] =
		useState("");
	const [videoType, setVideoType] = useState(
		EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentFilename, setVideoAttachmentFilename] = useState("");
	const [existingVideoURL, setExistingVideoURL] = useState("");
	const [isVideoModified, setIsVideoModified] = useState(false);

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		e.preventDefault();
		setFetching(true);
		setErrors({});

		const payload = {
			id: id,
			video_type: parseInt(videoType),
			thumbnail_type: parseInt(thumbnailType),
			name: name,
			description: description,
			status: status,
		};

		// Handle video based on type
		if (videoType === EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (videoAttachmentID !== existingVideoAttachmentID) {
				payload.video_upload = videoAttachmentID;
			}
		} else {
			// For YouTube/Vimeo
			payload.video_url = videoURL;
		}

		// Handle thumbnail based on type
		if (thumbnailType === EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (thumbnailAttachmentID !== existingThumbnailAttachmentID) {
				payload.thumbnail_upload = thumbnailAttachmentID;
			}
		} else {
			// For external URL
			payload.thumbnail_url = thumbnailURL;
		}

		// If we're not changing the thumbnail, keep the existing one
		if (
			!payload.thumbnail_upload &&
			!payload.thumbnail_url &&
			existingThumbnailURL
		) {
			if (thumbnailType === EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
				payload.thumbnail_upload = existingThumbnailAttachmentID;
			} else {
				payload.thumbnail_url = existingThumbnailURL;
			}
		}

		putTipUpdateAPI(payload, onUpdateSuccess, onUpdateError, onUpdateDone);
	};

	const handleVideoURLChange = (e) => {
		const newValue = e.target.value;
		setVideoURL(newValue);
		setIsVideoModified(true);
		// Clear file upload if URL is being used
		if (videoType !== EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE) {
			setVideoAttachmentID("");
			setVideoAttachmentFilename("");
		}
	};

	// Update the video type change handler
	const handleVideoTypeChange = (e) => {
		const newType = parseInt(e.target.value);
		setVideoType(newType);
		// Clear inappropriate values when switching types
		if (newType === EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE) {
			setVideoURL("");
		} else {
			setVideoAttachmentID("");
			setVideoAttachmentFilename("");
		}
	};

	function onUpdateSuccess(response) {
		setTopAlertMessage("Tip updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL("/admin/tips/" + response.id + "");
	}

	function onUpdateError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onUpdateDone() {
		setFetching(false);
	}

	function onDetailSuccess(response) {
		// Thumbnail handling
		setThumbnailType(response.thumbnailType);
		setThumbnailAttachmentID(
			response.thumbnailAttachmentId === "000000000000000000000000"
				? ""
				: response.thumbnailAttachmentId
		);
		setExistingThumbnailAttachmentID(
			response.thumbnailAttachmentId === "000000000000000000000000"
				? ""
				: response.thumbnailAttachmentId
		);
		setThumbnailAttachmentFilename(response.thumbnailAttachmentFilename);
		setExistingThumbnailURL(response.thumbnailObjectUrl);
		setThumbnailURL(response.thumbnailUrl || "");

		// Video handling
		setVideoType(response.videoType);
		setVideoAttachmentID(
			response.videoAttachmentId === "000000000000000000000000"
				? ""
				: response.videoAttachmentId
		);
		setExistingVideoAttachmentID(
			response.videoAttachmentId === "000000000000000000000000"
				? ""
				: response.videoAttachmentId
		);
		setVideoAttachmentFilename(response.videoAttachmentFilename);
		setExistingVideoURL(response.videoObjectUrl);
		setVideoURL(response.videoUrl || "");

		// Reset modification flags
		setIsVideoModified(false);

		// Basic info
		setName(response.name);
		setDescription(response.description);
		setStatus(response.status);
	}

	function onDetailError(apiErr) {
		setErrors(apiErr);

		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		setFetching(false);
	}

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			getTipDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Tips", link: "/admin/tips", icon: faLightbulb},
		{label: "Update", icon: faPencil},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50 py-8 animate-fade-in">
				<div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
					{/* Page Header */}
					<div className="mb-8">
						<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
							<div className="flex-1">
								<h1 className="text-2xl font-bold text-gray-900 flex items-center gap-3">
									<FontAwesomeIcon
										icon={faLightbulb}
										className="text-primary h-6 w-6"
									/>
									Update Tip
								</h1>
								<p className="mt-2 text-sm text-gray-600">
									Modify and improve your training tip
								</p>
							</div>
							<div className="mt-4 sm:mt-0 flex flex-wrap gap-3">
								<Link
									to={`/admin/tips/${id}`}
									className="inline-flex items-center px-4 py-2 border border-gray-300 
                           shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white 
                           hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-primary 
                           transition-all duration-300">
									<FontAwesomeIcon icon={faEye} className="mr-2" />
									View
								</Link>
								<Link
									to="/admin/tips"
									className="inline-flex items-center px-4 py-2 border border-gray-300 
                           shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white 
                           hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-primary 
                           transition-all duration-300">
									<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
									Back
								</Link>
							</div>
						</div>
					</div>

					{/* Main Content */}
					<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
						{errors && <FormErrorBox errors={errors} />}

						{isFetching ? (
							<PageLoadingContent />
						) : (
							<form
								onSubmit={onSubmitClick}
								className="divide-y divide-gray-200">
								{/* Thumbnail Section */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faImage}
												className="text-primary"
											/>
											Thumbnail
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
										{/* Thumbnail Type Selection */}
										<div className="space-y-4">
											<FormRadioField
												label="Thumbnail Type"
												name="thumbnailType"
												placeholder="Pick"
												value={thumbnailType}
												opt1Value={
													EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
												}
												opt1Label="File Upload"
												opt2Value={EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL}
												opt2Label="External URL"
												errorText={errors && errors.thumbnailType}
												onChange={(e) =>
													setThumbnailType(parseInt(e.target.value))
												}
												maxWidth="180px"
												disabled={false}
											/>
											<div className="text-xs text-gray-500 flex items-start gap-1">
												<FontAwesomeIcon
													icon={faInfoCircle}
													className="mt-0.5"
												/>
												<span>Choose how you want to add your thumbnail</span>
											</div>
										</div>

										{/* Thumbnail Upload/URL Input */}
										<div>
											{thumbnailType ===
											EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
												<div className="space-y-2">
													<FormAttachmentField
														label="Thumbnail Image"
														name="thumbnail"
														placeholder="Choose an image"
														errorText={errors && errors.thumbnail}
														attachmentID={thumbnailAttachmentID}
														setAttachmentID={setThumbnailAttachmentID}
														attachmentFilename={thumbnailAttachmentFilename}
														setAttachmentFilename={
															setThumbnailAttachmentFilename
														}
														helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
														validationText="Maximum size: 10MB"
														disabled={false}
														acceptedFileTypes="image/*"
													/>
													{existingThumbnailURL && !thumbnailURL && (
														<div className="mt-2">
															<p className="text-sm text-gray-500 mb-2">
																Current Thumbnail:
															</p>
															<img
																src={existingThumbnailURL}
																alt="Current thumbnail"
																className="w-48 h-auto rounded-lg border border-gray-200"
															/>
														</div>
													)}
												</div>
											) : (
												<FormInputField
													label="Thumbnail URL"
													name="thumbnailURL"
													type="url"
													placeholder="Enter thumbnail URL"
													value={thumbnailURL}
													errorText={errors?.thumbnailUrl}
													onChange={(e) => {
														setThumbnailURL(e.target.value);
														setThumbnailAttachmentID("");
														setThumbnailAttachmentFilename("");
													}}
													helpText="Enter the URL of the thumbnail image"
												/>
											)}
										</div>
									</div>
								</div>

								{/* Basic Information */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900">
											Basic Information
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 gap-6">
										<FormInputField
											label="Name"
											name="name"
											placeholder="Enter a clear, descriptive name"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="w-full"
										/>
										<FormTextareaField
											label="Description"
											name="description"
											placeholder="Provide detailed instructions and tips"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={5}
											className="w-full"
										/>
										<FormSelectField
											label="Status"
											name="status"
											value={status}
											onChange={(e) => setStatus(parseInt(e.target.value))}
											options={TIPS_STATUS_WITH_EMPTY_OPTIONS}
											className="w-full"
										/>
									</div>
								</div>

								{/* Video Section */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faVideoCamera}
												className="text-primary"
											/>
											Video Content
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
										<div className="space-y-4">
											<FormRadioField
												label="Video Type"
												name="videoType"
												placeholder="Pick"
												value={videoType}
												opt1Value={EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
												opt1Label="File Upload"
												opt2Value={EXERCISE_VIDEO_TYPE_YOUTUBE}
												opt2Label="YouTube"
												opt3Value={EXERCISE_VIDEO_TYPE_VIMEO}
												opt3Label="Vimeo"
												onChange={(e) => setVideoType(parseInt(e.target.value))}
												errorText={errors?.videoType}
												maxWidth="180px"
												disabled={false}
											/>
											<div className="text-xs text-gray-500 flex items-start gap-1">
												<FontAwesomeIcon
													icon={faInfoCircle}
													className="mt-0.5"
												/>
												<span>Choose your preferred video source</span>
											</div>
										</div>

										<div>
											{videoType ===
											EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? (
												<FormAttachmentField
													label="Video File"
													name="video"
													placeholder="Choose a video file"
													errorText={errors && errors.video}
													attachmentID={videoAttachmentID}
													setAttachmentID={setVideoAttachmentID}
													attachmentFilename={videoAttachmentFilename}
													setAttachmentFilename={setVideoAttachmentFilename}
													helpText="Upload a video file (MP4, MOV, etc.)"
													disabled={false}
												/>
											) : (
												<FormInputField
													label="Video URL"
													name="videoURL"
													type="url"
													placeholder={`Enter ${
														videoType === EXERCISE_VIDEO_TYPE_YOUTUBE
															? "YouTube"
															: "Vimeo"
													} URL`}
													value={videoURL}
													errorText={errors?.videoUrl}
													onChange={handleVideoURLChange}
													helpText={`Enter the ${
														videoType === EXERCISE_VIDEO_TYPE_YOUTUBE
															? "YouTube"
															: "Vimeo"
													} video URL`}
												/>
											)}
										</div>
									</div>
								</div>

								{/* Action Buttons */}
								<div className="px-6 py-4 bg-gray-50 flex flex-col sm:flex-row justify-end gap-3">
									<Link
										to="/admin/tips"
										className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 
                             shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white 
                             hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
                             transition-all duration-300">
										Cancel
									</Link>
									<button
										type="submit"
										className="inline-flex justify-center items-center px-6 py-2 border border-transparent 
                             shadow-sm text-sm font-medium rounded-lg text-white bg-primary 
                             hover:bg-primary-dark focus:ring-2 focus:ring-offset-2 focus:ring-primary 
                             transition-all duration-300">
										<FontAwesomeIcon icon={faPencil} className="mr-2" />
										Update Tip
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminTipUpdate;
