import React, {useState, useEffect} from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faFilterCircleXmark,
	faTrophy,
	faArrowLeft,
	faPlus,
	faGauge,
	faTable,
	faRefresh,
	faFilter,
	faSearch,
	faExclamationCircle,
	faCalendarAlt,
	faUser,
	faLocationDot,
	faCheckCircle,
	faClock,
	faArrowRight,
	faLeaf,
	faUserGear,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	getFitnessPlanListAPI,
	deleteFitnessPlanAPI,
	postFitnessPlanCreateAPI,
} from "../../../API/FitnessPlan";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	fitnessPlanFilterShowState,
	fitnessPlanFilterSortState,
	fitnessPlanFilterTemporarySearchTextState,
	fitnessPlanFilterActualSearchTextState,
	fitnessPlanFilterStatusState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Layout from "../../Menu/Layout";
import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import CardView from "../../Reusable/List/CardView";
import Modal from "../../Reusable/Modal";
import FormInputField from "../../Reusable/FormInputField";
import {
	FITNESS_PLAN_STATUS_CONFIG,
	NUTRITION_PLAN_VISIBILITY_OPTIONS,
} from "../../../Constants/FieldOptions";

function MemberFitnessPlanList() {
	const navigate = useNavigate();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		fitnessPlanFilterShowState
	); // Filtering + Searching

	const [sort, setSort] = useRecoilState(fitnessPlanFilterSortState); // Sorting

	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		fitnessPlanFilterTemporarySearchTextState
	); // Searching - The search field value as user writes their query.

	const [actualSearchText, setActualSearchText] = useRecoilState(
		fitnessPlanFilterActualSearchTextState
	); // Searching - The actual search query value to submit to the API.

	const [status, setStatus] = useRecoilState(fitnessPlanFilterStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [selectedFitnessPlanForDeletion, setSelectedFitnessPlanForDeletion] =
		useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [showGenerateModal, setshowGenerateModal] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");

	////
	//// API.
	////

	function onFitnessPlanListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onFitnessPlanListError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessPlanListDone() {
		setFetching(false);
	}

	function onFitnessPlanDeleteSuccess(response) {
		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("FitnessPlan deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Fetch again an updated list.
		fetchList(currentCursor, pageSize, actualSearchText, status, sort);
	}

	function onFitnessPlanDeleteError(apiErr) {
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onFitnessPlanDeleteDone() {
		setFetching(false);
	}

	const handleNavigateToAccount = (e) => {
		e.preventDefault();
		navigate("/account", {state: {activeTabProp: "detail"}});
	};

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Fitness Plans",
			icon: faLeaf,
		},
	];

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, stat, sbv) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination
		params.set("user_id", currentUser.id);
		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		if (stat !== "") {
			params.set("status", stat);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}

		params.set("status", stat);

		getFitnessPlanListAPI(
			params,
			onFitnessPlanListSuccess,
			onFitnessPlanListError,
			onFitnessPlanListDone
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		setActualSearchText(temporarySearchText);
	};

	const onSelectFitnessPlanForDeletion = (e, datum) => {
		setSelectedFitnessPlanForDeletion(datum);
	};

	const onDeselectFitnessPlanForDeletion = (e) => {
		setSelectedFitnessPlanForDeletion("");
	};

	const onDeleteConfirmButtonClick = (e) => {
		deleteFitnessPlanAPI(
			selectedFitnessPlanForDeletion.id,
			onFitnessPlanDeleteSuccess,
			onFitnessPlanDeleteError,
			onFitnessPlanDeleteDone
		);
		setSelectedFitnessPlanForDeletion("");
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setSort("no,1");
		setStatus(0);
	};

	const onGenerateFitnessplan = (e) => {
		e.preventDefault();
		setFetching(true);
		setErrors({});
		setshowGenerateModal(false);

		// To Snake-case for API from camel-case in React.
		const decamelizedData = {name: name};
		postFitnessPlanCreateAPI(
			decamelizedData,
			onMemberFitnessPlanAddSuccess,
			onMemberFitnessPlanAddError,
			onMemberFitnessPlanAddDone
		);
	};

	function onMemberFitnessPlanAddSuccess(response) {
		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Your fitness plan request has been submitted");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Redirect the user to a new page.
		setForceURL("/fitness-plan/" + response.id);
	}

	function onMemberFitnessPlanAddError(apiErr) {
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onMemberFitnessPlanAddDone() {
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setActualSearchText(temporarySearchText);
		}, 500); // 500ms delay

		return () => clearTimeout(timeoutId);
	}, [temporarySearchText, setActualSearchText]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			fetchList(currentCursor, pageSize, actualSearchText, status, sort);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, sort]);

	////
	//// Component rendering.
	////
	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const renderFitnessPlanCard = (plan) => {
		const statusConfig = FITNESS_PLAN_STATUS_CONFIG[plan.status] || {
			label: "Unknown",
			color: "text-gray-500",
			icon: faExclamationCircle,
		};

		return (
			<div className="h-full flex flex-col border rounded-lg shadow-lg overflow-hidden bg-white">
				<div className="p-6 bg-gradient-to-r from-primary to-primary-light text-white">
					<h2 className="text-2xl font-bold">{plan.name}</h2>
					<p className="text-sm mt-1 text-white">
						Created by: {plan.createdByUserName}
					</p>
				</div>

				<div className="p-6 space-y-4 flex-grow">
					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={statusConfig.icon}
							className={`w-5 ${statusConfig.color}`}
						/>
						<span className={`text-sm ${statusConfig.color}`}>
							Status: {statusConfig.label}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={faCalendarAlt}
							className="text-gray-400 w-5"
						/>
						<span className="text-sm text-gray-600">
							Created on:{" "}
							{new Date(plan.createdAt).toLocaleDateString("en-US", {
								weekday: "short",
								month: "long",
								day: "numeric",
							})}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon icon={faUser} className="text-gray-400 w-5" />
						<span className="text-sm text-gray-600">
							Trainer: {plan.trainerName || "Not assigned"}
						</span>
					</div>

					<div className="flex items-center space-x-2">
						<FontAwesomeIcon
							icon={faLocationDot}
							className="text-gray-400 w-5"
						/>
						<span className="text-sm text-gray-600">
							Organization: {plan.organizationName}
						</span>
					</div>
				</div>

				<div className="p-4 bg-gray-50">
					<Link
						to={`/fitness-plan/${plan.id}`}
						className="w-full py-2.5 sm:py-3 px-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-all duration-300 inline-flex items-center justify-center text-sm">
						View Plan
					</Link>
				</div>
			</div>
		);
	};

	// Component parts
	const DeleteConfirmationModal = () => (
		<Modal
			isOpen={!!selectedFitnessPlanForDeletion}
			onClose={onDeselectFitnessPlanForDeletion}
			header="Delete Fitness Plan">
			<div className="space-y-4">
				<p className="text-gray-600">
					Are you sure you want to delete this fitness plan? This action cannot
					be undone.
				</p>
				<div className="flex justify-end space-x-3">
					<button
						onClick={onDeselectFitnessPlanForDeletion}
						className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors">
						Cancel
					</button>
					<button
						onClick={onDeleteConfirmButtonClick}
						className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors duration-300">
						Delete
					</button>
				</div>
			</div>
		</Modal>
	);

	const filterContent = (
		<FilterSidebar>
			<FilterSection title="Search">
				<div className="relative">
					<input
						type="text"
						placeholder="Search fitness plans..."
						value={temporarySearchText}
						onChange={(e) => setTemporarySearchText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSearchButtonClick();
							}
						}}
						className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/10 transition-all duration-300"
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
					/>
				</div>
			</FilterSection>

			<FilterSection title="Status">
				<select
					value={status}
					onChange={(e) => setStatus(parseInt(e.target.value))}
					className="w-full rounded-lg border border-gray-200 py-2 px-3 focus:border-primary focus:ring-2 focus:ring-primary/10">
					<option value={0}>All Status</option>
					{Object.entries(FITNESS_PLAN_STATUS_CONFIG).map(([value, config]) => (
						<option key={value} value={value}>
							{config.label}
						</option>
					))}
				</select>
			</FilterSection>

			<div className="mt-6">
				<button
					onClick={onClearFilterClick}
					className="w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
					Clear Filters
				</button>
			</div>
		</FilterSidebar>
	);

	const ActionButtons = () => (
		<div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2 sm:gap-3 w-full sm:w-auto">
			<button
				onClick={() =>
					fetchList(currentCursor, pageSize, actualSearchText, status, sort)
				}
				className="inline-flex items-center justify-center px-4 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
				<FontAwesomeIcon icon={faRefresh} className="mr-2" />
				<span>Refresh</span>
			</button>

			<button
				onClick={() => setshowGenerateModal(true)}
				className="inline-flex items-center justify-center px-4 py-2 text-sm bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				Request Plan
			</button>
		</div>
	);

	const EmptyState = () => (
		<div className="text-center py-12">
			<FontAwesomeIcon
				icon={faTrophy}
				className="text-gray-400 text-4xl mb-4"
			/>
			<h3 className="text-lg font-medium text-gray-900 mb-2">
				No Fitness Plans Found
			</h3>
			<p className="text-gray-500">
				{actualSearchText
					? `No fitness plans matching "${actualSearchText}"`
					: "Start by requesting a new fitness plan"}
			</p>
			<button
				onClick={() => setshowGenerateModal(true)}
				className="inline-flex items-center px-4 py-2 mt-4 text-sm bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-300">
				<FontAwesomeIcon icon={faPlus} className="mr-2" />
				Request Plan
			</button>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<DeleteConfirmationModal />
			<Modal
				isOpen={showGenerateModal}
				onClose={() => setshowGenerateModal(false)}
				header="Generate Fitness Plan">
				<div className="space-y-6">
					{/* Warning Message */}
					<div className="flex items-start space-x-3 text-gray-600">
						<FontAwesomeIcon
							icon={faExclamationCircle}
							className="text-yellow-500 mt-1 flex-shrink-0"
						/>
						<div className="space-y-4">
							<p>
								You are about to create a fitness plan based on your profile.
							</p>
							<p>
								Plan will be based on your profile. If you wish to make any
								changes in your profile, please edit it{" "}
								<Link
									onClick={(e) => handleNavigateToAccount(e)}
									className="text-primary hover:text-primary-dark inline-flex items-center">
									here
									<FontAwesomeIcon
										icon={faArrowRight}
										className="ml-1 h-4 w-4"
									/>
								</Link>
							</p>
						</div>
					</div>

					{/* Form Input */}
					<div className="pt-2">
						<FormInputField
							label="Name:"
							name="name"
							placeholder="Fitness plan name"
							value={name}
							errorText={errors && errors.name}
							helpText="Give this fitness plan a name you can use to keep track for your own purposes. Ex: 'My Cardio-Plan'."
							onChange={(e) => setName(e.target.value)}
							isRequired={true}
						/>
					</div>

					{/* Footer Buttons */}
					<div className="flex justify-end space-x-3 pt-4 border-t border-gray-200">
						<button
							onClick={() => setshowGenerateModal(false)}
							className="px-4 py-2 text-gray-600 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors">
							Cancel
						</button>
						<button
							onClick={onGenerateFitnessplan}
							disabled={!name}
							title={!name ? "Enter Name to submit" : ""}
							className={`px-4 py-2 rounded-lg transition-colors duration-300 
              ${
								name
									? "bg-primary text-white hover:bg-primary-dark"
									: "bg-gray-100 text-gray-400 cursor-not-allowed"
							}`}>
							Confirm
						</button>
					</div>
				</div>
			</Modal>

			<div className="relative bg-gray-900">
				{/* Decorative Elements */}
				<div className="absolute inset-0">
					<div className="absolute inset-0 opacity-10" />
					<div className="absolute inset-0 bg-gradient-to-br from-primary/10 via-accent1/10 to-primary-light/10" />
					<div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-lightShade/25 to-transparent" />
				</div>

				{/* Hero Content */}
				<div className="relative container mx-auto px-4 sm:px-6 py-12 sm:py-20">
					<div className="mx-auto text-center">
						{/* Professional Badge */}
						<div
							className="inline-flex items-center justify-center p-2 mb-6 
                    rounded-2xl bg-primary/10 backdrop-blur-xs 
                    border border-primary/25">
							<span className="px-3 py-1 text-sm font-medium text-lightShade font-secondary">
								Professional MMA Training Plans
							</span>
						</div>

						{/* Main Heading */}
						<h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-lightShade mb-4 sm:mb-6 font-primary">
							Your Personalized Training Plan
						</h1>

						{/* Primary Description */}
						<p className="text-lg sm:text-xl text-gray-100/90 mx-auto mb-12 font-secondary">
							Expert-designed MMA training plans customized to your skill level,
							goals, and preferences
						</p>

						{/* Info Boxes */}
						<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mx-auto mb-12">
							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faUserGear}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Profile Based
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Plans are crafted using your onboarding responses and current
									profile preferences
								</p>
							</div>

							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faRefresh}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Always Fresh
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Generate new plans anytime as your fitness journey evolves
								</p>
							</div>

							<div className="p-6 rounded-xl bg-primary/5 border border-primary/25 text-left">
								<div className="flex items-center gap-3 mb-3">
									<FontAwesomeIcon
										icon={faUserTie}
										className="text-primary text-xl"
									/>
									<h3 className="text-lg font-semibold text-lightShade font-primary">
										Expert Design
									</h3>
								</div>
								<p className="text-sm text-gray-300 font-secondary">
									Created by certified MMA trainers for optimal progression
								</p>
							</div>
						</div>

						{/* Additional Info Grid */}
						{/* Additional Info Grid */}
						<div className="grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto">
							<div className="p-6 rounded-xl bg-gray-800/50 text-left">
								<h4 className="text-base font-semibold text-lightShade mb-2 font-primary">
									Getting Started
								</h4>
								<p className="text-sm text-gray-300 font-secondary">
									Complete your onboarding questionnaire to set your initial
									preferences and generate your first training plan
								</p>
							</div>

							<div className="p-6 rounded-xl bg-gray-800/50 text-left">
								<h4 className="text-base font-semibold text-lightShade mb-2 font-primary">
									Plan Updates
								</h4>
								<p className="text-sm text-gray-300 font-secondary">
									Visit your profile settings anytime to update your preferences
									and generate new customized plans
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ListWithFilters
				title={
					<div className="flex items-center justify-between py-4 px-4 sm:px-6 border-b border-gray-200">
						<div className="flex items-center space-x-3">
							<FontAwesomeIcon
								icon={faTrophy}
								className="text-primary text-xl"
							/>
							<h2 className="text-xl font-semibold">Fitness Plans</h2>
						</div>
						<div className="flex items-center space-x-2">
							<ActionButtons />
						</div>
					</div>
				}
				filterContent={filterContent}
				loading={isFetching}
				error={typeof errors === "string" ? errors : null}
				EmptyStateComponent={<EmptyState />}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={listData?.hasNextPage}>
				<CardView
					items={listData?.results}
					renderItem={renderFitnessPlanCard}
					linkPrefix="/fitness-plans"
					emptyState={<EmptyState />}
				/>
			</ListWithFilters>
		</Layout>
	);
}

export default MemberFitnessPlanList;
