const ContentHeader = ({ title, subtitle, actions }) => {
    return (
        <div className="border-b border-gray-200 px-4 sm:px-6 py-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
                <div>
                    <h2 className="text-lg sm:text-xl font-semibold text-gray-900">
                        {title}
                    </h2>
                    {subtitle && (
                        <p className="text-xs sm:text-sm text-gray-500 mt-1">
                            {subtitle}
                        </p>
                    )}
                </div>
                {actions && (
                    <div className="flex gap-2">
                        {actions}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ContentHeader;
