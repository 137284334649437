import { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faGauge,
  faEye,
  faCalendarPlus,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
  currentUserState,
  topAlertMessageState,
  topAlertStatusState,
} from "../../../AppState";
import { postTrainingProgCreateAPI } from "../../../API/trainingProgram";
import { getMemberListOptionsAPI } from "../../../API/member";
import FormSelectField from "../../Reusable/FormSelectField";
import FormRadioField from "../../Reusable/FormRadioField";
import Layout from "../../Menu/Layout";
import { ListHeader } from "../../Reusable/AdminList";

function AdminTrainingProgramAdd() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentuser] = useRecoilState(currentUserState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [memoptions, setmemoptions] = useState([]);

  const [name, setName] = useState("");
  const [phases, setphases] = useState(4);
  const [weeks, setweeks] = useState(4);
  const [description, setDescription] = useState("");
  const [user, setuser] = useState("");
  const [visibility, setVisibility] = useState("");

  ////
  //// Event handling.
  ////

  const onSubmitClick = (e) => {
    setFetching(true);
    setErrors({});
    postTrainingProgCreateAPI(
      {
        name: name,
        description: description,
        phases: parseInt(phases),
        weeks: parseInt(weeks),
        organization_id: currentuser.organizationId,
        user_id: user,
        visibility: parseInt(visibility),
      },
      onAddSuccess,
      onAddError,
      onAddDone
    );
  };

  ////
  //// API.
  ////

  // --- VideoCollection Create --- //

  function onAddSuccess(response) {
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("program  created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Redirect the organization to the organization attachments page.
    setForceURL("/admin/training-program/" + response.id + "");
  }

  function onAddError(apiErr) {
    setErrors(apiErr);

    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onAddDone() {
    setFetching(false);
  }

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
    }

    getMemberListOptionsAPI(
      currentuser.organizationId,
      onListOK,
      onListNotOK,
      onDone
    );

    return () => {
      mounted = false;
    };
  }, []);

  function onListOK(resp) {
    setFetching(false);
    if (resp?.length) {
      setmemoptions([{ value: "", label: "please select user" }, ...resp]);
    }
  }

  function onListNotOK(resp) {
    setErrors(resp);
    // Add a temporary banner message in the app and then clear itself after 2 seconds.
    setTopAlertMessage("Failed gettiing list");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
  }
  function onDone() {
    setFetching(false);
  }

  ////
  //// Component rendering.
  ////

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    {label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
    {label: "Training Programs", link: "/admin/training-program", icon: faBook},
    {label: "Add", icon: faPlus},
  ];  

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
    <div className="min-h-screen bg-gray-50/50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <ListHeader
          title="Add Training Program"
          icon={faCalendarPlus}
        />

        {/* Main Content */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          {/* Form Content */}
          <div className="p-6 space-y-6">
            <FormErrorBox errors={errors} />

            {/* Basic Information */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">
                Basic Information
              </h3>
              
              <FormInputField
                label="Name"
                name="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                errorText={errors && errors.name}
                helpText="Enter the program name"
                maxWidth="max-w-lg"
              />

              <FormTextareaField
                label="Description"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                errorText={errors && errors.description}
                helpText="Provide a detailed description"
                rows={4}
                maxWidth="max-w-lg"
              />
            </div>

            {/* Program Structure */}
            <div className="space-y-4 pt-6 border-t border-gray-200">
              <h3 className="text-lg font-medium text-gray-900">
                Program Structure
              </h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormInputField
                  label="Number of Phases"
                  name="phases"
                  type="number"
                  value={phases}
                  onChange={(e) => setphases(e.target.value)}
                  errorText={errors && errors.phases}
                  helpText="Enter number of phases"
                />

                <FormInputField
                  label="Weeks per Phase"
                  name="weeks"
                  type="number"
                  value={weeks}
                  onChange={(e) => setweeks(e.target.value)}
                  errorText={errors && errors.weeks}
                  helpText="Enter weeks per phase"
                />
              </div>
            </div>

            {/* Visibility Settings */}
            <div className="space-y-4 pt-6 border-t border-gray-200">
              <h3 className="text-lg font-medium text-gray-900">
                Visibility Settings
              </h3>

              <FormRadioField
                      label="Visibility"
                      name="visibility"
                      placeholder="Pick"
                      value={visibility}
                      opt1Value="1"
                      opt1Label="Public"
                      opt2Value="2"
                      opt2Label="Private"
                      errorText={errors && errors.visibility}
                      onChange={(e) => setVisibility(e.target.value)}
                      maxWidth="180px"
                      disabled={false}
                      helpText=""
                    />

                    {visibility === "2" && (
                      <div className="columns">
                        <div className="column">
                          <FormSelectField
                            label={"Pick User"}
                            onChange={(e) => setuser(e.target.value)}
                            options={memoptions}
                            selectedValue={user}
                          />
                        </div>
                      </div>
                    )}
            </div>
          </div>

          {/* Form Actions */}
          <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 flex justify-end space-x-3">
            <Link
              to="/admin/training-programs"
              className="inline-flex items-center px-4 py-2 border border-gray-300 
                       text-sm font-medium rounded-lg text-gray-700 bg-white 
                       hover:bg-gray-50 focus:outline-none focus:ring-2 
                       focus:ring-offset-2 focus:ring-primary"
            >
              Cancel
            </Link>
            <button
              onClick={onSubmitClick}
              className="inline-flex items-center px-4 py-2 border border-transparent 
                       text-sm font-medium rounded-lg text-white bg-primary 
                       hover:bg-primary-dark focus:outline-none focus:ring-2 
                       focus:ring-offset-2 focus:ring-primary"
              disabled={isFetching}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Create Program
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  );
}

export default AdminTrainingProgramAdd;
