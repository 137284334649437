import React, { useState, useEffect } from "react";
import Scroll from "react-scroll";
import { useParams } from "react-router-dom";
import { getVideoContentListAPI } from "../../../API/VideoContent";
import { LoadingState } from "./components/LoadingState";
import { ErrorState } from "./components/ErrorState";
import Layout from "../../Menu/Layout";
import { currentUserState } from "../../../AppState";
import { useRecoilState } from "recoil";
import CardLayout from "./components/CardLayout";
import { faGauge, faChevronRight, faFolderOpen, faVideo, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";

const CollectionDetail = () => {
	const {collectionId} = useParams();
	const [videos, setVideos] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		const params = new Map();
		params.set("collection_id", collectionId);

		Scroll.animateScroll.scrollToTop();

		getVideoContentListAPI(
			params,
			(response) => {
				const filteredVideos = response.results.filter(
					(video) => video.collectionId === collectionId
				);
				setVideos(filteredVideos);
				setIsLoading(false);
			},
			(error) => {
				setError(error);
				setIsLoading(false);
			},
			() => setIsLoading(false)
		);
	}, [collectionId]);

	if (isLoading) return <LoadingState />;
	if (error) return <ErrorState error={error} />;

	console.log(videos);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/dashboard", icon: faGauge},
		{label: "MMA Lessons", link: "/videos", icon: faVideo},
		{label: videos?.length > 0 ? videos[0]?.categoryName : "Collections", link: `/videos/category/${videos[0]?.categoryId}`, icon: faFolderOpen},
		{label: videos?.length > 0 ? videos[0]?.collectionName : "Content", icon: faLayerGroup},
	];
  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 p-4">
        {videos.length > 0 ? (
          videos.map((video) => (
            <CardLayout key={video.id} item={{ ...video, type: 'video' }} />
          ))
        ) : (
          <CardLayout emptyStateMessage="Videos will appear here once added." />
        )}
      </div>
    </Layout>
  );
};

export default CollectionDetail;