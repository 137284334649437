import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faArrowLeft,
	faGauge,
	faPencil,
	faEye,
	faLevelUp,
	faRibbon,
} from "@fortawesome/free-solid-svg-icons";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {useRecoilState} from "recoil";
import Layout from "../../Menu/Layout";
// import {getLevelDetailAPI, putLevelUpdateAPI} from "../../../API/levels";
import {
	BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
	FILE_UPLOAD_OPTIONS,
} from "../../../Constants/FieldOptions";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormSelectField from "../../Reusable/FormSelectField";
import {getLevelDetailAPI, putLevelUpdateAPI} from "../../../API/level";

function AdminLevelUpdate() {
	// URL Parameters.
	const {id} = useParams();

	// Global state.
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states.
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [thumbnailType, setThumbnailType] = useState(1);
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [challengesRequired, setChallengesRequired] = useState(0);

	// Breadcrumb items
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Levels", link: "/admin/levels", icon: faLevelUp},
		{label: "Detail", link: `/admin/levels/${id}`, icon: faEye},
		{label: "Edit", icon: faPencil},
	];

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});
		putLevelUpdateAPI(
			{
				id,
				name,
				description,
				thumbnail_type: thumbnailType,
				thumbnail_attachment_id: thumbnailAttachmentID,
				thumbnail_attachment_name: thumbnailAttachmentName,
				thumbnail_url: thumbnailURL,
				num_challenges: parseInt(challengesRequired),
			},
			onUpdateSuccess,
			onUpdateError,
			onUpdateDone
		);
		console.log("onSubmitClick: Finished.");
	};

	////
	//// API.
	////

	// --- Level Update --- //

	function onUpdateSuccess(response) {
		// For debugging purposes only.
		console.log("onUpdateSuccess: Starting...");
		console.log(response);

		// Update a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Level updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onUpdateSuccess: Delayed for 2 seconds.");
			console.log(
				"onUpdateSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL(`/admin/levels/${response.id}`);
	}

	function onUpdateError(apiErr) {
		console.log("onUpdateError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onUpdateError: Delayed for 2 seconds.");
			console.log(
				"onUpdateError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onUpdateDone() {
		console.log("onUpdateDone: Starting...");
		setFetching(false);
	}

	// --- Level Detail --- //

	function onDetailSuccess(response) {
		// For debugging purposes only.
		console.log("onDetailSuccess: Starting...");
		console.log(response);

		// DEVELOPERS NOTE: If zero string then that's a MongoDB empty db record so handle the code as follows.
		if (response.thumbnailAttachmentId === "000000000000000000000000") {
			response.thumbnailAttachmentId = "";
		} else {
			setThumbnailAttachmentID(response.thumbnailAttachmentId);
		}

		setThumbnailType(response.thumbnailType);
		setThumbnailURL(response.thumbnailUrl);
		setThumbnailAttachmentName(response.thumbnailAttachmentName);
		setDescription(response.description);
		setChallengesRequired(response.numChallenges);
		setName(response.name);
	}

	function onDetailError(apiErr) {
		console.log("onDetailError: Starting...");
		setErrors(apiErr);

		// Detail a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onDetailError: Delayed for 2 seconds.");
			console.log(
				"onDetailError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		console.log("onDetailDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			getLevelDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="animate-fade-in">
				<div className="bg-white rounded-xl shadow-lg border border-gray-200 p-8">
					{/* Enhanced Header */}
					<div className="flex items-center mb-8">
						<div className="bg-primary/10 p-3 rounded-lg mr-4">
							<FontAwesomeIcon
								icon={faPencil}
								className="text-primary text-2xl"
							/>
						</div>
						<div>
							<h1 className="text-2xl font-bold text-gray-900">Edit Level</h1>
							<p className="text-gray-500 text-sm mt-1">Update level details</p>
						</div>
					</div>

					<FormErrorBox errors={errors} />

					{isFetching ? (
						<PageLoadingContent displayMessage={"Loading level details..."} />
					) : (
						<>
							{/* Form Content */}
							<div className="grid md:grid-cols-2 gap-8 mt-6">
								{/* Left Column - Basic Information */}
								<div className="space-y-6">
									<div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
										<h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
											Basic Information
										</h3>

										<div className="space-y-6">
											<FormInputField
												name="name"
												label="Level Name"
												placeholder="Enter the level name"
												value={name}
												onChange={(e) => setName(e.target.value)}
												isRequired={true}
												errorText={errors?.name}
												className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
											/>

											<FormTextareaField
												name="description"
												label="Description"
												placeholder="Describe the level"
												value={description}
												onChange={(e) => setDescription(e.target.value)}
												isRequired={true}
												errorText={errors?.description}
												className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
												rows={4}
											/>

											<FormInputField
												name="challengesRequired"
												label="Challenges Required"
												type="number"
												value={challengesRequired}
												onChange={(e) => setChallengesRequired(e.target.value)}
												isRequired={true}
												errorText={errors?.num_challenges}
												className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
											/>
										</div>
									</div>
								</div>

								{/* Right Column - Thumbnail Settings */}
								<div className="space-y-6">
									<div className="bg-gray-50 p-6 rounded-xl border border-gray-100">
										<h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-4">
											Thumbnail Settings
										</h3>

										<div className="space-y-6">
											<FormSelectField
												name="thumbnailType"
												label="Thumbnail Type"
												value={thumbnailType}
												onChange={(e) =>
													setThumbnailType(parseInt(e.target.value))
												}
												options={FILE_UPLOAD_OPTIONS}
												errorText={errors?.thumbnail_type}
												className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
											/>

											{thumbnailType === 1 ? (
												<FormAttachmentField
													name="thumbnail"
													label="Upload Thumbnail"
													attachmentID={thumbnailAttachmentID}
													attachmentName={thumbnailAttachmentName}
													setAttachmentID={setThumbnailAttachmentID}
													setAttachmentName={setThumbnailAttachmentName}
													errorText={errors?.thumbnail_attachment_id}
												/>
											) : (
												<FormInputField
													name="thumbnailURL"
													label="Thumbnail URL"
													placeholder="Enter the URL for the thumbnail"
													value={thumbnailURL}
													onChange={(e) => setThumbnailURL(e.target.value)}
													errorText={errors?.thumbnail_url}
													className="w-full rounded-lg border-gray-300 focus:ring-primary focus:border-primary"
												/>
											)}
										</div>
									</div>
								</div>
							</div>

							{/* Footer Actions */}
							<div className="flex justify-end space-x-4 mt-8 pt-6 border-t border-gray-200">
								<Link
									to={`/admin/levels/${id}`}
									className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-all duration-300 shadow-sm hover:shadow-md group">
									<FontAwesomeIcon
										icon={faArrowLeft}
										className="mr-2 group-hover:-translate-x-1 transition-transform duration-300"
									/>
									Cancel
								</Link>
								<button
									onClick={onSubmitClick}
									disabled={isFetching}
									className={`
                    inline-flex items-center px-6 py-2 rounded-lg text-white
                    ${
											isFetching
												? "bg-gray-400 cursor-not-allowed"
												: "bg-primary hover:bg-primary-dark"
										}
                    transition-all duration-300 shadow-sm hover:shadow-md
                  `}>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className={`mr-2 ${isFetching ? "animate-spin" : ""}`}
									/>
									{isFetching ? "Saving..." : "Save Changes"}
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</Layout>
	);
}

export default AdminLevelUpdate;
