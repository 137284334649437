import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

const Breadcrumb = ({items}) => {
	// Only show mobile view if there's more than one item
	const showMobileBack = items.length > 1;

	return (
		<nav>
			{/* Mobile View - Only render if there's more than one item */}
			{showMobileBack && (
				<div className="md:hidden bg-lightShade p-4 rounded-lg">
					<Link
						to={items[items.length - 2]?.link || "/dashboard"}
						className="flex items-center text-main hover:text-darkOrangeShade transition-colors duration-DEFAULT">
						<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
						Back to {items[items.length - 2]?.label || "Dashboard"}
					</Link>
				</div>
			)}

			{/* Tablet/Desktop View */}
			<div
				className={`${
					showMobileBack ? "hidden md:flex" : "flex"
				} items-center space-x-4 p-4 bg-lightShade rounded-lg shadow-sm border border-lightGrayShade`}>
				{items.map((item, index) => (
					<React.Fragment key={index}>
						{index > 0 && <span className="text-gray-400">/</span>}
						{item.link ? (
							<Link
								to={item.link}
								className="text-main hover:text-darkOrangeShade transition-colors duration-DEFAULT flex items-center">
								{item.icon && (
									<FontAwesomeIcon icon={item.icon} className="mr-2" />
								)}
								{item.label}
							</Link>
						) : (
							<span className="text-darkShade flex items-center">
								{item.icon && (
									<FontAwesomeIcon icon={item.icon} className="mr-2" />
								)}
								{item.label}
							</span>
						)}
					</React.Fragment>
				))}
			</div>
		</nav>
	);
};

export default Breadcrumb;
