import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {useRecoilState} from "recoil";
import {useParams, Link, Navigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClock,
  faGauge,
  faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";

import Layout from "../../Menu/Layout";
import {getTipDetailAPI} from "../../../API/tips";
import {topAlertMessageState, topAlertStatusState, currentUserState} from "../../../AppState";
import {VideoPlayer, VIDEO_LAYOUTS} from "../../Reusable/VideoPlayer";

const MemberPreviousLessonsDetail = () => {
  const {id} = useParams();
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [datum, setDatum] = useState({});

  const breadcrumbItems = [
	{
		label: "Dashboard",
		icon: faGauge,
		link: "/dashboard",
	},
	{
		label: "Previous Tips",
		icon: faTicketSimple,
	},
  ]

  function onPreviousTipsDetailSuccess(response) {
    console.log("onPreviousTipsDetailSuccess: Starting...");
    setDatum(response);
  }

  function onPreviousTipsDetailError(apiErr) {
    console.log("onPreviousTipsDetailError: Starting...");
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onPreviousTipsDetailDone() {
    console.log("onPreviousTipsDetailDone: Starting...");
    setFetching(false);
  }

  // Handle like, save, and share actions
  const handleLike = (isLiked) => {
    console.log("Tip liked:", isLiked);
    // Implement like functionality
  };

  const handleSave = (isSaved) => {
    console.log("Tip saved:", isSaved);
    // Implement save functionality
  };

  const handleShare = () => {
    console.log("Share clicked");
    // Implement share functionality
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      Scroll.animateScroll.scrollToTop();
      setFetching(true);
      getTipDetailAPI(
        id,
        onPreviousTipsDetailSuccess,
        onPreviousTipsDetailError,
        onPreviousTipsDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="bg-lightShade rounded-lg shadow-sm">
        {isFetching ? (
          <div className="p-6">
            <div className="animate-pulse space-y-4">
              <div className="h-96 bg-lightGrayShade rounded-lg"></div>
              <div className="h-8 w-1/3 bg-lightGrayShade rounded"></div>
              <div className="h-24 bg-lightGrayShade rounded"></div>
            </div>
          </div>
        ) : (
          <>
            <VideoPlayer
              name={datum.name}
              videoType={datum.videoType}
              videoUrl={datum.videoUrl}
              videoObjectUrl={datum.videoObjectUrl}
              thumbnailUrl={datum.thumbnailUrl || datum.thumbnailObjectUrl}
              description={datum.description}
              metadata={[ 
                {
                  icon: faClock,  
                  label: "Posted On",
                  value: new Date(datum.createdAt).toLocaleDateString()
                },
                {
                  icon: faTicketSimple, 
                  label: "Category",
                  value: datum.category || "General"
                },
                {
                  icon: faClock,
                  label: "Duration",
                  value: datum.duration || "N/A"
                }
              ]}
              layout={VIDEO_LAYOUTS.COMPACT}
              showRelated={false}
              showActions={true}
              showDescription={true}
              onLike={handleLike}
              onSave={handleSave}
              onShare={handleShare}
            />
            <div className="p-6 border-t border-lightGrayShade">
              <Link 
                to="/previous-week-tips"
                className="inline-flex items-center px-4 py-2 rounded-lg bg-lightGrayShade text-textDark hover:bg-gray-200 transition-all duration-DEFAULT">
                <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                Back to Previous Tips
              </Link>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default MemberPreviousLessonsDetail;