import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilterCircleXmark,
  faTrophy,
  faArrowLeft,
  faUsers,
  faEye,
  faPlus,
  faGauge,
  faArrowRight,
  faTable,
  faRefresh,
  faFilter,
  faSearch,
  faExclamationCircle,
  faCalendar,
  faTrash,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState, useRecoilValue } from "recoil";

import FormErrorBox from "../../../Reusable/FormErrorBox";
import {
  getFitnessPlanListAPI,
  deleteFitnessPlanAPI,
  postFitnessPlanCreateAPI,
} from "../../../../API/FitnessPlan";
import {
  topAlertMessageState,
  topAlertStatusState,
  currentUserState,
  videoCategoryFilterShowState,
  videoCategoryFilterTemporarySearchTextState,
  videoCategoryFilterActualSearchTextState,
  videoCategoryFilterSortState,
  videoCategoryFilterStatusState,
} from "../../../../AppState";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../../Reusable/FormInputFieldWithButton";
import FormInputField from "../../../Reusable/FormInputField";
import Modal from "../../../Reusable/Modal";
import ListWithFilters from "../../../Reusable/List/ListWithFilters";
import FilterSidebar, { FilterSection } from "../../../Reusable/List/FilterSidebar";
import CardView from "../../../Reusable/List/CardView";

function AdminFitnessPlanList() {
  ////
  //// URL Parameters.
  ////
  const { uid } = useParams();
  let navigate = useNavigate();

  ////
  //// Global state.
  ////
  const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const currentUser = useRecoilValue(currentUserState);
  const [showFilter, setShowFilter] = useRecoilState(videoCategoryFilterShowState);
  const [sort, setSort] = useRecoilState(videoCategoryFilterSortState);
  const [temporarySearchText, setTemporarySearchText] = useRecoilState(
    videoCategoryFilterTemporarySearchTextState
  );
  const [actualSearchText, setActualSearchText] = useRecoilState(
    videoCategoryFilterActualSearchTextState
  );
  const [status, setStatus] = useRecoilState(videoCategoryFilterStatusState);

  ////
  //// Component states.
  ////
  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState("");
  const [selectedFitnessPlanForDeletion, setSelectedFitnessPlanForDeletion] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [previousCursors, setPreviousCursors] = useState([]);
  const [nextCursor, setNextCursor] = useState("");
  const [currentCursor, setCurrentCursor] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showGenerateModal, setshowGenerateModal] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [name, setName] = useState("");

    ////
  //// API Functions
  ////
  function onFitnessPlanListSuccess(response) {
    if (response.results !== null) {
      setListData(response);
      if (response.hasNextPage) {
        setNextCursor(response.nextCursor);
      }
    } else {
      setListData([]);
      setNextCursor("");
    }
  }

  function onFitnessPlanListError(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onFitnessPlanListDone() {
    setFetching(false);
  }

  function onFitnessPlanDeleteSuccess(response) {
    setTopAlertStatus("success");
    setTopAlertMessage("FitnessPlan deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    fetchList(currentCursor, pageSize, actualSearchText, status, sort);
  }

  function onFitnessPlanDeleteError(apiErr) {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onFitnessPlanDeleteDone() {
    setFetching(false);
    setShowModal(false);
  }

  function onMemberFitnessPlanAddSuccess(response) {
    setTopAlertMessage("Fitness plan created");
    setTopAlertStatus("success");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    setForceURL(`/admin/member/${response.userId}/fitness-plan/${response.id}`);
  }

  function onMemberFitnessPlanAddError(apiErr) {
    setErrors(apiErr);
    setTopAlertMessage("Failed submitting");
    setTopAlertStatus("danger");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onMemberFitnessPlanAddDone() {
    setFetching(false);
  }

  ////
  //// Event Handlers
  ////
  const fetchList = (cur, limit, keywords, stat, sbv) => {
    setFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit);
    params.set("user_id", uid);

    if (sbv !== undefined && sbv !== null && sbv !== "") {
      const sortArray = sbv.split(",");
      params.set("sort_field", sortArray[0]);
      params.set("sort_order", sortArray[1]);
    }

    if (cur !== "") {
      params.set("cursor", cur);
    }

    if (keywords !== undefined && keywords !== null && keywords !== "") {
      params.set("search", keywords);
    }

    params.set("status", stat);

    getFitnessPlanListAPI(
      params,
      onFitnessPlanListSuccess,
      onFitnessPlanListError,
      onFitnessPlanListDone
    );
  };

  const onNextClicked = (e) => {
    let arr = [...previousCursors];
    arr.push(currentCursor);
    setPreviousCursors(arr);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = (e) => {
    let arr = [...previousCursors];
    const previousCursor = arr.pop();
    setPreviousCursors(arr);
    setCurrentCursor(previousCursor);
  };

  const onSearchButtonClick = (e) => {
    setActualSearchText(temporarySearchText);
  };

  const onSelectFitnessPlanForDeletion = (e, datum) => {
    setSelectedFitnessPlanForDeletion(datum);
    setShowModal(true);
  };

  const onDeselectFitnessPlanForDeletion = (e) => {
    setSelectedFitnessPlanForDeletion("");
    setShowModal(false);
  };

  const onDeleteConfirmButtonClick = (e) => {
    deleteFitnessPlanAPI(
      selectedFitnessPlanForDeletion.id,
      onFitnessPlanDeleteSuccess,
      onFitnessPlanDeleteError,
      onFitnessPlanDeleteDone
    );
    setSelectedFitnessPlanForDeletion("");
  };

  const onClearFilterClick = (e) => {
    setShowFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
    setSort("no,1");
    setStatus(0);
  };

  const handleNavigateToAccount = (e) => {
    e.preventDefault();
    navigate("/account", { state: { activeTabProp: "detail" } });
  };

  const onGenerateFitnessplan = (e) => {
    setFetching(true);
    setErrors({});
    setshowGenerateModal(false);

    const decamelizedData = { name: name, user_id: uid };
    postFitnessPlanCreateAPI(
      decamelizedData,
      onMemberFitnessPlanAddSuccess,
      onMemberFitnessPlanAddError,
      onMemberFitnessPlanAddDone
    );
  };
  ////
  //// Effects
  ////
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
      fetchList(currentCursor, pageSize, actualSearchText, status, sort);
    }

    return () => {
      mounted = false;
    };
  }, [currentCursor, pageSize, actualSearchText, status, sort]);

  ////
  //// Render
  ////
  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <>
      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showModal}
        onClose={onDeselectFitnessPlanForDeletion}
        header="Are you sure?"
      >
        <div className="space-y-4">
          <p className="text-gray-700">
            You are about to <span className="font-bold">delete</span> this Fitness Plan; 
            it will no longer appear on your dashboard. This action cannot be undone. 
            Are you sure you want to continue?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="btn-danger"
              onClick={onDeleteConfirmButtonClick}
            >
              Confirm Delete
            </button>
            <button
              className="btn-secondary"
              onClick={onDeselectFitnessPlanForDeletion}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* Generate Plan Modal */}
      <Modal
        isOpen={showGenerateModal}
        onClose={() => setshowGenerateModal(false)}
        header="Generate Fitness Plan"
      >
        <div className="space-y-4">
          <div className="flex items-start space-x-2 text-yellow-600">
            <FontAwesomeIcon icon={faExclamationCircle} className="mt-1" />
            <p>You are about to create a fitness plan based on your profile.</p>
          </div>

          <p className="text-gray-700">
            Plan will be based on your profile. If you wish to make any changes in your profile, please edit it
            <Link 
              onClick={handleNavigateToAccount}
              className="text-primary-600 hover:text-primary-700 ml-1"
            >
              here
              <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
            </Link>
          </p>

          <FormInputField
            label="Name:"
            name="name"
            placeholder="Fitness plan name"
            value={name}
            errorText={errors && errors.name}
            helpText="Give this fitness plan a name you can use to keep track for your own purposes. Ex: 'My Cardio-Plan'."
            onChange={(e) => setName(e.target.value)}
            isRequired={true}
          />

          <div className="flex justify-end space-x-3">
            <button
              className="btn-primary"
              onClick={onGenerateFitnessplan}
              disabled={!name}
              title={!name ? "Enter Name to submit" : ""}
            >
              Generate Plan
            </button>
            <button 
              className="btn-secondary"
              onClick={() => setshowGenerateModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <ListWithFilters
        title={
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faTrophy} className="text-primary-600" />
              <h1 className="text-2xl font-bold">Fitness Plans</h1>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => fetchList(currentCursor, pageSize, actualSearchText, status, sort)}
                className="btn-secondary text-sm"
              >
                <FontAwesomeIcon icon={faRefresh} className="mr-2" />
                Refresh
              </button>
              <button
                onClick={() => setShowFilter(!showFilter)}
                className="btn-primary text-sm"
              >
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                Filter
              </button>
              <button
                onClick={() => setshowGenerateModal(true)}
                className="btn-success text-sm"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                Request Plan
              </button>
            </div>
          </div>
        }
        filterContent={
          <FilterSidebar>
            <FilterSection title="Search">
              <FormInputFieldWithButton
                name="temporarySearchText"
                type="text"
                placeholder="Search by name"
                value={temporarySearchText}
                onChange={(e) => setTemporarySearchText(e.target.value)}
                buttonLabel={<FontAwesomeIcon icon={faSearch} />}
                onButtonClick={onSearchButtonClick}
              />
            </FilterSection>
          </FilterSidebar>
        }
        loading={isFetching}
        error={errors}
        showFilters={showFilter}
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPreviousClicked={onPreviousClicked}
        onNextClicked={onNextClicked}
        previousCursors={previousCursors}
        hasNextPage={!!nextCursor}
      >        {/* Main Content Area */}
      {listData?.results?.length > 0 ? (
        <div className="space-y-6">
          {/* Desktop View */}
          <div className="hidden md:block">
            <CardView
              items={listData.results}
              renderItem={(item) => (
                <div className="p-6 space-y-4">
                  {/* Card Header */}
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {item.name}
                      </h3>
                      <p className="text-sm text-gray-500">
                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                        Created: {new Date(item.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Link
                        to={`/admin/member/${uid}/fitness-plan/${item.id}`}
                        className="btn-secondary text-sm"
                      >
                        <FontAwesomeIcon icon={faEye} className="mr-2" />
                        View
                      </Link>
                      <button
                        onClick={(e) => onSelectFitnessPlanForDeletion(e, item)}
                        className="btn-danger text-sm"
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        Delete
                      </button>
                    </div>
                  </div>

                  {/* Card Content */}
                  <div className="space-y-2">
                    <div className="flex items-center text-sm text-gray-500">
                      <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                      <span>Status: {item.status}</span>
                    </div>
                    {item.description && (
                      <p className="text-sm text-gray-600 line-clamp-2">
                        {item.description}
                      </p>
                    )}
                  </div>

                  {/* Card Footer */}
                  <div className="pt-4 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                      <div className="text-sm text-gray-500">
                        Last updated: {new Date(item.updatedAt).toLocaleDateString()}
                      </div>
                      <div>
                        {item.isActive ? (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        ) : (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            Inactive
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              linkPrefix={`/admin/member/${uid}/fitness-plan`}
            />
          </div>

          {/* Mobile View */}
          <div className="md:hidden">
            <CardView
              items={listData.results}
              renderItem={(item) => (
                <div className="p-4 space-y-3">
                  {/* Mobile Card Header */}
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-base font-medium text-gray-900">
                        {item.name}
                      </h3>
                      <p className="text-xs text-gray-500">
                        <FontAwesomeIcon icon={faClock} className="mr-1" />
                        {new Date(item.createdAt).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex space-x-2">
                      <Link
                        to={`/admin/member/${uid}/fitness-plan/${item.id}`}
                        className="btn-secondary text-xs p-2"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Link>
                      <button
                        onClick={(e) => onSelectFitnessPlanForDeletion(e, item)}
                        className="btn-danger text-xs p-2"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </div>

                  {/* Mobile Card Content */}
                  <div className="space-y-1">
                    <div className="flex items-center text-xs text-gray-500">
                      <FontAwesomeIcon icon={faCalendar} className="mr-1" />
                      <span>Status: {item.status}</span>
                    </div>
                    {item.description && (
                      <p className="text-xs text-gray-600 line-clamp-1">
                        {item.description}
                      </p>
                    )}
                  </div>

                  {/* Mobile Card Footer */}
                  <div className="pt-2 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                      <div className="text-xs text-gray-500">
                        Updated: {new Date(item.updatedAt).toLocaleDateString()}
                      </div>
                      {item.isActive ? (
                        <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Active
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Inactive
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              linkPrefix={`/admin/member/${uid}/fitness-plan`}
            />
          </div>
        </div>
      ) : (
        <section className="text-center py-12">
          <FontAwesomeIcon icon={faTable} className="text-4xl text-gray-400" />
          <h3 className="mt-4 text-xl font-medium">No Fitness Plans</h3>
          <p className="mt-2 text-gray-600">
            You currently have no fitness plans.
            <button
              onClick={() => setshowGenerateModal(true)}
              className="text-primary-600 hover:text-primary-700 ml-1"
            >
              Click here
              <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
            </button>
            to get started requesting your first fitness plan from our team!
          </p>
        </section>
      )}

      {/* Footer Actions */}
      <div className="mt-6 flex flex-col sm:flex-row justify-between gap-4">
        <Link
          to="/admin/dashboard"
          className="btn-secondary"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Dashboard
        </Link>
        <button
          onClick={() => setshowGenerateModal(true)}
          className="btn-success"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Request Plan
        </button>
      </div>
    </ListWithFilters>
  </>
);
}

export default AdminFitnessPlanList;