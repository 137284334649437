import React from 'react';

export const LoadingState = ({ message = 'Loading...' }) => (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
            <div className="w-16 h-16 border-4 border-primary/30 border-t-primary 
                          rounded-full animate-spin mx-auto mb-4" />
            <p className="text-gray-600">{message}</p>
        </div>
    </div>
);