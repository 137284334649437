import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faClock,
	faLeaf,
	faPencil,
	faArrowLeft,
	faSpinner,
	faGauge,
	faIdCard,
	faArchive,
	faExclamationTriangle,
	faEye,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {
	getNutritionPlanDetailAPI,
	deleteNutritionPlanAPI,
} from "../../../API/NutritionPlan";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	NUTRITION_GOAL_STATUS_QUEUED,
	NUTRITION_GOAL_STATUS_ACTIVE,
	NUTRITION_GOAL_STATUS_ARCHIVED,
	NUTRITION_GOAL_STATUS_ERROR,
	NUTRITION_GOAL_STATUS_IN_PROGRESS,
	NUTRITION_GOAL_STATUS_PENDING,
} from "../../../Constants/App";

import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";
import FormErrorBox from "../../Reusable/FormErrorBox";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import NutritionPlanFormatter from "./NutritionPlanFormatter";

// Constants
const GENDER_OPTIONS = {
	1: "Male",
	2: "Female",
	3: "Other",
};

const BOOLEAN_OPTIONS = {
	1: "Yes",
	2: "No",
};

const PHYSICAL_ACTIVITY_OPTIONS = {
	1: "Sedentary",
	2: "Moderate",
	3: "Active",
};

const WORKOUT_INTENSITY_OPTIONS = {
	1: "Light",
	2: "Moderate",
	3: "Intense",
};

const GOALS_OPTIONS = {
	1: "Weight Loss",
	2: "Muscle Gain",
	3: "Maintenance",
};

// Reusable Components
const Card = ({children, className = ""}) => (
	<div
		className={`bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ${className}`}>
		{children}
	</div>
);

const StatusCard = ({
	status,
	icon,
	title,
	message,
	bgColor = "from-yellow-50 to-orange-50",
}) => (
	<div
		className={`w-full p-4 sm:p-6 bg-gradient-to-br ${bgColor} rounded-xl shadow-sm hover:shadow-md transition-all duration-300`}>
		<div className="flex flex-col sm:flex-row items-center sm:items-start gap-4">
			<div className="w-14 h-14 sm:w-12 sm:h-12 flex items-center justify-center rounded-full bg-white/90 shadow-inner">
				<FontAwesomeIcon
					className="text-2xl sm:text-xl text-primary"
					icon={icon}
				/>
			</div>
			<div className="text-center sm:text-left flex-1">
				<h3 className="text-xl sm:text-lg font-semibold text-gray-800 mb-2">
					{title}
				</h3>
				<p className="text-gray-600 text-sm sm:text-base leading-relaxed">
					{message}
				</p>
			</div>
		</div>
	</div>
);
const NutritionPlanHeader = ({datum, onDeleteClick}) => (
	<div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
		<h1 className="text-2xl sm:text-3xl font-bold text-primary flex items-center">
			<FontAwesomeIcon className="mr-2" icon={faLeaf} />
			Nutrition Plan
		</h1>
		<div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
			{datum?.status === NUTRITION_GOAL_STATUS_ACTIVE && (
				<Link
					to={`/nutrition-plan/${datum.id}/update`}
					className="w-full sm:w-auto inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
					<FontAwesomeIcon className="mr-2" icon={faPencil} />
					Edit & Re-request
				</Link>
			)}
			<button
				onClick={onDeleteClick}
				className="w-full sm:w-auto inline-flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
				<FontAwesomeIcon className="mr-2" icon={faTrash} />
				Delete
			</button>
		</div>
	</div>
);

const NavigationTabs = ({datumId}) => (
	<nav className="border-b border-gray-200 mb-6">
		<ul className="flex flex-wrap -mb-px">
			<li className="mr-6">
				<Link className="inline-block p-4 text-primary font-semibold border-b-2 border-primary">
					Detail
				</Link>
			</li>
			<li>
				<Link
					to={`/nutrition-plan/${datumId}/submission-form`}
					className="inline-block p-4 text-gray-600 hover:text-primary transition-colors duration-200">
					Submission Form
				</Link>
			</li>
		</ul>
	</nav>
);

const NutritionPlanContent = ({datum}) => {
	const formatDate = (dateString) => {
		if (!dateString || dateString === "0001-01-01T00:00:00Z") return "Not set";
		return new Date(dateString).toLocaleDateString();
	};

	const formatGoals = (goals) => {
		if (!goals || !Array.isArray(goals)) return "None";
		return goals.map((g) => GOALS_OPTIONS[g]).join(", ");
	};

	return (
		<div className="space-y-8">
			<section>
				<h2 className="text-lg font-semibold text-gray-800 mb-4">
					Basic Information
				</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 rounded-lg shadow-sm">
					<DataDisplayRowText label="Name" value={datum.name} />
					<DataDisplayRowText
						label="Created By"
						value={datum.createdByUserName}
					/>
					<DataDisplayRowText
						label="Created At"
						value={formatDate(datum.createdAt)}
					/>
					<DataDisplayRowText
						label="Modified By"
						value={datum.modifiedByUserName}
					/>
					<DataDisplayRowText
						label="Modified At"
						value={formatDate(datum.modifiedAt)}
					/>
					<DataDisplayRowText
						label="Organization"
						value={datum.organizationName}
					/>
				</div>
			</section>

			<section>
				<h2 className="text-lg font-semibold text-gray-800 mb-4">
					Physical Details
				</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 rounded-lg shadow-sm">
					<DataDisplayRowText
						label="Gender"
						value={GENDER_OPTIONS[datum.gender]}
					/>
					<DataDisplayRowText
						label="Birthday"
						value={formatDate(datum.birthday)}
					/>
					<DataDisplayRowText
						label="Height"
						value={`${datum.heightFeet}'${datum.heightInches}"`}
					/>
					<DataDisplayRowText
						label="Current Weight"
						value={`${datum.weight} lbs`}
					/>
					<DataDisplayRowText
						label="Ideal Weight"
						value={`${datum.idealWeight} lbs`}
					/>
					<DataDisplayRowText
						label="Physical Activity"
						value={PHYSICAL_ACTIVITY_OPTIONS[datum.physicalActivity]}
					/>
					<DataDisplayRowText
						label="Workout Intensity"
						value={WORKOUT_INTENSITY_OPTIONS[datum.workoutIntensity]}
					/>
				</div>
			</section>

			<section>
				<h2 className="text-lg font-semibold text-gray-800 mb-4">
					Dietary Preferences
				</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 rounded-lg shadow-sm">
					<DataDisplayRowText
						label="Has Allergies"
						value={BOOLEAN_OPTIONS[datum.hasAllergies]}
					/>
					{datum.allergies && (
						<DataDisplayRowText label="Allergies" value={datum.allergies} />
					)}
					<DataDisplayRowText label="Meals Per Day" value={datum.mealsPerDay} />
					<DataDisplayRowText
						label="Consumes Junk Food"
						value={BOOLEAN_OPTIONS[datum.consumeJunkFood]}
					/>
					<DataDisplayRowText
						label="Consumes Fruits and Vegetables"
						value={BOOLEAN_OPTIONS[datum.consumeFruitsAndVegetables]}
					/>
					<DataDisplayRowText
						label="Intermittent Fasting"
						value={BOOLEAN_OPTIONS[datum.hasIntermittentFasting]}
					/>
				</div>
			</section>

			<section>
				<h2 className="text-lg font-semibold text-gray-800 mb-4">
					Goals and Plan
				</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-white p-6 rounded-lg shadow-sm">
					<DataDisplayRowText label="Goals" value={formatGoals(datum.goals)} />
					<DataDisplayRowText label="Max Weeks" value={datum.maxWeeks} />
					<DataDisplayRowText
						label="Estimated Ready Date"
						value={formatDate(datum.estimatedReadyDate)}
					/>
					<DataDisplayRowText
						label="Was Processed"
						value={datum.was_processed ? "Yes" : "No"}
					/>
					{datum.instructions && (
						<DataDisplayRowText
							label="Instructions"
							value={datum.instructions}
						/>
					)}
					{datum.error && (
						<DataDisplayRowText
							label="Error"
							value={datum.error}
							className="text-red-600"
						/>
					)}
				</div>
			</section>

			{datum.diet_plan && (
				<section>
					<h2 className="text-lg font-semibold text-gray-800 mb-4">
						Diet Plan
					</h2>
					<div className="bg-white p-6 rounded-lg shadow-sm">
						<pre className="whitespace-pre-wrap">
							{JSON.stringify(datum.dietPlan, null, 2)}
						</pre>
					</div>
				</section>
			)}
		</div>
	);
};

function MemberNutritionPlanDetail() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState(null);
	const [
		selectedNutritionPlanForDeletion,
		setSelectedNutritionPlanForDeletion,
	] = useState(null);

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/dashboard"},
		{label: "Nutrition Plans", icon: faLeaf, link: "/nutrition-plans"},
		{label: "Detail", icon: faEye},
	];

	const onNutritionPlanDetailSuccess = (response) => {
		setDatum(response);
	};

	const onNutritionPlanDetailError = (apiErr) => {
		setErrors(apiErr);
		Scroll.animateScroll.scrollToTop();
	};

	const onNutritionPlanDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Nutrition plan deleted");
		setTimeout(() => setTopAlertMessage(""), 2000);
		setForceURL("/nutrition-plans");
	};

	const onDeleteConfirmButtonClick = () => {
		deleteNutritionPlanAPI(
			selectedNutritionPlanForDeletion.id,
			onNutritionPlanDeleteSuccess,
			(apiErr) => {
				setErrors(apiErr);
				setTopAlertStatus("danger");
				setTopAlertMessage("Failed deleting");
				setTimeout(() => setTopAlertMessage(""), 2000);
			},
			() => {
				setFetching(false);
				setSelectedNutritionPlanForDeletion(null);
			}
		);
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			setFetching(true);
			getNutritionPlanDetailAPI(
				id,
				onNutritionPlanDetailSuccess,
				onNutritionPlanDetailError,
				() => setFetching(false)
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	if (forceURL) return <Navigate to={forceURL} />;

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50 px-4 sm:px-6 py-4 sm:py-6">
				<div className="mx-auto">
					<Card className="overflow-hidden">
						<div className="p-4 sm:p-6">
							{datum && (
								<NutritionPlanHeader
									datum={datum}
									onDeleteClick={() =>
										setSelectedNutritionPlanForDeletion(datum)
									}
								/>
							)}

							<FormErrorBox errors={errors} />

							{isFetching ? (
								<PageLoadingContent displayMessage="Loading your nutrition plan..." />
							) : (
								datum && (
									<div className="space-y-6">
										{/* Pending Status */}
										{datum.status === NUTRITION_GOAL_STATUS_PENDING && (
											<StatusCard
												icon={faClock}
												title="Nutrition Plan Pending"
												message="You have successfully submitted this nutrition plan to our team. The estimated time until our team completes your nutrition plan will take about 1-2 days. Please check back later."
												bgColor="from-yellow-50 to-orange-50"
											/>
										)}

										{/* Queued Status */}
										{datum.status === NUTRITION_GOAL_STATUS_QUEUED && (
											<StatusCard
												icon={faClock}
												title="Nutrition Plan Not Ready"
												message="Your nutrition plan has been queued for processing. Our team will start working on it soon."
												bgColor="from-blue-50 to-indigo-50"
											/>
										)}

										{/* In Progress Status */}
										{datum.status === NUTRITION_GOAL_STATUS_IN_PROGRESS && (
											<StatusCard
												icon={faSpinner}
												title="Nutrition Plan In Progress"
												message="Your nutrition plan is currently being prepared. Please check back soon for updates."
												bgColor="from-blue-50 to-cyan-50"
											/>
										)}

										{/* Error Status */}
										{datum.status === NUTRITION_GOAL_STATUS_ERROR && (
											<StatusCard
												icon={faExclamationTriangle}
												title="Nutrition Plan Problem"
												message="There appears to be a problem with your fitness plan submission. We are investigating and working through the issue. Please check back in another day."
												bgColor="from-red-50 to-pink-50"
											/>
										)}

										{/* Archived Status */}
										{datum.status === NUTRITION_GOAL_STATUS_ARCHIVED && (
											<StatusCard
												icon={faArchive}
												title="Nutrition Plan Archived"
												message="This nutrition plan has been archived. It is no longer active but can still be viewed."
												bgColor="from-gray-50 to-gray-100"
											/>
										)}

										{/* Active Status Content */}
										{datum.status === NUTRITION_GOAL_STATUS_ACTIVE && (
											<>
												<NavigationTabs datumId={datum.id} />
												{/* <NutritionPlanContent datum={datum} /> */}
												<NutritionPlanFormatter data={datum.dietPlan} />
											</>
										)}

										{/* Back Button */}
										<div className="flex flex-col sm:flex-row justify-between items-center gap-4 pt-6">
											<Link
												to="/nutrition-plans"
												className="w-full sm:w-auto inline-flex items-center justify-center bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2.5 rounded-lg transition-all duration-200">
												<FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
												Back to nutrition plans
											</Link>
										</div>
									</div>
								)
							)}
						</div>
					</Card>
				</div>
			</div>

			<Modal
				isOpen={selectedNutritionPlanForDeletion !== null}
				onClose={() => setSelectedNutritionPlanForDeletion(null)}
				header="Are you sure?">
				<div className="p-4 sm:p-6">
					<p className="text-gray-700 mb-6">
						You are about to delete this nutrition plan and all the data
						associated with it. This action cannot be undone. Are you sure you
						would like to continue?
					</p>
					<div className="flex flex-col sm:flex-row justify-end gap-2">
						<button
							onClick={() => setSelectedNutritionPlanForDeletion(null)}
							className="w-full sm:w-auto px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded-lg transition-all duration-200">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							className="w-full sm:w-auto px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-200">
							Confirm Delete
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default MemberNutritionPlanDetail;
