import React, {useState, useEffect} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faPencil,
	faArrowLeft,
	faGauge,
	faEye,
	faVideoCamera,
	faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import Scroll from "react-scroll";

import Layout from "../../../Menu/Layout";
import {ListHeader} from "../../../Reusable/AdminList";
import FormErrorBox from "../../../Reusable/FormErrorBox";
import FormAttachmentField from "../../../Reusable/FormAttachmentField";
import FormInputField from "../../../Reusable/FormInputField";
import FormTextareaField from "../../../Reusable/FormTextareaField";
import FormRadioField from "../../../Reusable/FormRadioField";
import FormSelectField from "../../../Reusable/FormSelectField";
import FormCheckboxField from "../../../Reusable/FormCheckboxField";
import FormSelectFieldForVideoCategory from "../../../Reusable/FormSelectFieldForVideoCategory";
import FormSelectFieldForOffer from "../../../Reusable/FormSelectFieldForOffer";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";

import {
	putVideoContentUpdateAPI,
	getVideoContentDetailAPI,
} from "../../../../API/VideoContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../../AppState";
import {
	VIDEO_CONTENT_TYPE_WITH_EMPTY_OPTIONS,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
} from "../../../../Constants/FieldOptions";
import {
	VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE,
	VIDEO_CONTENT_VIDEO_TYPE_VIMEO,
	VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL,
} from "../../../../Constants/App";
import VideoPlayer, {VIDEO_LAYOUTS} from "../../../Reusable/VideoPlayer";

function AdminVideoContentUpdate() {
	const {vcid, vconid} = useParams();

	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [forceURL, setForceURL] = useState("");
	const [showFilter, setShowFilter] = useState(false);

	// Form states
	const [thumbnailType, setThumbnailType] = useState(
		VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [existingThumbnailURL, setExistingThumbnailURL] = useState("");
	const [existingThumbnailAttachmentID, setExistingThumbnailAttachmentID] =
		useState("");
	const [existingVideoAttachmentID, setExistingVideoAttachmentID] =
		useState("");
	const [existingVideoURL, setExistingVideoURL] = useState("");
	const [videoType, setVideoType] = useState(
		VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentName, setVideoAttachmentName] = useState("");
	const [type, setType] = useState(0);
	const [videoCategoryID, setVideoCategoryID] = useState("");
	const [name, setName] = useState("");
	const [no, setNo] = useState("");
	const [description, setDescription] = useState("");
	const [authorName, setAuthorName] = useState("");
	const [authorURL, setAuthorURL] = useState("");
	const [duration, setDuration] = useState("");
	const [isVideoCategoryOther, setIsVideoCategoryOther] = useState(false);
	const [hasMonetization, setHasMonetization] = useState(false);
	const [offerID, setOfferID] = useState("");
	const [isOfferOther, setIsOfferOther] = useState(false);
	const [hasTimedLock, setHasTimedLock] = useState(false);
	const [timedLock, setTimedLock] = useState("");
	const [isVideoModified, setIsVideoModified] = useState(false);
	const [status, setStatus] = useState(1);

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/admin/dashboard"},
		{
			label: "Video Collections",
			icon: faVideo,
			link: "/admin/video-collections",
		},
		{
			label: "Detail(Overview)",
			icon: faEye,
			link: `/admin/video-collection/${vcid}`,
		},
		{
			label: name || "Loading...",
			icon: faEye,
			link: `/admin/video-collection/${vcid}/video-content/${vconid}`,
		},
		{label: "Edit", icon: faPencil},
	];

	useEffect(() => {
		fetchVideoContent();
	}, [vconid]);

	const fetchVideoContent = () => {
		setIsLoading(true);
		getVideoContentDetailAPI(vconid, onFetchSuccess, onFetchError, onFetchDone);
	};

	const onFetchSuccess = (response) => {
			// Thumbnail handling
			setThumbnailType(response.thumbnailType);
			setThumbnailAttachmentID(
				response.thumbnailAttachmentId === "000000000000000000000000"
					? ""
					: response.thumbnailAttachmentId
			);
			setExistingThumbnailAttachmentID(
				response.thumbnailAttachmentId === "000000000000000000000000"
					? ""
					: response.thumbnailAttachmentId
			);
			setThumbnailAttachmentName(response.thumbnailAttachmentFilename);
			setExistingThumbnailURL(response.thumbnailObjectUrl);
			setThumbnailURL(response.thumbnailUrl || "");
		
			// Video handling
			setVideoType(response.videoType);
			setVideoAttachmentID(
				response.videoAttachmentId === "000000000000000000000000"
					? ""
					: response.videoAttachmentId
			);
			setExistingVideoAttachmentID(
				response.videoAttachmentId === "000000000000000000000000"
					? ""
					: response.videoAttachmentId
			);
			setVideoAttachmentName(response.videoAttachmentFilename);
			setExistingVideoURL(response.videoObjectUrl);
			setVideoURL(response.videoUrl || "");
		
			// Reset modification flags
			setIsVideoModified(false);
		
			// Basic info
			setVideoCategoryID(response.categoryId);
			setName(response.name);
			setNo(response.no);
			setDescription(response.description);
			setAuthorName(response.authorName);
			setAuthorURL(response.authorUrl);
			setType(response.type || 2);
			setDuration(response.duration);
			setStatus(response.status);
		}
		

	const onFetchError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to load video content");
		setTopAlertStatus("error");
		Scroll.animateScroll.scrollToTop();
	};

	const onFetchDone = () => {
		setIsLoading(false);
	};

	const handleVideoURLChange = (e) => {
		const newValue = e.target.value;
		setVideoURL(newValue);
		setIsVideoModified(true);
	};
	
	// Update the submit handler
	const onSubmitClick = (e) => {
		e.preventDefault();
		setFetching(true);
		setErrors({});
	
		const payload = {
			id: vconid,
			video_type: parseInt(videoType),
			thumbnail_type: parseInt(thumbnailType),
			name: name,
			description: description,
			status: 1,
			collection_id: vcid,
			no: parseInt(no),
			description: description,
			author_name: authorName,
			author_url: authorURL,
			category_id: videoCategoryID,
			has_monetization: hasMonetization,
			offer_id: hasMonetization ? offerID : "",
			has_timed_lock: hasTimedLock,
			timed_lock: hasTimedLock ? timedLock : "",
		};
	
		// Handle video based on type
		if (videoType === VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (videoAttachmentID !== existingVideoAttachmentID) {
				payload.video_upload = videoAttachmentID;
			}
		} else {
			// For YouTube/Vimeo, always include video_url if it exists
			if (videoURL) {
				payload.video_url = videoURL;
			}
		}
	
		// Handle thumbnail based on type
		if (thumbnailType === VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (thumbnailAttachmentID !== existingThumbnailAttachmentID) {
				payload.thumbnail_upload = thumbnailAttachmentID;        // Set new S3 ID
				payload.thumbnail_url = "";                              // Clear URL
			} else {
				payload.thumbnail_upload = existingThumbnailAttachmentID; // Keep existing S3 ID
				payload.thumbnail_url = "";                              // Clear URL
			}
		} 
		// For External URL Type
		else {
			if (thumbnailURL !== existingThumbnailURL) {
				payload.thumbnail_url = thumbnailURL;                    // Set new URL
				payload.thumbnail_attachment_id = "";                    // Clear S3 ID
				payload.thumbnail_attachment_filename = "";              // Clear S3 filename
				payload.thumbnail_upload = "";                          // Clear S3 upload
			} else {
				payload.thumbnail_url = existingThumbnailURL;           // Keep existing URL
				payload.thumbnail_attachment_id = "";                    // Clear S3 ID
				payload.thumbnail_attachment_filename = "";              // Clear S3 filename
				payload.thumbnail_upload = "";                          // Clear S3 upload
			}
		}
	
		// Add duration if it exists
		if (duration) {
			payload.duration = duration;
		}
	
		// Add type if it exists
		if (type) {
			payload.type = type;
		}
	
		console.log("Submitting payload:", payload); // For debugging
	
		putVideoContentUpdateAPI(
			payload,
			onSubmitSuccess,
			onSubmitError,
			onSubmitDone
		);
	};

	const onSubmitSuccess = (response) => {
		setTopAlertMessage("Video content updated successfully");
		setTopAlertStatus("success");
		setForceURL(`/admin/video-collection/${vcid}/video-content/${vconid}`);
	};

	const onSubmitError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to update video content");
		setTopAlertStatus("error");
		Scroll.animateScroll.scrollToTop();
	};

	const onSubmitDone = () => {
		setFetching(false);
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="p-6">
				<ListHeader
					title="Edit Video Content"
					icon={faPencil}
					onRefresh={fetchVideoContent}
					onToggleFilter={() => setShowFilter(!showFilter)}
				/>

				<FormErrorBox errors={errors} />

				{isLoading ? (
					<div className="flex items-center justify-center p-12">
						<FontAwesomeIcon
							icon={faSpinner}
							spin
							className="text-primary text-3xl"
						/>
						<span className="ml-3 text-gray-600">Loading video content...</span>
					</div>
				) : (
					<form onSubmit={onSubmitClick} className="space-y-6">
						{/* Thumbnail Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faImage} className="mr-2" />
								Thumbnail
							</h2>
							<div className="space-y-4">
								<FormRadioField
									label="Thumbnail Type"
									name="thumbnailType"
									value={thumbnailType}
									opt1Value={
										VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
									}
									opt1Label="File Upload"
									opt2Value={VIDEO_CONTENT_THUMBNAIL_TYPE_EXTERNAL_URL}
									opt2Label="External URL"
									onChange={(e) => setThumbnailType(parseInt(e.target.value))}
									errorText={errors?.thumbnailType}
								/>

								{thumbnailType ===
								VIDEO_CONTENT_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<div className="space-y-2">
										<FormAttachmentField
											label="Thumbnail Image"
											name="thumbnail"
											placeholder="Choose an image"
											errorText={errors && errors.thumbnail}
											attachmentID={thumbnailAttachmentID}
											setAttachmentID={setThumbnailAttachmentID}
											attachmentFilename={thumbnailAttachmentName}
											setAttachmentFilename={setThumbnailAttachmentName}
											helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
											validationText="Maximum size: 10MB"
											disabled={false}
											acceptedFileTypes="image/*"
										/>
										{existingThumbnailURL && !thumbnailURL && (
											<div className="mt-2">
												<p className="text-sm text-gray-500 mb-2">
													Current Thumbnail:
												</p>
												<img
													src={existingThumbnailURL}
													alt="Current thumbnail"
													className="w-48 h-auto rounded-lg border border-gray-200"
												/>
											</div>
										)}
									</div>
								) : (
									<FormInputField
										label="Thumbnail URL"
										name="thumbnailURL"
										type="url"
										placeholder="Enter thumbnail URL"
										value={thumbnailURL}
										errorText={errors?.thumbnailUrl}
										onChange={(e) => {
											setThumbnailURL(e.target.value);
											setThumbnailAttachmentID("");
											setThumbnailAttachmentName("");
										}}
										helpText="Enter the URL of the thumbnail image"
									/>
								)}
							</div>
						</div>

						{/* Video Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faVideo} className="mr-2" />
								Video
							</h2>
							<div className="space-y-4">
								<FormRadioField
									label="Video Type"
									name="videoType"
									value={videoType}
									opt1Value={VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
									opt1Label="File Upload"
									opt2Value={VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE}
									opt2Label="YouTube URL"
									opt3Value={VIDEO_CONTENT_VIDEO_TYPE_VIMEO}
									opt3Label="Vimeo URL"
									onChange={(e) => setVideoType(parseInt(e.target.value))}
									errorText={errors?.videoType}
								/>

								{videoType ===
								VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<div className="space-y-2">
										<FormAttachmentField
											label="Video File"
											name="video"
											placeholder="Choose a video file"
											errorText={errors && errors.video}
											attachmentID={videoAttachmentID}
											setAttachmentID={setVideoAttachmentID}
											attachmentFilename={videoAttachmentName}
											setAttachmentFilename={setVideoAttachmentName}
											helpText="Upload a video file (MP4, MOV, etc.)"
											disabled={false}
										/>
										{/* {existingVideoURL && !videoAttachment && (
											<div className="mt-2">
												<p className="text-sm text-gray-500 mb-2">
													Current Video:
												</p>
												<video
													src={existingVideoURL}
													controls
													className="w-full max-w-lg rounded-lg border border-gray-200"
												/>
											</div>
										)} */}
									</div>
								) : (
									<FormInputField
    label="Video URL"
    name="videoURL"
    type="url"
    placeholder={`Enter ${
        videoType === VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE
            ? "YouTube"
            : "Vimeo"
    } URL`}
    value={videoURL}
    errorText={errors?.videoUrl}
    onChange={handleVideoURLChange}
    helpText={`Enter the ${
        videoType === VIDEO_CONTENT_VIDEO_TYPE_YOUTUBE
            ? "YouTube"
            : "Vimeo"
    } video URL`}
    isRequired={videoType !== VIDEO_CONTENT_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE}
/>
								)}
							</div>
						</div>

						{/* Details Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faVideoCamera} className="mr-2" />
								Video Details
							</h2>
							<div className="space-y-4">
								<FormInputField
									label="Name"
									name="name"
									type="text"
									placeholder="Enter video name"
									value={name}
									errorText={errors?.name}
									onChange={(e) => setName(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Number"
									name="no"
									type="text"
									placeholder="Enter video number"
									value={no}
									errorText={errors?.no}
									onChange={(e) => setNo(e.target.value)}
									isRequired={true}
									helpText="Used for ordering videos in the collection"
								/>

								<FormTextareaField
									label="Description"
									name="description"
									placeholder="Enter video description"
									value={description}
									errorText={errors?.description}
									onChange={(e) => setDescription(e.target.value)}
									isRequired={true}
									rows={4}
								/>

								<FormInputField
									label="Author Name"
									name="authorName"
									type="text"
									placeholder="Enter author name"
									value={authorName}
									errorText={errors?.authorName}
									onChange={(e) => setAuthorName(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Author URL"
									name="authorURL"
									type="url"
									placeholder="Enter author URL"
									value={authorURL}
									errorText={errors?.authorURL}
									onChange={(e) => setAuthorURL(e.target.value)}
									isRequired={true}
								/>

								<FormInputField
									label="Duration"
									name="duration"
									type="text"
									placeholder="Ex: 10:30"
									value={duration}
									errorText={errors?.duration}
									onChange={(e) => setDuration(e.target.value)}
									isRequired={true}
									helpText="Format: MM:SS"
								/>

								<FormSelectFieldForVideoCategory
									label="Category"
									videoCategoryID={videoCategoryID}
									setVideoCategoryID={setVideoCategoryID}
									isVideoCategoryOther={isVideoCategoryOther}
									setIsVideoCategoryOther={setIsVideoCategoryOther}
									errorText={errors?.categoryId}
									isRequired={true}
								/>
							</div>
						</div>

						{/* Monetization Section */}
						<div className="bg-white rounded-lg shadow-sm p-6 border border-gray-200">
							<h2 className="text-lg font-semibold mb-4 flex items-center text-gray-900">
								<FontAwesomeIcon icon={faMoneyBillWave} className="mr-2" />
								Monetization
							</h2>
							<div className="space-y-4">
								<FormCheckboxField
									label="Enable Monetization"
									name="hasMonetization"
									checked={hasMonetization}
									errorText={errors?.hasMonetization}
									onChange={() => setHasMonetization(!hasMonetization)}
									helpText="Enable Monetization to restrict access to this video based on user purchases."
								/>

								{hasMonetization && (
									<>
										<FormSelectFieldForOffer
											label="Offer"
											offerID={offerID}
											setOfferID={setOfferID}
											isOfferOther={isOfferOther}
											setIsOfferOther={setIsOfferOther}
											errorText={errors?.offerId}
											isRequired={true}
											helpText="Select the offer that will grant access to this video"
										/>

										<FormCheckboxField
											label="Enable Timed Lock"
											name="hasTimedLock"
											checked={hasTimedLock}
											errorText={errors?.hasTimedLock}
											onChange={() => setHasTimedLock(!hasTimedLock)}
											helpText="Enable artificial time lock on this video for the user."
										/>

										{hasTimedLock && (
											<FormSelectField
												label="Timed Lock Duration"
												name="timedLock"
												selectedValue={timedLock}
												options={TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS}
												onChange={(e) => setTimedLock(e.target.value)}
												errorText={errors?.timedLock}
												helpText="The duration will lock this video for the user until the duration has elapsed"
												isRequired={true}
											/>
										)}
									</>
								)}
							</div>
						</div>

						{/* Action Buttons */}
						<div className="flex flex-col sm:flex-row justify-between gap-4">
							<Link
								to={`/admin/video-collection/${vcid}/video-content/${vconid}`}
								className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to Detail
							</Link>
							<button
								type="submit"
								className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
								disabled={isFetching}>
								<FontAwesomeIcon icon={faPencil} className="mr-2" />
								{isFetching ? "Saving..." : "Save Changes"}
							</button>
						</div>
					</form>
				)}
			</div>
		</Layout>
	);
}

export default AdminVideoContentUpdate;
