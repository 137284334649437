import React from 'react';

export const ErrorState = ({ error, message = 'Error Loading Content' }) => (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="max-w-md w-full mx-auto p-6">
            <div className="bg-red-50 text-red-600 p-4 rounded-xl text-center">
                <h2 className="text-lg font-semibold mb-2">{message}</h2>
                <p>{error}</p>
            </div>
        </div>
    </div>
);