import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const VARIANTS = {
  primary: "bg-main text-lightShade hover:bg-darkOrangeShade active:bg-mutedOrange focus:ring-main",
  secondary: "bg-gray-600 text-white hover:bg-gray-700 focus:ring-gray-500",
  outline: "border border-main text-main hover:bg-main hover:text-white focus:ring-main",
  danger: "bg-darkRedLink text-white hover:bg-red-700 focus:ring-red-500",
  back: "bg-lightShade text-darkShade hover:bg-backgroundLight group",
  success: "bg-green-500 text-white hover:bg-green-600 focus:ring-green-500", 
  info: "bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-500", 
  warning: "bg-yellow-500 text-black hover:bg-yellow-600 focus:ring-yellow-500",
  disabled: "bg-inactiveBg text-gray-500 cursor-not-allowed"
};

const SIZES = {
  small: "px-3 py-1.5 text-sm",
  default: "px-4 py-2",
  large: "px-6 py-3 text-lg"
};

const Button = ({
  text,
  icon,
  className = "",
  type = "button",
  onClick,
  disabled = false,
  isLoading = false,
  name = "",
  variant = "primary",
  size = "default",
  fullWidth = false,
  as: Component = "button",
  to,
  ...props
}) => {
  // Base classes
  const baseClasses = [
    "inline-flex items-center justify-center", // Changed this line
    "rounded-lg",
    "transition-all duration-DEFAULT",
    "focus:outline-none focus:ring-2 focus:ring-offset-2",
    disabled ? VARIANTS.disabled : VARIANTS[variant],
    SIZES[size],
    fullWidth ? 'w-full' : '',
    className
  ].filter(Boolean).join(' ');

  const renderContent = () => (
    <span className="flex items-center justify-center">
      {isLoading ? (
        <>
          <FontAwesomeIcon 
            icon={faSpinner} 
            className="animate-spin mr-2" 
          />
          <span>Processing...</span>
        </>
      ) : (
        <>
          {/* Special case for back variant - icon goes before text */}
          {variant === 'back' && icon && (
            <FontAwesomeIcon
              icon={icon}
              className="mr-2 group-hover:-translate-x-1 transition-transform duration-DEFAULT"
            />
          )}
          <span>{text}</span>
          {/* For all other variants - icon goes after text */}
          {variant !== 'back' && icon && (
            <FontAwesomeIcon 
              icon={icon} 
              className={`ml-2 ${disabled ? 'opacity-50' : ''}`} 
            />
          )}
        </>
      )}
    </span>
  );

  const commonProps = {
    className: baseClasses,
    disabled: disabled || isLoading,
    onClick,
    name,
    ...props
  };

  if (to) {
    return (
      <Link to={to} {...commonProps}>
        {renderContent()}
      </Link>
    );
  }

  return (
    <Component
      type={type}
      {...commonProps}
    >
      {renderContent()}
    </Component>
  );
};

export default Button;