import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

import {getMemberDetailAPI} from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Navigation from "./Navigation";

function AdminMemberProfileDetail() {
	const {bid, id} = useParams();

	// Local state
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [memberData, setMemberData] = useState({});
	const [questionnaireAnswers, setQuestionnaireAnswers] = useState([]);

	////
	//// API Callbacks
	////

	function onMemberDetailSuccess(response) {
		setMemberData(response);
		if (response?.onboardingAnswers?.length > 0) {
			setQuestionnaireAnswers(response.onboardingAnswers);
		}
		setError(null);
	}

	function onMemberDetailError(apiErr) {
		setError(
			apiErr.message || "An error occurred while loading member details"
		);
		console.error("Member Detail API Error:", apiErr);
	}

	function onMemberDetailDone() {
		setIsLoading(false);
	}

	////
	//// Lifecycle
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setIsLoading(true);
			setError(null);
			getMemberDetailAPI(
				id,
				onMemberDetailSuccess,
				onMemberDetailError,
				onMemberDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Render Helpers
	////

	const renderPersonalInfo = () => (
		<div className="bg-white my-6">
			<h3 className="text-lg font-medium text-gray-900 mb-4">
				Personal Information
			</h3>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
				<div>
					<label className="block text-sm font-medium text-gray-700">
						Name
					</label>
					<p className="mt-1 text-gray-900">{`${memberData.firstName || ""} ${
						memberData.lastName || ""
					}`}</p>
				</div>
				<div>
					<label className="block text-sm font-medium text-gray-700">
						Email
					</label>
					<p className="mt-1 text-gray-900">{memberData.email || "N/A"}</p>
				</div>
				<div>
					<label className="block text-sm font-medium text-gray-700">
						Phone
					</label>
					<p className="mt-1 text-gray-900">{memberData.phone || "N/A"}</p>
				</div>
			</div>
		</div>
	);

	const renderAddress = () => (
		<div className="bg-white my-6">
			<h3 className="text-lg font-medium text-gray-900 mb-4">Address</h3>
			<div className="space-y-2">
				<p className="text-gray-900">{memberData.addressLine1}</p>
				{memberData.addressLine2 && (
					<p className="text-gray-900">{memberData.addressLine2}</p>
				)}
				<p className="text-gray-900">
					{[memberData.city, memberData.region, memberData.postalCode]
						.filter(Boolean)
						.join(", ")}
				</p>
				<p className="text-gray-900">{memberData.country}</p>
			</div>
		</div>
	);

	const renderQuestionnaire = () => (
		<div className="bg-white my-6">
			<h3 className="text-lg font-medium text-gray-900 mb-4">
				Questionnaire Responses
			</h3>
			{questionnaireAnswers.length === 0 ? (
				<div className="text-center py-8">
					<FontAwesomeIcon
						icon={faQuestionCircle}
						className="text-4xl text-gray-400 mb-4"
					/>
					<p className="text-gray-500">No questionnaire responses available</p>
				</div>
			) : (
				<div className="space-y-6 bg-gray-50">
					{questionnaireAnswers.map((answer, index) => (
						<div
							key={answer.questionId}
							className="border-b border-gray-200 pb-4 last:border-0">
							<p className="font-medium text-gray-900 mb-2">
								{index + 1}. {answer.questionText}
							</p>
							<p className="text-gray-700">{answer.answers.join(", ")}</p>
						</div>
					))}
				</div>
			)}
		</div>
	);

	////
	//// Main Render
	////

	if (isLoading) {
		return (
			<div className="container mx-auto px-4 py-6">
				<PageLoadingContent displayMessage={"Loading member profile..."} />
			</div>
		);
	}

	if (error) {
		return (
			<div className="mx-auto px-4 py-6">
				<div className="p-4 text-red-600 bg-red-50 rounded-lg">
					Error loading member profile: {error}
				</div>
			</div>
		);
	}

	return (
		<div className="space-y-6">
			{/* Member Profile Section */}
			<section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
				<h3 className="text-lg font-bold text-textDark flex items-center">
					<FontAwesomeIcon icon={faUser} className="mr-3 text-primaryColor" />
					Member Profile
				</h3>

				<div className="mt-6">
					{renderPersonalInfo()}
					{renderAddress()}
					{renderQuestionnaire()}
				</div>
			</section>
		</div>
	);
}

export default AdminMemberProfileDetail;
