import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {
	faFacebookF,
	faInstagram,
	faTwitter,
	faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import {faPhone, faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
	const [currentDateTime, setCurrentDateTime] = useState("");
	const [userLocation, setUserLocation] = useState("Ontario");

	const socialLinks = [
		{
			icon: faFacebookF,
			url: "https://www.facebook.com/adrenalinemma",
			label: "Facebook",
		},
		{
			icon: faInstagram,
			url: "https://www.instagram.com/adrenalinemmahome/",
			label: "Instagram",
		},
		// { icon: faTwitter, url: "https://x.com/atcmma", label: "Twitter" },
		// { icon: faTiktok, url: "#", label: "TikTok" },
	];

	useEffect(() => {
		const updateDateTime = () => {
			const now = new Date();
			const options = {
				year: "numeric",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
				hour12: true,
			};
			const formattedDateTime = now.toLocaleString("en-US", options);
			setCurrentDateTime(formattedDateTime);
		};

		const updateUserLocation = () => {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						const {latitude, longitude} = position.coords;
						fetch(
							`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
						)
							.then((response) => response.json())
							.then((data) => {
								const locationName = data.address?.state || "Ontario";
								setUserLocation(locationName);
							})
							.catch(() => setUserLocation("Ontario"));
					},
					() => setUserLocation("Ontario")
				);
			} else {
				setUserLocation("Ontario");
			}
		};

		updateDateTime();
		updateUserLocation();
		const interval = setInterval(updateDateTime, 60000);

		return () => clearInterval(interval);
	}, []);

	return (
		<footer
			className="bg-gradient-to-b from-darkShade via-darkShade/98 to-darkShade/95 text-lightShade py-16 
      animate-fadeIn relative overflow-hidden">
			{/* Background Pattern */}
			<div className="absolute inset-0 bg-[url('https://plus.unsplash.com/premium_photo-1685656440548-d8cad874d5d8?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cGF0dGVybiUyMGJhY2tncm91bmR8ZW58MHx8MHx8fDA%3D')] opacity-5"></div>

			<div className="mx-auto px-4 sm:px-6 lg:px-8 relative">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-16 pb-16 border-b border-lightShade/10">
					{/* Left Column */}
					<div className="space-y-8">
						<div className="flex items-center space-x-4 group">
							<img
								src="/logo.svg"
								alt="Adrenaline MMA"
								className="h-16 w-auto transition-transform duration-500 group-hover:scale-105"
							/>
							<div className="h-12 w-px bg-gradient-to-b from-transparent via-lightShade/10 to-transparent"></div>
							<div>
								<p className="text-md text-main leading-tight">
									Adrenaline MMA
								</p>
								<p className="text-sm text-lightShade/60 leading-tight">
									The Ultimate
								</p>
								<p className="text-sm font-bold bg-gradient-to-r from-lightShade to-lightShade/80 text-transparent bg-clip-text">
									Training Experience
								</p>
							</div>
						</div>

						<div className="flex space-x-4">
							{socialLinks.map((social, index) => (
								<a
									key={index}
									href={social.url}
									className="group relative w-12 h-12 rounded-xl bg-darkShade/5 
                 hover:bg-primary hover:scale-110 transition-all duration-300 ease-out
                 flex items-center justify-center animate-fade-in
                 before:absolute before:inset-0 before:rounded-xl 
                 before:border before:border-lightShade/10
                 before:transition-transform before:duration-300 
                 hover:before:scale-90 before:opacity-0
                 hover:before:opacity-100 hover:shadow-lg
                 hover:animate-float"
									target="_blank"
									rel="noopener noreferrer"
									aria-label={social.label}>
									<FontAwesomeIcon
										icon={social.icon}
										className="text-xl text-gray-600 group-hover:text-lightShade 
                   transition-colors duration-300 transform 
                   group-hover:scale-110"
									/>
									<span
										className="absolute -bottom-6 left-1/2 -translate-x-1/2 
                   opacity-0 group-hover:opacity-100 
                   text-xs text-gray-400 transition-all duration-300 
                   whitespace-nowrap bg-darkShade/80 px-2 py-1 
                   rounded-md backdrop-blur-xs animate-fade-in">
										{social.label}
									</span>
								</a>
							))}
						</div>

						<div
							className="bg-gradient-to-br from-lightShade/10 to-lightShade/5 p-6 rounded-2xl backdrop-blur-sm
              border border-lightShade/10 hover:border-lightShade/20 transition-colors duration-300">
							<p className="font-medium text-lightShade mb-3 flex items-center">
								<span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse"></span>
								<span className="bg-gradient-to-r from-main to-main/80 text-transparent bg-clip-text">
									Live Updates
								</span>
							</p>
							<p className="text-sm mb-2 text-lightShade">{currentDateTime}</p>
							<p className="text-sm mb-1 text-lightShade">
								Location: {userLocation}
							</p>
							<cite className="text-xs text-lightShade/40 block mt-3 border-t border-lightShade/10 pt-3">
								Data by OpenStreetMap, licensed under ODbL
							</cite>
						</div>
					</div>

					{/* Right Column */}
					<div className="space-y-8">
						<div
							className="bg-lightShade/5 p-6 rounded-2xl space-y-4 border border-lightShade/10
              hover:border-lightShade/20 transition-colors duration-300">
							<div className="flex items-start space-x-4 text-sm group">
								<div
									className="w-10 h-10 rounded-xl bg-main/10 flex items-center justify-center flex-shrink-0
                  group-hover:scale-110 transition-transform duration-300">
									<FontAwesomeIcon
										icon={faMapMarkerAlt}
										className="text-main"
									/>
								</div>
								<p className="text-lightShade/80 pt-2 group-hover:text-lightShade transition-colors duration-300">
									201 King Street,
									<br />
									London, Ontario N6A 1C9
								</p>
							</div>
							<div className="flex items-center space-x-4 text-sm group">
								<div
									className="w-10 h-10 rounded-xl bg-main/10 flex items-center justify-center flex-shrink-0
                  group-hover:scale-110 transition-transform duration-300">
									<FontAwesomeIcon icon={faPhone} className="text-main" />
								</div>
								<p className="text-lightShade/80 group-hover:text-lightShade transition-colors duration-300">
									(519) 123-4567
								</p>
							</div>
						</div>

						<div className="flex flex-col space-y-4">
							{["Privacy Policy", "Terms of Service"].map((text, index) => (
								<Link
									key={index}
									to="#"
									className="px-6 py-3 rounded-xl bg-lightShade/5 hover:bg-main text-lightShade hover:text-darkShade 
                    transition-all duration-300 text-sm text-center border border-lightShade/10
                    hover:border-transparent group relative overflow-hidden">
									<span className="relative z-10">{text}</span>
									<div
										className="absolute inset-0 bg-gradient-to-r from-main to-main/80 opacity-0 
                    group-hover:opacity-100 transition-opacity duration-300 -z-0"></div>
								</Link>
							))}
						</div>
					</div>
				</div>

				{/* Bottom Section */}
				<div className="pt-8 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
					<p className="text-sm text-lightShade/60">
						Copyright ©2024 Adrenaline MMA | All rights reserved
					</p>
					<a
						href="https://bcinnovationlabs.com"
						rel="noopener noreferrer"
						target="_blank"
						className="group flex items-center text-sm text-lightShade/60 hover:text-main transition-all duration-300">
						Developed by
						<span className="mx-1 text-transparent bg-clip-text bg-gradient-to-r from-main to-main/80 font-medium">
							BCI Innovation Labs
						</span>
						<FontAwesomeIcon
							icon={faExternalLink}
							className="ml-2 group-hover:translate-x-1 transition-transform duration-300"
						/>
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
