import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faQuestionCircle,
  faEdit,
  faTrash,
  faEye,
  faGauge,
  faTrashAlt,
  faPencilAlt,
  faSpinner,
  faTable,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import Scroll from 'react-scroll';

import {
  ListHeader,
  ListFilter,
  DataListDesktop,
  DataListMobile,
  TableCell,
} from "../../Reusable/AdminList";
import Modal from "../../Reusable/Modal";
import FormSelectField from "../../Reusable/FormSelectField";
import FormErrorBox from "../../Reusable/FormErrorBox";
import { topAlertMessageState, topAlertStatusState, currentUserState, questionnaireFilterShowState, questionnaireFilterStatus, questionnaireFilterSortState } from "../../../AppState";
import { QUESTIONNAIRE_STATUS_MAP, QUESTIONNAIRE_STATUS_OPTIONS, QUESTIONNAIRE_STATUS_OPTIONS_WITH_EMPTY_OPTION } from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";
import { deleteQuestionnaireAPI, getQuestionnaireListApi } from "../../../API/questionnaire";
import PageLoadingContent from "../../Reusable/PageLoadingContent";

function AdminQuestionnaireList() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);
  const [showFilter, setShowFilter] = useRecoilState(
    questionnaireFilterShowState
  ); // Filtering + Searching
  const [status, setStatus] = useRecoilState(questionnaireFilterStatus);
  const [sort, setSort] = useRecoilState(questionnaireFilterSortState);
  const [selectedQuestionForDeletion, setSelectedQuestionForDeletion] =
    useState("");

  ////
  //// Component states.
  ////

  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState("");

  const [isFetching, setFetching] = useState(false);
  const [pageSize, setPageSize] = useState(10); // Pagination
  const [previousCursors, setPreviousCursors] = useState([]); // Pagination
  const [nextCursor, setNextCursor] = useState(""); // Pagination
  const [currentCursor, setCurrentCursor] = useState(""); // Pagination
  const [showModal, setShowModal] = useState(false);

  ////
  //// API.
  ////

  function OnListSuccess(response) {
    if (response.results !== null) {
      setListData(response);
      if (response.hasNextPage) {
        setNextCursor(response.nextCursor); // For pagination purposes.
      }
    } else {
      setListData([]);
      setNextCursor("");
    }
  }

  function OnListErr(apiErr) {
    setErrors(apiErr);
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onListDone() {
    setFetching(false);
  }

  ////
  //// Event handling.
  ////

  const fetchList = (cur, limit, keywords, st, sbv) => {
    setFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit); // Pagination

    // DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
    // and sort order combined with a comma seperation. Therefore we
    // need to split as follows.
    if (sbv !== undefined && sbv !== null && sbv !== "") {
      const sortArray = sbv.split(",");
      params.set("sort_field", sortArray[0]);
      params.set("sort_order", sortArray[1]);
    }

    if (cur !== "") {
      // Pagination
      params.set("cursor", cur);
    }

    // Filtering
    if (keywords !== undefined && keywords !== null && keywords !== "") {
      // Searhcing
      params.set("search", keywords);
    }
    if (st !== undefined && st !== null && st !== "") {
      params.set("status", status);
    }

    getQuestionnaireListApi(params, OnListSuccess, OnListErr, onListDone);
  };

  const onNextClicked = (e) => {
    let arr = [...previousCursors];
    arr.push(currentCursor);
    setPreviousCursors(arr);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = (e) => {
    let arr = [...previousCursors];
    const previousCursor = arr.pop();
    setPreviousCursors(arr);
    setCurrentCursor(previousCursor);
  };

  const onViewClick = (id) => {
    // Navigate to view page
    window.location.href = `/admin/questions/${id}`;
  };

  const onEditClick = (id) => {
    // Navigate to edit page
    window.location.href = `/admin/questions/${id}/update`;
  };

  // Function resets the filter state to its default state.
  const onClearFilterClick = (e) => {
    setShowFilter(false);
    setStatus(true);
    setSort("created,-1");
  };

  ////
  //// Misc.
  ////

  function onQuestionDeleteError(apiErr) {
    setErrors(apiErr);

    // Update notification.
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onQuestionDeleteSuccess(response) {
    // Update notification.
    setTopAlertStatus("success");
    setTopAlertMessage("Question deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);

    // Fetch again an updated list.
    fetchList(currentCursor, pageSize, status, sort);
  }
  function onQuestionDeleteDone() {
    setFetching(false);
    setShowModal(false);
  }

  const onSelectQuestionForDeletion = (e, datum) => {
    setSelectedQuestionForDeletion(datum);
    setShowModal(true);
  };
  const onDeselectQuestionForDeletion = (e) => {
    setSelectedQuestionForDeletion("");
    setShowModal(false);
  };

  const handleDeleteConfirmButtonClick = (e) => {
    deleteQuestionnaireAPI(
      selectedQuestionForDeletion.id,
      onQuestionDeleteSuccess,
      onQuestionDeleteError,
      onQuestionDeleteDone
    );
    setSelectedQuestionForDeletion("");
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      fetchList(currentCursor, pageSize, status, sort);
    }

    return () => {
      mounted = false;
    };
  }, [currentCursor, pageSize, status, sort]);

  const headers = [
    { label: "ID", className: "w-20" },
    { label: "Title", className: "flex-1" },
    // { label: "Multi-Select", className: "w-32" },
    { label: "Status", className: "w-32" },
    { label: "Created At", className: "w-32" },
    { label: "Actions", className: "w-40" },
  ];

  const breadcrumbItems = [
    {label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
    {label: "Questionnaires", icon: faQuestionCircle},
  ];

  const renderRow = (item, index) => (
    <tr key={item.id} className="hover:bg-gray-50 transition-colors duration-200">
      <TableCell className="py-4 whitespace-nowrap text-sm text-gray-500">
        {index + 1}
      </TableCell>
      <TableCell className="py-4 text-sm text-gray-900">
        {item.title}
      </TableCell>
      {/* <TableCell className="py-4 whitespace-nowrap">
        {item.multiSelect ? 'Yes' : 'No'}
      </TableCell> */}
      <TableCell className="py-4 whitespace-nowrap">
        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
          ${item.status 
            ? 'bg-green-100 text-green-800' 
            : 'bg-gray-100 text-gray-800'}`}>
          {QUESTIONNAIRE_STATUS_MAP[item.status]}
        </span>
      </TableCell>
      <TableCell className="py-4 whitespace-nowrap">
        {new Date(item.createdAt).toLocaleDateString()}
      </TableCell>


      <TableCell>
				<div className="flex items-center space-x-3">
					<Link
						to={`/admin/questions/${item.id}`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
						View
					</Link>
					<Link
						to={`/admin/questions/${item.id}/update`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faPencilAlt} className="mr-2 h-4 w-4" />
						Edit
					</Link>
					<button
						onClick={() => setSelectedQuestionForDeletion(item)}
						className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
						<FontAwesomeIcon icon={faTrashAlt} className="mr-2 h-4 w-4" />
						Delete
					</button>
				</div>
			</TableCell>
    </tr>
  );

  const renderMobileItem = (item, index) => (
    <div key={item.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
      <div className="flex justify-between items-start mb-3">
        <div>
          <h3 className="text-sm font-medium text-gray-900">{item.title}</h3>
          <span className={`mt-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
            ${item.status ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
            {/* {QUESTIONNAIRE_STATUS_MAP[item.status]} */}
            {item.status === true ? 'Active' : 'Archived'}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onViewClick(item.id)}
            className="p-2 text-gray-600 hover:text-primary transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faEye} className="h-4 w-4" />
          </button>
          <button
            onClick={() => onEditClick(item.id)}
            className="p-2 text-gray-600 hover:text-primary transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faEdit} className="h-4 w-4" />
          </button>
          <button
            onClick={() => setSelectedQuestionForDeletion(item)}
            className="p-2 text-gray-600 hover:text-red-600 transition-colors duration-200"
          >
            <FontAwesomeIcon icon={faTrash} className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );

  return (
<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
  <div className="min-h-screen bg-gray-50/50">
    <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <ListHeader
        title="Questionnaires"
        icon={faQuestionCircle}
        onRefresh={() => fetchList(currentCursor, pageSize, status, sort)}
        onToggleFilter={() => setShowFilter(!showFilter)}
        addLink="/admin/questions/add"
        addLabel="Add"
      />

      {showFilter && (
        <ListFilter onClear={onClearFilterClick}>
          <FormSelectField
            label="Status"
            name="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            options={QUESTIONNAIRE_STATUS_OPTIONS_WITH_EMPTY_OPTION}
          />
        </ListFilter>
      )}

      {isFetching ? (
        <PageLoadingContent displayMessage="Loading questionnaires..." />
      ) : (
        <>
          <FormErrorBox errors={errors} />
          {listData && listData.results && (listData.results.length > 0 || previousCursors.length > 0) ? (
            <>
              {/* Desktop view */}
              <div className="hidden md:block">
                <DataListDesktop
                  headers={headers}
                  data={listData.results || []}
                  renderRow={renderRow}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  previousCursors={previousCursors}
                  hasNextPage={listData.hasNextPage}
                  onPreviousClicked={onPreviousClicked}
                  onNextClicked={onNextClicked}
                />
              </div>

              {/* Mobile view */}
              <div className="md:hidden">
                <DataListMobile
                  data={listData.results || []}
                  renderItem={renderMobileItem}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  previousCursors={previousCursors}
                  hasNextPage={listData.hasNextPage}
                  onPreviousClicked={onPreviousClicked}
                  onNextClicked={onNextClicked}
                />
              </div>
            </>
          ) : (
            <section className="min-h-[300px] bg-gray-50 flex items-center justify-center">
              <div className="text-center p-8">
                <p className="text-3xl font-bold mb-4">
                  <FontAwesomeIcon className="fas" icon={faTable} />
                  <span className="ml-2">No Questionnaires</span>
                </p>
                <p className="text-gray-600">
                  No questionnaires.{" "}
                  <b>
                    <Link 
                      to="/admin/questions/add"
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Click here
                      <FontAwesomeIcon
                        className="ml-1"
                        icon={faArrowRight}
                      />
                    </Link>
                  </b>{" "}
                  to get started creating your first questionnaire.
                </p>
              </div>
            </section>
          )}
        </>
      )}

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={!!selectedQuestionForDeletion}
        onClose={() => setSelectedQuestionForDeletion(null)}
        header="Confirm Deletion"
      >
        <div className="space-y-4">
          <p className="text-gray-600">
            Are you sure you want to delete this questionnaire? This action cannot be undone.
          </p>
          <div className="flex justify-end space-x-3">
            <button
              onClick={() => setSelectedQuestionForDeletion(null)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteConfirmButtonClick}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</Layout>
  );
}

export default AdminQuestionnaireList;