import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faEye,
	faGauge,
	faLevelUp,
	faPencilAlt,
	faTrashAlt,
	faSearch,
} from "@fortawesome/free-solid-svg-icons";

import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {deleteLevelAPI, getLevelsListAPI} from "../../../API/level";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
} from "../../Reusable/AdminList";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormErrorBox from "../../Reusable/FormErrorBox";

function AdminLevelList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState(null);
	const [selectedLevelForDeletion, setSelectedLevelForDeletion] =
		useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({
		search: "",
	});

	// Pagination
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState(null);
	const [currentCursor, setCurrentCursor] = useState("");

	const headers = [
		{label: "Name", className: "w-1/4"},
		{label: "Description", className: "w-2/4"},
		{label: "Actions", className: "w-1/4"},
	];

	// Breadcrumb configuration
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Levels", icon: faLevelUp},
	];

	// API Calls
	const fetchLevels = (cursor, limit) => {
		setIsFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);

		if (cursor) params.set("cursor", cursor);
		if (filters.search) params.set("search", filters.search);

		getLevelsListAPI(
			params,
			onLevelsListSuccess,
			onLevelsListError,
			onLevelsListDone
		);
	};

	// Handle filter changes
	const handleFilterChange = (key, value) => {
		setFilters((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	// Clear filters
	const handleClearFilters = () => {
		setFilters({
			search: "",
			status: "all",
			dateRange: {
				from: "",
				to: "",
			},
		});
		fetchLevels("", pageSize);
	};

	const onLevelsListSuccess = (response) => {
		setListData(response);
		if (response.hasNextPage) {
			setNextCursor(response.nextCursor);
		} else {
			setNextCursor(null);
		}
	};

	const onLevelsListError = (apiErr) => {
		setErrors(apiErr);
	};

	const onLevelsListDone = () => {
		setIsFetching(false);
	};

	const onLevelDeleteSuccess = (response) => {
		setTopAlertMessage("Level deleted successfully.");
		setTopAlertStatus("success");
		fetchLevels(currentCursor, pageSize); // Fetch updated list after deletion
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	};

	const onLevelDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertMessage("Failed to delete badge.");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
	};

	const onDeleteConfirmButtonClick = () => {
		deleteLevelAPI(
			selectedLevelForDeletion.id,
			onLevelDeleteSuccess,
			onLevelDeleteError,
			() => setSelectedLevelForDeletion(null)
		);
	};

	// Pagination event handlers
	const onNextClicked = () => {
		const newPrevCursors = [...previousCursors, currentCursor];
		setPreviousCursors(newPrevCursors);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		const prevCursor = previousCursors.pop();
		setPreviousCursors([...previousCursors]);
		setCurrentCursor(prevCursor);
	};

	// Initial data fetch on component mount
	useEffect(() => {
		fetchLevels(currentCursor, pageSize);
	}, [currentCursor, pageSize]);

	// Render row for desktop view
	const renderRow = (level, index) => (
		<tr key={level.id} className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
			<td className="px-6 py-4 whitespace-nowrap">
				<div className="text-sm font-medium text-gray-900">{level.name}</div>
			</td>
			<td className="px-6 py-4">
				<div className="text-sm text-gray-500 line-clamp-2">
					{level.description}
				</div>
			</td>
			<td className="px-6 py-4">
				<div className="flex items-center space-x-3">
					<Link
						to={`/admin/levels/${level.id}`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
						View
					</Link>
					<Link
						to={`/admin/levels/${level.id}/edit`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faPencilAlt} className="mr-2 h-4 w-4" />
						Edit
					</Link>
					<button
						onClick={() => setSelectedLevelForDeletion(level)}
						className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
						<FontAwesomeIcon icon={faTrashAlt} className="mr-2 h-4 w-4" />
						Delete
					</button>
				</div>
			</td>
		</tr>
	);

	// Render item for mobile view
	const renderMobileItem = (level, index) => (
		<div
			key={level.id}
			className="bg-white shadow-sm rounded-lg p-4 border border-gray-200">
			<div className="space-y-2">
				<div className="font-medium text-gray-900">{level.name}</div>
				<div className="text-sm text-gray-500">{level.description}</div>
				<div className="flex justify-end space-x-2">
					<button
						onClick={() => setSelectedLevelForDeletion(level)}
						className="text-red-600 hover:text-red-900 transition-colors duration-300">
						Delete
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 py-8 max-w-container animate-fade-in">
				<ListHeader
					title="Levels"
					icon={faLevelUp}
					onRefresh={() => fetchLevels(currentCursor, pageSize)}
					onToggleFilter={() => setShowFilters(!showFilters)}
					addLink="/admin/levels/add"
					addLabel="Add Level"
				/>

				{showFilters && (
					<ListFilter onClear={handleClearFilters}>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
							{/* Search Filter */}
							<div className="space-y-2">
								<label className="block text-sm font-medium text-gray-700">
									<FontAwesomeIcon icon={faSearch} className="mr-2" />
									Search
								</label>
								<input
									type="text"
									value={filters.search}
									onChange={(e) => handleFilterChange("search", e.target.value)}
									placeholder="Search badges..."
									className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
								/>
							</div>
						</div>
					</ListFilter>
				)}

				{isFetching ? (
					<PageLoadingContent displayMessage="Loading levels..." />
				) : (
					<>
						<FormErrorBox errors={errors} />

						{/* Desktop View */}
						<div className="hidden md:block">
							<DataListDesktop
								headers={headers}
								data={listData?.results || []}
								renderRow={renderRow}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={!!nextCursor}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>

						{/* Mobile View */}
						<div className="md:hidden">
							<DataListMobile
								data={listData?.results || []}
								renderItem={renderMobileItem}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={!!nextCursor}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>
					</>
				)}

				{/* Delete Confirmation Modal */}
				<Modal
					isOpen={!!selectedLevelForDeletion}
					onClose={() => setSelectedLevelForDeletion(null)}
					header="Confirm Deletion">
					<div className="space-y-4">
						<p className="text-gray-600">
							Are you sure you want to delete the level "
							{selectedLevelForDeletion?.name}"?
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={() => setSelectedLevelForDeletion(null)}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
								Cancel
							</button>
							<button
								onClick={onDeleteConfirmButtonClick}
								disabled={isFetching}
								className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors duration-300 disabled:bg-gray-400">
								{isFetching ? "Deleting..." : "Delete"}
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminLevelList;
