import React from "react";

function FormDuelSelectField({
  label,
  oneName, onePlaceholder, oneSelectedValue, oneErrorText, oneOnChange, oneOptions, oneDisabled, oneMaxWidth,
  twoName, twoPlaceholder, twoSelectedValue, twoErrorText, twoOnChange, twoOptions, twoDisabled, twoMaxWidth,
  helpText,
}) {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <div className="flex space-x-4">
        <div className="flex-1" style={oneMaxWidth}>
          <select
            className={`block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none ${
              oneErrorText ? 'border-red-500 focus:border-red-500 focus:ring-red-500' :
              'focus:border-primary focus:ring-primary'
            } ${oneDisabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}`}
            name={oneName}
            placeholder={onePlaceholder}
            onChange={oneOnChange}
            disabled={oneDisabled}
          >
            {oneOptions.map((option, i) => (
              <option key={i} value={option.value} selected={oneSelectedValue === option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {oneErrorText && <p className="mt-1 text-sm text-red-600">{oneErrorText}</p>}
        </div>
        <div className="flex-1" style={twoMaxWidth}>
          <select
            className={`block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:outline-none ${
              twoErrorText ? 'border-red-500 focus:border-red-500 focus:ring-red-500' :
              'focus:border-primary focus:ring-primary'
            } ${twoDisabled ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}`}
            name={twoName}
            placeholder={twoPlaceholder}
            onChange={twoOnChange}
            disabled={twoDisabled}
          >
            {twoOptions.map((option, i) => (
              <option key={i} value={option.value} selected={twoSelectedValue === option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {twoErrorText && <p className="mt-1 text-sm text-red-600">{twoErrorText}</p>}
        </div>
      </div>
      {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}
    </div>
  );
}

export default FormDuelSelectField;