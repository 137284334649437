import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faInfoCircle,
	faCircleExclamation,
	faTimes,
	faWarning,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {topAlertMessageState, topAlertStatusState} from "../../AppState";

function TopAlertBanner() {
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	// Auto-dismiss after 5 seconds
	useEffect(() => {
		if (topAlertMessage) {
			const timer = setTimeout(() => {
				setTopAlertMessage("");
				setTopAlertStatus("");
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [topAlertMessage, setTopAlertMessage, setTopAlertStatus]);

	if (!topAlertMessage) return null;

	const alertStyles = {
		success: {
			bg: "bg-activeBg",
			bgLight: "bg-green-50",
			border: "border-green-500/20",
			text: "text-green-800",
			icon: faCheckCircle,
			iconColor: "text-green-400",
		},
		danger: {
			bg: "bg-darkRedLink",
			bgLight: "bg-red-50",
			border: "border-red-500/20",
			text: "text-red-800",
			icon: faCircleExclamation,
			iconColor: "text-red-400",
		},
		warning: {
			bg: "bg-burntOrange",
			bgLight: "bg-orange-50",
			border: "border-orange-500/20",
			text: "text-orange-800",
			icon: faWarning,
			iconColor: "text-orange-400",
		},
		info: {
			bg: "bg-softBlue",
			bgLight: "bg-blue-50",
			border: "border-blue-500/20",
			text: "text-blue-800",
			icon: faInfoCircle,
			iconColor: "text-blue-400",
		},
		default: {
			bg: "bg-primaryColor",
			bgLight: "bg-orange-50",
			border: "border-primaryColor/20",
			text: "text-gray-800",
			icon: faInfoCircle,
			iconColor: "text-primaryColor",
		},
	};

	const currentStyle = alertStyles[topAlertStatus] || alertStyles.default;

	return (
		<div
			className={`
                fixed top-0 left-0 right-0 z-[9999]
                transition-all duration-300 ease-in-out
                ${
									topAlertMessage
										? "translate-y-0 opacity-100"
										: "-translate-y-full opacity-0"
								}
                pt-14 sm:pt-16 lg:pt-14 lg:ml-64
            `}>
			<div
				className={`
                relative
                mx-auto max-w-7xl px-4 sm:px-6 lg:px-8
            `}>
				<div
					className={`
                    ${currentStyle.bgLight}
                    rounded-sm
                    border ${currentStyle.border}
                    shadow-lg
                    backdrop-blur-sm
                    overflow-hidden
                `}>
					<div
						className={`
                        px-4 py-3
                        flex items-center justify-between
                        ${currentStyle.text}
                    `}>
						<div className="flex items-center space-x-3 flex-1">
							<div
								className={`
                                flex-shrink-0
                                ${currentStyle.iconColor}
                            `}>
								<FontAwesomeIcon icon={currentStyle.icon} className="h-5 w-5" />
							</div>
							<div className="flex-1 min-w-0">
								<p
									className="
                                    font-secondary 
                                    font-medium 
                                    text-sm 
                                    sm:text-base
                                    line-clamp-2
                                ">
									{topAlertMessage}
								</p>
							</div>
						</div>
						<div className="ml-4 flex-shrink-0">
							<button
								type="button"
								onClick={() => {
									setTopAlertMessage("");
									setTopAlertStatus("");
								}}
								className={`
                                    rounded-md 
                                    inline-flex 
                                    ${currentStyle.text}
                                    hover:${currentStyle.bg} 
                                    hover:text-white
                                    focus:outline-none 
                                    focus:ring-2 
                                    focus:ring-offset-2 
                                    focus:ring-${currentStyle.bg}
                                    transition-colors
                                    duration-200
                                    p-1.5
                                `}>
								<span className="sr-only">Dismiss</span>
								<FontAwesomeIcon icon={faTimes} className="h-5 w-5" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopAlertBanner;
