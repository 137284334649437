import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faHandHolding,
	faArrowLeft,
	faUsers,
	faEye,
	faPencil,
	faTrashCan,
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faArrowUpRightFromSquare,
	faRefresh,
	faFilter,
	faSearch,
	faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {getOfferListAPI, deleteOfferAPI} from "../../../API/Offer";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	offersFilterShowState,
	offersFilterTemporarySearchTextState,
	offersFilterActualSearchTextState,
	offersFilterStatusState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectField from "../../Reusable/FormSelectField";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import {
	PAGE_SIZE_OPTIONS,
	OFFER_STATUS_OPTIONS,
} from "../../../Constants/FieldOptions";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
	TableCell,
} from "../../Reusable/AdminList";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function AdminOfferList() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(offersFilterShowState); // Filtering + Searching
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		offersFilterTemporarySearchTextState
	); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useRecoilState(
		offersFilterActualSearchTextState
	); // Searching - The actual search query value to submit to the API.
	const [status, setStatus] = useRecoilState(offersFilterStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState({
		results: [],
		count: 0,
		hasNextPage: false,
		nextCursor: "",
	});
	const [selectedOfferForDeletion, setSelectedOfferForDeletion] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [sortField, setSortField] = useState("created"); // Sorting

	////
	//// API.
	////

	function onOfferListSuccess(response) {
		console.log("onOfferListSuccess: Starting...");
		if (response && response.results) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData({
				results: [],
				count: 0,
				hasNextPage: false,
				nextCursor: "",
			});
			setNextCursor("");
		}
	}

	function onOfferListError(apiErr) {
		console.log("onOfferListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onOfferListDone() {
		console.log("onOfferListDone: Starting...");
		setFetching(false);
	}

	function onOfferDeleteSuccess(response) {
		console.log("onOfferDeleteSuccess: Starting..."); // For debugging purposes only.

		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Offer deleted");
		setTimeout(() => {
			console.log(
				"onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Fetch again an updated list.
		fetchList(currentCursor, pageSize, actualSearchText, status);
	}

	function onOfferDeleteError(apiErr) {
		console.log("onOfferDeleteError: Starting..."); // For debugging purposes only.
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			console.log(
				"onOfferDeleteError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onOfferDeleteDone() {
		console.log("onOfferDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (
		cursor = "",
		limit = pageSize,
		search = "",
		statusFilter = ""
	) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		params.set("sort_field", sortField);
		params.set("sort_order", -1);

		if (cursor) {
			params.set("cursor", cursor);
		}
		if (search) {
			params.set("search", search);
		}
		if (statusFilter) {
			params.set("status", statusFilter);
		}

		getOfferListAPI(
			params,
			onOfferListSuccess,
			onOfferListError,
			onOfferListDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	const onSelectOfferForDeletion = (e, datum) => {
		console.log("onSelectOfferForDeletion", datum);
		setSelectedOfferForDeletion(datum);
	};

	const onDeselectOfferForDeletion = (e) => {
		console.log("onDeselectOfferForDeletion");
		setSelectedOfferForDeletion("");
	};

	const onDeleteConfirmButtonClick = (e) => {
		console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

		deleteOfferAPI(
			selectedOfferForDeletion.id,
			onOfferDeleteSuccess,
			onOfferDeleteError,
			onOfferDeleteDone
		);
		setSelectedOfferForDeletion("");
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setStatus(2); // 1=Pending, 2=Active, 3=Archived
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(currentCursor, pageSize, actualSearchText, status);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status]);

	////
	//// Component rendering.
	////

	const breadcrumbItems = [
		{label: "Dashboard", icon: faGauge, link: "/admin/dashboard"},
		{label: "Offers", icon: faHandHolding},
	];

	const getStatusLabel = (statusValue) => {
		const statusOption = OFFER_STATUS_OPTIONS.find(
			(option) => option.value === statusValue
		);
		return statusOption ? statusOption.label : "Unknown";
	};

	////
	//// Render helpers
	////

	const tableHeaders = [
		{label: "Name", className: "w-1/4"},
		{label: "Description", className: "w-1/2"},
		{label: "Status", className: "w-1/8"},
		{label: "Actions", className: "w-1/8"},
	];

	const renderDesktopRow = (offer) => (
		<tr key={offer.id}>
			<td className="px-4 py-4 whitespace-nowrap">
				<Link
					to={`/admin/offers/${offer.id}`}
					className="text-primary hover:text-primary-dark transition-colors duration-300">
					{offer.name}
				</Link>
			</td>
			<td className="px-4 py-4">
				<div className="line-clamp-2 text-gray-600">{offer.description}</div>
			</td>
			<td className="px-4 py-4 whitespace-nowrap">
				<span
					className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
          ${
						offer.status === 1
							? "bg-green-100 text-green-800"
							: offer.status === 2
							? "bg-blue-100 text-blue-800"
							: "bg-gray-100 text-gray-800"
					}`}>
					{getStatusLabel(offer.status)}
				</span>
			</td>
			<td className="px-4 py-4 whitespace-nowrap">
				<div className="flex items-center space-x-2">
					<Link
						to={`/admin/offer/${offer.id}`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
						View
					</Link>
					{/* <Link
            to={`/admin/offer/${offer.id}/update`}
            className="text-gray-600 hover:text-primary transition-colors duration-300"
            title="Edit"
          >
            <FontAwesomeIcon icon={faPencil} className="w-4 h-4" />
          </Link>
          <button
            onClick={(e) => onSelectOfferForDeletion(e, offer)}
            className="text-gray-600 hover:text-red-600 transition-colors duration-300"
            title="Archive"
          >
            <FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
          </button> */}
				</div>
			</td>
		</tr>
	);

	const renderMobileItem = (offer) => (
		<div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
			<div className="flex justify-between items-start mb-3">
				<Link
					to={`/admin/offer/${offer.id}`}
					className="text-primary hover:text-primary-dark transition-colors duration-300 font-medium">
					{offer.name}
				</Link>
				<span
					className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
          ${
						offer.status === 1
							? "bg-green-100 text-green-800"
							: offer.status === 2
							? "bg-blue-100 text-blue-800"
							: "bg-gray-100 text-gray-800"
					}`}>
					{getStatusLabel(offer.status)}
				</span>
			</div>

			<p className="text-gray-600 text-sm mb-4 line-clamp-2">
				{offer.description}
			</p>

			<div className="flex items-center justify-end space-x-3">
				<Link
					to={`/admin/offer/${offer.id}`}
					className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
					<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
					View
				</Link>
				{/* <Link
          to={`/admin/offer/${offer.id}/update`}
          className="text-gray-600 hover:text-primary transition-colors duration-300"
          title="Edit"
        >
          <FontAwesomeIcon icon={faPencil} className="w-4 h-4" />
        </Link>
        <button
          onClick={(e) => onSelectOfferForDeletion(e, offer)}
          className="text-gray-600 hover:text-red-600 transition-colors duration-300"
          title="Archive"
        >
          <FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
        </button> */}
			</div>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50/50">
				<div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
					<ListHeader
						title="Offers"
						icon={faHandHolding}
						onRefresh={() =>
							fetchList(currentCursor, pageSize, actualSearchText)
						}
						onToggleFilter={() => setShowFilter(!showFilter)}
						addLink="/admin/offers/add"
						addLabel="New"
					/>

					{showFilter && (
						<ListFilter onClear={onClearFilterClick}>
							<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
								<FormInputFieldWithButton
									label="Search"
									name="search"
									placeholder="Search by name or description..."
									value={temporarySearchText}
									onChange={(e) => setTemporarySearchText(e.target.value)}
									onButtonClick={onSearchButtonClick}
									buttonLabel="Search"
								/>
								<FormSelectField
									label="Status"
									name="status"
									value={status}
									options={OFFER_STATUS_OPTIONS}
									onChange={(e) => setStatus(e.target.value)}
								/>
							</div>
						</ListFilter>
					)}

					{isFetching ? (
						<div className="flex justify-center py-8">
							<PageLoadingContent message="Loading offers..." />
						</div>
					) : (
						<>
							<FormErrorBox errors={errors} />

							{listData?.results?.length > 0 || previousCursors.length > 0 ? (
								<>
									<div className="hidden md:block">
										<DataListDesktop
											headers={tableHeaders}
											data={listData.results}
											renderRow={renderDesktopRow}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>

									<div className="md:hidden">
										<DataListMobile
											data={listData.results}
											renderItem={renderMobileItem}
											pageSize={pageSize}
											setPageSize={setPageSize}
											previousCursors={previousCursors}
											hasNextPage={listData.hasNextPage}
											onPreviousClicked={onPreviousClicked}
											onNextClicked={onNextClicked}
										/>
									</div>
								</>
							) : (
								<div className="bg-white rounded-xl shadow-sm p-8 text-center border border-gray-200">
									<div className="max-w-md mx-auto">
										<FontAwesomeIcon
											icon={faTable}
											className="text-4xl text-gray-400 mb-4"
										/>
										<h3 className="text-lg font-medium text-gray-900 mb-2">
											No Offers Found
										</h3>
										<p className="text-gray-500 mb-6">
											Get started by creating your first offer.
										</p>
										<Link
											to="/admin/offers/add"
											className="inline-flex items-center px-4 py-2 bg-primary hover:bg-primary-dark 
                               text-white rounded-lg transition-colors duration-300">
											<FontAwesomeIcon icon={faPlus} className="mr-2" />
											Create New Offer
										</Link>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>

			<Modal
				isOpen={!!selectedOfferForDeletion}
				onClose={onDeselectOfferForDeletion}
				header="Are you sure?">
				<div className="space-y-4">
					<p className="text-gray-600">
						You are about to archive this offer. This action can be undone but
						you'll need to contact the system administrator. Would you like to
						continue?
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={onDeselectOfferForDeletion}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
                       border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-300">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirmButtonClick}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                       rounded-lg hover:bg-red-700 transition-colors duration-300">
							Archive
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default AdminOfferList;
