import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo, faVideoCamera, faEye, faPencil, 
  faTrashCan, faPlus, faGauge, faArrowLeft,
  faSearch, faFilter, faRefresh, faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import Scroll from "react-scroll";

import { 
  ListHeader, 
  ListFilter, 
  DataListDesktop, 
  DataListMobile,
  TableHeader,
  TableCell 
} from "../../../Reusable/AdminList";
import Modal from "../../../Reusable/Modal";
import FormErrorBox from "../../../Reusable/FormErrorBox";
import FormInputFieldWithButton from "../../../Reusable/FormInputFieldWithButton";
import FormSelectFieldForOffer from "../../../Reusable/FormSelectFieldForOffer";
import FormSelectFieldForVideoCategory from "../../../Reusable/FormSelectFieldForVideoCategory";
import FormSelectField from "../../../Reusable/FormSelectField";
import PageLoadingContent from "../../../Reusable/PageLoadingContent";

import { getVideoContentListAPI, deleteVideoContentAPI } from "../../../../API/VideoContent";
import {
  topAlertMessageState,
  topAlertStatusState,
  videoContentsFilterShowState,
  videoContentsFilterSortState,
  videoContentsFilterTemporarySearchTextState,
  videoContentsFilterActualSearchTextState,
  videoContentsFilterVideoTypeState,
  videoContentsFilterStatusState,
  videoContentsFilterOfferIDState,
  videoContentsFilterCategoryIDState,
  currentUserState
} from "../../../../AppState";
import {
  VIDEO_CONTENT_STATUS_OPTIONS_WITH_EMPTY_OPTION,
  VIDEO_CONTENT_TYPE_WITH_EMPTY_OPTIONS,
  VIDEO_COLLECTION_STATUS_MAP,
  VIDEO_CONTENT_VIDEO_TYPE_MAP
} from "../../../../Constants/FieldOptions";

import Layout from "../../../Menu/Layout";


function AdminVideoContentList() {
  ////
  //// URL Parameters.
  ////

  const { vcid } = useParams();

  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);
  const [showFilter, setShowFilter] = useRecoilState(
    videoContentsFilterShowState,
  ); // Filtering + Searching
  const [sort, setSort] = useRecoilState(videoContentsFilterSortState); // Sorting
  const [temporarySearchText, setTemporarySearchText] = useRecoilState(
    videoContentsFilterTemporarySearchTextState,
  ); // Searching - The search field value as your writes their query.
  const [actualSearchText, setActualSearchText] = useRecoilState(
    videoContentsFilterActualSearchTextState,
  ); // Searching - The actual search query value to submit to the API.
  const [status, setStatus] = useRecoilState(videoContentsFilterStatusState);
  const [videoType, setVideoType] = useRecoilState(
    videoContentsFilterVideoTypeState,
  );
  const [offerID, setOfferID] = useRecoilState(videoContentsFilterOfferIDState);
  const [videoCategoryID, setVideoCategoryID] = useRecoilState(
    videoContentsFilterCategoryIDState,
  );

  ////
  //// Component states.
  ////

  const [isVideoCategoryOther, setIsVideoCategoryOther] = useState("");
  const [isOfferOther, setIsOfferOther] = useState("");
  const [errors, setErrors] = useState({});
  const [listData, setListData] = useState("");
  const [selectedVideoContentForDeletion, setSelectedVideoContentForDeletion] =
    useState("");
  const [isFetching, setFetching] = useState(false);
  const [pageSize, setPageSize] = useState(10); // Pagination
  const [previousCursors, setPreviousCursors] = useState([]); // Pagination
  const [nextCursor, setNextCursor] = useState(""); // Pagination
  const [currentCursor, setCurrentCursor] = useState(""); // Pagination

  // Updated table headers to match requirements
  const tableHeaders = [
      { label: "Name", className: "w-1/4" },
      { label: "Category", className: "w-1/5" },
      { label: "Video Type", className: "w-1/5" },
      { label: "Status", className: "w-1/5" },
      { label: "Actions", className: "w-24" } // Actions column
  ];

    ////
  //// API.
  ////

  function onVideoContentListSuccess(response) {
    console.log("onVideoContentListSuccess: Starting...");
    if (response.results !== null) {
      setListData(response);
      if (response.hasNextPage) {
        setNextCursor(response.nextCursor); // For pagination purposes.
      }
    } else {
      setListData([]);
      setNextCursor("");
    }
  }

  function onVideoContentListError(apiErr) {
    console.log("onVideoContentListError: Starting...");
    setErrors(apiErr);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onVideoContentListDone() {
    console.log("onVideoContentListDone: Starting...");
    setFetching(false);
  }

  function onVideoContentDeleteSuccess(response) {
    console.log("onVideoContentDeleteSuccess: Starting..."); // For debugging purposes only.

    // Update notification.
    setTopAlertStatus("success");
    setTopAlertMessage("VideoContent deleted");
    setTimeout(() => {
      console.log(
        "onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus,
      );
      setTopAlertMessage("");
    }, 2000);

    // Fetch again an updated list.
    fetchList(
      currentCursor,
      pageSize,
      actualSearchText,
      status,
      videoType,
      offerID,
      videoCategoryID,
      vcid,
    );
  }

  function onVideoContentDeleteError(apiErr) {
    console.log("onVideoContentDeleteError: Starting..."); // For debugging purposes only.
    setErrors(apiErr);

    // Update notification.
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => {
      console.log(
        "onVideoContentDeleteError: topAlertMessage, topAlertStatus:",
        topAlertMessage,
        topAlertStatus,
      );
      setTopAlertMessage("");
    }, 2000);

    // The following code will cause the screen to scroll to the top of
    // the page. Please see ``react-scroll`` for more information:
    // https://github.com/fisshy/react-scroll
    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onVideoContentDeleteDone() {
    console.log("onVideoContentDeleteDone: Starting...");
    setFetching(false);
  }

  // Table row renderer
  const renderTableRow = (item) => (
      <tr key={item.id} className="hover:bg-gray-50">
          <TableCell>
              {item.name}
              {item.alternateName && (
                  <span className="text-gray-500 ml-2">({item.alternateName})</span>
              )}
          </TableCell>
          <TableCell>
              {item.categoryName ? (
                  <Link 
                      to={`/admin/video-category/${item.categoryId}`}
                      className="text-primary hover:text-primary-dark inline-flex items-center"
                      target="_blank" 
                      rel="noreferrer"
                  >
                      {item.categoryName}
                      <FontAwesomeIcon 
                          className="ml-1 h-4 w-4" 
                          icon={faArrowUpRightFromSquare} 
                      />
                  </Link>
              ) : (
                  <span className="text-gray-400">-</span>
              )}
          </TableCell>
          <TableCell>
              {VIDEO_CONTENT_VIDEO_TYPE_MAP[item.videoType]}
          </TableCell>
          <TableCell>
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${item.status === 1 ? 'bg-green-100 text-green-800' : 
                    item.status === 2 ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-gray-100 text-gray-800'}`}
              >
                  {VIDEO_COLLECTION_STATUS_MAP[item.status]}
              </span>
          </TableCell>
          <TableCell className="text-right">
              <div className="space-x-2">
                  <Link
					to={`/admin/video-collection/${item.collectionId}/video-content/${item.id}`}
					className="inline-flex items-center px-3 sm:px-4 py-2 bg-white text-gray-700 
                 hover:text-primary rounded-lg border border-gray-200 
                 hover:border-primary transition-all duration-300 shadow-sm
                 text-sm sm:text-base flex-1 sm:flex-none justify-center sm:justify-start">
					<FontAwesomeIcon icon={faEye} className="w-4 h-4 mr-1" />
					View
				</Link>
              </div>
          </TableCell>
      </tr>
  );

  // Mobile item renderer
  const renderMobileItem = (item) => (
      <div key={item.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 space-y-3">
          <div className="flex justify-between items-start">
              <div>
                  <h3 className="text-sm font-medium text-gray-900">{item.name}</h3>
                  {item.alternateName && (
                      <p className="text-sm text-gray-500">{item.alternateName}</p>
                  )}
              </div>
              <div className="space-x-2">
                  <Link
					to={`/admin/video-collection/${item.collectionId}/video-content/${item.id}`}
					className="inline-flex items-center px-3 sm:px-4 py-2 bg-white text-gray-700 
                 hover:text-primary rounded-lg border border-gray-200 
                 hover:border-primary transition-all duration-300 shadow-sm
                 text-sm sm:text-base flex-1 sm:flex-none justify-center sm:justify-start">
					<FontAwesomeIcon icon={faEye} className="w-4 h-4 mr-1" />
					View
				</Link>
              </div>
          </div>

          <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                  <p className="text-gray-500">Category</p>
                  {item.categoryName ? (
                      <Link 
                          to={`/admin/video-category/${item.categoryId}`}
                          className="text-primary hover:text-primary-dark inline-flex items-center"
                          target="_blank" 
                          rel="noreferrer"
                      >
                          {item.categoryName}
                          <FontAwesomeIcon 
                              className="ml-1 h-3 w-3" 
                              icon={faArrowUpRightFromSquare} 
                          />
                      </Link>
                  ) : (
                      <span className="text-gray-400">-</span>
                  )}
              </div>
              <div>
                  <p className="text-gray-500">Video Type</p>
                  <p>{VIDEO_CONTENT_VIDEO_TYPE_MAP[item.videoType]}</p>
              </div>
              <div>
                  <p className="text-gray-500">Status</p>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${item.status === 1 ? 'bg-green-100 text-green-800' : 
                        item.status === 2 ? 'bg-yellow-100 text-yellow-800' : 
                        'bg-gray-100 text-gray-800'}`}
                  >
                      {VIDEO_COLLECTION_STATUS_MAP[item.status]}
                  </span>
              </div>
          </div>
      </div>
  );


  ////
  //// Event handling.
  ////

  const fetchList = (cur, limit, keywords, st, vct, oid, catid, vcid) => {
    setFetching(true);
    setErrors({});

    let params = new Map();
    params.set("page_size", limit); // Pagination
    params.set("sort_field", "created"); // Sorting
    params.set("sort_order", -1); // Sorting - descending, meaning most recent start date to oldest start date.

    if (cur !== "") {
      // Pagination
      params.set("cursor", cur);
    }

    params.set("video_collection_id", vcid);

    // Filtering
    if (keywords !== undefined && keywords !== null && keywords !== "") {
      // Searhcing
      params.set("search", keywords);
    }
    if (st !== undefined && st !== null && st !== "") {
      params.set("status", st);
    }
    if (vct !== undefined && vct !== null && vct !== "") {
      params.set("video_type", vct);
    }
    if (oid !== undefined && oid !== null && oid !== "") {
      params.set("offer_id", oid);
    }
    if (catid !== undefined && catid !== null && catid !== "") {
      params.set("category_id", catid);
    }

    console.log("params:", params);

    getVideoContentListAPI(
      params,
      onVideoContentListSuccess,
      onVideoContentListError,
      onVideoContentListDone,
    );
  };

  const onNextClicked = (e) => {
    console.log("onNextClicked");
    let arr = [...previousCursors];
    arr.push(currentCursor);
    setPreviousCursors(arr);
    setCurrentCursor(nextCursor);
  };

  const onPreviousClicked = (e) => {
    console.log("onPreviousClicked");
    let arr = [...previousCursors];
    const previousCursor = arr.pop();
    setPreviousCursors(arr);
    setCurrentCursor(previousCursor);
  };

  const onSearchButtonClick = (e) => {
    // Searching
    console.log("Search button clicked...");
    setActualSearchText(temporarySearchText);
  };

  const onSelectVideoContentForDeletion = (e, datum) => {
    console.log("onSelectVideoContentForDeletion", datum);
    setSelectedVideoContentForDeletion(datum);
  };

  const onDeselectVideoContentForDeletion = (e) => {
    console.log("onDeselectVideoContentForDeletion");
    setSelectedVideoContentForDeletion("");
  };

  const onDeleteConfirmButtonClick = (e) => {
    console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

    deleteVideoContentAPI(
      selectedVideoContentForDeletion.id,
      onVideoContentDeleteSuccess,
      onVideoContentDeleteError,
      onVideoContentDeleteDone,
    );
    setSelectedVideoContentForDeletion("");
  };

  // Function resets the filter state to its default state.
  const onClearFilterClick = (e) => {
    setShowFilter(false);
    setActualSearchText("");
    setTemporarySearchText("");
    setVideoType(0);
    setStatus(0);
    setOfferID(null);
    setVideoCategoryID("");
    setSort("created,-1");
  };

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
      fetchList(
        currentCursor,
        pageSize,
        actualSearchText,
        status,
        videoType,
        offerID,
        videoCategoryID,
        vcid,
      );
    }

    return () => {
      mounted = false;
    };
  }, [
    currentCursor,
    pageSize,
    actualSearchText,
    status,
    videoType,
    offerID,
    videoCategoryID,
    vcid,
  ]);

  ////
  //// Component rendering.
  ////
  // Main Render
  return (
    <div className="mx-auto px-2 py-4">
    {/* Header Section */}
    <ListHeader
        title="Video Contents"
        icon={faVideoCamera}
        onRefresh={() => fetchList(currentCursor, pageSize, actualSearchText, status, videoType, offerID, videoCategoryID, vcid)}
        onToggleFilter={() => setShowFilter(!showFilter)}
        addLink={`/admin/video-collection/${vcid}/video-contents/add`}
        addLabel="Add"
    />

    {/* Main Content Card */}
    <div>
        {/* Filter Section */}
        {showFilter && (
            <ListFilter onClear={onClearFilterClick}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <FormInputFieldWithButton
                        label="Search"
                        name="search"
                        type="text"
                        value={temporarySearchText}
                        placeholder="Search by name..."
                        onChange={(e) => setTemporarySearchText(e.target.value)}
                        onButtonClick={onSearchButtonClick}
                        buttonIcon={faSearch}
                    />
                    <FormSelectField
                        label="Status"
                        name="status"
                        selectedValue={status}
                        options={VIDEO_CONTENT_STATUS_OPTIONS_WITH_EMPTY_OPTION}
                        onChange={(e) => setStatus(e.target.value)}
                    />
                    <FormSelectField
                        label="Video Type"
                        name="videoType"
                        selectedValue={videoType}
                        options={VIDEO_CONTENT_TYPE_WITH_EMPTY_OPTIONS}
                        onChange={(e) => setVideoType(e.target.value)}
                    />
                    <FormSelectFieldForVideoCategory
                        videoCategoryID={videoCategoryID}
                        setVideoCategoryID={setVideoCategoryID}
                        isVideoCategoryOther={isVideoCategoryOther}
                        setIsVideoCategoryOther={setIsVideoCategoryOther}
                    />
                    <FormSelectFieldForOffer
                        offerID={offerID}
                        setOfferID={setOfferID}
                        isOfferOther={isOfferOther}
                        setIsOfferOther={setIsOfferOther}
                    />
                </div>
            </ListFilter>
        )}

        {/* Content Section */}
        <div>
            <FormErrorBox errors={errors} />
            
            {isFetching ? (
                <PageLoadingContent />
            ) : listData?.results?.length > 0 ? (
                <>
                    {/* Desktop View */}
                    <div className="hidden md:block">
                        <DataListDesktop
                            headers={tableHeaders}
                            data={listData.results}
                            renderRow={renderTableRow}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            previousCursors={previousCursors}
                            hasNextPage={listData.hasNextPage}
                            onPreviousClicked={onPreviousClicked}
                            onNextClicked={onNextClicked}
                        />
                    </div>

                    {/* Mobile View */}
                    <div className="md:hidden">
                        <DataListMobile
                            data={listData.results}
                            renderItem={renderMobileItem}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            previousCursors={previousCursors}
                            hasNextPage={listData.hasNextPage}
                            onPreviousClicked={onPreviousClicked}
                            onNextClicked={onNextClicked}
                        />
                    </div>
                </>
            ) : (
                <div className="text-center py-12">
                    <FontAwesomeIcon icon={faVideo} className="h-12 w-12 text-gray-400 mb-4" />
                    <h3 className="text-lg font-medium text-gray-900">No video contents found</h3>
                    <p className="mt-2 text-sm text-gray-500">
                        Get started by adding your first video content.
                    </p>
                    <div className="mt-6">
                        <Link
                            to={`/admin/video-collection/${vcid}/video-contents/add`}
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark"
                        >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Add Content
                        </Link>
                    </div>
                </div>
            )}
        </div>
    </div>

    {/* Delete Confirmation Modal */}
    <Modal
        isOpen={!!selectedVideoContentForDeletion}
        onClose={() => setSelectedVideoContentForDeletion(null)}
        header="Confirm Deletion"
    >
        <div className="space-y-4">
            <p className="text-gray-700">
                Are you sure you want to delete this video content? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
                <button
                    onClick={() => setSelectedVideoContentForDeletion(null)}
                    className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={onDeleteConfirmButtonClick}
                    className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                >
                    Delete
                </button>
            </div>
        </div>
    </Modal>
</div>
  );
}

export default AdminVideoContentList;
