import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faMoneyBillWave,
	faImage,
	faVideo,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faDumbbell,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faLink,
	faRibbon,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {
	putExerciseUpdateAPI,
	getExerciseDetailAPI,
} from "../../../API/Exercise";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormSelectFieldForOffer from "../../Reusable/FormSelectFieldForOffer";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	EXERCISE_MOMENT_TYPE_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_CATEGORY_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_TYPE_WITH_EMPTY_OPTIONS,
	EXERCISE_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	EXERCISE_GENDER_OPTIONS_WITH_EMPTY_OPTION,
	TIMED_LOCK_DURATION_WITH_EMPTY_OPTIONS,
	BADGE_CRITERIA_WITH_EMPTY_OPTIONS,
	FILE_UPLOAD_OPTIONS,
	FILE_UPLOAD_OPTIONS_MAP,
} from "../../../Constants/FieldOptions";
import {
	BADGE_THUMBNAIL_TYPE_EXTERNAL_URL,
	BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
} from "../../../Constants/App";
import {getBadgeDetailAPI, putBadgeUpdateAPI} from "../../../API/badge";
import Layout from "../../Menu/Layout";

function AdminBadgeUpdate() {
	////
	//// URL Parameters.
	////

	const {id} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [thumbnailType, setThumbnailType] = useState(
		BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");
	const [existingThumbnailURL, setExistingThumbnailURL] = useState("");
	const [existingThumbnailAttachmentID, setExistingThumbnailAttachmentID] =
		useState("");
	const [criteria, setCriteria] = useState("");

	// Breadcrumb items
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Badges", link: "/admin/badges", icon: faRibbon},
		{label: "Update Badge", icon: faPencil},
	];

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});

		const payload = {
			id: id,
			name: name,
			description: description,
			thumbnail_type: parseInt(thumbnailType),
			criteria: parseInt(criteria),
		};

		if (thumbnailType === BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE) {
			if (thumbnailAttachmentID !== existingThumbnailAttachmentID) {
				payload.thumbnail_upload = thumbnailAttachmentID;
        payload.thumbnail_url = "";
			} else {
				payload.thumbnail_upload = existingThumbnailAttachmentID;
        payload.thumbnail_url = "";
			}
		} else {
			if (thumbnailURL !== existingThumbnailURL) {
				payload.thumbnail_url = thumbnailURL;
        payload.thumbnail_upload = "";  
			} else {
				payload.thumbnail_url = existingThumbnailURL;
        payload.thumbnail_upload = "";
			}
		}

  putBadgeUpdateAPI(payload, onUpdateSuccess, onUpdateError, onUpdateDone);
	};

	////
	//// API.
	////

	// --- Exercise Update --- //

	function onUpdateSuccess(response) {
		// For debugging purposes only.
		console.log("onUpdateSuccess: Starting...");
		console.log(response);

		// Update a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Badge updated");
		setTopAlertStatus("success");
		setTimeout(() => {
			console.log("onUpdateSuccess: Delayed for 2 seconds.");
			console.log(
				"onUpdateSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect the organization to the organization attachments page.
		setForceURL(`/admin/badges/${response.id}`);
	}

	function onUpdateError(apiErr) {
		console.log("onUpdateError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onUpdateError: Delayed for 2 seconds.");
			console.log(
				"onUpdateError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onUpdateDone() {
		console.log("onUpdateDone: Starting...");
		setFetching(false);
	}

	// --- Exercise Detail --- //

	function onDetailSuccess(response) {
		setThumbnailType(response.thumbnailType);
    setThumbnailAttachmentID(
        response.thumbnailAttachmentId === "000000000000000000000000"
            ? ""
            : response.thumbnailAttachmentId
    );
    setExistingThumbnailAttachmentID(
        response.thumbnailAttachmentId === "000000000000000000000000"
            ? ""
            : response.thumbnailAttachmentId
    );
    setThumbnailAttachmentName(response.thumbnailAttachmentFilename);
    setExistingThumbnailURL(response.thumbnailObjectURL);
    setThumbnailURL(response.thumbnailUrl || "");
    setDescription(response.description);
    setName(response.name);
    setCriteria(response.criteria);
	}

	function onDetailError(apiErr) {
		console.log("onDetailError: Starting...");
		setErrors(apiErr);

		// Detail a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onDetailError: Delayed for 2 seconds.");
			console.log(
				"onDetailError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDetailDone() {
		console.log("onDetailDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			getBadgeDetailAPI(id, onDetailSuccess, onDetailError, onDetailDone);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 py-8 max-w-container animate-fade-in">
				{/* Header Section - Using ListHeader style */}
				<div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
					<div className="mb-4 md:mb-0">
						<h1 className="text-2xl font-bold text-textDark flex items-center">
							<div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center mr-4">
								<FontAwesomeIcon
									icon={faPencil}
									className="text-primary text-xl"
								/>
							</div>
							Update Badge
						</h1>
					</div>
					<Link
						to="/admin/badges"
						className="inline-flex items-center px-4 py-2 bg-white text-primary hover:text-primary-dark rounded-lg border border-gray-200 hover:border-primary transition-all duration-300 shadow-sm">
						<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
						Back to List
					</Link>
				</div>

				<FormErrorBox errors={errors} />

				{/* Main Form Card */}
				<div className="bg-white rounded-2xl shadow-lg border border-gray-200 overflow-hidden hover:shadow-xl transition-shadow duration-300">
					<div className="relative">
						{/* Header Accent */}
						<div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-primary via-primary-light to-primary-dark"></div>

						<div className="p-8 space-y-6">
							{/* Badge Information */}
							<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
								<FormInputField
									label="Badge Name"
									name="name"
									placeholder="Enter badge name"
									value={name}
									onChange={(e) => setName(e.target.value)}
									errorMessage={errors?.name}
									maxLength={100}
									required={true}
								/>

								<FormSelectField
									label="Badge Criteria"
									name="criteria"
									placeholder="Select criteria"
									value={criteria}
									options={BADGE_CRITERIA_WITH_EMPTY_OPTIONS}
									onChange={(e) => setCriteria(e.target.value)}
									errorMessage={errors?.criteria}
								/>
							</div>

							{/* Thumbnail Section */}
							<div className="space-y-6">
								<div className="border-b border-gray-100 pb-4">
									<label className="block text-sm font-medium text-gray-700 mb-1">
										Badge Thumbnail
									</label>
									<p className="text-sm text-gray-500">
										Update your badge image by uploading a file or providing a
										URL
									</p>
								</div>

								<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
									{/* Thumbnail Type Selection */}
									<div className="relative">
										<div className="absolute -left-4 top-4 w-1 h-12 bg-primary rounded-r-full opacity-75"></div>
										<FormSelectField
											name="thumbnailType"
											label="Choose Upload Method"
											placeholder="Select thumbnail type"
											value={thumbnailType}
											options={FILE_UPLOAD_OPTIONS}
											onChange={(e) => setThumbnailType(parseInt(e.target.value))}
											errorMessage={errors?.thumbnailType}
											className="shadow-sm hover:shadow transition-shadow duration-300"
										/>
									</div>

									{/* Upload Section with Enhanced UI */}
									<div className="relative">
										{thumbnailType ===
										BADGE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
											<div className="space-y-2">
												<FormAttachmentField
													label="Thumbnail Image"
													name="thumbnail"
													placeholder="Choose an image"
													errorText={errors && errors.thumbnail}
													attachmentID={thumbnailAttachmentID}
													setAttachmentID={setThumbnailAttachmentID}
													attachmentFilename={thumbnailAttachmentName}
													setAttachmentFilename={setThumbnailAttachmentName}
													helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
													validationText="Maximum size: 10MB"
													disabled={false}
													acceptedFileTypes="image/*"
												/>
												{existingThumbnailURL && !thumbnailURL && (
													<div className="mt-2">
														<p className="text-sm text-gray-500 mb-2">
															Current Thumbnail:
														</p>
														<img
															src={existingThumbnailURL}
															alt="Current thumbnail"
															className="w-48 h-auto rounded-lg border border-gray-200"
														/>
													</div>
												)}
											</div>
										) : (
											<FormInputField
												label="Thumbnail URL"
												name="thumbnailURL"
												type="url"
												placeholder="Enter thumbnail URL"
												value={thumbnailURL}
												errorText={errors?.thumbnailUrl}
												onChange={(e) => {
													setThumbnailURL(e.target.value);
													setThumbnailAttachmentID("");
													setThumbnailAttachmentName("");
												}}
												helpText="Enter the URL of the thumbnail image"
											/>
										)}
									</div>
								</div>

								{/* Enhanced Preview Section */}
								{(existingThumbnailURL || thumbnailURL) && (
									<div className="mt-6 p-6 bg-gray-50 rounded-xl border border-gray-100 animate-fade-in">
										<h4 className="text-sm font-medium text-gray-700 mb-4 flex items-center">
											<FontAwesomeIcon
												icon={faEye}
												className="mr-2 text-primary"
											/>
											Preview
										</h4>
										<div className="relative w-32 h-32 mx-auto group">
											<div className="absolute inset-0 bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 group-hover:scale-105">
												<img
													src={thumbnailURL || existingThumbnailURL}
													alt="Badge Preview"
													className="w-full h-full object-cover object-center"
													onError={(e) => {
														e.target.src = "/placeholder-badge.png";
														e.target.classList.add("opacity-50");
													}}
												/>
											</div>
											<div className="absolute -inset-0.5 bg-gradient-to-r from-primary to-primary-dark rounded-lg blur opacity-20 group-hover:opacity-30 transition-opacity duration-300"></div>
										</div>
									</div>
								)}
							</div>

							{/* Description with Enhanced UI */}
							<FormTextareaField
								label="Description"
								name="description"
								placeholder="Enter badge description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
								errorMessage={errors?.description}
								rows={5}
								className="transition-all duration-300 focus:shadow-md"
							/>

							{/* Submit Button with Enhanced UI */}
							<div className="flex justify-end pt-6 border-t border-gray-100">
								<button
									onClick={onSubmitClick}
									disabled={isFetching}
									className={`
                    inline-flex items-center px-6 py-3 rounded-lg text-white
                    ${
											isFetching
												? "bg-gray-400 cursor-not-allowed"
												: "bg-primary hover:bg-primary-dark transform hover:scale-105"
										}
                    transition-all duration-300 shadow-md hover:shadow-lg
                  `}>
									<FontAwesomeIcon
										icon={faCheckCircle}
										className={`mr-2 ${isFetching ? "animate-spin" : ""}`}
									/>
									{isFetching ? "Updating..." : "Update Badge"}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminBadgeUpdate;
