import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faImage,
	faPlus,
	faArrowLeft,
	faGauge,
	faVideoCamera,
	faInfoCircle,
	faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import FormAttachmentField from "../../Reusable/FormAttachmentField";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormSelectField from "../../Reusable/FormSelectField";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	TIPS_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
	TIPS_VIDEO_TYPE_YOUTUBE,
	TIPS_VIDEO_TYPE_VIMEO,
	TIPS_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	TIPS_THUMBNAIL_TYPE_EXTERNAL_URL,
	TIPS_STATUS_ACTIVE,
} from "../../../Constants/App";
import {postTipCreateAPI} from "../../../API/tips";
import {
	TIPS_STATUS_WITH_EMPTY_OPTIONS,
	TIPS_VIDEO_TYPE_WITH_EMPTY_OPTIONS,
	FILE_UPLOAD_OPTIONS_WITH_EMPTY,
} from "../../../Constants/FieldOptions";
import Layout from "../../Menu/Layout";

function AdminTipAdd() {
	const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
	const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [thumbnailType, setThumbnailType] = useState(
		TIPS_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [thumbnailURL, setThumbnailURL] = useState("");
	const [thumbnailAttachmentID, setThumbnailAttachmentID] = useState("");
	const [thumbnailAttachmentName, setThumbnailAttachmentName] = useState("");

	const [videoType, setVideoType] = useState(
		TIPS_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
	);
	const [videoURL, setVideoURL] = useState("");
	const [videoAttachmentID, setVideoAttachmentID] = useState("");
	const [videoAttachmentName, setVideoAttachmentName] = useState("");
	const [videoAttachment, setVideoAttachment] = useState(null);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [status, setStatus] = useState(0);

	// Breadcrumb items
	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Tips", link: "/admin/tips", icon: faVideoCamera},
		{label: "New", icon: faPlus},
	];

	////
	//// Event handling.
	////

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		setFetching(true);
		setErrors({});
		postTipCreateAPI(
			{
				video_type: parseInt(videoType),
				video_upload: videoAttachmentID,
				video_url: videoURL,
				thumbnail_type: parseInt(thumbnailType),
				thumbnail_upload: thumbnailAttachmentID,
				thumbnail_url: thumbnailURL,
				name: name,
				description: description,
				status: status,
			},
			onAddSuccess,
			onAddError,
			onAddDone
		);
		console.log("onSubmitClick: Finished.");
	};

	function onAddSuccess(response) {
		setTopAlertMessage("Tip created successfully");
		setTopAlertStatus("success");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setForceURL("/admin/tips/");
	}

	function onAddError(apiErr) {
		setErrors(apiErr);
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAddDone() {
		setFetching(false);
	}

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
		}

		return () => {
			mounted = false;
		};
	}, []);

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50 py-8">
				<div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
					{/* Page Header */}
					<div className="mb-8">
						<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
							<div className="flex-1">
								<h1 className="text-2xl font-bold text-gray-900 flex items-center gap-3">
									<FontAwesomeIcon
										icon={faLightbulb}
										className="text-primary h-6 w-6"
									/>
									Create New Tip
								</h1>
								<p className="mt-2 text-sm text-gray-600">
									Add a new training tip to share with your community
								</p>
							</div>
							<Link
								to="/admin/tips"
								className="mt-4 sm:mt-0 inline-flex items-center px-4 py-2 border border-gray-300 
                         shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white 
                         hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-primary 
                         transition-all duration-300">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to Tips
							</Link>
						</div>
					</div>

					{/* Main Content */}
					<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
						{errors && <FormErrorBox errors={errors} />}

						{isFetching ? (
							<PageLoadingContent />
						) : (
							<form
								onSubmit={onSubmitClick}
								className="divide-y divide-gray-200">
								{/* Thumbnail Section */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faImage}
												className="text-primary"
											/>
											Thumbnail
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
										<div className="space-y-4">
											<FormSelectField
												label="Thumbnail Type"
												name="thumbnailType"
												value={thumbnailType}
												options={FILE_UPLOAD_OPTIONS_WITH_EMPTY}  // This is correct
												onChange={(e) => {
													const newType = parseInt(e.target.value);
													console.log(newType)
													setThumbnailType(newType);
													// Clear relevant fields when switching types
													if (
														newType ===
														TIPS_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE
													) {
														setThumbnailURL("");
													} else {
														setThumbnailAttachmentID("");
														setThumbnailAttachmentName("");
													}
												}}
												errorText={errors?.thumbnailType}
											/>
											<div className="text-xs text-gray-500 flex items-start gap-1">
												<FontAwesomeIcon
													icon={faInfoCircle}
													className="mt-0.5"
												/>
												<span>Choose how you want to add your thumbnail</span>
											</div>
										</div>

										<div className="space-y-4">
										{thumbnailType ===
								TIPS_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<FormAttachmentField
										label="Thumbnail Image"
										name="thumbnail"
										placeholder="Choose an image"
										errorText={errors && errors.thumbnail}
										attachmentID={thumbnailAttachmentID}
										setAttachmentID={setThumbnailAttachmentID}
										attachmentFilename={thumbnailAttachmentName}
										setAttachmentFilename={setThumbnailAttachmentName}
										helpText="Upload a thumbnail image (PNG, JPG, JPEG)"
										validationText="Maximum size: 10MB"
										disabled={false}
										acceptedFileTypes="image/*"
									/>
								) : (
									<FormInputField
										label="Thumbnail URL"
										name="thumbnailURL"
										type="url"
										placeholder="Enter thumbnail URL"
										value={thumbnailURL}
										errorText={errors?.thumbnailUrl}
										onChange={(e) => {
											setThumbnailURL(e.target.value);
											setThumbnailAttachmentID("");
											setThumbnailAttachmentName("");
										}}
										helpText="Enter the URL of the thumbnail image"
									/>
								)}
										</div>
									</div>
								</div>

								{/* Basic Info Section */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900">
											Basic Information
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 gap-6">
										<FormInputField
											label="Name"
											name="name"
											placeholder="Enter a clear, descriptive name"
											value={name}
											onChange={(e) => setName(e.target.value)}
											className="w-full"
										/>
										<FormTextareaField
											label="Description"
											name="description"
											placeholder="Provide detailed instructions and tips"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={5}
											className="w-full"
										/>
										<FormSelectField
											label="Status"
											name="status"
											value={status}  // Changed from selectedValue to value
											onChange={(e) => setStatus(parseInt(e.target.value))}
											options={TIPS_STATUS_WITH_EMPTY_OPTIONS}
											className="w-full"
										/>
									</div>
								</div>

								{/* Video Section */}
								<div className="p-6 space-y-6">
									<div className="flex items-center justify-between">
										<h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
											<FontAwesomeIcon
												icon={faVideoCamera}
												className="text-primary"
											/>
											Video Content
										</h3>
										<span className="text-xs text-gray-500">Required</span>
									</div>

									<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
										<div className="space-y-4">
											<FormSelectField
												label="Video Type"
												name="videoType"
												value={videoType}
												options={TIPS_VIDEO_TYPE_WITH_EMPTY_OPTIONS}  // Changed from FILE_UPLOAD_OPTIONS_WITH_EMPTY
												onChange={(e) => {
													const newType = parseInt(e.target.value);
													setVideoType(newType);
													if (
														newType ===
														TIPS_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE
													) {
														setVideoURL("");
													} else {
														setVideoAttachmentID("");
														setVideoAttachmentName("");
														setVideoAttachment(null);
													}
												}}
												errorText={errors?.videoType}
											/>
											<div className="text-xs text-gray-500 flex items-start gap-1">
												<FontAwesomeIcon
													icon={faInfoCircle}
													className="mt-0.5"
												/>
												<span>Choose your preferred video source</span>
											</div>
										</div>

										<div>
										{videoType ===
								TIPS_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE ? (
									<FormAttachmentField
										label="Video File"
										name="video"
										placeholder="Choose a video file"
										errorText={errors && errors.video}
										attachmentID={videoAttachmentID}
										setAttachmentID={setVideoAttachmentID}
										attachmentFilename={videoAttachmentName}
										setAttachmentFilename={setVideoAttachmentName}
										helpText="Upload a video file (MP4, MOV, etc.)"
										validationText="Maximum size: 500MB"
										disabled={false}
										acceptedFileTypes="video/*"
									/>
								) : (
									<FormInputField
										label="Video URL"
										name="videoURL"
										type="url"
										placeholder={`Enter ${
											videoType === TIPS_VIDEO_TYPE_YOUTUBE
												? "YouTube"
												: "Vimeo"
										} URL`}
										value={videoURL}
										errorText={errors?.videoUrl}
										onChange={(e) => {
											setVideoURL(e.target.value);
											setVideoAttachmentID("");
											setVideoAttachmentName("");
											setVideoAttachment(null);
										}}
										helpText={`Enter the ${
											videoType === TIPS_VIDEO_TYPE_YOUTUBE
												? "YouTube"
												: "Vimeo"
										} video URL`}
									/>
								)}
							</div>
										</div>
									</div>

								{/* Action Buttons */}
								<div className="px-6 py-4 bg-gray-50 flex flex-col sm:flex-row justify-between gap-3">
									<Link
										to="/admin/tips"
										className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 
                             shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white 
                             hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
                             transition-all duration-300">
										Cancel
									</Link>
									<button
										type="submit"
										className="inline-flex justify-center items-center px-6 py-2 border border-transparent 
                             shadow-sm text-sm font-medium rounded-lg text-white bg-primary 
                             hover:bg-primary-dark focus:ring-2 focus:ring-offset-2 focus:ring-primary 
                             transition-all duration-300">
										<FontAwesomeIcon icon={faPlus} className="mr-2" />
										Create Tip
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminTipAdd;
