import PageLoadingContent from "../PageLoadingContent";
import { PAGE_SIZE_OPTIONS } from "../../../Constants/FieldOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faChevronLeft, 
  faChevronRight,
  faLayerGroup,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import FormErrorBox from "../FormErrorBox";

const ListWithFilters = ({
    title,
    subtitle,
    filterContent,
    children,
    loading,
    error,
    EmptyStateComponent,
    showFilters = true,
    // Pagination props
    pageSize,
    setPageSize,
    onPreviousClicked,
    onNextClicked,
    previousCursors,
    hasNextPage,
    totalItems, // Optional: for showing total items count
  }) => {
    return (
      <div>
        {/* Header */}
        
  
        {/* Main Content */}
        <div className="mx-auto sm:px-6 lg:px-8">
        <div className="m-8">
          {title}
        </div>
          <div className={`${showFilters ? 'flex flex-col md:flex-row gap-6' : ''}`}>
            {/* Filters */}
            {showFilters && (
              <div className="md:w-1/5 flex-shrink-0">
                {filterContent}
              </div>
            )}
  
            {/* Content Area */}
            <div className={`flex-grow ${showFilters ? 'md:w-4/5' : 'w-full'}`}>
              {loading ? (
                <PageLoadingContent displayMessage="Please wait..." />
              ) : error ? (
                <FormErrorBox errors={error} />
              ) : children ? (
                <>
                  {children}
                  
                  {/* Enhanced Pagination Controls */}
                  <div className="mt-6 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <div className="px-4 py-3 sm:px-6">
                      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                        {/* Page Size Selector with Label */}
                        <div className="flex items-center space-x-2 w-full sm:w-auto">
                          <FontAwesomeIcon 
                            icon={faLayerGroup} 
                            className="text-gray-400"
                          />
                          <select
                            className="form-select block w-full sm:w-auto pl-3 pr-10 py-2 text-sm text-gray-700 
                                     border-gray-300 rounded-md focus:outline-none focus:ring-2 
                                     focus:ring-primary/20 focus:border-primary transition-all duration-200
                                     bg-white shadow-sm"
                            value={pageSize}
                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                          >
                            {PAGE_SIZE_OPTIONS.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          
                          {totalItems && (
                            <span className="text-sm text-gray-500">
                              of {totalItems} items
                            </span>
                          )}
                        </div>

                        {/* Navigation Controls */}
                        <div className="flex items-center space-x-2">
                          <nav className="relative z-0 inline-flex shadow-sm rounded-md">
                            <button
                              onClick={onPreviousClicked}
                              disabled={!previousCursors?.length}
                              className={`relative inline-flex items-center px-4 py-2 rounded-l-md
                                       border text-sm font-medium
                                       ${previousCursors?.length 
                                         ? 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300' 
                                         : 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200'
                                       } transition-colors duration-200`}
                            >
                              <FontAwesomeIcon 
                                icon={faChevronLeft} 
                                className="mr-2 h-4 w-4"
                              />
                              Previous
                            </button>
                            <button
                              onClick={onNextClicked}
                              disabled={!hasNextPage}
                              className={`relative inline-flex items-center px-4 py-2 rounded-r-md
                                       border-t border-r border-b text-sm font-medium
                                       ${hasNextPage 
                                         ? 'bg-white text-gray-700 hover:bg-gray-50 border-gray-300' 
                                         : 'bg-gray-100 text-gray-400 cursor-not-allowed border-gray-200'
                                       } transition-colors duration-200`}
                            >
                              Next
                              <FontAwesomeIcon 
                                icon={faChevronRight} 
                                className="ml-2 h-4 w-4"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                EmptyStateComponent
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ListWithFilters;