import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faCheckCircle,
  faPencil,
  faCalendar,
  faClock,
  faGauge,
  faRibbon,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import { deleteBadgeAPI, getBadgeDetailAPI } from "../../../API/badge";
import Layout from "../../Menu/Layout";
import { BADGE_STATUS_MAP } from "../../../Constants/FieldOptions";
import Modal from "../../Reusable/Modal";


function AdminBadgeDetail() {
  //// URL Parameters
  const { id } = useParams();

  //// Global state
  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  //// Component states
  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [badge, setBadge] = useState({});
  const [selectedBadgeForDeletion, setSelectedBadgeForDeletion] =
    useState(null);

  // Breadcrumb items
  const breadcrumbItems = [
    { label: "Dashboard", link: "/admin/dashboard", icon: faGauge },
    { label: "Badges", link: "/admin/badges", icon: faRibbon },
    { label: "Detail", icon: faEye },
  ];

  //// Event handling
  const onDeleteConfirmButtonClick = () => {
    deleteBadgeAPI(
      selectedBadgeForDeletion.id,
      onBadgeDeleteSuccess,
      onBadgeDeleteError,
      onBadgeDeleteDone
    );
    setSelectedBadgeForDeletion(null);
  };

  //// API
  const onBadgeDetailSuccess = (response) => {
    setBadge(response);
  };

  const onBadgeDetailError = (apiErr) => {
    setErrors(apiErr);
    Scroll.animateScroll.scrollToTop();
  };

  const onBadgeDetailDone = () => {
    setFetching(false);
  };

  const onBadgeDeleteSuccess = () => {
    setTopAlertStatus("success");
    setTopAlertMessage("Badge deleted");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    setForceURL("/admin/badges");
  };

  const onBadgeDeleteError = (apiErr) => {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed to delete badge");
    setTimeout(() => {
      setTopAlertMessage("");
    }, 2000);
    Scroll.animateScroll.scrollToTop();
  };

  const onBadgeDeleteDone = () => {
    setFetching(false);
  };

  //// Misc
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on mount
    setFetching(true);
    getBadgeDetailAPI(
      id,
      onBadgeDetailSuccess,
      onBadgeDetailError,
      onBadgeDetailDone
    );
  }, [id]);

  //// Component rendering
  if (forceURL) {
    return <Navigate to={forceURL} />;
  }

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="container mx-auto px-4 py-8 max-w-container">
        {/* Header Section - Using ListHeader component style */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 animate-fade-in">
          <div className="mb-4 md:mb-0">
            <h1 className="text-2xl font-bold text-textDark flex items-center">
              <div className="w-10 h-10 bg-primary/10 rounded-xl flex items-center justify-center mr-4">
                <FontAwesomeIcon icon={faRibbon} className="text-primary text-xl" />
              </div>
              Badge Details
            </h1>
          </div>
          <div className="flex flex-wrap items-center gap-3">
            <Link
              to={`/admin/badges/${id}/edit`}
              className="inline-flex items-center px-4 py-2 bg-white text-primary hover:text-primary-dark rounded-lg border border-gray-200 hover:border-primary transition-all duration-300 shadow-sm"
            >
              <FontAwesomeIcon icon={faPencil} className="mr-2" />
              <span>Edit</span>
            </Link>
            <button
              onClick={() => setSelectedBadgeForDeletion(badge)}
              className="inline-flex items-center px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-all duration-300 shadow-sm"
            >
              <FontAwesomeIcon icon={faTrash} className="mr-2" />
              <span>Delete</span>
            </button>
          </div>
        </div>

        <FormErrorBox errors={errors} />

        {isFetching ? (
          <PageLoadingContent displayMessage="Loading badge..." />
        ) : (
<div className="grid gap-8 animate-slide-up">
  {/* Badge Information Card */}
  <div className="bg-white rounded-2xl shadow-lg border border-gray-200 overflow-hidden hover:shadow-xl transition-shadow duration-300">
    <div className="relative">
      {/* Header Accent */}
      <div className="absolute inset-x-0 top-0 h-2 bg-gradient-to-r from-primary via-primary-light to-primary-dark"></div>

      <div className="p-8 space-y-8">
        {/* Badge Preview Section */}
        <div className="flex flex-col md:flex-row items-start md:items-center gap-8 pb-8 border-b border-gray-100">
          {/* Badge Image */}
          <div className="flex-shrink-0">
            {badge.thumbnailUrl || badge.thumbnailObjectUrl ? (
              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-primary to-primary-dark rounded-2xl blur opacity-30 group-hover:opacity-50 transition duration-300"></div>
                <div className="relative w-40 h-40 rounded-2xl overflow-hidden border-2 border-white shadow-lg">
                  <img 
                    src={badge.thumbnailUrl || badge.thumbnailObjectUrl} 
                    alt={badge.name}
                    className="w-full h-full object-cover object-center transform group-hover:scale-105 transition duration-300"
                  />
                </div>
              </div>
            ) : (
              <div className="w-40 h-40 rounded-2xl border-2 border-dashed border-gray-300 flex flex-col items-center justify-center bg-gray-50 text-center p-4">
                <FontAwesomeIcon icon={faRibbon} className="text-gray-400 text-3xl mb-2" />
                <span className="text-sm text-gray-500">No image available</span>
              </div>
            )}
          </div>

          {/* Badge Main Info */}
          <div className="flex-grow space-y-4">
            <div>
              <label className="text-xs font-medium text-gray-500 uppercase tracking-wider">Badge Name</label>
              <h2 className="text-2xl font-bold text-gray-900 mt-1">{badge.name || "N/A"}</h2>
            </div>
            
            <div>
              <label className="text-xs font-medium text-gray-500 uppercase tracking-wider">Criteria</label>
              <div className="mt-1">
                <span className={`
                  inline-flex items-center px-4 py-2 rounded-full text-sm font-medium
                  ${badge.criteria === 1 
                    ? 'bg-green-50 text-green-700 ring-1 ring-green-600/20' 
                    : 'bg-blue-50 text-blue-700 ring-1 ring-blue-600/20'}
                `}>
                  <div className={`w-2 h-2 rounded-full mr-2 ${badge.criteria === 1 ? 'bg-green-500' : 'bg-blue-500'}`}></div>
                  {BADGE_STATUS_MAP[badge.criteria] || "Unknown"}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Description Section */}
        <div className="space-y-3">
          <label className="text-xs font-medium text-gray-500 uppercase tracking-wider">Description</label>
          <div className="bg-gray-50 rounded-xl p-6 border border-gray-100">
            {badge.description ? (
              <p className="text-gray-700 leading-relaxed whitespace-pre-wrap">
                {badge.description}
              </p>
            ) : (
              <p className="text-gray-500 italic">No description provided.</p>
            )}
          </div>
        </div>

        {/* Additional Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6 border-t border-gray-100">
          <div>
            <label className="text-xs font-medium text-gray-500 uppercase tracking-wider">Created</label>
            <p className="mt-1 text-gray-900">
              <FontAwesomeIcon icon={faCalendar} className="text-gray-400 mr-2" />
              {new Date(badge.createdAt).toLocaleDateString()}
            </p>
          </div>
          <div>
            <label className="text-xs font-medium text-gray-500 uppercase tracking-wider">Last Modified</label>
            <p className="mt-1 text-gray-900">
              <FontAwesomeIcon icon={faClock} className="text-gray-400 mr-2" />
              {new Date(badge.modifiedAt).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        )}

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={selectedBadgeForDeletion !== null}
          onClose={() => setSelectedBadgeForDeletion(null)}
          header={
            <div className="flex items-center space-x-2 text-gray-900">
              <FontAwesomeIcon icon={faTrash} className="text-red-500" />
              <span>Confirm Deletion</span>
            </div>
          }
        >
          <div className="space-y-6">
            <p className="text-gray-700">
              Are you sure you want to delete the badge <strong>{selectedBadgeForDeletion?.name}</strong>? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setSelectedBadgeForDeletion(null)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={onDeleteConfirmButtonClick}
                className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors duration-300"
              >
                Delete Badge
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
}

export default AdminBadgeDetail;
