import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faDownload
} from "@fortawesome/free-solid-svg-icons";

import { getMemberDetailAPI } from "../../../API/member";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Navigation from "./Navigation";

function AdminMemberDetailForInvoiceList() {
  const { bid, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);

  ////
  //// API Callbacks
  ////

  function onMemberDetailSuccess(response) {
    setInvoices(response.invoices || []);
    setError(null);
  }

  function onMemberDetailError(apiErr) {
    setError(apiErr.message || "An error occurred while loading invoices");
    console.error("Member Detail API Error:", apiErr);
  }

  function onMemberDetailDone() {
    setIsLoading(false);
  }

  ////
  //// Lifecycle
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setIsLoading(true);
      setError(null);
      getMemberDetailAPI(
        id,
        onMemberDetailSuccess,
        onMemberDetailError,
        onMemberDetailDone
      );
    }

    return () => {
      mounted = false;
    };
  }, [id]);

  ////
  //// Render
  ////

  return (
    <div className="space-y-6">
      
      {isLoading ? (
        <PageLoadingContent displayMessage="Loading invoices..." />
      ) : error ? (
        <div className="p-4 text-red-600 bg-red-50 rounded-lg">
          Error loading invoices: {error}
        </div>
      ) : (
        <>
          <section className="bg-white rounded-2xl shadow-sm border border-primaryColor/5 p-6">
                      <h3 className="text-lg font-bold text-textDark flex items-center">
                        <FontAwesomeIcon icon={faFileInvoice} className="mr-3 text-primaryColor" />
                        Invoices
                      </h3>

          {invoices.length === 0 ? (
        <div className="mt-6 text-center py-12 bg-gray-50 rounded-lg">
          <div className="w-12 h-12 mx-auto rounded-xl bg-main flex items-center justify-center mb-4">
            <FontAwesomeIcon icon={faFileInvoice} className="w-6 h-6 text-white" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">No Invoices</h3>
          <p className="text-sm text-gray-500">
                This member doesn't have any invoices.
              </p>
            </div>
      ) : (
            <div className="mt-6 bg-white shadow rounded-lg">
              <ul className="divide-y divide-gray-200">
                {invoices.map((invoice) => (
                  <li key={invoice.id} className="p-4 hover:bg-gray-50">
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Invoice #{invoice.number}
                        </h3>
                        <p className="text-sm text-gray-500">
                          {new Date(invoice.date).toLocaleDateString()}
                        </p>
                      </div>
                      <div className="flex items-center gap-4">
                        <span className="text-sm font-medium text-gray-900">
                          ${invoice.amount}
                        </span>
                        <button
                          className="text-primary hover:text-primary-dark"
                          onClick={() => window.open(invoice.downloadUrl, '_blank')}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          </section>
        </>
      )}
    </div>
  );
}

export default AdminMemberDetailForInvoiceList;