import React, {useEffect, useState} from "react";
import {postLogoutAPI} from "../../API/gateway";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faSpinner,
	faRightFromBracket,
	faCheckCircle,
	faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

function LogoutRedirector() {
	const [errors, setErrors] = useState({});
	// Set initial status to 'loading'
	const [status, setStatus] = useState("loading");

	function onLogoutSuccess(response) {
		console.log("onLogoutSuccess: Starting...");
		setStatus("success");
		// Wait for success animation before redirecting
		setTimeout(onRedirect, 750);
	}

	function onLogoutError(apiErr) {
		console.log("onLogoutError: Starting...");
		setErrors(apiErr);
		setStatus("error");

		// Scroll to top on error
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onLogoutDone() {
		console.log("onLogoutDone: Starting...");
		// Don't set success here as it might override an error state
	}

	const onRedirect = () => {
		localStorage.clear();
		window.location.href = "/login";
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setTimeout(() => {
				postLogoutAPI(onLogoutSuccess, onLogoutError, onLogoutDone);
			}, 2000);
		}

		return () => (mounted = false);
	}, []);

	return (
		<div className="min-h-screen bg-gradient-to-br from-primary-dark via-primary to-primary-light flex items-center justify-center px-4">
			<div className="max-w-md w-full">
				<div className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all animate-fade-in">
					{/* Header */}
					<div className="p-6 sm:p-8 text-center border-b border-gray-200">
						<div className="mb-4">
							{status === "loading" && (
								<div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 animate-pulse-glow">
									<FontAwesomeIcon
										icon={faSpinner}
										className="w-8 h-8 text-primary animate-spin"
									/>
								</div>
							)}
							{status === "success" && (
								<div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-green-50">
									<FontAwesomeIcon
										icon={faCheckCircle}
										className="w-8 h-8 text-green-500 animate-scale"
									/>
								</div>
							)}
							{status === "error" && (
								<div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-50">
									<FontAwesomeIcon
										icon={faCircleExclamation}
										className="w-8 h-8 text-red-500 animate-bounce-gentle"
									/>
								</div>
							)}
						</div>
						<h2 className="text-2xl font-bold text-gray-900 mb-2">
							{status === "loading" && "Signing Out..."}
							{status === "success" && "Successfully Signed Out"}
							{status === "error" && "Sign Out Error"}
						</h2>
						<p className="text-gray-500">
							{status === "loading" &&
								"Please wait while we securely log you out."}
							{status === "success" && "You have been successfully logged out."}
							{status === "error" && "An error occurred during sign out."}
						</p>
					</div>

					{/* Progress Bar */}
					<div className="relative h-1 bg-gray-100">
						<div className="absolute inset-0 bg-primary animate-expand-width origin-left"></div>
					</div>

					{/* Footer */}
					<div className="p-6 sm:p-8 bg-gray-50">
						<div className="flex items-center justify-between">
							<div className="flex items-center text-sm text-gray-500">
								<FontAwesomeIcon
									icon={faRightFromBracket}
									className="w-4 h-4 mr-2"
								/>
								Redirecting to login...
							</div>
							{errors && Object.keys(errors).length > 0 && (
								<button
									onClick={onRedirect}
									className="px-4 py-2 text-sm font-medium text-primary hover:text-primary-dark transition-colors">
									Redirect Now
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LogoutRedirector;
