import React, {useState, useEffect} from "react";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faDumbbell,
	faFilter,
	faSearch,
	faTimes,
	faFire,
	faChartLine,
	faRocket,
	faHeart,
	faGauge,
	faCalendarAlt,
	faRefresh,
	faLocationDot,
	faUser,
	faUsers,
	faClock,
	faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {
	currentUserState,
	workoutFilterShowState,
	workoutFilterTemporarySearchTextState,
	workoutFilterActualSearchTextState,
	workoutFilterStatusState,
	workoutSortState,
	workoutVisibilityForMember,
	videoCollectionsFilterVideoTypeState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";
import FilterSidebar, {FilterSection} from "../../Reusable/List/FilterSidebar";
import ListWithFilters from "../../Reusable/List/ListWithFilters";
import CardView from "../../Reusable/List/CardView";
import Modal from "../../Reusable/Modal";
import {VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION, WORKOUT_VISIBILITY_WITH_EMPTY_OPTIONS} from "../../../Constants/FieldOptions";
import {Link} from "react-router-dom";
import {getWorkoutListApi} from "../../../API/workout";

function MemberWorkoutList() {
	////
	//// Global state.
	////
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilters, setShowFilters] = useRecoilState(workoutFilterShowState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		workoutFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		workoutFilterActualSearchTextState
	);
	const [status, setStatus] = useRecoilState(workoutFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		videoCollectionsFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(workoutSortState);
	const [visibility, setVisibility] = useRecoilState(
		workoutVisibilityForMember
	);

	////
	//// Component states.
	////
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState("");
	const [currentCursor, setCurrentCursor] = useState("");

	////
	//// API.
	////

	function onWorkoutListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor);
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onWorkoutlistError(apiErr) {
		setErrors(apiErr);
	}

	function onWorkoutListDone() {
		setFetching(false);
	}

	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Workouts",
			icon: faDumbbell,
		},
	];

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, st, sbv, visibility) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		params.set("user_id", currentUser.id);

		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]);
			params.set("sort_order", sortArray[1]);
		}

		if (cur !== "") {
			params.set("cursor", cur);
		}

		params.set("visibility", visibility);

		if (keywords !== undefined && keywords !== null && keywords !== "") {
			params.set("search", keywords);
		}
		if (st !== undefined && st !== null && st !== "") {
			let stArray = [];
			stArray.push(st);
			params.set("status_list", stArray);
			if (st === 2) {
				params.set("exclude_archived", false);
			}
		}

		getWorkoutListApi(
			params,
			onWorkoutListSuccess,
			onWorkoutlistError,
			onWorkoutListDone
		);
	};

	const onNextClicked = () => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onClearFilterClick = () => {
		setShowFilters(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
    setVisibility(0);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setActualSearchText(temporarySearchText);
		}, 500); // 500ms delay

		return () => clearTimeout(timeoutId);
	}, [temporarySearchText, setActualSearchText]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			Scroll.animateScroll.scrollToTop();
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				sort,
				visibility
			);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, sort, visibility]);

	////
	//// Component rendering.
	////

	const ActionButtons = () => (
		<div className="flex items-center space-x-3">
			<button
				onClick={() =>
					fetchList(currentCursor, pageSize, actualSearchText, status, sort, visibility)
				}
				className="inline-flex items-center px-4 py-2 text-sm bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
				<FontAwesomeIcon icon={faRefresh} className="mr-2" />
				<span className="hidden sm:inline">Refresh</span>
			</button>
		</div>
	);

	const filterContent = (
		<FilterSidebar>
			<FilterSection title="Search">
				<div className="relative">
					<input
						type="text"
						placeholder="Search workouts..."
						value={temporarySearchText}
						onChange={(e) => setTemporarySearchText(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								setActualSearchText(temporarySearchText);
							}
						}}
						className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:border-primary focus:ring-2 focus:ring-primary/10 transition-all duration-300"
					/>
					<FontAwesomeIcon
						icon={faSearch}
						className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
					/>
				</div>
			</FilterSection>

			<FilterSection title="Status">
				<select
					value={status}
					onChange={(e) => setStatus(parseInt(e.target.value))}
					className="w-full rounded-lg border border-gray-200 py-2 px-3 focus:border-primary focus:ring-2 focus:ring-primary/10">
					{VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</FilterSection>
      <FilterSection title="Visibility">
				<select
					value={visibility}
					onChange={(e) => setVisibility(parseInt(e.target.value))}
					className="w-full rounded-lg border border-gray-200 py-2 px-3 focus:border-primary focus:ring-2 focus:ring-primary/10">
					{WORKOUT_VISIBILITY_WITH_EMPTY_OPTIONS.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
			</FilterSection>

			<div className="mt-6">
				<button
					onClick={onClearFilterClick}
					className="w-full px-4 py-2 text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-300">
					Clear Filters
				</button>
			</div>
		</FilterSidebar>
	);

	const renderWorkoutCard = (workout) => {
		// Get the first exercise name for the subtitle
		const firstExercise =
			workout.workoutExercises?.[0]?.exerciseName || "No exercises";

		// Calculate total exercises
		const totalExercises = workout.workoutExercises?.length || 0;

		return (
			<div className="h-full flex flex-col border rounded-lg shadow-lg overflow-hidden bg-white">
				<div className="p-4 bg-gradient-to-r from-primary to-primary-light text-white">
					<h2 className="text-2xl font-bold">{workout.name}</h2>
					<p className="text-sm mt-1 text-white">{workout.description}</p>
				</div>

				{/* Info Grid */}
				<div className="p-6 space-y-4">
					{/* Date and Location Row */}
					<div className="flex flex-col space-y-3">
						{/* Date */}
						<div className="flex items-center text-gray-600">
							<FontAwesomeIcon
								icon={faCalendarAlt}
								className="text-gray-400 w-4 h-4 flex-shrink-0"
							/>
							<span className="ml-3 text-sm">
								{new Date(workout.createdAt).toLocaleDateString("en-US", {
									weekday: "short",
									month: "long",
									day: "numeric",
								})}
							</span>
						</div>

						{/* Trainer */}
						<div className="flex items-center text-gray-600">
							<FontAwesomeIcon
								icon={faUser}
								className="text-gray-400 w-4 h-4 flex-shrink-0"
							/>
							<span className="ml-3 text-sm">{workout.createdByUserName}</span>
						</div>
					</div>

					{/* Stats Row */}
					<div className="flex items-center space-x-6 pt-2">
						{/* Duration */}
						<div className="flex items-center text-gray-600">
							<FontAwesomeIcon
								icon={faClock}
								className="text-gray-400 w-4 h-4 flex-shrink-0"
							/>
							<span className="ml-2 text-sm">
								{workout.workoutExerciseTimeInMins || 60} mins
							</span>
						</div>

						{/* Available Exercises */}
						<div className="flex items-center text-gray-600">
							<FontAwesomeIcon
								icon={faDumbbell}
								className="text-gray-400 w-4 h-4 flex-shrink-0"
							/>
							<span className="ml-2 text-sm">{totalExercises} exercises</span>
						</div>
					</div>
				</div>

				{/* View Button */}
				<div className="p-4 bg-gray-50">
					<Link
						to={`/workouts/${workout.id}`}
						className={`w-full py-2.5 sm:py-3 px-4 bg-primary hover:bg-primary-dark text-white font-medium rounded-lg transition-all duration-300 inline-flex items-center justify-center text-sm`}>
						View
					</Link>
				</div>
			</div>
		);
	};

	const EmptyState = () => (
		<div className="text-center py-12">
			<FontAwesomeIcon
				icon={faDumbbell}
				className="text-gray-400 text-4xl mb-4"
			/>
			<h3 className="text-lg font-medium text-gray-900 mb-2">
				No Workouts Found
			</h3>
			<p className="text-gray-500">
				{actualSearchText
					? `No workouts matching "${actualSearchText}"`
					: "Please check back later"}
			</p>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="relative bg-gray-900">
  {/* Decorative Elements */}
  <div className="absolute inset-0">
    <div className="absolute inset-0 bg-[url('/grid-pattern.svg')] opacity-10" />
    <div className="absolute inset-0 bg-gradient-to-br from-primary/10 via-accent1/10 to-primary-light/10" />
    <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-lightShade/25 to-transparent" />
  </div>

  {/* Hero Content */}
  <div className="relative container mx-auto px-6 py-20">
    <div className="max-w-4xl mx-auto text-center">
      {/* Badge */}
      <div className="inline-flex items-center justify-center p-2 mb-6 
                    rounded-2xl bg-primary/10 backdrop-blur-xs 
                    border border-primary/25">
        <span className="px-3 py-1 text-sm font-medium text-lightShade font-secondary">
          Professional Trainer Workouts
        </span>
      </div>

      {/* Heading */}
      <h1 className="text-5xl font-bold tracking-tight mb-6 text-lightShade font-primary">
        Expert-Crafted Training Programs
      </h1>

      {/* Description */}
      <p className="text-xl text-gray-100/90 max-w-2xl mx-auto mb-12 font-secondary">
        Access premium workout routines designed by professional MMA trainers and athletes.
        Each program is carefully curated to deliver maximum results.
      </p>

	  <div className="flex items-center justify-center mb-12">
        <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full 
                      bg-primary/20 border border-primary/30
                      animate-pulse-glow shadow-primary">
          <FontAwesomeIcon icon={faUserTie} className="text-primary" />
          <span className="text-sm font-medium text-lightShade font-secondary">
            Created by Certified MMA Trainers
          </span>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 max-w-3xl mx-auto">
        {[
          {
            icon: faFire,
            label: "Expert-Led",
            value: "Programs",
            color: "from-primary to-primary-light",
            description: "Professional trainer guidance"
          },
          {
            icon: faChartLine,
            label: "Structured",
            value: "Progress",
            color: "from-accent1 to-primary",
            description: "Track your improvement"
          },
          {
            icon: faRocket,
            label: "Professional",
            value: "Techniques",
            color: "from-burntOrange to-primary",
            description: "Learn proper form"
          },
          {
            icon: faHeart,
            label: "Certified",
            value: "Training",
            color: "from-primary-dark to-primary",
            description: "Quality assured content"
          },
        ].map((stat, index) => (
          <div
            key={index}
            className="group p-4 rounded-2xl 
                     bg-primary/10 backdrop-blur-xs border border-primary/25
                     hover:bg-primary/15 transition-colors duration-DEFAULT">
            <div
              className={`
                p-2 rounded-xl bg-gradient-to-br ${stat.color} mb-3 
                w-12 h-12 flex items-center justify-center
                shadow-md
              `}>
              <FontAwesomeIcon
                icon={stat.icon}
                className="text-2xl text-lightShade"
              />
            </div>
            <div className="text-sm font-medium text-gray-100 font-secondary">
              {stat.label}
            </div>
            <div className="text-lg font-semibold text-lightShade font-primary mb-2">
              {stat.value}
            </div>
            <div className="text-xs text-gray-300/80 font-secondary">
              {stat.description}
            </div>
          </div>
        ))}
      </div>

      {/* Additional Context */}
      <div className="mt-12 p-6 rounded-xl 
                    bg-primary/5 border border-primary/25
                    hover:bg-primary/10 transition-colors duration-DEFAULT">
        <h3 className="text-xl font-semibold text-lightShade mb-3 font-primary">
          Why Choose Our Global Workouts?
        </h3>
        <p className="text-gray-100/80 font-secondary">
          Our global workout collection features premium content created by certified MMA trainers. 
          Each workout is professionally designed, validated, and regularly updated to ensure 
          you receive the highest quality training experience.
        </p>
      </div>
    </div>
  </div>
</div>

			<ListWithFilters
				title={
					<div className="w-full flex items-center justify-between gap-4">
						<div className="flex items-center space-x-3 flex-shrink-0">
							<FontAwesomeIcon
								icon={faDumbbell}
								className="text-primary text-xl"
							/>
							<h1 className="text-2xl font-bold">Workouts</h1>
						</div>
						<div className="flex-shrink-0 ml-auto">
							<ActionButtons />
						</div>
					</div>
				}
				filterContent={filterContent}
				loading={isFetching}
				error={typeof errors === "string" ? errors : null}
				EmptyStateComponent={<EmptyState />}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPreviousClicked={onPreviousClicked}
				onNextClicked={onNextClicked}
				previousCursors={previousCursors}
				hasNextPage={listData?.hasNextPage}>
				<CardView
					items={listData?.results}
					renderItem={renderWorkoutCard}
					linkPrefix="/workouts"
					emptyState={<EmptyState />}
				/>
			</ListWithFilters>
		</Layout>
	);
}

export default MemberWorkoutList;
