import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import {
	faImage,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {postAccountAvatarAPI} from "../../../../../API/Account";
import {getAccountDetailAPI} from "../../../../../API/Account";
import FormErrorBox from "../../../../Reusable/FormErrorBox";
import PageLoadingContent from "../../../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
} from "../../../../../AppState";
import Layout from "../../../../Menu/Layout";
import FileUpload from "../../../../Reusable/FileUploader";
import { ACCOUNT_QUERY_KEY } from "../../../../../Hooks/useAccountQuery";

function AdminAccountAvatarOperation() {

	const queryClient = useQueryClient();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [currentUser, setCurrentUser] = useState({});

	////
	//// Event handling.
	////

	// --- Detail --- //

	function onAccountDetailSuccess(response) {
		console.log("onAccountDetailSuccess: Starting...");
		setCurrentUser(response);
	}

	function onAccountDetailError(apiErr) {
		console.log("onAccountDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onAccountDetailDone() {
		console.log("onAccountDetailDone: Starting...");
		setFetching(false);
	}

	// --- Avatar Upload --- //

	const onHandleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const onSubmitClick = (e) => {
		console.log("onSubmitClick: Starting...");
		console.log("onSubmitClick: user_id:", currentUser.id);
		setFetching(true);
		setErrors({});

		const formData = new FormData();
		formData.append("user_id", currentUser.id);
		formData.append("file", selectedFile);

		postAccountAvatarAPI(
			formData,
			onOperationSuccess,
			onOperationError,
			onOperationDone
		);
		console.log("onSubmitClick: Finished.");
	};

	////
	//// API.
	////

	// --- Avatar Operation --- //

	async function onOperationSuccess(response) {
		// For debugging purposes only.
		console.log("onOperationSuccess: Starting...");
		console.log(response);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Photo changed");
		setTopAlertStatus("success");

		await queryClient.invalidateQueries({ queryKey: ACCOUNT_QUERY_KEY });

		queryClient.setQueryData(ACCOUNT_QUERY_KEY, (oldData) => ({
			...oldData,
			avatar: response.avatar // Adjust this based on your API response structure
		  }));

		setTimeout(() => {
			console.log("onOperationSuccess: Delayed for 2 seconds.");
			console.log(
				"onOperationSuccess: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);


		// Redirect the user to the user attachments page.
		setForceURL("/account");
	}

	function onOperationError(apiErr) {
		console.log("onOperationError: Starting...");
		setErrors(apiErr);

		// Add a temporary banner message in the app and then clear itself after 2 seconds.
		setTopAlertMessage("Failed submitting");
		setTopAlertStatus("danger");
		setTimeout(() => {
			console.log("onOperationError: Delayed for 2 seconds.");
			console.log(
				"onOperationError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onOperationDone() {
		console.log("onOperationDone: Starting...");
		setFetching(false);
	}

	////
	//// BREADCRUMB
	////
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/dashboard",
		},
		{
			label: "Account",
			icon: faUserCircle,
			link: "/account",
		},
		{
			label: "Avatar",
			icon: faImage,
		},
	];

	// --- Detail --- //

	function onSuccess(response) {
		console.log("onSuccess: Starting...");
		setCurrentUser(response);
	}

	function onError(apiErr) {
		console.log("onError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onDone() {
		console.log("onDone: Starting...");
		setFetching(false);
	}

	const onUnauthorized = () => {
		setForceURL("/login?unauthorized=true"); // If token expired or user is not logged in, redirect back to login.
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			setFetching(true);
			setErrors({});
			setFetching(true);
			getAccountDetailAPI(onSuccess, onError, onDone, onUnauthorized);
		}

		return () => {
			mounted = false;
		};
	}, [,]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
		<div className="bg-backgroundLight rounded-lg shadow p-6">
			{/* Title */}
			<h1 className="text-2xl font-semibold flex items-center mb-6">
				<FontAwesomeIcon className="text-primaryColor" icon={faImage} />
				<span className="ml-2 text-textDark">Change Photo</span>
			</h1>

			<FormErrorBox errors={errors} />

			{isFetching ? (
				<PageLoadingContent displayMessage={"Submitting..."} />
			) : (
				<div className="space-y-6">
					{/* Warning Message */}
					<div className="bg-yellowShade1 bg-opacity-10 border border-yellowShade2 text-textDark px-4 py-3 rounded-lg">
						<strong>Warning:</strong> Submitting with new uploaded file
						will delete previous upload.
					</div>

					{selectedFile ? (
						<div className="bg-greenShade1 bg-opacity-10 border border-greenShade2 text-textDark px-4 py-3 rounded-lg flex items-center">
							<FontAwesomeIcon className="text-greenShade1" icon={faCheckCircle} />
							<span className="ml-2">File ready to upload.</span>
						</div>
					) : (
						<div className="space-y-2">
							<label className="font-medium block text-textDark">
								File (Optional)
							</label>
							<FileUpload
								fileType="Avatar"
								fileName={currentUser?.avatarFileName}
								onFileChange={onHandleFileChange}
							/>
						</div>
					)}

					{/* Action Buttons */}
					<div className="flex flex-col sm:flex-row justify-between gap-4 pt-6">
						<Link 
							to="/account"
							className="inline-flex items-center justify-center px-4 py-2 border border-divider rounded-lg shadow-sm text-sm font-medium text-textDark bg-backgroundLight hover:bg-lightGrayShade focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor transition-colors duration-DEFAULT w-full sm:w-auto"
						>
							<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
							Back to Account
						</Link>
						
						<button
							onClick={onSubmitClick}
							className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-textLight bg-primaryColor hover:bg-darkOrangeShade focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor transition-colors duration-DEFAULT w-full sm:w-auto"
						>
							<FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
							Save
						</button>
					</div>
				</div>
			)}
		</div>
	</Layout>
	);
}

export default AdminAccountAvatarOperation;
