import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faPlus,
	faGauge,
	faArrowRight,
	faTable,
	faBolt,
	faEye,
	faPencilAlt,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import FormErrorBox from "../../Reusable/FormErrorBox";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
	videoCollectionsFilterShowState,
	videoCollectionsFilterTemporarySearchTextState,
	videoCollectionsFilterActualSearchTextState,
	videoCollectionsFilterStatusState,
	videoCollectionsFilterVideoTypeState,
	videoCollectionsFilterSortState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import FormSelectField from "../../Reusable/FormSelectField";
import {
	FITNESS_CHALLENGE_STATUS_MAP,
	VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
} from "../../Reusable/AdminList";
import {
	getfitnessChallengeListApi,
	deletefitnessChallengeAPI,
} from "../../../API/FitnessChallenge";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";

function FitnessChallengeList() {
	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);
	const [showFilter, setShowFilter] = useRecoilState(
		videoCollectionsFilterShowState
	); // Filtering + Searching
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		videoCollectionsFilterTemporarySearchTextState
	); // Searching - The search field value as your writes their query.
	const [actualSearchText, setActualSearchText] = useRecoilState(
		videoCollectionsFilterActualSearchTextState
	); // Searching - The actual search query value to submit to the API.
	const [status, setStatus] = useRecoilState(videoCollectionsFilterStatusState);
	const [videoType, setVideoType] = useRecoilState(
		videoCollectionsFilterVideoTypeState
	);
	const [sort, setSort] = useRecoilState(videoCollectionsFilterSortState);
	const [challengeToDelete, setChallengeToDelete] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState("");
	const [isFetching, setFetching] = useState(false);
	const [pageSize, setPageSize] = useState(10); // Pagination
	const [previousCursors, setPreviousCursors] = useState([]); // Pagination
	const [nextCursor, setNextCursor] = useState(""); // Pagination
	const [currentCursor, setCurrentCursor] = useState(""); // Pagination
	const [category, setcategory] = useState("");

	// Breadcrumb items
	const breadcrumbItems = [
		{
			label: "Dashboard",
			icon: faGauge,
			link: "/admin/dashboard",
		},
		{
			label: "Challenges",
			icon: faBolt,
		},
	];

	////
	//// API.
	////

	function onWorkoutListSuccess(response) {
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onWorkoutlistError(apiErr) {
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onWorkoutListDOne() {
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, st, vt, sbv) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		params.set("type", vt);

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}
		if (st !== undefined && st !== null && st !== "" && st !== 0) {
			let stArray = new Array();
			stArray.push(st);
			params.set("status_list", stArray);
			if (st === 2) {
				params.set("exclude_archived", false);
			}
		}
		if (category !== 0) {
			params.set("category", category);
		}

		getfitnessChallengeListApi(
			params,
			onWorkoutListSuccess,
			onWorkoutlistError,
			onWorkoutListDOne
		);
	};

	const onNextClicked = (e) => {
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		setActualSearchText(temporarySearchText);
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setVideoType(0);
		setStatus(0);
		setSort("created,-1");
		setcategory("");
	};

	const onDeleteClick = (challenge) => {
		setChallengeToDelete(challenge);
		setShowDeleteModal(true);
	};

	const onDeleteConfirm = () => {
		if (!challengeToDelete) return;

		setFetching(true);
		setErrors({});

		deletefitnessChallengeAPI(
			challengeToDelete.id,
			onDeleteSuccess,
			onDeleteError,
			onDeleteDone
		);
	};

	const onDeleteCancel = () => {
		setShowDeleteModal(false);
		setChallengeToDelete(null);
	};

	const onDeleteSuccess = (response) => {
		setTopAlertMessage("Fitness Challenge deleted successfully");
		setTopAlertStatus("success");
		onDeleteCancel();
		fetchList(currentCursor, pageSize, actualSearchText, status);
	};

	const onDeleteError = (apiErr) => {
		console.error("Delete error:", apiErr);
		setErrors(apiErr);
		onDeleteCancel();
	};

	const onDeleteDone = () => {
		setFetching(false);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(
				currentCursor,
				pageSize,
				actualSearchText,
				status,
				videoType,
				sort
			);
		}

		return () => {
			mounted = false;
		};
	}, [
		currentCursor,
		pageSize,
		actualSearchText,
		status,
		videoType,
		sort,
		category,
	]);

	////
	//// Component rendering.
	////

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50 py-8">
				<div className="mx-auto px-4 sm:px-6 lg:px-8">
					{/* Main Content Container */}
					<div className="space-y-6">
						{/* Header Section */}
						<ListHeader
							title="Fitness Challenges"
							icon={faBolt}
							onRefresh={() =>
								fetchList(currentCursor, pageSize, actualSearchText, status)
							}
							onToggleFilter={() => setShowFilter(!showFilter)}
							addLink="/admin/fitness-challenge/add"
							addLabel="New Challenge"
						/>

						{/* Filter Section */}
						{showFilter && (
							<ListFilter onClear={onClearFilterClick}>
								<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
									<FormInputFieldWithButton
										label="Search Challenges"
										name="search"
										placeholder="Enter challenge name..."
										value={temporarySearchText}
										onChange={(e) => setTemporarySearchText(e.target.value)}
										onButtonClick={onSearchButtonClick}
										buttonLabel="Search"
									/>
									<FormSelectField
										label="Status Filter"
										name="status"
										value={status}
										options={VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION}
										onChange={(e) => setStatus(e.target.value)}
									/>
								</div>
							</ListFilter>
						)}

						{/* Content Section */}
						<div className="bg-white rounded-lg shadow-sm overflow-hidden">
							{isFetching ? (
								<div className="p-8">
									<PageLoadingContent message="Loading challenges..." />
								</div>
							) : (
								<>
									{listData?.results?.length > 0 ? (
										<>
											{/* Desktop View */}
											<div className="hidden md:block">
												<DataListDesktop
													headers={[
														{label: "Challenge Name", className: "w-2/5"},
														{label: "Status", className: "w-1/5"},
														{label: "Actions", className: "w-2/5"},
													]}
													data={listData.results}
													renderRow={(challenge) => (
														<tr
															key={challenge.id}
															className="hover:bg-gray-50 transition-colors">
															<td className="px-6 py-4">
																<div className="flex items-center">
																	<div className="h-10 w-10 flex-shrink-0 rounded-lg bg-primary/10 flex items-center justify-center">
																		<FontAwesomeIcon
																			icon={faBolt}
																			className="text-primary"
																		/>
																	</div>
																	<div className="ml-4">
																		<div className="font-medium text-gray-900">
																			{challenge.name}
																		</div>
																		<div className="text-sm text-gray-500">
																			ID: {challenge.id}
																		</div>
																	</div>
																</div>
															</td>
															<td className="px-6 py-4">
																<span
																	className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
																		FITNESS_CHALLENGE_STATUS_MAP[
																			challenge.status
																		] === "Active"
																			? "bg-green-100 text-green-800"
																			: FITNESS_CHALLENGE_STATUS_MAP[
																					challenge.status
																			  ] === "Archived"
																			? "bg-gray-100 text-gray-800"
																			: "bg-yellow-100 text-yellow-800"
																	}`}>
																	{FITNESS_CHALLENGE_STATUS_MAP[
																		challenge.status
																	] || challenge.status}
																</span>
															</td>
															<td className="px-6 py-4">
																<div className="flex items-center space-x-3">
																	<Link
																		to={`/admin/fitness-challenge/${challenge.id}`}
																		className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
																		<FontAwesomeIcon
																			icon={faEye}
																			className="mr-2 h-4 w-4"
																		/>
																		View
																	</Link>
																	<Link
																		to={`/admin/fitness-challenge/${challenge.id}/update`}
																		className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
																		<FontAwesomeIcon
																			icon={faPencilAlt}
																			className="mr-2 h-4 w-4"
																		/>
																		Edit
																	</Link>
																	<button
																		onClick={() => onDeleteClick(challenge)}
																		className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
																		<FontAwesomeIcon
																			icon={faTrashAlt}
																			className="mr-2 h-4 w-4"
																		/>
																		Delete
																	</button>
																</div>
															</td>
														</tr>
													)}
													pageSize={pageSize}
													setPageSize={setPageSize}
													previousCursors={previousCursors}
													hasNextPage={listData.hasNextPage}
													onPreviousClicked={onPreviousClicked}
													onNextClicked={onNextClicked}
												/>
											</div>

											{/* Mobile View */}
											<div className="md:hidden">
												<DataListMobile
													data={listData.results}
													renderItem={(challenge) => (
														<div
															key={challenge.id}
															className="p-4 border-b border-gray-200 last:border-b-0">
															<div className="flex items-center space-x-4 mb-4">
																<div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center">
																	<FontAwesomeIcon
																		icon={faBolt}
																		className="text-primary h-6 w-6"
																	/>
																</div>
																<div>
																	<h3 className="text-lg font-medium text-gray-900">
																		{challenge.name}
																	</h3>
																	<span
																		className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mt-1
                                    ${
																			challenge.status === "active"
																				? "bg-green-100 text-green-800"
																				: challenge.status === "inactive"
																				? "bg-red-100 text-red-800"
																				: "bg-yellow-100 text-yellow-800"
																		}`}>
																		{challenge.status}
																	</span>
																</div>
															</div>
															<div className="grid grid-cols-3 gap-2">
																<Link
																	to={`/admin/fitness-challenge/${challenge.id}`}
																	className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																	<FontAwesomeIcon
																		icon={faEye}
																		className="h-4 w-4"
																	/>
																	<span className="ml-2">View</span>
																</Link>
																<Link
																	to={`/admin/fitness-challenge/${challenge.id}/update`}
																	className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																	<FontAwesomeIcon
																		icon={faPencilAlt}
																		className="h-4 w-4"
																	/>
																	<span className="ml-2">Edit</span>
																</Link>
																<button
																	onClick={() => onDeleteClick(challenge)}
																	className="inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700">
																	<FontAwesomeIcon
																		icon={faTrashAlt}
																		className="h-4 w-4"
																	/>
																	<span className="ml-2">Delete</span>
																</button>
															</div>
														</div>
													)}
													pageSize={pageSize}
													setPageSize={setPageSize}
													previousCursors={previousCursors}
													hasNextPage={listData.hasNextPage}
													onPreviousClicked={onPreviousClicked}
													onNextClicked={onNextClicked}
												/>
											</div>
										</>
									) : (
										<div className="text-center py-12">
											<div className="h-24 w-24 rounded-full bg-primary/10 flex items-center justify-center mx-auto mb-4">
												<FontAwesomeIcon
													icon={faTable}
													className="h-12 w-12 text-primary"
												/>
											</div>
											<h3 className="text-xl font-medium text-gray-900 mb-2">
												No Challenges Found
											</h3>
											<p className="text-gray-500 mb-6">
												Get started by creating your first fitness challenge.
											</p>
											<Link
												to="/admin/fitness-challenge/add"
												className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
												<FontAwesomeIcon
													icon={faPlus}
													className="mr-2 h-4 w-4"
												/>
												Create Challenge
											</Link>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				{/* Delete Modal */}
				<Modal
					isOpen={showDeleteModal}
					onClose={onDeleteCancel}
					header="Delete Challenge">
					<div className="space-y-4">
						<div className="flex items-center space-x-4">
							<div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
								<FontAwesomeIcon
									icon={faTrashAlt}
									className="h-6 w-6 text-red-600"
								/>
							</div>
							<div>
								<h4 className="text-lg font-medium text-gray-900">
									Confirm Deletion
								</h4>
								<p className="text-sm text-gray-500">
									This action cannot be undone.
								</p>
							</div>
						</div>

						<p className="text-gray-600">
							Are you sure you want to delete "
							<span className="font-medium">{challengeToDelete?.name}</span>"?
						</p>

						<div className="flex justify-end space-x-3">
							<button
								onClick={onDeleteCancel}
								className="px-4 py-2 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
								disabled={isFetching}>
								Cancel
							</button>
							<button
								onClick={onDeleteConfirm}
								className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
								disabled={isFetching}>
								{isFetching ? (
									<span className="inline-flex items-center">
										<svg
											className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
											fill="none"
											viewBox="0 0 24 24">
											<circle
												className="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												strokeWidth="4"
											/>
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
										Deleting...
									</span>
								) : (
									"Delete Challenge"
								)}
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default FitnessChallengeList;
