import React, { useState, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowLeft,
  faEye,
  faPencil,
  faGauge,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import { getTipDetailAPI, deleteTipAPI } from "../../../API/tips";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import {
  EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_VIDEO_TYPE_SIMPLE_STORAGE_SERVICE,
  EXERCISE_VIDEO_TYPE_VIMEO,
  EXERCISE_VIDEO_TYPE_YOUTUBE,
} from "../../../Constants/App";
import YouTubeVideo from "../../Reusable/YoutubePlayer";
import { getVimeoVideoId } from "../../Reusable/ExerciseDisplay";
import Modal from "../../Reusable/Modal";
import VideoPlayer from "../../Reusable/VideoPlayer";
import Layout from "../../Menu/Layout";

function AdminTipDetail() {
  const { id } = useParams();

  const [, setTopAlertMessage] = useRecoilState(topAlertMessageState);
  const [, setTopAlertStatus] = useRecoilState(topAlertStatusState);
  const [currentUser] = useRecoilState(currentUserState);

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(false);
  const [forceURL, setForceURL] = useState("");
  const [tip, setTip] = useState({});
  const [selectedTipForDeletion, setSelectedTipForDeletion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [thumbnailURL, setThumbnailURL] = useState("");


  const onDeleteConfirmButtonClick = () => {
    deleteTipAPI(
      selectedTipForDeletion.id,
      onTipDeleteSuccess,
      onTipDeleteError,
      onTipDeleteDone
    );
    setSelectedTipForDeletion(null);
  };

  // --- API Call Handlers --- //
  function onTipDetailSuccess(response) {
    setTip(response);
  }

  function onTipDetailError(apiErr) {
    setErrors(apiErr);
    window.scrollTo(0, 0);
  }

  function onTipDetailDone() {
    setFetching(false);
  }

  function onTipDeleteSuccess(response) {
    setTopAlertStatus("success");
    setTopAlertMessage("Tip deleted");
    setTimeout(() => setTopAlertMessage(""), 2000);
    setForceURL("/admin/tips");
  }

  function onTipDeleteError(apiErr) {
    setErrors(apiErr);
    setTopAlertStatus("danger");
    setTopAlertMessage("Failed deleting");
    setTimeout(() => setTopAlertMessage(""), 2000);
  }

  function onTipDeleteDone() {
    setFetching(false);
  }

  useEffect(() => {
    setFetching(true);
    getTipDetailAPI(id, onTipDetailSuccess, onTipDetailError, onTipDetailDone);
  }, [id]);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  const breadcrumbItems = [
    {label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
    {label: "Tips", link: "/admin/tips", icon: faLightbulb},
    {label: "Detail", icon: faEye},
  ];

  return (
    <Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 animate-fade-in">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200">
          {/* Content */}
          <div className="p-6">
            {errors && <FormErrorBox errors={errors} />}
            
            {isFetching ? (
              <PageLoadingContent />
            ) : tip ? (
              <div className="space-y-8">
                {/* Header */}
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                  <h1 className="text-2xl font-bold text-gray-900">
                    {tip.name}
                  </h1>
                  <div className="flex flex-wrap gap-2">
                    <Link
                      to={`/admin/tips/${id}/update`}
                      className="inline-flex items-center px-4 py-2 bg-white border border-gray-300 
                               rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 
                               transition-colors duration-300"
                    >
                      <FontAwesomeIcon icon={faPencil} className="mr-2" />
                      Edit
                    </Link>
                    <button
                      onClick={() => setIsModalOpen(true)}
                      className="inline-flex items-center px-4 py-2 bg-red-600 text-white 
                               rounded-lg text-sm font-medium hover:bg-red-700 
                               transition-colors duration-300"
                    >
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                      Delete
                    </button>
                  </div>
                </div>

                {/* Video Player */}
                <VideoPlayer
                  name={tip.name}
                  videoType={tip.videoType}
                  videoUrl={tip.videoUrl || tip.videoObjectUrl}
                  thumbnailUrl={tip.thumbnailUrl || tip.thumbnailObjectUrl}
                  description={tip.description}
                  // metadata={{
                  //   Status: tip.status,
                  //   Created: new Date(tip.createdAt).toLocaleDateString(),
                  //   Updated: new Date(tip.modifiedAt).toLocaleDateString(),
                  // }}
                  showRelated={false}
                  showActions={false}
                />
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-500">No tip found</p>
              </div>
            )}
          </div>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          header="Confirm Deletion"
        >
          <div className="space-y-4">
            <p className="text-gray-600">
              Are you sure you want to delete this tip? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-gray-700 hover:bg-gray-100 
                         rounded-lg transition-colors duration-300"
              >
                Cancel
              </button>
              <button
                onClick={onDeleteConfirmButtonClick}
                className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 
                         rounded-lg transition-colors duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
}

export default AdminTipDetail;
