import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faDumbbell,
	faEye,
	faPencil,
	faTrashCan,
	faPlus,
	faGauge,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
	TableCell,
} from "../../Reusable/AdminList";
import FormErrorBox from "../../Reusable/FormErrorBox";
import Modal from "../../Reusable/Modal";
import FormInputFieldWithButton from "../../Reusable/FormInputFieldWithButton";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	PAGE_SIZE_OPTIONS,
	VIDEO_COLLECTION_STATUS_MAP,
} from "../../../Constants/FieldOptions";
import {
	getVideoCategoryListAPI,
	deleteVideoCategoryAPI,
} from "../../../API/VideoCategory";
import {
	topAlertMessageState,
	topAlertStatusState,
	videoCategoryFilterShowState,
	videoCategoryFilterSortState,
	videoCategoryFilterStatusState,
	videoCategoryFilterTemporarySearchTextState,
	videoCategoryFilterActualSearchTextState,
	currentUserState,
} from "../../../AppState";
import Layout from "../../Menu/Layout";

function AdminVideoCategoryList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	const [showFilter, setShowFilter] = useRecoilState(
		videoCategoryFilterShowState
	);
	const [sort, setSort] = useRecoilState(videoCategoryFilterSortState);
	const [status, setStatus] = useRecoilState(videoCategoryFilterStatusState);
	const [temporarySearchText, setTemporarySearchText] = useRecoilState(
		videoCategoryFilterTemporarySearchTextState
	);
	const [actualSearchText, setActualSearchText] = useRecoilState(
		videoCategoryFilterActualSearchTextState
	);

	// Local state
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [listData, setListData] = useState({results: []});
	const [
		selectedVideoCategoryForDeletion,
		setSelectedVideoCategoryForDeletion,
	] = useState(null);
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [currentCursor, setCurrentCursor] = useState("");
	const [nextCursor, setNextCursor] = useState("");

	////
	//// API.
	////

	function onVideoCategoryListSuccess(response) {
		console.log("onVideoCategoryListSuccess: Starting...");
		if (response.results !== null) {
			setListData(response);
			if (response.hasNextPage) {
				setNextCursor(response.nextCursor); // For pagination purposes.
			}
		} else {
			setListData([]);
			setNextCursor("");
		}
	}

	function onVideoCategoryListError(apiErr) {
		console.log("onVideoCategoryListError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCategoryListDone() {
		console.log("onVideoCategoryListDone: Starting...");
		setFetching(false);
	}

	function onVideoCategoryDeleteSuccess(response) {
		console.log("onVideoCategoryDeleteSuccess: Starting...");

		// First close the modal
		setSelectedVideoCategoryForDeletion(null);

		// Update notification
		setTopAlertStatus("success");
		setTopAlertMessage("Video category deleted successfully");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Fetch updated list
		fetchList(currentCursor, pageSize, actualSearchText, status, sort);
	}

	function onVideoCategoryDeleteError(apiErr) {
		console.log("onVideoCategoryDeleteError: Starting...");
		setErrors(apiErr);
		setFetching(false); // Reset loading state

		// Update notification
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed to delete video category");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Keep modal open on error
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCategoryDeleteDone() {
		console.log("onVideoCategoryDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// Event handling.
	////

	const fetchList = (cur, limit, keywords, stat, sbv) => {
		setFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit); // Pagination

		// DEVELOPERS NOTE: Our `sortByValue` is string with the sort field
		// and sort order combined with a comma seperation. Therefore we
		// need to split as follows.
		if (sbv !== undefined && sbv !== null && sbv !== "") {
			const sortArray = sbv.split(",");
			params.set("sort_field", sortArray[0]); // Sort (1 of 2)
			params.set("sort_order", sortArray[1]); // Sort (2 of 2)
		}

		if (cur !== "") {
			// Pagination
			params.set("cursor", cur);
		}

		// Filtering
		if (keywords !== undefined && keywords !== null && keywords !== "") {
			// Searhcing
			params.set("search", keywords);
		}

		params.set("status", stat);

		getVideoCategoryListAPI(
			params,
			onVideoCategoryListSuccess,
			onVideoCategoryListError,
			onVideoCategoryListDone
		);
	};

	const onNextClicked = (e) => {
		console.log("onNextClicked");
		let arr = [...previousCursors];
		arr.push(currentCursor);
		setPreviousCursors(arr);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = (e) => {
		console.log("onPreviousClicked");
		let arr = [...previousCursors];
		const previousCursor = arr.pop();
		setPreviousCursors(arr);
		setCurrentCursor(previousCursor);
	};

	const onSearchButtonClick = (e) => {
		// Searching
		console.log("Search button clicked...");
		setActualSearchText(temporarySearchText);
	};

	const onSelectVideoCategoryForDeletion = (e, datum) => {
		console.log("onSelectVideoCategoryForDeletion", datum);
		setSelectedVideoCategoryForDeletion(datum);
	};

	const onDeselectVideoCategoryForDeletion = (e) => {
		console.log("onDeselectVideoCategoryForDeletion");
		setSelectedVideoCategoryForDeletion("");
	};

	const onDeleteConfirmButtonClick = (e) => {
		console.log("onDeleteConfirmButtonClick");
		setFetching(true); // Show loading state

		deleteVideoCategoryAPI(
			selectedVideoCategoryForDeletion.id,
			onVideoCategoryDeleteSuccess,
			onVideoCategoryDeleteError,
			onVideoCategoryDeleteDone
		);
	};

	// Function resets the filter state to its default state.
	const onClearFilterClick = (e) => {
		setShowFilter(false);
		setActualSearchText("");
		setTemporarySearchText("");
		setSort("no,1");
		setStatus(0);
	};

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.
			fetchList(currentCursor, pageSize, actualSearchText, status, sort);
		}

		return () => {
			mounted = false;
		};
	}, [currentCursor, pageSize, actualSearchText, status, sort]);

	// Initial fetch
	useEffect(() => {
		fetchList("", pageSize, actualSearchText, status, sort);
	}, []);

	const tableHeaders = [
		{label: "Name", className: "w-1/4"},
		{label: "Status", className: "w-1/6"},
		{label: "Actions", className: "w-1/6"},
	];

	const renderDesktopRow = (item, index) => (
		<tr
			key={item.id}
			className="hover:bg-gray-50 transition-colors duration-200">
			<TableCell>{item.name}</TableCell>
			<TableCell>
				<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
					{VIDEO_COLLECTION_STATUS_MAP[item.status]}
				</span>
			</TableCell>
			<TableCell>
				<div className="flex items-center space-x-2">
					<Link
						to={`/admin/video-category/${item.id}`}
						className="text-gray-600 hover:text-primary transition-colors p-1">
						<FontAwesomeIcon icon={faEye} className="w-4 h-4" />
					</Link>
					<Link
						to={`/admin/video-category/${item.id}/update`}
						className="text-gray-600 hover:text-primary transition-colors p-1">
						<FontAwesomeIcon icon={faPencil} className="w-4 h-4" />
					</Link>
					<button
						onClick={() => setSelectedVideoCategoryForDeletion(item)}
						className="text-gray-600 hover:text-red-500 transition-colors p-1">
						<FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
					</button>
				</div>
			</TableCell>
		</tr>
	);

	const renderMobileItem = (item, index) => (
		<div
			key={item.id}
			className="bg-white rounded-lg shadow-sm border border-gray-200 p-4">
			<div className="flex justify-between items-start mb-3">
				<div>
					<h3 className="font-medium text-gray-900">{item.name}</h3>
					<p className="text-sm text-gray-600 mt-1">{item.description}</p>
				</div>
				<span
					className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${
											item.status === "active"
												? "bg-green-100 text-green-800"
												: "bg-gray-100 text-gray-800"
										}`}>
					{item.status}
				</span>
			</div>
			<div className="flex justify-end space-x-3">
				<Link
					to={`/admin/video-categories/${item.id}`}
					className="text-sm text-primary hover:text-primary-dark transition-colors">
					View
				</Link>
				<Link
					to={`/admin/video-category/${item.id}/update`}
					className="text-sm text-primary hover:text-primary-dark transition-colors">
					Edit
				</Link>
				<button
					onClick={() => setSelectedVideoCategoryForDeletion(item)}
					className="text-sm text-red-500 hover:text-red-600 transition-colors">
					Delete
				</button>
			</div>
		</div>
	);

	const breadcrumb = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Video Categories", icon: faDumbbell},
	];

	return (
		<Layout breadcrumb={breadcrumb} currentUser={currentUser}>
			<div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<ListHeader
					title="Video Categories"
					icon={faDumbbell}
					onRefresh={() =>
						fetchList(currentCursor, pageSize, actualSearchText, status, sort)
					}
					onToggleFilter={() => setShowFilter(!showFilter)}
					addLink="/admin/video-categories/add"
					addLabel="Add"
				/>

				{showFilter && (
					<ListFilter onClear={onClearFilterClick}>
						<FormInputFieldWithButton
							label="Search Categories"
							name="temporarySearchText"
							type="text"
							placeholder="Search by name or description"
							value={temporarySearchText}
							onChange={(e) => setTemporarySearchText(e.target.value)}
							onButtonClick={onSearchButtonClick}
						/>
					</ListFilter>
				)}

				{/* Delete Confirmation Modal */}
				<Modal
					isOpen={selectedVideoCategoryForDeletion !== null}
					onClose={() => setSelectedVideoCategoryForDeletion(null)}
					header="Confirm Deletion">
					<div className="space-y-4">
						<p className="text-gray-600">
							Are you sure you want to delete this video category? This action
							cannot be undone.
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={() => setSelectedVideoCategoryForDeletion(null)}
								disabled={isFetching}
								className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 
                         transition-colors duration-300">
								Cancel
							</button>
							<button
								onClick={onDeleteConfirmButtonClick}
								disabled={isFetching}
								className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 
                         transition-colors duration-300 flex items-center">
								{isFetching ? (
									<>
										<svg
											className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
											fill="none"
											viewBox="0 0 24 24">
											<circle
												className="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												strokeWidth="4"
											/>
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
											/>
										</svg>
										Deleting...
									</>
								) : (
									"Delete"
								)}
							</button>
						</div>
					</div>
				</Modal>

				{isFetching ? (
					<PageLoadingContent displayMessage={"Loading categories..."} />
				) : listData?.results?.length > 0 || previousCursors.length > 0 ? (
					<>
						<FormErrorBox errors={errors} />

						{/* Desktop View */}
						<div className="hidden md:block">
							<DataListDesktop
								headers={tableHeaders}
								data={listData.results || []}
								renderRow={renderDesktopRow}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData.hasNextPage}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>

						{/* Mobile View */}
						<div className="md:hidden">
							<DataListMobile
								data={listData.results || []}
								renderItem={renderMobileItem}
								pageSize={pageSize}
								setPageSize={setPageSize}
								previousCursors={previousCursors}
								hasNextPage={listData.hasNextPage}
								onPreviousClicked={onPreviousClicked}
								onNextClicked={onNextClicked}
							/>
						</div>
					</>
				) : (
					<div className="bg-white rounded-xl shadow-sm border border-gray-200 p-8">
						<div className="text-center">
							{/* Empty state icon */}
							<div className="mx-auto h-12 w-12 rounded-full bg-primary/10 flex items-center justify-center mb-4">
								<FontAwesomeIcon
									icon={faDumbbell}
									className="h-6 w-6 text-primary"
								/>
							</div>

							{/* Empty state heading */}
							<h3 className="text-lg font-medium text-gray-900 mb-2">
								No Video Categories Found
							</h3>

							{/* Empty state description */}
							<p className="text-gray-500 mb-6 mx-auto">
								{showFilter && temporarySearchText
									? "No categories match your search criteria. Try adjusting your filters or search terms."
									: "Get started by creating your first video category. Categories help organize your video content."}
							</p>

							{/* Action buttons */}
							<div className="flex flex-col sm:flex-row gap-3 justify-center">
								{showFilter && temporarySearchText && (
									<button
										onClick={onClearFilterClick}
										className="inline-flex items-center justify-center px-4 py-2 
                                             border border-gray-300 rounded-lg text-sm font-medium 
                                             text-gray-700 bg-white hover:bg-gray-50 
                                             transition-colors duration-300">
										Clear Filters
									</button>
								)}

								<Link
									to="/admin/video-categories/add"
									className="inline-flex items-center justify-center px-4 py-2 
                                         border border-transparent rounded-lg text-sm font-medium 
                                         text-white bg-primary hover:bg-primary-dark 
                                         transition-colors duration-300">
									<FontAwesomeIcon icon={faPlus} className="mr-2" />
									Add First Category
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
}

export default AdminVideoCategoryList;
