import React from "react";

function DataDisplayRowSelectStatic({
	label = "",
	selectedValue = 0,
	options,
	helpText = "",
}) {
	console.log(selectedValue);
	return (
		<div className="mb-6">
			<label className="block text-sm font-medium text-gray-700 mb-2">
				{label}
			</label>
			<div className="mt-1">
				<div className="text-gray-900">
					{options.find((option) => option.value === selectedValue)?.label || (
						<span className="text-gray-500">-</span>
					)}
				</div>
				{helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
			</div>
		</div>
	);
}

export default DataDisplayRowSelectStatic;
