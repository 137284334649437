import { useState, useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import Scroll from "react-scroll";
import { useRecoilState } from "recoil";
import { useQueryClient } from '@tanstack/react-query';

import { postLoginAPI } from "../../API/gateway";
import PageLoadingContent from "../Reusable/PageLoadingContent";
import { onHamburgerClickedState, currentUserState } from "../../AppState";
import LoginDesktop from "./LoginDesktop";
import LoginMobile from "./LoginMobile";
import { ACCOUNT_QUERY_KEY, useAccountQuery } from "../../Hooks/useAccountQuery";
import { getAccountDetailAPIUsingPromise } from "../../API/Account";

function Login() {
  
  const queryClient = useQueryClient();
  const { refetch: refetchAccount } = useAccountQuery();

  const [searchParams] = useSearchParams();
  const isUnauthorized = searchParams.get("unauthorized");

  // Global state
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  // Component states
  const [errors, setErrors] = useState({});
  const [validation, setValidation] = useState({
    email: false,
    password: false,
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forceURL, setForceURL] = useState("");
  const [isFetching, setFetching] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  async function onLoginSuccess(response) {
    if (!response.user) {
      alert("user null error with login endpoint - please contact system administrator.");
      return;
    }

    try {
      // Save user data to Recoil state
      setCurrentUser(response.user);

      // Invalidate any existing account queries
      await queryClient.invalidateQueries({ queryKey: ACCOUNT_QUERY_KEY });

      // Fetch fresh account data
      await refetchAccount();

      // Redirect based on user role
      switch (response.user.role) {
        case 1:
          alert("unsupported user role detected - executive (`root`) user accounts are not supported at this time.");
          break;
        case 2:
          setForceURL("/admin/dashboard");
          break;
        case 3:
          setForceURL("/admin/dashboard");
          break;
        case 4:
          if (!response.user.onboardingCompleted) {
            setForceURL("/onboarding");
          } else if (response.user.isVisitorPassHolder) {
            setForceURL("/dashboard");
          } else if (response.user.isSubscriber) {
            setForceURL("/dashboard");
          } else if (response.user.waiverVersion == undefined) {
            setForceURL("/dashboard");
          } else {
            setForceURL("/subscriptions");
          }
          break;
        case 5:
          setForceURL("/admin/dashboard");
          break;
        default:
          alert("onLoginSuccess: unsupported user role detected - please contact system administrator.");
      }
    } catch (error) {
      console.error("Error during login success:", error);
      alert("Error fetching account details. Please try again or contact support.");
    }
  }

  function onLoginError(apiErr) {
    console.log("onLoginError:", onLoginError);

    // Ensure we're setting a plain object with string values
    const displayErrors = {
      ...(apiErr.auth && { auth: apiErr.auth }),
      ...(apiErr.error && { general: apiErr.error }),
      // Add any other specific error fields you want to handle
    };

    setErrors(displayErrors);

    var scroll = Scroll.animateScroll;
    scroll.scrollToTop();
  }

  function onLoginDone() {
    setFetching(false);
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
    setValidation(prev => ({ ...prev, email: false }));
  }

  function onPasswordChange(e) {
    setPassword(e.target.value);
    setValidation(prev => ({ ...prev, password: false }));
  }

  function onButtonClick(e) {
    console.log("onButtonClick: Starting");
    const newErrors = {};
    const newValidation = {};

    if (!email) {
      newErrors.email = "value is missing";
    } else {
      newValidation.email = true;
    }
    if (!password) {
      newErrors.password = "value is missing";
    } else {
      newValidation.password = true;
    }

    setErrors(newErrors);
    setValidation(newValidation);

    if (Object.keys(newErrors).length > 0) {
      var scroll = Scroll.animateScroll;
      scroll.scrollToTop();
    } else {
      setFetching(true);
      const data = { email: email, password: password, rememberMe: rememberMe };
      postLoginAPI(
        data,
        onLoginSuccess,
        onLoginError,
        onLoginDone
      );
    }
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0);
      document.body.classList.add("login-mobile-dark-bg");
    }

    return () => {
      mounted = false;
      document.body.classList.remove("login-mobile-dark-bg");
    };
  }, []);

  if (forceURL !== "") {
    return <Navigate to={forceURL} />;
  }

  return (
    <div className="min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url('/static/black-background-geometric-gradient-design.jpg')`,
      }}>
      {isFetching ? (
        <PageLoadingContent displayMessage={"Please wait..."} />
      ) : (
        <div className="p-6">
          <div className="flex justify-center items-center">
            <div className="container">
              <div className="flex justify-center">
                <div>
                  {/* Mobile Login */}
                  <div className="md:hidden">
                    <LoginMobile
                      errors={errors}
                      isUnauthorized={isUnauthorized}
                      validation={validation}
                      email={email}
                      onEmailChange={onEmailChange}
                      password={password}
                      onPasswordChange={onPasswordChange}
                      onButtonClick={onButtonClick}
                      rememberMe={rememberMe}
                      setRememberMe={setRememberMe}
                    />
                  </div>

                  {/* Desktop Login */}
                  <div className="hidden md:block w-full">
                    <LoginDesktop
                      errors={errors}
                      isUnauthorized={isUnauthorized}
                      validation={validation}
                      email={email}
                      onEmailChange={onEmailChange}
                      password={password}
                      onPasswordChange={onPasswordChange}
                      onButtonClick={onButtonClick}
                      rememberMe={rememberMe}
                      setRememberMe={setRememberMe}
                    />
                  </div>

                  {/* Help Section */}
                  <div className="mt-8 text-center">
                    <h3 className="text-lightShade font-medium mb-4">Need help?</h3>
                    <div className="space-y-2">
                      <div>
                        <span className="text-lightShade">London: </span>
                        <a href="mailto:info@MMA_fitness.com" className="text-main hover:text-main/50">
                          info@MMA_fitness.com
                        </a>
                      </div>
                      <div>
                        <span className="text-lightShade">Worldwide: </span>
                        <a href="mailto:info.tt@MMA_fitness.com" className="text-main hover:text-main/50">
                          info.tt@MMA_fitness.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
