import React from "react";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faInfoCircle,
	faArrowLeft,
	faArrowUpRightFromSquare,
	faGauge,
	faHandHolding,
	faPlus,
	faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {currentUserState} from "../../../AppState";
import Layout from "../../Menu/Layout";

function AdminOfferAdd() {
	const [currentUser] = useRecoilState(currentUserState);

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Offers", link: "/admin/offers", icon: faHandHolding},
		{label: "Add", icon: faPlus},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
				<div className="bg-white rounded-xl shadow-lg overflow-hidden">
					{/* Header Section */}
					<div className="bg-gradient-to-r from-primary to-primary-dark p-6 text-white">
						<div className="flex items-center space-x-3">
							<FontAwesomeIcon icon={faInfoCircle} className="h-6 w-6" />
							<h2 className="text-xl font-semibold">
								How to Add an Offer via Stripe
							</h2>
						</div>
					</div>

					<div className="p-6 lg:p-8 space-y-8">
						{/* Introduction */}
						<div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-r-lg">
							<p className="text-blue-700">
								Please follow these steps to add an offer into the system.
							</p>
						</div>

						{/* Steps */}
						<ol className="relative space-y-6">
							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									1
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium">
										Log into{" "}
										<Link
											to="https://stripe.com"
											target="_blank"
											rel="noreferrer"
											className="inline-flex items-center text-primary hover:text-primary-dark 
                             transition-colors duration-300 group">
											Stripe, Inc.
											<FontAwesomeIcon
												icon={faArrowUpRightFromSquare}
												className="ml-1 h-4 w-4 transform group-hover:translate-x-0.5 
                               group-hover:-translate-y-0.5 transition-transform duration-300"
											/>
										</Link>
									</p>
								</div>
							</li>

							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									2
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium">Go to "Products"</p>
								</div>
							</li>

							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									3
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium">
										Click "Add Product"
									</p>
								</div>
							</li>

							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									4
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium mb-3">
										Fill in the form with the following details:
									</p>
									<ul className="bg-gray-50 rounded-lg p-4 space-y-3">
										{[
											"Name",
											"Description",
											"Image (only upload one)",
											'Metadata (key="OrganizationID" value="648763d3f6fbead15f5bd4d2")',
											"Recurring: Yes",
											"Monthly billing period",
											"Price",
										].map((item, index) => (
											<li key={index} className="flex items-center space-x-2">
												<FontAwesomeIcon
													icon={faCheckCircle}
													className="h-4 w-4 text-green-500"
												/>
												<span className="text-gray-600">{item}</span>
											</li>
										))}
									</ul>
								</div>
							</li>

							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									5
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium">
										Click "Save product"
									</p>
								</div>
							</li>

							<li className="flex items-start space-x-4">
								<div className="flex-shrink-0 w-8 h-8 bg-primary text-white rounded-full flex items-center justify-center font-semibold">
									6
								</div>
								<div className="flex-grow pt-1">
									<p className="text-gray-700 font-medium">
										Inside this web-app, go back to the offers list, reload and
										you should see the new offer automatically populated.
									</p>
								</div>
							</li>
						</ol>

						{/* Footer */}
						<div className="flex justify-between items-center pt-6 mt-8 border-t border-gray-200">
							<Link
								to="/admin/offers"
								className="inline-flex items-center px-6 py-3 border border-gray-300 
                       text-sm font-medium rounded-lg bg-white text-gray-700 
                       hover:bg-gray-50 hover:border-primary hover:text-primary
                       focus:outline-none focus:ring-2 focus:ring-offset-2 
                       focus:ring-primary transition-all duration-300
                       shadow-sm hover:shadow">
								<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
								Back to Offers
							</Link>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default AdminOfferAdd;
