import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Scroll from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLock, faEllipsis, faXmark, faCartShopping, faArrowRight, faFileInvoiceDollar, faTasks, faTachometer, faKey, faArrowLeft, faCheckCircle, faUserCircle, faGauge, faPencil, faIdCard, faAddressBook, faContactCard, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState } from 'recoil';

import { getAccountDetailAPI } from "../../../API/Account";
import { postSubscriptionCancelAPI } from "../../../API/PaymentProcessor";
import { getMemberDetailAPI } from "../../../API/member";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import { topAlertMessageState, topAlertStatusState, currentUserState } from "../../../AppState";
// import Footer from "../Menu/Footer";
// import PricingTable from "../Reusable/Pricing";
import { PAY_FREQUENCY } from "../../../Constants/FieldOptions";
import Modal from "../../Reusable/Modal";

const CancelSubscriptionModal = ({ show, onClose, onConfirm, subscriptionInterval }) => (
    <Modal
        isOpen={show}
        onClose={onClose}
        header="Are you sure?"
    >
        <div className="space-y-4">
            <p className="text-gray-600">
                You are about to <span className="font-semibold">cancel</span> your{" "}
                {subscriptionInterval && <span>{`${subscriptionInterval}ly`}</span>}{" "}
                subscription. This action cannot be reversed.
            </p>
            
            <div className="flex justify-end space-x-3">
                <button
                    onClick={onClose}
                    className="btn-secondary"
                >
                    Keep Subscription
                </button>
                <button
                    onClick={onConfirm}
                    className="btn-danger"
                >
                    Cancel Subscription
                </button>
            </div>
        </div>
    </Modal>
);

const PricingCard = ({ 
    title, 
    price, 
    period, 
    description, 
    imageUrl, 
    isCurrentPlan,
    onCancelClick 
}) => (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden relative">
        {isCurrentPlan && (
            <div className="absolute top-0 left-0 right-0 bg-primary text-white 
                          text-xs font-semibold py-2 text-center uppercase">
                Current Plan
            </div>
        )}
        
        {imageUrl && (
            <div className="relative aspect-[4/5] overflow-hidden">
                <img 
                    src={imageUrl} 
                    alt={title}
                    className="w-full h-full object-cover"
                />
            </div>
        )}

        <div className="p-6 space-y-6">
            <PricingCardContent 
                title={title}
                price={price}
                period={period}
                description={description}
            />
            <PricingCardActions onCancelClick={onCancelClick} />
        </div>
    </div>
);

const PricingCardContent = ({ title, price, period, description }) => (
    <>
        <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
            <div className="flex items-baseline justify-center gap-1">
                <span className="text-4xl font-bold text-primary">${price}</span>
                {period && <span className="text-gray-500">/ {period}</span>}
            </div>
        </div>
        {description && <p className="text-gray-600 text-center">{description}</p>}
    </>
);

const PricingCardActions = ({ onCancelClick }) => (
    <div className="flex flex-col sm:flex-row gap-3 pt-4">
        <Link 
            to="/account/subscription/invoices"
            className="btn-secondary flex-1 flex items-center justify-center"
        >
            <FontAwesomeIcon icon={faCartShopping} className="mr-2" />
            View Past Invoices
        </Link>
        <button
            onClick={onCancelClick}
            className="btn-danger flex-1 flex items-center justify-center"
        >
            <FontAwesomeIcon icon={faXmark} className="mr-2" />
            Cancel Subscription
        </button>
    </div>
);

const NoSubscriptionBanner = () => (
    <div className="bg-gray-50 rounded-xl p-6 md:p-8">
        <div className="max-w-3xl mx-auto text-center">
            <FontAwesomeIcon 
                icon={faFileInvoiceDollar} 
                className="text-gray-400 text-3xl mb-4"
            />
            <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-3">
                No Subscription Plan
            </h2>
            <p className="text-gray-600 mb-6">
                No subscription enrollment.{" "}
                <Link 
                    to="/account/more/subscribe"
                    className="text-primary hover:text-primary-dark font-medium inline-flex items-center"
                >
                    Click here to get started
                    <FontAwesomeIcon icon={faArrowRight} className="ml-1" />
                </Link>
            </p>
        </div>
    </div>
);

const SubscriptionContent = ({ isSubscriber, stripeSubscription, setShowModal }) => {
    if (!isSubscriber) {
        return <NoSubscriptionBanner />;
    }

    if (!stripeSubscription) {
        return null;
    }

    const { offerPurchase } = stripeSubscription;

    if (offerPurchase) {
        return (
            <div className="max-w-2xl mx-auto">
                <PricingCard
                    title={offerPurchase.offerName}
                    price={offerPurchase.offerPrice}
                    period={offerPurchase.offerPayFrequency === PAY_FREQUENCY[1].value ? 'month' : 'year'}
                    description={offerPurchase.offerDescription}
                    imageUrl="https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg"
                    isCurrentPlan={true}
                    onCancelClick={() => setShowModal(true)}
                />
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <p className="text-gray-600">
                You are subscribed to our{" "}
                <span className="font-medium">
                    {stripeSubscription.interval === 'year' ? "annual" : "monthly"}
                </span>{" "}
                premium plan.
            </p>
            <PricingCardActions onCancelClick={() => setShowModal(true)} />
        </div>
    );
};

function AccountSubscriptionDetailAndCancel() {
    const [topAlertMessage, setTopAlertMessage] = useRecoilState(topAlertMessageState);
    const [topAlertStatus, setTopAlertStatus] = useRecoilState(topAlertStatusState);
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [errors, setErrors] = useState({});
    const [isFetching, setFetching] = useState(false);
    const [forceURL, setForceURL] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleSubscriptionCancel = () => {
        setFetching(true);
        setShowModal(false);
        setErrors({});

        postSubscriptionCancelAPI(
            "",
            handleSubscriptionCancelSuccess,
            handleSubscriptionCancelError,
            handleSubscriptionCancelDone
        );
    };

    const handleSubscriptionCancelSuccess = (response) => {
        setTopAlertStatus("success");
        setTopAlertMessage("Subscription successfully cancelled");
        getMemberDetailAPI(
            currentUser.id,
            response => setCurrentUser(response),
            setErrors,
            () => setFetching(false)
        );
        setTimeout(() => setTopAlertMessage(""), 2000);
    };

    const handleSubscriptionCancelError = (apiErr) => {
        setTopAlertStatus("danger");
        setTopAlertMessage("Failed to cancel subscription");
        setTimeout(() => setTopAlertMessage(""), 2000);
        setErrors(apiErr);
        Scroll.animateScroll.scrollToTop();
    };

    const handleSubscriptionCancelDone = () => {
        setFetching(false);
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            window.scrollTo(0, 0);
            setFetching(true);
            setErrors({});
            getAccountDetailAPI(
                response => setCurrentUser(response),
                setErrors,
                () => setFetching(false)
            );
        }

        return () => { mounted = false; }
    }, []);

    if (forceURL) return <Navigate to={forceURL} />;

    const { isSubscriber, stripeSubscription } = currentUser;

    return (
        <div className="container mx-auto px-4 py-8">
            <CancelSubscriptionModal 
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleSubscriptionCancel}
                subscriptionInterval={stripeSubscription?.interval}
            />
            
            <SubscriptionContent 
                isSubscriber={isSubscriber}
                stripeSubscription={stripeSubscription}
                setShowModal={setShowModal}
            />
        </div>
    );
}

export default AccountSubscriptionDetailAndCancel;