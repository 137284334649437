import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faLeaf,
	faFire,
	faClock,
	faUtensils,
	faAppleAlt,
	faListCheck,
	faCalendar,
	faChartLine,
	faCircleCheck,
	faBowlFood,
	faDownload,
	faPrint,
	faShare,
	faInfoCircle,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faBullseye,
	faWeightScale,
	faDroplet,
} from "@fortawesome/free-solid-svg-icons";

const NutritionPlanFormatter = ({data}) => {
	const [expandedMeal, setExpandedMeal] = useState(null);
	const [showTips, setShowTips] = useState(true);
	const [selectedDay, setSelectedDay] = useState(0);
	const [checkedItems, setCheckedItems] = useState({});

	if (!data) return <EmptyState />;

	const {groceryList, caloricTargets, instructions, intermittentFasting} = data;

	const dailyCalories = caloricTargets?.[0]?.calories || "2000";
	const mealCount = caloricTargets?.[0]?.meals?.[0]?.mealPlans?.length || 0;
	const totalDays = caloricTargets?.[0]?.meals?.length || 0;

	const handlePrint = () => {
		window.print();
	};

	const toggleMealExpansion = (mealIndex) => {
		setExpandedMeal(expandedMeal === mealIndex ? null : mealIndex);
	};

	const toggleCheckedItem = (category, item) => {
		setCheckedItems((prev) => ({
			...prev,
			[`${category}-${item}`]: !prev[`${category}-${item}`],
		}));
	};

	return (
		<div className="mx-auto p-4 sm:p-6 lg:p-8 bg-gray-50">
			{/* Hero Section with Quick Actions */}
			<div className="relative overflow-hidden bg-gradient-to-r from-primary to-primary-dark rounded-2xl shadow-xl mb-8">
				<div className="absolute inset-0 bg-grid-white/[0.1]" />
				<div className="relative p-8 sm:p-10 lg:p-12">
					<div className="flex flex-col lg:flex-row justify-between items-start gap-6">
						<div>
							<h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-white mb-4">
								Your Personalized Nutrition Plan
							</h1>
							<p className="text-lightShade text-base sm:text-lg max-w-3xl">
								{instructions}
							</p>
						</div>
						{/* <div className="flex gap-2">
							<button
								onClick={handlePrint}
								className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-all flex items-center gap-2">
								<FontAwesomeIcon icon={faPrint} className="w-5 h-5" />
								<span className="hidden sm:inline">Print</span>
							</button>
							<button className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-all flex items-center gap-2">
								<FontAwesomeIcon icon={faDownload} className="w-5 h-5" />
								<span className="hidden sm:inline">Download</span>
							</button>
							<button className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg transition-all flex items-center gap-2">
								<FontAwesomeIcon icon={faShare} className="w-5 h-5" />
								<span className="hidden sm:inline">Share</span>
							</button>
						</div> */}
					</div>
				</div>
			</div>

			{/* Nutrition Tips */}
			{showTips && (
				<div className="bg-blue-50 border border-blue-100 rounded-xl p-4 mb-8">
					<div className="flex items-start justify-between">
						<div className="flex items-start space-x-3">
							<div className="p-2 bg-blue-100 rounded-lg mt-1">
								<FontAwesomeIcon
									icon={faInfoCircle}
									className="text-blue-500 w-5 h-5"
								/>
							</div>
							<div>
								<h3 className="font-semibold text-blue-900 mb-1">
									Nutrition Tips
								</h3>
								<ul className="text-sm text-blue-700 space-y-1">
									<li className="flex items-center space-x-2">
										<FontAwesomeIcon
											icon={faCheckCircle}
											className="w-4 h-4 text-blue-500"
										/>
										<span>Track your meals using the provided schedule</span>
									</li>
									<li className="flex items-center space-x-2">
										<FontAwesomeIcon
											icon={faCheckCircle}
											className="w-4 h-4 text-blue-500"
										/>
										<span>Stay within your calorie targets</span>
									</li>
									<li className="flex items-center space-x-2">
										<FontAwesomeIcon
											icon={faCheckCircle}
											className="w-4 h-4 text-blue-500"
										/>
										<span>Follow the eating window for optimal results</span>
									</li>
									<li className="flex items-center space-x-2">
										<FontAwesomeIcon
											icon={faCheckCircle}
											className="w-4 h-4 text-blue-500"
										/>
										<span>Stay hydrated throughout the day</span>
									</li>
								</ul>
							</div>
						</div>
						<button
							onClick={() => setShowTips(false)}
							className="text-blue-500 hover:text-blue-700">
							×
						</button>
					</div>
				</div>
			)}

			{/* Stats Grid */}
			<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
				<StatsCard
					icon={faFire}
					title="Daily Calories"
					value={dailyCalories}
					subtitle="calories per day"
					iconColor="text-orange-500"
					iconBg="bg-orange-100"
				/>
				<StatsCard
					icon={faBowlFood}
					title="Daily Meals"
					value={mealCount}
					subtitle="meals per day"
					iconColor="text-green-500"
					iconBg="bg-green-100"
				/>
				<StatsCard
					icon={faClock}
					title="Eating Window"
					value={
						intermittentFasting?.match(
							/\d{1,2}\s*PM\s*to\s*\d{1,2}\s*PM/i
						)?.[0] || "12 PM to 8 PM"
					}
					subtitle={`${
						intermittentFasting?.match(/(\d{1,2}:\d{1,2})/)?.[0] || "16:8"
					} schedule`}
					iconColor="text-blue-500"
					iconBg="bg-blue-100"
				/>
				<StatsCard
					icon={faDroplet}
					title="Water Intake"
					value="2-3L"
					subtitle="recommended daily"
					iconColor="text-cyan-500"
					iconBg="bg-cyan-100"
				/>
			</div>

			{/* Day Selection */}
			<div className="mb-8">
				<div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100">
					<div className="flex items-center justify-between mb-4">
						<h2 className="text-lg font-semibold text-gray-900">Select Day</h2>
						<span className="text-sm text-gray-500">
							{totalDays} days total
						</span>
					</div>
					{/* Day Selection - Improved Responsive Design */}
					<div className="relative mb-6">
						<div className="overflow-x-auto pb-2 -mx-4 px-4 sm:mx-0 sm:px-0">
							<div className="flex gap-2 min-w-max">
								{Array.from({length: totalDays}).map((_, index) => (
									<button
										key={index}
										onClick={() => setSelectedDay(index)}
										className={`
                        px-4 py-2 rounded-lg text-sm font-medium whitespace-nowrap
                        transition-all duration-200 ease-in-out
                        ${
													selectedDay === index
														? "bg-primary text-white shadow-sm"
														: "bg-white text-gray-600 hover:bg-gray-50 border border-gray-200"
												}
                    `}>
										Day {index + 1}
									</button>
								))}
							</div>
						</div>
						{/* Optional: Fade indicators for scroll */}
						<div className="absolute left-0 top-0 bottom-0 w-8 bg-gradient-to-r from-white to-transparent pointer-events-none hidden sm:block" />
						<div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-white to-transparent pointer-events-none hidden sm:block" />
					</div>
				</div>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
				{/* Grocery List */}
				<div className="lg:col-span-1">
					<div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100 sticky top-4">
						<div className="p-6 border-b border-gray-100">
							<div className="flex items-center justify-between">
								<h2 className="text-xl font-bold text-gray-900">
									Shopping List
								</h2>
								<div className="p-2 bg-green-100 rounded-lg">
									<FontAwesomeIcon
										icon={faListCheck}
										className="text-green-500 w-5 h-5"
									/>
								</div>
							</div>
							<p className="text-sm text-gray-500 mt-2">
								Check items as you shop to track your progress
							</p>
						</div>
						<div className="p-6">
							<div className="space-y-6">
								{Object.entries(groceryList || {}).map(([category, items]) => (
									<div
										key={category}
										className="border-b border-gray-100 last:border-0 pb-4 last:pb-0">
										<div className="flex items-center justify-between mb-3">
											<h3 className="flex items-center text-gray-800 font-semibold capitalize">
												<CategoryIcon category={category} />
												{category}
											</h3>
											<span className="text-xs font-medium text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
												{items.length} items
											</span>
										</div>
										<ul className="space-y-2">
											{items.map((item, index) => (
												<li key={index} className="flex items-center gap-3">
													<button
														onClick={() => toggleCheckedItem(category, item)}
														className="flex-shrink-0 focus:outline-none">
														<div
															className={`
                                                            w-5 h-5 rounded border-2 flex items-center justify-center
                                                            transition-colors duration-200
                                                            ${
																															checkedItems[
																																`${category}-${item}`
																															]
																																? "bg-primary border-primary"
																																: "border-gray-300 hover:border-primary"
																														}
                                                        `}>
															{checkedItems[`${category}-${item}`] && (
																<FontAwesomeIcon
																	icon={faCheckCircle}
																	className="w-4 h-4 text-white"
																/>
															)}
														</div>
													</button>
													<span
														className={`text-sm ${
															checkedItems[`${category}-${item}`]
																? "text-gray-400 line-through"
																: "text-gray-700"
														}`}>
														{item}
													</span>
												</li>
											))}
										</ul>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Meal Plan */}
				<div className="lg:col-span-2">
					<div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 border border-gray-100">
						<div className="p-6 border-b border-gray-100">
							<div className="flex items-center justify-between">
								<h2 className="text-xl font-bold text-gray-900">
									Daily Meal Plan
								</h2>
								<div className="p-2 bg-blue-100 rounded-lg">
									<FontAwesomeIcon
										icon={faUtensils}
										className="text-blue-500 w-5 h-5"
									/>
								</div>
							</div>
						</div>
						<div className="p-2">
							{caloricTargets?.[0]?.meals[selectedDay]?.mealPlans.map(
								(meal, index) => (
									<div key={index} className="mb-6 last:mb-0">
										<button
											onClick={() => toggleMealExpansion(index)}
											className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors duration-200">
											<div className="flex items-center gap-3">
												<div className="p-2 bg-primary/10 rounded-lg">
													<FontAwesomeIcon
														icon={faBowlFood}
														className="w-5 h-5 text-primary"
													/>
												</div>
												<div className="text-left">
													<h3 className="font-semibold text-gray-900">
														{meal.name}
													</h3>
													<p className="text-sm text-gray-500">
														{meal.description}
													</p>
												</div>
											</div>
											<div className="flex items-center gap-3">
												<span className="text-sm font-medium text-primary bg-primary/10 px-2 py-1 rounded-full">
													{meal.calories} cal
												</span>
												<FontAwesomeIcon
													icon={
														expandedMeal === index ? faChevronUp : faChevronDown
													}
													className="w-4 h-4 text-gray-400"
												/>
											</div>
										</button>

										{expandedMeal === index && (
											<div className="mt-4 pl-4 space-y-4 animate-slideDown">
												<div className="bg-white p-4 rounded-lg border border-gray-100">
													<h4 className="font-medium text-gray-900 mb-2">
														Ingredients
													</h4>
													<ul className="grid grid-cols-2 gap-2">
														{meal.ingredients.map((ingredient, idx) => (
															<li
																key={idx}
																className="flex items-center gap-2 text-sm text-gray-600">
																<span className="w-1.5 h-1.5 rounded-full bg-primary/20" />
																{ingredient}
															</li>
														))}
													</ul>
												</div>
												<div className="bg-white p-4 rounded-lg border border-gray-100">
													<h4 className="font-medium text-gray-900 mb-2">
														Instructions
													</h4>
													<p className="text-sm text-gray-600">
														{meal.instructions}
													</p>
												</div>
											</div>
										)}
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Stats Card Component
const StatsCard = ({icon, title, value, subtitle, iconColor, iconBg}) => (
	<div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 p-6 border border-gray-100">
		<div className="flex items-center justify-between mb-4">
			<div className="flex items-center space-x-3">
				<div className={`p-2 ${iconBg} rounded-lg`}>
					<FontAwesomeIcon icon={icon} className={`${iconColor} w-6 h-6`} />
				</div>
				<h3 className="font-semibold text-gray-800">{title}</h3>
			</div>
		</div>
		<p className="text-2xl font-bold text-gray-900">{value}</p>
		<p className="text-sm text-gray-500 mt-1">{subtitle}</p>
	</div>
);

// Empty State Component
const EmptyState = () => (
	<div className="flex items-center justify-center min-h-[400px] bg-gray-50 rounded-xl">
		<div className="text-center space-y-3">
			<div className="w-16 h-16 mx-auto bg-gray-200 rounded-full flex items-center justify-center">
				<FontAwesomeIcon icon={faUtensils} className="w-8 h-8 text-gray-400" />
			</div>
			<p className="text-gray-500 text-lg font-medium">
				No nutrition plan available
			</p>
			<p className="text-gray-400 text-sm">
				Please check back later or contact support
			</p>
		</div>
	</div>
);

// Category Icon Component
const CategoryIcon = ({category}) => {
	const iconMap = {
		proteins: faUtensils,
		carbohydrates: faLeaf,
		vegetables: faAppleAlt,
		fruits: faAppleAlt,
		fats: faFire,
		dairy: faUtensils,
		others: faListCheck,
	};

	return (
		<FontAwesomeIcon
			icon={iconMap[category] || faListCheck}
			className="mr-2 text-gray-400"
		/>
	);
};

export default NutritionPlanFormatter;
