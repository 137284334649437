import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Splash = () => {
	const navigate = useNavigate();
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		setIsVisible(true);
		const timer = setTimeout(() => navigate("/"), 3000);
		const interval = setInterval(() => {
			setLoadingProgress((prev) => (prev >= 100 ? 100 : prev + 5));
		}, 50);

		return () => {
			clearTimeout(timer);
			clearInterval(interval);
		};
	}, [navigate]);

	return (
		<div className="fixed inset-0 bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center overflow-hidden">
			{/* Enhanced Background Pattern */}
			<div className="absolute inset-0 opacity-20">
				<div className="absolute top-0 right-0 w-64 h-64 bg-accent1 rounded-bl-full transform rotate-45"></div>
				<div className="absolute bottom-0 left-0 w-64 h-64 bg-accent1 rounded-tr-full transform -rotate-45"></div>
			</div>

			{/* Main Content */}
			<div
				className={`z-10 text-center px-6 transform transition-all duration-700 ease-out
        ${
					isVisible ? "translate-y-0 opacity-100" : "translate-y-4 opacity-0"
				}`}>
				{/* Logo Container */}
				<div className="relative mb-6 group">
					<div className="absolute inset-0 transition-transform"></div>
					<img
						src="logo.svg"
						alt="Adrenaline MMA"
						className="relative w-18 h-18 mx-auto object-contain transform hover:scale-105 transition-all duration-300 drop-shadow-lg"
					/>
				</div>

				{/* Title & Subtitle */}
				<div className="space-y-2 mb-8">
					<h1 className="text-3xl font-bold text-white">
						Adrenaline <span className="text-highlightColor">MMA</span>
					</h1>
					<p className="text-base text-gray-300">Transform your potential</p>
				</div>

				{/* Enhanced Progress Bar */}
				<div className="relative w-48 mx-auto mb-4">
					<div className="h-1 bg-gray-700 rounded-full overflow-hidden">
						<div
							className="h-full bg-highlightColor transition-all duration-300 ease-out"
							style={{width: `${loadingProgress}%`}}
						/>
					</div>

					{/* Status Text */}
					<p
						className={`mt-2 text-sm font-medium transition-colors duration-300
            ${
							loadingProgress === 100 ? "text-highlightColor" : "text-gray-300"
						}`}>
						{loadingProgress === 100 ? "Ready" : "Loading..."}
					</p>
				</div>

				{/* Feature Tags */}
				<div className="flex justify-center gap-3 text-xs text-gray-300">
					{["Professional", "Interactive", "Personalized"].map(
						(feature, index) => (
							<span
								key={feature}
								className="opacity-70"
								style={{
									animationDelay: `${index * 100}ms`,
									opacity: isVisible ? 0.7 : 0,
								}}>
								{feature}
							</span>
						)
					)}
				</div>
			</div>
		</div>
	);
};

export default Splash;
