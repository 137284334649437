import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTrash,
	faImage,
	faRepeat,
	faTasks,
	faTachometer,
	faPlus,
	faArrowLeft,
	faCheckCircle,
	faUserCircle,
	faGauge,
	faPencil,
	faVideo,
	faEye,
	faIdCard,
	faAddressBook,
	faContactCard,
	faChartPie,
	faCogs,
	faVideoCamera,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import {
	getVideoCollectionDetailAPI,
	deleteVideoCollectionAPI,
} from "../../../API/VideoCollection";
import FormErrorBox from "../../Reusable/FormErrorBox";
import FormInputField from "../../Reusable/FormInputField";
import FormTextareaField from "../../Reusable/FormTextareaField";
import FormRadioField from "../../Reusable/FormRadioField";
import FormMultiSelectField from "../../Reusable/FormMultiSelectField";
import FormSelectField from "../../Reusable/FormSelectField";
import FormCheckboxField from "../../Reusable/FormCheckboxField";
import FormCountryField from "../../Reusable/FormCountryField";
import FormRegionField from "../../Reusable/FormRegionField";
import DataDisplayRowURL from "../../Reusable/DataDisplayRowURL";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION,
	VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION,
} from "../../../Constants/FieldOptions";
import {
	EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE,
	EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL,
	EXERCISE_TYPE_SYSTEM,
} from "../../../Constants/App";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowRadio from "../../Reusable/DataDisplayRowRadio";
import DataDisplayRowSelect from "../../Reusable/DataDisplayRowSelect";
import Layout from "../../Menu/Layout";
import Modal from "../../Reusable/Modal";
import AdminVideoContentList from "./VideoContent/List";

function AdminVideoCollectionDetail() {
	////
	//// URL Parameters.
	////

	const {vcid} = useParams();

	////
	//// Global state.
	////

	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////

	const [errors, setErrors] = useState({});
	const [activeTab, setActiveTab] = useState("overview");
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [tabIndex, setTabIndex] = useState(1);
	const [
		selectedVideoCollectionForDeletion,
		setSelectedVideoCollectionForDeletion,
	] = useState(null);

	////
	//// Event handling.
	////

	const onDeleteConfirmButtonClick = () => {
		console.log("onDeleteConfirmButtonClick"); // For debugging purposes only.

		deleteVideoCollectionAPI(
			selectedVideoCollectionForDeletion.id,
			onVideoCollectionDeleteSuccess,
			onVideoCollectionDeleteError,
			onVideoCollectionDeleteDone
		);
		setSelectedVideoCollectionForDeletion(null);
	};

	////
	//// API.
	////

	// --- Detail --- //

	function onVideoCollectionDetailSuccess(response) {
		console.log("onVideoCollectionDetailSuccess: Starting...");
		setDatum(response);
	}

	function onVideoCollectionDetailError(apiErr) {
		console.log("onVideoCollectionDetailError: Starting...");
		setErrors(apiErr);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDetailDone() {
		console.log("onVideoCollectionDetailDone: Starting...");
		setFetching(false);
	}

	// --- Delete --- //

	function onVideoCollectionDeleteSuccess(response) {
		console.log("onVideoCollectionDeleteSuccess: Starting..."); // For debugging purposes only.

		// Update notification.
		setTopAlertStatus("success");
		setTopAlertMessage("Video collection deleted");
		setTimeout(() => {
			console.log(
				"onDeleteConfirmButtonClick: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// Redirect back to the members page.
		setForceURL("/admin/video-collections");
	}

	function onVideoCollectionDeleteError(apiErr) {
		console.log("onVideoCollectionDeleteError: Starting..."); // For debugging purposes only.
		setErrors(apiErr);

		// Update notification.
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			console.log(
				"onVideoCollectionDeleteError: topAlertMessage, topAlertStatus:",
				topAlertMessage,
				topAlertStatus
			);
			setTopAlertMessage("");
		}, 2000);

		// The following code will cause the screen to scroll to the top of
		// the page. Please see ``react-scroll`` for more information:
		// https://github.com/fisshy/react-scroll
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onVideoCollectionDeleteDone() {
		console.log("onVideoCollectionDeleteDone: Starting...");
		setFetching(false);
	}

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0); // Start the page at the top of the page.

			setFetching(true);
			getVideoCollectionDetailAPI(
				vcid,
				onVideoCollectionDetailSuccess,
				onVideoCollectionDetailError,
				onVideoCollectionDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [vcid]);
	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{
			label: "MMA Lessons",
			icon: faVideo,
			link: "/admin/video-collections",
		},
		{label: "Detail", icon: faEye},
	];

	const EmptyThumbnail = () => (
		<div className="w-full h-full flex items-center justify-center bg-gray-50 text-gray-400">
			<FontAwesomeIcon icon={faImage} className="h-12 w-12" />
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="min-h-screen bg-gray-50">
				<div className="mx-auto px-4 sm:px-6 lg:px-8">
					{/* Main Content Card */}
					<div className="rounded-xl shadow-sm overflow-hidden">
						{/* Header Section with Hero Banner */}
						<div className="relative bg-gradient-to-r from-primary to-primary-dark p-6">
							<div className="relative z-10">
								<div className="flex justify-between items-center">
									<div>
										<h1 className="text-2xl font-bold text-white flex items-center">
											<FontAwesomeIcon icon={faVideo} className="mr-3" />
											{datum.name || "Video Collection"}
										</h1>
										<p className="mt-2 text-primary-100">
											{datum.summary || "Collection Details"}
										</p>
									</div>
								</div>
							</div>
						</div>

						{/* Tab Navigation - Modernized */}
						<div className="border-b border-gray-200 bg-white">
							<div className="px-6">
								<nav className="-mb-px flex space-x-8" aria-label="Tabs">
									<button
										onClick={() => setActiveTab("overview")}
										className={`${
											activeTab === "overview"
												? "border-primary text-primary font-semibold"
												: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
										} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors`}>
										<FontAwesomeIcon icon={faEye} className="mr-2" />
										Overview
									</button>
									<button
										onClick={() => setActiveTab("videos")}
										className={`${
											activeTab === "videos"
												? "border-primary text-primary font-semibold"
												: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
										} whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm flex items-center transition-colors`}>
										<FontAwesomeIcon icon={faVideoCamera} className="mr-2" />
										Videos
										{datum?.videoCount > 0 && (
											<span className="ml-2 bg-primary/10 text-primary py-0.5 px-2.5 rounded-full text-xs font-semibold">
												{datum.videoCount}
											</span>
										)}
									</button>
								</nav>
							</div>
						</div>

						{/* Content Area with Better Spacing and Structure */}
						<div>
							{isFetching ? (
								<div className="flex justify-center items-center py-12">
									<div className="animate-spin rounded-full h-10 w-10 border-3 border-primary border-t-transparent"></div>
								</div>
							) : (
								<>
									<FormErrorBox errors={errors} />

									{datum && (
										<>
											{activeTab === "overview" ? (
												<div className="space-y-6 my-8">
													{/* Main Content Grid */}
													<div className="grid grid-cols-1 xl:grid-cols-12 gap-6">
														{/* Thumbnail Card - Full width on mobile, sidebar on desktop */}
														<div className="xl:col-span-4">
															<div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
																<div className="p-4 sm:p-6">
																	<h2 className="text-lg font-semibold text-gray-900 flex items-center mb-4">
																		<FontAwesomeIcon
																			icon={faImage}
																			className="mr-2 text-primary"
																		/>
																		Thumbnail
																	</h2>
																	<div className="relative aspect-video bg-gray-50 rounded-lg overflow-hidden">
																		{(() => {
																			switch (datum.thumbnailType) {
																				case EXERCISE_THUMBNAIL_TYPE_SIMPLE_STORAGE_SERVICE:
																					return datum.thumbnailObjectUrl ? (
																						<img
																							src={datum.thumbnailObjectUrl}
																							alt={datum.name}
																							className="w-full h-full object-cover"
																						/>
																					) : (
																						<EmptyThumbnail />
																					);
																				case EXERCISE_THUMBNAIL_TYPE_EXTERNAL_URL:
																					return datum.thumbnailUrl ? (
																						<img
																							src={datum.thumbnailUrl}
																							alt={datum.name}
																							className="w-full h-full object-cover"
																						/>
																					) : (
																						<EmptyThumbnail />
																					);
																				default:
																					return <EmptyThumbnail />;
																			}
																		})()}
																	</div>
																</div>
															</div>
														</div>

														{/* Information Card - Full width on mobile, main content on desktop */}
														<div className="xl:col-span-8">
															<div className="bg-white rounded-xl shadow-sm border border-gray-200">
																<div className="p-4 sm:p-6">
																	<h2 className="text-lg font-semibold text-gray-900 flex items-center mb-6">
																		<FontAwesomeIcon
																			icon={faEye}
																			className="mr-2 text-primary"
																		/>
																		Collection Information
																	</h2>

																	{/* Responsive Grid for Information Fields */}
																	<div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
																		{/* Left Column */}
																		<div className="space-y-4">
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowText
																					label="Name"
																					value={datum.name}
																					labelClassName="text-sm font-medium text-gray-500"
																					valueClassName="mt-1 text-gray-900"
																				/>
																			</div>
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowText
																					label="Summary"
																					value={datum.summary}
																					labelClassName="text-sm font-medium text-gray-500"
																					valueClassName="mt-1 text-gray-900"
																				/>
																			</div>
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowURL
																					label="Category"
																					urlKey={datum.categoryName}
																					urlValue={`/admin/video-category/${datum.categoryId}`}
																					type="external"
																					labelClassName="text-sm font-medium text-gray-500"
																					linkClassName="mt-1 text-primary hover:text-primary-dark"
																				/>
																			</div>
																		</div>

																		{/* Right Column */}
																		<div className="space-y-4">
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowSelect
																					label="Type"
																					selectedValue={datum.type}
																					options={
																						VIDEO_COLLECTION_TYPE_OPTIONS_WITH_EMPTY_OPTION
																					}
																					labelClassName="text-sm font-medium text-gray-500"
																					valueClassName="mt-1 text-gray-900"
																				/>
																			</div>
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowSelect
																					label="Status"
																					selectedValue={datum.status}
																					options={
																						VIDEO_COLLECTION_STATUS_OPTIONS_WITH_EMPTY_OPTION
																					}
																					labelClassName="text-sm font-medium text-gray-500"
																					valueClassName="mt-1 text-gray-900"
																				/>
																			</div>
																			<div className="bg-gray-50 rounded-lg p-4">
																				<DataDisplayRowText
																					label="Description"
																					value={datum.description}
																					labelClassName="text-sm font-medium text-gray-500"
																					valueClassName="mt-1 text-gray-900"
																				/>
																			</div>
																		</div>

																		
																	</div>
                                                                    <div className="w-screen flex justify-between my-3 space-x-3">
																			<Link
																				to={`/admin/video-collection/${vcid}/update`}
																				className="inline-flex items-center p-3 border border-gray-300 text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 transition-colors">
																				<FontAwesomeIcon
																					icon={faPencil}
																					className="mr-2"
																				/>
																				Edit Collection
																			</Link>
																			<button
																				onClick={() =>
																					setSelectedVideoCollectionForDeletion(
																						datum
																					)
																				}
																				className="inline-flex items-center p-3 bg-red-500 border border-transparent text-sm font-medium rounded-lg text-white hover:bg-red-600 transition-colors">
																				<FontAwesomeIcon
																					icon={faTrash}
																					className="mr-2"
																				/>
																				Delete
																			</button>
																		</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											) : (
												<AdminVideoContentList vcid={vcid} />
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
				</div>

				{/* Delete Modal - Keep existing modal code */}
				<Modal
					isOpen={selectedVideoCollectionForDeletion !== null}
					onClose={() => setSelectedVideoCollectionForDeletion(null)}
					header="Are you sure?">
					<div>
						<p className="text-gray-700 mb-6">
							You are about to delete this video collection and all associated
							data. This action cannot be undone.
						</p>
						<div className="flex justify-end space-x-4">
							<button
								onClick={() => setSelectedVideoCollectionForDeletion(null)}
								className="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50">
								Cancel
							</button>
							<button
								onClick={onDeleteConfirmButtonClick}
								className="px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700">
								Delete
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminVideoCollectionDetail;
