import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faClock,
	faArrowLeft,
	faGauge,
	faLeaf,
	faEye,
	faIdCard,
    faPencil,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {
	getNutritionPlanDetailAPI,
	deleteNutritionPlanAPI,
} from "../../../API/NutritionPlan";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import {
	currentUserState,
	topAlertMessageState,
	topAlertStatusState,
} from "../../../AppState";
import DataDisplayRowText from "../../Reusable/DataDisplayRowText";
import DataDisplayRowRadio from "../../Reusable/DataDisplayRowRadio";
import DataDisplayRowMultiSelect from "../../Reusable/FormTextOptionRow";
import DataDisplayRowMultiSelectStatic from "../../Reusable/DataDisplayRowMultiSelectStatic";
import DataDisplayRowSelectStatic from "../../Reusable/DataDisplayRowSelectStatic";
import {
	HOME_GYM_EQUIPMENT_MAP,
	PHYSICAL_ACTIVITY_MAP,
	DAYS_PER_WEEK_MAP,
	TIME_PER_DAY_MAP,
	MAX_WEEK_MAP,
	FITNESS_GOAL_MAP,
	WORKOUT_PREFERENCE_MAP,
	MEALS_PER_DAY_MAP,
	CONSUME_FREQUENCY_MAP,
	NUTRITIONAL_GOAL_MAP,
} from "../../../Constants/FieldOptions";
import {
	FITNESS_GOAL_STATUS_QUEUED,
	FITNESS_GOAL_STATUS_ACTIVE,
	GENDER_OTHER,
	GENDER_MALE,
	GENDER_FEMALE,
	WORKOUT_INTENSITY_LOW,
	WORKOUT_INTENSITY_MEDIUM,
	WORKOUT_INTENSITY_HIGH,
    NUTRITION_GOAL_STATUS_ACTIVE,
} from "../../../Constants/App";
import Layout from "../../Menu/Layout";

function MemberNutritionPlanSubmissionForm() {
	////
	//// URL Parameters.
	////
	const {id} = useParams();

	////
	//// Global state.
	////
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	////
	//// Component states.
	////
	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [
		selectedNutritionPlanForDeletion,
		setSelectedNutritionPlanForDeletion,
	] = useState(null);

	////
	//// Event handling.
	////

	const handleDelete = () => {
		deleteNutritionPlanAPI(
			selectedNutritionPlanForDeletion.id,
			onNutritionPlanDeleteSuccess,
			onNutritionPlanDeleteError,
			onNutritionPlanDeleteDone
		);
		setShowDeleteModal(false);
		setSelectedNutritionPlanForDeletion(null);
	};

	////
	//// API.
	////

	function onNutritionPlanDetailSuccess(response) {
		setDatum(response);
	}

	function onNutritionPlanDetailError(apiErr) {
		setErrors(apiErr);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onNutritionPlanDetailDone() {
		setFetching(false);
	}

	function onNutritionPlanDeleteSuccess(response) {
		setTopAlertStatus("success");
		setTopAlertMessage("Nutrition plan deleted");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		setForceURL("/nutrition-plans");
	}

	function onNutritionPlanDeleteError(apiErr) {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	}

	function onNutritionPlanDeleteDone() {
		setFetching(false);
	}

	const NutritionPlanHeader = ({datum, onDeleteClick}) => (
		<div className="flex flex-col sm:flex-row justify-between items-center gap-4 mb-6">
			<h1 className="text-2xl sm:text-3xl font-bold text-primary flex items-center">
				<FontAwesomeIcon className="mr-2" icon={faLeaf} />
				Nutrition Plan
			</h1>
			<div className="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
				{datum?.status === NUTRITION_GOAL_STATUS_ACTIVE && (
					<Link
						to={`/nutrition-plan/${datum.id}/update`}
						className="w-full sm:w-auto inline-flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
						<FontAwesomeIcon className="mr-2" icon={faPencil} />
						Edit & Re-request
					</Link>
				)}
				<button
					onClick={onDeleteClick}
					className="w-full sm:w-auto inline-flex items-center justify-center bg-red-500 hover:bg-red-600 text-white px-4 py-2.5 rounded-lg transition-all duration-200">
					<FontAwesomeIcon className="mr-2" icon={faTrash} />
					Delete
				</button>
			</div>
		</div>
	);

	////
	//// Misc.
	////

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			setFetching(true);
			Scroll.animateScroll.scrollToTop();
			getNutritionPlanDetailAPI(
				id,
				onNutritionPlanDetailSuccess,
				onNutritionPlanDetailError,
				onNutritionPlanDetailDone
			);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	////
	//// Component rendering.
	////

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const breadcrumbItems = [
		{label: "Dashboard", link: "/dashboard", icon: faGauge},
		{label: "Nutrition Plans", link: "/nutrition-plans", icon: faLeaf},
		{label: "Detail", icon: faEye},
	];

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			{/* Delete Confirmation Modal */}
			<Modal
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				header="Are you sure?">
				<div className="space-y-4">
					<p className="text-gray-600">
						You are about to delete this nutrition plan and all the data
						associated with it. This action cannot be undone. Are you sure you
						would like to continue?
					</p>
					<div className="flex justify-end space-x-2">
						<button
							onClick={() => setShowDeleteModal(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
							Cancel
						</button>
						<button
							onClick={handleDelete}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700">
							Delete
						</button>
					</div>
				</div>
			</Modal>

			{/* Main Content */}
			<div className="bg-white rounded-lg shadow-lg p-4">
				{datum && (
					<NutritionPlanHeader
						datum={datum}
						onDeleteClick={() => setSelectedNutritionPlanForDeletion(datum)}
					/>
				)}

				<FormErrorBox errors={errors} />

				{isFetching ? (
					<PageLoadingContent displayMessage={"Please wait..."} />
				) : (
					datum && (
						<div className="space-y-6">
							{/* Queued Status Message */}
							{datum.status === FITNESS_GOAL_STATUS_QUEUED && (
								<div className="bg-gray-50 p-6 rounded-lg">
									<h2 className="text-xl font-semibold flex items-center mb-2">
										<FontAwesomeIcon icon={faClock} className="mr-2" />
										Nutrition Plan Submitted
									</h2>
									<p className="text-gray-600">
										You have successfully submitted this nutrition plan to our
										team. The estimated time until our team completes your
										nutrition plan will take about <b>1 or 2 days</b>. Please
										check back later.
									</p>
								</div>
							)}

							{/* Active Status Content */}
							{datum.status === FITNESS_GOAL_STATUS_ACTIVE && (
								<>
									{/* Navigation Tabs */}
									<div className="border-b border-gray-200 mb-6">
										<nav className="-mb-px flex space-x-8">
											<Link
												to={`/nutrition-plan/${datum.id}`}
												className="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium">
												Detail
											</Link>
											<Link
												to="#"
												className="border-primary text-primary whitespace-nowrap py-4 px-1 border-b-2 font-medium"
												aria-current="page">
												Submission Form
											</Link>
										</nav>
									</div>

									{/* Meta Section */}
									<div className="space-y-6">
										<h2 className="text-lg font-medium text-gray-900">Meta</h2>
										<DataDisplayRowText label="Name" value={datum.name} />
									</div>

									{/* Personal Details Section */}
									<div className="space-y-6">
										<h2 className="text-lg font-medium text-gray-900 flex items-center">
											<FontAwesomeIcon icon={faIdCard} className="mr-2" />
											Personal Details
										</h2>

										<DataDisplayRowText
											label="Birthday"
											value={datum.birthday}
											type="date"
										/>

										<DataDisplayRowText
											label="Height"
											value={`${datum.heightFeet}' ${datum.heightInches}"`}
										/>

										<DataDisplayRowText
											label="Weight"
											value={`${datum.weight} lbs`}
										/>

										<DataDisplayRowRadio
											label="Gender"
											value={datum.gender}
											opt1Value={GENDER_MALE}
											opt1Label="Male"
											opt2Value={GENDER_FEMALE}
											opt2Label="Female"
											opt3Value={GENDER_OTHER}
											opt3Label="Other"
										/>

										{datum.gender === GENDER_OTHER && (
											<DataDisplayRowText
												label="Gender (Other)"
												value={datum.genderOther}
											/>
										)}

										<DataDisplayRowText
											label="What is your ideal weight for your nutrition goal?"
											value={`${datum.idealWeight} lbs`}
										/>

										<DataDisplayRowSelectStatic
											label="My current level of physical activity is"
											selectedValue={datum.physicalActivity}
											map={PHYSICAL_ACTIVITY_MAP}
										/>

										<DataDisplayRowRadio
											label="My current intensity in my exercise routine is"
											value={datum.workoutIntensity}
											opt1Value={WORKOUT_INTENSITY_LOW}
											opt1Label="Low"
											opt2Value={WORKOUT_INTENSITY_MEDIUM}
											opt2Label="Medium"
											opt3Value={WORKOUT_INTENSITY_HIGH}
											opt3Label="High"
										/>
									</div>

									{/* Allergies Section */}
									<div className="space-y-6">
										<h2 className="text-lg font-medium text-gray-900 flex items-center">
											<FontAwesomeIcon icon={faIdCard} className="mr-2" />
											Allergies
										</h2>

										<DataDisplayRowRadio
											label="Do you have any allergies?"
											value={datum.hasAllergies}
											opt1Value={1}
											opt1Label="Yes"
											opt2Value={2}
											opt2Label="No"
										/>

										{datum.hasAllergies === 1 && (
											<DataDisplayRowText
												label="If yes, what are your allergies?"
												value={datum.allergies}
											/>
										)}
									</div>

									{/* Nutrition Goals Section */}
									<div className="space-y-6">
										<h2 className="text-lg font-medium text-gray-900 flex items-center">
											<FontAwesomeIcon icon={faIdCard} className="mr-2" />
											Goal(s) for Nutrition Plan
										</h2>

										<DataDisplayRowSelectStatic
											label="How many meals do you typically eat in a day?"
											selectedValue={datum.mealsPerDay}
											map={MEALS_PER_DAY_MAP}
										/>

										<DataDisplayRowSelectStatic
											label="How often do you consume fast food or junk food?"
											selectedValue={datum.consumeJunkFood}
											map={CONSUME_FREQUENCY_MAP}
										/>

										<DataDisplayRowSelectStatic
											label="How often do you consume fruits and/or vegetables?"
											selectedValue={datum.consumeFruitsAndVegetables}
											map={CONSUME_FREQUENCY_MAP}
										/>

										<DataDisplayRowMultiSelectStatic
											label="Enter your nutrition goals"
											selectedValues={datum.goals}
											map={NUTRITIONAL_GOAL_MAP}
										/>

										<DataDisplayRowSelectStatic
											label="Enter the number of weeks that you would like your training plan to last"
											selectedValue={datum.maxWeeks}
											map={MAX_WEEK_MAP}
										/>

										<DataDisplayRowRadio
											label="Has Intermittent Fasting"
											value={datum.hasIntermittentFasting}
											opt1Value={1}
											opt1Label="Yes"
											opt2Value={2}
											opt2Label="No"
										/>
									</div>
								</>
							)}

							{/* Back Button */}
							<div className="mt-8">
								<Link
									to="/nutrition-plans"
									className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
									<FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
									Back to nutrition plans
								</Link>
							</div>
						</div>
					)
				)}
			</div>
		</Layout>
	);
}

export default MemberNutritionPlanSubmissionForm;
