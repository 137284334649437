import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faRibbon,
	faGauge,
	faSearch,
	faEye,
	faPencilAlt,
	faTrashAlt,
	faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";

import {
	ListHeader,
	ListFilter,
	DataListDesktop,
	DataListMobile,
	TableCell,
} from "../../Reusable/AdminList";
import FormErrorBox from "../../Reusable/FormErrorBox";
import {getBadgesListAPI, deleteBadgeAPI} from "../../../API/badge";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";
import {BADGE_STATUS_MAP} from "../../../Constants/FieldOptions";

function AdminBadgeList() {
	// Global state
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	// Component states
	const [errors, setErrors] = useState({});
	const [listData, setListData] = useState(null);
	const [selectedBadgeForDeletion, setSelectedBadgeForDeletion] =
		useState(null);
	const [isFetching, setIsFetching] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [filters, setFilters] = useState({
		search: "",
		status: "all",
		dateRange: {
			from: "",
			to: "",
		},
	});

	// Pagination
	const [pageSize, setPageSize] = useState(10);
	const [previousCursors, setPreviousCursors] = useState([]);
	const [nextCursor, setNextCursor] = useState(null);
	const [currentCursor, setCurrentCursor] = useState("");

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Badges", icon: faRibbon},
	];

	// Table headers configuration
	const headers = [
		{label: "Badge", className: "w-1/4"},
		{label: "Description", className: "w-2/4"},
		{label: "Actions", className: "w-1/4"},
	];

	// API Calls
	// Update fetchBadges to include filters
	const fetchBadges = (cursor, limit) => {
		setIsFetching(true);
		setErrors({});

		let params = new Map();
		params.set("page_size", limit);
		if (cursor) params.set("cursor", cursor);
		if (filters.search) params.set("search", filters.search);
		if (filters.status !== "all") params.set("status", filters.status);
		if (filters.dateRange.from) params.set("date_from", filters.dateRange.from);
		if (filters.dateRange.to) params.set("date_to", filters.dateRange.to);

		getBadgesListAPI(
			params,
			onBadgesListSuccess,
			onBadgesListError,
			onBadgesListDone
		);
	};

	// Handle filter changes
	const handleFilterChange = (key, value) => {
		setFilters((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	// Clear filters
	const handleClearFilters = () => {
		setFilters({
			search: "",
			status: "all",
			dateRange: {
				from: "",
				to: "",
			},
		});
		fetchBadges("", pageSize);
	};

	// API Callbacks
	const onBadgesListSuccess = (response) => {
		setListData(response);
		setNextCursor(response.hasNextPage ? response.nextCursor : null);
	};

	const onBadgesListError = (apiErr) => setErrors(apiErr);
	const onBadgesListDone = () => setIsFetching(false);

	const onBadgeDeleteSuccess = () => {
		setTopAlertMessage("Badge deleted successfully");
		setTopAlertStatus("success");
		fetchBadges(currentCursor, pageSize);
		setTimeout(() => setTopAlertMessage(""), 2000);
	};

	// Event Handlers
	const onNextClicked = () => {
		setPreviousCursors([...previousCursors, currentCursor]);
		setCurrentCursor(nextCursor);
	};

	const onPreviousClicked = () => {
		const prevCursor = previousCursors.pop();
		setPreviousCursors([...previousCursors]);
		setCurrentCursor(prevCursor);
	};

	// Initial fetch
	useEffect(() => {
		fetchBadges(currentCursor, pageSize);
	}, [currentCursor, pageSize, filters]);

	// Row rendering functions
	const renderDesktopRow = (badge, index) => (
		<tr key={badge.id}>
			<TableCell>
				<div className="flex items-center space-x-3">
					<img
						src={badge.thumbnailUrl || badge.thumbnailObjectUrl}
						alt={badge.name}
						className="h-10 w-10 rounded-full object-cover"
					/>
					<span className="font-medium text-gray-900">{badge.name}</span>
				</div>
			</TableCell>
			<TableCell>{badge.description}</TableCell>
			<TableCell>
				<div className="flex items-center space-x-3">
					<Link
						to={`/admin/badges/${badge.id}`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faEye} className="mr-2 h-4 w-4" />
						View
					</Link>
					<Link
						to={`/admin/badges/${badge.id}/edit`}
						className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
						<FontAwesomeIcon icon={faPencilAlt} className="mr-2 h-4 w-4" />
						Edit
					</Link>
					<button
						onClick={() => setSelectedBadgeForDeletion(badge)}
						className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
						<FontAwesomeIcon icon={faTrashAlt} className="mr-2 h-4 w-4" />
						Delete
					</button>
				</div>
			</TableCell>
		</tr>
	);

	const renderMobileItem = (badge, index) => (
		<div
			key={badge.id}
			className="bg-white rounded-xl p-4 border border-gray-200 shadow-sm">
			<div className="flex items-center space-x-3 mb-3">
				<img
					src={badge.thumbnailUrl}
					alt={badge.name}
					className="h-12 w-12 rounded-full object-cover"
				/>
				<div>
					<h3 className="font-medium text-gray-900">{badge.name}</h3>
					<p className="text-sm text-gray-500">{badge.description}</p>
				</div>
			</div>
			<div className="flex justify-end space-x-3">
				<Link
					to={`/admin/badges/${badge.id}`}
					className="text-primary hover:text-primary-dark transition-colors">
					View
				</Link>
				<button
					onClick={() => setSelectedBadgeForDeletion(badge)}
					className="text-red-600 hover:text-red-700 transition-colors">
					Delete
				</button>
			</div>
		</div>
	);

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="container mx-auto px-4 py-8 max-w-container">
				<ListHeader
					title="Badges"
					icon={faRibbon}
					onRefresh={() => fetchBadges(currentCursor, pageSize)}
					onToggleFilter={() => setShowFilter(!showFilter)}
					addLink="/admin/badges/add"
					addLabel="New Badge"
				/>

				{showFilter && (
					<ListFilter onClear={handleClearFilters}>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-4">
							{/* Search Filter */}
							<div className="space-y-2">
								<label className="block text-sm font-medium text-gray-700">
									<FontAwesomeIcon icon={faSearch} className="mr-2" />
									Search
								</label>
								<input
									type="text"
									value={filters.search}
									onChange={(e) => handleFilterChange("search", e.target.value)}
									placeholder="Search badges..."
									className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
								/>
							</div>
						</div>
					</ListFilter>
				)}

				{isFetching ? (
					<PageLoadingContent displayMessage="Loading badges..." />
				) : (
					<>
						<FormErrorBox errors={errors} />
						{listData &&
						listData.results &&
						(listData.results.length > 0 || previousCursors.length > 0) ? (
							<div className="animate-fade-in">
								{/* Desktop View */}
								<div className="hidden md:block">
									<DataListDesktop
										headers={headers}
										data={listData?.results || []}
										renderRow={renderDesktopRow}
										pageSize={pageSize}
										setPageSize={setPageSize}
										previousCursors={previousCursors}
										hasNextPage={!!nextCursor}
										onPreviousClicked={onPreviousClicked}
										onNextClicked={onNextClicked}
									/>
								</div>

								{/* Mobile View */}
								<div className="md:hidden">
									<DataListMobile
										data={listData?.results || []}
										renderItem={renderMobileItem}
										pageSize={pageSize}
										setPageSize={setPageSize}
										previousCursors={previousCursors}
										hasNextPage={!!nextCursor}
										onPreviousClicked={onPreviousClicked}
										onNextClicked={onNextClicked}
									/>
								</div>
							</div>
						) : (
							<div
								className="animate-fade-in min-h-[300px] bg-gray-50 rounded-lg 
				  flex items-center justify-center">
								<div className="text-center p-8">
									<p className="text-3xl font-bold text-gray-400 mb-4">
										<FontAwesomeIcon icon={faRibbon} className="mr-2" />
										No Badges
									</p>
									<p className="text-gray-600">
										No badges found.{" "}
										<Link
											to="/admin/badges/add"
											className="text-primary hover:text-primary-dark transition-colors">
											Click here
											<FontAwesomeIcon icon={faArrowRight} className="ml-1" />
										</Link>{" "}
										to create your first badge.
									</p>
								</div>
							</div>
						)}
					</>
				)}

				{/* Delete Confirmation Modal */}
				<Modal
					isOpen={!!selectedBadgeForDeletion}
					onClose={() => setSelectedBadgeForDeletion(null)}
					header="Confirm Deletion">
					<div className="space-y-4">
						<p className="text-gray-600">
							Are you sure you want to delete this badge? This action cannot be
							undone.
						</p>
						<div className="flex justify-end space-x-3">
							<button
								onClick={() => setSelectedBadgeForDeletion(null)}
								className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors">
								Cancel
							</button>
							<button
								onClick={() => {
									deleteBadgeAPI(
										selectedBadgeForDeletion.id,
										onBadgeDeleteSuccess,
										(err) => setErrors(err),
										() => setSelectedBadgeForDeletion(null)
									);
								}}
								className="px-4 py-2 bg-red-600 text-white hover:bg-red-700 rounded-lg transition-colors">
								Delete
							</button>
						</div>
					</div>
				</Modal>
			</div>
		</Layout>
	);
}

export default AdminBadgeList;
