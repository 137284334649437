import React, {useState, useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import Scroll from "react-scroll";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faHandHolding,
	faEye,
	faPencil,
	faTrashCan,
	faGauge,
} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState} from "recoil";
import {useParams} from "react-router-dom";

import {getOfferDetailAPI, deleteOfferAPI} from "../../../API/Offer";
import FormErrorBox from "../../Reusable/FormErrorBox";
import PageLoadingContent from "../../Reusable/PageLoadingContent";
import Modal from "../../Reusable/Modal";
import Layout from "../../Menu/Layout";
import {
	topAlertMessageState,
	topAlertStatusState,
	currentUserState,
} from "../../../AppState";
import {
	OFFER_PAY_FREQUENCY_WITH_EMPTY_OPTIONS,
	BUSINESS_FUNCTION_WITH_EMPTY_OPTIONS,
	OFFER_MEMBERSHIP_RANK_WITH_EMPTY_OPTIONS,
	OFFER_STATUS_WITH_EMPTY_OPTIONS,
} from "../../../Constants/FieldOptions";

function AdminOfferDetail() {
	const {id} = useParams();
	const [topAlertMessage, setTopAlertMessage] =
		useRecoilState(topAlertMessageState);
	const [topAlertStatus, setTopAlertStatus] =
		useRecoilState(topAlertStatusState);
	const [currentUser] = useRecoilState(currentUserState);

	const [errors, setErrors] = useState({});
	const [isFetching, setFetching] = useState(false);
	const [forceURL, setForceURL] = useState("");
	const [datum, setDatum] = useState({});
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			window.scrollTo(0, 0);
			setFetching(true);
			getOfferDetailAPI(id, onSuccess, onError, onDone);
		}

		return () => {
			mounted = false;
		};
	}, [id]);

	const onSuccess = (response) => {
		console.log("onSuccess: Starting...");
		setDatum(response);
	};

	const onError = (apiErr) => {
		console.log("onError: Starting...");
		setErrors(apiErr);

		// Scroll to top.
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onDone = () => {
		console.log("onDone: Starting...");
		setFetching(false);
	};

	const onDeleteConfirm = () => {
		console.log("onDeleteConfirm: Starting...");
		deleteOfferAPI(id, onDeleteSuccess, onDeleteError, onDeleteDone);
	};

	const onDeleteSuccess = () => {
		setTopAlertStatus("success");
		setTopAlertMessage("Offer deleted");
		setTimeout(() => {
			setTopAlertMessage("");
			setForceURL("/admin/offers");
		}, 2000);
	};

	const onDeleteError = (apiErr) => {
		setErrors(apiErr);
		setTopAlertStatus("danger");
		setTopAlertMessage("Failed deleting");
		setTimeout(() => {
			setTopAlertMessage("");
		}, 2000);

		// Scroll to top.
		var scroll = Scroll.animateScroll;
		scroll.scrollToTop();
	};

	const onDeleteDone = () => {
		setFetching(false);
	};

	if (forceURL !== "") {
		return <Navigate to={forceURL} />;
	}

	const getStatusLabel = (status) => {
		const option = OFFER_STATUS_WITH_EMPTY_OPTIONS.find(
			(opt) => opt.value === status
		);
		return option ? option.label : "Unknown";
	};

	const breadcrumbItems = [
		{label: "Dashboard", link: "/admin/dashboard", icon: faGauge},
		{label: "Offers", link: "/admin/offers", icon: faHandHolding},
		{label: "Detail", icon: faEye},
	];

	const formatCurrency = (amount, currencyCode) => {
		if (!amount || !currencyCode) {
			return "N/A";
		}

		try {
			return new Intl.NumberFormat("en-CA", {
				style: "currency",
				currency: currencyCode.toUpperCase(),
			}).format(amount);
		} catch (error) {
			console.error("Currency formatting error:", error);
			return `${amount} ${currencyCode}`;
		}
	};

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleDateString("en-CA", {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		});
	};

	const getPayFrequencyLabel = (value) => {
		const option = OFFER_PAY_FREQUENCY_WITH_EMPTY_OPTIONS.find(
			(opt) => opt.value === value
		);
		return option ? option.label : "Unknown";
	};

	const getBusinessFunctionLabel = (value) => {
		const option = BUSINESS_FUNCTION_WITH_EMPTY_OPTIONS.find(
			(opt) => opt.value === value
		);
		return option ? option.label : "Unknown";
	};

	const getMembershipRankLabel = (value) => {
		const option = OFFER_MEMBERSHIP_RANK_WITH_EMPTY_OPTIONS.find(
			(opt) => opt.value === value
		);
		return option ? option.label : "Unknown";
	};

	return (
		<Layout breadcrumbItems={breadcrumbItems} currentUser={currentUser}>
			<div className="mx-auto px-4 sm:px-6 lg:px-8">
				<div className="bg-white rounded-lg shadow-sm">
					<div>
						<FormErrorBox errors={errors} />

						{isFetching ? (
							<PageLoadingContent message="Loading..." />
						) : (
							<>
								<div className="flex flex-col md:flex-row justify-between items-start md:items-center mt-6 gap-4">
									<h1 className="text-2xl font-bold text-gray-900">
										{datum.name}
									</h1>
									<div className="flex items-center gap-2">
										<Link
											to={`/admin/offer/${id}/update`}
											className="inline-flex items-center px-4 py-2 bg-primary hover:bg-primary-dark 
                               text-white rounded-lg transition-colors duration-300">
											<FontAwesomeIcon icon={faPencil} className="mr-2" />
											Edit
										</Link>
										<button
											onClick={() => setShowDeleteConfirm(true)}
											className="inline-flex items-center px-4 py-2 bg-red-600 hover:bg-red-700 
                               text-white rounded-lg transition-colors duration-300">
											<FontAwesomeIcon icon={faTrashCan} className="mr-2" />
											Delete
										</button>
									</div>
								</div>

								<div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
									<div className="bg-white rounded-lg shadow-sm p-6">
										<h3 className="text-lg font-medium text-gray-900 mb-4">
											Basic Information
										</h3>
										<dl className="space-y-4">
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Description
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{datum.description}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Price
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{formatCurrency(
														datum.price,
														datum.priceCurrency || datum.priceCurrency
													)}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Payment Frequency
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{getPayFrequencyLabel(datum.payFrequency)}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Status
												</dt>
												<dd className="mt-1">
													<span
														className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                          ${
														datum.status === 1
															? "bg-green-100 text-green-800"
															: datum.status === 2
															? "bg-blue-100 text-blue-800"
															: "bg-gray-100 text-gray-800"
													}`}>
														{getStatusLabel(datum.status)}
													</span>
												</dd>
											</div>
										</dl>
									</div>

									<div className="bg-white rounded-lg shadow-sm p-6">
										<h3 className="text-lg font-medium text-gray-900 mb-4">
											Additional Details
										</h3>
										<dl className="space-y-4">
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Business Function
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{getBusinessFunctionLabel(datum.businessFunction)}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Membership Rank
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{getMembershipRankLabel(datum.membershipRank)}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Organization
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{datum.organizationName}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Subscription Type
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{datum.is_subscription ? "Recurring" : "One-time"}
												</dd>
											</div>
										</dl>
									</div>

									<div className="bg-white rounded-lg shadow-sm p-6">
										<h3 className="text-lg font-medium text-gray-900 mb-4">
											Payment Processing
										</h3>
										<dl className="space-y-4">
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Payment Processor
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{datum.paymentProcessorName}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Stripe Product ID
												</dt>
												<dd className="mt-1 text-sm font-mono text-gray-900">
													{datum.stripeProductId}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Stripe Price ID
												</dt>
												<dd className="mt-1 text-sm font-mono text-gray-900">
													{datum.stripePriceId}
												</dd>
											</div>
										</dl>
									</div>

									<div className="bg-white rounded-lg shadow-sm p-6">
										<h3 className="text-lg font-medium text-gray-900 mb-4">
											Timestamps
										</h3>
										<dl className="space-y-4">
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Created At
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{formatDate(datum.createdAt)}
												</dd>
											</div>
											<div>
												<dt className="text-sm font-medium text-gray-500">
													Last Modified
												</dt>
												<dd className="mt-1 text-sm text-gray-900">
													{formatDate(datum.modifiedAt)}
												</dd>
											</div>
										</dl>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			<Modal
				isOpen={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				header="Confirm Deletion">
				<div className="space-y-4">
					<p className="text-gray-600">
						Are you sure you want to delete this offer? This action cannot be
						undone.
					</p>
					<div className="flex justify-end space-x-3">
						<button
							onClick={() => setShowDeleteConfirm(false)}
							className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border 
                       border-gray-300 rounded-lg hover:bg-gray-50 transition-colors duration-300">
							Cancel
						</button>
						<button
							onClick={onDeleteConfirm}
							className="px-4 py-2 text-sm font-medium text-white bg-red-600 
                       rounded-lg hover:bg-red-700 transition-colors duration-300">
							Delete
						</button>
					</div>
				</div>
			</Modal>
		</Layout>
	);
}

export default AdminOfferDetail;
